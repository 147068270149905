import { listClientContractsByClientId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchClientContractsByClientId = async (clientId) => {
  if (!clientId) return;

  try {
    const response = await client.graphql({
      query: listClientContractsByClientId,
      variables: {
        clientId: clientId,
        sortDirection: 'DESC',
      },
    });

    const results = response.data.clientContractsByClientId.items;
    return results;
  } catch (err) {
    return ('error fetchClientContractsByClientId: ', [err]);
  }
};

fetchClientContractsByClientId.propTypes = {
  clientId: PropTypes.string.isRequired,
};
