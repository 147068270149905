import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { billableMaterialsByDivisionId } from '../../../graphql/custom_queries';

const client = generateClient();

export const fetchDefaultBillableMaterials = async (divisionId) => {
  try {
    const response = await client.graphql({
      query: billableMaterialsByDivisionId,
      variables: {
        divisionId: divisionId,
        filter: { isActive: { eq: true } }
      }
    });

    const results = response.data.billableMaterialsByDivisionId.items
      .sort((a, b) => (a.description > b.description ? 1 : -1));
      
    return results;
  } catch (err) {
    return 'error fetchDefaultBillableMaterials: ' + err;
  }
};

fetchDefaultBillableMaterials.propTypes = {
  divisionId: PropTypes.string.isRequired,
};
