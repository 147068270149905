import PropTypes from 'prop-types';
import { Box } from "@chakra-ui/react";

export const ModuleBoxDrawer = (props) => {
  const {
    border='none',
    children,
  } = props;

  return (
    <Box 
      border={border} //used only for debugging placement
      w={{ 
        base: '100px', //< 400 screen width
        sm: '380px', 
        md: '1035px', // ipad 7th gen target - 810 when with enu nav
        lg: '1366px', // macbook air and ipad
        xl: '1505px',
        '2xl': '1625px'
      }}
      borderRadius='6px'
      p='25px'
      {...props}
      bg='var(--dark-module-bg)'>
        {children}
    </Box>
  );
}

// Prop validation
ModuleBoxDrawer.propTypes = {
  border: PropTypes.string, // Assuming border is a string like "1px solid black"
  children: PropTypes.node.isRequired, // children is required and can be any renderable content
};
