import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

const identityPoolId = import.meta.env.VITE_IDENTITY_POOL;

// Configure the S3 client using Cognito Identity Pool
const s3Client = new S3Client({
  region: 'us-east-1',
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: 'us-east-1' }),
    identityPoolId: identityPoolId,
  }),
});

// This function generates a signed URL on-demand for one file
export const getSignedUrlForFile = async ({ bucketName, identityId, fileName, fileSecurity = 'protected' }) => {
  try {

    const fileKey =(fileName.startsWith('protected')) ? `${fileName}` : `${fileSecurity}/${identityId}/${fileName}`;

    // Create the S3 GetObject command
    const command = new GetObjectCommand({
      Bucket: bucketName,
      Key: fileKey,
    });

    // Generate and return the signed URL
    return await getSignedUrl(s3Client, command, { expiresIn: 604800 }); // URL expires in 7 days
  } catch (error) {
    console.error('Error generating signed URL:', error);
    throw error; // Re-throw the error to be handled in the parent component
  }
};