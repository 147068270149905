import { getColor, mode } from '@chakra-ui/theme-tools'

export const FormTheme = {
  components: {
    Input: {
      variants: {
        outline: (props) => {
          const { theme } = props
          return {
            field: {
              _invalid: {
                borderColor: '#FF3C1E',
                boxShadow: `0 0 0 2px ${getColor(theme, '#f85b43')}`,
                // borderColor: 'blue.400',
                // boxShadow: `0 0 0 2px ${getColor(theme, 'blue.700')}`,
              },
            },
          }
        },
      },
      defaultProps: {
        // errorBorderColor: 'purple.500',
        errorBorderColor: '#FF3C1E',
      },
    },
    Form: {
      baseStyle: (props) => ({
        requiredIndicator: {
          color: mode('#FF3C1E', '#FF3C1E')(props),
          // color: mode('blue.500', 'blue.300')(props),
          // fontSize: '24px',
          // fontWeight: 'bold',
        },
      }),
    },
    FormError: {
      baseStyle: (props) => ({
        text: {
          // fontFamily: 'monospace',
          // fontSize: '24px',
          // color: mode('blue.500', 'blue.300')(props),
          color: mode('#FF3C1E', '#FF3C1E')(props),
        },
      }),
    },
  },
}
