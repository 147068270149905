import { useContext } from 'react';
import { FormContext } from '../Form';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
} from "@chakra-ui/react";

export function TextareaInput(props) {
  const { 
    fieldname, 
    fieldlabel, 
    prettyname, 
    fieldtype='text', 
    fieldvalue,
    defaultvalue,
    register, 
    errors, 
    // reference,
    placeholder='Placeholder', 
    isReadOnly=false, 
    isRequired=false, 
    isDisabled=false, 
    maximLength=600, 
    minimLength=0, 
    onChange,
    onBlur,
    onClick,
    onFocus,
    rows=3,
    variant='primary',
    rules,
    // control
  } = props;

  const formContext = useContext(FormContext);
  const { form, handleFormChange } = formContext;

  const handleChange = (onChange) ? onChange : (event) => {}
  const handleBlur = (onBlur) ? onBlur : (event) => {}
  const handleClick = (onClick) ? onClick : (event) => {}
  const handleFocus = (onFocus) ? onFocus : (event) => {}

  const defaultFieldValue = (fieldvalue) ? fieldvalue : form[fieldname];
  const defaultFieldType = (fieldtype===undefined) ? 'text' : fieldtype

  return (
    <FormControl isInvalid={errors[fieldname]}>
      <FormLabel htmlFor={fieldname}>{fieldlabel}</FormLabel>
      <Textarea
        variant={variant}
        // ref={reference}
        // defaultValue={defaultvalue}
        defaultValue={defaultvalue}
        id={fieldname}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        type={defaultFieldType}
        name={fieldname}
        placeholder={placeholder}
        rows={rows}
        value={defaultFieldValue}
        {...register(fieldname, { 
            onChange: (e) => handleChange(e),
            onBlur: (e) => handleBlur(e),
            onClick: (e) => handleClick(e),
            onFocus: (e) => handleFocus(e),
            required: { 
            value: isRequired, 
            // message: rules?.required || `${prettyname||fieldname} is required.`, 
            // message: `${prettyname||fieldname} is required.`, 
            message: `${prettyname||fieldname} is required.`, 
          }, 
          maxLength: { 
            value: maximLength, 
            message: `${prettyname} must be maximum ${maximLength}`, 
          }, 
          minLength: { 
            value: minimLength, 
            message: `${prettyname} must be minimum ${minimLength}`, 
          }
        })} 
      />
      <FormErrorMessage>
        {errors[fieldname] && errors[fieldname].message}
      </FormErrorMessage>
    </FormControl>

  )
}

