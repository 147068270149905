import { listNavMenuByTeamMemberId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';

import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const fetchMenuItemsByTeamMemberId = async (id) => {
  if (id) {
    try {
      const gqlParams = {
        tmId: id
      };
      // console.warn('gqlParams: ', gqlParams);
      const response = await client
      .graphql({
        query: listNavMenuByTeamMemberId,
        variables: gqlParams
      })
      console.warn('response: ', response);
      const results = response.data.navMenuItemsByTeamMemberId.items;
      console.warn('results: ', results);
      return results;
    } catch (err) {
      return('fetchMenuItemsByTeamMemberId error: ', [err]);
    }
  } else {
    return('Missing email')
  }
}

fetchMenuItemsByTeamMemberId.propTypes = {
  id: PropTypes.node.isRequired,
};