import { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Create a context for the previous location
const PreviousLocationContext = createContext();

// Component to provide the PreviousLocationContext
export const PreviousLocationProvider = ({ children }) => {
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState(null);

  useEffect(() => {
    // Store the previous location before the location changes
    return () => {
      setPreviousLocation(location);
    };
  }, [location]);

  return (
    <PreviousLocationContext.Provider value={previousLocation}>
      {children}
    </PreviousLocationContext.Provider>
  );
};

PreviousLocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PreviousLocationContext };