import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { clientContactsByClientId } from '../../../graphql/custom_queries';

const client = generateClient();

export const fetchClientContactsByClientId = async (clientId) => {
  if (clientId) {
    try {
      const response = await client.graphql({
        query: clientContactsByClientId,
        variables: {
          clientId: clientId,
        },
      });
      const results = response.data.clientContactsByClientId.items;
      // console.info('fetchClientContactsByClientId RESULTS: ', results)
      return results;
    } catch (err) {
      return 'error fetchClientContactsByClientId: ' + err;
    }
  } else {
    return null;
  }
};

fetchClientContactsByClientId.propTypes = {
  clientId: PropTypes.string.isRequired,
};
