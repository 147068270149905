import PropTypes from 'prop-types';
import {
  Box,
  Text,
  HStack,
} from "@chakra-ui/react";
// import { ModuleBox } from '../Structural/ModuleBox';
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon';
import { ButtonQuickBooksLogin } from '../Form/Button/ButtonQuickBooksLogin';
import { AvailableIcons } from '../Icons/AvailableIcons';

export const QBLoginLogoutButtons = (props) => {
  const {
    isLoggedInQB,
    loginQB,
    logoutQB,
    displayType,
  } = props;
  
  let moduleP = '25px';
  let moduleML = '25px';
  let moduleMB = '0px';

  let moduleWidth = {
    base: '100px', //< 400 screen width
    sm: '360px', // total width of phone screen
    ipadv: '810px', // ipad vertical
    md: '810px', // ipad 7th gen target - 810 when with nav
    lg: '1086px', // macbook air and ipad - nav is 322, plus 25 for pad, then what is left is 347, 1366-347=1019
    lg2: '1350px', // bigger
    xl: '1400px',
    '2xl': '1920px'
  }

  if (displayType==='embedded') {
    moduleWidth = {
      base: '90%', //< 400 screen width
      // sm: '140px', // without being in a drawer
      sm: '90%', // total width of phone screen
      md: '90%', // ipad 7th gen target - 810 when with enu nav
      lg: '90%', 
      xl: '90%',
    };

    moduleP = '0px';
    moduleML = '0px';
    moduleMB = '25px';
  }

  return (
    <>
      <Box 
        // border={'1px solid pink'}
        w={moduleWidth}
        borderRadius='6px'
        p={moduleP}
        mt='25px'
        ml={moduleML}
        mb={moduleMB}
        bg='var(--dark-module-bg)'>
        
      {/* <ModuleBox border={'1px solid pink'} displayType={displayType}> */}
        {!isLoggedInQB ? (
          <ButtonQuickBooksLogin
            isDisabled={isLoggedInQB}
            type="button"
            onClick={loginQB}
            name='login_qb'
          />
        ) : (
          <ButtonQuaternaryWithIcon
            name='new'
            leftIcon='signout'
            iconsize='22px'
            isDisabled={!isLoggedInQB}
            onClick={logoutQB}
            value='Logout from QuickBooks'
          />
        )}
      {/* </ModuleBox> */}
      </Box>

      {!isLoggedInQB && (
        <Box 
        // border={'1px solid pink'}
        w={moduleWidth}
        borderRadius='6px'
        p={moduleP}
        mt='25px'
        ml={moduleML}
        mb={moduleMB}
        bg='var(--dark-module-bg)'>
          <HStack>
            <Box paddingTop={'5px'} paddingRight={'15px'}>
              <AvailableIcons boxSize={'35px'} color={'var(--error-red)'} iconRef={'alert'} />
            </Box>
            <Box>
              <Text textStyle={'alert-1'}>
                Please connect to QuickBooks to perform any QuickBooks related actions.
              </Text>
            </Box>
          </HStack>
        </Box>
      )}
    </>
  );
}

// Prop validation
QBLoginLogoutButtons.propTypes = {
  isLoggedInQB: PropTypes.bool.isRequired, // Boolean indicating QuickBooks login status
  loginQB: PropTypes.func.isRequired, // Function to handle QuickBooks login
  logoutQB: PropTypes.func.isRequired, // Function to handle QuickBooks logout
  displayType: PropTypes.string, // Display type for the component
};