import { createUploadedDocument } from '../../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const addFileToUploadedDocuments = async (params) => {
  if (params) {
    try {
      // console.log(' -- about to send to addFileToUploadedDocuments: ', params);

      const response = await client.graphql({
        query: createUploadedDocument,
        variables: { input: params }
      });

      // console.info('RESPONSE: ', response)

      const uploadedDocumentId = response?.data?.createSpkUploadedDocumentTbl.id;

      // console.info('uploadedDocumentId: ', uploadedDocumentId)

      return uploadedDocumentId;
    } catch (err) {
      return ('uploadDocument error: ', [err]);
    }
  } else {
    return null;
  }
};

addFileToUploadedDocuments.propTypes = {
  params: PropTypes.shape({
    bucket: PropTypes.string.isRequired,
    identityId: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
};
