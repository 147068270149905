import { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Text,
  Center,
  useDisclosure,
  IconButton,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
} from "@chakra-ui/react";

import { generateClient } from 'aws-amplify/api';
const client = generateClient();

// structural components
import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

//
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// graphql
import { fetchInspectionPoints } from '../graphqlCompnents/Inspection/fetchInspectionPoints.jsx';
import { fetchUnitCodeByQrCodeValue } from '../graphqlCompnents/Inspection/fetchUnitCodeByQrCodeValue.jsx';
import { fetchPrimaryUnitByUnitCode } from '../graphqlCompnents/Inspection/fetchPrimaryUnitByUnitCode.jsx';
// import { GetSignedURL } from '../graphqlCompnents/Unit/GetSignedURL';
import { fetchUnitsInspectedAtDivisionOnDate } from '../graphqlCompnents/Inspection/fetchUnitsInspectedAtDivisionOnDate.jsx';
import { fetchAllUnits } from '../graphqlCompnents/DJT/fetchAllUnits.jsx';
import { fetchPopularUnitsByDivision } from '../graphqlCompnents/DJT/fetchPopularUnitsByDivision.jsx';


import { onCreatePrimaryUnitInspection } from '../../graphql/custom_subscriptions.js';


import { UnitsToInspect } from './UnitsToInspect.jsx';
import { UnitInspectionForm } from './UnitInspectionForm.jsx';
import { InspectedUnits } from './InspectedUnits.jsx';

// screensize
// import { useScreenSize } from '../custom-hooks/useScreenSize.jsx';

import { todaysDateYMD } from '../functions/dateTime.jsx';


export const UnitInspections = () => {
  // console.log('LOADING UnitInspectionQueue - PARENT')

  // const navigate = useNavigate();

  // grab global context values
  const { store } = useContext(AppContext);
  // set the users current division id
  // these may need to be states that get updated all anytime store changes.
  const currentUserId = store?.userData?.id
  const inspectionStatuses = store?.inspectionStatuses
  const currentDivisionId = store?.userData?.divisionId
  // const inspectionQueue = store?.inspectionQueue
  // const screenSize = useScreenSize();
  // const currentScreenWidth = screenSize.width
  const userIsMobile = store?.userIsMobile||false;

  // const todayMDY = todaysDateYMD()
  // const todaySpelledOut = spellOutDate(todayMDY, 'phone-tight')

  const { isOpen: isInspectUnitOpen , onOpen: onInspectUnitOpen, onClose: onInspectUnitClose } = useDisclosure()

  const [ unitsData, setUnitsData ] = useState([]);
  const [ popularUnitsWithDetails, setPopularUnitsWithDetails] = useState([]);
  const [ displayedUnits, setDisplayedUnits ] = useState([]);
  const [ fullUnitsData, setFullUnitsData ] = useState([]);
  const [ unitsDataIsLoading, setIsUnitsDataLoading ] = useState(false);
  const [ refreshIndex, setRefreshIndex ] = useState(0);

  useEffect(() => {
    const fetchUnits = async () => {
      setIsUnitsDataLoading(true);
      try {
        // Fetch popular units by division
        let popularUnits = await fetchPopularUnitsByDivision(currentDivisionId, 10);

        // console.warn('popularUnits: ', popularUnits);
  
        // Fetch all units
        let units = await fetchAllUnits(null, 500);
        // console.log('unitsData: ', units);
        if (units?.items?.length > 0) {
          // Add the 'inspectedViaSearch' property to each item and sort by item.code
          const updatedItems = units.items
            .map((item) => ({
              ...item, // Spread the existing item properties
              inspectedViaSearch: true, // Add the new property
            }))
            .sort((a, b) => a.codeSortable.localeCompare(b.codeSortable)); // Sort by code
    
          // console.log('unitsData: ', updatedItems);
  
          // Ensure we update units data as before
          setUnitsData(updatedItems);
          setFullUnitsData(updatedItems);
  
          // Now create a lookup for the unitsData array
          const unitLookup = updatedItems.reduce((lookup, unit) => {
            lookup[unit.id] = unit;
            return lookup;
          }, {});
  
          // Map over popularUnits and combine with full unit details using the lookup
          const popularUnitsWithDetails = popularUnits.map((popularUnit) => {
            const matchingUnit = unitLookup[popularUnit.unitId]; // Use the lookup object for fast access
            
            return matchingUnit 
              ? { ...matchingUnit, popularity: popularUnit.popularity } 
              : null; // Return null if no match is found
          });
  
          // Clean up any nulls and set the resulting popular units with full details
          const popularUnitsCleaned = popularUnitsWithDetails.filter(unit => unit !== null);

          // Sort by popularity in descending order
          const sortedPopularUnits = popularUnitsCleaned.sort((a, b) => b.popularity - a.popularity);

          setDisplayedUnits(sortedPopularUnits);
  
          // Set the state with the detailed popular units
          setPopularUnitsWithDetails(sortedPopularUnits);

          
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsUnitsDataLoading(false);
      }
    };
  
    currentDivisionId && fetchUnits();
  }, [currentDivisionId, userIsMobile]);


  const [ queuedForInspectionId, setQueuedForInspectionId ] = useState(null)
  const [ initalInspectionId, setInitialInspectionId ] = useState(null)
  useEffect(() => {
    if (store?.inspectionStatuses && !isInspectUnitOpen) {
      setQueuedForInspectionId(store?.inspectionStatuses?.find((status) => status?.statusQuery==='inspection_queued')?.id)
      setInitialInspectionId(inspectionStatuses?.find((status) => status?.statusQuery==='initial_inspection')?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[store])
  
  // --- REACT-HOOK-FORM ---
  const { 
    setValue,
    register, 
    formState: { 
      errors,
    }} = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const [ unitsInspectedToday, setUnitsInspectedToday ] = useState([]);
  useEffect(() => {
    const fetchUnitsInspectedToday = async () => {
      try {
        const formattedDate = todaysDateYMD();
        const unitsInspectedToday = await fetchUnitsInspectedAtDivisionOnDate({
          divisionId: currentDivisionId, 
          searchDate: formattedDate});
        setUnitsInspectedToday(unitsInspectedToday);
        // console.log('unitsInspectedToday: ', unitsInspectedToday)
      } catch (err) {
        console.log(err);
      }
    };
  
    currentDivisionId && fetchUnitsInspectedToday();
  },[currentDivisionId, refreshIndex])

  useEffect(() => {
    if (currentDivisionId) {
      const today = todaysDateYMD();
      const divisionDate = `${currentDivisionId}#${today}`;
      const subscription = client
        .graphql({
          query: onCreatePrimaryUnitInspection,
          variables: {
            filter: { divisionDate: { eq: divisionDate } },
          },
        })
        .subscribe({
          next: (response) => {
            const newInspection = response?.data?.onCreateSpkPrimaryUnitInspectionTbl;
            if (newInspection) {
              // console.warn('New Inspection:', newInspection);
              setRefreshIndex(prev => prev + 1); // Increment to trigger useEffect
              // Append the new inspection to the array
              // setUnitsInspectedToday((prevInspections) => {
              //   // Optional: Check if newInspection is already in the array
              //   const isAlreadyAdded = prevInspections.some(
              //     (inspection) => inspection.id === newInspection.id
              //   );
                
              //   if (!isAlreadyAdded) {
              //     return [...prevInspections, newInspection];
              //   }
              //   return prevInspections;
              // });
            } else {
              console.warn('No data found in response or value is undefined');
            }
            // if (newInspection) {
            //   console.warn('New Inspection: ', newInspection);
            // } else {
            //   console.warn('No data found in response or value is undefined');
            // }
          },
          error: (error) => console.error('Messages subscription issue: ', error),
          complete: () => console.log('Done'),
        });

      return () => subscription.unsubscribe();
    }
  }, [currentDivisionId]);

  const [ inspectionParams, setInspectionParams ] = useState(null)


  const fetchUnitCodeByQrCode= async (data) => {
    const unitCode = await fetchUnitCodeByQrCodeValue(data)
    return unitCode
  }

  const [ updateInspectionQueue, setUpdateInspectionQueue ] = useState(false)
  const loadUnitInspection = async (unitData) => {
    // console.warn('unitData: ', unitData)
    if (!unitData) return

    // unitData.inspectedViaQR = true
    if (unitData.inspectedViaSearch === true) {
      setUpdateInspectionQueue(false)
      const inspectionPoints = await fetchInspectionPoints(unitData?.subType?.id)
      unitData.inspectionPoints = inspectionPoints

      const newUnitData = {
        inspectionPoints: inspectionPoints,
        unitId: unitData.id,
        code: unitData?.code,
        unit: {
          subType: {
            id: unitData?.subType?.id,
            name: unitData?.subType?.name,
          },
          type: {
            name: unitData?.type?.name,
          }
        },
        createdById: currentUserId,
      }

      handleOpenDrawer(newUnitData)
    } else {
      // if the status is available, then claim it
      if (unitData?.unitStatus?.status?.id===queuedForInspectionId || 
        unitData?.unitStatus?.status?.id===initalInspectionId || 
        unitData?.claimedById===currentUserId) {

          setUpdateInspectionQueue(true)
          // get the inspeciton points here and pass them down?
          const inspectionPoints = await fetchInspectionPoints(unitData?.unit?.subType?.id)
          unitData.inspectionPoints = inspectionPoints

          handleOpenDrawer(unitData)
      } else {
        if (unitData.inspectedViaQR === true) {
          // get the qr code value
          const qrCodeValue = unitData?.qrCodeValue
          const unitCode = await fetchUnitCodeByQrCode(qrCodeValue)
          // console.info('UNIT CODE: ', unitCode)
          const unit = await fetchPrimaryUnitByUnitCode(unitCode?.unitCode)
          const unitObj = unit[0]?.data?.primaryUnitByCode?.items[0]
          const unitSubTypeId = unitObj?.subTypeId
          // console.info('GOT UNIT OBJ: ', unitObj)
          // load up the inspection form for a manual inspeciton
          const inspectionPoints = await fetchInspectionPoints(unitSubTypeId)

          //inspectionParams.unit.subType.id
          const newUnitData = {
            inspectionPoints: inspectionPoints,
            unitId: unitObj.id,
            code: unitCode,
            unit: {
              subType: {
                id: unitSubTypeId,
                name: unitObj?.subType?.name,
              },
              type: {
                name: unitObj?.type?.name,
              }
            },
            createdById: currentUserId,
          }

          // console.log('unit obj data: ', newUnitData)
          // return
          handleOpenDrawer(newUnitData)
        }
      }
    }
  }

  const [ closeAfterOpening, setCloseAfterOpening ] = useState(false)
  useEffect(() => {
    // if isOpen and closeAfterOpening = true then trigger, otherwise toggle closeAfterOpening = true
    if (!isInspectUnitOpen && closeAfterOpening) {
      setCloseAfterOpening(false)
    } 
    (isInspectUnitOpen && !closeAfterOpening) && setCloseAfterOpening(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isInspectUnitOpen])

  const handleOpenDrawer = (unitData) => {
      setInspectionParams(unitData, onInspectUnitOpen())
  }

  const CustomCloseDrawerButton = () => {
    return(
      <IconButton 
        variant={'icononlybutton'}
        icon={<AvailableIcons boxSize={'22px'} iconRef={'close'} />} 
        onClick={onInspectUnitClose}
      />
    )
  }

  // this is what is causing the inspection to refresh

  // update mparent inspection queue data so when reloads, it hhas the updated data
  return (
    <>
    <Container as="form" pb='25px'>
      
      <Box pt='10px' pb='25px'>
        <Center>
          <Text as="span" mt={'6px'} mb={'3px'} color={'var(--dark-text-grey-1)'} textStyle='heading-4'>Inspections</Text><br/>
        </Center>
      </Box>

      <Tabs 
        borderColor='var(--dark-module-divider)' 
        variant='enclosed' 
        colorScheme='spikedarktabs' 
        // onChange={(index) => handleTabChange(index)}
        >
        <TabList>
          <Tab><Text>Units</Text></Tab>
          <Tab><Text>Inspected</Text></Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <UnitsToInspect
              currentUserId={currentUserId}
              loadUnitInspection={loadUnitInspection}
              register={register}
              errors={errors}
              userIsMobile={userIsMobile}
              setValue={setValue}
              currentDivisionId={currentDivisionId}
              unitsData={unitsData}
              popularUnitsWithDetails={popularUnitsWithDetails}
              displayedUnits={displayedUnits}
              fullUnitsData={fullUnitsData}
              unitsDataIsLoading={unitsDataIsLoading}
            />
          </TabPanel>
          <TabPanel>
            <InspectedUnits 
              unitsInspectedToday={unitsInspectedToday}
              currentUserId={currentUserId}
              userIsMobile={userIsMobile}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
        

    </Container>

    <ModuleDrawer
      onClose={onInspectUnitClose}
      isOpen={isInspectUnitOpen}
      bodyContent={
        <UnitInspectionForm 
          inspectionParams={inspectionParams} 
          updateInspectionParams={setInspectionParams}
          onClose={onInspectUnitClose} 
          drawerCloseButton={CustomCloseDrawerButton()}
          updateInspectionQueue={updateInspectionQueue}
        />
      }
      size={'full'}
      headerContent={''}
      footerContent={''}
    />

    </>

  )

  

}