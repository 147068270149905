import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { increaseDJTUnitPopularity } from '../../../graphql/custom_mutations';

const client = generateClient();

export const updateUnitPopularity = async (divisionId, unitId) => {
  if (divisionId && unitId) {
    try {
      const gqlParams = {
        divisionId, 
        unitId, 
        divisionIdUnitId: `${divisionId}#${unitId}`,
      };
  
      const response = await client.graphql({
        query: increaseDJTUnitPopularity,
        variables: gqlParams,
      });
      console.log('updateUnitPopularity response: ', response);
      
      const results = response.data.IncreaseDJTUnitPopularity.popularity;
      console.log('updateUnitPopularity results: ', results);
      return results;
    } catch (err) {
      return 'error updateUnitPopularity: ' + err;
    }
  } else {
    return null;
  }
};

updateUnitPopularity.propTypes = {
  divisionUnit: PropTypes.string.isRequired,
};
