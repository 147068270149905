import { listUnitDocumentsByUnitId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const fetchUnitDocumentsByUnitId = async (unitId) => {
  if (!unitId) return;

  try {
    const gqlParams = {
      unitId: unitId
    };

    const response = await client.graphql({
      query: listUnitDocumentsByUnitId,
      variables: gqlParams
    });

    const results = response.data.unitDocumentsByUnitId.items
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    return results;
  } catch (err) {
    return ('error fetchUnitDocumentsByUnitId: ', [err]);
  }
};

fetchUnitDocumentsByUnitId.propTypes = {
  unitId: PropTypes.string.isRequired,
};
