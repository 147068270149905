import { createClientPO } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const addClientPO = async (props) => {
  if (!props) return;

  try {
    const variables = {
      input: props
    };

    const response = await client.graphql({
      query: createClientPO,
      variables
    });

    const results = response.data.createSpkDJTPOTbl.id;

    return results;
  } catch (err) {
    return ('error addClientPO: ', [err]);
  }
}

addClientPO.propTypes = {
  props: PropTypes.object.isRequired,
};
