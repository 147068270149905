import { useState, useEffect, useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useBreakpointValue } from '@chakra-ui/react'
import { AppContext } from '../AppContext.jsx';

// chakra ui base components
import {
  Box,
  Text,
  Button,
  IconButton,
  HStack,
} from '@chakra-ui/react';

// utility functions
import { formatDateFn } from '../functions/dateTime.jsx';

// icons
import { DeleteIcon } from '@chakra-ui/icons'

// structural components
import { ModuleBoxHStack } from '../Structural/ModuleBoxHStack.jsx';
import { ModuleBox } from '../Structural/ModuleBox.jsx';

// form components imports
import { FormSelectClearable } from '../Form/Select/FormSelectClearable.jsx';
import { DateInput } from '../Form/Input/DateInput.jsx';

// graphql component imports
import { fetchContactCompaniesByDivision } from '../graphqlCompnents/DJT/fetchContactCompaniesByDivision.jsx';

export const SearchDailyJobTicketsByClientByDate = (props) => {

  const {
    control,
    register,
    onChange,
    startDate,
    startDateOnChange,
    endDate,
    endDateOnChange,
    endDateDisabled=true,
    clientSelected,
    djtStartDate,
    djtEndDate,
    clientIconOnClick,
    startDateIconOnClick,
    endDateIconOnClick,
  } = props;

  const [ customersSearch, setCustomersSearch ] = useState(null)
  // const [ clientSelected, setClientSelected ] = useState(null)

  const { store } = useContext(AppContext);
  const currentDivisionId = store?.userData?.divisionId

  const getCustomers = async (divisionId) => {
    const customers = await fetchContactCompaniesByDivision(divisionId)
    setCustomersSearch(customers)
  }

  useEffect(() => {
    (currentDivisionId!==undefined) && getCustomers(currentDivisionId);
  }, [currentDivisionId]);

  const searchHeaderTextClass = useBreakpointValue({
    sm: 'dark-sfpro-heading-2',
    md: 'dark-sfpro-heading-2',
    lg: 'dark-sfpro-heading-1',
    xl: 'dark-sfpro-heading-1',
    '2xl': 'dark-sfpro-heading-1'
  })
  
  // const searchHeaderSelectWidth = useBreakpointValue({
  //   sm: '25%',
  //   md: '29%',
  //   lg: '33%',
  //   xl: '40%',
  //   '2xl': '45%'
  // })

  const searchHeaderSelectWidth = useBreakpointValue({
    sm: '35%',
    md: '55%',
    lg: '55%',
    xl: '55%',
    '2xl': '55%'
  })

  const CustomDateButton = forwardRef(({ value, onClick, isDisabled }, ref) => (
    <Button 
      isDisabled={isDisabled}
      variant={'calendarbutton'} 
      onClick={onClick} 
      ref={ref}>
        {value}
    </Button>
  ));

  CustomDateButton.displayName = 'CustomDateButton';

  CustomDateButton.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
  }

  return (
    <>
    {/* module wrapper for search daily job tickets */}
    <ModuleBox>
      <HStack spacing={'0px'} alignItems={'start'}>
        {/* search text */}
        <Box><Text className={searchHeaderTextClass}>Search</Text></Box>
        {/* select */}
        <Box pl={'20px'} w={searchHeaderSelectWidth} >
          <FormSelectClearable
            fieldlabel=''
            placeholder='All clients...'
            isDisabled={false}
            optionsArray={
              customersSearch?.map((options) => ({ 
                label: options.label, value: options.value
              }))
            }
            selectedoption={clientSelected}
            // onChange={(event, action) => onCustomerChanged(event, action)}
            onChange={onChange}
            control={control}
            fieldname="selectCustomer"
            id="selectCustomer"
            isClearable={true}
            isSearchable={true}
            register={register}
          />
        </Box>

        {/* start date */}
        <Box pl={'20px'}>
          <DateInput 
            name='searchDate'
            dateFormat="MM/dd/yyyy"
            selected={startDate}
            onChange={startDateOnChange}
            customInput={<CustomDateButton />}
          />
        </Box>
          <Text color={'var(--light-text-white)'} textStyle='text-6' pt={'12px'} pl={'10px'} pr={'10px'} mr={'0px'}>to</Text>
        {/* end date of date range */}
        <Box pl={'0px'}>
          <DateInput
            name='endDate'
            dateFormat="MM/dd/yyyy"
            selected={endDate}
            onChange={endDateOnChange}
            customInput={<CustomDateButton 
            isDisabled={endDateDisabled} />}
          />
        </Box>
      </HStack>
    </ModuleBox>

    {/* module wrapper for delete filtered search parameters */}
    {(clientSelected || djtStartDate || djtEndDate ) && <ModuleBoxHStack>
        {/* client */}
        {(clientSelected?.label!==null && clientSelected?.label!=='' && clientSelected?.label!==undefined) && <Box paddingRight={'20px'}><IconButton 
          variant={'icononlybutton'}
          icon={<DeleteIcon />} 
          onClick={clientIconOnClick}/>{clientSelected?.label}</Box>
        }
        {/* start date */}
        {((djtStartDate===null || djtStartDate==='') && (djtEndDate===null || djtEndDate==='')) 
          ? <></> 
          : (djtStartDate!==null && djtStartDate!=='' && (djtEndDate===null || djtEndDate==='')) 
            ? <Box paddingRight={'20px'}>
                <IconButton 
                  variant={'icononlybutton'}
                  icon={<DeleteIcon />} 
                  onClick={startDateIconOnClick}/>{formatDateFn(startDate, 'mdy', '/')}
                  {/* onClick={startDateIconOnClick}/>{formatDateFn(djtStartDate, 'mdy', '/')} */}
              </Box> 
            : <Box paddingRight={'20px'}>
                <IconButton  
                  variant={'icononlybutton'}
                  isDisabled={true}
                  icon={<DeleteIcon />}/>{formatDateFn(startDate, 'mdy', '/')}
              </Box>
        }
        {/* end date */}
        {(djtEndDate!==null && djtEndDate!=='') 
          && <Box paddingRight={'20px'}>
              <IconButton 
                variant={'icononlybutton'}
                icon={<DeleteIcon />} 
                onClick={endDateIconOnClick}/>{formatDateFn(endDate, 'mdy', '/')}
              </Box>
        }
      </ModuleBoxHStack>}
    </>
  )
}

// PropTypes validation for the main component
SearchDailyJobTicketsByClientByDate.propTypes = {
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  startDateOnChange: PropTypes.func.isRequired,
  endDate: PropTypes.instanceOf(Date),
  endDateOnChange: PropTypes.func.isRequired,
  endDateDisabled: PropTypes.bool,
  clientSelected: PropTypes.object,
  djtStartDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  djtEndDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  clientIconOnClick: PropTypes.func.isRequired,
  startDateIconOnClick: PropTypes.func.isRequired,
  endDateIconOnClick: PropTypes.func.isRequired,
};

// SearchDailyJobTicketsByClientByDate.CustomDateButton.propTypes = {
//   value: PropTypes.string,
//   onClick: PropTypes.func,
//   isDisabled: PropTypes.bool,

// }