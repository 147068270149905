import { fetchMappedDepartments } from '../../../graphql/custom_queries';

import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const listAllMappedDepartments = async () => {
  try {
    const gqlParams = {
      allSort: "all"
    }
    const response = await client
    .graphql({
      query: fetchMappedDepartments,
      variables: gqlParams
    })
    // console.log('response: ', response)
    const results = response.data.allISolvedMappedDepartments.items;
    return results;
  } catch (err) {
    return('listAllMappedDepartments error: ', [err]);
  }

}