import React, { useEffect, useState, useContext, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Button,
  Text,
  Flex,
  Spacer,
  Center,
  Table,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  HStack,
  Spinner,
  useDisclosure,
  useBreakpointValue,
  TableContainer,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  Badge,
} from "@chakra-ui/react";

// screensize
import { useScreenSize } from '../custom-hooks/useScreenSize.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// generic functions
import { formatCurrency } from '../functions/currency.jsx';
import { isTimeExceeded, spellOutDate, awsFormatUnknownDate,
  todaysDateYMD, getPreviousDate } from '../functions/dateTime.jsx';
import { stripToNumbersAndDecimal } from '../functions/number.jsx';

// Buttons
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';

// structural components
import { ModuleBox } from '../Structural/ModuleBox.jsx';
import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';

// imported components
import { CameraUploadStatement } from './CameraUploadStatement.jsx';
import { StatementDetail } from './StatementDetail.jsx';

// graphql
// import { fetchStatementsByStatusTab } from '../graphqlCompnents/Statement/fetchStatementsByStatusTab.jsx';
import { fetchAllChartOfAccounts } from '../graphqlCompnents/Statement/fetchAllChartOfAccounts.jsx';
import { updateChartOfAccounts } from '../graphqlCompnents/Statement/updateChartOfAccounts.jsx';
import { addChartOfAccounts } from '../graphqlCompnents/Statement/addChartOfAccounts.jsx';
import { addCreditCardTeamMemberMap } from '../graphqlCompnents/Statement/addCreditCardTeamMemberMap.jsx';
import { updateCreditCardTeamMember } from '../graphqlCompnents/Statement/updateCreditCardTeamMember.jsx';
import { fetchAllCreditCardTeamMemberMaps } from '../graphqlCompnents/Statement/fetchAllCreditCardTeamMemberMaps.jsx';
import { fetchAllTeamMembers } from '../graphqlCompnents/Statement/fetchAllTeamMembers.jsx';
import { fetchPlaidInstitutionsByUser } from '../graphqlCompnents/Statement/fetchPlaidInstitutionsByUser.jsx';
import { fetchPlaidPendingTransactions } from '../graphqlCompnents/Statement/fetchPlaidPendingTransactions.jsx';
// import { updatePendingTransaction } from '../graphqlCompnents/Statement/updatePendingTransaction.jsx';
import { fetchPlaidPostedTransactionsByMatched } from '../graphqlCompnents/Statement/fetchPlaidPostedTransactionsByMatched.jsx';
import { fetchPlaidPendingTransactionsByTeamMember } from '../graphqlCompnents/Statement/fetchPlaidPendingTransactionsByTeamMember.jsx';
import { fetchAccountsByTeamMember } from '../graphqlCompnents/Statement/fetchAccountsByTeamMember.jsx';

// user queries
// import { fetchUserTransactions } from '../graphqlCompnents/Statement/fetchUserTransactions.jsx';
// import { fetchInstantNotificationsByLastFour } from '../graphqlCompnents/Statement/fetchInstantNotificationsByLastFour.jsx';  //lastFour, isMatched, limit

// quickbooks
import { QuickBooksAuth } from '../QuickBooks/QuickBooksAuth.jsx';
import { QBLoginLogoutButtons } from '../QuickBooks/QBLoginLogoutButtons.jsx';
import { getChartOfAccounts } from '../QuickBooks/getChartOfAccounts.jsx';

// Plaid
import { PlaidAuth } from '../Plaid/PlaidAuth.jsx';
import { getTransactions } from '../Plaid/getTransactions.jsx';
import { syncTransactions } from '../Plaid/syncTransactions.jsx';

// S3
// import { Storage } from 'aws-amplify';

export const Receipt = () => {
  // if phone, show the small version
  const screenSize = useScreenSize();
  const currentScreenWidth = screenSize.width
  
  const { 
    isOpen: isCameraUploadStatementOpen , 
    onOpen: onCameraUploadStatementOpen, 
    onClose: uploadReceiptClose 
  } = useDisclosure()

  const { 
    isOpen: isStatementDetailOpen , 
    onOpen: onStatementDetailOpen, 
    onClose: statementDetailClose 
  } = useDisclosure()

  const onCameraUploadStatementClose = () => {
    handleRefresh()
    uploadReceiptClose()
  }
  const onStatementDetailClose = () => {
    handleRefresh()
    statementDetailClose()
  }
  const [ refreshIndex, setRefreshIndex ] = useState(0);
  const handleRefresh = (index) => {
    setNextToken('')
    setNextNextToken('')
    setStatements([])
    setFilteredData([])
    
    setRefreshIndex(prev => prev + 1); // Increment to trigger useEffect
    setValue('searchInput', '')
    console.log(`Tab index ${index} clicked, refreshing...`);
  };

  const [ selectedStatement, setSelectedStatement ] = useState({})
  const handleOpenDrawer = (type, statementObject) => {
    // console.log('id: ', id)
    // console.log('type: ', type)
    // id of djt to view, edit or duplicate
    // type of action: new djt, duplicate djt, view djt, edit djt

    // setDjtParentId(parentId)
    // setDjtIsParent(isParent)

    if (type==='new') {
      onCameraUploadStatementOpen()
    }

    if (type==='detail') {
      // setStatementId(id, onStatementDetailOpen())
      setSelectedStatement(statementObject, onStatementDetailOpen())
    }

    // if (type==='view') {
    //   setDjtId(id, onViewDjtOpen())
    // }

    // if (type==='edit') {
    //   // setDjtIsParent(isParent)
    //   setDjtId(id, onEditDjtOpen())
    // }
    
  }

  const { control, register, handleSubmit, setValue, formState: { errors, isSubmitting } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    // reValidateMode: 'onBlur',
    defaultValues: {}
  });
  
  const onError = (errors, e) => console.log(errors, e);

  // grab global context values
  const { store } = useContext(AppContext);

  const currentTeamMemberName = store?.userData?.name
  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id
  // console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)
  const currentUserIsGlobalAdmin = store?.userData?.isGlobalAdmin||false

  // QuickBooks environment
  const qbEnv = store?.qbEnvironment;
  // Plaid environment
  // const plaidEnv = store?.plaidEnvironment;
  // const hostname = window.location.hostname;
  // const plaidEnv = hostname === 'localhost'
  //   ? 'sandbox' // When on localhost
  //   : 'production'; // For production domain
  // console.log('plaidEnv: ', plaidEnv)
  const plaidEnv = 'production';

  const [ userData, SetUserData ] = useState({})
  useEffect(() => {
    if (currentTeamMemberName && currentTeamMemberId && currentUserIsGlobalAdmin) {
      SetUserData({
        name: currentTeamMemberName,
        id: currentTeamMemberId,
        isGlobalAdmin: currentUserIsGlobalAdmin
      })
    }
  },[currentTeamMemberName, currentTeamMemberId, currentUserIsGlobalAdmin]);

  // const [ showResultsSpinner, setShowResultsSpinner ] = useState(false)  
  const showResultsSpinner = false;
  const [ currentTab, setCurrentTab ] = useState(0)
  const [ currentTabName, setCurrentTabName ] = useState('Inbox')
  const [ previousTabName, setPreviousTabName ] = useState('Inbox')
  const handleTabChange = async (tab) => {
    
    setCurrentTab(tab)
    let currentTabNames = ['Inbox', 'Archived']
    setCurrentTabName(currentTabNames[tab])
    console.log('currentTabName: ', currentTabNames[tab])

    if (currentTabNames[tab] !== previousTabName) {
      console.log('Tab changed, resetting...')
      setNextToken('')
      setNextNextToken('')
      setStatements([])
      setFilteredData([])
      setPreviousTabName(currentTabNames[tab])
    }
  }

  const searchHeaderTextClass = useBreakpointValue({
    sm: 'dark-sfpro-heading-2',
    md: 'dark-sfpro-heading-2',
    lg: 'dark-sfpro-heading-1',
    xl: 'dark-sfpro-heading-1',
    '2xl': 'dark-sfpro-heading-1'
  })

  // const getSignedUrl = async (uploadedDocument) => {
  //   const bucketName = uploadedDocument?.bucket;
  //   // console.log('fetching bucketName: ', bucketName)
  //   // get document data
  //   // const signedUrl = await Storage.get(uploadedDocument?.key, {
  //   //   level: "protected",
  //   //   identityId: uploadedDocument?.identityId,
  //   //   bucket: bucketName,
  //   // });
  //   // console.info('signedUrl: ', signedUrl)
  //   // return signedUrl
  //   // setUnitImage(signedUrl)
  // }

  const [ nextToken, setNextToken ] = useState('')
  const [ nextNextToken, setNextNextToken ] = useState('')
  // const searchLimit = 25;
  const [ statements, setStatements ] = useState([])
  

  // handle for when the tab is not inbox or archived
  // const fetchAndProcessStatements = async (fetchFunction, setStatements, setFilteredData) => {
  //   try {
  //       setShowResultsSpinner(true);

  //       const results = await fetchFunction();
  //       console.log('results: ', results);
        
  //       if (!results) {
  //           console.error('No results returned from fetchFunction');
  //           setShowResultsSpinner(false);
  //           return;
  //       }

  //       setNextToken(results?.nextToken);

  //       // Safely spread items if they exist, otherwise use an empty array
  //       let allResults = [...statements, ...(results?.items || [])];

  //       // Process each statement to add the signedUrl
  //       const statementsWithSignedUrl = await Promise.all(
  //           allResults.map(async (statement) => {
  //               const signedUrl = await getSignedUrl(statement.uploadedDocument);
  //               return { ...statement, signedUrl };
  //           })
  //       );

  //       let statementsWithSignedUrlOrdered = statementsWithSignedUrl.sort((a, b) => 
  //           a.submittedDate > b.submittedDate ? -1 : 1
  //       );

  //       console.log('statementsWithSignedUrlOrdered: ', statementsWithSignedUrlOrdered);

  //       setStatements(statementsWithSignedUrlOrdered);
  //       setFilteredData(statementsWithSignedUrlOrdered);

  //   } catch (error) {
  //       console.error('Error in fetchAndProcessStatements: ', error);
  //   } finally {
  //       setShowResultsSpinner(false);
  //   }
  // };

  const handleScroll = (e) => {
    if (!nextToken) return;
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      console.log('reached the bottom of the scrollbox: ', nextToken);
      setNextNextToken(nextToken)
    }
  };

  useEffect(() => {
    if (currentTeamMemberId) {
      if (currentUserIsGlobalAdmin) {
        if (currentTabName === 'Inbox' || currentTabName === 'Archived') {
          
          // fetchAndProcessStatements(() => fetchStatementsByStatusTab(currentTabName, searchLimit, nextNextToken), setStatements, setFilteredData);
          
        }
      } else {

        // no longer using statements as the precursor to statement tracking
        // fetchAndProcessStatements(() => fetchStatementsByTeamMember(currentTeamMemberId), setStatements, setFilteredData);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamMemberId, refreshIndex, currentUserIsGlobalAdmin, nextNextToken, currentTabName]);

  // this needs to take in ALL statemwents from both inbox and archive
  // then flag the archive version as already saved so they can delete the new one.
  const groupStatementsByKeys = (statements) => {
    const grouped = new Map();
  
    statements.forEach(statement => {
      const key = `${statement.vendorName}-${statement.statementTotal}-${statement.invoiceReceiptDate}`;
      if (!grouped.has(key)) {
        grouped.set(key, []);
      }
      grouped.get(key).push(statement);
    });
  
    return grouped;
  };

  const generateRows = (statements) => {
    if (!statements[0]) return [];

    console.log('first statement:', statements[0])
    const groupedStatements = groupStatementsByKeys(statements);
  
    const rows = [];

    groupedStatements.forEach((group, groupKey) => {
      const isDuplicate = group.length > 1;
      
      group.forEach((item, index) => {
        // console.log('item:', item);

        item.isDuplicate = isDuplicate;
        const total = formatCurrency(item.statementTotal) || '';
        const amountPaid = formatCurrency(item.amountPaid) || item.amountPaid || '';
        let hasIssue = (total === '' && amountPaid !== '') ? true : false;
        const itemSubTotal = stripToNumbersAndDecimal(item.subTotal) || '';
        const itemTax = stripToNumbersAndDecimal(item.tax) || '';
        const itemTotal = stripToNumbersAndDecimal(item.statementTotal) || '';
        if (itemSubTotal && itemTax && itemTotal) {
          const totalAmountComputed = (parseFloat(itemSubTotal) + parseFloat(itemTax)).toFixed(2); 
          if (totalAmountComputed !== itemTotal) {
            // console.error(`Total amount mismatch for statement ${total} ${totalAmountComputed}`);
            hasIssue = true;
          }
        }
        const uniqueKey = item.qbChartOfAccountsId || `${groupKey}_${index}`;
        let badgeColor = (item.statusKey === 'Ready') ? 'green' : 'yellow';
        let amountToDisplay = (total && total !== '$0.00') ? total : (amountPaid || '$');
        let displayName = (item?.vendorMap?.qbVendorId) ? item?.vendorMap?.qbVendor?.qbDisplayName : item?.vendorMap?.vendorName;
        const isMapped = (item?.vendorMap?.qbVendorId) ? false : true;
        let invoiceReceiptDate = item?.invoiceReceiptDate || '';
        const timeExceeded = isTimeExceeded(item?.createdAt, 5);
        const isProcessing = (!item?.documentKey && !item?.submittedDate) ? true : false;
        if (isProcessing) {
          if (!timeExceeded) {
            displayName = "Processing...";
            amountToDisplay = "Processing...";
            invoiceReceiptDate = "Processing...";
          } else {
            displayName = "Insufficient Data";
            amountToDisplay = "";
            invoiceReceiptDate = "";
          }
        }
        item.fixedTotal = itemTotal

        rows.push(
          <Fragment key={`frag_${uniqueKey}`}>
            <Tr key={`tr_${uniqueKey}`} onClick={() => handleOpenDrawer('detail', item)} cursor="pointer">
              <Td onClick={() => handleOpenDrawer('detail', item)} cursor="pointer">
                <Box py={'15px'}>
                  <Flex justify="space-between">
                    <Text as="span" textStyle='heading-3'>{displayName || 'Unknown Vendor'}</Text>
                    <Text as="span" textStyle='heading-3'>{(invoiceReceiptDate) ? spellOutDate((awsFormatUnknownDate(item?.invoiceReceiptDate || '')), 'apple-fy') : 'Empty Date'}</Text>
                  </Flex>
                  <Flex justify="space-between" mt={2}>
                    {(hasIssue) 
                      ? <Text as="span" textStyle='text-6' color='red'>{amountToDisplay}</Text> 
                      : <Text as="span" textStyle='text-6'>{amountToDisplay}</Text>}
                    
                    <Box>
                      {(isMapped) && <Badge variant="solid" borderRadius={'6px'} marginRight={'10px'} paddingTop={'5px'} paddingBottom={'5px'} colorScheme={'red'} textAlign="center" display="inline-block" minWidth="60px">Map/Add</Badge>}
                      {(isDuplicate) && <Badge variant="solid" borderRadius={'6px'} marginRight={'10px'} paddingTop={'5px'} paddingBottom={'5px'} colorScheme={'orange'} textAlign="center" display="inline-block" minWidth="60px">Duplicate</Badge>}
                      <Badge variant="solid" borderRadius={'6px'} paddingTop={'5px'} paddingBottom={'5px'} colorScheme={badgeColor} textAlign="center" display="inline-block" minWidth="60px">{item.statusKey || 'Unknown'}</Badge>
                    </Box>
                  </Flex>
                </Box>
              </Td>
            </Tr>
            <Tr key={`divider_${uniqueKey}`}><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
          </Fragment>
        );
      });
    });
  
    return rows;
  };

  const generateChartOfAccountsRows = (accounts, mappedtms) => {
    return accounts?.map((item, index) => {
      const mappedMember = mappedtms.find(mapping => mapping.qbChartOfAccountsId === item.qbChartOfAccountsId);

      let selectedTeamMember = '';
      if (mappedMember && selectedTeamMemberMap[item?.qbChartOfAccountsId] && selectedTeamMemberMap[item?.qbChartOfAccountsId] !== mappedMember?.teamMemberId) {
        const matchedTeamMember = allTeamMemberNames.find(tm => tm.id === selectedTeamMemberMap[item?.qbChartOfAccountsId]);
        const matchedGoesBy = (matchedTeamMember?.goesBy) ? `"${matchedTeamMember?.goesBy}"` : '';
        const matchedName = `${matchedTeamMember?.firstName} ${matchedGoesBy} ${matchedTeamMember?.lastName}`;
        selectedTeamMember = {label: matchedName, value: `${matchedTeamMember?.id}`};
      } else if (mappedMember?.teamMember) {
        const mappedGoesBy = (mappedMember?.teamMember?.goesBy) ? `"${mappedMember?.teamMember?.goesBy}"` : '';
        const mappedName = `${mappedMember?.teamMember?.firstName} ${mappedGoesBy} ${mappedMember?.teamMember?.lastName}`;
        selectedTeamMember = {label: mappedName, value: mappedMember?.teamMemberId};
      } else if (selectedTeamMemberMap[item?.qbChartOfAccountsId]) {
        const matchedTeamMember = allTeamMemberNames.find(tm => tm.id === selectedTeamMemberMap[item?.qbChartOfAccountsId]);
        const matchedGoesBy = (matchedTeamMember?.goesBy) ? `"${matchedTeamMember?.goesBy}"` : '';
        const matchedName = `${matchedTeamMember?.firstName} ${matchedGoesBy} ${matchedTeamMember?.lastName}`;
        selectedTeamMember = {label: matchedName, value: `${matchedTeamMember?.id}`};
        // selectedTeamMember = {label: `${matchedTeamMember?.firstName} ${matchedTeamMember?.lastName}`, value: `${matchedTeamMember?.id}`};
      }

      // let showButtonCell = false;
      const teamMemberIsMapped = (selectedTeamMemberMap[item?.qbChartOfAccountsId]?.length>0) ? true : false
      const selectedTeamMemberCOCId = selectedTeamMemberMap[item?.qbChartOfAccountsId]
      const mappedTeamMemberCOCId = mappedMember?.qbChartOfAccountsId
      const accountCOCId = item?.qbChartOfAccountsId
      const mappedTeamMemberId = mappedMember?.teamMemberId

      return (
        <Fragment key={`frag_${index}`}>
          <Tr key={`tr_${index}`}>
            <Td>{item?.qbChartOfAccountsName}</Td>
            <Td>
              <Box>
                <FormSelectSimple
                  key={`teamMember_${accountCOCId}`}
                  register={register}
                  control={control}
                  errors={errors}
                  isRequired={true}
                  fieldName={`selectTeamMember_${accountCOCId}`}
                  placeholder={'Select Team Member'}
                  optionsArray={allTeamMemberNames?.map((tm, index) => ({
                    key: index,
                    value: tm.id,
                    label: tm.goesBy?.length>0 
                      ? `${tm.firstName} "${tm.goesBy}" ${tm.lastName}` 
                      : `${tm.firstName} ${tm.lastName}`
                  }))}
                  onChange={(e) => updateSelectedTeamMemberMap(accountCOCId, e?.value, mappedTeamMemberCOCId)}
                  selectedoption={selectedTeamMember}
                />
              </Box>
            </Td>
            <Td>
              {(teamMemberIsMapped) && 
                <Box display={'flex'} gap={'5px'}>
                  {((selectedTeamMemberCOCId 
                      && mappedTeamMemberCOCId !== accountCOCId) 
                      || mappedTeamMemberId !== selectedTeamMemberMap[accountCOCId])
                    && 
                    <ButtonPrimaryPlain 
                      type="button"
                      name='updateTeamMemberMap'
                      value={isSubmitting ? 'Updating...' : 'Update'}
                      isDisabled={isSubmitting===true}
                      onClick={handleSubmit(() =>
                        updateTeamMemberMap(accountCOCId)
                      )}
                    />
                  }
                  {((selectedTeamMemberCOCId 
                      && mappedTeamMemberCOCId !== accountCOCId) 
                      || mappedTeamMemberId !== selectedTeamMemberMap[accountCOCId])
                    && <ButtonPrimaryPlain 
                    type="button"
                    name='resetTeamMemberMap'
                    value={'Cancel'}
                    isDisabled={isSubmitting===true}
                    onClick={handleSubmit(() =>
                      removeTeamMemberMapEntry(accountCOCId)
                    )}
                  />}
                </Box>
              }
            </Td>
          </Tr>
          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
        </Fragment>
      );
    });
  }

  const removeTeamMemberMapEntry = (qbChartOfAccountsId) => {
    setValue(`selectTeamMember_${qbChartOfAccountsId}`, {label: '', id: ''})
    setSelectedTeamMemberMap((prev) => {
      const updatedMap = { ...prev };
      delete updatedMap[qbChartOfAccountsId];
      return updatedMap;
    });
  };

  const [ selectedTeamMemberMap, setSelectedTeamMemberMap ] = useState([])


  const updateSelectedTeamMemberMap = (qbChartOfAccountsId, selectedTeamMemberId) => {
    setSelectedTeamMemberMap((prev) => ({
      ...prev,
      [qbChartOfAccountsId]: selectedTeamMemberId
    }));
  }

  const updateTeamMemberMap = async (accountId) => {
    const isExistingMapping = mappedTeamMembers.find(mapping => mapping.qbChartOfAccountsId === accountId);
    const input = {
      qbChartOfAccountsId: accountId,
      teamMemberId: selectedTeamMemberMap[accountId],
      isActive: true,
      isDefault: true
    };
    if (isExistingMapping) {
      // update ddb then refresh
      try {
        await updateCreditCardTeamMember(input);
        fetchMappedTeamMembers();
      } catch (error) {
        console.error('Error updating DynamoDB:', error);
      }
    } else {
      // create new mapping
      try {
        await addCreditCardTeamMemberMap(input);
        fetchMappedTeamMembers();
      } catch (error) {
        console.error('Error creating DynamoDB:', error);
      }
    }
  }

  // query creditCardTeamMemberByTeamMemberId to get card numbers
  // then query plaidCreditCardTransactionByAccountNumber for transactions

  const [ filteredData, setFilteredData ] = useState([])
  // useEffect(() => {
  //   (filteredData) && console.warn('filteredData changed: ', filteredData)
  // },[filteredData])

  const filterData = (event) => {
    if (!event) {
      return statements;
    }
    
    const searchTerm = event?.target?.value
    const lowercasedTerm = searchTerm.toLowerCase();
    const tempData =  statements.filter(item =>
      item?.vendorName?.toLowerCase().includes(lowercasedTerm) ||
      item?.statementTotal?.toLowerCase().includes(lowercasedTerm) ||
      item?.vendorMap?.qbVendor?.qbDisplayNameLower.includes(lowercasedTerm)
    );
    // console.log("tempData: ", tempData)
    setFilteredData(tempData);
  }

  const formSubmit = async (event) => {
    console.log('event: ', event)
    // if (!data.selectDivision) return
    // console.log('Form Values:', data);
  }

  const [ dynamodbChartOfAccounts, setDynamoDBChartOfAccounts ] = useState([])
  const [ mappedTeamMembers, setMappedTeamMembers ] = useState([])
  useEffect(() => {
    (mappedTeamMembers) && console.log('mappedTeamMembers: ', mappedTeamMembers)
  },[mappedTeamMembers])
  const [ allTeamMemberNames, setAllTeamMemberNames ] = useState([])
  
  const fetchAllTeamMemberNames = async () => {
    const fetchedTeamMembers = await fetchAllTeamMembers();
    // console.log('fetchedTeamMembers: ', fetchedTeamMembers)
    const teamMembers = [{ id: '00000', firstName: 'Select Team Member', goesBy: '', lastName: '' }, ...fetchedTeamMembers];
    setAllTeamMemberNames(teamMembers);
  };

  const fetchMappedTeamMembers = async (tmId) => {
    if (currentUserIsGlobalAdmin) {
      const mappedTeamMembers = await fetchAllCreditCardTeamMemberMaps();
      console.warn('mappedTeamMembers: ', mappedTeamMembers);
      setMappedTeamMembers(mappedTeamMembers);
      return mappedTeamMembers;
    } else {
      //fetchCreditCardByTeamMemberId
      const mappedTeamMembers = await fetchAccountsByTeamMember(tmId);
      console.warn('card of team member: ', mappedTeamMembers);
      setMappedTeamMembers(mappedTeamMembers);
      return mappedTeamMembers;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const mappedTeamMembers = await fetchAccountsByTeamMember(plaidEnv, currentTeamMemberId);
      console.warn('cards of team member: ', mappedTeamMembers);
      setMappedTeamMembers(mappedTeamMembers);
      return mappedTeamMembers;
    }
    if (currentTeamMemberId) {
      fetchData()
    }
  },[currentTeamMemberId, plaidEnv])

  const fetchDynamoDbChartOfAccounts = async (mappedTeamMembers) => {
    const ddbChartOfAccounts = await fetchAllChartOfAccounts();
    console.warn('ddbChartOfAccounts: ', ddbChartOfAccounts);

    // Initialize the selectedTeamMemberMap with existing mappings
    const initialMap = {};
    ddbChartOfAccounts.forEach(account => {
      initialMap[account.qbChartOfAccountsId] = '';
    });
    mappedTeamMembers.forEach(mapping => {
      initialMap[mapping.qbChartOfAccountsId] = mapping.teamMemberId;
    });

    setSelectedTeamMemberMap(initialMap);
    setDynamoDBChartOfAccounts(ddbChartOfAccounts);
  };

  useEffect(() => {
    setNextToken('')
    setNextNextToken('')
    setStatements([])
    // setFilteredData([])

    if (currentTab === 1 || currentTab === 2 || currentTab === 3) {
      if (currentUserIsGlobalAdmin) {
        const fetchData = async () => {
          await fetchAllTeamMemberNames();
          const mappedMembers = await fetchMappedTeamMembers();
          await fetchDynamoDbChartOfAccounts(mappedMembers);
        };
  
        fetchData();
      } else {
        // const fetchData = async () => {
        //   const mappedMembers = await fetchMappedTeamMembers();
        // };
  
        // fetchData();

      }
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, currentUserIsGlobalAdmin]);

  const fetchAndProcessChartOfAccounts = async () => {
    // fetch local chart of accounts aand store in state
    const ddbChartOfAccounts = await fetchAllChartOfAccounts()
    setDynamoDBChartOfAccounts(ddbChartOfAccounts)
    const quickBooksChartOfAccounts = await getChartOfAccounts(tokenObj?.access_token, qbEnv)
    const { creates, updates } = compareDatasets(ddbChartOfAccounts, quickBooksChartOfAccounts?.Account);

    if (creates.length > 0 || updates.length > 0) {
      await updateDynamoDBChartOfAccounts(creates, updates);
    }
  }

  const updateDynamoDBChartOfAccounts = async (creates, updates) => {
    for (const item of creates) {
      const input = {
        qbChartOfAccountsId: item.Id,
        qbChartOfAccountsName: item.Name,
        qbChartOfAccountsFullyQualifiedName: item.FullyQualifiedName,
        qbChartOfAccountsActive: item.Active.toString(),
      };
  
      try {
        const result = await addChartOfAccounts(input);
        console.log('DynamoDB created:', result)
      } catch (error) {
        console.error('Error creating DynamoDB:', error);
      }
    }
    for (const item of updates) {
      const input = {
        qbChartOfAccountsId: item.Id,
        qbChartOfAccountsName: item.Name,
        qbChartOfAccountsFullyQualifiedName: item.FullyQualifiedName,
        qbChartOfAccountsActive: item.Active.toString(),
      };
  
      try {
        const result = await updateChartOfAccounts(input);
        console.log('DynamoDB updated:', result)
      } catch (error) {
        console.error('Error updating DynamoDB:', error);
      }
    }
  };
  
  const compareDatasets = (dynamoDBData, quickBooksData) => {
    const updates = [];
    const creates = [];
  
    quickBooksData.forEach(qbItem => {
      const match = dynamoDBData.find(dynamoItem => dynamoItem.qbChartOfAccountsId === qbItem.Id);
  
      if (!match) {
        creates.push(qbItem);
      } else if (!areObjectsEqual(match, qbItem)) {
        updates.push(qbItem);
      }
    });
  
    return { creates, updates };
  };
  
  const areObjectsEqual = (dynamoItem, qbItem) => {
    return (
      dynamoItem.qbChartOfAccountsId === qbItem.Id &&
      dynamoItem.qbChartOfAccountsName === qbItem.Name &&
      dynamoItem.qbChartOfAccountsFullyQualifiedName === qbItem.FullyQualifiedName &&
      dynamoItem.qbChartOfAccountsActive === qbItem.Active.toString()
    );
  };

  const [tokenObj, setTokenObj] = useState(null);
  useEffect(() => {
    if (tokenObj) {
      console.log('Token Object Updated On Child:', tokenObj);
    }
  }, [tokenObj]);

  const [plaidTokenObj, setPlaidTokenObj] = useState(null);
  useEffect(() => {
    if (plaidTokenObj) {
      console.log('Plaid Token Object Updated On Child:', plaidTokenObj);
    }
  }, [plaidTokenObj]);

  const [institutionTransactions, setInstitutionTransactions] = useState(new Map());
  const getInstitutionTransactions = async (aToken, instId) => {
    const startDate = getPreviousDate(10);
    const endDate = todaysDateYMD();
 
    // leave this on for testing adding category to a smart expense category select. This might need to be added to the table.
    const test = await getTransactions(aToken, startDate, endDate);
    console.log('Test:', test);


    const sync = await syncTransactions(aToken);
    console.log('Sync:', sync);

    // now query transactions table to get all transactions for this institution (capital one)

    // Now we need to get a list for hte users to see that they can click on  to uplaod receipts
    // these can be matched or pending
    // once uploaded, they will roll off of their to do list
    // admins will see completed transactions that just need to be confirmed and pushed to QB.

    // Show for users, both pending and matched transactions that need to be uploaded.
    // Once a receipt is uploaded, it will be removed from the list.

    // This is going to require pagination
    const unmatchedTransactions = await fetchPlaidPostedTransactionsByMatched('unmatched');
    console.log('Unmatched Transactions:', unmatchedTransactions);
    // const response = await getTransactions(aToken, startDate, endDate);
    
    // Sort the transactions by the most recent date
    // const sortedTransactions = response.sort((a, b) => new Date(b.date) - new Date(a.date));
    // const sortedTransactions = sync.added.sort((a, b) => new Date(b.date) - new Date(a.date));
    const sortedTransactions = unmatchedTransactions?.sort((a, b) => new Date(b.date) - new Date(a.date));

    setInstitutionTransactions(prevState => {
      const newMap = new Map(prevState);
      newMap.set(instId, sortedTransactions);
      return newMap;
    });
    console.log('Plaid Synced Transactions:', sortedTransactions);
  }

  const handleGetTransactions = async (institutionToken, instId) => {
    const transactions = await getInstitutionTransactions(institutionToken, instId);
    console.info('transactions:', transactions);
  }

  const [ userInstitutions, setUserInstitutions ] = useState([]);
  const handleGetUserInstitutions = async () => {
    const response = await fetchPlaidInstitutionsByUser('spike_erp');
    setUserInstitutions(response);
    console.log('User Institutions:', response);
  }

  useEffect(() => {
    handleGetUserInstitutions('spike_erp');
  },[])

  const [pendingTransactions, setPendingTransactions] = useState([]);
  useEffect(() => {
    const fetchTeamMemberPendingTransactions = async (teamMemberId) => {
      // a user can have multiple cards, for now we are only dealing with one.
      const card = await fetchAccountsByTeamMember(qbEnv, teamMemberId);

      const response = await fetchPlaidPendingTransactionsByTeamMember(card[0]?.cardLastFour);
      console.log('Pending Transactions:', response);
      setPendingTransactions(response);
    };

    const fetchPendingTransactions = async () => {
      const response = await fetchPlaidPendingTransactions();

      // Add cardHolderName to each transaction
      const transactionsWithCardholderNames = response.map(transaction => {
        const lastFour = transaction.cardNumber.slice(-4);
        const matchedMember = mappedTeamMembers.find(member => member.cardLastFour === lastFour);
        
        let cardHolderName = 'Unknown';
        if (matchedMember && matchedMember.teamMember) {
          const { firstName, middleName, lastName, goesBy } = matchedMember.teamMember;
          cardHolderName = `${firstName} ${goesBy ? '"'+goesBy+'"' : ''} ${middleName ? middleName + ' ' : ''}${lastName}`;
        }

        return {
          ...transaction,
          cardHolderName
        };
      });

      // Sort transactions by createdAt
      transactionsWithCardholderNames.sort((a, b) => 
        a.createdAt > b.createdAt ? 1 : -1
      );

      console.log('Pending Transactions:', transactionsWithCardholderNames);
      setPendingTransactions(transactionsWithCardholderNames);
    };

    if (currentUserIsGlobalAdmin) {
      if (mappedTeamMembers.length > 0) {
        fetchPendingTransactions();
      }
    } else {
      fetchTeamMemberPendingTransactions(currentTeamMemberId);
    }
  }, [mappedTeamMembers, currentTeamMemberId, currentUserIsGlobalAdmin, qbEnv]);

  const [pendingMatchedToPosted, setPendingMatchedToPosted] = useState([]);

  useEffect(() => {
    if (pendingTransactions?.length > 0 && institutionTransactions?.size > 0) {
      const pendingMatchedToPosted = [];
  
      pendingTransactions.forEach(pending => {
        // Use the full cardNumber for comparison
        const cardNumber = pending.cardNumber;
  
        // Iterate over all entries in the institutionTransactions Map
        for (const transactions of institutionTransactions) {
          const matchedTransactions = transactions.filter(transaction => 
            transaction.account_owner === cardNumber
          );
  
          // might need to match on date within 3 days.
          if (matchedTransactions.length > 0) {
            const matched = matchedTransactions.find(matched => 
              parseFloat(matched.amount) === parseFloat(pending.amount) &&
              matched.account_owner === pending.cardNumber
              // matched.date === pending.date 
              // && matched.merchant_name === pending.merchant
            );
  
            if (matched) {
              pendingMatchedToPosted.push({
                pending,
                matched
              });
              break; // Exit the loop once a match is found
            }
          }
        }
      });
  
      console.info('Pending:', pendingTransactions);
      console.info('Posted:', institutionTransactions);
      console.info('Pending Matched to Posted:', pendingMatchedToPosted);
      setPendingMatchedToPosted(pendingMatchedToPosted);
    }
  }, [pendingTransactions, institutionTransactions]);

  return (

    <>
    {(currentUserIsGlobalAdmin===true) 
      ? 
        <><QuickBooksAuth 
        qbAppId='ManageExpenses'
        qbEnv={qbEnv}
        onTokenChange={setTokenObj}>
          {({ isLoggedInQB, loginQB, logoutQB }) => (
          
            <Container maxW="1600" mb={'50px'} as="form" onSubmit={handleSubmit(formSubmit, onError)}>
              <QBLoginLogoutButtons 
                isLoggedInQB={isLoggedInQB}
                loginQB={loginQB}
                logoutQB={logoutQB}
              />

              {(currentScreenWidth<460) ?
                <>
                  <Box mt={'25px'}>
                    <Box>
                      <Text as="span" textStyle="heading-1">Search Receipts</Text>
                    </Box>
                    <Box mt={'10px'}>
                      <Text>Search visible results by amount, vendor.</Text>
                    </Box>
                    <Box w={'100%'} my={'25px'}>
                      <TextInput
                        register={register}
                        errors={errors}
                        fieldname="searchInput"
                        fieldlabel="Search"
                        prettyname="Search"
                        placeholder="Enter search term"
                        onChange={filterData}
                      />
                    </Box>
                    <Box>
                      <ButtonQuaternaryWithIcon
                        name='refresh'
                        leftIcon='refresh'
                        iconsize='22px'
                        onClick={() => { handleRefresh(); }}
                        value='Refresh page'
                      />
                    </Box>
                  </Box>

                  <Box>
                    <Tabs 
                      borderColor='var(--dark-module-divider)' 
                      variant='enclosed' 
                      colorScheme='spikedarktabs' 
                      onChange={(index) => handleTabChange(index)}>
                      <TabList>
                        <Tab><Text>User Inbox</Text></Tab>
                        <Tab><Text>Inbox</Text></Tab>
                        <Tab><Text>Archived</Text></Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <TableContainer>
                            <HStack mb='15px'>
                              <Text className={searchHeaderTextClass}></Text>
                              <Spacer/>
                              <ButtonQuaternaryWithIcon
                                name='new'
                                leftIcon='new'
                                iconsize='22px'
                                // isDisabled={buttonsDisabled}
                                onClick={() => handleOpenDrawer('new', null)}
                                value='Add Receipt'
                              />
                            </HStack>
                            <Table variant={'compact'} size={'compact'} >
                              <Tbody>
                                {(filteredData?.length===0) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                                
                                {generateRows(filteredData)}
                                
                                <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                              </Tbody> 
                              
                              

                            </Table>
                          </TableContainer>
                          
                        </TabPanel>
                        <TabPanel>
                          <TableContainer>
                            <HStack mb='15px'>
                              <Text className={searchHeaderTextClass}></Text>
                              <Spacer/>
                              <ButtonQuaternaryWithIcon
                                name='new'
                                leftIcon='new'
                                iconsize='22px'
                                // isDisabled={buttonsDisabled}
                                onClick={() => handleOpenDrawer('new', null)}
                                value='Add Receipt'
                              />
                            </HStack>
                            <Table variant={'compact'} size={'compact'} >
                              <Tbody>
                                {(filteredData?.length===0) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                                
                                {generateRows(filteredData)}
                                
                                <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                              </Tbody> 
                              
                              

                            </Table>
                          </TableContainer>
                          
                        </TabPanel>
                        <TabPanel>
                          <TableContainer>
                            <HStack mb='15px'>
                              <Text className={searchHeaderTextClass}>Archived Estimates</Text>
                              <Spacer/>

                            </HStack>
                            <Table variant={'compact'} size={'compact'} >
                              <Tbody>
                                {(filteredData?.length===0) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                                
                                {generateRows(filteredData)}
                                
                                <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                              </Tbody> 
                               
                            </Table>
                          </TableContainer>
                          
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Box>
                </>
              :
                <>
                  <ModuleBox>
                    <Box>
                      <Text as="span" textStyle="heading-1">Search Receipts</Text>
                    </Box>
                    <Box mt={'10px'}>
                      <Text>Search visible results by amount, vendor.</Text>
                    </Box>
                    <Box w={'40%'} my={'25px'}>
                      <TextInput
                        register={register}
                        errors={errors}
                        fieldname="searchInput"
                        fieldlabel="Search"
                        prettyname="Search"
                        placeholder="Enter search term"
                        onChange={filterData}
                      />
                    </Box>
                    <Box>
                      <ButtonQuaternaryWithIcon
                        name='refresh'
                        leftIcon='refresh'
                        iconsize='22px'
                        onClick={() => { handleRefresh()}}
                        value='Refresh page'
                      />
                    </Box>

                  </ModuleBox>

                  <ModuleBox>
                    <Tabs 
                      borderColor='var(--dark-module-divider)' 
                      // size='md' 
                      variant='enclosed' 
                      colorScheme='spikedarktabs' 
                      onChange={(index) => handleTabChange(index)}>
                      <TabList>
                        <Tab><Text>Inbox</Text></Tab>
                        <Tab><Text>Archived</Text></Tab>
                        <Tab><Text>Your Purchases</Text></Tab>
                        {currentUserIsGlobalAdmin && <Tab>Payment Methods</Tab>}
                        {currentUserIsGlobalAdmin && <Tab>Playground</Tab>}
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <TableContainer>
                            <HStack mb='15px'>
                              <Text className={searchHeaderTextClass}></Text>
                              <Spacer/>
                              <ButtonQuaternaryWithIcon
                                name='new'
                                leftIcon='new'
                                iconsize='22px'
                                // isDisabled={buttonsDisabled}
                                onClick={() => handleOpenDrawer('new', null)}
                                value='Add Receipt'
                              />
                            </HStack>
                            <Box
                              height={'600px'}
                              // width={width||'100%'}
                              overflowY="scroll"
                              border="1px solid"
                              borderColor="var(-dark-component-border)"
                              borderRadius="6px"
                              p={2}
                              onScroll={handleScroll}
                            >
                              <Table variant={'compact'} size={'compact'} >
                                <Tbody>
                                    {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                                    {(filteredData?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr> }
                                  
                                  {generateRows(filteredData)}
                                  
                                  <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                                </Tbody>
                                
                                
                              </Table>
                            </Box>
                          </TableContainer>
                          
                        </TabPanel>
                        <TabPanel>
                          <TableContainer>
                          <HStack mb='15px'>
                              <Text className={searchHeaderTextClass}></Text>
                              <Spacer/>
                              <ButtonQuaternaryWithIcon
                                name='new'
                                leftIcon='new'
                                iconsize='22px'
                                // isDisabled={buttonsDisabled}
                                onClick={() => handleOpenDrawer('new', null)}
                                value='Add Receipt'
                              />
                            </HStack>
                            <Box
                              height={'600px'}
                              // width={width||'100%'}
                              overflowY="scroll"
                              border="1px solid"
                              borderColor="var(-dark-component-border)"
                              borderRadius="6px"
                              p={2}
                              onScroll={handleScroll}
                            >
                              <Table variant={'compact'} size={'compact'} >
                                <Tbody>
                                    {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                                    {(filteredData?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr> }
                                  
                                  {generateRows(filteredData)}
                                  
                                  <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                                </Tbody>
                                {/* {(showResultsSpinner) && <Tbody><Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr></Tbody>} */}

                              </Table>
                            </Box>
                          </TableContainer>
                          
                        </TabPanel>
                        <TabPanel>
                          <TableContainer>
                          <HStack mb='15px'>
                              <Text className={searchHeaderTextClass}>These purchases need receipts uploaded</Text>
                              <Spacer/>
                              <ButtonQuaternaryWithIcon
                                name='new'
                                leftIcon='new'
                                iconsize='22px'
                                // isDisabled={buttonsDisabled}
                                onClick={() => handleOpenDrawer('new', null)}
                                value='Add Receipt'
                              />
                            </HStack>
                            <Box
                              height={'600px'}
                              // width={width||'100%'}
                              overflowY="scroll"
                              border="1px solid"
                              borderColor="var(-dark-component-border)"
                              borderRadius="6px"
                              p={2}
                              onScroll={handleScroll}
                            >
                              <Table variant={'compact'} size={'compact'} >
                                <Tbody>
                                    {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                                    {(filteredData?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr> }
                                  
                                  {generateRows(filteredData)}
                                  
                                  <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                                </Tbody>
                                
                                <Tfoot>
                                  <Tr>
                                    <Th>
                                    
                                    </Th>
                                  </Tr>
                                </Tfoot>
                              </Table>
                            </Box>
                          </TableContainer>
                          
                        </TabPanel>
                        <TabPanel>
                          {/* <Container border={'1px solid pink'} pb='25px' as="form" onSubmit={handleSubmit(formSubmit, onError)}> */}
                            <Box>
                              <Flex alignItems="center" justifyContent="space-between" pb={'25px'}>
                                <Box>
                                <Text className={searchHeaderTextClass}>Sync Accounts</Text>
                                </Box>
                                <Spacer />
                                <Box>
                                  <ButtonQuaternaryWithIcon 
                                    name='closeDrawer'
                                    iconsize='26px'
                                    leftIcon='close'
                                    value='Get QuickBooks Accounts'
                                    onClick={fetchAndProcessChartOfAccounts}
                                  />
                                </Box>
                              </Flex>
                              <Box>
                              <TableContainer>
                                <HStack mb='15px'>
                                  <Text className={searchHeaderTextClass}>QuickBooks Accounts - Team Members</Text>
                                  <Spacer/>
                                </HStack>
                                <Table variant={'compact'} size={'compact'} >
                                  <Tbody>
                                    {(dynamodbChartOfAccounts?.length===0) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                                    
                                    {dynamodbChartOfAccounts && generateChartOfAccountsRows(dynamodbChartOfAccounts, mappedTeamMembers)}
                                    
                                    <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                                  </Tbody> 
                                </Table>
                              </TableContainer>
                              </Box>
                            </Box>

                          {/* </Container> */}
                        </TabPanel>
                        <TabPanel>
                          {(currentUserIsGlobalAdmin===true) 
                            ? 
                              <>
                                <PlaidAuth plaidEnv={plaidEnv} onTokenChange={setPlaidTokenObj} />

                                <Box mt={'25px'}>
                                  <ButtonPrimaryPlain
                                    type="button"
                                    onClick={handleGetUserInstitutions}
                                    // width="100%"
                                    name="institutions"
                                    value="Refresh Institutions"
                                    // isDisabled={!isFormLoaded || djtFields?.length===0 || (attachableObjects?.length!==djtFields?.length)}
                                  />  
                                </Box>

                                {/* 
                                  Link button to request update to this institutions products can be added here.
                                  1.	Retrieve the Existing access_token: Ensure you have the stored access_token for the institution.
                                  2.	Create a New Link Token in Update Mode: Use the access_token to create a new link token in update mode.
                                  3.	Initialize Link with the Update Token: Initialize Link with the new token, allowing users to add additional accounts.
                                  Reference Fixing Plaid Link Issues 
                                */}

                                <Box mt={'25px'}>
                                  {userInstitutions.map((institution) => {
                                    // console.log('Institution: ', institution);
                                    const instId = institution.institutionId;
                                    const instAccessToken = institution.accessToken;
                                    const institutionDetails = JSON.parse(JSON.parse(institution.institution));
                                    const instName = institutionDetails.name;
                                    // console.log('Parsed Institution Details: ', institutionDetails); // Debugging line to check parsed details

                                    // Get transactions for the current institution
                                    const transactions = institutionTransactions.get(instId) || [];

                                    // Still need to match up to filteredData

                                    return (
                                      <React.Fragment key={'inst_' + instId}>
                                        <Box mt={'25px'} display={'flex'} gap={'5px'} alignItems="bottom">
                                          <Text as="span" textStyle='heading-3'>{instName}</Text>
                                          <Button
                                            type="button"
                                            onClick={() => handleGetTransactions(instAccessToken, instId, '2024-09-01', '2024-09-27')}
                                            name="transactions"
                                            value="Refresh Transactions"
                                          >
                                            Get Transactions
                                          </Button>
                                        </Box>

                                        {/* pendingMatchedToPosted 
                                        include button to confirm match and update pending status to matched.
                                        If beyond 2 days, suggest manual match?
                                        */}
                                        <Box mt={'25px'} key={'insta_matched_transactions_' + instId}>
                                        <Text as="span" textStyle='heading-3'>Probably Matching</Text>
                                          {pendingMatchedToPosted?.map((matchedTransactions, index) => (
                                            <Box mt={'5px'} key={'matched_transaction_' + index + '_' + instId} >
                                              <Box mb={'10px'}>
                                                <Text textStyle='heading-3'>{matchedTransactions.pending.cardHolderName}</Text>
                                                <Text textStyle='heading-3'>{matchedTransactions.matched.date} {matchedTransactions.matched.merchant_name} {matchedTransactions.matched.amount} {matchedTransactions.matched.account_owner}</Text>
                                                <Text textStyle='heading-3'>{matchedTransactions.pending.date} {matchedTransactions.pending.merchant} {matchedTransactions.pending.amount} {matchedTransactions.pending.cardNumber}</Text>
                                                <ButtonPrimaryPlain 
                                                  type="button"
                                                  name='matchPendingToPosted'
                                                  value={'Match these transactions'}
                                                  // isDisabled={isSubmitting===true}
                                                  // onClick={() => {clearPendingTransaction(matchedTransactions[index])}}
                                                />
                                              </Box>
                                            </Box>
                                          ))}
                                        </Box>

                                        <Box mt={'25px'} key={'insta_pending_transactions_' + instId}>
                                        <Text as="span" textStyle='heading-3'>Pending</Text>
                                          {pendingTransactions?.map((pending, index) => (
                                            <Box mt={'5px'} key={'pending_transaction_' + index + '_' + instId} >
                                              <Box><Text as="span" textStyle='heading-3'>{pending.date} {pending.merchant} {pending.amount} {pending.cardNumber} {pending.cardHolderName}</Text></Box>
                                              {/* <Box><Text as="span" textStyle='heading-3'>Card Number: {pending.cardNumber}</Text></Box>
                                              <Box><Text as="span" textStyle='heading-3'>Card Holder: {pending.cardHolderName}</Text></Box>
                                              <Box><Text as="span" textStyle='heading-3'>Date: {pending.date}</Text></Box>
                                              <Box><Text as="span" textStyle='heading-3'>Merchant: {pending.merchant}</Text></Box>
                                              <Box><Text as="span" textStyle='heading-3'>Amount: {pending.amount}</Text></Box> */}
                                              </Box>
                                          ))}
                                        </Box>

                                        {/* authorized_date, date, merchant_name, name, amount, payment_channel, pending, transaction_type */}
                                        <Box mt={'25px'} key={'insta_transactions_' + instId}>
                                        <Text as="span" textStyle='heading-3'>Posted</Text>
                                          {transactions?.map((transaction, index) => (
                                            <Box mt={'5px'} key={'transaction_' + index + '_' + instId} >
                                              <Box><Text as="span" textStyle='heading-3'>{transaction.date} {transaction.merchant_name} {transaction.amount} {transaction.account_owner}</Text></Box>
                                              {/* <Box><Text as="span" textStyle='heading-3'>Date: {transaction.date}</Text></Box>
                                              <Box><Text as="span" textStyle='heading-3'>Account: {transaction.account_owner}</Text></Box>
                                              <Box><Text as="span" textStyle='heading-3'>Institution: {instName}</Text></Box>
                                              <Box><Text as="span" textStyle='heading-3'>Amount: {transaction.amount}</Text></Box>
                                              <Box><Text as="span" textStyle='heading-3'>Auth Date: {transaction.authorized_date}</Text></Box>
                                              <Box><Text as="span" textStyle='heading-3'>Merchant: {transaction.merchant_name}</Text></Box>
                                              <Box><Text as="span" textStyle='heading-3'>Merchant Name: {transaction.name}</Text></Box>
                                              <Box><Text as="span" textStyle='heading-3'>Payment Channel: {transaction.payment_channel}</Text></Box>
                                              <Box><Text as="span" textStyle='heading-3'>Transaction Type: {transaction.transaction_type}</Text></Box>
                                              <Box><Text as="span" textStyle='heading-3'>Category Array: {transaction.category.toString()}</Text></Box> */}
                                            </Box>
                                          ))}
                                        </Box>
                                      </React.Fragment>
                                    );
                                  })}
                                </Box>

                                

                              </> 
                            : <>Content in progress...</>
                          }
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </ModuleBox>
                </>
            }
              
            <ModuleDrawer
              onClose={onCameraUploadStatementClose}
              isOpen={isCameraUploadStatementOpen}
              bodyContent={<CameraUploadStatement screenWidth={currentScreenWidth} userData={userData} onClose={onCameraUploadStatementClose}/>}
              size={'full'}
            />
                
            <ModuleDrawer
              onClose={onStatementDetailClose}
              isOpen={isStatementDetailOpen}
              bodyContent={<StatementDetail qbEnv={qbEnv} screenWidth={currentScreenWidth} selectedStatement={selectedStatement} userData={userData} onClose={onStatementDetailClose}/>}
              size={'full'}
            />
                
            </Container>
          )
        }
      </QuickBooksAuth></> 
    : 
      <>
        <ModuleBox>
          <Box>
            <Text as="span" textStyle="heading-1">Search Receipts</Text>
          </Box>
          <Box mt={'10px'}>
            <Text>Search visible results by amount, vendor.</Text>
          </Box>
          <Box w={'40%'} my={'25px'}>
            <TextInput
              register={register}
              errors={errors}
              fieldname="searchInput"
              fieldlabel="Search"
              prettyname="Search"
              placeholder="Enter search term"
              onChange={filterData}
            />
          </Box>
          <Box>
            <ButtonQuaternaryWithIcon
              name='refresh'
              leftIcon='refresh'
              iconsize='22px'
              onClick={() => { handleRefresh()}}
              value='Refresh page'
            />
          </Box>
          {/* <Box>
            <Text as="span" textStyle="heading-1">Sort / Filter</Text>
          </Box>
          <Box mt={'10px'}>
            <Text>Sort visible results by date, amount, vendor, status.</Text>
          </Box> */}
        </ModuleBox>

        <ModuleBox>
          <Tabs 
            borderColor='var(--dark-module-divider)' 
            // size='md' 
            variant='enclosed' 
            colorScheme='spikedarktabs' 
            onChange={(index) => handleTabChange(index)}>
            <TabList>
              <Tab><Text>Inbox</Text></Tab>
              <Tab><Text>Archived</Text></Tab>
              <Tab><Text>Your Purchases</Text></Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <TableContainer>
                  <HStack mb='15px'>
                    <Text className={searchHeaderTextClass}></Text>
                    <Spacer/>
                    <ButtonQuaternaryWithIcon
                      name='new'
                      leftIcon='new'
                      iconsize='22px'
                      // isDisabled={buttonsDisabled}
                      onClick={() => handleOpenDrawer('new', null)}
                      value='Add Receipt'
                    />
                  </HStack>
                  <Box
                    height={'600px'}
                    // width={width||'100%'}
                    overflowY="scroll"
                    border="1px solid"
                    borderColor="var(-dark-component-border)"
                    borderRadius="6px"
                    p={2}
                    onScroll={handleScroll}
                  >
                    <Table variant={'compact'} size={'compact'} >
                      <Tbody>
                          {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(filteredData?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr> } */}
                        
                        {generateRows(filteredData)}
                        
                        <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                      </Tbody>
                      
                      <Tfoot>
                        <Tr>
                          <Th>
                          
                          </Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </Box>
                </TableContainer>
              </TabPanel>
              <TabPanel>
                <TableContainer>
                <HStack mb='15px'>
                    <Text className={searchHeaderTextClass}></Text>
                    <Spacer/>
                    <ButtonQuaternaryWithIcon
                      name='new'
                      leftIcon='new'
                      iconsize='22px'
                      // isDisabled={buttonsDisabled}
                      onClick={() => handleOpenDrawer('new', null)}
                      value='Add Receipt'
                    />
                  </HStack>
                  <Box
                    height={'600px'}
                    // width={width||'100%'}
                    overflowY="scroll"
                    border="1px solid"
                    borderColor="var(-dark-component-border)"
                    borderRadius="6px"
                    p={2}
                    onScroll={handleScroll}
                  >
                    <Table variant={'compact'} size={'compact'} >
                      <Tbody>
                          {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(filteredData?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr> }
                        
                        {generateRows(filteredData)}
                        
                        <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                      </Tbody>
                      {/* {(showResultsSpinner) && <Tbody><Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr></Tbody>} */}
                      
                      <Tfoot>
                        <Tr>
                          <Th>
                          {/* <Flex >
                            <Flex mt='13px'>
                            {(doShowAllSupervisorsSwitch) && <><Box>
                                <Text as="span" textStyle='text-2'>Show All Supervisors: </Text>
                              </Box>
                              <Spacer />
                              <Box ml={'10px'}>
                                <Switch
                                  colorScheme={'switch'}
                                  size={'lg'}
                                  id="showAllResults"
                                  isChecked={searchFilter}
                                  isDisabled={buttonsDisabled}
                                  onChange={(e) => {
                                    // setValue('doShowHours', e)
                                    handleShowAllToggle(!searchFilter)
                                    setSearchFilter(!searchFilter)
                                  }}
                                />
                              </Box></>}
                            </Flex>
                            <Spacer />
                            <Box>
                              <Center>
                                <ButtonSecondaryPlainWithRef 
                                  name='loadMore'
                                  ref={loadMoreRef} 
                                  isDisabled={(buttonsDisabled===true) ? true : loadMoreDisabled} 
                                  onClick={loadMoreOnClick} 
                                  value={(loadMoreDisabled) ? 'No More Results' : 'Load More'} //'Load More'
                                  my='5px'
                                  px='15px'
                                />
                              </Center>
                            </Box>
                          </Flex> */}
                          </Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </Box>
                </TableContainer>
                
              </TabPanel>
              <TabPanel>
                <TableContainer>
                <HStack mb='15px'>
                    <Text className={searchHeaderTextClass}>These purchases need receipts uploaded</Text>
                    <Spacer/>
                    <ButtonQuaternaryWithIcon
                      name='new'
                      leftIcon='new'
                      iconsize='22px'
                      // isDisabled={buttonsDisabled}
                      onClick={() => handleOpenDrawer('new', null)}
                      value='Add Receipt'
                    />
                  </HStack>
                  <Box
                    height={'600px'}
                    // width={width||'100%'}
                    overflowY="scroll"
                    border="1px solid"
                    borderColor="var(-dark-component-border)"
                    borderRadius="6px"
                    p={2}
                    onScroll={handleScroll}
                  >

                                      
                    <Text as="span" textStyle='heading-3'>Pending</Text>
                    {pendingTransactions?.map((pending, index) => (
                      <Box mt={'5px'} key={'pending_transaction_x' + index} >
                        <Box>
                          <Text as="span" textStyle='heading-3'>{pending.date} {pending.merchant} {pending.amount} {pending.cardNumber} {pending.cardHolderName}</Text>
                        </Box>
                        {/* <Box><Text as="span" textStyle='heading-3'>Card Number: {pending.cardNumber}</Text></Box>
                        <Box><Text as="span" textStyle='heading-3'>Card Holder: {pending.cardHolderName}</Text></Box>
                        <Box><Text as="span" textStyle='heading-3'>Date: {pending.date}</Text></Box>
                        <Box><Text as="span" textStyle='heading-3'>Merchant: {pending.merchant}</Text></Box>
                        <Box><Text as="span" textStyle='heading-3'>Amount: {pending.amount}</Text></Box> */}
                      </Box>
                    ))}
                    {/* <Table variant={'compact'} size={'compact'} >
                      {(showSearchResutlsTable) 
                        && <Tbody>
                          {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(filteredData?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr> }
                        
                        {generateRows(filteredData)}
                        
                        <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                      </Tbody>}
                      
                      <Tfoot>
                        <Tr>
                          <Th>
                          
                          </Th>
                        </Tr>
                      </Tfoot>
                    </Table> */}
                  </Box>
                </TableContainer>
                
              </TabPanel>
              </TabPanels>
            </Tabs>
          </ModuleBox>
    
    
      </>}

    
    </>


  );
};
