import { 
  useEffect, 
  // useContext, 
  useState 
} from 'react';
import { PropTypes } from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  HStack,
  Text,
  Flex,
  Spacer,
  useDisclosure,
  List,
  ListItem,
} from "@chakra-ui/react";

// uuid
import { v4 as uuidv4 } from 'uuid';

// modals
import { CenteredSubmitWithProgressModal } from '../Modal/CenteredSubmitWithProgressModal.jsx';
import { delay } from '../functions/generic.jsx';

import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';
import { ButtonSenaryWithIconOnly } from '../Form/Button/ButtonSenaryWithIconOnly.jsx';

import { fetchPrimaryUnitById } from '../graphqlCompnents/Unit/fetchPrimaryUnitById.jsx';
import { fetchDivisionOptions } from '../graphqlCompnents/Unit/fetchDivisionOptions.jsx';
import { fetchUnitTypes } from '../graphqlCompnents/Unit/fetchUnitTypes.jsx';
import { fetchUnitSubTypes } from '../graphqlCompnents/Unit/fetchUnitSubTypes.jsx';
import { updateUnit } from '../graphqlCompnents/Unit/updateUnit.jsx';
import { fetchAuxiliaryUnits } from '../graphqlCompnents/Unit/fetchAuxiliaryUnits.jsx';
import { removePrimaryUnitAuxiliaryUnits } from '../graphqlCompnents/Unit/removePrimaryUnitAuxiliaryUnits.jsx';
import { addPrimaryUnitAuxiliaryUnits } from '../graphqlCompnents/Unit/addPrimaryUnitAuxiliaryUnits.jsx';
import { updatePrimaryUnitStatus } from '../graphqlCompnents/Unit/updatePrimaryUnitStatus.jsx';

// structural components
import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSwitch } from '../Form/Switch/FormSwitch.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';

// globals
// import { AppContext } from '../AppContext.jsx';

export const EditUnit = ({ unitId, onClose }) => {

  // grab global context values
  // const { store } = useContext(AppContext);

  // set the current users team member id
  // const currentTeamMemberId = store?.userData?.id
  // console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)

  // --- REACT-HOOK-FORM ---
  const { 
    control, 
    register, 
    handleSubmit, 
    clearErrors,
    setValue, 
    formState: { 
      errors, 
    }} = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const onError = (errors, e) => console.log(errors, e);

  const { isOpen: isSubmitModalOpen , onOpen: onSubmitModalOpen, onClose: onSubmitModalClose } = useDisclosure()
  const [ submitModalContent, setSubmitModalContent ] = useState(null)
  const [ submitModalCloseDisabled, setSubmitModalCloseDisabled ] = useState(true)
  const [ submissionProgress, setSubmissionProgress ] = useState(0)
  async function handleSubmitModalClosed() {
    onSubmitModalClose()
    await delay(500)
    onClose()
  }

  const [ fetchedUnit, setFetchedUnit ] = useState(null)
  const [ isActive, setIsActive ] = useState(false)
  const [ isIrpiftaRegistered, setIsIrpiftaRegistered ] = useState(false)
  const [ isInspectedDaily, setIsInspectedDaily ] = useState(false)
  const [ requiresDriver, setRequiresDriver ] = useState(false)
  const [ requiresOperator, setRequiresOperator ] = useState(false)

  const [ divisionOptions, setDivisionOptions ] = useState([])
  const [ divisionSelectedOption, setDivisionSelectedOption ] = useState(null)
  useEffect(() => {
    const fetchDivisions = async () => {
      try {
        const response = await fetchDivisionOptions();
        console.log('fetchDivisionOptions: ', response);
        const options = response.map(division => ({
          value: division.id,
          label: division.prettyname,
        }))
        setDivisionOptions(options);

      } catch (error) {
        console.error('Failed to fetch divisions:', error);
      }
    };

    fetchDivisions();
  }, []);

  const [ unitTypeOptions, setUnitTypeOptions ] = useState([])
  const [ unitTypeSelectedOption, setUnitTypeSelectedOption ] = useState(null)
  useEffect(() => {
    const listUnitTypes = async () => {
      try {
        const response = await fetchUnitTypes();
        const options = response.map(unit => ({
          value: unit.id,
          label: unit.name,
        }))
        setUnitTypeOptions(options);
        
      } catch (error) {
        console.error('Failed to fetch unit types:', error);
      }
    };
    
    listUnitTypes();
  }, []);

  const [ unitSubTypes, setUnitSubTypes ] = useState([])
  const [ unitSubTypeOptions, setUnitSubTypeOptions ] = useState([])
  const [ unitSubTypeSelectedOption, setUnitSubTypeSelectedOption ] = useState(null)
  useEffect(() => {
    const listUnitSubTypes = async () => {
      try {
        const response = await fetchUnitSubTypes();
        console.log('fetchUnitSubTypes response: ', response);
        setUnitSubTypes(response);

      } catch (error) {
        console.error('Failed to fetch unit sub-types:', error);
      }
    };

    listUnitSubTypes();
  }, []);

  const [ auxiliaryUnitOptions, setAuxiliaryUnitOptions ] = useState([])
  let auxiliaryUnitSelectedOption = null;
  const [ selectedAuxiliaryUnits, setSelectedAuxiliaryUnits ] = useState([])  // id, name
  const [ initialAuxiliaryUnits, setInitialAuxiliaryUnits ] = useState([])
  const [ isIntialAuxUnitsLoaded, setIsIntialAuxUnitsLoaded ] = useState(false)
  const [ auxiliaryUnitsToAdd, setAuxiliaryUnitsToAdd ] = useState([])
  const [ auxiliaryUnitsToRemove, setAuxiliaryUnitsToRemove ] = useState([])
  useEffect(() => {
    const listAuxiliaryUnits = async () => {
      try {
        const response = await fetchAuxiliaryUnits();
        const options = response.map(unit => ({
          value: unit.id,
          label: unit.name,
        }))

        // now remove the currently selected aux units from the options
        const remainingOptions = options.filter(unit => !selectedAuxiliaryUnits.some(selectedUnit => selectedUnit.id === unit.value))
        setAuxiliaryUnitOptions(remainingOptions)
        
      } catch (error) {
        console.error('Failed to fetch auxiliary units:', error);
      }
    };

    if (isIntialAuxUnitsLoaded) listAuxiliaryUnits();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntialAuxUnitsLoaded]);

  useEffect(() => {
    if (unitId) {
      const fetchUnit = async () => {
        const unit = await fetchPrimaryUnitById(unitId)
        if (unit) {
          setFetchedUnit(unit)
          console.log(' ----- unit: ', unit)

          setValue('code', unit.code)
          setValue('make', unit.make)
          setValue('model', unit.model)
          setValue('color', unit.color)
          setValue('year', unit.year)
          setValue('tag', unit.tag)
          setValue('vin', unit.vin)
          setValue('odometer', unit.odometer)
          setValue('serial', unit.serial)
          setValue('isIrpiftaRegistered', unit?.irpiftaRegistered||false)
          setIsIrpiftaRegistered(unit?.irpiftaRegistered||false)
          setValue('isActive', unit.isActive)
          setIsActive(unit.isActive)
          setValue('isInspectedDaily', unit.isInspectedDaily)
          setIsInspectedDaily(unit.isInspectedDaily)
          setValue('requiresDriver', unit.requiresDriver)
          setRequiresDriver(unit.requiresDriver)
          setValue('requiresOperator', unit.requiresOperator)
          setRequiresOperator(unit.requiresOperator)
        }
      }

      fetchUnit()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[unitId])

  useEffect(() => {
    if (fetchedUnit && divisionOptions.length>0) {
      const divisionSelectedOption = divisionOptions.find(division => division.value === fetchedUnit?.unitStatus?.divisionId)
      setDivisionSelectedOption(divisionSelectedOption)
      setValue('selectDivision', fetchedUnit?.unitStatus?.divisionId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchedUnit, divisionOptions])

  useEffect(() => {
    if (fetchedUnit) {
      // get auxiliary units
      if (fetchedUnit?.auxillaryUnits?.items?.length > 0) {
        const selectedAuxiliaryUnits = fetchedUnit?.auxillaryUnits?.items.map(unit => ({
          id: unit?.spkAuxillaryUnitTbl?.id,
          name: unit?.spkAuxillaryUnitTbl?.name
        }))
        setSelectedAuxiliaryUnits(selectedAuxiliaryUnits)
        // now remove them from the options
        // const remainingOptions = auxiliaryUnitOptions.filter(unit => !selectedAuxiliaryUnits.some(selectedUnit => selectedUnit.id === unit.value))
        // setAuxiliaryUnitOptions(remainingOptions)

        const initialAuxUnits = fetchedUnit?.auxillaryUnits?.items.map(unit => ({
          id: unit?.id,
          unitId: unit?.spkAuxillaryUnitTbl?.id
        }))
        setInitialAuxiliaryUnits(initialAuxUnits)
        console.log('initialAuxUnits: ', initialAuxUnits)
      }
      setIsIntialAuxUnitsLoaded(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchedUnit])

  useEffect(() =>{
    if (fetchedUnit && unitTypeOptions.length>0) {
      const unitTypeSelectedOption = unitTypeOptions.find(unitType => unitType.value === fetchedUnit?.typeId)
      setUnitTypeSelectedOption(unitTypeSelectedOption)
      setValue('selectUnitType', fetchedUnit?.typeId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchedUnit, unitTypeOptions])

  useEffect(() => {
    if (fetchedUnit && unitSubTypes.length>0) {
      const fetchUnitSubTypes = async (typeId) => {
        const subTypeOptions = await getSubTypesByTypeId(typeId)
        setUnitSubTypeOptions(subTypeOptions)
        
        const unitSubTypeSelectedOption = subTypeOptions.find(unit => unit.value === fetchedUnit.subTypeId)
        setUnitSubTypeSelectedOption(unitSubTypeSelectedOption)
        setValue('selectUnitSubType', fetchedUnit.subTypeId)
      }

      fetchUnitSubTypes(fetchedUnit?.typeId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchedUnit, unitSubTypes])

  const handleDivisionChanged = (event) => {
    const selectedOption = divisionOptions.find(division => division.value === event.value);
    setDivisionSelectedOption(selectedOption);
    setValue('selectDivision', selectedOption.value);
    clearErrors('selectDivision');
  }

  const handleUnitSubTypeChanged = (event) => {
    const selectedOption = unitSubTypeOptions.find(unit => unit.value === event.value);
    setUnitSubTypeSelectedOption(selectedOption);
    setValue('selectUnitSubType', selectedOption.value)
    clearErrors('selectUnitSubType');
  }

  const getSubTypesByTypeId = async (typeId) => {
    const subTypes = unitSubTypes.filter(unit => unit.typeId === typeId)
      .map(unit => ({
          value: unit.id,
          label: unit.name,
          rateTypeId: unit?.priceCode?.rateTypeId,
          price: unit?.priceCode?.price,
      }));
    console.log('unitTypeId: ', typeId)
    console.log('subTypes: ', subTypes)
    return subTypes;
  }

  const handleUnitTypeChanged = (event) => {
    setUnitSubTypeSelectedOption(null);

    const selectedOption = unitTypeOptions.find(unit => unit.value === event.value);
    setUnitTypeSelectedOption(selectedOption);

    const subTypeOptions = unitSubTypes
      .filter(unit => unit.typeId === event.value)
      .map(unit => ({
          value: unit.id,
          label: unit.name,
          rateTypeId: unit?.priceCode?.rateTypeId,
          price: unit?.priceCode?.price,
      }));
      console.log('unitTypeId: ', event.value)
    console.log('subTypeOptions: ', subTypeOptions)
    setUnitSubTypeOptions(subTypeOptions);
    setValue('selectUnitType', selectedOption.value);
    clearErrors('selectUnitType');
  }

  const handleKeyDown = (event) => {
    // Check if the key pressed is 'Enter'
    if (event.key === 'Enter' && event.target.type !== 'textarea') {
      event.preventDefault();  // Prevent form submission
    }
  };

  const formSubmit = async (values, event) => {
    // reference AddTeamMember.js for Cognito Add User functionality
    event.preventDefault();
    console.warn(' ----- formSubmit values: ', values)
    console.log(' ----- fetchedUnit: ', fetchedUnit)
    console.log(' ----- auxiliaryUnitsToRemove: ', auxiliaryUnitsToRemove)
    console.log(' ----- auxiliaryUnitsToAdd: ', auxiliaryUnitsToAdd)
    
    onSubmitModalOpen()
    setSubmitModalContent('Updating unit...')
    setSubmitModalCloseDisabled(true)
    setSubmissionProgress(10)

    //auxiliaryUnitsToAdd
    if (auxiliaryUnitsToAdd.length > 0) {
      await Promise.all(auxiliaryUnitsToAdd.map(async (auxUnitId) => {
        const newAuxiliaryUnit = {
          spkPrimaryUnitTblID: unitId,
          spkAuxillaryUnitTblID: auxUnitId,
        };
        await addPrimaryUnitAuxiliaryUnits(newAuxiliaryUnit);
      }));
    }

    if (auxiliaryUnitsToRemove.length > 0) {
      for (const unit of auxiliaryUnitsToRemove) {
        try {
          await removePrimaryUnitAuxiliaryUnits(unit.id);
        } catch (error) {
          console.error('Error removing unit:', unit.id, error);
        }
      }
    }

    const unitParams = {
      id: unitId,
      code: fetchedUnit?.code,
      color: values?.color,
      isActive: values?.isActive,
      isInspectedDaily: values?.isInspectedDaily,
      make: values?.make,
      model: values?.model,
      requiresDriver: values?.requiresDriver,
      requiresOperator: values?.requiresOperator,
      subTypeId: values?.selectUnitSubType,
      typeId: values?.selectUnitType,
      serial: values?.serial,
      tag: values?.tag,
      vin: values?.vin,
      year: values?.year,
      irpiftaRegistered: values?.isIrpiftaRegistered,
      odometer: values?.odometer,
    }

    console.warn(' ----- sending unitParams: ', unitParams)
    const response = await updateUnit(unitParams)
    console.warn(' ----- updateUnit response: ', response)

    const unitStatus = {
      code: fetchedUnit?.code,
      divisionId: values.selectDivision,
      inService: values.isActive,
    };
    console.log('unitStatus: ', unitStatus);
    const unitStatusId = await updatePrimaryUnitStatus(unitStatus);
    console.log('unitStatusId: ', unitStatusId);

    setSubmitModalContent('Unit has been updated.')
    setSubmissionProgress(100)
    setSubmitModalCloseDisabled(false)

  }

  const handleAuxiliaryUnitChanged = (event) => {
    const selectedOption = auxiliaryUnitOptions.find(unit => unit.value === event.value);
    console.log('auxiliaryUnitOptions: ', auxiliaryUnitOptions)

    const updateSelectedAuxiliaryUnits = (selectedOption) => {
      const newAuxiliaryUnit = {
        id: selectedOption.value,
        name: selectedOption.label
      }
      setSelectedAuxiliaryUnits([...selectedAuxiliaryUnits, newAuxiliaryUnit]);

      // Remove the selected option from the auxiliary unit options
      const remainingOptions = auxiliaryUnitOptions.filter(unit => unit.value !== selectedOption.value);
      setAuxiliaryUnitOptions(remainingOptions);
    }

    if (selectedOption) {
      updateSelectedAuxiliaryUnits(selectedOption);
    }

    // We need to keep track of the aux units to add to the database
    // if the unit is not in the initialAuxiliaryUnits array, we need to add it to the database
    // We also need to make sure we don't add the same unit twice
    // AND we also need to see if the unit is in the auxiliaryUnitsToRemove array, because if it is, we need to remove it from that array

    // if it already existed in the database, and then it got deleted here (which updates the deleteFromDB array) 
    // and we ADDED IT BACK, technically, we don't need to do anything to the database for this aux unit so we want to 
    // make sure it's neither in the addTo or removeFrom DB arrays.
    if (initialAuxiliaryUnits.some(item => item.unitId === selectedOption.value) && auxiliaryUnitsToRemove.some(item => item.unitId === selectedOption.value)) {
      // remove it from the remove array
      const updatedAuxiliaryUnitsToRemove = auxiliaryUnitsToRemove.filter(unit => unit.unitId !== selectedOption.value);
      setAuxiliaryUnitsToRemove(updatedAuxiliaryUnitsToRemove);
    }

    // now add it to the add array if needed
    if (!initialAuxiliaryUnits.some(item => item.unitId === selectedOption.value) && !auxiliaryUnitsToAdd.includes(selectedOption.value)) {
      setAuxiliaryUnitsToAdd([...auxiliaryUnitsToAdd, selectedOption.value]);
    }
  }

  const handleDeleteAuxiliaryUnit = (unitId) => {
    // Find the unit to be deleted to add it back to options
    const unitToDelete = selectedAuxiliaryUnits.find(item => item.id === unitId);
  
    // Filter out the deleted unit from selected auxiliary units
    const updatedAuxiliaryUnits = selectedAuxiliaryUnits.filter(item => item.id !== unitId);
    setSelectedAuxiliaryUnits(updatedAuxiliaryUnits);
  
    // If the unit to delete exists, add it back to the options and sort them
    if (unitToDelete) {
      setAuxiliaryUnitOptions(prevOptions => {
        const newOptions = [...prevOptions, { value: unitToDelete.id, label: unitToDelete.name }];
        return newOptions.sort((a, b) => a.label.localeCompare(b.label));
      });
    }

    // We need to keep track of the aux units to remove from the database
    // if the unit is in the initialAuxiliaryUnits array, we need to remove it from the database
    // We also need to make sure we aren't adding the same unit twice

    // We also need to make sure that the auxiliaryUnitsToAdd array is kept updated
    // When we go to submit, we only want to remove previously existing aux units and we only 
    // want to add new aux units without making any changes to existing aux units that weren't mutated,
    
    if (initialAuxiliaryUnits.some(item => item.unitId === unitId) && !auxiliaryUnitsToAdd.includes(unitId)) {
      
      const matchedAuxUnit = initialAuxiliaryUnits.filter(item => item.unitId === unitId);
      console.log('matchedAuxUnit: ', matchedAuxUnit)
      // const filteredAuxUnitsToRemove = initialAuxiliaryUnits.filter(unit => unit.unitId === matchedAuxUnit[0].unitId);
      // setAuxiliaryUnitsToRemove(filteredAuxUnitsToRemove)
      setAuxiliaryUnitsToRemove([...auxiliaryUnitsToRemove, matchedAuxUnit[0]]);
    }

    if (!initialAuxiliaryUnits.some(item => item.unitId === unitId) && auxiliaryUnitsToAdd.includes(unitId)) {
      // remove it from the add array
      const updatedAuxiliaryUnitsToAdd = auxiliaryUnitsToAdd.filter(unit => unit !== unitId);
      setAuxiliaryUnitsToAdd(updatedAuxiliaryUnitsToAdd);
    }

    
    
  };

  return (
    <>
    <Container as="form" maxW="1600" mb={12} onKeyDown={handleKeyDown} onSubmit={handleSubmit(formSubmit, onError)}>

      <ModuleBoxDrawer>
        <Flex>
          <Box>
            <Text as="span" textStyle='heading-1'>Edit Unit: {fetchedUnit?.code}</Text>
          </Box>
          <Spacer />
          <Box>
            <ButtonQuaternaryWithIcon 
              name='closeDrawer'
              iconsize='26px'
              leftIcon='close'
              value='Cancel'
              onClick={onClose}
            />
          </Box>
        </Flex>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="make"
            fieldlabel="Make"
            prettyname="Make"
            placeholder="Enter unit make"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="model"
            fieldlabel="Model"
            prettyname="Model"
            placeholder="Enter unit model"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="color"
            fieldlabel="Color"
            prettyname="Color"
            placeholder="Enter unit color"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="year"
            fieldlabel="Year"
            prettyname="Year"
            placeholder="Enter unit year"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="tag"
            fieldlabel="Tag"
            prettyname="Tag"
            isRequired={true}
            placeholder="Enter unit tag"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="vin"
            fieldlabel="VIN"
            prettyname="VIN"
            isRequired={true}
            placeholder="Enter unit vin"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="odometer"
            fieldlabel="Odometer"
            prettyname="Odometer"
            // isRequired={true}
            placeholder="Enter unit odometer"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="serial"
            fieldlabel="Serial No."
            prettyname="Serial No."
            placeholder="Enter unit serial number"
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <FormSelectSimple
            register={register}
            control={control}
            errors={errors}
            fieldname="selectDivision"
            fieldlabel="Current Unit Division"
            isRequired={true}
            rules={{ required: "Division is required" }}
            placeholder="Select current division location"
            optionsArray={divisionOptions||''}
            onChange={(e) => handleDivisionChanged(e)}
            selectedoption={divisionSelectedOption||''}
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <FormSelectSimple
            register={register}
            control={control}
            errors={errors}
            fieldname="selectUnitType"
            fieldlabel="Unit Type"
            isRequired={true}
            rules={{ required: "Unit type is required" }}
            placeholder="Select unit type"
            optionsArray={unitTypeOptions||''}
            onChange={(e) => handleUnitTypeChanged(e)}
            selectedoption={unitTypeSelectedOption||''}
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <FormSelectSimple
            register={register}
            control={control}
            errors={errors}
            fieldname="selectUnitSubType"
            fieldlabel="Unit Sub-type"
            isRequired={true}
            rules={{ required: "Unit sub-type is required" }}
            placeholder="Select unit sub-type"
            optionsArray={unitSubTypeOptions||''}
            onChange={(e) => handleUnitSubTypeChanged(e)}
            selectedoption={unitSubTypeSelectedOption||''}
            isDisabled={!unitTypeSelectedOption}
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <FormSelectSimple
            register={register}
            control={control}
            errors={errors}
            fieldname="selectAuxiliaryUnits"
            fieldlabel="Auxiliary Units"
            placeholder="Select auxiliary units to add to this unit"
            optionsArray={auxiliaryUnitOptions||''}
            onChange={(e) => handleAuxiliaryUnitChanged(e)}
            selectedoption={auxiliaryUnitSelectedOption||''}
          />
        </Box>
        <Box w={'40%'} my={'25px'}>
          <Box>
            <List spacing={'10px'}>
              {selectedAuxiliaryUnits.map((unit, index) => (
                <ListItem key={unit.id} style={{ listStyleType: "none" }}>
                  <ButtonSenaryWithIconOnly
                    mr='5px'
                    key={'aux_unit_list_button_'+index}
                    name={'aux_unit_list_button_'+index}
                    icon='delete'
                    iconsize='22px'
                    onClick={() => handleDeleteAuxiliaryUnit(unit.id)}
                  />
                  <Text as="span" textStyle='dark-sfpro-text-1'>{unit.name}</Text>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>

        <Box w={'40%'} my={'25px'}>
          <Flex>
            <Box>
              <Text as="span" textStyle='label-1'>Unit is active</Text><br/>
            </Box>
            <Spacer />
            <Box>
              <FormSwitch
                register={register}
                control={control}
                fieldname="isActive"
                isChecked={isActive}
                onChange={() => {
                  setValue('isActive', !isActive)
                  setIsActive(!isActive)
                }}
              />
            </Box>
          </Flex>
        </Box>

        <Box w={'40%'} my={'25px'}>
          <Flex>
            <Box>
              <Text as="span" textStyle='label-1'>Unit is registered IRP/IFTA</Text><br/>
            </Box>
            <Spacer />
            <Box>
              <FormSwitch
                register={register}
                control={control}
                fieldname="isIrpiftaRegistered"
                isChecked={isIrpiftaRegistered}
                onChange={() => {
                  setValue('isIrpiftaRegistered', !isIrpiftaRegistered)
                  setIsIrpiftaRegistered(!isIrpiftaRegistered)
                }}
              />
            </Box>
          </Flex>
        </Box>

        <Box w={'40%'} my={'25px'}>
          <Flex>
            <Box>
              <Text as="span" textStyle='label-1'>Unit is inspected daily</Text><br/>
            </Box>
            <Spacer />
            <Box>
              <FormSwitch
                register={register}
                control={control}
                fieldname="isInspectedDaily"
                isChecked={isInspectedDaily}
                onChange={() => {
                  setValue('isInspectedDaily', !isInspectedDaily)
                  setIsInspectedDaily(!isInspectedDaily)
                }}
              />
            </Box>
          </Flex>
        </Box>

        <Box w={'40%'} my={'25px'}>
          <Flex>
            <Box>
              <Text as="span" textStyle='label-1'>Unit requires driver</Text><br/>
            </Box>
            <Spacer />
            <Box>
              <FormSwitch
                register={register}
                control={control}
                fieldname="requiresDriver"
                isChecked={requiresDriver}
                onChange={() => {
                  setValue('requiresDriver', !requiresDriver)
                  setRequiresDriver(!requiresDriver)
                }}
              />
            </Box>
          </Flex>
        </Box>

        <Box w={'40%'} my={'25px'}>
          <Flex>
            <Box>
              <Text as="span" textStyle='label-1'>Unit requires operator</Text><br/>
            </Box>
            <Spacer />
            <Box>
              <FormSwitch
                register={register}
                control={control}
                fieldname="requiresOperator"
                isChecked={requiresOperator}
                onChange={() => {
                  setValue('requiresOperator', !requiresOperator)
                  setRequiresOperator(!requiresOperator)
                }}
              />
            </Box>
          </Flex>
        </Box>





        <HStack my={'25px'} key={uuidv4()}>
          <Box w={'40%'}>
            <ButtonSecondaryPlain 
              width={'100%'}
              name='cancel'
              value='Cancel'
              onClick={onClose}
              // onClick={() => {
              //   setSelectedActionButton(null)
              // }}
              // onClick={() =>  handleInvoiceReset()}
              // isDisabled={!isFormLoaded}
            />  
          </Box>

          <Box w={'60%'}>
            <ButtonPrimaryPlain
              type="submit"
              onClick={handleSubmit(formSubmit, onError)}
              width={'100%'}
              name='submit'
              value={'Update Unit'}
              // isDisabled={!isFormLoaded || djtFields?.length===0 || (attachableObjects?.length!==djtFields?.length)}
            />
          </Box>
        </HStack>


      </ModuleBoxDrawer>


    </Container>
   {(submitModalContent) && <CenteredSubmitWithProgressModal
      isModalOpen={isSubmitModalOpen}
      onModalClose={onSubmitModalClose}
      content={submitModalContent}
      closeButtonDisabled={submitModalCloseDisabled}
      handleSubmitModalClosed={handleSubmitModalClosed}
      progress={submissionProgress}
      successMessage={'Unit has been updated.'}
      closeButtonVisible={true}
    />}
    </>
  )
}

EditUnit.propTypes = {
  unitId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
