import { getClientContactByClientContactId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const fetchClientContactByClientContactId = async (clientContactId) => {
  if (clientContactId) {
    try {
      const response = await client.graphql({
        query: getClientContactByClientContactId,
        variables: { id: clientContactId }
      });

      const results = response.data.getSpkDJTClientContactTbl;
      return results;
    } catch (err) {
      return ('error fetchClientContactByClientContactId: ', [err]);
    }
  } else {
    return null;
  }
};

fetchClientContactByClientContactId.propTypes = {
  clientContactId: PropTypes.string.isRequired,
};
