import PropTypes from 'prop-types';
import { convertMilitaryToStandard } from './functions/dateTime'

export const  DjtPdfTemplate = (props) => {
	const { 
    date,
		po='',
		djtNotes,
		client,
		location,
		contact,
		contactEmail,
		phone,
		billingEmail,
		laborItems=[],
		laborItemsTotal=0,
		equipmentItems=[],
		equipmentItemsContinued=[],
		equipmentItemsTotal=0,
		materialItems=[],
		materialItemsTotal=0,
		djtTotalBilled=0,
		// pagebreakVerticalSpacer=0,
  } = props;

	// console.info('COMPILING PDF WITH PROPS: ', props)

	let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
	
	const styles = {
		page: {
			margin: '0',
			pageBreakAfter: 'always',
		},
		
		pdfRenderFriendly: {
			margin: '0',
			pageBreakAfter: 'always',
		},

		djtHeader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '87px',
      width: '748px',
      marginTop: '40px'
		},

		verticalSpacerXLg: {
			width: '748px',
			height: '88px',
			marginLeft: '29px',
			display: 'inline-block',
		},

		verticalSpacerLg: {
			width: '748px',
			height: '44px',
			marginLeft: '29px',
			display: 'inline-block',
		},

		verticalSpacerMd: {
			width: '748px',
			height: '24px',
			marginLeft: '29px',
			display: 'inline-block',
		},

		verticalSpacerSm: {
			width: '748px',
			height: '14px',
			marginLeft: '29px',
			display: 'inline-block',
		},

		sectionWrapper: {
			width: '748px',
			marginLeft: '29px',
			display: 'inline-block',
		},

		sectionBlockHeader: {
			width: '748px',
			height: '36px',
			backgroundColor: '#000000'
		},

		sectionBlockText: {
			marginLeft: '10px',
			letterSpacing: '0.56px',
			lineHeight: '18px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '18px',
      fontWeight: '700',
			color: '#fcb34c',
			textAlign: 'left',
		},

		columnLayout: {
			display: 'flex',
			justifyContent: 'space-between',
		},

		column: {
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'left',
			borderLeft: '1px solid #000000',
			borderRight: '1px solid #000000',
			borderBottom: '1px solid #000000',
		},

		columnRow: {
			color: '#000',
			width: '370px',
			height: '28px',
			borderTop: '1px solid #000000',
			paddingLeft: '10px',
			letterSpacing: '0.01px',
			lineHeight: '10px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '18px',
      fontWeight: '700',
			whiteSpace: 'nowrap',
		},

		authColumn: {
			marginTop: '17px',
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'left',
			borderLeft: '1px solid #000000',
			borderRight: '1px solid #000000',
			borderBottom: '1px solid #000000',
		},

		clientAuthColumn: {
			width: '370px',
			height: '28px',
			borderBottom: '1px solid #000000',
			paddingLeft: '10px',
			letterSpacing: '0.01px',
			lineHeight: '10px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '18px',
      fontWeight: '700',
			whiteSpace: 'nowrap',
		},

		spikeAuthColumn: {
			width: '370px',
			height: '28px',
			borderBottom: '1px solid #000000',
			paddingLeft: '10px',
			letterSpacing: '0.01px',
			lineHeight: '10px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '18px',
      fontWeight: '700',
			whiteSpace: 'nowrap',
		},

		signatureAuthColumn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '17px',
      width: '370px',
      height: '100px',
      paddingTop: '5px',
      paddingBottom: '5px',
		},


		workDescriptionTextContainer: {
			width: '748px',
			marginTop: '17px',
			border: '1px solid #000000',

		},

		workDescriptionText: {
			color: '#000',
			marginTop: '-5px',
			marginLeft: '10px',
			marginRight: '10px',
			marginBottom: '20px',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '22px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '18px',
      fontWeight: '700',
		},
		
		totalRowContainer: {
			width: '748px',
			height: '31px',
			backgroundColor: '#c1c1c4',
			borderRight: '1px solid #000000',
			borderLeft: '1px solid #000000',
			borderBottom: '1px solid #000000',
		},

		totalItemContainer: {
			height: '31px',
			display: 'flex',
		},

		totalItemLeftColumn: {
			borderRight: '1px solid #000000',
			width: '662px',
			display: 'inline-block',
			textAlign: 'right',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingRight: '9px',
		},

		totalItemRightColumnContainer: {
			color: '#000',
			height: '30px',
			width: '86px',
			backgroundColor: '#ffffff',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},
		
		headerRowContainer: {
			width: '748px',
			height: '31px',
			backgroundColor: '#c1c1c4',
			marginTop: '17px',
			borderRight: '1px solid #000000',
			borderLeft: '1px solid #000000',
			borderBottom: '1px solid #000000',
		},

		headerItemContainer: {
			height: '31px',
			display: 'flex',
		},

		headerItemEquipmentDescription: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '562px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		headerItemUnitId: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '100px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		headerItemEmployeeName: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '128px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		headerItemJobClass: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '127px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		headerItemStart: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '75px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		headerItemFinish: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '75px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		headerItemPerDiem: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '74px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		headerItemStraightTime: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '98px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		headerItemOverTime: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '85px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		headerItemTotal: {
			color: '#000',
			width: '86px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		rowContainer: {
			width: '748px',
			height: '31px',
			backgroundColor: '#ffffff',
			borderRight: '1px solid #000000',
			borderLeft: '1px solid #000000',
			borderBottom: '1px solid #000000',
		},

		itemContainer: {
			height: '31px',
			display: 'flex',
		},

		itemSummaryDescription: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '662px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		headerItemSummaryDescription: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '662px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		headerItemMaterialDescription: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '462px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		headerItemUnitPrice: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '100px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		headerItemQuantity: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '100px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		itemMaterialDescription: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '462px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		itemUnitPrice: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '100px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		itemQuantity: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '100px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		itemEquipmentDescription: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '562px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		itemEquipmentUnitId: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '100px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		itemEmployeeName: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '128px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		itemJobClass: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '127px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		itemStart: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '75px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		itemFinish: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '75px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		itemPerDiem: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '74px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		itemStraightTime: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '98px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		itemOverTime: {
			color: '#000',
			borderRight: '1px solid #000000',
			width: '85px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},

		itemTotal: {
			color: '#000',
			width: '86px',
			display: 'inline-block',
			textAlign: 'left',
			letterSpacing: '0.01px',
			lineHeight: '12px',
			fontFamily: 'Arial Narrow Bold',
			fontSize: '16px',
      fontWeight: '700',
			paddingLeft: '9px',
		},
	};

	return (
		<>
			<div style={styles.pdfRenderFriendly}>
				
				<div style={styles.sectionWrapper}>
					<div style={styles.djtHeader}>
					{/* <img src='/djt-header-transp-2.png' alt="header"/> */}
					<img src='/images/djt-header-transp.png' alt="header"/>
					</div>
				</div>

				<div style={styles.verticalSpacerSm}></div>

				<div style={styles.sectionWrapper}>
					<div style={styles.columnLayout}>
						<div style={styles.column}>
							<div style={styles.columnRow}>Date: {date}</div>
							<div style={styles.columnRow}>PO: {po}</div>
							<div style={styles.columnRow}>Client: {client}</div>
							<div style={styles.columnRow}>Location: {location}</div>
						</div>

						<div style={styles.column}>
							<div style={styles.columnRow}>Contact: {contact}</div>
							<div style={styles.columnRow}>Contact Email: {contactEmail}</div>
							<div style={styles.columnRow}>Phone: {phone}</div>
							<div style={styles.columnRow}>Billing Email: {billingEmail}</div>
						</div>
					</div>
				</div>

				<div style={styles.verticalSpacerMd}></div>

				<div style={styles.sectionWrapper}>
					<div style={styles.sectionBlockHeader}>
						<div style={styles.sectionBlockText}>WORK DESCRIPTION</div>
						<div style={styles.workDescriptionTextContainer}>
							<div style={styles.workDescriptionText}>{djtNotes}</div>
						</div>

					</div>
				</div>
				
				<div style={styles.verticalSpacerLg}></div>

				<div style={styles.sectionWrapper}>
					<div style={styles.sectionBlockHeader}>
						<div style={styles.sectionBlockText}>LABOR</div>
						<div style={styles.headerRowContainer}>
							<div style={styles.headerItemContainer}>
								<div style={styles.headerItemEmployeeName}>Employee Name</div>
								<div style={styles.headerItemJobClass}>Job Class</div>
								<div style={styles.headerItemStart}>Start</div>
								<div style={styles.headerItemFinish}>Finish</div>
								<div style={styles.headerItemPerDiem}>Per Diem</div>
								<div style={styles.headerItemStraightTime}>Straight Time</div>
								<div style={styles.headerItemOverTime}>Over Time</div>
								<div style={styles.headerItemTotal}>Total</div>
							</div>
						</div>

						{laborItems?.map((labor, index) => {
							return(
								<div key={index}>
									<div key={index+'0'} style={styles.rowContainer}>
										<div key={index+'1'} style={styles.itemContainer}>
											<div key={index+'2'} style={styles.itemEmployeeName}>{labor?.teamMember?.firstName} {labor?.teamMember?.goesBy} {labor?.teamMember?.lastName}</div>
											{/* <div key={index+'3'} style={styles.itemJobClass}>{labor?.jobClass?.className}</div> */}
											<div key={index+'3'} style={styles.itemJobClass}>{labor?.jobClass?.appDisplayName}</div>
											<div key={index+'4'} style={styles.itemStart}>{convertMilitaryToStandard(labor?.startTime)}</div>
											<div key={index+'5'} style={styles.itemFinish}>{convertMilitaryToStandard(labor?.finishTime)}</div>
											<div key={index+'6'} style={styles.itemPerDiem}>{labor?.perDiemAmount}</div>
											<div key={index+'7'} style={styles.itemStraightTime}>000</div>
											<div key={index+'8'} style={styles.itemOverTime}>000</div>
											<div key={index+'9'} style={styles.itemTotal}>{labor?.totalPrice}</div>
										</div>
									</div>
								</div>
							)
						})}

						<div style={styles.totalRowContainer}>
							<div style={styles.totalItemContainer}>
								<div style={styles.totalItemLeftColumn}>Total</div>
								<div style={styles.totalItemRightColumnContainer}>{USDollar.format(laborItemsTotal)}</div>
							</div>
						</div>

					</div>
				</div>
				
				<div style={styles.verticalSpacerLg}></div>

				<div style={styles.sectionWrapper}>
					<div style={styles.sectionBlockHeader}>
						<div style={styles.sectionBlockText}>EQUIPMENT</div>
						<div style={styles.headerRowContainer}>
							<div style={styles.headerItemContainer}>
								<div style={styles.headerItemEquipmentDescription}>Description</div>
								<div style={styles.headerItemUnitId}>Unit ID</div>
								<div style={styles.headerItemTotal}>Total</div>
							</div>
						</div>

						{equipmentItems?.map((equipment, index) => {
							return(
								<div key={index}>
									<div key={index+'0'} style={styles.rowContainer}>
										<div key={index+'1'} style={styles.itemContainer}>
											<div key={index+'2'} style={styles.itemEquipmentDescription}>{equipment?.billableDescription}</div>
											<div key={index+'3'} style={styles.itemEquipmentUnitId}>{equipment?.unitCode}</div>
											<div key={index+'4'} style={styles.itemTotal}>{equipment?.totalBilled}</div>
										</div>
									</div>
								</div>
							)
						})}

						<div style={styles.totalRowContainer}>
							<div style={styles.totalItemContainer}>
								<div style={styles.totalItemLeftColumn}>Total</div>
								<div style={styles.totalItemRightColumnContainer}>{USDollar.format(equipmentItemsTotal)}</div>
							</div>
						</div>

					</div>
				</div>

				{(equipmentItemsContinued?.length>0) && <div style={styles.sectionWrapper}>
					<div style={styles.verticalSpacerXLg}></div>
					<div style={styles.sectionBlockHeader}>
						<div style={styles.sectionBlockText}>EQUIPMENT CONT.</div>
						<div style={styles.headerRowContainer}>
							<div style={styles.headerItemContainer}>
								<div style={styles.headerItemEquipmentDescription}>Description</div>
								<div style={styles.headerItemUnitId}>Unit ID</div>
								<div style={styles.headerItemTotal}>Total</div>
							</div>
						</div>

						{equipmentItemsContinued?.map((equipment, index) => {
							return(
								<div key={index}>
									<div key={index+'0'} style={styles.rowContainer}>
										<div key={index+'1'} style={styles.itemContainer}>
											<div key={index+'2'} style={styles.itemEquipmentDescription}>{equipment?.billableDescription}</div>
											<div key={index+'3'} style={styles.itemEquipmentUnitId}>{equipment?.unitCode}</div>
											<div key={index+'4'} style={styles.itemTotal}>{equipment?.totalBilled}</div>
										</div>
									</div>
								</div>
							)
						})}

						<div style={styles.totalRowContainer}>
							<div style={styles.totalItemContainer}>
								<div style={styles.totalItemLeftColumn}>Total</div>
								<div style={styles.totalItemRightColumnContainer}>{USDollar.format(equipmentItemsTotal)}</div>
							</div>
						</div>

					</div>
				</div>}
				
				<div style={styles.verticalSpacerLg}></div>
				{/* {(pagebreakVerticalSpacer>0) && <div style={styles.verticalSpacerCustom}></div>} */}

				<div style={styles.sectionWrapper}>
					<div style={styles.sectionBlockHeader}>
						<div style={styles.sectionBlockText}>MATERIAL & SUPPLIES</div>
						<div style={styles.headerRowContainer}>
							<div style={styles.headerItemContainer}>
								<div style={styles.headerItemMaterialDescription}>Description</div>
								<div style={styles.headerItemUnitPrice}>Unit Price</div>
								<div style={styles.headerItemQuantity}>Quantity</div>
								<div style={styles.headerItemTotal}>Total</div>
							</div>
						</div>

						{materialItems?.map((material, index) => {
							const totalPrice = material?.totalPrice?.replace(/\$/g, '')
							const quantity = material?.quantity
							const itemPriceCalculated = totalPrice/quantity
							return(
								<div key={index}>
									<div key={index+'0'} style={styles.rowContainer}>
										<div key={index+'1'} style={styles.itemContainer}>
											<div key={index+'2'} style={styles.itemMaterialDescription}>{material?.description}</div>
											<div key={index+'3'} style={styles.itemUnitPrice}>{USDollar.format(itemPriceCalculated)}</div>	
											<div key={index+'4'} style={styles.itemQuantity}>{material?.quantity}</div>
											<div key={index+'5'} style={styles.itemTotal}>{material?.totalPrice}</div>
										</div>
									</div>
								</div>
							)
						})}

						<div style={styles.totalRowContainer}>
							<div style={styles.totalItemContainer}>
								<div style={styles.totalItemLeftColumn}>Total</div>
								<div style={styles.totalItemRightColumnContainer}>{USDollar.format(materialItemsTotal)}</div>
							</div>
						</div>

					</div>
				</div>

				<div style={styles.verticalSpacerLg}></div>

				<div style={styles.sectionWrapper}>
					<div style={styles.sectionBlockHeader}>
						<div style={styles.sectionBlockText}>DAILY JOB TICKET SUMMARY</div>
						<div style={styles.headerRowContainer}>
							<div style={styles.headerItemContainer}>
								<div style={styles.headerItemSummaryDescription}>Description</div>
								<div style={styles.headerItemTotal}>Total</div>
							</div>
						</div>

						<div style={styles.rowContainer}>
							<div style={styles.itemContainer}>
								<div style={styles.itemSummaryDescription}>Labor</div>
								<div style={styles.itemTotal}>{USDollar.format(laborItemsTotal)}</div>
							</div>
						</div>

						<div style={styles.rowContainer}>
							<div style={styles.itemContainer}>
								<div style={styles.itemSummaryDescription}>Equipment</div>
								<div style={styles.itemTotal}>{USDollar.format(equipmentItemsTotal)}</div>
							</div>
						</div>

						<div style={styles.rowContainer}>
							<div style={styles.itemContainer}>
								<div style={styles.itemSummaryDescription}>Materials & Supplies</div>
								<div style={styles.itemTotal}>{USDollar.format(materialItemsTotal)}</div>
							</div>
						</div>

						<div style={styles.totalRowContainer}>
							<div style={styles.totalItemContainer}>
								<div style={styles.totalItemLeftColumn}>Total</div>
								<div style={styles.totalItemRightColumnContainer}>{USDollar.format(djtTotalBilled)}</div>
							</div>
						</div>

					</div>
				</div>

				<div style={styles.verticalSpacerSm}></div>

				<div style={styles.sectionWrapper}>
					<div style={styles.sectionBlockHeader}>
						<div style={styles.sectionBlockText}>APPROVALS</div>
						<div style={styles.columnLayout}>
							<div style={styles.authColumn}>
								<div style={styles.clientAuthColumn}>Client Acceptance Authorization:</div>
								<div style={styles.signatureAuthColumn}>
									{/* <img src="/img/client-test-signature.png" alt="" /> */}
								</div>
								
							</div>

							<div style={styles.authColumn}>
								<div style={styles.spikeAuthColumn}>Spike Acceptance Authorization:</div>
								<div style={styles.signatureAuthColumn}>
									{/* <img src="/img/spike-test-signature.png" alt="" /> */}
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/> */}
		</>
	);
};

// Prop validation
DjtPdfTemplate.propTypes = {
  date: PropTypes.string.isRequired, // Date is required
  po: PropTypes.string, // PO is optional
  djtNotes: PropTypes.string.isRequired, // DJT Notes is required
  client: PropTypes.string.isRequired, // Client name is required
  location: PropTypes.string.isRequired, // Location is required
  contact: PropTypes.string.isRequired, // Contact name is required
  contactEmail: PropTypes.string.isRequired, // Contact email is required
  phone: PropTypes.string.isRequired, // Phone number is required
  billingEmail: PropTypes.string.isRequired, // Billing email is required
  laborItems: PropTypes.arrayOf(PropTypes.object), // Array of labor items
  laborItemsTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Total labor cost
  equipmentItems: PropTypes.arrayOf(PropTypes.object), // Array of equipment items
  equipmentItemsContinued: PropTypes.arrayOf(PropTypes.object), // Array of continued equipment items
  equipmentItemsTotal:PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Total equipment cost
  materialItems: PropTypes.arrayOf(PropTypes.object), // Array of material items
  materialItemsTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Total material cost
  djtTotalBilled: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // Total billed amount
};

