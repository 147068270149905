import { updatePrimaryUnit } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const updateUnit = async (props) => {
  if (!props) return;

  try {
    console.log('params: ', props);

    const gqlParams = {
      input: props
    };

    const response = await client.graphql({
      query: updatePrimaryUnit,
      variables: gqlParams
    });

    console.log('updatePrimaryUnit response: ', response);
    const results = response.data.updateSpkPrimaryUnitTbl.id;
    console.log('updatePrimaryUnit results: ', results);

    return results;
  } catch (err) {
    return ('error updateUnit: ', [err]);
  }
};

updateUnit.propTypes = {
  props: PropTypes.object.isRequired,
};
