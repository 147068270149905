import PropTypes from 'prop-types';
import { AsyncCreatableSelect } from "chakra-react-select";
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Divider,
} from "@chakra-ui/react";
import { chakraStyles } from './SelectStyles';

export function FormSelectAsyncCreatableSingle(props) {
  const { 
    reference,
    placeholder,
    control, 
    fieldname, 
    fieldlabel='', 
    defaultOptionsArray=[],
    optionsArray=[], 
    selectedoption,
    width,
    isDisabled=false, 
    onChange, 
    // onBlur,
    onFocus,
    onMenuOpen,
    isMulti=false,
    // value,
    defaultValue,
    className='',
    classNamePrefix='',
    // variant="brandPrimary",
    errors={},
    rules,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    onInputChange,
  } = props;

  return (
    <Controller
      control={control}
      name={fieldname}
      rules={rules}
      render={({ 
        field: { onBlur, value, name, ref }
      }) => (
        <FormControl 
          width={width}
          id={fieldname} 
          isInvalid={!!errors[fieldname]}>
          {fieldlabel && <FormLabel>{fieldlabel}</FormLabel>}
          <AsyncCreatableSelect
            components={{ 
              ClearIndicator: null,
              IndicatorSeparator: () => <Divider orientation='vertical' color='spikegray.600'/>,
            }}
            name={name}
            ref={reference || ref}
            // onChange={onChange}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onMenuOpen={onMenuOpen}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            onInputChange={onInputChange}
            value={selectedoption || value}
            defaultOptions={defaultOptionsArray}
            loadOptions={(inputValue, callback) => {
              setTimeout(() => {
                const filteredOptions = inputValue?.length < 1 
                  ? optionsArray 
                  : optionsArray.filter(i =>
                      i.label.toLowerCase().includes(inputValue.toLowerCase())
                    );
                    callback(filteredOptions);
              }, 100);
            }}
            placeholder={placeholder||''}
            selectedOptionColorScheme="spikegray"
            className={className}
            classNamePrefix={classNamePrefix}
            closeMenuOnSelect={!isMulti}
            isDisabled={isDisabled}
            isClearable
            defaultValue={defaultValue}
            chakraStyles={chakraStyles}
          />
          <FormErrorMessage>{errors[fieldname]?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}

// Prop validation
FormSelectAsyncCreatableSingle.propTypes = {
  reference: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  fieldname: PropTypes.string.isRequired,
  fieldlabel: PropTypes.string,
  defaultOptionsArray: PropTypes.arrayOf(PropTypes.object),
  optionsArray: PropTypes.array,
  selectedoption: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onMenuOpen: PropTypes.func,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  // variant: PropTypes.string,
  errors: PropTypes.object,
  rules: PropTypes.object,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func,
  onInputChange: PropTypes.func,
};

