import { fetchCreditCardByTeamMemberId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const fetchAccountsByTeamMember = async (environment, tmId) => {
  try {
    let allSort = 'all';
    if (environment === 'sandbox') {
      allSort = 'sandbox';
    }

    const gqlParams = {
      teamMemberId: tmId,
      filter: { allSort: { eq: allSort } },
    };

    console.info('gqlParams: ', gqlParams);

    const response = await client.graphql({
      query: fetchCreditCardByTeamMemberId,
      variables: gqlParams,
    });

    console.info('cards response: ', response);

    const results = response.data.creditCardTeamMemberByTeamMemberId.items
      .sort((a, b) => (a.isDefault > b.isDefault ? -1 : 1));

    return results;
  } catch (err) {
    return 'error fetchAccountsByTeamMember: ' + err;
  }
};

fetchAccountsByTeamMember.propTypes = {
  environment: PropTypes.string.isRequired,
  tmId: PropTypes.string.isRequired,
};