export const getTransactions = async (token, startdate, enddate) => {
  if (!token) {
    console.warn('getTransactions called without a token')
    return null
  }

  const response = await fetch('https://znnc7t6exxutdn3g6wq7hdsbra0gacqf.lambda-url.us-east-1.on.aws/?token='+token+'&startdate='+startdate+'&enddate='+enddate, { 
    method: 'GET', 
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
    })
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const result = await response.json();
  console.log('getTransactions result: ', result)

  return result;
  
}