import { allUnitSubTypes } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const fetchUnitSubTypes = async (allSort) => {
  try {
    const gqlParams = {
      allSort: allSort||'all'
    };

    const response = await client.graphql({
      query: allUnitSubTypes,
      variables: gqlParams
    });

    // console.log('response: ', response)
    const results = response.data.allUnitSubTypesSortableByName.items;
    // console.log('results: ', results)
    
    return results;
  } catch (err) {
    return ('error fetchUnitSubTypes: ', [err]);
  }
};

fetchUnitSubTypes.propTypes = {
  allSort: PropTypes.string.isRequired,
};
