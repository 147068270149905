// generic functions
import { truncateString } from '../functions/truncateString'

// graphql components
import { fetchClientContactByClientContactId } from '../graphqlCompnents/DJT/fetchClientContactByClientContactId.jsx';

// PDF
import { callAddFont } from '../Arial Narrow Bold-normal'
import jsPDF from 'jspdf';
import { DjtPdfTemplate } from '../DjtPdfTemplate';
import { renderToStaticMarkup } from 'react-dom/server';
import { fetchDailyJobTicketById } from '../graphqlCompnents/DJT/fetchDailyJobTicketById.jsx';
import { alphaNumeric } from '../functions/random'


import { uploadBlobToS3 } from '../AWS/S3/UploadBlobToS3.jsx'
import { pushFileToQB } from '../QuickBooks/pushFileToQuickBooks.jsx'
import { updateDJTAttachedPDF } from '../graphqlCompnents/DJT/updateDJTAttachedPDF.jsx'


async function fetchContact(djtClientContactId) {
  const contact = await fetchClientContactByClientContactId(djtClientContactId);
  // console.warn(' CONTACT?: ', contact)
  // contact.phone = formatToPhoneData(contact?.phone);
  // if (contact?.phone !== '' && contact?.phone !== null) {
  //     contact.phone = formatToPhoneData(contact.phone);
  // }
  return contact
}

export const renderTemplateToHTML = async (djtId) => {

  const djtProps = await fetchDailyJobTicketById(djtId)

  const updatedLabor = djtProps?.djtLabor?.items?.map(item => {
    // If isTempLabor is true, then we perform our little "transformation".
    if (item.isTempLabor) {
      return {
        ...item, // Spread the existing properties (just in case there are others we care about)
        teamMember: { ...item.tempEmployee, goesBy: '' }, // Replace teamMember with tempEmployee
      };
    }
  
    // If isTempLabor is false, we keep the item unchanged.
    return item;
  });

  // console.log('updatedLabor: ', updatedLabor)

  // console.log('-------------------------')
  // console.info('DJT PROPS: ', djtProps)
  // console.log('-------------------------')

  let clientContact = await fetchContact(djtProps.djtClientContactId)
  // console.info('clientContact: ', clientContact)
  //showLaborHours
  // const clientName = djtProps?.djtClient?.contactName
  // const supervisorName = djtProps?.djtSupervisorAuthName
  
  const doShowHours = djtProps?.showLaborHours||false
  const mergeMaterialEquipment = djtProps?.mergeMaterialEquipment
  const equipmentItems = djtProps?.djtEquipment?.items
  const materialItems = djtProps?.djtMaterial?.items
  let equipmentItemsToDisplay = equipmentItems
  let equipmentItemsToDisplayContinued = []
  let materialItemsToDisplay = []
  let materialItemsTotalToDisplay = 0
  let equipmentItemsTotalToDisplay = 0
  if (mergeMaterialEquipment===true) {
    if (materialItems?.length>0) {
      materialItems.forEach(material => {
        const equipmentObj = {
          billableDescription: material?.description,
          quantity: material?.quantity,
          totalBilled: material?.totalPrice,
          unitId: '',
          unitCode: '',
        }
        equipmentItemsToDisplay.push(equipmentObj)
      })
    }
    // console.info('equipment before: ', equipmentItems)
    // console.info('materialItems before: ', materialItems)
    // equipmentItemsToDisplay.concat(...materialItems)
    // console.info('equipment after: ', equipmentItemsToDisplay)
    // console.info('equipment total: ', Number(djtProps?.djtEquipmentTotal))
    // console.info('material total: ', Number(djtProps?.djtMaterialTotal))
    equipmentItemsTotalToDisplay = Number(djtProps?.djtEquipmentTotal) + Number(djtProps?.djtMaterialTotal)
    // console.info('merged total: ', equipmentItemsTotalToDisplay)
  } else {
    materialItemsToDisplay = materialItems
    equipmentItemsTotalToDisplay = djtProps?.djtEquipmentTotal
    materialItemsTotalToDisplay = djtProps?.djtMaterialTotal
  }

  // console.info('equipmentItemsToDisplay: ', equipmentItemsToDisplay)
  // console.info('equipmentItemsTotalToDisplay: ', equipmentItemsTotalToDisplay)
  // console.info('materialItemsToDisplay: ', materialItemsToDisplay)
  // console.info('materialItemsTotalToDisplay: ', materialItemsTotalToDisplay)

  // for each ~100 characters in the job description, we need to add to the count for line break issues
  // const jobDescriptionLength = djtProps?.djtNotes?.length
  // console.info('jobDescriptionLength: ', jobDescriptionLength)
  // let additionalRows = 0
  // if (jobDescriptionLength>100) {
  //   // for each 100 rows, add an additional line
  //   // let splitCount = Math.ceil(jobDescriptionLength/100)
  //   // console.info(' !!!!! splitCount: ', splitCount)
  //   // additionalRows = Math.ceil(splitCount/1.75)
  //   // console.info(' !!!!! additionalRows: ', additionalRows)
  // }

  // if total labor and equipment is 8 items and their are items in the materials list, push the materials table down past the page break
  // const maxRowsBeforeEquipmentSplit = 12
  // const currentLaborRows = djtProps?.djtLabor?.items?.length
  // const currentEquipmentRows = equipmentItemsToDisplay?.length
  // const visibleLaborRows = (doShowHours) ? currentLaborRows : 0

  // console.info('indexToSplit: ', indexToSplit)
  // console.info('currentRows: ', currentRows)
  // console.info('currentLaborRows: ', currentLaborRows)
  // console.info('maxRowsBeforeMaterialMove: ', maxRowsBeforeMaterialMove)
  // console.info('maxRowsBeforeEquipmentSplit: ', maxRowsBeforeEquipmentSplit)
  // if (currentRows>=maxRowsBeforeMaterialMove && currentRows<=maxRowsBeforeEquipmentSplit) {
  //   // material table is going to be sitting on the page break
  //   if (currentRows===7) {
  //     pagebreakVerticalSpacer=200
  //   }
  //   if (currentRows===8) {
  //     pagebreakVerticalSpacer=160
  //   }
  //   if (currentRows===9) {
  //     pagebreakVerticalSpacer=120
  //   }
  //   if (currentRows===10) {
  //     pagebreakVerticalSpacer=80
  //   }
  //   if (currentRows===11) {
  //     pagebreakVerticalSpacer=40
  //   }
  //   if (currentRows===12) {
  //     pagebreakVerticalSpacer=40
  //   }
  // }
  
  // if (currentRows>maxRowsBeforeEquipmentSplit) {
  //   let firstPageArr = equipmentItemsToDisplay.slice(0, indexToSplit)
  //   let secondPageArr = equipmentItemsToDisplay.slice(indexToSplit)
  //   equipmentItemsToDisplay = firstPageArr
  //   equipmentItemsToDisplayContinued = secondPageArr
  // }

  // split on daily jib ticket summary

  // console.info('equipmentItemsToDisplay: ', equipmentItemsToDisplay)
  // console.info('equipmentItemsToDisplayContinued: ', equipmentItemsToDisplayContinued)
  const elementToRender = <DjtPdfTemplate 
    date={djtProps?.djtDate}
    po={djtProps?.djtPO?.po}
    djtNotes={djtProps?.djtNotes}
    client={djtProps?.djtClient?.contactCompany || djtProps?.djtClient?.qbCompany}
    location={truncateString(djtProps?.djtLocation, 30)}
    // contact={djtProps?.djtClient?.contactName}
    contact={clientContact?.name||null}
    // contactEmail={djtProps?.djtClient?.contactEmail}
    contactEmail={truncateString(clientContact?.email||null, 30)}
    // phone={djtProps?.djtClient?.contactPhone}
    phone={clientContact?.phone||null}
    billingEmail={truncateString(djtProps?.djtClient?.qbEmail||null, 30)}
    // laborItems={(doShowHours) ? djtProps?.djtLabor?.items : []}
    laborItems={(doShowHours) ? updatedLabor : []}
    laborItemsTotal={djtProps?.djtLaborTotal}
    // equipmentItems={djtProps?.djtEquipment?.items}
    equipmentItems={equipmentItemsToDisplay}
    equipmentItemsContinued={equipmentItemsToDisplayContinued}
    // equipmentItemsTotal={djtProps?.djtEquipmentTotal}
    equipmentItemsTotal={equipmentItemsTotalToDisplay}
    // materialItems={djtProps?.djtMaterial?.items}
    materialItems={materialItemsToDisplay}
    // materialItemsTotal={djtProps?.djtMaterialTotal}
    materialItemsTotal={materialItemsTotalToDisplay}
    djtTotalBilled={djtProps?.djtTotalBilled}
    // pagebreakVerticalSpacer={pagebreakVerticalSpacer}
  />

  const renderedHTML = await renderToStaticMarkup(elementToRender)

  return renderedHTML
}

export const generatePdf = async (djt, token, environment, doDownloadPdf, doUploadPdf, identityId) => {

  // to upload, we have to have the token and environment

  // console.warn(' WORKING WITH DJT: ', djt)
  const djtId = djt.id
  const djtDate = djt.djtDate
  const accessToken = (token) ? token?.accessToken : null
  const qbEnv = (environment) ? environment : 'sandbox'
  const downloadPdf = (doDownloadPdf) ? doDownloadPdf : false
  const uploadPdf = (doUploadPdf) ? doUploadPdf : false

  const renderedHTML = await renderTemplateToHTML(djtId)
  console.log('renderedHTML: ', renderedHTML)

  jsPDF.API.events.push(["addFonts",  callAddFont]);
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'px',
    format: 'letter',
    hotfixes: ["px_scaling"],
  });

  const randomString = alphaNumeric(6)
  let filename = 'DJT-'+djtDate+'-'+randomString+'.pdf'
  filename = filename.replace(" ","")
  // setPdfFileName(filename)
  console.warn(' -----> filename: ', filename)
  let attachableObjId = ''

  doc.setFont('Arial Narrow Bold', 'normal');
  const pdfOperationCompleted =  new Promise((resolve) => {
    doc.html(renderedHTML, {
      callback: async (doc) => {

        let processPdfResults = null

        if (downloadPdf===true) {
          console.log('DOWNLOADING FILE: ', filename)
          // doc.save(`${new Date().toISOString()}.pdf`);
          doc.save(`${filename}`);
        } 

        if (uploadPdf===true) {
          let blobPDF = new Blob([doc.output('blob')], {type: 'applications/pdf'})
          const params = {file: blobPDF, filename: filename, filetype: 'application/pdf', bucket: 'qb-invoice-attachments-prod', identityId: identityId}
          console.warn(' xxxxx uploadPdf PARAMS: ', params)
          const result = await uploadBlobToS3(params);
          console.warn(' xxxxx uploadPdf RESULTS: ', result)
          processPdfResults = await pushFileToQB(result?.fileName, result?.key, result?.identityId, accessToken, qbEnv)
          console.warn(' xxxxx processPdfResults: ', processPdfResults)
          attachableObjId = processPdfResults?.Id
          // const attachableResults = await updateDJTAttachedPDF(djtId, attachableObjId)
          await updateDJTAttachedPDF(djtId, attachableObjId)
          // console.log(' xxxxx attachableResults: ', attachableResults)
          // return processPdfResults
        }
        resolve(processPdfResults);
        
      },
    });
  });

  return await pdfOperationCompleted;
};

export const generatePdfX = async (djtId, djtDate, doLocalDownload) => {
  let downloadToComputer = null
  if (!doLocalDownload) {
    downloadToComputer = false
  } else {
    downloadToComputer = doLocalDownload
  }

  // need to truncate location doen to ~30 characters @ line 675
  const renderedHTML = await renderTemplateToHTML(djtId)

  jsPDF.API.events.push(["addFonts",  callAddFont]);
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'px',
    format: 'letter',
    hotfixes: ["px_scaling"],
  });

  const randomString = alphaNumeric(6)
  // const filenameCompany = currentDJT?.djtClient.contactCompany || currentDJT?.djtClient.qbCompany
  const filenameDate = djtDate
  const filename = 'DJT '+filenameDate+'-'+randomString

  let attachableObjId = ''

  doc.setFont('Arial Narrow Bold', 'normal');
  doc.html(renderedHTML, {
      async callback(doc) {
      // let blobPDF = new Blob([doc.output('blob')], {type: 'applications/pdf'})

      // const filename = uuidv4()
      // DJT Date Client Random
      
      // const params = {file: blobPDF, filename: filename+'.pdf', filetype: 'application/pdf', bucket: 'qb-invoice-attachments'}

      // doc.save(`${new Date().toISOString()}.pdf`);
      // doc.output('dataurlnewwindow')
      // window.open(doc.output('bloburl'), '_new');
      (downloadToComputer===true) && doc.save(`${filename}.pdf`);
      // const blob = doc.output('datauristring');
      // console.log(blob)
      // return blob

      return attachableObjId
    },
  });
  // fix this
  return attachableObjId
};