import { listPlaidPendingTransactionByCardNumber } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const fetchPlaidPendingTransactionsByTeamMember = async (card) => {
  if (!card) {
    console.log('Team member card number is required.');
    return null;
  }

  try {
    const gqlParams = {
      cardNumber: card,
      sortDirection: 'DESC',
    };

    const response = await client.graphql({
      query: listPlaidPendingTransactionByCardNumber,
      variables: gqlParams,
    });

    console.log('fetchPlaidPendingTransactionsByTeamMember response ' + card + ': ', response);

    const results = response.data.plaidPendingTransactionByCardNumber?.items;

    return results;
  } catch (err) {
    return 'error fetchPlaidPendingTransactionsByTeamMember: ' + err;
  }
};

fetchPlaidPendingTransactionsByTeamMember.propTypes = {
  card: PropTypes.string.isRequired,
};