import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { getContactCompanyByCompanyId } from '../../../graphql/custom_queries';

const client = generateClient();

export const fetchContactCompanyByCompanyId = async (id) => {
  if (id) {
    try {
      // console.log('id: ', id)
      const response = await client.graphql({
        query: getContactCompanyByCompanyId,
        variables: {
          id: id,
        },
      });
      const results = response.data.getSpkDJTClientTbl;
      return results;
    } catch (err) {
      return 'error fetchContactCompanyByCompanyId: ' + err;
    }
  } else {
    return null;
  }
};

fetchContactCompanyByCompanyId.propTypes = {
  id: PropTypes.string.isRequired,
};
