import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useOAuth2 } from "@tasoskakour/react-use-oauth2";

export const QuickBooksAuth = ({ qbAppId='Invoices', qbEnv='sandbox', children, onTokenChange }) => {
  const [isLoggedInQB, setIsLoggedInQB] = useState(false);

  let clientId = '';
  let appUrl = '';
  
  if (qbAppId === 'Invoices') {

    clientId = qbEnv === 'production' ? 
    'ABP8PJqGvfaI3tNtXuvKC9iwnL9ZUIDyN8plAbDrvihUbeF697' : 
    'ABrc9XINDdYVQiBR51Buxkl84gNwrX8mHLi4zfwYhfPTPgHvhL';

    appUrl = 'ywr5n26siri25kupsa7noxc5hu0xdiwk';
  }

  if (qbAppId === 'ManageExpenses') {

    clientId = qbEnv === 'production' ? 
    'ABCZV9104pvuddVgnTpQQMPBLOhTw9ZdxJ6PUh2OZa9VIACOmc' : 
    'ABr4PG9kkm2kV0F9dBiLyCpcJoUMjO24byqykNIN6sOSFBLiuV';

    appUrl = 'ivwnuafbelpqvtxjz7hdispzim0uarwx';
  }
  
  const redirectUri = `${document.location.origin}/callback`;

  const {
    data,
    loading,
    getAuth,
    logout,
  } = useOAuth2({
    authorizeUrl: "https://appcenter.intuit.com/connect/oauth2",
    clientId: `${clientId}`,
    redirectUri: redirectUri,
    scope: "com.intuit.quickbooks.accounting",
    responseType: "code",
    exchangeCodeForTokenQueryFn: async (callbackParameters) => {
      const response = await fetch(`https://${appUrl}.lambda-url.us-east-1.on.aws?qbenv=${qbEnv}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...callbackParameters,
          qbenv: qbEnv,
          client_id: clientId,
          redirect_uri: redirectUri,  // Include the redirect_uri here
        }),
      });
      if (!response.ok) throw new Error('Failed to exchange code for token');
      const tokenData = await response.json();
      return tokenData;
    },
    onSuccess: (payload) => {
      console.log("onSuccess payload", payload);
      if (payload?.error) {
        setIsLoggedInQB(false);
        console.error("QB OAuth2 Error", payload.error);
      } else {
        try {
          const accessTokenObject = {
            accessToken: payload.access_token,
            expirationTime: new Date().getTime() + (Number(payload.expires_in) * 1000)
          };
          localStorage.setItem('accessToken', JSON.stringify(accessTokenObject));
          localStorage.setItem('refreshToken', payload.refresh_token);
          setIsLoggedInQB(true);
          onTokenChange(payload);
        } catch (error) {
          console.error("Error setting tokens in localStorage:", error);
        }
      }
    },
    onError: (error_) => {
      console.log("QB OAuth2 Error", error_);
      if (error_.message === "Popup was closed before completing authentication.") {
        console.error("Authentication was cancelled. Please try again.");
      } else {
        console.error("An error occurred during authentication. Please try again.");
      }
    },
  });

  // updated useEffect
  useEffect(() => {
    const restoreSession = async () => {
      const isLoggedIn = await checkLoginStatus();  // Now also handles token refresh
      setIsLoggedInQB(isLoggedIn);
  
      if (!isLoggedIn && qbEnv) {
        console.log("User is not logged in. They can initiate login when ready.");
      }
    };
  
    restoreSession();  // Call the session restore logic when the component mounts
  }, [qbEnv]);

  // Updated checkLoginStatus
  const checkLoginStatus = async () => {
    const storedTokenString = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    
    if (storedTokenString) {
      const accessTokenObject = JSON.parse(storedTokenString);
      const { accessToken, expirationTime } = accessTokenObject;
  
      if (new Date().getTime() > expirationTime) {
        console.warn('Token has expired.');
  
        if (refreshToken) {
          // Try refreshing the token
          try {
            const newTokenData = await refreshAuthToken(refreshToken);
            localStorage.setItem('accessToken', JSON.stringify({
              accessToken: newTokenData.access_token,
              expirationTime: new Date().getTime() + (Number(newTokenData.expires_in) * 1000)
            }));
            localStorage.setItem('refreshToken', newTokenData.refresh_token);
            return true;
          } catch (error) {
            console.error('Failed to refresh token:', error);
            return false;
          }
        } else {
          console.warn('No refresh token available.');
          return false;
        }
      }
  
      if (!accessToken) {
        console.warn('No access token found in local storage.');
        return false;
      }
  
      return true;
    }
  
    return false;
  };

  // New refreshAuthToken
  const refreshAuthToken = async (refreshToken) => {
    const response = await fetch(`https://${appUrl}.lambda-url.us-east-1.on.aws/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh_token: refreshToken,
        qbenv: qbEnv,
        client_id: clientId,
        redirect_uri: redirectUri,
      }),
    });

    console.info('REFRESHED QB AUTH TOKEN RESPONSE: ', response)
  
    if (!response.ok) throw new Error('Failed to refresh token');
    
    const tokenData = await response.json();
    return tokenData;
  };

  const loginQB = () => {
    getAuth();
  };

  const logoutQB = () => {
    console.log("Logging out...");
    logout();
    setIsLoggedInQB(false);
    onTokenChange(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return children({ 
    isLoggedInQB, 
    loginQB, 
    logoutQB, 
    tokenObj: data,
  });
};

QuickBooksAuth.propTypes = {
  qbAppId: PropTypes.oneOf(['Invoices', 'ManageExpenses']),
  qbEnv: PropTypes.oneOf(['production', 'sandbox']).isRequired,
  children: PropTypes.func.isRequired,
  onTokenChange: PropTypes.func.isRequired,
};

export default QuickBooksAuth;