import { uploadData } from 'aws-amplify/storage';
import { fetchAuthSession } from 'aws-amplify/auth';
import { addFileToUploadedDocuments } from '../../graphqlCompnents/AWS/S3/addFileToUploadedDocuments';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

export const uploadFileToS3 = async (props) => {
  if (!props) {
    console.log('ERROR: No props sent.');
    return 'ERROR: No props';
  }

  try {
    const session = await fetchAuthSession();
    const identityId = session.identityId;
    const fileobj = props.file;
    const filename = props.filename||props.file.name;
    // const [extension] = filename.split('.');
    const extension = filename.split('.').pop();
    // const filename_extension = filename.split('.').slice(-2).join('.');
    const mimeType = props.filetype;
    const bucket = props.bucket;
    const fileKey = 'protected/' + identityId + '/' + uuidv4() + '.' + extension;

    const fileParams = {
      bucket: bucket,
      identityId: identityId,
      //protected/us-east-1:acfe627b-7f9c-c12b-46a1-8d65dc75169a/a1d3aec5-5890-4b9e-a32a-cab61307d46d.png
      key: fileKey,
    };

    const uploadedDocumentId = await addFileToUploadedDocuments(fileParams);

    await uploadData({
      path: fileKey,
      data: fileobj,
      options: {
        contentType: mimeType,
        bucket: {
          bucketName: bucket,
          region: 'us-east-1',
        },
      },
    });

    const returnData = {
      fileName: fileKey,
      // fileName: filename,
      identityId: identityId,
      key: fileKey,
      documentId: uploadedDocumentId,
    };

    return returnData;
  } catch (err) {
    return err;
  }
};

uploadFileToS3.propTypes = {
  props: PropTypes.shape({
    file: PropTypes.object.isRequired,
    filename: PropTypes.string.isRequired,
    filetype: PropTypes.string.isRequired,
    bucket: PropTypes.string.isRequired,
  }).isRequired,
};
