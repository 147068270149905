import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Center,
  Divider,
  VStack
} from "@chakra-ui/react";

import { AvailableIcons } from "../Icons/AvailableIcons";
import { ButtonNonaryPlain } from "../Form/Button/ButtonNonaryPlain";

import PropTypes from 'prop-types';
// import { TextInputDate } from "../Form/Input/TextInputDate";

export const CenteredSingleButtonConfirmationModal = (props) => {

  const {
    isModalOpen,
    onModalClose,
    headerIcon='outlinecheckcircle',
    headerIconColor='var(--success-green)',
    headerText='Success!',
    bodyContent='',
    closeButtonDisabled,
    handleSubmitModalClosed,
  } = props

  return (
    <>
      <Modal 
        closeOnEsc={false} 
        blockScrollOnMount={true} 
        closeOnOverlayClick={false}  
        onClose={onModalClose} 
        isOpen={isModalOpen} 
        onCloseComplete={handleSubmitModalClosed} 
        isCentered>
        <ModalOverlay 
          bg='none'
          backdropFilter='auto'
          backdropBrightness='60%'
          backdropBlur='2px'
        />
        <ModalContent maxH={'310px'} maxW={'500px'} bg={'var(--dark-menu-background)'}>
        
          <ModalHeader>
            <Box mt={'5px'} h={'105px'}>
              <Center><VStack p='0px' m='0px'>
                <Box><AvailableIcons boxSize={'60px'} iconRef={headerIcon} color={headerIconColor}/></Box>
                <Box><Text as="span" textStyle='heading-2'>{headerText}</Text></Box>
              </VStack></Center>
            </Box>

            {/* <Box mt={'5px'} h={'105px'}>
            <Center><VStack p='0px' m='0px'>
              <Box h='70px'></Box>
              <Box><Text as="span" textStyle='heading-2'>{bodyContent}</Text></Box>
            </VStack></Center>
            </Box> */}
          </ModalHeader>

          <ModalBody mx={'25px'} mb={'20px'}>
            <Center>
              <Box w='250px'>
                <Text align='center' textStyle='modal-content-text'>{bodyContent}</Text>
              </Box>
            </Center>
          </ModalBody>

          <ModalFooter mx={'0px'} px={'0px'} my={'0px'} py={'0px'}>
            <Box width={'100%'}>
            <Divider color='var(--dark-divider)'/>
              <Center>
                <ButtonNonaryPlain 
                  width='100px'
                  name='closeSubmitModal'
                  value='Close'
                  onClick={onModalClose}
                  isDisabled={closeButtonDisabled}
                />
              </Center>
            </Box>
          </ModalFooter>

        </ModalContent>
      </Modal>
    </>
  )
}

CenteredSingleButtonConfirmationModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  headerIcon: PropTypes.string,
  headerIconColor: PropTypes.string,
  headerText: PropTypes.string,
  bodyContent: PropTypes.string,
  closeButtonDisabled: PropTypes.bool,
  handleSubmitModalClosed: PropTypes.func.isRequired,
}