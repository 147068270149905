import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { allAuxillaryUnitsSortableByName } from '../../../graphql/custom_queries';

const client = generateClient();

export const fetchAllAuxillaryUnitOptions = async (searchLimit) => {
  try {
    const gqlParams = {
      allSort: "all",
      sortDirection: 'ASC',
      limit: searchLimit
    };

    const response = await client.graphql({
      query: allAuxillaryUnitsSortableByName,
      variables: gqlParams
    });

    // console.log('RESPONSE: ', response)
    const results = response.data.allAuxillaryUnitsSortableByName.items;

    return results;
  } catch (err) {
    return 'error fetchAllAuxillaryUnitOptions: ' + err;
  }
};

fetchAllAuxillaryUnitOptions.propTypes = {
  searchLimit: PropTypes.number.isRequired,
};
