export function toTitleCase(str) {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

export function trimNumbersFromEnd (str) {
  return str.replace(/\d+$/, '');
}

export function truncateString(string, toLength) {
  if (string?.length>toLength && toLength) {
    let truncatedString = string.substring(0, toLength).concat('...')
    return truncatedString
  } else {
    return string
  }
}