import { listPlaidTransactionByMatched } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const fetchPlaidPostedTransactionsByMatched = async (status) => {
  let matchedStatus = "unmatched";
  if (status) {
    matchedStatus = status;
  }

  try {
    const gqlParams = {
      matched: matchedStatus,
      sortDirection: 'DESC',
      limit: 100,
    };

    const response = await client.graphql({
      query: listPlaidTransactionByMatched,
      variables: gqlParams,
    });
    console.log('response: ', response);

    const results = response.data.plaidTransactionByMatched?.items;

    return results;
  } catch (err) {
    return 'error fetchPlaidPostedTransactionsByMatched: ' + err;
  }
};

fetchPlaidPostedTransactionsByMatched.propTypes = {
  status: PropTypes.string,
};