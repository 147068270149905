import { allUnitTypes } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const fetchUnitTypes = async (allSort) => {
  try {
    const gqlParams = {
      allSort: allSort||"all"
    };

    const response = await client.graphql({
      query: allUnitTypes,
      variables: gqlParams
    });

    const results = response.data.allUnitTypesSortableByName.items;

    // Uncomment if sorting is required
    // results.sort((a, b) => (a.code > b.code ? 1 : -1));

    return results;
  } catch (err) {
    return ('error fetchUnitTypes: ', [err]);
  }
};

fetchUnitTypes.propTypes = {
  allSort: PropTypes.string.isRequired,
};
