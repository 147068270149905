export const chakraStyles = {
  placeholder: base => ({ 
    ...base, 
    color: '#ffffff'
  }),
  option: base => ({
    ...base,
    color:'spikegray.100',
    bg:'spikegray.800',
    _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' },
  }),
  menuList: base => ({
    ...base,
    color:'spikegray.100',
    bg:'spikegray.800',
    _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' },
  }),
}
export const customStyles = {
  menuPortal: base => ({ 
    ...base, 
    fontSize: '15px',
    // zIndex: 9999, 
    zIndex: 9000, 
  }),
}


