import { deleteUnitDriver } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const removeUnitDriver = async (operatorId) => {
  if (!operatorId) return;

  try {
    const params = {
      id: operatorId,
    };

    const response = await client.graphql({
      query: deleteUnitDriver,
      variables: { input: params },
    });

    const results = response.data.deleteSpkDJTDriverTbl.id;
    return results;
  } catch (err) {
    return ('error removeUnitDriver: ', [err]);
  }
};

removeUnitDriver.propTypes = {
  operatorId: PropTypes.string.isRequired,
};
