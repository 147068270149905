import { createTeamMember } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const addTeamMember = async (props) => {
  if (!props) return;
  try {
    console.log('props: ', props);

    const response = await client.graphql({
      query: createTeamMember,
      variables: { input: props }
    });

    const results = response.data.createSpkTeamMemberTbl.id;
    return results;
  } catch (err) {
    return ('error addTeamMember: ', [err]);
  }
}

addTeamMember.propTypes = {
  props: PropTypes.shape({
    allSort: PropTypes.string,
    loginEmail: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    goesBy: PropTypes.string,
    dob: PropTypes.string,
    hireDate: PropTypes.string,
    billableDivisionJobClassId: PropTypes.string,
    doTrackPunches: PropTypes.bool,
    djtHideByDefault: PropTypes.bool,
    divisionId: PropTypes.string,
    badgeNumber: PropTypes.string,
    employeeNumber: PropTypes.string,
    addCity: PropTypes.string,
    addState: PropTypes.string,
    addStreet: PropTypes.string,
    addZipcode: PropTypes.string,
    phoneNum: PropTypes.string,
    isActive: PropTypes.bool,
    isGlobalAdmin: PropTypes.bool,
    emergcyContName: PropTypes.string,
    emergcyContPhone: PropTypes.string,
    canTransfer: PropTypes.bool,
  }).isRequired,
};
