import {
  Box,
  Text,
  Progress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Center,
  Divider,
  VStack
} from "@chakra-ui/react";

import { AvailableIcons } from "../Icons/AvailableIcons";
import { ButtonNonaryPlain } from "../Form/Button/ButtonNonaryPlain";

// import { TextInputDate } from "../Form/Input/TextInputDate";

export const CenteredStatusProgressModal = (props) => {

  const {
    isModalOpen,
    onModalClose,
    content,
    closeButtonDisabled,
    handleSubmitModalClosed,
    progress,
    successMessage,
  } = props

  return (
    <>
      <Modal 
        closeOnEsc={false} 
        blockScrollOnMount={true} 
        closeOnOverlayClick={false}  
        onClose={onModalClose} 
        isOpen={isModalOpen} 
        onCloseComplete={handleSubmitModalClosed} 
        isCentered>
        <ModalOverlay 
          bg='none'
          backdropFilter='auto'
          backdropBrightness='60%'
          backdropBlur='2px'
        />
        <ModalContent maxH={'310px'} maxW={'500px'} bg={'var(--dark-menu-background)'}>
        {(progress===100) ? <>
          <ModalHeader>
            <Box mt={'5px'} h={'105px'}>
              <Center><VStack p='0px' m='0px'>
                <Box><AvailableIcons boxSize={'60px'} iconRef={'outlinecheckcircle'} color={'var(--success-green)'}/></Box>
                <Box><Text as="span" textStyle='heading-2'>Success!</Text></Box>
              </VStack></Center>
            </Box>
          </ModalHeader></> : <>
          <ModalHeader mx={'25px'}>
            <Box mt={'5px'} h={'105px'}>
            <Center><VStack p='0px' m='0px'>
              <Box h='70px'></Box>
              <Box><Text as="span" textStyle='heading-2'>{content}</Text></Box>
            </VStack></Center>
            </Box>
          </ModalHeader></>}

          <ModalBody mx={'25px'} mb={'20px'}>
            <Progress mb={'25px'} value={progress} size='sm' hasStripe colorScheme='progressbar' />
            {(progress===100) ? 
              <Center>
                <Box w='250px'>
                  <Text align='center' textStyle='modal-content-text'>{successMessage}</Text>
                </Box>
              </Center> : <Center><Box height='50px'></Box></Center>}
          </ModalBody>
          <ModalFooter mx={'0px'} px={'0px'} my={'0px'} py={'0px'}>
            {/* enable button on completion of submission */}
            {(progress===100) ? <>
            <Box width={'500px'}>
              <Divider color='var(--dark-divider)'/>
              <Center>
                <ButtonNonaryPlain 
                  width='100px'
                  name='closeSubmitModal'
                  value='Close'
                  onClick={onModalClose}
                  isDisabled={closeButtonDisabled}
                />
              </Center>
            </Box>
            </> : <><Box h='45px'></Box></>}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}