// cognitoIdentityService.js
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";

// Configure your Cognito Identity Pool
const identityPoolId = "us-east-1:9b337b3c-ff2f-4a5b-9ca3-1760a93e1f0b"; // Example: us-east-1:xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
const region = "us-east-1"; // Example: us-east-1

// Create a Cognito Identity Client
const cognitoClient = new CognitoIdentityClient({ region });

// Function to get the current user's identityId
export const getIdentityId = async () => {
  try {
    // Create a credential provider for the identity pool
    const credentialsProvider = fromCognitoIdentityPool({
      client: cognitoClient,
      identityPoolId,  // Your Identity Pool ID
    });

    // Retrieve credentials, which include the identityId
    const credentials = await credentialsProvider();
    const identityId = credentials.identityId;

    // console.info("Identity ID:", identityId);
    return identityId;
  } catch (error) {
    console.error("Error getting identityId:", error);
    throw new Error("Failed to retrieve identityId");
  }
};