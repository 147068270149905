import { listUserInstitutions } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const fetchPlaidInstitutionsByUser = async (id) => {
  try {
    if (!id) return 'error fetchPlaidInstitutionsByUser: userId is required';

    const gqlParams = {
      userId: id,
    };

    const response = await client.graphql({
      query: listUserInstitutions,
      variables: gqlParams,
    });

    const results = response.data.institutionsByUserId?.items;

    return results;
  } catch (err) {
    return 'error fetchPlaidInstitutionsByUser: ' + err;
  }
};

fetchPlaidInstitutionsByUser.propTypes = {
  id: PropTypes.string.isRequired,
};