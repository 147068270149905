import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';

import { ButtonSenaryWithIconOnly } from '../../Form/Button/ButtonSenaryWithIconOnly.jsx';
import { TextInput } from '../../Form/Input/TextInput.jsx';

// decorative components
import { Divider } from '../../Divider.jsx';

// functions
import { spellOutDate } from '../../functions/dateTime.jsx';

import PropTypes from 'prop-types';

export const InspectionSummaryTable = (props) => {
  const {
    tableData,
    updateInspectionSummary,
    // toggleAtDivisionButtonState,
    // toggleNotAtDivisionButtonState,
    // control,
    register,
    errors,
  } = props;

  console.log('tableData: ', tableData)

  return (
    <TableContainer>
      <Table 
        variant={'compact'}
        size={'compact'}
        >
        <Thead>
          <Tr>
            <Th></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>UNIT</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>LAST INSPECTED</Text></Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableData?.map((data, inspectionIndex) => {
            const lastInspectedDate = (data?.LastInspected) ? spellOutDate(data?.LastInspected, 'apple-fy') : 'not yet inspected'

            return(
              <Tr key={'tr_inspections_'+inspectionIndex}>
                <Td h={'57px'} key={'td_inspections_0_'+inspectionIndex}><ButtonSenaryWithIconOnly
                  key={'td_0_inspections_delete_'+inspectionIndex}
                  name={'inspection_delete_row_'+inspectionIndex}
                  icon='delete'
                  iconsize='22px'
                  onClick={() => {
                    updateInspectionSummary('remove', data, inspectionIndex)
                  }}
                /></Td>
                <Td key={'td_inspections_1_'+inspectionIndex}>
                  <Text as="span" className='dark-sfpro-text-1'>{data?.Description}</Text>
              
                  <TextInput
                    fieldname={`inspection[${inspectionIndex}].UnitId`}
                    fieldvalue={data.Id}
                    register={register}
                    errors={errors}
                    fieldtype='hidden'
                  />
                </Td>
                <Td key={'td_inspections_2_'+inspectionIndex}>
                  <Text as="span" className='dark-sfpro-text-1'>{lastInspectedDate}</Text>
                </Td>
              </Tr>
            )
          })}
          {(tableData?.length>0) && <Tr key={'tr_inspections_divider_end'}><Td colSpan='3' h='15px'><Divider h={'2px'} mt='5px' mb='5px'/></Td></Tr>}
          <Tr><Td colSpan='3' h='15px'></Td></Tr>
        </Tbody>
        {/* <Tfoot>
          <Tr>
            <Th colSpan={8} textAlign={'right'}>
            <Box><Text as="span" textStyle='text-7' pr='10px'>Material Total:&nbsp;&nbsp;{USDollar.format(materialTotalAmount)}</Text></Box>
            <Box mt='5px'><Text as="span" textStyle='text-8' pr='10px'>DJT Total:&nbsp;&nbsp;{USDollar.format(djtTotalAmount)}</Text></Box>
            </Th>
          </Tr>
        </Tfoot> */}
      </Table>
    </TableContainer>
    
  )
}

InspectionSummaryTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateInspectionSummary: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};