import { listInspectedUnitsByDivisionByDate } from '../../../graphql/custom_queries';
import { generateClient } from 'aws-amplify/api';
import { todaysDateYMD } from '../../functions/dateTime';

const client = generateClient();

export const fetchCompletedUnitInspections = async (divisionId) => {
  if (!divisionId) return;

  try {
    const todayYMD = todaysDateYMD();
    const divisionDate = `${divisionId}#${todayYMD}`;

    const response = await client.graphql({
      query: listInspectedUnitsByDivisionByDate,
      variables: { divisionDate },
    });

    const unsortedResults = response.data.inspectedUnitsByDivisionByDate.items;

    const results = unsortedResults.sort((a, b) => 
      a.createdAt > b.createdAt ? -1 : 1
    );

    console.info('----- SORTED RESULTS: ', results);
          
    return results;
  } catch (err) {
    return ('error fetchCompletedUnitInspections: ', [err]);
  }
}
