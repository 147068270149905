export const getClasses = async (token, environment) => {
  if (!token && !token.accessToken) {
    console.warn('NO TOKEN in getClasses')
    return null
  }
  // Lambda: getQBQuery
  const query = encodeURIComponent('query?query=select  * from Class&minorversion=70')

  // getQBQuery
  const response = await fetch('https://ye5zper44kdptmvqao3fiyfzhe0tzcab.lambda-url.us-east-1.on.aws/?token='+token.accessToken+'&query='+query+'&qbenv='+environment, { 
    method: 'GET', 
    headers: new Headers({
        'Authorization': 'Bearer '+token.accessToken, 
        'Content-Type': 'application/x-www-form-urlencoded'
    })
  });

  const dataJSON = await response.json()
  let qbresponse = ''

  // if data.JSON.fault.error[0].code = "3200". we need to refresh
  if (dataJSON.fault?.error?.length>0) {
    console.warn('GOT AN ERROR in getClasses: ', dataJSON.fault?.error)
    qbresponse = null
  }
  if (dataJSON?.QueryResponse?.Class) {
    // qbresponse = dataJSON.QueryResponse
    qbresponse = dataJSON.QueryResponse.Class
  }
  // console.log('qbresponse is: ', qbresponse)
  // setCustomers(dataJSON.QueryResponse.Customer)
  return qbresponse
}