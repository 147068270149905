import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { listCompaniesByDivisionId } from '../../../graphql/custom_queries';

const client = generateClient();

export const fetchContactCompaniesByDivision = async (divisionId) => {
  if (divisionId) {
    try {
      const response = await client.graphql({
        query: listCompaniesByDivisionId,
        variables: {
          divisionId: divisionId,
          filter: { isActive: { eq: true } },
        },
      });

      const results = response.data.djtClientsByDivisionId.items
        .sort((a, b) => (a.contactCompany > b.contactCompany ? 1 : -1))
        .map((data) => ({
          value: data.id,
          label: data.contactCompany,
          staleAfterDate: data.staleAfterDate,
          // value: data.id, label: data.contactCompany + ' - ' + data.contactName
        }));
      return results;
    } catch (err) {
      // return 'error fetchContactCompaniesByDivision: ' + err;
    }
  } else {
    return null;
  }
};

fetchContactCompaniesByDivision.propTypes = {
  divisionId: PropTypes.string.isRequired,
};
