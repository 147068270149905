export function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export function prettifyName(firstName, goesBy, middleName, lastName) {
  let fullName = ''
  // always have a first name
  fullName = firstName
  if (goesBy!==null && goesBy!=='' && goesBy!==undefined) {
    fullName += ` "${goesBy}"`
  }
  if (middleName!==null && middleName!=='' && middleName!==undefined) {
    fullName += ` ${middleName}`
  }
  // we should always have a last name
  fullName += ` ${lastName}`
  
  return `${fullName}`
}

export const handleKeyDown = (e) => {
  // disable submit on enter
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};

export const validateEmail = async (inputValue) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(inputValue).toLowerCase());
};

// A simple debounce function
export function debounce(fn, delay) {
  let timeoutID = null;
  return function (...args) {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}