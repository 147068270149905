import { useEffect, useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Container,
  Box,
  Text,
  Flex,
  Spacer,
  // useDisclosure,
  List,
  ListItem,
} from "@chakra-ui/react";

// modals
// import { CenteredSubmitWithProgressModal } from '../Modal/CenteredSubmitWithProgressModal';
// import { delay } from '../functions/generic.js';

import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';

import { fetchPrimaryUnitById } from '../graphqlCompnents/Unit/fetchPrimaryUnitById.jsx';
import { fetchUnitSubTypes } from '../graphqlCompnents/Unit/fetchUnitSubTypes.jsx';

// structural components
import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

export const ViewUnit = ({ unitId, onClose }) => {

  // grab global context values
  const { store } = useContext(AppContext);

  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id
  console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)

  

  useEffect(() => {
    const listUnitSubTypes = async () => {
      try {
        const response = await fetchUnitSubTypes();
        console.log('fetchUnitSubTypes response: ', response);

      } catch (error) {
        console.error('Failed to fetch unit sub-types:', error);
      }
    };

    listUnitSubTypes();
  }, []);

  const [ selectedAuxiliaryUnits, setSelectedAuxiliaryUnits ] = useState([])  // id, name
  const [ fetchedUnit, setFetchedUnit ] = useState(null)

  useEffect(() => {
    if (unitId) {
      const fetchUnit = async () => {
        const unit = await fetchPrimaryUnitById(unitId)
        console.log('unit: ', unit)
        if (unit) {
          setFetchedUnit(unit)
        }
      }

      fetchUnit()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[unitId])

  useEffect(() => {
    if (fetchedUnit) {
      // get auxiliary units
      if (fetchedUnit?.auxillaryUnits?.items?.length > 0) {
        const selectedAuxiliaryUnits = fetchedUnit?.auxillaryUnits?.items.map(unit => ({
          id: unit?.spkAuxillaryUnitTbl?.id,
          name: unit?.spkAuxillaryUnitTbl?.name
        }))
        setSelectedAuxiliaryUnits(selectedAuxiliaryUnits)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchedUnit])

  return (
    <> 
    <Container maxW="1600" mb={12}>

      <ModuleBoxDrawer mt='25px'>
        <Flex>
          <Box>
            <Text as="span" textStyle='heading-1'>View Unit: {fetchedUnit?.code}</Text>
          </Box>
          <Spacer />
          <Box>
            <ButtonQuaternaryWithIcon 
              name='closeDrawer'
              iconsize='26px'
              leftIcon='close'
              value='Cancel'
              onClick={onClose}
            />
          </Box>
        </Flex>

        <Box py={'12px'}>
          <Text>Make: {fetchedUnit?.make}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Model: {fetchedUnit?.model}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Color: {fetchedUnit?.color}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Year: {fetchedUnit?.year}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Tag: {fetchedUnit?.tag}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>VIN: {fetchedUnit?.vin}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Odometer: {fetchedUnit?.odometer}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Serial: {fetchedUnit?.serial}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Division: {fetchedUnit?.unitStatus?.division?.prettyname}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Type: {fetchedUnit?.type?.name}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Sub-type: {fetchedUnit?.subType?.name}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Status: {fetchedUnit?.isActive ? 'Active' : 'Inactive'}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Is IRP/IFTA Registered: {fetchedUnit?.irpiftaRegistered ? 'Registered' : 'Not Registered'}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Inspected Daily: {fetchedUnit?.isInspectedDaily ? 'Yes' : 'No'}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Requires Driver: {fetchedUnit?.requiresDriver ? 'Yes' : 'No'}</Text>
        </Box>

        <Box py={'12px'}>
          <Text>Requires Operator: {fetchedUnit?.requiresOperator ? 'Yes' : 'No'}</Text>
        </Box>


        <Box pt={'12px'}>
          <Text>Auxiliary Units:</Text>
        </Box>
        <Box w={'40%'} >
          <Box>
            <List spacing={'10px'}>
              {selectedAuxiliaryUnits.map((unit) => (
                <ListItem key={unit.id} style={{ listStyleType: "none" }}>
                  <Text pl={'25px'} as="span" textStyle='dark-sfpro-text-1'>{unit.name}</Text>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </ModuleBoxDrawer>

    </Container>
    {/* <CenteredSubmitWithProgressModal
      isModalOpen={isSubmitModalOpen}
      onModalClose={onSubmitModalClose}
      content={submitModalContent}
      closeButtonDisabled={submitModalCloseDisabled}
      handleSubmitModalClosed={handleSubmitModalClosed}
      progress={submissionProgress}
      successMessage={'Unit has been updated.'}
      closeButtonVisible={true}
    /> */}
    </>
  )
}

ViewUnit.propTypes = {
  unitId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};