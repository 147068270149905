import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Select } from "chakra-react-select";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Divider,
} from "@chakra-ui/react";
import { chakraStyles, customStyles } from './SelectStyles';

export function FormSelect(props) {
  const { 
    placeholder='',
    control, 
    fieldname='', 
    fieldlabel='', 
    optionsArray, 
    selectedoption, 
    isDisabled=false, 
    onMenuOpen,
    defaultValue,
    classNamePrefix,
    rules,
    isclearable=false,
    issearchable=true,
    ismulti=false,
    errors,
  } = props;

  return (
    <Controller
      errors={errors}
      control={control}
      name={fieldname}
      rules={rules}
      render={({ 
        field: { onChange, onBlur, ref }
      }) => (
        <FormControl id={fieldname} isInvalid={!!errors[fieldname]}>
          {fieldlabel && <FormLabel>{fieldlabel}</FormLabel>}
          <Select
            components={{ 
              IndicatorSeparator: () => <Divider orientation='vertical' color='spikegray.600'/>,
            }}
            {...props}
            name={fieldname}
            ref={ref}
            onMenuOpen={onMenuOpen}
            onChange={onChange}
            onBlur={onBlur}
            value={selectedoption}
            options={optionsArray}
            placeholder={placeholder}
            styles={customStyles}
            chakraStyles={chakraStyles}
            menuPortalTarget={document.body}
            selectedOptionColorScheme="spikegray"
            className="select"
            classNamePrefix={classNamePrefix}
            closeMenuOnSelect={true}
            isDisabled={isDisabled}
            isMulti={ismulti}
            isClearable={isclearable}
            isSearchable={issearchable}
            defaultValue={defaultValue}
          />
          <FormErrorMessage>
            {errors[fieldname]?.message}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
}

// Prop validation
FormSelect.propTypes = {
  placeholder: PropTypes.string, // Placeholder is a string
  control: PropTypes.object.isRequired, // Control is an object and is required (from React Hook Form)
  fieldname: PropTypes.string, // Fieldname is a string
  fieldlabel: PropTypes.string, // Fieldlabel is a string
  optionsArray: PropTypes.arrayOf(PropTypes.object).isRequired, // OptionsArray is an array of objects and is required
  selectedoption: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), // Selected option can be an object or an array (for multi-select)
  isDisabled: PropTypes.bool, // isDisabled is a boolean
  onMenuOpen: PropTypes.func, // onMenuOpen is a function
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), // DefaultValue can be an object or an array
  classNamePrefix: PropTypes.string, // classNamePrefix is a string
  rules: PropTypes.object, // Rules is an object (from React Hook Form)
  isclearable: PropTypes.bool, // isclearable is a boolean
  issearchable: PropTypes.bool, // issearchable is a boolean
  ismulti: PropTypes.bool, // ismulti is a boolean (looks like a typo, should be isMulti)
  errors: PropTypes.object, // Errors is an object (from React Hook Form)
};

