import { Controller } from 'react-hook-form';
import { CreatableSelect } from "chakra-react-select";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Divider,
} from "@chakra-ui/react";
import { chakraStyles } from './SelectStyles';

export function FormSelectCreatableClearableSingle(props) {
  const { 
    reference,
    placeholder,
    control, 
    fieldname, 
    fieldlabel, 
    optionsArray, 
    // selectedOption, 
    // selectedoption,
    width,
    // register, 
    isDisabled=false, 
    // isChecked=false, 
    // onChange, 
    // onBlur,
    // isMulti=false,
    // value,
    defaultValue,
    className,
    classNamePrefix,
    // variant="brandPrimary",
    errors,
    rules,
    // maxAuxillaryOptionsSelected=false,
    // maxAuxillaryOptionLabel,
    // maxAuzillaryOptionValue,
  } = props;

  
//   //https://react-select.com/advanced
//   The action argument is a string with the following possible values

// 'select-option': Selecting an option from the list
// 'deselect-option': (Multiple) Deselecting an option from the list
// 'remove-value': (Multiple) Removing a selected option with the remove button
// 'pop-value': Removing options using backspace
// 'set-value': Calling setValue from a component without an action
// 'clear': Removing all selected options with the clear button
// 'create-option': (Creatable) Creating a new option
// By explicitly passing you what type of change event has been fired, we allow you to have more granular control over how the select behaves after an onChange even is fired.

  return (
    
    <Controller
      errors={errors}
      control={control}
      name={fieldname}
      rules={rules}
      render={({ 
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error }
      }) => (
        <FormControl 
          width={width}
          id={fieldname} 
          isInvalid={!!errors[fieldname]}> {/* errors[fieldname] exists after changing */}
        {(fieldlabel!=='') && <FormLabel>{fieldlabel}</FormLabel>}
            <CreatableSelect
              // styles={customStyles}
              // components={{ ClearIndicator: () => <div>Clear</div> }}
              components={{ 
                // ClearIndicator: () => <div>Clear</div>
                // ClearIndicator: null,
                IndicatorSeparator: () => <Divider orientation='vertical' color='spikegray.600'/>,
              }}
              // {...props}
              name={fieldname}
              // ref={ref}
              ref={reference}
              // onChange={onChange}
              onChange={props.onChange}
              onBlur={onBlur}
              // onChange={(e)=>props.onChange(e)}
              // value={selectedoption}
              value={value}
              options={optionsArray}
              placeholder={placeholder}
              // menuPortalTarget={document.body} 
              selectedOptionColorScheme="spikegray"
              // focusBorderColor="spikeorange.500"
              className={className}
              classNamePrefix={classNamePrefix}
              closeMenuOnSelect={true}
              isDisabled={isDisabled}
              // isMulti
              // ADD showCloseX in Select
              // isClearable={true}
              isClearable
              // isCreatable
              defaultValue={defaultValue}
              chakraStyles={chakraStyles}
            />
            <FormErrorMessage>{errors[fieldname]?.message}</FormErrorMessage>
          </FormControl>
      )}
    />
  )
}