import {
  Box,
  Text,
  Progress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Center,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  List,
  ListItem,
} from "@chakra-ui/react";

import { AvailableIcons } from "../Icons/AvailableIcons";
import { ButtonPrimaryPlain } from "../Form/Button/ButtonPrimaryPlain";
import { ButtonDenaryPlain } from "../Form/Button/ButtonDenaryPlain";
// import { TextInputDate } from "../Form/Input/TextInputDate";

// --success-green: #67C336;
// --caution-yellow: #F1CE3C;
// --disabled-red: #FF3C1E;

export const CenteredLoadDuplicateEditDJTModal = (props) => {

  const {
    isModalOpen,
    onModalClose,
    content,
    loadType, //either edit or duplciate
    duplicateEditLaborLoaded,
    duplicateEditLaborError,
    duplicateEditUnitsLoaded,
    duplicateEditUnitError,
    closeButtonDisabled,
    handleSubmitModalClosed,
    progress,
  } = props
  
  const errors = new Set(duplicateEditUnitError)
  const uniqueDuplicateEditUnitError = [...errors]
  const hasLaborIssue = (duplicateEditLaborError?.length>0)
  const hasUnitIssue = (uniqueDuplicateEditUnitError?.length>0)

  // console.log('hasUnitIssue: ', hasUnitIssue)
  // console.log('hasLaborIssue: ', hasLaborIssue)
  // console.log('duplicateEditLaborLoaded: ', duplicateEditLaborLoaded)
  // console.log('duplicateEditUnitsLoaded: ', duplicateEditUnitsLoaded)


  return (
    <>
      <Modal 
        closeOnEsc={false} 
        blockScrollOnMount={true} 
        closeOnOverlayClick={false}  
        onClose={onModalClose} 
        isOpen={isModalOpen} 
        onCloseComplete={handleSubmitModalClosed} 
        isCentered>
        <ModalOverlay 
          bg='none'
          backdropFilter='auto'
          backdropBrightness='60%'
          backdropBlur='2px'
        />
        <ModalContent width={(hasLaborIssue && hasUnitIssue) ? '710px' : '380px'}  maxH={'486px'} maxW={'710px'} bg={'var(--dark-menu-background)'}>
        {(progress===100 && 
          !closeButtonDisabled && 
          duplicateEditLaborLoaded && 
          duplicateEditUnitsLoaded && 
          (hasLaborIssue || hasUnitIssue)) 
        ? <><ModalHeader>
            <Box mt={'20px'} h={'50px'}>
              {(!closeButtonDisabled) && 
                <Center>
                  {/* <VStack p='0px' m='0px'> */}
                    <Box>
                      {(hasLaborIssue || hasUnitIssue) ? 
                        <AvailableIcons boxSize={'60px'} iconRef={'alerttriangle'} color={'var(--caution-yellow)'}/> 
                        : <AvailableIcons boxSize={'60px'} iconRef={'outlinecheckcircle'} color={'var(--success-green)'}/>}
                    </Box>
                    {/* <Box>
                      {(hasLaborIssue || hasUnitIssue) ? 
                        <Text as="span" textStyle='heading-2' color={'var(--dark-text-grey-1)'}>Required Edits</Text> 
                        : <Text as="span" textStyle='heading-2' color={'var(--dark-text-grey-1)'}>Loading Completed</Text>}
                    </Box> */}
                  {/* </VStack> */}
                </Center>
              }
            </Box>
          </ModalHeader></>
        : 
          <><ModalHeader mx={'25px'}>
            <Box mt={'5px'} h={'105px'}>
            <Center>
              <VStack p='0px' m='0px'>
              {(progress===100 && !closeButtonDisabled) && <><Box mt='10px'><AvailableIcons boxSize={'60px'} iconRef={'outlinecheckcircle'} color={'var(--success-green)'}/></Box>
                <Box><Text as="span" textStyle='heading-2'>Success!</Text></Box></>}
                
                <Box h='30px'></Box>
                <Box h='50px'><Text as="span" textStyle='heading-2'>{content}</Text></Box>
              </VStack>
            </Center>
            </Box>
          </ModalHeader>
        </>}

          <ModalBody>
            <Progress 
            // border='3px solid green' 
            mt='10px' mb={'35px'} value={progress} size='sm' 
            // maxW={'320px'} 
            maxW={'100%'} 
            hasStripe colorScheme='progressbar' />
            {(progress===100 && !closeButtonDisabled && duplicateEditLaborLoaded && duplicateEditUnitsLoaded) ? 
              <Center>
                {(hasLaborIssue || hasUnitIssue) ? 
                  
                  <Box bg='var(--dark-divider)' p='1px'>
                    <SimpleGrid columns={(hasLaborIssue && hasUnitIssue) ? 2 : 1} columnGap='1px' width={(hasLaborIssue && hasUnitIssue) ? '660px' : '330px'}>
                      {(hasLaborIssue) && <Box p='25px' bg='var(--dark-menu-background)' align="left">
                        {/* <Box pb={'4px'}><Text as="span" textStyle={'modal-content-header'}>Labor</Text></Box> */}
                        <Box pb={'10px'}>
                          <Text textStyle={'modal-content-header'}>Labor included for this job ticket date was not recorded:</Text>
                        </Box>
                        <Box pl={'20px'}>
                          <Text as="span" textStyle={'modal-content-text'}>
                            {/* tooltip with all issues */}
                          {/* <List styleType="disc"> */}
                            {
                              duplicateEditLaborError.map((issue, index) => {
                                if (index<3) {
                                  return(
                                    <Text key={`labor_issue_${index}`} textStyle={'modal-content-text'}>{issue}</Text>
                                    // <ListItem key={`labor_issue_${index}`}>{issue}</ListItem>
                                  )
                                }
                                if (index===duplicateEditLaborError.length-1 && duplicateEditLaborError.length>3) {
                                  return(
                                    <Text key={`labor_issue_${index}`} textStyle={'modal-content-text'}>More labor...</Text>
                                    // <ListItem>More edits required...</ListItem>
                                  )
                                }
                                return null
                              })
                            }
                          {/* </List> */}
                          </Text>
                        </Box>
                      </Box>}
                      {(hasUnitIssue) && <Box p='25px' bg='var(--dark-menu-background)' align="left">
                        {/* <Box pb={'4px'}><Text as="span" textStyle={'modal-content-header'}>Equipment</Text></Box> */}
                        <Box pb={'10px'}>
                          {/* <Text textStyle={'modal-content-text'}>Entries not loaded due to not being inspected for this date:</Text> */}
                          <Text textStyle={'modal-content-header'}>Equipment included for this job ticket date was not inspected:</Text>
                        </Box>
                        <Box pl={'20px'}>
                          <Text as="span" textStyle={'modal-content-text'}>
                          {/* <List styleType="disc"> */}
                            {
                              uniqueDuplicateEditUnitError.map((issue, index) => {
                                if (index<3) {
                                  return(
                                    <Text key={`unit_issue_${index}`} textStyle={'modal-content-text'}>Unit ID: {issue}</Text>
                                    // <ListItem key={`unit_issue_${index}`}>Unit ID: {issue}</ListItem>
                                    )
                                }
                                if (index===uniqueDuplicateEditUnitError.length-1 && uniqueDuplicateEditUnitError.length>3) {
                                  return(
                                    <Text key={`unit_issue_${index}`} textStyle={'modal-content-text'}>More equipment...</Text>
                                    // <ListItem>More equipment...</ListItem>
                                  )
                                }
                                return null
                              })
                            }
                          {/* </List> */}
                          </Text>
                        </Box>
                      </Box>}
                    </SimpleGrid>
                  </Box>
                  
                : <Box w='250px'>
                    <Text align='center' textStyle='modal-content-text'>Your daily job ticket has been successfully loaded.</Text>
                  </Box>}
                    {/* double check errors have been checked and loaded if exist and everything is complete before showing everything okay message */}
            </Center> : <Center><Box height='50px'></Box></Center>}
          </ModalBody>
          <ModalFooter mx={'0px'} px={'0px'} my={'0px'} pt={'20px'} pb={'25px'}>
            {/* enable button on completion of submission */}
            {(progress===100 && !closeButtonDisabled) ? <>
            <Box width={'100%'}>
              <Center>
                <ButtonPrimaryPlain 
                  width='148px'
                  name='closeSubmitModal'
                  value='Continue'
                  onClick={onModalClose}
                  isDisabled={closeButtonDisabled}
                />
              </Center>
            </Box>
            </> : <><Box h='45px'></Box></>}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}