/* eslint-disable react/prop-types */
import { useEffect, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useForm, useFieldArray } from 'react-hook-form';
import {
  Container,
  Box,
  Text,
  Center,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

// inspection table components
import { InspectionSummaryTable } from './QueueDailyInspections/InspectionSummaryTable.jsx';
import { InspectionQueueTable } from './QueueDailyInspections/InspectionQueueTable.jsx';
import { InspectionCompletedTable } from './QueueDailyInspections/InspectionCompletedTable.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// buttons
import { ButtonSeptenaryWithIcon } from '../Form/Button/ButtonSeptenaryWithIcon.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';

// structural components
import { ModuleBox } from '../Structural/ModuleBox.jsx';

// general utility functions
import { todaysDateYMD } from '../functions/dateTime.jsx';

// graphQL
import { fetchCompletedUnitInspections } from '../graphqlCompnents/Inspection/fetchCompletedUnitInspections.jsx';
import { fetchAllUnitsForInspection } from '../graphqlCompnents/Inspection/fetchAllUnitsForInspection.jsx';
import { addQueuedUnitInspection } from '../graphqlCompnents/Inspection/addQueuedUnitInspection.jsx';
import { updatePrimaryUnitInspectionStatus } from '../graphqlCompnents/Inspection/updatePrimaryUnitInspectionStatus.jsx';
import { updateQueuedPrimaryUnitInspection } from '../graphqlCompnents/Inspection/updateQueuedPrimaryUnitInspection.jsx';

import PropTypes from 'prop-types';

export const QueueDailyInspections = () => {

  // grab global context values
  const { store } = useContext(AppContext);

  // set the users current division id
  const currentDivisionId = store?.userData?.divisionId
  const currentUserId = store?.userData?.id
  const inspectionStatuses = store?.inspectionStatuses
  const activeInspections = store?.inspectionQueue
  // const completedInspections = store?.completedInspections

  const inspectionQueued = inspectionStatuses?.filter((inspectionStatus) => {
    return inspectionStatus.statusQuery === 'inspection_queued'
  })

  // accordian defaults
  const [accordianAtDivisionIndex, setAccordianAtDivisionIndex] = useState(0) //-1
  const [accordianNotAtDivisionIndex, setAccordianNotAtDivisionIndex] = useState(-1)

  // --- REACT-HOOK-FORM ---
  const { 
    control, 
    register, 
    handleSubmit, 
    formState: { 
      errors, 
    }} = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const {
    fields: inspectionFields,
    append: inspectionAppend,
    remove: inspectionRemove,
    // update: laborUpdate,
    // replace: laborReplace,
  } = useFieldArray({ control, name: "inspection" });

  const onError = (errors, e) => console.log(errors, e);

  const [ unitsAtDivision, setUnitsAtDivision ] = useState(null)
  const [ unitsNotAtDivision, setUnitsNotAtDivision ] = useState(null)
  const [ currentActiveInspections, setCurrentActiveInspections ] = useState([])

  const [ allUnits, setAllUnits ] = useState(null)
  // const [ uniqueDivisions, setUniqueDivisions ] = useState(null)
  const allUnitsForInspection = useQuery(['fetchAllUnitsForInspection'], () => fetchAllUnitsForInspection(), {
    enabled: !!currentDivisionId, 
    refetchOnWindowFocus: false,
    onSuccess: (inspectionData) => {
      let isInspectableUnits = []
      let unitsBeingInspected = []
      let unitsCompletedInspection = []
      let unitsBeingInspectedIds = []
      inspectionData.sort(function( a , b ){
        return a.codeSortable < b.codeSortable ? -1 : 1;
      })

      
      // need to check unit?.unitStatus?.lastInspected? to show units that have already been inspected TODAY ONLY
      inspectionData.forEach((unit) => {

        let isInspectionFromToday = false
        
        if (unit?.unitStatus?.inspectionQueue?.items?.length>0) {
          // console.log('FOUND ONE')
          const queuedInspections = unit?.unitStatus?.inspectionQueue?.items
          queuedInspections.forEach((queuedInspection) => {
            const queuedOnYMD = queuedInspection?.createdOn?.split('T')[0]
            const todayYMD = todaysDateYMD()
            // if any of these are from today, set true
            // if they have alrady been inspected, put them in a different array
            if (queuedOnYMD === todayYMD) {
              isInspectionFromToday = true
            }
          })
        }
        // (isInspectionFromToday) && console.log('isInspectionFromToday: ', isInspectionFromToday)
        // we only need to disable units being inspected TODAY, units could have been queued yesterday and never inspected.
        // if it is in the queue OR being inspected AND it isn't from the current super AND it is from today
        // OR -- is it out of service
        // console.info('unit what is isAvailable: ', unit)
        // do the active inspection
        if (
          isInspectionFromToday &&
          unit?.unitStatus?.status?.isAvailable && 
            (
              unit?.unitStatus?.status?.statusQuery==='inspection_active' || 
              unit?.unitStatus?.status?.statusQuery==='inspection_queued' ||
              unit?.unitStatus?.status?.statusQuery==='in_maintenance' ||
              unit?.unitStatus?.status?.statusQuery==='inspected_pending_out_of_service'
            )
          ) 
        {
          unitsBeingInspected.push(unit)
          unitsBeingInspectedIds.push(unit.code)
          unit.disabled = true
        }
        // now do the completed inspections
        if (
          isInspectionFromToday &&
          unit?.unitStatus?.status?.isAvailable && 
            (
              unit?.unitStatus?.status?.statusQuery==='inspected_in_service' || 
              unit?.unitStatus?.status?.statusQuery==='inspected_in_service_issues' ||
              unit?.unitStatus?.status?.statusQuery==='inspected_not_found' ||
              unit?.unitStatus?.status?.statusQuery==='in_maintenance' ||
              unit?.unitStatus?.status?.statusQuery==='inspected_out_of_service'
            )
          ) 
        {
          unitsCompletedInspection.push(unit)
        }


        if (unit?.unitStatus?.status?.isAvailable) {
          isInspectableUnits.push(unit)
        }
        

      })

      // console.log('active inspections: ', unitsBeingInspected)
      console.log(' ============ active inspections: ', unitsBeingInspectedIds)
      // unique the array
      // const uniqueUnitsBeingInspected = new Set(unitsBeingInspected)
      // console.info('uniqueUnitsBeingInspected: ', uniqueUnitsBeingInspected)
      setCurrentActiveInspections(unitsBeingInspected)


      setAllUnits(isInspectableUnits)

      // units that are currently in unitsBeingInspected need to be flagged as disabled because they are currently being inspected and can't be added again.
      // also, units should be able to be inspected more than once if the unit is being inspected by multiple supervisors
      // store active unit ids in an array and filter out of all units
      
// console.warn('isInspectableUnits: ', isInspectableUnits)
      const unitsAtHomeDivision = isInspectableUnits?.filter((unit) => {
        return unit.unitStatus?.divisionId === currentDivisionId
      })
      setUnitsAtDivision(unitsAtHomeDivision)

      

      // statusQuery will show status so we can track units being inspected, possible by other supervisors
      // auto add these to the summary

      // let divisionNames = []
      const unitsNotAtHomeDivision = isInspectableUnits?.filter((unit) => {
        // let divisionName =  (unit.unitStatus?.division?.prettyname!==undefined) ? unit.unitStatus?.division?.prettyname : 'Unknown'
        // divisionNames.push(divisionName)
        return unit.unitStatus?.divisionId !== currentDivisionId
      })
      // .sort(function( a , b ){
      //   return a.unitStatus?.divisionId < b.unitStatus?.divisionId ? -1 : 1;
      // })
      // const uniquesResults = new Set(divisionNames)
      // setUniqueDivisions(uniquesResults)
      setUnitsNotAtDivision(unitsNotAtHomeDivision)
      // console.log('unitsNotAtHomeDivision: ', unitsNotAtHomeDivision)
    }
  });

  const [ atLocationButtonVariants, setAtLocationButtonVariants ] = useState([])
  // useEffect(() => {
  //   (atLocationButtonVariants.length>0) && console.log('atLocationButtonVariants: ', atLocationButtonVariants)
  // },[atLocationButtonVariants])
  const [ notAtLocationButtonVariants, setNotAtLocationButtonVariants ] = useState([])
  useEffect(() => {
    if (allUnits) {

      // local units
      const localUnits = allUnits?.filter((unit) => {
        return unit.unitStatus?.divisionId === currentDivisionId
      })
      setUnitsAtDivision(localUnits)

      let tmpAtDivisionArray = []
      for (let i = 0; i < localUnits.length; i++) {
        tmpAtDivisionArray.push({variant: 'withIconSenary', icon: 'plus', isDisabled: false})
      }
      setAtLocationButtonVariants(tmpAtDivisionArray)

      // remote units
      const remoteUnits = allUnits?.filter((unit) => {
        return unit.unitStatus?.divisionId !== currentDivisionId
      })
      setUnitsNotAtDivision(remoteUnits)

      let tmpNotAtDivisionArray = []
      for (let i = 0; i < remoteUnits.length; i++) {
        tmpNotAtDivisionArray.push({variant: 'withIconSenary', icon: 'plus', isDisabled: false})
      }
      setNotAtLocationButtonVariants(tmpNotAtDivisionArray)

    }
  },[allUnits, currentDivisionId])

  const [ formDisabled, setFormDisabled ] = useState(false)

  // mirror inspectionFields in inspection summary to handle changes
  const updateInspectionSummary = async (action, props, index) => {
    setFormDisabled(true)

    if (action==='append') {
      // update the inspectionSummary array, add new object to end of inspection summary array

      // update the button state
      // if available or unavailable
      if (props?.DivisionId === currentDivisionId) {
        toggleAtDivisionButtonState(index)
      } else {
        toggleNotAtDivisionButtonState(index)
      }

      // append the row to laborFields
      inspectionAppend(props)

    }

    if (action==='remove') {

      const buttonIndex = props?.ButtonIndex
      // if local or remote
      if (props?.DivisionId === currentDivisionId) {
        toggleAtDivisionButtonState(buttonIndex)
      } else {
        toggleNotAtDivisionButtonState(buttonIndex)
      }

      // remove unit row
      inspectionRemove(index)
    }

    setFormDisabled(false)

  }

  const atDivisionUnitsButtons = (localUnits, localUnitsButtonArr, divisionId) => {
    if (!localUnits || !localUnitsButtonArr || !divisionId) return <Box></Box>
    return(
      <Box>
        {localUnits?.map((unit, index) => {

          // console.log('unit status: ', unit?.unitStatus)
            
          // buttons
          const localUnitsButton = localUnitsButtonArr[index]
          const buttonVariant = localUnitsButton?.variant||'withIconQuinary'
          const rightIcon = localUnitsButton?.icon||'plus'

          const isButtonDisabled = (localUnitsButton?.isDisabled===true || unit?.disabled===true) ? true : false

          // button label
          const unitButtonText = `${unit.code} - ${unit?.type?.name}/${unit?.subType?.name}`

          const unitObj = {
            DivisionId: currentDivisionId,
            UnitId: unit?.unitStatus?.unitId,
            UnitCode: unit?.code,
            StatusId: unit?.unitStatus?.status?.id,
            CreatedById: currentUserId,
            CreatedOn: todaysDateYMD(),
            // LastInspected: unit?.unitStatus?.lastInspectedAt,
            LastInspected: unit?.unitStatus?.lastInspected,
            Description: unitButtonText,
            ButtonIndex: index,
          }
          
          return(
            <ButtonSeptenaryWithIcon
              variant={buttonVariant}
              key={`add_unit_button_${index}`}
              mr='25px'
              mt='25px'
              name='plus'
              rightIcon={rightIcon}
              isDisabled={isButtonDisabled}
              iconsize='22px'
              value={unitButtonText}
              onClick={() => {
                // addUnitToInspectionQueue(unitObj)
                updateInspectionSummary('append', unitObj, index)
              }}
              // onClick={() => updateLaborSummary('append', laborObj, index) }
            />
          )
        })}
      </Box>
    )
  }

  const notAtDivisionUnitsButtons = (remoteUnits, remoteUnitsButtonArr, divisionId) => {
    if (!remoteUnits || !remoteUnitsButtonArr || !divisionId) return <Box></Box>
    return(
      <Box>
        {remoteUnits?.map((unit, index) => {
            
          // buttons
          const remoteUnitsButton = remoteUnitsButtonArr[index]
          const buttonVariant = remoteUnitsButton?.variant||'withIconQuinary'
          const rightIcon = remoteUnitsButton?.icon||'plus'
          // const isButtonDisabled = remoteUnitsButton?.isDisabled||false
          const isButtonDisabled = (remoteUnitsButton?.isDisabled===true || unit?.disabled===true) ? true : false

          // button label
          const unitButtonText = `${unit.code} - ${unit?.type?.name}/${unit?.subType?.name}`

          const unitObj = {
            DivisionId: unit?.unitStatus?.divisionId,
            UnitId: unit?.unitStatus?.unitId,
            UnitCode: unit?.code,
            StatusId: unit?.unitStatus?.status?.id,
            CreatedById: currentUserId,
            CreatedOn: todaysDateYMD(),
            LastInspected: unit?.unitStatus?.lastInspectedAt,
            Description: unitButtonText,
            ButtonIndex: index,
          }
          
          return(
            <ButtonSeptenaryWithIcon
              variant={buttonVariant}
              key={`add_remote_unit_button_${index}`}
              mr='25px'
              mt='25px'
              name='plus'
              rightIcon={rightIcon}
              isDisabled={isButtonDisabled}
              iconsize='22px'
              value={unitButtonText}
              onClick={() => {
                // toggleNotAtDivisionButtonState(index)
                updateInspectionSummary('append', unitObj, index)
              }}
              // onClick={() => updateLaborSummary('append', laborObj, index) }
            />
          )
        })}
      </Box>
    )
  }

  const toggleAtDivisionButtonState = async (index) => {

    if (atLocationButtonVariants[index] ) {
      const currentAtDivisionButtonVariant = atLocationButtonVariants[index].variant
      
      const updateButtonVariant = (variant, icon, isDisabled) => {
        atLocationButtonVariants[index].variant = variant;
        atLocationButtonVariants[index].icon = icon;
        atLocationButtonVariants[index].isDisabled = isDisabled;
      };
      

      if (currentAtDivisionButtonVariant === 'withIconSenary') {
        updateButtonVariant('withIconQuinary', 'check', true);
      } else {
        updateButtonVariant('withIconSenary', 'plus', false);
      }
    
      setAtLocationButtonVariants([...atLocationButtonVariants]);
      return true
    }
    return false
  }

  const toggleNotAtDivisionButtonState = async (index) => {
    
    if (notAtLocationButtonVariants[index] ) {
      const currentNotAtDivisionButtonVariant = notAtLocationButtonVariants[index].variant
      
      const updateButtonVariant = (variant, icon, isDisabled) => {
        notAtLocationButtonVariants[index].variant = variant;
        notAtLocationButtonVariants[index].icon = icon;
        notAtLocationButtonVariants[index].isDisabled = isDisabled;
      };
    
      if (currentNotAtDivisionButtonVariant === 'withIconSenary') {
        updateButtonVariant('withIconQuinary', 'check', true);
      } else {
        updateButtonVariant('withIconSenary', 'plus', false);
      }
    
      setNotAtLocationButtonVariants([...notAtLocationButtonVariants]);
      return true
    }
    return false
  }

  const addQueuedInspection = async (inspectionObj) => {
    if (!inspectionObj) return false
    console.log('inspecitonObj: ', inspectionObj)
    const response = await addQueuedUnitInspection(inspectionObj)
    console.log('INSPECTION RESPONSE: ', response)
    return response
  }

  const updateInspectionStatus = async (statusObj) => {
    if (!statusObj) return false
    const response = await updatePrimaryUnitInspectionStatus(statusObj)
    return response
  }

  const formSubmit = async (values, event) => {

    const now = new Date();
    const localISODate = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).toISOString();

    console.log(' >>> EVENT: ', event.target.name)
    console.log(' >>> VALUES: ', values)

    let submittedInspections = values?.inspection

    if (!submittedInspections || !Array.isArray(submittedInspections)) {
      console.error('No inspections found or invalid format');
      return;
    }

    const promises = submittedInspections.map(async (inspection) => {
      const inspectionQueueParams = {
        createdById: currentUserId,
        createdOn: localISODate,
        divisionId: currentDivisionId,
        unitId: inspection?.UnitId,
        code: inspection?.UnitCode,
        active: "yes",
      };
  
      console.log('inspectionQueueParams: ', inspectionQueueParams);
  
      const inspectionStatusParams = {
        code: inspection?.UnitCode,
        statusId: inspectionQueued[0].id,
      };
  
      console.log('inspectionStatusParams: ', inspectionStatusParams);
  
      try {
        const updatedInspectionStatus = await updateInspectionStatus(inspectionStatusParams);
        console.log('updatedInspectionStatus: ', updatedInspectionStatus);
  
        // You don't need to store the result if you're refetching afterwards
        await addQueuedInspection(inspectionQueueParams);
  
        updateInspectionSummary('remove', inspection, 0);
      } catch (error) {
        console.error('Error processing inspection: ', error, inspection);
      }
    });
  
    await Promise.all(promises);
  
    // Refetching the units
    allUnitsForInspection.refetch();
  
    event.preventDefault();

    // for (const [inspection] of submittedInspections.entries()) {
    //   const inspectionQueueParams = {
    //     createdById: currentUserId,
    //     // createdOn: now.toISOString(),
    //     createdOn: localISODate,
    //     divisionId: currentDivisionId,
    //     // statusId: inspectionQueued[0].id,
    //     unitId: inspection?.UnitId,
    //     code: inspection?.UnitCode,
    //     active: "yes",
    //   }

    //   console.log('inspectionQueueParams: ', inspectionQueueParams)

    //   const inspectionStatusParams = {
    //     code: inspection?.UnitCode, 
    //     statusId: inspectionQueued[0].id
    //   }

    //   console.log('inspectionStatusParams: ', inspectionStatusParams)

    //   // update the primaryUnitStatusTbl
    //   const updatedInspectionStatus = await updateInspectionStatus(inspectionStatusParams)
    //   console.log('updatedInspectionStatus: ', updatedInspectionStatus)

    //   // add this item to the inspection queue
    //   const addedInspection = await addQueuedInspection(inspectionQueueParams)
    //   let inspections = currentActiveInspections
    //   inspections.push(addedInspection)

    //   // now remove the item(s) form the inspections summary
    //   // get the current index of the incoming unit
    //   updateInspectionSummary('remove', inspection, 0)
      
    //   // change this to refetch
    //   // setCurrentActiveInspections(inspections)
    //   allUnitsForInspection.refetch()

    // }

    // // console.log('currentActiveInspections: ', currentActiveInspections)

    // // add the inspeciton to the quese and update the inspections obj with the results
    // // THEN do the subscription

    // event.preventDefault();

  }

  const handleDeleteQueuedInspection = async (props) => {
    console.log('GOT PROPS!!!!!:', props)
    const { id, unitCode, statusId } = props
    const statusObj = {
      code: unitCode,
      statusId: statusId,
    }
    const queueObj = {
      id: id,
      code: unitCode,
    }
    await updatePrimaryUnitInspectionStatus(statusObj)
    await updateQueuedPrimaryUnitInspection(queueObj)
  }

  handleDeleteQueuedInspection.propTypes = {
    id: PropTypes.string.isRequired,
    unitCode: PropTypes.string.isRequired,
    statusId: PropTypes.string.isRequired
  };

  // useEffect(() => {
  //   (completedInspections) && console.warn('NEW DATA COMING IN: ', completedInspections)
  // },[completedInspections])

  const [ completedInspectionsToday, setCompletedInspectionsToday ] = useState(null)
  useEffect(() => {
    if (!store?.completedInspections && currentDivisionId) {
      console.info(' ***** NO CURRENT RECORDS AVAILABLE')
      const fetchCompletedInspections = async () => {
        const completedInspections = await fetchCompletedUnitInspections(currentDivisionId)
        return completedInspections
      }
      // fetchInspectionPointsBySubTypeId(unitSubTypeId).then((data) => {
        //       console.log('RUNNING REFRESH DATA')
        //       setUnitInspectionPoints(data)
        //     })
        //   }
      fetchCompletedInspections().then((data) => {
        console.info(' ***** completedInspections: ', data)
        setCompletedInspectionsToday(data)
      })
    } else {
      console.info(' ***** PULLING RECORDS FROM STORE')
      if (store?.completedInspections) {
        setCompletedInspectionsToday(store?.completedInspections)
      } 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[store?.completedInspections])

  return (

    <Container as="form" pb='25px' onSubmit={handleSubmit(formSubmit, onError)}>

      <ModuleBox>
        <fieldset disabled={formDisabled}>

      {/* Move this to it's own component at some point */}
      {/* <Box borderRadius='6px' border='1px solid var(--dark-component-border)' mt='25px' p='25px'><BulkImport/></Box> */}

        <Box 
          borderRadius='6px' 
          border='1px solid var(--dark-component-border)' 
          // mt='25px'
          p='25px'>
          <Accordion 
            allowToggle 
            index={accordianAtDivisionIndex} 
            onChange={setAccordianAtDivisionIndex}
            >
            <AccordionItem>
              <h2>  {/* needed by chakra */}
              <AccordionButton 
                _expanded={{ bg: 'var(--black)', color: 'var(--dark-text-white)' }}>
                <Box as="span" flex='1' textAlign='left'>
                  <Text as="span" textStyle='text-2'>Units for Inspection</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              </h2>
              <AccordionPanel>
                {atDivisionUnitsButtons(unitsAtDivision, atLocationButtonVariants, currentDivisionId)}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>

        <Box 
          borderRadius='6px' 
          border='1px solid var(--dark-component-border)' 
          mt='25px'
          p='25px'>
          <Accordion 
            allowToggle 
            index={accordianNotAtDivisionIndex} 
            onChange={setAccordianNotAtDivisionIndex}
            >
            <AccordionItem>
              <h2>  {/* needed by chakra */}
              <AccordionButton 
                _expanded={{ bg: 'var(--black)', color: 'var(--dark-text-white)' }}>
                <Box as="span" flex='1' textAlign='left'>
                  <Text as="span" textStyle='text-2'>Units NOT at this division</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              </h2>
              <AccordionPanel>
                {notAtDivisionUnitsButtons(unitsNotAtDivision, notAtLocationButtonVariants, currentDivisionId)}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
        </fieldset>

      </ModuleBox>

      {(inspectionFields?.length>0) && <ModuleBox>
      <fieldset disabled={formDisabled}>
      
        {/* divider */}
        {/* <Divider mt='30px' mb='25px'/> */}

        <Box>
          <Text as="span" mb={'10px'} textStyle='heading-1'>Units to Inspect Summary</Text>
        </Box>
        {/* <Box mb='25px'>
          <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>Edit or remove entries.</Text>
        </Box> */}

        <InspectionSummaryTable
          tableData={inspectionFields}
          updateInspectionSummary={updateInspectionSummary}
          // toggleAtDivisionButtonState={toggleAtDivisionButtonState}
          // toggleNotAtDivisionButtonState={toggleNotAtDivisionButtonState}
          // control={control}
          register={register}
          errors={errors}
        />
      </fieldset>
      </ModuleBox>}

      <ModuleBox>
      <fieldset disabled={formDisabled}>
      
        {/* divider */}
        {/* <Divider mt='30px' mb='25px'/> */}

        <Box>
          <Text as="span" mb={'10px'} textStyle='heading-1'>Queued Inspections</Text>
        </Box>
        {/* <Box mb='25px'>
          <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>Edit or remove entries.</Text>
        </Box> */}

        <InspectionQueueTable
          tableData={currentActiveInspections}
          activeInspections = {activeInspections}
          handleDeleteQueuedInspection={handleDeleteQueuedInspection}
          // updateInspectionSummary={updateInspectionSummary}
          // toggleAtDivisionButtonState={toggleAtDivisionButtonState}
          // toggleNotAtDivisionButtonState={toggleNotAtDivisionButtonState}
          control={control}
          register={register}
          errors={errors}
        />
      </fieldset>
      </ModuleBox>

      <ModuleBox>
      <fieldset disabled={formDisabled}>
      
        {/* divider */}
        {/* <Divider mt='30px' mb='25px'/> */}

        <Box>
          <Text as="span" mb={'10px'} textStyle='heading-1'>Completed Inspections</Text>
        </Box>
        {/* <Box mb='25px'>
          <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>Edit or remove entries.</Text>
        </Box> */}

        <InspectionCompletedTable
          completedInspections = {completedInspectionsToday}
          control={control}
          register={register}
          errors={errors}
        />
      </fieldset>
      </ModuleBox>

      <ModuleBox>
      <fieldset disabled={formDisabled}>

          <Box mb='50px' pt='45px'>
            <Center>

              {/* disable this until everything is available and is ready */}
              <ButtonPrimaryPlain
                type="submit"
                onClick={handleSubmit(formSubmit, onError)}
                // onClick={formConfirmation}
                width={'210px'}
                name='queue-inspections'
                value='Queue Inspections'
              />
            </Center>
          </Box>
          </fieldset>
      </ModuleBox>
    </Container>
  )
}


QueueDailyInspections.propTypes = {
  store: PropTypes.shape({
    userData: PropTypes.shape({
      divisionId: PropTypes.string,
      id: PropTypes.string,
    }),
    inspectionStatuses: PropTypes.arrayOf(
      PropTypes.shape({
        statusQuery: PropTypes.string,
      })
    ),
    inspectionQueue: PropTypes.array,
    completedInspections: PropTypes.array,
  }),
  control: PropTypes.object,
  register: PropTypes.func,
  handleSubmit: PropTypes.func,
  formState: PropTypes.shape({
    errors: PropTypes.object,
  }),
  unitsAtDivision: PropTypes.arrayOf(
    PropTypes.shape({
      DivisionId: PropTypes.string.isRequired,
      UnitId: PropTypes.string.isRequired,
      UnitCode: PropTypes.string.isRequired,
      StatusId: PropTypes.string.isRequired,
      CreatedById: PropTypes.string.isRequired,
      CreatedOn: PropTypes.string.isRequired,
      LastInspected: PropTypes.string, // optional
      Description: PropTypes.string.isRequired,
      ButtonIndex: PropTypes.number.isRequired,
    })
  ),
  unitsNotAtDivision: PropTypes.arrayOf(
    PropTypes.shape({
      DivisionId: PropTypes.string.isRequired,
      UnitId: PropTypes.string.isRequired,
      UnitCode: PropTypes.string.isRequired,
      StatusId: PropTypes.string.isRequired,
      CreatedById: PropTypes.string.isRequired,
      CreatedOn: PropTypes.string.isRequired,
      LastInspected: PropTypes.string, // optional
      Description: PropTypes.string.isRequired,
      ButtonIndex: PropTypes.number.isRequired,
    })
  ),
};
