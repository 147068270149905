import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { listInspectedUnitsByDivisionByDate } from '../../../graphql/custom_queries';

const client = generateClient();

export const fetchInspectedUnitsByDateByStatus = async (params) => {
  if (params) {
    try {
      const { divisionId, workDayHuman } = params;
      const divisionDate = `${divisionId}#${workDayHuman}`;

      // console.log('getting units: ', divisionDate)
      const response = await client.graphql({
        query: listInspectedUnitsByDivisionByDate,
        variables: { divisionDate }
      });

      // console.log('response for units: ', response)
      const results = response.data.inspectedUnitsByDivisionByDate.items
        .sort((a, b) => (a.unit.code < b.unit.code ? 1 : -1));

      // console.log('results for units: ', results)
      return results;
    } catch (err) {
      return 'error fetchInspectedUnitsByDateByStatus: ' + err;
    }
  } else {
    return 'Missing params';
  }
};

fetchInspectedUnitsByDateByStatus.propTypes = {
  params: PropTypes.shape({
    divisionId: PropTypes.string.isRequired,
    workDayHuman: PropTypes.string.isRequired,
  }).isRequired,
};
