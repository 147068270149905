export function levenshteinDistance(s, t) {
  // Convert null or undefined to empty strings
  s = (s === null || s === undefined) ? '' : s;
  t = (t === null || t === undefined) ? '' : t;

  // Ensure both inputs are strings
  if (typeof s !== 'string' || typeof t !== 'string') {
    console.warn('levenshteinDistance expects both parameters to be strings.');
    return -1; // Return -1 or some error value to indicate the problem
  }

  if (s.length === 0) return t.length;
  if (t.length === 0) return s.length;

  const track = Array(t.length + 1).fill(null).map(() =>
    Array(s.length + 1).fill(null));

  for (let i = 0; i <= s.length; i += 1) track[0][i] = i;
  for (let j = 0; j <= t.length; j += 1) track[j][0] = j;

  for (let j = 1; j <= t.length; j += 1) {
    for (let i = 1; i <= s.length; i += 1) {
      const indicator = s[i - 1] === t[j - 1] ? 0 : 1;
      track[j][i] = Math.min(
        track[j][i - 1] + 1, // deletion
        track[j - 1][i] + 1, // insertion
        track[j - 1][i - 1] + indicator, // substitution
      );
    }
  }

  return track[t.length][s.length];
}

export function fuzzyMatch(s, t, threshold = 0.6) {
  const distance = levenshteinDistance(s, t);
  const length = Math.max(s.length, t.length);
  const score = 1 - distance / length;
  return score >= threshold;
}

