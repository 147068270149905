import stringSimilarity from 'string-similarity'; // Ensure correct import

// Helper function to safely access nested properties
const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, key) => (acc && acc[key] !== 'undefined' ? acc[key] : null), obj);
};

// Helper function to calculate percentage difference between two amounts
const percentageDifference = (amount1, amount2) => {
  if (amount1 === 0 || amount2 === 0) return 100; // Prevent division by zero
  return Math.abs((amount1 - amount2) / ((amount1 + amount2) / 2)) * 100;
};

// Helper function to calculate date difference in days
const dateDifferenceInDays = (date1, date2) => {
  if (!date1 || !date2) return Number.MAX_SAFE_INTEGER;
  const dayInMilliseconds = 24 * 60 * 60 * 1000;
  const diff = Math.abs(new Date(date1) - new Date(date2));
  return diff / dayInMilliseconds;
};

// Normalize merchant names by removing special characters and converting to lowercase
export function normalizeMerchantName(input) {
  return input ? input.replace(/[-'\s]/g, '').toLowerCase() : "";
}

function trimString(input, n) {
  return input.substring(0, n);
}

// Updated function to calculate the similarity score based on revised criteria
const calculateMatchingScore = (item1, item2, config) => {
  let score = 0;
  let breakdown = {}; // To keep track of score contribution from each field

  const amount1 = parseFloat(getNestedValue(item1, config.amountField1)) || 0;
  const amount2 = parseFloat(getNestedValue(item2, config.amountField2)) || 0;
  const account1 = getNestedValue(item1, config.accountField1);
  const account2 = getNestedValue(item2, config.accountField2);
  const date1 = getNestedValue(item1, config.dateField1);
  const date2 = getNestedValue(item2, config.dateField2);
  const categoryId = getNestedValue(item2, 'categoryId');

  const merchant2 = normalizeMerchantName(getNestedValue(item2, config.merchantField2));
  const merchant2length = merchant2.length;
  const merchant1 = trimString(normalizeMerchantName(getNestedValue(item1, config.merchantField1)), merchant2length);

  // Strict match for account number
  if (account1 !== account2) return 0;

  // Determine the amount tolerance to use based on categoryId
  const useTolerance = config.categoryIds && config.categoryIds.includes(categoryId);
  const tolerancePercentage = useTolerance ? config.amountTolerancePercentage : 0;

  // Calculate amount difference and assign score based on tolerance
  const amountDiff = percentageDifference(amount1, amount2);
  if (amount1 === amount2) {
    score += config.amountWeight;
    breakdown.amountMatch = config.amountWeight;
  } else if (amountDiff <= tolerancePercentage) {
    const partialScore = config.amountWeight / 3; // Less weight for amount tolerance match
    score += partialScore;
    breakdown.amountMatch = partialScore;
  } else {
    return 0; // If amount difference is too large, not a match
  }

  // Calculate score based on date proximity
  if (date1 && date2 && dateDifferenceInDays(date1, date2) <= config.dateProximity) {
    score += config.dateWeight;
    breakdown.dateProximity = config.dateWeight;
  } else {
    score -= config.dateWeight / 2; // Apply penalty if date proximity is outside the acceptable range
    breakdown.datePenalty = config.dateWeight / 2;
  }

  // Calculate score based on merchant name similarity, but only if similarity is above a threshold
  let merchantScore = 0;
  if (merchant1 && merchant2) {
    const similarity = stringSimilarity.compareTwoStrings(merchant1, merchant2);

    if (similarity >= config.minimumMerchantSimilarity) {
      merchantScore = Math.floor(similarity * config.merchantWeight);
      score += merchantScore;
    }

    breakdown.merchantSimilarity = merchantScore;

    if (similarity < 0.5) {
      const penalty = config.merchantWeight / 2;
      score -= penalty;
      breakdown.merchantPenalty = penalty;
    }
  }

  return score;
};

// Original function to find matches between array1 and array2
export const findMatches = (array1, array2, config = {}) => {
  const defaultConfig = {
    amountField1: 'capitalOneInstantNotification.purchaseAmount',
    amountField2: 'amount',
    accountField1: 'capitalOneInstantNotification.lastFour',
    accountField2: 'accountNumber',
    dateField1: 'capitalOneInstantNotification.purchaseDate',
    dateField2: 'date',
    merchantField1: 'capitalOneInstantNotification.purchaseMerchant',
    merchantField2: 'merchantEntityName',

    amountWeight: 25,
    accountWeight: 25,
    dateWeight: 15,
    dateProximity: 2,
    merchantWeight: 0.1,

    amountTolerancePercentage: 1,
    minimumScoreThreshold: 1,

    // Separate similarity configurations for merchant and vendor matching
    minimumMerchantSimilarity: 0.7,  // Keep this for merchantEntityName matching
    minimumQbVendorSimilarity: 0.4,  // New value for qbVendor matching to loosen it up

    categoryIds: [], // Add default for categoryIds
  };

  const finalConfig = { ...defaultConfig, ...config };

  const matches = [];
  const unmatchedFromArray1 = [];
  const unmatchedFromArray2 = new Set(array2.map((item) => item.id));

  // Store the IDs of items from array2 that were matched with array1 items
  const matchedFromArray2Ids = new Set();

  array1.forEach((item1) => {
    let bestMatch = null;
    let highestScore = 0;

    array2.forEach((item2) => {
      const score = calculateMatchingScore(item1, item2, finalConfig);

      if (score > highestScore) {
        highestScore = score;
        bestMatch = item2;
      }
    });

    // Consider a match only if it meets the minimum score threshold
    if (bestMatch && highestScore >= finalConfig.minimumScoreThreshold) {
      matches.push({ ...item1, matchedItem: bestMatch, matchingScore: highestScore });
      unmatchedFromArray2.delete(bestMatch.id);
      matchedFromArray2Ids.add(bestMatch.id); // Track matched IDs
    } else {
      unmatchedFromArray1.push(item1);
    }
  });

  // Create the final unmatchedFromArray2, removing items that are matched in unmatchedFromArray1
  const unmatchedFromArray2Array = Array.from(unmatchedFromArray2)
    .filter((id) => !matchedFromArray2Ids.has(id))
    .map((id) => array2.find((item) => item.id === id));

  return {
    matches,
    unmatchedFromArray1,
    unmatchedFromArray2: unmatchedFromArray2Array,
  };
};

// Helper function to find a matching vendor from qbVendors based on qbId or normalizedName using name similarity
// const findMatchingQbVendor = (matchedItem, qbVendors, config) => {
//   const { qbId, merchantEntityName } = matchedItem;

//   // Normalize the merchantEntityName for comparison
//   const normalizedMerchantName = normalizeMerchantName(merchantEntityName);

//   // Find all vendors with a similar name to merchantEntityName using similarity
//   let bestQbVendor = null;
//   let highestSimilarity = 0;

//   qbVendors.forEach((vendor) => {
//     const similarity = stringSimilarity.compareTwoStrings(normalizedMerchantName, vendor.normalizedName);
//     if (similarity >= config.minimumMerchantSimilarity && similarity > highestSimilarity) {
//       highestSimilarity = similarity;
//       bestQbVendor = vendor;
//     }
//   });

//   return bestQbVendor || null; // Return null if no match is found
// };

// const findMatchingQbVendor = (matchedItem, qbVendors, config) => {
//   const { merchantEntityName } = matchedItem;

//   // Normalize the merchantEntityName for comparison
//   const normalizedMerchantName = normalizeMerchantName(merchantEntityName);

//   let bestQbVendor = null;
//   let highestSimilarity = 0;

//   qbVendors.forEach((vendor) => {
//     const vendorNormalizedName = vendor.normalizedName;

//     // Calculate similarity using string similarity library
//     const similarity = stringSimilarity.compareTwoStrings(normalizedMerchantName, vendorNormalizedName);

//     // Check if there's a partial match using substring comparison
//     const partialMatch = normalizedMerchantName.includes(vendorNormalizedName) || vendorNormalizedName.includes(normalizedMerchantName);

//     // Determine if we should consider this vendor a match based on relaxed criteria
//     if (
//       similarity >= config.minimumMerchantSimilarity || // Original similarity check
//       partialMatch // Allow partial match as a fallback
//     ) {
//       if (similarity > highestSimilarity) {
//         highestSimilarity = similarity;
//         bestQbVendor = vendor;
//       }
//     }
//   });

//   return bestQbVendor || null; // Return null if no match is found
// };

// const findMatchingQbVendor = (matchedItem, qbVendors, config) => {
//   const { merchantEntityName } = matchedItem;

//   // Normalize the merchantEntityName for comparison
//   const normalizedMerchantName = normalizeMerchantName(merchantEntityName);

//   let bestQbVendor = null;
//   let highestSimilarity = 0;

//   qbVendors.forEach((vendor) => {
//     const vendorNormalizedName = vendor.normalizedName;

//     // Calculate similarity using string similarity library
//     const similarity = stringSimilarity.compareTwoStrings(normalizedMerchantName, vendorNormalizedName);

//     // Check if there's a partial match using substring comparison
//     const partialMatch = normalizedMerchantName.includes(vendorNormalizedName) || vendorNormalizedName.includes(normalizedMerchantName);

//     // Use separate threshold for qbVendor matching
//     if (
//       similarity >= config.minimumQbVendorSimilarity || // Use the new config value for vendor matching
//       partialMatch // Allow partial match as a fallback
//     ) {
//       if (similarity > highestSimilarity) {
//         highestSimilarity = similarity;
//         bestQbVendor = vendor;
//       }
//     }
//   });

//   return bestQbVendor || null; // Return null if no match is found
// };

// Helper function to find a matching vendor from qbVendors based on qbId or normalizedName using name similarity
const findMatchingQbVendor = (matchedItem, qbVendors, config) => {
  const { merchantEntityName } = matchedItem;

  // Normalize the merchantEntityName for comparison
  const normalizedMerchantName = normalizeMerchantName(merchantEntityName);

  let bestQbVendor = null;
  let highestSimilarity = 0;

  qbVendors.forEach((vendor) => {
    const vendorNormalizedName = vendor.normalizedName;

    // Calculate similarity using string similarity library
    const similarity = stringSimilarity.compareTwoStrings(normalizedMerchantName, vendorNormalizedName);

    // Check if there's a partial match using substring comparison
    const partialMatch = normalizedMerchantName.includes(vendorNormalizedName) || vendorNormalizedName.includes(normalizedMerchantName);

    // Use separate threshold for qbVendor matching
    if (
      similarity >= config.minimumQbVendorSimilarity || // Use the new config value for vendor matching
      partialMatch // Allow partial match as a fallback
    ) {
      if (similarity > highestSimilarity) {
        highestSimilarity = similarity;
        bestQbVendor = vendor;
      }
    }
  });

  // Return both the best matched qbVendor and the match score (highestSimilarity)
  return {
    qbVendor: bestQbVendor,
    qbVendorMatchScore: highestSimilarity,
  };
};

// New function to find matches and include qbVendors using name similarity, along with the match score
export const findMatchesWithQbVendors = (array1, array2, qbVendors, config = {}) => {
  // Run the original matching function to get matches and unmatched items
  const { matches, unmatchedFromArray1, unmatchedFromArray2 } = findMatches(array1, array2, config);

  // Enrich each match with the corresponding qbVendor and include qbVendorMatchScore
  const enrichedMatches = matches.map((match) => {
    const { qbVendor, qbVendorMatchScore } = findMatchingQbVendor(match.matchedItem, qbVendors, config);
    return { ...match, qbVendor, qbVendorMatchScore };
  });

  return {
    matches: enrichedMatches,
    unmatchedFromArray1,
    unmatchedFromArray2,
  };
};


// Example usage:
// const uploadedReceipts = /* Your first array here */;
// const syncedTransactions = /* Your second array here */;
// const qbVendors = /* Your qbVendors array here */;
// const config = {
//   amountField1: 'capitalOneInstantNotification.purchaseAmount',
//   amountField2: 'amount',
//   accountField1: 'capitalOneInstantNotification.lastFour',
//   accountField2: 'accountNumber',
//   dateField1: 'capitalOneInstantNotification.purchaseDate',
//   dateField2: 'date',
//   merchantField1: 'capitalOneInstantNotification.purchaseMerchant',
//   merchantField2: 'merchantEntityName',
//   amountWeight: 25,
//   accountWeight: 25,
//   dateWeight: 15,
//   dateProximity: 2,
//   merchantWeight: 0.1,
//   amountTolerancePercentage: 1,
//   minimumScoreThreshold: 1,
//   minimumMerchantSimilarity: 0.7,
//   minimumQbVendorSimilarity: 0.4,
//   categoryIds: [], // Add default for categoryIds
// };

// const result = findMatchesWithQbVendors(uploadedReceipts, syncedTransactions, qbVendors, config);
// console.log(result);
