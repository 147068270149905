import { createPrimaryUnitInspection, createPrimaryUnitCompletedInspection } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();
 
export const addPrimaryUnitInspection = async (props) => {
  if (!props) {
    return 'Missing params';
  }

  try {
    const {
      divisionId,
      createdById,
      unitId,
      inspectedOn,
      inspectedAt,
      inspectedById,
      statusId,
      timeToInspect,
      inspectionPoints,
      failureCount,
    } = props;

    const inspectionProps = {
      divisionDateForFailure: `${divisionId}#${inspectedOn}`,
      createdById,
      divisionDate: `${divisionId}#${inspectedOn}`,
      unitId,
      inspectedOn,
      inspectedAt,
      inspectedById,
      divisionId,
      dateUnit: `${inspectedOn}#${unitId}`,
      statusId,
      timeToInspect,
    };

    if (failureCount > 0) {
      inspectionProps.failureCount = failureCount;
    }

    // console.log(' ----------> new inspection props: ', inspectionProps);

    const primaryUnitInspectionResponse = await client.graphql({
      query: createPrimaryUnitInspection,
      variables: { input: inspectionProps },
    });

    // console.log(' !!!!! primaryUnitInspectionResponse : ', primaryUnitInspectionResponse);
    const inspectionId = primaryUnitInspectionResponse.data.createSpkPrimaryUnitInspectionTbl.id;
    // console.log('This should be the just completed inspection ID: ', inspectionId);

    // Process inspection points
    for (const point of inspectionPoints) {
      const inspectionPointProps = {
        unitInspectionId: inspectionId,
        inspectionPointId: point.point_id,
        didPass: point.point_value === 'Pass',
        failedReason: point.point_value === 'Fail' ? point.failed_reason : null,
      };

      const primaryUnitInspectionPointsResponse = await client.graphql({
        query: createPrimaryUnitCompletedInspection,
        variables: { input: inspectionPointProps },
      });

      // console.log('Primary Unit Inspection Points Response: ', primaryUnitInspectionPointsResponse);
      const result = primaryUnitInspectionPointsResponse.data.createSpkPrimaryUnitCompletedInspectionTbl;
      // console.log('Result for inspection point: ', result);
    }

    return inspectionId;
  } catch (err) {
    return 'error addPrimaryUnitInspection: ' + err;
  }
};

addPrimaryUnitInspection.propTypes = {
  props: PropTypes.shape({
    divisionId: PropTypes.string.isRequired,
    createdById: PropTypes.string.isRequired,
    unitId: PropTypes.string.isRequired,
    inspectedOn: PropTypes.string.isRequired,
    inspectedAt: PropTypes.string.isRequired,
    inspectedById: PropTypes.string.isRequired,
    statusId: PropTypes.string.isRequired,
    timeToInspect: PropTypes.string.isRequired,
    inspectionPoints: PropTypes.arrayOf(
      PropTypes.shape({
        point_id: PropTypes.string.isRequired,
        point_value: PropTypes.string.isRequired,
        failed_reason: PropTypes.string,
      })
    ).isRequired,
    failureCount: PropTypes.number,
  }).isRequired,
};
