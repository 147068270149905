import { updateDJT } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const updateDailyJobTicket = async (props) => {
  if (!props) return null;

  try {
    const response = await client.graphql({
      query: updateDJT,
      variables: { input: props }
    });

    const results = response.data.updateSpkDailyJobTicketTbl.id;
    return results;
  } catch (err) {
    return ('error updateDailyJobTicket: ', [err]);
  }
};

updateDailyJobTicket.propTypes = {
  props: PropTypes.object.isRequired,
};