import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { createSpkDJTEquipment } from '../../../graphql/custom_mutations';

const client = generateClient();

export const addDJTEquipment = async (props) => {
  if (props) {
    try {
      console.warn('addDJTEquipment props: ', props);

      const response = await client.graphql({
        query: createSpkDJTEquipment,
        variables: { input: props },
      });

      console.warn('addDJTEquipment response: ', response);
      const results = response.data.createSpkDJTEquipmentTbl.id;
      return results;
    } catch (err) {
      return 'error addDJTEquipment: ' + err;
    }
  } else {
    return null;
  }
};

addDJTEquipment.propTypes = {
  props: PropTypes.object.isRequired,
};
