import { useContext } from 'react';
import { FormContext } from '../Form';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";

export function TextInputWithConfirmationModal(props) {
  const { 
    fieldname='', 
    fieldlabel, 
    prettyname, 
    fieldtype='text', 
    // fieldvalue='',
    fieldvalue,
    variant='defaultinput',
    defaultValue,
    register, 
    errors, 
    placeholder='Placeholder', 
    isReadOnly=false, 
    isRequired=false, 
    isDisabled=false, 
    maximLength=100, 
    minimLength=0, 
    width,
    onChange,
    onBlur,
    onClick,
    onFocus,
    onTouchTap,
    onTouchStart,
    onMouseUp,
    onKeyUp,
    className,
    textAlign,
    isInvalid,
  } = props;
  
  const formContext = useContext(FormContext);
  const { form, handleFormChange } = formContext;

  const handleChange = (onChange) ? onChange : (event) => {}
  const handleBlur = (onBlur) ? onBlur : (event) => {}
  const handleClick = (onClick) ? onClick : (event) => {}
  const handleOnTouchTap = (onTouchTap) ? onTouchTap : (event) => {}
  const handleFocus = (onFocus) ? onFocus : (event) => {}
  const handleTouch = (onTouchStart) ? onTouchStart : (event) => {}
  const handleMouseUp = (onMouseUp) ? onMouseUp : (event) => {}
  const handleKeyUp = (onKeyUp) ? onKeyUp : (event) => {}

  const defaultFieldValue = (fieldvalue) ? fieldvalue : form[fieldname];
  const defaultFieldType = (fieldtype===undefined) ? 'text' : fieldtype

  let regexPattern = null
  if (fieldtype==="time") {
    regexPattern = {
      value: /^\d*$/,
      message: "Invalid time format"
    }
  }
  return (
    <FormControl isInvalid={errors[fieldname]}>
      <FormLabel htmlFor={fieldname}>{fieldlabel}</FormLabel>
      <Input
        width={width}
        textAlign={textAlign}
        className={className}
        defaultValue={defaultValue}
        id={fieldname}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        type={defaultFieldType}
        variant={variant}
        name={fieldname}
        placeholder={placeholder}
        value={defaultFieldValue}
        onMouseUp={(e) => handleMouseUp(e)}
        onKeyUp={(e) => handleKeyUp(e)}
        onFocus={(e) => handleFocus(e)}
        // onChange={(e) => alert(e)}
        // onMouseUp={(e) => alert(e)}
        // onClick={(e) => handleClick(e)}
        // handleOnTouchTap={(e) => handleOnTouchTap(e)}
        // onMouseUp={(e) => e.preventDefault()}
        {...register(fieldname, { 
            // onChange: (e) => handleChange(e),
            onBlur: (e) => handleBlur(e),
            // onFocus: (e) => handleFocus(e),
            required: { 
              value: isRequired, 
              message: `${prettyname||fieldname} required.`, 
            }, 
            maxLength: { 
              value: maximLength, 
              message: `${prettyname} must be maximum ${maximLength}`, 
            }, 
            minLength: { 
              value: minimLength, 
              message: `${prettyname} must be minimum ${minimLength}`, 
            },
            pattern: {regexPattern},
        })} 
      />
      <FormErrorMessage>
        {errors[fieldname] && errors[fieldname].message}
      </FormErrorMessage>
    </FormControl>

  )
}

