import { allAuxillaryUnitsSortableByName } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const fetchAuxiliaryUnits = async (allSort, sortDirection, limit) => {
  try {
    const gqlParams = {
      allSort: allSort||"all",
      sortDirection: sortDirection,
      limit: limit
    };

    const response = await client.graphql({
      query: allAuxillaryUnitsSortableByName,
      variables: gqlParams
    });

    // console.log('RESPONSE: ', response)
    const results = response.data.allAuxillaryUnitsSortableByName.items;

    return results;
  } catch (err) {
    return ('error fetchAuxiliaryUnits: ', [err]);
  }
};

fetchAuxiliaryUnits.propTypes = {
  allSort: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
};
