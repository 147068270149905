import PropTypes from 'prop-types';
import { IconButton } from '@chakra-ui/react';
import { AvailableIcons } from '../../Icons/AvailableIcons';

export const ButtonNonaryWithIconOnly = (props) => {
  const {
    name,
    ref,
    icon,
    iconsize = '24px',
    onClick,
    isDisabled=false,
    mx='0px',
    my='0px',
  } = props;

  return (
    <IconButton 
      {...props}
      w="40px"
      name={name}
      ref={ref}
      variant="withIconNonary" 
      icon={<AvailableIcons boxSize={iconsize} iconRef={icon} />}
      onClick={onClick}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
    />
  );
};

// Prop validation
ButtonNonaryWithIconOnly.propTypes = {
  name: PropTypes.string, // Name is expected to be a string
  ref: PropTypes.oneOfType([ // ref can be a function or an object created by React.createRef()
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  icon: PropTypes.string.isRequired, // icon is required and should be a string
  iconsize: PropTypes.string, // iconsize should be a string like "20px"
  onClick: PropTypes.func, // onClick is a function
  isDisabled: PropTypes.bool, // isDisabled is a boolean
  mx: PropTypes.oneOfType([ // mx can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([ // my can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
};