export const getItems = async (token, environment) => {
  if (!token) {
    console.warn('NO TOKEN in getItems')
    return null
  }
  // Lambda: getQBQuery
  const query = encodeURIComponent('query?query=SELECT * FROM Item WHERE Type = \'Service\'&minorversion=65')

  const response = await fetch('https://ye5zper44kdptmvqao3fiyfzhe0tzcab.lambda-url.us-east-1.on.aws/?token='+token+'&query='+query+'&qbenv='+environment, { 
    method: 'GET', 
    headers: new Headers({
        'Authorization': 'Bearer '+token, 
        'Content-Type': 'application/x-www-form-urlencoded',
    })
  });

  const dataJSON = await response.json()
  let qbresponse = ''

  // if data.JSON.fault.error[0].code = "3200". we need to refresh
  if (dataJSON.fault?.error.length>0) {
    console.warn('GOT AN ERROR getItems: ', dataJSON.fault?.error)
    qbresponse = null
  }
  if (dataJSON.QueryResponse) {
    qbresponse = dataJSON.QueryResponse
  }
  // console.log('qbresponse is: ', qbresponse)
  // setCustomers(dataJSON.QueryResponse.Customer)
  return qbresponse
}