import { listAllDJTClients } from '../../../graphql/custom_queries';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchAllClients = async () => {
  try {
    const response = await client.graphql({
      query: listAllDJTClients,
      variables: { allSort: "all" },
    });

    const results = response?.data?.allDjtClients?.items?.sort((a, b) => {
      if (a.contactCompany > b.contactCompany) return 1;
      if (a.contactCompany < b.contactCompany) return -1;
      return 0; // explicitly handling the case where they are equal
    });

    return results;
  } catch (err) {
    console.error('error fetchAllClients: ', err);
    return null;
  }
};
