import { createIFTAFuelTaxTracking } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const addIFTAFuelTracking = async (props) => {
  if (!props) return;
  
  try {
    console.log('addIFTAFuelTracking props: ', props);

    const response = await client.graphql({
      query: createIFTAFuelTaxTracking,
      variables: { input: props },
    });

    console.log('addIFTAFuelTracking response: ', response);

    const results = response.data.createSpkIFTAFuelTaxTrackingTbl.id;
    console.log('addIFTAFuelTracking results: ', results);

    return results;
  } catch (err) {
    return ('error addIFTAFuelTracking: ', [err]);
  }
};

addIFTAFuelTracking.propTypes = {
  props: PropTypes.shape({
    // Define the structure of "props" based on what is expected by the mutation.
  }).isRequired,
};