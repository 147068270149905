import { createClientSummaryEmailSent } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const addClientEmailSummarySent = async (props) => {
  try {
    const response = await client.graphql({
      query: createClientSummaryEmailSent,
      variables: { input: props }
    });

    // console.log('addClientEmailSummarySent response: ', response)
    const results = response.data.createSpkDJTClientSummaryEmailSent;
    // console.log('addClientEmailSummarySent results: ', results)
    return results;
  } catch(err) {
    return('Error: ', [err]);
  }
};

addClientEmailSummarySent.propTypes = {
  props: PropTypes.object.isRequired,
};
