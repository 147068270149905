import { fetchAuthSession } from 'aws-amplify/auth';

// // Assuming this URL will be used for fetching address suggestions.
const endpointUrl = "https://ugczaq3r89.execute-api.us-east-1.amazonaws.com/dev/adminsuggestaddress";

export const suggestAddress = async (query) => {
  try {
    
    // Refresh the session to ensure it's current and to get the latest authentication tokens
    const session = await fetchAuthSession();
    const authToken = `Bearer ${session.tokens.idToken}`;

    // Prepare the request payload
    let inputJSON = JSON.stringify(query);
    
    // Fetch suggested addresses from your API
    const response = await fetch(endpointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authToken,
      },
      body: inputJSON,
    });

    // console.info('<<<<< XXXXXXX >>>>>', inputJSON);

    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }

    const data = await response.json();
    // console.info('***** data?.body: ', data?.body); // Log the parsed data
    
    // Assuming data?.body contains the suggested addresses
    return data?.body;
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    throw error; // Rethrow to let the caller handle it
  }
};
