import { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
// import { generateClient } from 'aws-amplify/api';
// import { 
  // onCreateSpkPubSubMessage, 
  // onCreateSpkPrimaryUnitInspectionQueue,
  // onUpdateSpkPrimaryUnitInspectionQueue,
  // onCreateSpkTimeCoDailyDepartmentPunchesTbl,
  // onUpdateSpkPubSubMessage 
  // onCreateSpkPrimaryUnitInspection,
//   // onCreatePrimaryUnitInspection
// } from '../graphql/custom_subscriptions';
// import { getMessagesByTopic } from './graphqlCompnents/PubSub/getMessagesByTopic.jsx'
// import { getMessageStatusIdByStatus } from './graphqlCompnents/PubSub/getMessageStatusIdByStatus.jsx'
// import { updateMessageStatusId } from './graphqlCompnents/PubSub/updateMessageStatusId.jsx'
// import { CustomToast } from "../components/toast/CustomToast.jsx"
// import { listActiveTeamMemberIds } from './graphqlCompnents/TeamMember/listActiveTeamMemberIds' //(nextoken, limit)
// import { fetchTeamMemberPictureName } from './graphqlCompnents/Avatar/fetchTeamMemberPictureName' //(id)
// import { fetchAllUnitStatusOptions } from './graphqlCompnents/Inspection/fetchAllUnitStatusOptions.jsx'
// import { fetchCompletedUnitInspections } from './graphqlCompnents/Inspection/fetchCompletedUnitInspections.jsx'

// subscription - inspections
// import { fetchNewQueuedUnitInspections } from './graphqlCompnents/Inspection/fetchNewQueuedUnitInspections'
// import { fetchQueuedUnitInspections } from './graphqlCompnents/Inspection/fetchQueuedUnitInspections.jsx'
// import { fetchAllUnitsForInspection } from './graphqlCompnents/Inspection/fetchAllUnitsForInspection'
// import { fetchUnitDocumentTypes } from './graphqlCompnents/Unit/fetchUnitDocumentTypes.jsx';
// import { fetchUnitDocumentsByUnitIdDocumentTypeId } from './graphqlCompnents/Unit/fetchUnitDocumentsByUnitIdDocumentTypeId.jsx'
// import { fetchUnitDocumentsByUnitIdDocumentTypeId } from './graphqlCompnents/Unit/fetchUnitDocumentsByUnitIdDocumentTypeId'
// import { updateQueuedPrimaryUnitInspection } from './graphqlCompnents/Inspection/updateQueuedPrimaryUnitInspection'

// import { fetchUploadedDocument } from './graphqlCompnents/UploadedDocument.jsx'

// import { useAuthenticator } from '@aws-amplify/ui-react';
import { fetchTeamMemberDataByLoginEmail } from './graphqlCompnents/TeamMember/fetchTeamMemberDataByLoginEmail.jsx'

// import { Storage } from 'aws-amplify';
// import { SetS3Config } from '../services';
//https://medium.com/@anjanava.biswas/uploading-files-to-aws-s3-from-react-app-using-aws-amplify-b286dbad2dd7

// import { getSignedUrlForFile } from './custom-hooks/useSignedUrlForFile.jsx';

// import { getTeamMemberTopicId } from './graphqlCompnents/PubSub/getTeamMemberTopicId.jsx'

// import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
// import { Hub } from 'aws-amplify';
// import { Hub } from 'aws-amplify/utils';

import { isMobile } from 'react-device-detect';

// import { todaysDateYMD } from './functions/dateTime'

// import ImagePending from "/images/image_pending_icon_thumb.png"

import { useScreenSize } from './custom-hooks/useScreenSize'

import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

import { useAuthenticator } from '@aws-amplify/ui-react';



import { fetchAccountsByTeamMember } from './graphqlCompnents/Statement/fetchAccountsByTeamMember.jsx';
import { fetchInstantNotificationsByLastFour } from './graphqlCompnents/Statement/fetchInstantNotificationsByLastFour.jsx';


export const AppContext = createContext(null);

// export const ContextWrapper =  (props) => {
export const ContextWrapper = ({ children }) => {

  // console.info('LOADING APP CONTEXT')

  let isUserLoggedIn = false
  // useEffect(() => {
  //   console.log('isUserLoggedIn: ', isUserLoggedIn)
  // },[isUserLoggedIn])

  // useEffect(() => {
  //   const isUserLoggedIn = async () => {
  //     try {
  //       const { route } = useAuthenticator((context) => [context.route]);
        
  //     } catch (error) {
  //       return false;  // User is not logged in
  //     }
  //   };
  //   const loggedIn = isUserLoggedIn()
  //   setIsUserLoggedIn(loggedIn);
  // },[])

  const { route } = useAuthenticator((context) => [context.route]);
  if (route === 'authenticated') {
    isUserLoggedIn = true
  }
  // console.log('route: ', route)
  

  // SetS3Config('prod-spike-unit-images', 'protected');
  // THIS HAS GROWN TOO LARGE, NEED TO BREAK IT UP INTO SMALLER COMPONENTS
  // THE PROCESS FOR MAINTING STATE OF CONTEXT IS OUT OF CONTROL
  // THIS ENTIRE CONTEXT PROCESS NEEDS TO BE REFACTORED

  const [ userGroups, setUserGroups ] = useState([]);
  const [ userAttributes, setUserAttributes ] = useState([])
  const [ store, setStore ] = useState({
    userMessages: null,
    userData: null,
    inspectionStatuses: null,
    inspectionQueue: null,
    completedInspections: null,
    unitDocumentTypes: null,
    screenWidth: null,
    divisionHasOneClient: null,
    divisionAllowsEmailCC: null,
    enableAllSupervisors: null,
    defaultStartTime: null,
    defaultLunchDuration: null,
    defaultEndTime: null,
    showMaterialEquipmentMergeToggle: null,
    showDownloadPDF: null,
    usersAvatars: null,
    userIsMobile: null,
  });

  // const [ userMessages, setUserMessages ] = useState(null)
  // const [ inspectionQueue, setInspectionQueue ] = useState(null)
  // const [ completedInspections, setCompletedInspections ] = useState(null)
  // const [ usersAvatars, setUsersAvatars ] = useState(null)
  const [ userData, setUserData ] = useState(null)
  // const [ userEmail, setUserEmail ] = useState(null)
  // const [ userIsMobile, setUserIsMobile ] = useState(false)
  // const [ divisionId, setDivisionId ] = useState(null)
  const [ divisionHasOneClient, setDivisionHasOneClient ] = useState(null)
  const [ divisionAllowsEmailCC, setDivisionAllowsEmailCC ] = useState(null)
  const [ enableAllSupervisors, setEnableAllSupervisors ] = useState(null)
  const [ defaultStartTime, setDefaultStartTime ] = useState(null)
  const [ defaultLunchDuration, setDefaultLunchDuration ] = useState(null)
  const [ defaultEndTime, setDefaultEndTime ] = useState(null)
  const [ showMergeMaterialEquipmentToggle, setShowMergeMaterialEquipmentToggle ] = useState(false)
  const [ showDownloadPDF, setShowDownloadPDF ] = useState(false)
  const [ teamMemberId, setTeamMemberId ] = useState(null)
  // const [ userIsGlobalAdmin, setUserIsGlobalAdmin ] = useState(false)
  // const qbEnvironment = import.meta.env.VITE_QB_ENV;
  const hostname = window.location.hostname;

  const qbEnvironment = (hostname === 'localhost' || hostname === '192.168.10.226' || hostname==='brain-dev.spikeenterprise.io')
    ? 'sandbox' // When on localhost
    : 'production'; // For production domain

  // const client = generateClient();
  // const REFRESH_MS = 10*60*1000 //refresh every 10 minutes
  // const [ usertopicId, setUserTopicId ] = useState(null)
  const screenSize = useScreenSize();
  const [ screenWidth, setScreenWidth ] = useState(null)
  // const [ inspectionStatuses, setInspectionStatuses ] = useState(null)
  // const [ documentTypes, setDocumentTypes ] = useState(null)
  // const [ thumbnailTypeId, setThumbnailTypeId ] = useState(null)

  useEffect(() => {
    if (screenSize.width) {
      setScreenWidth(screenSize.width)
    } 
  },[screenSize])

  const actions = {
    clearStore: () => {
      setStore(null)
    }
  };

  useEffect(() => {
    async function fetchUserData() {
      try {
        const attributes = await fetchUserAttributes();
        // console.info('userAttributes: ', attributes)
        setUserAttributes(attributes)
        const session = await fetchAuthSession();
        // console.info('session: ', session)
        const groups = session.tokens.idToken?.payload['cognito:groups'] || [];
        // console.info('groups: ', groups)
        setUserGroups(groups);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }

    isUserLoggedIn && fetchUserData();
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (userData && divisionAllowsEmailCC !== null) {
      setStore({ 
        // userMessages: userMessages?.userMessages,
        userData: userData?.userData,
        userIsMobile: isMobile,
        // inspectionStatuses: inspectionStatuses,
        // inspectionQueue: inspectionQueue?.inspectionQueue,
        // completedInspections: completedInspections?.completedInspections,
        // unitDocumentTypes: documentTypes,
        screenWidth: screenWidth,
        divisionHasOneClient: divisionHasOneClient,
        divisionAllowsEmailCC: divisionAllowsEmailCC,
        enableAllSupervisors: enableAllSupervisors,
        defaultStartTime: defaultStartTime,
        defaultLunchDuration: defaultLunchDuration,
        defaultEndTime: defaultEndTime,
        showMaterialEquipmentMergeToggle: showMergeMaterialEquipmentToggle,
        showDownloadPDF: showDownloadPDF,
        qbEnvironment: qbEnvironment,
      });
    }
  }, [
    userData, 
    // userMessages, 
    // inspectionStatuses, 
    // inspectionQueue, 
    // completedInspections, 
    // documentTypes, 
    screenWidth, 
    userGroups,
    divisionHasOneClient, divisionAllowsEmailCC, enableAllSupervisors, 
    defaultStartTime, defaultLunchDuration, defaultEndTime, 
    showMergeMaterialEquipmentToggle, showDownloadPDF, qbEnvironment
  ]);

  



  const plaidEnv = 'production';
  const [ userAccounts, setUserAccounts ] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const accounts = await fetchAccountsByTeamMember(plaidEnv, teamMemberId);
      console.warn('cards of team member: ', accounts);
      setUserAccounts(accounts);
    }
    if (teamMemberId) {
      fetchData()
    }
  },[teamMemberId, plaidEnv])

  const [ unsubmittedReceiptCount, setUnsubmittedReceiptCount ] = useState(null)
  useEffect(() => {
    const fetchTransactions = async () => {
      if (!userAccounts || userAccounts.length === 0) return;
  console.log('userAccounts: ', userAccounts);
      try {
        const transactionPromises = userAccounts.map(async (account) => {
          const cardNumber = account.cardLastFour;
  
          const [trueTransactions, falseTransactions] = await Promise.allSettled([
            fetchInstantNotificationsByLastFour(cardNumber, true, 500),
            fetchInstantNotificationsByLastFour(cardNumber, false, 500),
          ]);
  
          const transactions = [];
          if (trueTransactions.status === 'fulfilled' && trueTransactions.value) {
            transactions.push(...trueTransactions.value);
          }
          if (falseTransactions.status === 'fulfilled' && falseTransactions.value) {
            transactions.push(...falseTransactions.value);
          }
  
          return transactions;
        });
  
        const allTransactionResults = await Promise.allSettled(transactionPromises);
  
        const successfulTransactions = allTransactionResults
          .filter(result => result.status === 'fulfilled' && result.value.length > 0)
          .map(result => result.value);
  
        const flattenedTransactions = successfulTransactions.flat();
  
        // Separate transactions into two groups: those with isSubmitted === null and others
        const [nullSubmitted, otherSubmitted] = flattenedTransactions.reduce(
          (acc, transaction) => {
            transaction.isSubmitted === null ? acc[0].push(transaction) : acc[1].push(transaction);
            return acc;
          },
          [[], []]
        );
  
        // Sort both groups by purchaseDate in ascending order
        nullSubmitted.sort((a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate));
        otherSubmitted.sort((a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate));
  
        // Concatenate the two groups, with nullSubmitted transactions first
        const sortedTransactions = [...nullSubmitted, ...otherSubmitted];
  
        // Update the state with the sorted transactions
        // setUserTransactions(sortedTransactions);
        let count = sortedTransactions.filter(item => item.isSubmitted !== true).length;
        console.warn('count: ', count);
        setUnsubmittedReceiptCount(count||0);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, [userAccounts]);

  useEffect(() => {
    if (userAttributes.email) {
      getLoggedInUserData(userAttributes.email).then((results) => {
        setTeamMemberId(results.id)
        setUserData({
          userData: {
            email: userAttributes.email, 
            name: results.firstName+' '+results.lastName, 
            divisionId: results.divisionId, 
            // divisionHasOneClient: divisionHasOneClient,
            // enableAllSupervisors: enableAllSupervisors,
            // defaultStartTime: defaultStartTime,
            // defaultLunchDuration: defaultLunchDuration,
            // defaultEndTime: defaultEndTime,
            id: results.id, 
            signedUrl: results.signedUrl, 
            isGlobalAdmin: results.isGlobalAdmin,
            groups: userGroups,
            receiptCount: (unsubmittedReceiptCount) ? unsubmittedReceiptCount.toString() : '0',
          }
        })
      })
    }
  }, [userAttributes, userGroups, unsubmittedReceiptCount])



  // let priorConnectionState = ConnectionState
  // monitor connection state
  // Hub.listen('pubsub', (data) => {
  // Hub.listen('api', (data) => {
  //   // console.log('payload data: ', data)
  //   const { payload } = data;
  //   if (payload.event && payload.event === CONNECTION_STATE_CHANGE) {
  //     // const connectionState = payload.data.connectionState;
  //     // console.log('connectionState: ', CONNECTION_STATE_CHANGE);
  //     if (priorConnectionState === ConnectionState.Connecting && payload.data.connectionState === ConnectionState.Connected) {
  //       // const connectionState = payload.data.connectionState
  //       // console.warn('---CONNECTION STATE CHANGE--- ', connectionState)
  //       const refreshMessages = async () => {
  //         await updateMessages();
  //       }
  //       // console.info('RECONNECTING AFTER CONNECTION STATE CHANGE');
  //       (usertopicId) && refreshMessages();
  //       // THIS IS CRASHING THE APP AFTER LOGIN, DEAL WITH IT LATER
  //       // NO REFRESHING INSPECTIONS AFTER TIMEOUT RIGHT NOW
  //       // (divisionId) && updateInspectonQueue();
  //     }
  //     priorConnectionState = payload.data.connectionState;
  //   }
  // });
  
  const getLoggedInUserData = async (email) => {
    if (!email) return
    /*---  Get user data for user by email  ---*/
    /*---  id, divisionId, firstName, middleName, lastName, goesBy, imgUrl  ---*/
    /*---  Insert signedUrl if the user has an image avatar  ---*/
    const userData = await fetchTeamMemberDataByLoginEmail(email)

    // console.warn(' !!!!!-----> userData: ', userData)
    
    // setTeamMemberId(userData?.id)
    // setDivisionId(userData?.divisionId)
    setDivisionHasOneClient(userData?.division?.djtOneClient||false)
    setDivisionAllowsEmailCC(userData?.division?.djtAllowsEmailCC||false)
    setEnableAllSupervisors(userData?.division?.djtEnableAllSupervisors||false)
    setDefaultStartTime(userData?.division?.djtDefaultStartTime||'08:00')
    setDefaultLunchDuration(userData?.division?.djtDefaultLunchDuration||'00:30')
    setDefaultEndTime(userData?.division?.djtDefaultEndTime||'17:00')
    setShowMergeMaterialEquipmentToggle(userData?.division?.djtMergeMaterialEquipment||false)
    setShowDownloadPDF(userData?.division?.djtShowDownloadPDF||false)
    // setUserIsGlobalAdmin(userData?.isGlobalAdmin||false)

    // const topicId = await getTeamMemberTopicId(userData?.id)
    // console.warn('x-topicId: ', topicId)
    // setUserTopicId(topicId)

    const hasAvatarImage = (userData.imgUrl||false)
    userData.signedUrl = null

    // SetS3Config("spikeavatarimages", "protected");

    if (hasAvatarImage) {
      // console.log('userData.imgUrl: ', userData.imgUrl)
      // const uploadedDcoument = await fetchUploadedDocument(userData.imgUrl)
      // console.log('uploadedDcoument: ', uploadedDcoument)
      // userData.signedUrl = await Storage.get(uploadedDcoument.key, {
      //   level: "protected",
      //   identityId: uploadedDcoument.identityId,
      // });
    }
    // console.log('z-userData: ', userData)
    return userData
  }

  // --- current user data

  // const initializeAvatars = useCallback(async (tmIDs) => {
  //   if (!tmIDs) return
  //   let usersAvatarData = []
  //   for (const item of tmIDs) {
  //     const avatarData = await fetchTeamMemberPictureName(item.id)
  //     usersAvatarData.push(avatarData)
  //   }

  //   // userMessages: [...store.userMessages]
  //   // setStore({ 
  //   //   ...store,
  //   //   userMessages: [...store.userMessages],
  //   //   usersAvatars: usersAvatarData })

  //   // setStore({ 
  //   //   ...store,
  //   //   usersAvatars: usersAvatarData })
  //   // setUsersAvatars({usersAvatars: usersAvatarData})
  // }, [])

  //fetchAllUnitStatusOptions
  
  // useEffect(() => {
  //   if (divisionId) {
  //     // this only needs to run one time
  //     let thumbnailTypeId = ''
  //     const documentTypes = async () => {
  //       const unitDocumentTypes = await fetchUnitDocumentTypes()
  //       // console.log('documentTypes: ', documentTypes)
  //       return unitDocumentTypes
  //     }
  //     documentTypes().then((data) => {
  //       thumbnailTypeId = data?.filter((type) => type?.name === 'unit-photo-thumbnail')?.[0]?.id
  //       setDocumentTypes(data)
  //       setThumbnailTypeId(thumbnailTypeId)
  //     })
  //   }
  // },[divisionId])

  // useEffect(() => {
  //   if (!divisionId || !thumbnailTypeId) {
  //     // console.log('divisionId or thumbnailTypeId not available yet');
  //     return;
  //   } else {
  //     // console.info('divisionId: ', divisionId)
  //     // console.info('thumbnailTypeId: ', thumbnailTypeId)
  //   }
  
  //   const fetchStatuses = async () => {
  //     try {
  //       const unitStatuses = await fetchAllUnitStatusOptions();
  //       setInspectionStatuses(unitStatuses);
  //     } catch (error) {
  //       console.error('Error fetching unit statuses:', error);
  //     }
  //   };
  
  //   const fetchInspections = async () => {
  //     try {
  //       const allUnitsForInspeciton = await fetchQueuedUnitInspections(divisionId);
  //       console.log('allUnitsForInspeciton: ', allUnitsForInspeciton);
  //       if (!allUnitsForInspeciton || allUnitsForInspeciton.length === 0) {
  //         console.log('No units for inspection found');
  //         return [];
  //       }
  
  //       const unitsAtDivision = allUnitsForInspeciton.items?.filter(unit => unit?.divisionId === divisionId) || [];
        
  //       const queuedUnitsAtDivision = unitsAtDivision.filter(unit => 
  //         ['initial_inspection', 'inspection_queued', 'inspection_active'].includes(unit?.unitStatus?.status?.statusQuery)
  //       );
  
  //       let unitObjWithThumbnails = [];
  //       for (const unit of queuedUnitsAtDivision) {
  //         unit.thumbnailSignedUrl = ImagePending;
  //         const unitDocumentParams = `${unit.unitId}#${thumbnailTypeId}`;
          
  //         try {
  //           const thumbnailDocument = await fetchUnitDocumentsByUnitIdDocumentTypeId(unitDocumentParams);
  //           if (thumbnailDocument[0]?.uploadedDocument?.id) {
  //             const { key, identityId } = thumbnailDocument[0].uploadedDocument;
  //             try {
  //               // unit.thumbnailSignedUrl = await Storage.get(key, {
  //               //   level: "protected",
  //               //   identityId: identityId,
  //               // });
  //               //bucketName, identityId, fileName, fileSecurity = 'protected'
  //               unit.thumbnailSignedUrl = await getSignedUrlForFile({
  //                 bucketName: 'prod-spike-unit-images', 
  //                 identityId: identityId, 
  //                 fileName: key, 
  //                 fileSecurity: 'protected'
  //               });
  //               // console.log('CHECK THIS OBJ FOR URL OR OTHER PARAMS: ', unit.thumbnailSignedUrl)
  //             } catch (storageError) {
  //               console.error('Error fetching thumbnail from storage:', storageError);
  //             }
  //           }
  //         } catch (documentError) {
  //           console.error('Error fetching thumbnail document:', documentError);
  //         }
          
  //         unitObjWithThumbnails.push(unit);
  //       }
  
  //       return unitObjWithThumbnails;
  //     } catch (error) {
  //       console.error('Error in fetchInspections:', error);
  //       return [];
  //     }
  //   };
  
  //   const initializeFetching = async () => {
  //     await fetchStatuses();
  //     const inspectionData = await fetchInspections();
  //     const uniqueData = removeDuplicateCodes(inspectionData);
  //     setInspectionQueue({inspectionQueue: uniqueData});
  //   };
  
  //   initializeFetching();
  
  // }, [divisionId, thumbnailTypeId]);
  
  // function removeDuplicateCodes(array) {
  //   const seenCodes = new Set();
  //   const uniqueArray = array.filter(item => {
  //     if (seenCodes.has(item.code)) {
  //       return false; // Skip this item, it's a duplicate code
  //     } else {
  //       seenCodes.add(item.code);
  //       return true; // Keep this item, its code is unique so far
  //     }
  //   });
  
  //   return uniqueArray;
  // }

  // useEffect(() => {
  //   if (inspectionQueue) {
  //     console.info(' ----- APPCONTEXT inspectionQueue: ', inspectionQueue)
  //   }
  // },[inspectionQueue])
  // useEffect(() => {
  //   const activeTMs = async getActiveTMs => {
  //     const data = await listActiveTeamMemberIds(null, 50)
  //     // const data = await listActiveTeamMemberIds(null, 3)
  //     return data.items
  //   }
  //   activeTMs().then((data) => initializeAvatars(data))
  // }, [initializeAvatars])


  // const { addToast } = CustomToast();

  // update status as delivered and get intital messages
  // useEffect(() => {

  //     // getting all messages
  //     // WORKING ORIGINAL
  //     (usertopicId) && getMessageStatusIdByStatus('DELIVERED').then(statusId => {
  //       // console.log('got statusId: ', statusId)
  //       getMessagesByTopic(usertopicId).then((messages) => {
  //         // console.log('messages obj: ', messages.length)
  //         // for each message that is 'SENT', update to 'DELIVERED'
  //         (messages.length > 0) && messages.forEach((message) => {
  //           if (message?.status?.status === 'SENT') {
  //             // update this message statusId to DELIVERED id. Will be reflected user side on next refresh
  //             // updateMessageStatusId({id: message.id, statusId: statusId})
  //             // updateMessageStatusId({id: message.id, statusId: statusId}).then((result) => {
  //             //   console.log('---UPDATED MESSAGE STATUS AND GOT: ', result)
  //             // })
  //           }
  //         })
  //         // setStore({ ...store, userMessages: messages })
  //         setUserMessages({userMessages: messages})
  //       })
  //     })

  // }, [usertopicId])

  // useEffect(() => {
  //   // console.log('Got user messages length: ', userMessages?.length)
  // }, [userMessages])

  // need to monitor subscription connection
  // also need to refresh messages every n minutes. n = 10
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // console.log('Running inbox refresh');
  //     // getMessagesByTopic(usertopicId).then((messages) => setStore({ ...store, userMessages: messages }))
  //     getMessagesByTopic(usertopicId).then((messages) => setUserMessages({userMessages: messages}))

  //   }, REFRESH_MS);
  
  //   return () => clearInterval(interval)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [usertopicId, store])

  // update when a new one comes in
  // function updateMessages() {
  //   // getMessagesByTopic(usertopicId).then((messages) => setStore({ ...store, userMessages: messages }))
  //   getMessagesByTopic(usertopicId).then((messages) => setUserMessages({userMessages: messages}))
  // }

  // const updateMessages = async () => {
  //   if (!usertopicId) return
  //   await getMessagesByTopic(usertopicId).then((messages) => setUserMessages({userMessages: messages}))
  // }

  // const updateInspectonQueue = async () => {
  //   // console.info('INSPECTION QUEUE WAS UPDATED!')
  //   const fetchStatuses = async () => {
  //     const unitStatuses = await fetchAllUnitStatusOptions()
  //     return unitStatuses
  //   }
  //   fetchStatuses().then((data) => {
  //     setInspectionStatuses(data)
  //   })

  //   const allUnitsForInspeciton = await fetchQueuedUnitInspections(divisionId)

  //   const unitsAtDivision = allUnitsForInspeciton?.items?.filter((unit) => unit?.divisionId === divisionId)

  //   const queuedUnitsAtDivision = unitsAtDivision?.filter((unit) => 
  //     unit?.unitStatus?.status?.statusQuery === 'initial_inspection' || 
  //     unit?.unitStatus?.status?.statusQuery === 'inspection_queued' || 
  //     unit?.unitStatus?.status?.statusQuery === 'inspection_active'
  //     )
    
  //   // console.info(' &&&&&&&&&&&& queuedUnitsAtDivision: ', queuedUnitsAtDivision)
  //   let unitObjWithThumbnails = []
  //   for (const unit of queuedUnitsAtDivision) {
  //     // default the image to pending, fill in if it exists below
  //     unit.thumbnailSignedUrl = ImagePending
  //     // if (unit?.unitId) {
  //     const unitDocumentParams = `${unit.unitId}#${thumbnailTypeId}`
  //     // console.info('unitDocumentParams: ', unitDocumentParams)
  //     const thumbnailDocument = await fetchUnitDocumentsByUnitIdDocumentTypeId(unitDocumentParams)
  //     // console.log('thumbnailDocument: ', thumbnailDocument)
  //     if (thumbnailDocument[0]?.uploadedDocument?.key) {
  //       const key = thumbnailDocument[0]?.uploadedDocument?.key
  //       const identityId = thumbnailDocument[0]?.uploadedDocument?.identityId

  //       // const url = await Storage.get(key, {
  //       //   level: "protected",
  //       //   identityId: identityId,
  //       // });
  //       const url = await getSignedUrlForFile({
  //         bucketName: 'prod-spike-unit-images', 
  //         identityId: identityId, 
  //         fileName: key, 
  //         fileSecurity: 'protected'
  //       });
  //       // console.log('CHECK THIS OBJ FOR URL OR OTHER PARAMS: ', url)
  //       unit.thumbnailSignedUrl = url
  //       // console.log('CHECK THIS OBJ FOR URL OR OTHER PARAMS: ', url)
  //       // console.log('CHECK THIS OBJ FOR URL OR OTHER PARAMS: ', unit.thumbnailSignedUrl)
  //     }
  //     unitObjWithThumbnails.push(unit)
  //     // }
  //   }

  //   // console.log('check for signedUrl here too: ', unitObjWithThumbnails)
  //   const uniqueData = removeDuplicateCodes(unitObjWithThumbnails)
  //   // console.warn(' 000000000000 uniqueData: ', uniqueData)
  //   setInspectionQueue({inspectionQueue: uniqueData})
  //   // setInspectionQueue({inspectionQueue: unitObjWithThumbnails})
  //   // })
  // }

  // const updateCompletedInspections = async () => {

  // }


  // Might need to have a subscription when a message status changes to DELIVERED, READ, REPLIED
  // https://dev.to/aws-builders/lessons-learned-aws-appsync-subscriptions-jek

  // subscription handling
  // https://docs.amplify.aws/lib/pubsub/subunsub/q/platform/js/#subscription-connection-status-updates
  // https://github.com/aws-amplify/amplify-js/pull/10063

  //https://docs.amplify.aws/javascript/prev/build-a-backend/graphqlapi/subscribe-data/
  
  // subscribe to punches
  // onCreateSpkTimeCoDailyDepartmentPunchesTbl
  // this needs to only run when an administrator has logged in.
  // probably get group and compare to adming
  // userGroups is an array of groups
  // userGroups?.includes('admin')

  // turning this off for now
  // useEffect(() => {
  //   if (divisionId) {
  //     const subscription = API.graphql(graphqlOperation(onCreateSpkTimeCoDailyDepartmentPunchesTbl)).subscribe({
  //       next: ({ provider, value }) => {
  //         const newPunches = value?.data?.onCreateSpkTimeCoDailyDepartmentPunchesTbl
  //         // console.log('provider: ', provider)
  //         // console.log('value: ', value?.data?.onUpdateSpkPrimaryUnitInspectionQueueTbl)
  //         // const queuedInspection = value.data.onUpdateSpkPrimaryUnitInspectionQueueTbl
  //         console.info('NOW GETTING NEW PUNCH DATA')
  //         console.info('new punch data: ', newPunches)
  //         // updateInspectonQueue()
  //         // update the queued inspection values
  //         // updateQueuedInspection(updatedValues)
  //       },
  //       error: error => console.error('Messages subscription issue: ', error),
  //       complete: () => console.log('Done'),
  //       // error: error => console.warn(error)
  //     });
  //     return () => subscription.unsubscribe()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [divisionId])

  // subscribe to pubsub messages
  // useEffect(() => {
  //   if (usertopicId) {
  //     // console.warn(' ***** USERTOPICID:', usertopicId)

  //     const subscription = API.graphql( graphqlOperation(onCreateSpkPubSubMessage, { 
  //       filter: {topicId: {eq: usertopicId}}
  //     })).subscribe({
  //       next: ({ value }) => {
  //         const newMessage = value.data.onCreateSpkPubSubMessageTbl
  //         const messageItems = JSON.parse(newMessage.messageJSON)

  //         addToast({fromId: newMessage.fromId, title: messageItems[0].subject, message: messageItems[0].message})

  //         // now need to trigger adding the new message to the userMessages
  //         updateMessages(usertopicId)

  //       },
  //       error: error => console.error('Messages subscription issue: ', error),
  //       complete: () => console.log('Done'),
  //       // error: error => console.warn(error)
  //   });    
  //   return () => subscription.unsubscribe()
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [usertopicId])

  

  // useEffect(() => {
  //   if (usertopicId) {
  //     console.warn('***** USERTOPICID:', usertopicId);

  //     const subscription = client.graphql({
  //       query: onCreateSpkPubSubMessage,
  //       gqlParams: {
  //         filter: { topicId: { eq: usertopicId } }
  //       }
  //     }).subscribe({
  //       next: () => {
  //         // const newMessage = value.data.onCreateSpkPubSubMessageTbl;
  //         // const messageItems = JSON.parse(newMessage.messageJSON);

  //         // addToast({
  //         //   fromId: newMessage.fromId,
  //         //   title: messageItems[0].subject,
  //         //   message: messageItems[0].message
  //         // });

  //         // Now need to trigger adding the new message to the userMessages
  //         updateMessages(usertopicId);
  //       },
  //       error: (error) => console.error('Messages subscription issue: ', error),
  //       complete: () => console.log('Done')
  //     });

  //     return () => subscription.unsubscribe();
  //   }
  // }, [usertopicId]); // eslint-disable-line react-hooks/exhaustive-deps

  //subscribe to inspection queue updated
  // useEffect(() => {
  //   if (divisionId) {
  //     const subscription = API.graphql(graphqlOperation(onUpdateSpkPrimaryUnitInspectionQueue, { 
  //       filter: {divisionId: {eq: divisionId}}
  //     })).subscribe({
  //       next: ({ value }) => {
  //         // const updatedValues = value?.data?.onUpdateSpkPrimaryUnitInspectionQueueTbl
  //         // console.log('provider: ', provider)
  //         console.log('value: ', value?.data?.onUpdateSpkPrimaryUnitInspectionQueueTbl)
  //         // const queuedInspection = value.data.onUpdateSpkPrimaryUnitInspectionQueueTbl
  //         // console.info('NOW RUNNING UPDATE INSPECTION QUEUE')
  //         updateInspectonQueue()
  //         // update the queued inspection values
  //         // updateQueuedInspection(updatedValues)
  //       },
  //       error: error => console.error('Messages subscription issue: ', error),
  //       complete: () => console.log('Done'),
  //       // error: error => console.warn(error)
  //     });
  //     return () => subscription.unsubscribe()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [divisionId, thumbnailTypeId])
  // useEffect(() => {
  //   if (divisionId) {
  //     const subscription = client.graphql({
  //       query: onUpdateSpkPrimaryUnitInspectionQueue,
  //       gqlParams: {
  //         filter: { divisionId: { eq: divisionId } }
  //       }
  //     }).subscribe({
  //       next: ({ value }) => {
  //         console.log('value: ', value?.data?.onUpdateSpkPrimaryUnitInspectionQueueTbl);

  //         // Trigger the update of the inspection queue
  //         updateInspectonQueue();

  //         // Optionally, you can update queued inspections if needed
  //         // updateQueuedInspection(updatedValues);
  //       },
  //       error: (error) => console.error('Messages subscription issue: ', error),
  //       complete: () => console.log('Done'),
  //     });

  //     return () => subscription.unsubscribe();
  //   }
  // }, [divisionId, thumbnailTypeId]); // eslint-disable-line react-hooks/exhaustive-deps
 
  //subscribe to inspection queue created
  // useEffect(() => {
  //   if (divisionId) {
  //     const subscription = API.graphql(graphqlOperation(onCreateSpkPrimaryUnitInspectionQueue, { 
  //       filter: {divisionId: {eq: divisionId}}
  //     })).subscribe({
  //       next: ({ value }) => {
  //         const queuedInspection = value.data.onCreateSpkPrimaryUnitInspectionQueueTbl
  //         // const messageItems = JSON.parse(newMessage.messageJSON)
  //         console.log('New queued inspection added: ', queuedInspection)
  //         console.info('RUNNING UPDATE INSPECTION QUEUE HERE')
  //         updateInspectonQueue()


  //       },
  //       error: error => console.error('Messages subscription issue: ', error),
  //       complete: () => console.log('Done'),
  //       // error: error => console.warn(error)
  //     });
  //     return () => subscription.unsubscribe()
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [divisionId])
  // useEffect(() => {
  //   if (divisionId) {
  //     const subscription = client.graphql({
  //       query: onCreateSpkPrimaryUnitInspectionQueue,
  //       gqlParams: {
  //         filter: { divisionId: { eq: divisionId } }
  //       }
  //     }).subscribe({
  //       next: ({ value }) => {
  //         const queuedInspection = value?.data?.onCreateSpkPrimaryUnitInspectionQueueTbl;
  //         console.log('New queued inspection added: ', queuedInspection);
  //         console.info('RUNNING UPDATE INSPECTION QUEUE HERE');
          
  //         updateInspectonQueue();
  //       },
  //       error: (error) => console.error('Messages subscription issue: ', error),
  //       complete: () => console.log('Done'),
  //     });
  
  //     return () => subscription.unsubscribe();
  //   }
  // }, [divisionId]); // eslint-disable-line react-hooks/exhaustive-deps

  //subscribe to completed inspections for today
  // useEffect(() => {
  //   if (divisionId) {
  //     const today = todaysDateYMD()
  //     const divisionDate = `${divisionId}#${today}`
  //     // console.info('ABOUT TO PULL SUBSCRIPTION FOR:', divisionDate)
  //     const subscription = API.graphql(graphqlOperation(onCreatePrimaryUnitInspection, { 
  //       filter: {divisionDate: {eq: divisionDate}}
  //     })).subscribe({
  //       next: ({ value }) => {
  //         // console.info(' ________________ ANYTHING???')
  //         const newInspection = value.data.onCreateSpkPrimaryUnitInspectionTbl
  //         console.log('newInspection: ', newInspection)

  //         const fetchCompletedInspections = async () => {
  //           const completedInspections = await fetchCompletedUnitInspections(divisionId)
  //           return completedInspections
  //         }
  //         fetchCompletedInspections().then((data) => {
  //           console.info(' -----> SETTING completedInspections IN STORE: ', data)
  //           setCompletedInspections({completedInspections: data})
  //         })
  //       },
  //       error: error => console.error('Messages subscription issue: ', error),
  //       complete: () => console.log('Done'),
  //       // error: error => console.warn(error)
  //     });
  //     return () => subscription.unsubscribe()
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [divisionId, thumbnailTypeId])
  // useEffect(() => {
  //   if (divisionId) {
  //     const today = todaysDateYMD();
  //     const divisionDate = `${divisionId}#${today}`;
  
  //     // Initiating subscription for the specific divisionDate
  //     const subscription = client.graphql({
  //       query: onCreatePrimaryUnitInspection,
  //       gqlParams: {
  //         filter: { divisionDate: { eq: divisionDate } }
  //       }
  //     }).subscribe({
  //       next: async ({ value }) => {
  //         const newInspection = value?.data?.onCreateSpkPrimaryUnitInspectionTbl;
  //         console.log('newInspection: ', newInspection);
  
  //         // Fetch the completed inspections
  //         const completedInspections = await fetchCompletedUnitInspections(divisionId);
  //         console.info('-----> SETTING completedInspections IN STORE: ', completedInspections);
  
  //         // Update the store with the fetched data
  //         setCompletedInspections({ completedInspections });
  //       },
  //       error: (error) => console.error('Messages subscription issue: ', error),
  //       complete: () => console.log('Done'),
  //     });
  
  //     return () => subscription.unsubscribe();
  //   }
  // }, [divisionId, thumbnailTypeId]); // eslint-disable-line react-hooks/exhaustive-deps
  

  // subscribe to create SpkPrimaryUnitInspectionTbl

  // Context example: https://4geeks.com/lesson/context-api
  // https://codesandbox.io/s/w75wq6v01k?from-embed=&file=/index.js:471-478

  // const [ actions, setActions ] = useState({
  //   addTask: title => setStore({ ...store, todos: store.todos.concat(title) })
  // })

  // setStore({ 
  //   ...store,
  //   usersAvatars: usersAvatarData })
  //   userData: {email: null, name: null, divisionId: null, id: null}

  
  
  

  return (
    <AppContext.Provider value={{ store, actions }}>
      {children}
    </AppContext.Provider>
  );
}

ContextWrapper.propTypes = {
  children: PropTypes.node.isRequired, // Ensure children are passed and are valid React nodes
};