/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import {
  Box,
  // Image,
  Flex,
  Spacer,
  Text,
  Container,
  HStack,
} from "@chakra-ui/react";

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';

// form buttons
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';

// react-hook-form
// import { useForm } from 'react-hook-form';

// graphql
// import { searchQBVendorsByVendorNameLower } from '../graphqlCompnents/Statement/searchQBVendorsByVendorNameLower.jsx';
import { syncQBVendors } from '../QuickBooks/syncQBVendors.jsx';

// custom components
import { ScrollableBox } from '../reactComponents/ScrollableBox.jsx'

import PropTypes from 'prop-types';

export const VendorMap = (props) => {
  // const { selectedStatement, screenWidth, handleMapVendor, onClose } = props;
  const { 
    handleMapVendor=()=>{}, 
    vendorName='',
    vendors=[], 
    environment='sandbox', 
    token='',
    register=()=>{},
    errors={},
    setError=()=>{},
    clearErrors=()=>{},
    setValue=()=>{},
    rowIndex=0,
    showCreateSupplier=true,
   } = props;

  //  console.log('VendorMap props: ', props)

  // clear out any previous search terms
  setValue('searchInput', '') 
  // const screenWidth = 1920;

  const [ searchTerm, setSearchTerm ] = useState('')
  // const [ nextToken, setNextToken ] = useState('')
  // const [ nextNextToken, setNextNextToken ] = useState('')
  const [ qbVendorsResults, setQBVendorsResults ] = useState([])

  // useEffect(() => {
  //   const searchVendors = async () => {
  //     const searchParams = {
  //       limit: 30,
  //       startsWith: searchTerm
  //     }
  //     if (nextNextToken) {
  //       searchParams.nextToken = nextNextToken
  //     } else {
  //       searchParams.nextToken = ''
  //     }
  //     const results = await searchQBVendorsByVendorNameLower(searchParams)

  //     const searchResults = results?.items || []
  //     setNextToken(results?.nextToken)
      
  //     const transformedData = transformData(searchResults) || []
  //     console.log('transformedData: ', transformedData)
  //     // setQBVendorsResults(transformedData)
  //     if (searchTerm) {
  //       console.log('setting transaformed data: ', transformedData)
  //       setQBVendorsResults(transformedData);
  //     } else {
  //       console.log('setting appended transaformed data: ', transformedData)
  //       setQBVendorsResults((prevVendors = []) => [...(prevVendors || []), ...transformedData]);
  //     }
      
  //   }
  //   searchVendors()
  // },[searchTerm, nextNextToken, vendorName])

  useEffect(() => {
    const searchVendors = async () => {

      let searchResults = [];
      if (searchTerm?.length<3) {
        searchResults = vendors.filter(vendor => vendor.normalizedName.startsWith(searchTerm.toLowerCase()));
      } else {
        searchResults = vendors.filter(vendor => vendor.normalizedName.includes(searchTerm.toLowerCase()))
      }

      // transform it to select options
      const transformedData = transformData(searchResults) || []

      if (searchTerm) {
        setQBVendorsResults(transformedData);
      } else {
        // setQBVendorsResults((prevVendors = []) => [...(prevVendors || []), ...transformedData]);
        setQBVendorsResults(transformData(vendors));
      }
      
    }
    vendors?.length>0 && searchVendors()
  },[searchTerm, vendors])

  // const searchNext = () => {
  //   setNextNextToken(nextToken)
  // }
  // const boxWidth = (screenWidth>480) ? screenWidth*.8 : screenWidth
  // const boxWidth = screenWidth*.8
  // console.log('screenWidth: ', screenWidth)
  // console.log('boxWidth: ', boxWidth)

  // Transform function
  const transformData = (data) => {
    return data?.map(item => ({
      id: item.qbId,
      name: item.qbDisplayName,
      value: item.qbDisplayNameLower
    }));
  };

  const resetVendor = () => {
    setValue('searchInput', '')
    setSearchTerm('')
  }

  // if search term is only one or two cahracters, search filter is begins with
  // if search term is 3 or more characters, serch filter is now contains
  const updateSearchTerm = (event) => {
    if (!event) return
    
    // if (event?.target?.value?.length>=3) {
      setSearchTerm(event.target.value)
    // }
  }

  const assignVendor = (vendor) => {
    console.log('assigned vendor: ', vendor)
    handleMapVendor(vendor, rowIndex)
    // onClose()
  }

  const handleSyncQuickBooksVendors = async () => {
    // if (environment!=='sandbox') {
      console.log('Sync QuickBooks Vendors')
      const props = {
        token: token, 
        environment: environment
      }
      const response = await syncQBVendors(props)
      console.log('syncQBVendors response: ', response)
    // }
  }

  return(
    <>
      <Container 
        // pb='25px' 
        // as="form" 
      // onSubmit={handleSubmit(formSubmit, onError)}
      >
        <Box w={'100%'}>
          <Flex alignItems="center" justifyContent="space-between" pt={'25px'}>
            <Box>
              {/* <Text as="span" textStyle='heading-1'>Map Vendor to QuickBooks</Text> */}
            </Box>
            <Spacer />
            <Box>
              <ButtonQuaternaryWithIcon 
                name='closeDrawer'
                iconsize='26px'
                leftIcon='close'
                value='Sync QuickBooks Vendors'
                onClick={handleSyncQuickBooksVendors}
                isDisabled={!token}
              />
            </Box>
          </Flex>
        </Box>

        {/* <Box paddingTop={'25px'}>
          <Image 
            border={'1px solid var(--dark-unit-image-border)'} 
            src={signedUrl} />
        </Box> */}

        <Box 
          w={'100%'}
          // maxWidth={boxWidth} 
          paddingTop={'25px'}>
          <HStack alignItems="flex-end" height="100%">
            <TextInput
              register={register}
              errors={errors}
              fieldname="searchInput"
              fieldlabel="Search existing QuickBooks suppliers"
              prettyname="Search"
              placeholder="Enter search term"
              onChange={updateSearchTerm}
            />
            <ButtonPrimaryPlain
              type="button"
              onClick={resetVendor}
              height={'40px'}
              name="resetVendor"
              value="Reset"
            />
          </HStack>
        </Box>

        {/* <Box maxWidth={boxWidth} paddingTop={'25px'}>
          <Text>Vendor name as extracted: {!vendorName ? 'Empty - Only this instance will be mapped.' : vendorName}</Text>
        </Box> */}

        <Box 
          w={'100%'}
          // width={boxWidth} 
          paddingTop={'25px'}>
          <Text pb={'10px'}>All suppliers from QuickBooks</Text>
          <ScrollableBox 
            // handleLoadMore={searchNext} 
            handleSelectedObject={assignVendor} 
            items={qbVendorsResults} 
            suggestedMerchantName={vendorName}
            // width={boxWidth} 
            // width={'300px'} 
            width={'100%'} 
            height='285px' 
            register={register} 
            errors={errors} 
            setError={setError} 
            clearErrors={clearErrors} 
            showCreateSupplier={showCreateSupplier}
            />
        </Box>

        {/* <Box height={'50px'}></Box> */}

      </Container>
      {/* <Box height={'50px'}></Box> */}
    </>
  )
}

VendorMap.propTypes = {
  handleMapVendor: PropTypes.func,
  vendorName: PropTypes.string,
  vendors: PropTypes.array,
  environment: PropTypes.string,
  token: PropTypes.string,
  register: PropTypes.func,
  errors: PropTypes.object,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  setValue: PropTypes.func,
  rowIndex: PropTypes.number,
  showCreateSupplier: PropTypes.bool,
};