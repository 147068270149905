import { NavLink } from 'react-router-dom'

import {
  Box,
  Text,
  HStack,
  MenuItem,
} from "@chakra-ui/react";
import PropTypes from 'prop-types';

// icons
import { AvailableIcons } from './Icons/AvailableIcons';

export const MainNavMenuItemPhone = (props) => {

  // console.log('nav props: ', props)
  const { index, navlinkTo, itemText, itemIcon } = props

  const renderIcon = (icon, index, isNavAcive, isNavPending) => {
    const pendingNav = (isNavPending) ? "" : "navlink-text"
    const iconClassName = (isNavAcive) ? "navlink-text-selected" : pendingNav
    return (
      <AvailableIcons key={'_i_'+index} boxSize={'18px'} iconRef={icon} className={iconClassName} />
    )
  }

  return(
   
    <MenuItem>
      <NavLink key={`_n_${index}`} to={`${navlinkTo}`}>
      {({ isActive, isPending }) => (
        <Box 
          key={`_b1_${index}`}
          className={isActive ? "navlink-selected" : isPending ? "" : "navlink"} >
          <HStack 
            key={`_hs_${index}`}
            mt={'9px'}
            align={'left'} >
            <Box 
              key={`_bs_${index}`}
              marginLeft={'8px'} 
              marginRight={'2px'} >
                {renderIcon(itemIcon, index, isActive, isPending)}
            </Box>
            <Text key={`_t_${index}`} className={isActive ? "navlink-text-selected" : isPending ? "" : "navlink-text"}>{itemText}</Text>
          </HStack>
        </Box>
      )}
    </NavLink>
    </MenuItem>
  )
}
MainNavMenuItemPhone.propTypes = {
  index: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]), 
  navlinkTo: PropTypes.string, 
  itemText: PropTypes.string, 
  itemIcon: PropTypes.string
}