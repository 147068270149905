import PropTypes from 'prop-types';
import quickBooksLogo from './ButtonQuickBooksLogin.svg';
import { Button, Image } from '@chakra-ui/react';

export const ButtonQuickBooksLogin = (props) => {
  const {
    name,
    onClick,
    isDisabled=false,
  } = props;

  return (
    <Button 
      name={name}
      variant='plainQuickBooks' 
      onClick={onClick}
      isDisabled={isDisabled}
    >
      <Image src={quickBooksLogo} w={'210px'} alt="QuickBooks Logo" />
    </Button>
  );
};

// Prop validation
ButtonQuickBooksLogin.propTypes = {
  name: PropTypes.string, // Name is expected to be a string
  onClick: PropTypes.func, // onClick is a function
  isDisabled: PropTypes.bool, // isDisabled is a boolean
};
