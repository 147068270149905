export const createQBVendor = async (token, vendorName, qbEnv) => {
  if (!vendorName || !token) return
  const displayName = encodeURIComponent(vendorName);
  try {
      const response = await fetch(`https://3zj4oudsyrnwldkzfz73bxfanq0mjrza.lambda-url.us-east-1.on.aws/?token=${token}&displayName=${displayName}&qbenv=${qbEnv}`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/x-www-form-urlencoded',
          }
      });

      if (!response.ok) {
          const error = await response.json();
          console.error('Error response from fetch:', error);
          if (response.status === 409) {
              console.error('Vendor already exists.');
              return { error: 'Vendor already exists' };
          }
          throw new Error(`Fetch error: ${JSON.stringify(error)}`);
      }

      const dataJSON = await response.json();
      return dataJSON;
  } catch (error) {
      console.error('Fetch error:', error);
      return { error: error.message };
  }
};