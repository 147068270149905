import { useState, useEffect, useContext } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { useAuthenticator } from '@aws-amplify/ui-react';
// import { fetchAuthSession } from 'aws-amplify/auth';

import { AppContext } from './AppContext.jsx';
import { AvatarNoPopover } from './NavBar/Avatar/AvatarNoPopover.jsx';
import { NotificationsBadge } from './NavBar/Notifications/NotificationsBadge.jsx';
import { ShortcutsBadge } from './NavBar/Shortcuts/ShortcutsBadge.jsx';
import { useScrollToHash } from './useScrollToHash.jsx';
import { fetchMenuItemsByTeamMemberId } from './graphqlCompnents/NavBar/fetchMenuItemsByTeamMemberId.jsx';
import { useScreenSize } from './custom-hooks/useScreenSize.jsx';
import usePreviousLocation from '../usePreviousLocation.jsx';
// import { verifyPhoneNumber } from './APIGateway/verifyPhoneNumber.jsx';

// icons
import { AvailableIcons } from './Icons/AvailableIcons.jsx';

// buttons
import { ButtonSignOutWithIcon } from './Form/Button/ButtonSignOutWithIcon.jsx';

// Footer component
// import MainFooter from './MainFooter.jsx';

import {
  Box,
  Image,
  IconButton,
  Tooltip,
  HStack,
  VStack,
  Flex,
  Spacer,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from "@chakra-ui/react";

import { MainNavMenuItem } from './MainNavMenuItem.jsx';
import { MainNavMenuItemPhone } from './MainNavMenuItemPhone.jsx';

import { 
  AiOutlinePoweroff as IconLogout
} from "react-icons/ai";

import ColorModeButton from "../color-mode-button.jsx";

export const MainMain = () => {

  const {store, actions } = useContext(AppContext);
  // console.info('store: ', store)

  const { signOut } = useAuthenticator((context) => [context.signOut]);


  // MainMain.jsx
  // const checkPhoneVerificationStatus = async () => {
  //   try {
  //       // Fetch the current session
  //       const session = await fetchAuthSession();
  //       const idTokenPayload = session.tokens.idToken.payload;
  //       const isPhoneVerified = idTokenPayload.phone_number_verified;
  //       console.log("Phone Verified:", isPhoneVerified ? "Yes" : "No");
        
  //       if (!isPhoneVerified) {
  //           const code = prompt("Please enter the verification code sent to your phone:");
  //           if (code) {
  //               const username = idTokenPayload['cognito:username'];
  //               const response = await verifyPhoneNumber({ username, code });
                
  //               if (!response.success) {
  //                   console.error('Verification failed:', response.error);
  //                   alert(`Failed to verify phone number: ${response.error}`);
  //               } else {
  //                   console.log('Verification successful:', response.data);
  //                   alert('Phone number verified successfully!');
  //                   // Refresh the verification status
  //                   await checkPhoneVerificationStatus();
  //               }
  //           }
  //       }
  //   } catch (error) {
  //       console.error("Error in checkPhoneVerificationStatus:", error);
  //       alert("Error checking verification status. Please try again.");
  //   }
  // };

  // useEffect(() => {
  //     checkPhoneVerificationStatus();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const navigate = useNavigate();

  function logOut() {
    actions.clearStore(null)
    signOut();
    localStorage.clear(); 
    // window.location.href = window.location.href;
    navigate('/login');
  }

  // if phone, show the small version
  const screenSize = useScreenSize();
  const currentScreenWidth = screenSize.width

  const [ menuItems, setMenuItems ] = useState(null)
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

  const usersGroups = store?.userData?.groups
  const userGroupCount = usersGroups?.length||0;

  const previousLocation = usePreviousLocation();
  // console.log('previousLocation: ', previousLocation)
  // Get the current location object
  const currentLocation = useLocation();
  // const fromPath = currentLocation.state?.from;

  // console.log('currentLocation: ', currentLocation)
  // console.log('fromPath: ', fromPath)

  // Extract the pathname (e.g., "/user-receipts")
  const path = currentLocation.pathname||'/';

  // Extract the query parameters (e.g., "?id=123456")
  // const queryParams = currentLocation.search;

  // Create a URLSearchParams object to extract individual query parameters
  // const searchParams = new URLSearchParams(queryParams);

  // Get specific query parameter values (e.g., "id" -> "123456")
  // const id = searchParams.get('id')||'';

  // window.location.href = https://localhost:4010/receipt-upload/?id=b4e40f97-92cb-4f97-a3b1-4890e980f34a

  // console.log('window.location.href: ', window.location.href)

  useEffect(() => {

    // if (userGroupCount>0 && path==='/user-receipts') {
    //   navigate('/user-receipts')
    // }

    if (userGroupCount>0 && path==='/') {
      
      // exec_admin
      // exec_admin_readonly
      // team_member
      // issued_credit_card
      // supervisor
      // maintenance
      // start from the top with admin, work down
      // const isAdmin = usersGroups.some(group => 
      //   group === 'exec_admin' || group === 'exec_admin_readonly'
      // )
      // const isSupervisor = (usersGroups.includes('supervisor')) ? true : false
      // const isMaintenance = (usersGroups.includes('maintenance')) ? true : false
      // const isIssuedCreditCard = (store?.userData?.groups?.filter(group => group==='issued_credit_card')) ? true : false
      // const isTeamMember = (usersGroups.includes('team_member')) ? true : false

      let defaultPage = '/dashboard'
      // if (isSupervisor) defaultPage = '/daily-job-ticket'
      // if (isMaintenance) defaultPage = '/dashboard'
      // if (isIssuedCreditCard) defaultPage = '/app/dashboard'
      // if (isAdmin) defaultPage = '/viewdjt'
      // if (isTeamMember && !isMaintenance && !isSupervisor && !isAdmin) defaultPage = '/tm-unit-inspections'

      // and finally
      // if the user is on a phone, force them to load the tm-unit-inspections page
      // if (currentScreenWidth<460 && isSupervisor) defaultPage = '/tm-unit-inspections'

      console.warn(' ===== default page: ', defaultPage)
      // navigate(defaultPage);
      // if (previousLocation?.pathname==='/user-receipts') {
      if (/\/user-receipts(\/|$)/.test(previousLocation?.pathname)) {
        navigate('user-receipts')
      } else {
        // navigate(defaultPage);
        navigate('dashboard');
      }
      // navigate('/user-receipts')
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[usersGroups, menuItems])

  useEffect(() => {
    // console.log(' +++++ useEffect store; ', store)
    const getMenu = async (id) => {
      // console.log(' +++++ getting menu: ', id)
      let menuItemsArr = []
      const menuData = await fetchMenuItemsByTeamMemberId(id)
      console.log('menuData: ', menuData)
      // if not localhost and menu item displayOrder >= 900 then remove it
      // if (isLocalhost) {
      //   menuData?.filter(item => item?.navMenu?.linkTo === "daily-job-ticket")
      // }

      // isPhoneSafe

      menuData?.sort(function( a , b ){
        return a.navMenu.displayOrder > b.navMenu.displayOrder ? 1 : -1;
      }).forEach(item => {
        menuItemsArr.push(item?.navMenu)
        // temporary for showing local menu items for testing
        // if (!isLocalhost) {
        //   // if (item?.navMenu?.displayOrder<900) {
        //     menuItemsArr.push(item?.navMenu)
        //   // }
        // } else {
        //   menuItemsArr.push(item?.navMenu)
        // }
      })

      // console.info(' +++++ GOT MENU ITEMS: ', menuItemsArr)
      if (currentScreenWidth<460) {
        menuItemsArr = menuItemsArr.filter(item => item?.isPhoneSafe === true)
      }
      setMenuItems(menuItemsArr)
      // console.warn(' +++++ RUNNING getMenu: ', menuData)
    }
    
    (store?.userData?.id) && getMenu(store?.userData?.id)
  },[store?.userData?.id, isLocalhost, currentScreenWidth])
  // },[store?.userData?.id])

  const currentUserFirstName = store?.userData?.name?.split(' ')[0]

  const [ userMessageCount, setUserMessageCount ] = useState(0)
  useEffect(() => {
    // console.log('do we have store yet?', store?.userData)
    // setUserGroups(store?.userData?.groups)
    setUserMessageCount(store?.userMessages?.length)
  },[store?.userMessages])
  // wrap nav and content in left/right css

  // console.log('store messages: ', store.userMessages.length)

  useScrollToHash()

  // need to shoehorn in a link for all users at the bottom of the nav menu
  // https://onesource.myisolved.com
  
  return (<>
  {(currentScreenWidth<460) ?
    <>
    <VStack align='stretch'>
      <Box bgColor={'var(--light-module-bg)'}>
        <Flex>
          <Menu>
            <MenuButton
              m={'10px'}
              as={IconButton}
              aria-label='Options'
              icon={<AvailableIcons key={'_i_x0'} boxSize={'18px'} iconRef={'hamburger'} color={'var(--dark-text-grey-3)'} />}
              variant='outline'
            />
            <MenuList>
              {menuItems?.map((item, index) => {
                // console.warn('FISHING FOR ERROR: ', menuItems)
                return(
                  (item?.linkTo && item?.menuItem && item?.menuClass) && <MainNavMenuItemPhone
                    key={index}
                    index={index}
                    navlinkTo={item?.linkTo}
                    itemText={item.menuItem}
                    itemIcon={item.menuClass}
                  />
                )
              })}
            </MenuList>
          </Menu>
          <Box display='flex' mt={'9px'}>
            {(currentUserFirstName) && <Text as="span" textStyle='text-8'>Howdy, {currentUserFirstName}!</Text>}
          </Box>

          <Spacer />

          <Box>
            <ButtonSignOutWithIcon
              key={'asign_out_button'}
              name='signout'
              leftIcon={'signout'}
              iconsize='18px'
              value={'Sign Out'}
              onClick={() => logOut()}
            />
          </Box>
        </Flex>
      </Box>
      <Box>
        <Outlet/>
      </Box>
    </VStack>
    </>

  :
  
    // <Box className="framework screen">
		
    //   {/* <!-- Left Content Begin --> */}
    //   <Box className="left-nav-wrapper" bg="leftNavWrapperBg">
    //     <Image className="nav-bar-logo" src="../img/nav-spike-logo-1@1x.png" alt="nav-bar-logo" />
    //     <Divider className="nav-bar-user-seperator" color="navBarUserSeparator" mt='14px' />
    //     <Box className="flex-row">
    //       <AvatarNoPopover name={store?.userData?.name} imgSrc={store?.userData?.signedUrl||null} />
    //       <NotificationsBadge msgCount={userMessageCount} />
    //       <ShortcutsBadge/>
    //     </Box>
      
    //     <Box className="flex-col">
    //       <Divider className="nav-bar-user-seperator" color="navBarUserSeparator" mb='14px'/>

    //       {menuItems?.map((item, index) => {
    //         return(
    //           (item?.linkTo && item?.menuItem && item?.menuClass) && <MainNavMenuItem
    //             key={index}
    //             index={index}
    //             navlinkTo={item?.linkTo}
    //             itemText={item.menuItem}
    //             itemIcon={item.menuClass}
    //           />
    //         )
    //       })}

    //       <Divider className="nav-bar-user-seperator" color="navBarUserSeparator" mt='14px' />

    //       <HStack spacing='14px' mt='10px'>
    //         <Box>
    //           <Tooltip 
    //             openDelay={500} 
    //             closeDelay={500} 
    //             arrowSize={15} 
    //             placement='top' 
    //             // hasArrow 
    //             label='Log out'
    //             wrapper="span">
    //             <IconButton size={'lg'} onClick={() => logOut()} aria-label='Logout' variant="iconbuttonlogout" icon={<IconLogout />} />
    //           </Tooltip>
    //         </Box>
    //         <Box>
    //           <ColorModeButton />
    //         </Box>
    //       </HStack>
    //       {/* <>{screenSize.width}</> */}
    //       <Divider className="nav-bar-user-seperator" color="navBarUserSeparator" mt='14px' />
    //       {/* <MainFooter /> */}
    //     </Box>
    //     <MainFooter />
    //   </Box>
    
    //   {/* <!-- Left Content End --> */}
    //   {/* <!-- Right Content Begin --> */}
    //   <Box className="right-side-content-wrapper">
    //     <Outlet/>
    //   </Box>
    // </Box>
    <Box className="framework screen">
		
      {/* <!-- Left Content Begin --> */}
      <Box className="left-nav-wrapper" bg="leftNavWrapperBg">
        <Image className="nav-bar-logo" src="../img/nav-spike-logo-1@1x.png" alt="nav-bar-logo" />
        <Divider className="nav-bar-user-seperator" color="navBarUserSeparator" mt='14px' />
        <Box className="flex-row">
          <AvatarNoPopover name={store?.userData?.name} imgSrc={store?.userData?.signedUrl||null} />
          <NotificationsBadge msgCount={userMessageCount} />
          <ShortcutsBadge/>
        </Box>
      
        <Box className="flex-col">
          <Divider className="nav-bar-user-seperator" color="navBarUserSeparator" mb='14px'/>

          {menuItems?.map((item, index) => {
            return(
              (item?.linkTo && item?.menuItem && item?.menuClass) && <MainNavMenuItem
                key={index}
                index={index}
                navlinkTo={item?.linkTo}
                itemText={item.menuItem}
                itemIcon={item.menuClass}
              />
            )
          })}

          <Divider className="nav-bar-user-seperator" color="navBarUserSeparator" mt='14px' />

          <HStack spacing='14px' mt='10px'>
            <Box>
              <Tooltip 
                openDelay={500} 
                closeDelay={500} 
                arrowSize={15} 
                placement='top' 
                // hasArrow 
                label='Log out'
                wrapper="span">
                <IconButton size={'lg'} onClick={() => logOut()} aria-label='Logout' variant="iconbuttonlogout" icon={<IconLogout />} />
              </Tooltip>
            </Box>
            <Box>
              <ColorModeButton />
            </Box>
          </HStack>
          {/* <>{screenSize.width}</> */}
          <Divider className="nav-bar-user-seperator" color="navBarUserSeparator" mt='14px' />

        </Box>
      </Box>
    
      {/* <!-- Left Content End --> */}
      {/* <!-- Right Content Begin --> */}
      <Box className="right-side-content-wrapper">
        <Outlet/>
      </Box>
    </Box>
    }
    </>
  )
}