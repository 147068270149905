import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Button } from '@chakra-ui/react';

export const ButtonNonaryPlain = (props) => {
  const {
    name,
    ref,
    value,
    onClick,
    isDisabled=false,
    mx,
    my,
    mt,
    width,
  } = props;

  return (
    <Button 
      name={name}
      ref={ref}
      variant='plainNonary' 
      onClick={onClick}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
      mt={mt}
      width={width}
    >
      {value}
    </Button>
  );
};

// Prop validation
ButtonNonaryPlain.propTypes = {
  name: PropTypes.string, // Name is expected to be a string
  ref: PropTypes.oneOfType([ // ref can be a function or an object created by React.createRef()
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  value: PropTypes.oneOfType([ // Value can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]).isRequired, // Value is required
  onClick: PropTypes.func, // onClick is a function
  isDisabled: PropTypes.bool, // isDisabled is a boolean
  mx: PropTypes.oneOfType([ // mx can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([ // my can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  mt: PropTypes.oneOfType([ // my can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  width: PropTypes.oneOfType([ // width can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
};

export const ButtonNonaryPlainWithRef = forwardRef(function ButtonNonaryPlainWithRef(props, ref) {
  const {
    name,
    value,
    onClick,
    isDisabled,
    mx,
    my,
    mt,
    width,
  } = props;

  return (
    <Button 
      {...props}
      name={name}
      ref={ref}
      variant='plainNonary' 
      onClick={onClick}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
      mt={mt}
      width={width}
    >
      {value}
    </Button>
  );
});

// Setting the displayName property to avoid the ESLint warning
ButtonNonaryPlainWithRef.displayName = 'ButtonNonaryPlainWithRef';

// Prop validation for ButtonNonaryPlainWithRef
ButtonNonaryPlainWithRef.propTypes = {
  name: PropTypes.string, 
  ref: PropTypes.oneOfType([ 
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  value: PropTypes.oneOfType([ 
    PropTypes.string,
    PropTypes.number,
  ]).isRequired, 
  onClick: PropTypes.func, 
  isDisabled: PropTypes.bool, 
  mx: PropTypes.oneOfType([ 
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([ 
    PropTypes.string,
    PropTypes.number,
  ]),
  mt: PropTypes.oneOfType([ 
    PropTypes.string,
    PropTypes.number,
  ]),
  width: PropTypes.oneOfType([ 
    PropTypes.string,
    PropTypes.number,
  ]),
};