import { updateDJT } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const updateParentDJTAsParent = async (djtId, isDuplicate) => {
  if (isDuplicate === false) return;

  try {
    const variables = {
      input: {
        id: djtId,
        isParent: true,
      }
    };

    const response = await client.graphql({
      query: updateDJT,
      variables
    });

    const results = response.data.updateSpkDailyJobTicketTbl.id;
    return results;
  } catch (err) {
    return ('error updateParentDJTAsParent: ', [err]);
  }
}

updateParentDJTAsParent.propTypes = {
  djtId: PropTypes.string.isRequired,
  isDuplicate: PropTypes.bool.isRequired,
};
