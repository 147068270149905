import { listInspectedUnitsByDivisionByDate } from '../../../graphql/custom_queries';
import { generateClient } from 'aws-amplify/api';
import { todaysDateYMD } from '../../functions/dateTime';

const client = generateClient();

export const fetchUnitsInspectedAtDivisionOnDate = async (props) => {
  if (!props.divisionId) return;

  console.log('divisionsId: ', props.divisionId);

  try {
    const todayYMD = todaysDateYMD();
    const inspectionDate = props.searchDate ? props.searchDate : todayYMD;
    const divisionDate = `${props.divisionId}#${inspectionDate}`;

    // console.warn('fetchUnitsInspectedAtDivisionOnDate divisionDate:', divisionDate);

    const gqlParams = { divisionDate: divisionDate };

    const response = await client.graphql({
      query: listInspectedUnitsByDivisionByDate,
      variables: gqlParams,
    });

    // console.warn('fetchUnitsInspectedAtDivisionOnDate response:', response)

    const unsortedResults = response.data.inspectedUnitsByDivisionByDate.items;

    const results = unsortedResults.sort((a, b) => 
      a.createdAt > b.createdAt ? -1 : 1
    );

    // console.info('----- SORTED RESULTS: ', results);
          
    return results;
  } catch (err) {
    return ('error fetchUnitsInspectedAtDivisionOnDate: ', [err]);
  }
}
