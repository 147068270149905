import {
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Center,
} from "@chakra-ui/react";

export const CenteredAllLaborModal = (props) => {

  const {
    isModalOpen,
    onModalClose,
  } = props

  return (
    <>
      <Modal onClose={onModalClose} isOpen={isModalOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader><Center>Add Labor Not At Your Division</Center></ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <Center>You are adding labor that has not clocked in at your division.</Center>
          </ModalBody>
          <ModalFooter>
            <Center><Button onClick={onModalClose}>Continue</Button></Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}