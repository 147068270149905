import { createPrimaryUnitAuxillaryUnits } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const addPrimaryUnitAuxiliaryUnits = async (props) => {
  if (!props) return;
  
  try {
    console.log('xxx addPrimaryUnitAuxiliaryUnits props: ', props);

    const gqlParams = {
      input: props
    };

    const response = await client.graphql({
      query: createPrimaryUnitAuxillaryUnits,
      variables: gqlParams
    });

    console.log('xxx addPrimaryUnitAuxiliaryUnits response: ', response);
    const results = response.data.createPrimaryUnitAuxillaryUnits.id;
    console.log('xxx addPrimaryUnitAuxiliaryUnits results: ', results);

    return results;
  } catch (err) {
    return ('error addPrimaryUnitAuxiliaryUnits: ', [err]);
  }
};

addPrimaryUnitAuxiliaryUnits.propTypes = {
  props: PropTypes.object.isRequired,
};
