import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Avatar,
  Badge,
  Container,
  Box,
  Text,
  Flex,
  Spacer,
  Stack,
  VStack,
  useDisclosure,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Grid,
} from "@chakra-ui/react";

// add in new data for iSolved team members. Make sure queries to SpkTeamMemberTbl are up to dat ith new fields isOnboarded and isERPUser.
// we now have isolvedEmail as the email of the user that can be used if they do not have a Spike email address.

// uuid
// import { v4 as uuidv4 } from 'uuid';

// lambda components for API Gateway
// import { LambdaListUserPoolGroups } from "../APIGateway/LambdaListUserPoolGroups.jsx";
// import { LambdaListUsers } from '../APIGateway/LambdaListUsers.jsx';
// import { suggestAddress } from '../APIGateway/suggestAddress.jsx';
// import { getLocationDetails } from '../APIGateway/getLocationDetails.jsx';
// import { fetchTimeCoExtendedEmployees } from '../APIGateway/fetchTimeCoExtendedEmployees.jsx';

// globals
import { AppContext } from '../AppContext.jsx';


// graphql components
// import { fetchAllDivisions } from '../graphqlCompnents/Billing/fetchAllDivisions.jsx';
// import { listAllMappedTimeCoTeamMembers } from '../graphqlCompnents/TeamMember/listAllMappedTimeCoTeamMembers.jsx'
// import { fetchJobClassRatesByDivisionId } from '../graphqlCompnents/JobClass/fetchJobClassRatesByDivisionId.jsx';
import { listAllTeamMembers } from '../graphqlCompnents/TeamMember/listAllTeamMembers.jsx';
// import { fetchAllNoTimeCoTempEmployees } from '../graphqlCompnents/TeamMember/fetchAllNoTimeCoTempEmployees.jsx';

// form components
// import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
// import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSwitch } from '../Form/Switch/FormSwitch.jsx';

// buttons
// import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';
// import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
// import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';

// icons
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// structural components
import { ModuleBox } from '../Structural/ModuleBox.jsx';
import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';
// import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';

// decorative components
import { Divider } from '../Divider';


// spike forms
import { CreateTeamMember } from './CreateTeamMember.jsx';
import { ViewTeamMember } from './ViewTeamMember.jsx';
import { EditTeamMember } from './EditTeamMember.jsx';

export const TeamMembers = () => {

  const [ selectedTeamMemberId, setSelectedTeamMemberId ] = useState('')
  // const [ selectedTeamMemberType, setSelectedTeamMemberType ] = useState(null)
  // const { isOpen: isCreateTeamMemberOpen , onOpen: onCreateTeamMemberOpen, onClose: onCreateTeamMemberClose } = useDisclosure()
  const { isOpen: isCreateTeamMemberOpen , onOpen: onCreateTeamMemberOpen, onClose: onCreateClose } = useDisclosure()
  // const { isOpen: isEditTeamMemberOpen , onOpen: onEditTeamMemberOpen, onClose: onEditTeamMemberClose } = useDisclosure()
  const { isOpen: isEditTeamMemberOpen , onOpen: onEditTeamMemberOpen, onClose: onEditClose } = useDisclosure()
  const { isOpen: isViewTeamMemberOpen , onOpen: onViewTeamMemberOpen, onClose: onViewTeamMemberClose } = useDisclosure()

  const [ showInactive, setShowInactive ] = useState(false)
  useEffect(() => {
    if (showInactive) {
      setGroupedMembers(allGroupedMembers)
    } else {
      const activeMembers = {}
      for (const division in allGroupedMembers) {
        activeMembers[division] = allGroupedMembers[division].filter(member => member.isActive)
      }
      setGroupedMembers(activeMembers)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ showInactive ])
  
  const CustomCloseDrawerButton = (type) => {
    let closeDrawer = null
    if (type==='new') {
      closeDrawer = onCreateTeamMemberClose
    }
    if (type==='view') {
      closeDrawer = onViewTeamMemberClose
    }
    if (type==='edit') {
      closeDrawer = onEditTeamMemberClose
    }

    return(
      <IconButton 
        variant={'icononlybutton'}
        icon={<AvailableIcons boxSize={'22px'} iconRef={'close'} />} 
        onClick={closeDrawer}
      />
    )
  }

  const { control, register, handleSubmit, setValue } = useForm({
      mode: 'onChange',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const onEditTeamMemberClose = () => {
    handleRefresh()
    setSelectedTeamMemberId('')
    // setSelectedTeamMemberType(null)
    onEditClose()
  }

  const onCreateTeamMemberClose = () => {
    handleRefresh()
    setSelectedTeamMemberId('')
    // setSelectedTeamMemberType(null)
    onCreateClose()
  }
  
  const onError = (errors, e) => console.log(errors, e);

  // grab global context values
  const { store } = useContext(AppContext);

  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id
  // console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)

  // const [ divisions, setDivisions ] = useState([])

  const formSubmit = async (values, event) => {
    // reference AddTeamMember.js for Cognito Add User functionality

    event.preventDefault();
    console.warn(' ----- formSubmit values: ', values)
  }

  const handleOpenDrawer = (type, id) => {

    if (type==='new') {
      onCreateTeamMemberOpen()
    }

    if (type==='view') {
      setSelectedTeamMemberId(id, onViewTeamMemberOpen())
    }

    if (type==='edit') {
      // setDjtIsParent(isParent)
      setSelectedTeamMemberId(id, onEditTeamMemberOpen())
    }
    
  }

  // function useDebounce(value, delay) {
  //   const [debouncedValue, setDebouncedValue] = useState(value);
    
  //   useEffect(() => {
  //       const handler = setTimeout(() => setDebouncedValue(value), delay);
  //       return () => clearTimeout(handler);
  //   }, [value, delay]);

  //   return debouncedValue;
  // }

  // useEffect(() => {
  //   const loadDivisions = async () => {
  //     try {
  //       const divisions = await fetchAllDivisions();
  //       console.warn('----- divisions: ', divisions);
  //       setDivisions(divisions);
  //     } catch (error) {
  //       console.error('Failed to fetch divisions:', error);
  //     }
  //   };
  
  //   loadDivisions();
  // }, []); 

  const [ refreshIndex, setRefreshIndex ] = useState(0);
  const handleRefresh = () => {
    setRefreshIndex(prev => prev + 1); // Increment to trigger useEffect
  };

  // load up all team members with id, name, division, jobclass, ???
  const [allGroupedMembers, setAllGroupedMembers] = useState({});  // State to store members grouped by division
  const [groupedMembers, setGroupedMembers] = useState({});  // State to store members grouped by division
  useEffect(() => {
    const fetchAllTeamMembers = async () => {
      try {
        // const tempHires = await fetchAllNoTimeCoTempEmployees();
        // console.log(' ----- tempHires: ', tempHires);

        // const tempHiresWithTypes = tempHires.map(tempHire => ({
        //   ...tempHire,
        //   teamMemberType: 'temp'
        // }));

        const response = await listAllTeamMembers('', 500);
        const employees = response.items;
        console.log(' ----- allTeamMembers: ', employees);

        // const employeesWithTypes = employees.map(employee => ({
        //   ...employee,
        //   teamMemberType: 'employee'
        // }));

        // const allTeamMembers = [...employeesWithTypes, ...tempHiresWithTypes];
        const allTeamMembers = employees.map(employee => ({
          ...employee,
          teamMemberType: 'employee'
        }));

        // Group team members by 'prettyName' of their division
        const membersByDivision = allTeamMembers.reduce((acc, member) => {
          const divisionName = member.division.prettyname;
          if (!acc[divisionName]) {
            acc[divisionName] = [];
          }
          acc[divisionName].push(member);
          return acc;
        }, {});

        // Sort each group by isActive, lastName, then firstName
        for (const division in membersByDivision) {
          membersByDivision[division].sort((a, b) => {
            // Sort by isActive first (false < true)
            if (a.isActive !== b.isActive) {
              return a.isActive ? -1 : 1;
            }
            // Then by lastName
            if (a.lastName !== b.lastName) {
              return a.lastName.localeCompare(b.lastName);
            }
            // Finally by firstName
            return a.firstName.localeCompare(b.firstName);
          });
        }

        // setGroupedMembers(membersByDivision);  // Store the grouped members in state
        setAllGroupedMembers(membersByDivision);  // Store active and inactive grouped members in state

        const activeMembers = {}
        for (const division in membersByDivision) {
          activeMembers[division] = membersByDivision[division].filter(member => member.isActive)
        }
        setGroupedMembers(activeMembers)

        console.log('Fetched new team members.')
      } catch (error) {
        console.error('Failed to fetch team members:', error);
      }
    };

    fetchAllTeamMembers();
  },[ refreshIndex ])

  const DivisionMemberCard = ({ member, divisionName }) => {
    return (
      <Box 
        borderWidth="1px" 
        borderRadius="6px" 
        overflow="hidden" 
        p={5} 
        m={2} 
        // cursor="pointer"
        // _hover={{ bg: "gray.600" }} // Optional: change background on hover
        // onClick={() => onCardClick(member)}
        backgroundColor={'var(--dark-menu-background)'}>
        <Box 
          display="flex" 
          // border='1px solid red'
          alignItems="center">
          <VStack 
            // border='1px solid green'
            >
            <Box>
              <Avatar mx='5px' bg='black' color='var(--dark-gold-primary)' size='md' name={`${member.firstName} ${member.lastName}`} src={member?.imgUrl} />
            </Box>
            <Spacer/>
            <Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<AvailableIcons boxSize={'22px'} iconRef={'more'} />} 
                  variant='teamMemberEllipses' 
                  color={'var(--dark-gold-primary)'}
                  backgroundColor={'var(--dark-menu-background)'}
                  w={'60px'}
                  iconsize='18px'
                  height='28px'
                  mb='3px'
                  isDisabled={(divisionName==='ExxonMobil' || divisionName==='Citgo') ? true : false}
                  // isDisabled={!tokenObj || !isFormLoaded || isMenuDisabled || disabledToolbars[djt?.id] || !isLoggedInQB}
                />
                <MenuList>
                  {/* <MenuItem 
                    onClick={() => handleOpenDrawer('view', `${member.teamMemberType}#${member.id}`)}
                    icon={<AvailableIcons boxSize={'22px'} iconRef={'view'} />} >
                    View Team Member
                  </MenuItem> */}
                  <MenuItem 
                    onClick={() => handleOpenDrawer('edit', `${member.teamMemberType}#${member.id}`)}
                    icon={<AvailableIcons boxSize={'22px'} iconRef={'edit'} />} >
                    View/Edit Team Member
                  </MenuItem>
                  <MenuItem 
                    onClick={() => console.log('REPORT INCIDENT')}
                    icon={<AvailableIcons boxSize={'22px'} iconRef={'edit'} />} >
                    Report Incident
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </VStack>
          <Stack direction={['row', 'column']} spacing='5px' ml="3">
            <Box><Text as="span" textStyle='heading-3'>
            {member?.firstName} {(member.goesBy) && `"${member.goesBy}"`} {(member?.middleName) && member?.middleName} {member?.lastName}
            </Text></Box>
            <Box><Text as="span" textStyle='text-2'>{(member.teamMemberType==='temp') ? 'Temp-to-Hire' : 'Full-time'}</Text></Box>
            <Box><Text as="span" textStyle='text-2'>{member?.billableDivisionJobClass?.appDisplayName}</Text></Box>
            <Box><Badge colorScheme={member.isActive ? "green" : "red"}>
              {member.isActive ? 'Active User' : 'Inactive User'}
            </Badge></Box>
            <Box><Badge colorScheme={member.isERPUser ? "green" : "red"}>
              {member.isERPUser ? 'ERP User' : 'Non ERP User'}
            </Badge></Box>
            <Box><Badge colorScheme={member.isOnboarded ? "green" : "red"}>
              {!member.isOnboarded && 'Incomplete Onboarding'}
            </Badge></Box>
          </Stack>
        </Box>
      </Box>
    );
  };

  DivisionMemberCard.propTypes = {
    member: PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      goesBy: PropTypes.string,
      middleName: PropTypes.string,
      imgUrl: PropTypes.string,
      teamMemberType: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      isERPUser: PropTypes.bool,
      isOnboarded: PropTypes.bool,
      billableDivisionJobClass: PropTypes.shape({
        appDisplayName: PropTypes.string.isRequired
      })
    }).isRequired,
    onCardClick: PropTypes.func,
    divisionName: PropTypes.string.isRequired,
  };

  const DivisionLayout = ({ divisionData }) => {
    return (
      <Box>
        {Object.entries(divisionData).map(([divisionName, members]) => (
          // (divisionName!=='ExxonMobil' && divisionName!=='Citgo') && 
          <Box key={divisionName}>
            <Box key={divisionName} mb={8}>
              <Box display="flex" alignItems="center" mb={4}>
                <Text as="span" textStyle='heading-1' pr={'15px'}>{divisionName}</Text>
                <AvailableIcons boxSize={'22px'} iconRef={'iconTeamMembers'} color={'var(--dark-gold-primary)'}/>
                <Text as="span" textStyle='heading-2' pl={'5px'}>{members.length} Members</Text>
              </Box>
              <Grid templateColumns="repeat(auto-fill, minmax(270px, 1fr))" gap={6}>
                {members.map(member => (
                  <DivisionMemberCard key={member.id} member={member} divisionName={divisionName} />
                ))}
              </Grid>
            </Box>
            <Divider mt='25px' mb='25px'/>
          </Box>
        ))}
      </Box>
    );
  };

  DivisionLayout.propTypes = {
    divisionData: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
          goesBy: PropTypes.string,
          middleName: PropTypes.string,
          imgUrl: PropTypes.string,
          teamMemberType: PropTypes.string.isRequired,
          isActive: PropTypes.bool.isRequired,
          billableDivisionJobClass: PropTypes.shape({
            appDisplayName: PropTypes.string.isRequired
          })
        })
      )
    ).isRequired,
  };

  return (
    <>
    <Container as="form" maxW="1600" mb={12} onSubmit={handleSubmit(formSubmit, onError)}>
      <ModuleBox>
        <Flex>
          <Box>
            <Text as="span" textStyle='heading-1'>View Team Members</Text>
          </Box>
          {/* <Spacer />
          <Box>
            <ButtonQuaternaryWithIcon
              name='new'
              leftIcon='new'
              iconsize='22px'
              // isDisabled={buttonsDisabled}
              onClick={() => handleOpenDrawer('new', null)}
              value='Create New Team Member'
              mr={'25px'}
            />
          </Box> */}
        </Flex>
        <Box w={'300px'} >
          <Flex>
            <Box>
              <Text as="span" textStyle='label-1'>Show Inactive Team Members</Text><br/>
            </Box>
            <Spacer />
            <Box>
              <FormSwitch
                register={register}
                control={control}
                fieldname="showInactive"
                isChecked={showInactive}
                onChange={() => {
                  setValue('showInactive', !showInactive)
                  setShowInactive(!showInactive)
                  // setSelectedTCUser('')
                }}
              />
            </Box>
          </Flex>
        </Box>
      </ModuleBox>
      
      {/* <Box w={{ 
        base: '100px', //< 400 screen width
        sm: '360px', // total width of phone screen
        md: '810px', // ipad 7th gen target - 810 when with enu nav
        lg: '1200px', 
        xl: '1400px',
        '2xl': '1600px'
      }} ml={'25px'}>
      <HStack mb='15px' mt={'25px'}>
        <Box></Box>
        <Spacer/>
          <ButtonQuaternaryWithIcon
            name='new'
            leftIcon='new'
            iconsize='22px'
            // isDisabled={buttonsDisabled}
            onClick={() => handleOpenDrawer('new', null)}
            value='Create New Team Member'
            mr={'25px'}
          />
      </HStack>
      </Box> */}

      <ModuleBox mt='25px'>
        <Box>
          <DivisionLayout divisionData={groupedMembers} />
        </Box>
      </ModuleBox>



    </Container>

    <ModuleDrawer
      onClose={onCreateTeamMemberClose}
      isOpen={isCreateTeamMemberOpen}
      bodyContent={<CreateTeamMember srcAction={'new'} adminId={currentTeamMemberId} onClose={onCreateTeamMemberClose} drawerCloseButton={CustomCloseDrawerButton('new')}/>}
      size={'full'}
    />
    <ModuleDrawer
      onClose={onViewTeamMemberClose}
      isOpen={isViewTeamMemberOpen}
      bodyContent={<ViewTeamMember srcId={selectedTeamMemberId} onClose={onViewTeamMemberClose} drawerCloseButton={CustomCloseDrawerButton('view')}/>}
      size={'full'}
    />
    <ModuleDrawer
      onClose={onEditTeamMemberClose}
      isOpen={isEditTeamMemberOpen}
      bodyContent={<EditTeamMember srcId={selectedTeamMemberId} onClose={onEditTeamMemberClose} drawerCloseButton={CustomCloseDrawerButton('edit')}/>}
      size={'full'}
    />
    </>

  );
}

TeamMembers.propTypes = {
  selectedTeamMemberId: PropTypes.string,
  selectedTeamMemberType: PropTypes.string,
  isCreateTeamMemberOpen: PropTypes.bool,
  isEditTeamMemberOpen: PropTypes.bool,
  isViewTeamMemberOpen: PropTypes.bool,
  showInactive: PropTypes.bool,
  allGroupedMembers: PropTypes.object,
  groupedMembers: PropTypes.object,
  currentTeamMemberId: PropTypes.string,
  divisions: PropTypes.array,
  refreshIndex: PropTypes.number,
};
