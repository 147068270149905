import { primaryUnitByCode } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const fetchPrimaryUnitByUnitCode = async (value) => {
  if (!value) return;

  try {
    console.log(' !!!!! code: ', value);

    const gqlParams = { code: value };

    const response = await client.graphql({
      query: primaryUnitByCode,
      variables: gqlParams,
    });

    console.log(' !!!!! RESPONSE: ', response);

    const results = response.data.primaryUnitByCode.items[0];
    console.log(' !!!!! RESULTS: ', results);

    return results;
  } catch (err) {
    return 'error fetchPrimaryUnitByUnitCode: ' + err;
  }
};

fetchPrimaryUnitByUnitCode.propTypes = {
  value: PropTypes.string.isRequired,
};
