import { updateTeamMemberAdmin } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const updateTeamMemberAdministrative = async (props) => {
  if (!props) return;

  try {
    console.log('PROPS: ', props);
    const response = await client.graphql({
      query: updateTeamMemberAdmin,
      variables: { input: props }
    });

    console.log('RESPONSE: ', response);
    const results = response.data.updateSpkTeamMemberTbl;
    console.log('RESULTS: ', results);
    return results;
  } catch (err) {
    return ('error updateTeamMemberAdmin: ', [err]);
  }
}

updateTeamMemberAdministrative.propTypes = {
  props: PropTypes.shape({
    id: PropTypes.string,
    loginEmail: PropTypes.string,
    isERPUser: PropTypes.bool,
    isOnboarded: PropTypes.bool,
    billableDivisionJobClassId: PropTypes.string,
    memberOf: PropTypes.string,
    isGlobalAdmin: PropTypes.bool,
  }).isRequired,
};

