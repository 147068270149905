import {
  useColorMode,
  // useColorModeValue,
  IconButton,
  Tooltip
} from "@chakra-ui/react";
import { CiLight, CiDark } from "react-icons/ci";

const ColorModeButton = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  // const isDark = colorMode === "dark";
  // const bg = useColorModeValue("white", "gray.800");
  return (
    <Tooltip 
      openDelay={500} 
      closeDelay={500} 
      arrowSize={15} 
      placement='top' 
      // hasArrow 
      label={colorMode === 'light' ? 'Switch to dark mode' : 'Switch to light mode'}>
    <IconButton 
      // onClick={toggleColorMode}
      size='lg'
      aria-label='Switch Modes' 
      variant="iconbuttoncolormode" 
      // icon={colorMode === 'light' ? <CiDark /> : <CiLight />
      icon={<CiDark />}
    />
    </Tooltip>
  );
};

export default ColorModeButton;