import { updateSpkPrimaryUnitStatus } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const updatePrimaryUnitStatus = async (props) => {
  if (!props) return;

  try {
    console.log('updatePrimaryUnitStatus props: ', props);

    const gqlParams = {
      input: props
    };

    const response = await client.graphql({
      query: updateSpkPrimaryUnitStatus,
      variables: gqlParams
    });

    console.log('updatePrimaryUnitStatus response: ', response);
    const results = response.data.updateSpkPrimaryUnitStatusTbl.code;
    console.log('updatePrimaryUnitStatus results: ', results);

    return results;
  } catch (err) {
    return ('error updatePrimaryUnitStatus: ', [err]);
  }
};

updatePrimaryUnitStatus.propTypes = {
  props: PropTypes.object.isRequired,
};
