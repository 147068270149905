import {
  Box,
  HStack
} from "@chakra-ui/react";

export function ModuleBoxHStack(props) {
  const {
    children
  } = props;

  return (
    <Box
      w={{ 
        base: '100px', //< 400 screen width
        // sm: '140px', // without being in a drawer
        sm: '360px', // total width of phone screen
        md: '810px', // ipad 7th gen target - 810 when with enu nav
        lg: '1366px', // macbook air and ipad
        xl: '1400px',
        '2xl': '1600px'
      }}

      className='module-container'>
      <HStack spacing={'0px'} alignItems={'start'}>
        {children}
      </HStack>
    </Box>

  )
}
