// import AWS from 'aws-sdk';
import { addQuickBooksVendor } from '../graphqlCompnents/Statement/addQuickBooksVendor';
import { fetchAllQuickBooksVendorNamesIds } from '../graphqlCompnents/Statement/fetchAllQuickBooksVendorNamesIds';
import { getVendors } from './getVendors';

// const dynamodb = new AWS.DynamoDB();

export const syncQBVendors = async (props) => {
  const { token, environment } = props;
  if (!token) {
    console.warn('NO TOKEN in syncQBVendors');
    return null;
  }

  // Function to fetch vendors with pagination from QuickBooks
  const getVendorsFromQB = async (startPosition = 1) => {
    const maxResults = 1000;
    const response = await getVendors(token, environment, startPosition, maxResults);
    const dataJSON = response;
    console.log('dataJSON: ', dataJSON);

    if (dataJSON.fault?.error?.length > 0) {
      console.warn('GOT AN ERROR in syncQBVendors: ', dataJSON.fault?.error);
      return null;
    }

    const vendors = dataJSON?.Vendor || [];
    console.log('Vendors: ', vendors);

    return vendors;
  };

  // Function to fetch all vendors from QuickBooks using pagination
  const getAllVendors = async () => {
    let allVendors = [];
    let startPosition = 1;
    let moreRecords = true;

    while (moreRecords) {
      // console.log('Fetching vendors from QuickBooks...');
      const vendors = await getVendorsFromQB(startPosition);
      if (vendors.length < 1000) {
        moreRecords = false;
      }
      allVendors = allVendors.concat(vendors);
      startPosition += 1000;
    }

    return allVendors;
  };

  const getCurrentDynamoVendors = async () => {
    let currentVendors = [];
    const vendors = await fetchAllQuickBooksVendorNamesIds({startsWith: '', limit: 5000, nextToken: ''});
    // console.log('fetchAllQuickBooksVendorNamesIds: ', vendors);
    // Map over the results to extract only the vendor IDs
    const vendorIds = vendors.items.map(vendor => vendor.qbId);
    // console.log('Vendor IDs: ', vendorIds);
    currentVendors = currentVendors.concat(vendorIds);
    // console.log('Current Vendors: ', currentVendors);
    return currentVendors;
  }

  // Step 1: Fetch all vendors from QuickBooks
  const qbVendors = await getAllVendors();

  // Step 2: Fetch current vendors from DynamoDB using the index
  const dynamoVendors = await getCurrentDynamoVendors();

  // Step 3: Find new vendors to add to DynamoDB using the addQuickBooksVendor function
  const newVendors = qbVendors.filter(vendor => !dynamoVendors.includes(vendor.Id));

  // Step 4: Add new vendors using the addQuickBooksVendor function
  for (const vendor of newVendors) {
    try {
      const vendorProps = {
        id: vendor.Id,
        name: vendor.DisplayName,
      };
      await addQuickBooksVendor(vendorProps); // Use the existing function to add vendor
      console.log(`Successfully added vendor ${vendor.DisplayName} to DynamoDB using GraphQL.`);
    } catch (err) {
      console.error(`Failed to add vendor ${vendor.DisplayName}: `, err);
    }
  }

  console.log('Sync completed successfully!');
  return newVendors;
};