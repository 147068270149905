import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { createNewDJTClient } from '../../../graphql/custom_mutations';

const client = generateClient();

export const addClient = async (props) => {
  if (!props) return;

  try {
    // console.log('addClient props: ', props);

    const response = await client.graphql({
      query: createNewDJTClient,
      variables: { input: props },
    });

    // console.log('addClient response: ', response);
    const results = response.data.createSpkDJTClientTbl.id;
    // console.log('addClient results: ', results);
    return results;
  } catch (err) {
    return 'error addClient: ' + err;
  }
};

addClient.propTypes = {
  props: PropTypes.object.isRequired,
};
