import { createStatementDocumentUnit } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const addStatementDocumentUnit = async (props) => {
  if (!props) return;

  try {
    console.log('addStatementDocumentUnit props: ', props);

    const response = await client.graphql({
      query: createStatementDocumentUnit,
      variables: { input: props },
    });

    console.log('addStatementDocumentUnit response: ', response);

    const results = response.data.createSpkStatementDocumentUnitTbl.id;
    console.log('addStatementDocumentUnit results: ', results);

    return results;
  } catch (err) {
    return ('error addStatementDocumentUnit: ', [err]);
  }
};

addStatementDocumentUnit.propTypes = {
  props: PropTypes.shape({
    // Define the structure of "props" based on what is expected by the mutation.
  }).isRequired,
};