import PropTypes from 'prop-types';
import { Box, VStack, Text } from '@chakra-ui/react';

export const UnitsScrollableBox = (props) => {

  const { 
    items, 
    width, 
    height, 
    handleSelectedObject, 
    handleLoadMore=()=>{}, 
    maxHeight,
   } = props

  //  console.log('width: ', width);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      // console.log('reached the bottom of the scrollbox');
      handleLoadMore()
    }
  };

  return (
    <Box 
      minWidth={'100%'}
      maxWidth={'450px'}
      width={width}
      >
      <Box
        // maxHeight={maxHeight||'650px'}
        maxHeight={maxHeight}
        // height={height||'285px'}
        height={height}
        // height='auto'
        // width={width||'100%'}
        width={'100%'}
        overflowY="scroll"
        border="2px solid"
        borderColor="#42424A"
        borderRadius="6px"
        p={2}
        onScroll={handleScroll}
        // bg="var(--dark-button-grey-1)"
        sx={{
          '::-webkit-scrollbar': {
            width: '12px',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'var(--dark-button-grey-4)',
            borderRadius: '10px',
          },
          '::-webkit-scrollbar-track': {
            background: 'var(--dark-button-grey-1)',
          },
        }}
      >
        <VStack spacing={2}>
          {items?.map((item, index) => (
            <Box
              key={'box_'+index+'_'+item?.id}
              w="100%"
              p={2}
              // bg={selectedObject?.id === item.id ? 'var(--dark-button-grey-4)' : 'var(--dark-button-grey-1)'}
              bg={'var(--dark-button-grey-1)'}
              borderRadius="6px"
              cursor="pointer"
              onClick={() => handleSelectedObject(item)}
              _hover={{ bg: 'var(--dark-button-grey-4)' }}
            >
              {/* <Text textStyle='text-1'>{item.id} : {item.name} - {item.value}</Text> */}
              <Text textStyle='text-1'>{item?.code} {item?.type?.name}/{item?.subType?.name}</Text>
            </Box>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

UnitsScrollableBox.propTypes = {
  items: PropTypes.array.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  handleSelectedObject: PropTypes.func,
  handleLoadMore: PropTypes.func,
  maxHeight: PropTypes.string,
};