import { updatePrimaryUnitInspectionQueue } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const updateQueuedPrimaryUnitInspection = async (props) => {
  if (!props) return;

  try {
    console.log('PROPS: ', props);

    const gqlParams = { input: props };

    const response = await client.graphql({
      query: updatePrimaryUnitInspectionQueue,
      variables: gqlParams,
    });

    console.log('RESPONSE: ', response);

    const results = response.data.updateSpkPrimaryUnitInspectionQueueTbl;
    return results;
  } catch (err) {
    return 'error updateQueuedPrimaryUnitInspection: ' + err;
  }
};

updateQueuedPrimaryUnitInspection.propTypes = {
  props: PropTypes.shape({
    code: PropTypes.string.isRequired,
    statusId: PropTypes.string.isRequired,
  }).isRequired,
};
