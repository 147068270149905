import { useEffect, useState, useContext, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Text,
  Flex,
  // Spacer,
  Spinner,
  Center,
  Table,
  Tbody,
  Tr,
  Td,
  // HStack,
  useDisclosure,
  // useBreakpointValue,
  TableContainer,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  Badge,
} from "@chakra-ui/react";

// screensize
import { useScreenSize } from '../custom-hooks/useScreenSize.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// generic functions
import { spellOutDate, awsFormatUnknownDate,
  // todaysDateYMD, getPreviousDate 
} from '../functions/dateTime.jsx';
import { truncateString } from '../functions/strings.jsx';

// Buttons
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
// import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';

// structural components
import { ModuleBox } from '../Structural/ModuleBox.jsx';
import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';

// imported components
import { QuickCameraUploadStatement } from './QuickCameraUploadStatement.jsx';
// import { StatementDetail } from './StatementDetail.jsx';

// graphql
import { fetchAccountsByTeamMember } from '../graphqlCompnents/Statement/fetchAccountsByTeamMember.jsx';
import { fetchInstantNotificationsByLastFour } from '../graphqlCompnents/Statement/fetchInstantNotificationsByLastFour.jsx';

export const UserReceipts = () => {
  // if phone, show the small version
  const screenSize = useScreenSize();
  const currentScreenWidth = screenSize.width
  // console.log('currentScreenWidth: ', currentScreenWidth)


  const { 
    isOpen: isCameraUploadStatementOpen , 
    onOpen: onCameraUploadStatementOpen, 
    onClose: uploadReceiptClose 
  } = useDisclosure()

  // const { 
  //   isOpen: isStatementDetailOpen , 
  //   onOpen: onStatementDetailOpen, 
  //   onClose: statementDetailClose 
  // } = useDisclosure()

  const onCameraUploadStatementClose = () => {
    setSelectedTransactionId(null)
    setSelectedActionType(null)
    handleRefresh()
    uploadReceiptClose()
  }
  // const onStatementDetailClose = () => {
  //   handleRefresh()
  //   // statementDetailClose()
  // }
  const [ refreshIndex, setRefreshIndex ] = useState(0);
  const handleRefresh = (index) => {
    setNextToken('')
    setNextNextToken('')
    
    setRefreshIndex(prev => prev + 1); // Increment to trigger useEffect
    setValue('searchInput', '')
    console.log(`Tab index ${index} clicked, refreshing...`);
  };

  const [ selectedTransactionId, setSelectedTransactionId ] = useState(null)
  const [ selectedActionType, setSelectedActionType ] = useState(null)
  const handleOpenDrawer = (type, id) => {
    setSelectedTransactionId(id)
    setSelectedActionType(type)
  }

  useEffect(() => {
    if (selectedTransactionId && selectedActionType) {
      if (selectedActionType==='upload') {
        onCameraUploadStatementOpen()
      }
  
      if (selectedActionType==='detail') {
        // setStatementId(id, onStatementDetailOpen())
        // setSelectedStatement(statementObject, onStatementDetailOpen())
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedTransactionId, selectedActionType])

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    // reValidateMode: 'onBlur',
    defaultValues: {}
  });
  
  const onError = (errors, e) => console.log(errors, e);

  // grab global context values
  const { store } = useContext(AppContext);

  // const currentTeamMemberName = store?.userData?.name
  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id
  // console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)
  const currentUserIsGlobalAdmin = store?.userData?.isGlobalAdmin||false

  // QuickBooks environment
  // const qbEnv = store?.qbEnvironment;
  const plaidEnv = 'production';

  const userIsMobile = store?.userIsMobile;

  // const [ userData, SetUserData ] = useState({})
  // useEffect(() => {
  //   if (currentTeamMemberName && currentTeamMemberId && currentUserIsGlobalAdmin) {
  //     SetUserData({
  //       name: currentTeamMemberName,
  //       id: currentTeamMemberId,
  //       isGlobalAdmin: currentUserIsGlobalAdmin
  //     })
  //   }
  // },[currentTeamMemberName, currentTeamMemberId, currentUserIsGlobalAdmin]);

  // const [ showResultsSpinner, setShowResultsSpinner ] = useState(false)  
  const showResultsSpinner = false;
  // const [ currentTab, setCurrentTab ] = useState(0)
  const [ currentTabName, setCurrentTabName ] = useState('Inbox')
  const [ previousTabName, setPreviousTabName ] = useState('Inbox')
  const handleTabChange = async (tab) => {
    
    // setCurrentTab(tab)
    let currentTabNames = ['Inbox', 'Archived', 'Pending Approvals']
    setCurrentTabName(currentTabNames[tab])
    // console.log('currentTabName: ', currentTabNames[tab])

    if (currentTabNames[tab] !== previousTabName) {
      // console.log('Tab changed, resetting...')
      setNextToken('')
      setNextNextToken('')
      setPreviousTabName(currentTabNames[tab])
    }
  }

  // const searchHeaderTextClass = useBreakpointValue({
  //   sm: 'dark-sfpro-heading-2',
  //   md: 'dark-sfpro-heading-2',
  //   lg: 'dark-sfpro-heading-1',
  //   xl: 'dark-sfpro-heading-1',
  //   '2xl': 'dark-sfpro-heading-1'
  // })

  const [ userAccounts, setUserAccounts ] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const accounts = await fetchAccountsByTeamMember(plaidEnv, currentTeamMemberId);
      console.warn('cards of team member: ', accounts);
      setUserAccounts(accounts);
    }
    if (currentTeamMemberId) {
      fetchData()
    }
  },[currentTeamMemberId, plaidEnv])

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [userTransactions, setUserTransactions] = useState([]);
  
  useEffect(() => {
    const fetchTransactions = async () => {
      if (!userAccounts || userAccounts.length === 0) return;
  
      try {
        const transactionPromises = userAccounts.map(async (account) => {
          const cardNumber = account.cardLastFour;
  
          const [trueTransactions, falseTransactions] = await Promise.allSettled([
            fetchInstantNotificationsByLastFour(cardNumber, true, 500),
            fetchInstantNotificationsByLastFour(cardNumber, false, 500),
          ]);
  
          const transactions = [];
          if (trueTransactions.status === 'fulfilled' && trueTransactions.value) {
            transactions.push(...trueTransactions.value);
          }
          if (falseTransactions.status === 'fulfilled' && falseTransactions.value) {
            transactions.push(...falseTransactions.value);
          }
  
          return transactions;
        });
  
        const allTransactionResults = await Promise.allSettled(transactionPromises);
  
        const successfulTransactions = allTransactionResults
          .filter(result => result.status === 'fulfilled' && result.value.length > 0)
          .map(result => result.value);
  
        const flattenedTransactions = successfulTransactions.flat();
  
        // Separate transactions into two groups: those with isSubmitted === null and others
        const [nullSubmitted, otherSubmitted] = flattenedTransactions.reduce(
          (acc, transaction) => {
            transaction.isSubmitted === null ? acc[0].push(transaction) : acc[1].push(transaction);
            return acc;
          },
          [[], []]
        );
  
        // Sort both groups by purchaseDate in ascending order
        nullSubmitted.sort((a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate));
        otherSubmitted.sort((a, b) => new Date(a.purchaseDate) - new Date(b.purchaseDate));
  
        // Concatenate the two groups, with nullSubmitted transactions first
        const sortedTransactions = [...nullSubmitted, ...otherSubmitted];
  
        // Update the state with the sorted transactions
        setUserTransactions(sortedTransactions);
        setIsDataLoaded(true);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };
  
    fetchTransactions();
  }, [userAccounts, refreshIndex]);

  const [ nextToken, setNextToken ] = useState('')
  const [ nextNextToken, setNextNextToken ] = useState('')
  // const searchLimit = 25;
  // const [ statements, setStatements ] = useState([])

  const handleScroll = (e) => {
    if (!nextToken) return;
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      console.log('reached the bottom of the scrollbox: ', nextToken);
      setNextNextToken(nextToken)
    }
  };

  useEffect(() => {
    if (currentTeamMemberId) {
      if (currentUserIsGlobalAdmin) {
        if (currentTabName === 'Inbox' || currentTabName === 'Archived') {
          
          // fetchAndProcessStatements(() => fetchStatementsByStatusTab(currentTabName, searchLimit, nextNextToken), setStatements, setFilteredData);
          
        }
      } else {

        // no longer using statements as the precursor to statement tracking
        // fetchAndProcessStatements(() => fetchStatementsByTeamMember(currentTeamMemberId), setStatements, setFilteredData);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamMemberId, refreshIndex, currentUserIsGlobalAdmin, nextNextToken, currentTabName]);

  // this needs to take in ALL statemwents from both inbox and archive
  // then flag the archive version as already saved so they can delete the new one.
  // const groupStatementsByKeys = (statements) => {
  //   console.info('statements:', statements);
  //   const grouped = new Map();
  
  //   statements.forEach(statement => {
  //     const key = `${statement.vendorName}-${statement.purchaseAmount}-${statement.purchaseDate}`;
  //     if (!grouped.has(key)) {
  //       grouped.set(key, []);
  //     }
  //     grouped.get(key).push(statement);
  //   });
  
  //   return grouped;
  // };

  const handleBadgeClick = (id) => {
    // console.log('Badge clicked for ID:', id);
    handleOpenDrawer('upload', id);
    // open drawer for upload receipt code
    // in that code handle for props to be passed in
  };

  const [showIssuesTab, setShowIssuesTab] = useState(false);
  const [issueCounts, setIssueCounts] = useState({
    hasIssueCount: 0,
    hasFixedIssueCount: 0,
  });

  useEffect(() => {
    // This useEffect calculates issue counts whenever transactions change
    let newHasIssueCount = 0;
    let newHasFixedIssueCount = 0;

    userTransactions?.forEach((item) => {
      const receiptHasIssue = item?.capitalOneCreditCardTransactionReceipt?.hasIssue || false;
      if (receiptHasIssue) newHasIssueCount++;

      const receiptHasFixedIssue = item?.status;
      if (receiptHasFixedIssue) newHasFixedIssueCount++;
    });

    setIssueCounts({
      hasIssueCount: newHasIssueCount,
      hasFixedIssueCount: newHasFixedIssueCount,
    });

    // console.log('newHasIssueCount: ', newHasIssueCount);
    // console.log('newHasFixedIssueCount: ', newHasFixedIssueCount);
    // Determine if the Issues tab should be shown
    // setShowIssuesTab(newHasIssueCount > 0 || newHasFixedIssueCount > 0);
    setShowIssuesTab(newHasIssueCount > 0);
  }, [userTransactions]);

  
  const generateRows = (transactions, isMobile, tab) => {
    if (!transactions || transactions.length === 0) {
      return <Fragment><Tr><Td><p>No transactions available.</p></Td></Tr></Fragment>
    }
  
    const rows = [];
    // Map through each transaction and return a simple row with the ID
    transactions.forEach((item, index) => {
      // if (item.purchaseAmount === '59.86') console.log('item: ', item)
      const receiptHasIssue = item?.capitalOneCreditCardTransactionReceipt?.hasIssue||false;      
      const receiptHasFixedIssue = item?.status==='fixed-issue';
      const isSubmitted = (item.isSubmitted===true) ? true : false;

      if (tab==='Inbox') {
        // Filter out rows so we only show receipts ready to upload
        if (isSubmitted || receiptHasIssue || receiptHasFixedIssue) {
          return; // Skip this iteration if any of these conditions are true
        }
      }

      if (tab==='Archived') {
        // Filter out rows so we only show receipts ready to upload
        if (receiptHasIssue || receiptHasFixedIssue || !isSubmitted) {
          return; // Skip this iteration if any of these conditions are true
        }
      }

      if (tab==='Pending Approvals') {
        // Filter out rows so we only show receipts ready to upload
        if (!receiptHasIssue && !receiptHasFixedIssue) {
          return; // Skip this iteration if any of these conditions are true
        }
      }
      
      let receiptStatus = ''
      let submittedText = '';
      let submittedBadgeColor = 'green';
      if (item.isSubmitted) {
        submittedBadgeColor = 'green';
        receiptStatus = 'submitted';
        submittedText = 'Has Receipt';
      } else {
        submittedBadgeColor = 'red';
        receiptStatus = 'unsubmitted';
        submittedText = 'Upload Receipt';
      }
      if (receiptHasIssue) {
        submittedBadgeColor = 'red';
        receiptStatus = 'has-issue';
        submittedText = 'Receipt Has Issue';
      }

      if (receiptHasFixedIssue) {
        submittedBadgeColor = 'green';
        receiptStatus = 'fixed-issue';
        submittedText = 'Fixed Issue';
      }

      let merchantName = (isMobile) ? truncateString(item.purchaseMerchant, 15) : `${item.purchaseMerchant} ${item.lastFour}`;

      rows.push(
        <Fragment key={`frag_${index}_${item.id}`}>
          <Tr 
            key={`tr_${item.id}`} 
            // onClick={() => handleOpenDrawer('detail', item)} 
            onClick={(e) => {
              e.preventDefault();  // Prevent default browser behavior
              e.stopPropagation(); // Stop event propagation to parent elements
              handleBadgeClick(item.id, receiptStatus); // Call function to handle the click and pass the ID
            }}
            cursor="pointer">
            <Td>
              <Box py={'15px'}>
                <Flex justify="space-between">
                  {/* <Text as="span" textStyle='heading-3'>{item.vendorMap?.vendorDisplayName?.vendorDisplayName || item?.vendorName || 'Unknown Vendor'}</Text> */}
                  <Text as="span" textStyle='heading-3'>{merchantName || 'Unknown Vendor'}</Text>
                  <Text as="span" textStyle='heading-3'>{(item.purchaseDate) ? spellOutDate((awsFormatUnknownDate(item?.purchaseDate || '')), 'apple-fy') : 'Empty Date'}</Text>
                </Flex>
                <Flex justify="space-between" mt={2}>
                  <Text as="span" textStyle='text-6'>${item.purchaseAmount}</Text>
                  <Box>
                    <Badge 
                      // isDisabled={true}
                      variant="solid"
                      borderRadius={'6px'}
                      marginRight={'10px'}
                      paddingTop={'5px'}
                      paddingBottom={'5px'} 
                      colorScheme={submittedBadgeColor} 
                      textAlign="center" 
                      display="inline-block" 
                      minWidth="60px"
                      onClick={(e) => {
                        e.preventDefault();  // Prevent default browser behavior
                        e.stopPropagation(); // Stop event propagation to parent elements
                        handleBadgeClick(item.id, receiptStatus); // Call function to handle the click and pass the ID
                      }}>{submittedText}</Badge>
                  </Box>
                </Flex>
              </Box>
            </Td>
          </Tr>
          <Tr key={`divider_${item.id}`}><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
        </Fragment>
      );
    });

    return rows;
  };

  const formSubmit = async () => {
    // console.log('event: ', event)
    // if (!data.selectDivision) return
    // console.log('Form Values:', data);
  }

  const filterTransactionData = (event) => {
    if (!event) {
      return userTransactions;
    }
    
    const searchTerm = event?.target?.value
    const lowercasedTerm = searchTerm.toLowerCase();
    const tempData =  userTransactions.filter(item =>
      item?.purchaseMerchant?.toLowerCase().includes(lowercasedTerm) ||
      item?.purchaseAmount?.toLowerCase().includes(lowercasedTerm) || 
      item?.lastFour?.toLowerCase().includes(lowercasedTerm)
    );
    // console.log("tempData: ", tempData)
    setUserTransactions(tempData);
  };

  // let leftPadding = (currentScreenWidth>460) ? '15px' : '5px';
  let mobilePaddingX = '15px';


  // Calculate number of transactions where isSubmitted is not true
  const unsubmittedCount = userTransactions.filter(item => item.isSubmitted !== true).length;
  // console.log('unsubmittedCount: ', unsubmittedCount)
  let adjustedBoxWidth = (currentScreenWidth>460) ? currentScreenWidth-220 : '100%';

  return (
    <>
      <Container maxW="1600" mb={'50px'} as="form" onSubmit={handleSubmit(formSubmit, onError)}>
        {userIsMobile ?
          <>
            <Box mt={'25px'} w={adjustedBoxWidth}>
              <Box px={mobilePaddingX}>
                <Text as="span" textStyle="heading-1">Search Receipts</Text>
              </Box>
              <Box px={mobilePaddingX} mt={'10px'}>
                <Text>Search visible results by amount, vendor.</Text>
              </Box>
              <Box px={mobilePaddingX} w={'100%'} mt={'5px'} mb={'25px'}>
                <TextInput
                  register={register}
                  errors={errors}
                  fieldname="searchInput"
                  // fieldlabel="Search"
                  prettyname="Search"
                  placeholder="Enter search term"
                  onChange={filterTransactionData}
                />
              </Box>
              <Box px={mobilePaddingX} mb={'25px'}>
                <ButtonQuaternaryWithIcon
                  name='refresh'
                  leftIcon='refresh'
                  iconsize='22px'
                  onClick={() => { handleRefresh(); }}
                  value='Refresh page'
                />
              </Box>
            </Box>

            <Box w={adjustedBoxWidth}>
              <Tabs 
                w={adjustedBoxWidth}
                borderColor='var(--dark-module-divider)' 
                variant='enclosed' 
                colorScheme='spikedarktabs' 
                onChange={(index) => handleTabChange(index)}>
                <TabList>
                  <Tab><Text>Inbox ({unsubmittedCount})</Text></Tab>
                  <Tab><Text>Archived</Text></Tab>
                  {showIssuesTab && <Tab><Text>{`Pending (${issueCounts.hasIssueCount})`}</Text></Tab>}
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <TableContainer>
                      <Table variant={'compact'} size={'compact'} >
                        <Tbody>
                          {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>} */}
                          
                          {isDataLoaded && generateRows(userTransactions, true, currentTabName)}
                          
                          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                        </Tbody> 
                      </Table>
                    </TableContainer>
                  </TabPanel>

                  <TabPanel>
                    <TableContainer>
                      <Table variant={'compact'} size={'compact'} >
                        <Tbody>
                          {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>} */}
                          
                          {isDataLoaded && generateRows(userTransactions, true, currentTabName)}
                          
                          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                        </Tbody> 
                      </Table>
                    </TableContainer>
                  </TabPanel>

                  {showIssuesTab && <TabPanel>
                    <TableContainer>
                      <Table variant={'compact'} size={'compact'} >
                        <Tbody>
                          {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>} */}
                          
                          {isDataLoaded && generateRows(userTransactions, true, currentTabName)}
                          
                          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                        </Tbody> 
                      </Table>
                    </TableContainer>
                  </TabPanel>}


                </TabPanels>
              </Tabs>
            </Box>
          </>
        :
          <> 
            <ModuleBox>
              <Box>
                <Text as="span" textStyle="heading-1">Search Receipts</Text>
              </Box>
              <Box mt={'10px'}>
                <Text>Search visible results by amount, vendor.</Text>
              </Box>
              <Box w={'40%'} my={'25px'}>
                <TextInput
                  register={register}
                  errors={errors}
                  fieldname="searchInput"
                  fieldlabel="Search"
                  prettyname="Search"
                  placeholder="Enter search term"
                  onChange={filterTransactionData}
                />
              </Box>
              <Box>
                <ButtonQuaternaryWithIcon
                  name='refresh'
                  leftIcon='refresh'
                  iconsize='22px'
                  onClick={() => { handleRefresh()}}
                  value='Refresh page'
                />
              </Box>
            </ModuleBox>

            <ModuleBox>
              <Tabs 
                borderColor='var(--dark-module-divider)' 
                variant='enclosed' 
                colorScheme='spikedarktabs' 
                onChange={(index) => handleTabChange(index)}>
                <TabList>
                  <Tab><Text>Inbox ({unsubmittedCount})</Text></Tab>
                  <Tab><Text>Archived</Text></Tab>
                  {showIssuesTab && <Tab><Text>{`Pending Approvals (${issueCounts.hasIssueCount})`}</Text></Tab>}
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <TableContainer>
                      <Box
                        height={'600px'}
                        // width={width||'100%'}
                        overflowY="scroll"
                        border="1px solid"
                        borderColor="var(-dark-component-border)"
                        borderRadius="6px"
                        p={2}
                        onScroll={handleScroll}
                      >
                        <Table variant={'compact'} size={'compact'} >
                          <Tbody>
                            {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                            {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                            
                            {isDataLoaded && generateRows(userTransactions, false, currentTabName)}
                            
                            <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                          </Tbody>
                        </Table>
                      </Box>
                    </TableContainer>
                  </TabPanel>

                  <TabPanel>
                    <TableContainer>
                      <Box
                        height={'600px'}
                        // width={width||'100%'}
                        overflowY="scroll"
                        border="1px solid"
                        borderColor="var(-dark-component-border)"
                        borderRadius="6px"
                        p={2}
                        onScroll={handleScroll}
                      >
                        <Table variant={'compact'} size={'compact'} >
                          <Tbody>
                              {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                              {(filteredData?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr> }*/}
                            
                            {isDataLoaded && generateRows(userTransactions, false, currentTabName)}
                            
                            <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                          </Tbody>
                        </Table>
                      </Box>
                    </TableContainer>
                  </TabPanel>

                  {showIssuesTab && <TabPanel>
                    <TableContainer>
                      <Table variant={'compact'} size={'compact'} >
                        <Tbody>
                          {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                          {(userTransactions?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>} */}
                          
                          {isDataLoaded && generateRows(userTransactions, true, currentTabName)}
                          
                          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                        </Tbody> 
                      </Table>
                    </TableContainer>
                  </TabPanel>}

                </TabPanels>
              </Tabs>
            </ModuleBox>
          </>
        }
        
      <ModuleDrawer
        onClose={onCameraUploadStatementClose}
        isOpen={isCameraUploadStatementOpen}
        bodyContent={<QuickCameraUploadStatement screenWidth={currentScreenWidth} id={selectedTransactionId} onClose={onCameraUploadStatementClose}/>}
        size={'full'}
      />
          
      {/* <ModuleDrawer
        onClose={onStatementDetailClose}
        isOpen={isStatementDetailOpen}
        bodyContent={<StatementDetail qbEnv={qbEnv} screenWidth={currentScreenWidth} selectedStatement={selectedStatement} userData={userData} onClose={onStatementDetailClose}/>}
        size={'full'}
      /> */}
          
      </Container>
    </> 

  );
};
