import { getPrimaryUnit } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const fetchPrimaryUnitById = async (unitId) => {
  if (!unitId) return;

  try {
    const gqlParams = {
      id: unitId
    };

    const response = await client.graphql({
      query: getPrimaryUnit,
      variables: gqlParams
    });

    const results = response.data.getSpkPrimaryUnitTbl;
    return results;
  } catch (err) {
    return ('error fetchPrimaryUnitById: ', [err]);
  }
};

fetchPrimaryUnitById.propTypes = {
  unitId: PropTypes.string.isRequired,
};
