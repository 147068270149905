import { allChartOfAccounts } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const fetchAllChartOfAccounts = async (allsort) => {
  try {
    const gqlParams = {
      allSort: allsort || 'all',
      sortDirection: 'ASC',
    };

    const response = await client.graphql({
      query: allChartOfAccounts,
      variables: gqlParams,
    });

    // console.log('fetchAllChartOfAccounts response: ', response);
    const results = response.data.allCreditCardChartOfAccountsSortableByQBChartOfAccountsName.items;
    return results;
    
  } catch (err) {
    return('error fetchAllChartOfAccounts: ', [err]);
  }
};

fetchAllChartOfAccounts.propTypes = {
  allsort: PropTypes.string,
};