import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Text,
  Flex,
  Spacer,
  useBreakpointValue,
} from "@chakra-ui/react";

// graphql
import { fetchDailyJobTicketById } from '../graphqlCompnents/DJT/fetchDailyJobTicketById.jsx';
import { fetchClientContactByClientContactId } from '../graphqlCompnents/DJT/fetchClientContactByClientContactId.jsx';

// structural components
import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';

// form components
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';

// decorative components
import { Divider } from '../Divider.jsx';

// utility functions
import { USDollar } from '../functions/currency.jsx';
import { converNumberToDouble } from '../functions/number.jsx';

// components
import { LaborSummaryTableViewable } from './LaborSummaryTableViewable.jsx';
import { EquipmentSummaryTableViewable } from './EquipmentSummaryTableViewable.jsx';
import { MaterialSummaryTableViewable } from './MaterialSummaryTableViewable.jsx';

export const ViewDailyJobTicket = (props) => {
  const {
    djtId=props.srcId,
    onClose,
  } = props

  // console.log('props: ', props)

  // get djt data
  const [ dailyJobTicket, setDailyJobTicket ] = useState(null)
  async function loadDailyJobTicket(id) {
    if (id) {
      try {
        const djt = await fetchDailyJobTicketById(id)
        console.log('DJT: ', djt)

        const clientContactId = djt?.djtClientContactId
        const contact = await fetchClientContactByClientContactId(clientContactId)
        djt.contact = contact
        setDailyJobTicket(djt)  
      } catch(err) {
        console.log('loadDailyJobTicket Error: ', err)
      }
    }
  }

  useEffect(() => {
    (djtId) && loadDailyJobTicket(djtId)
  },[djtId])

  useEffect(() => {
    (dailyJobTicket) && console.log('dailyJobTicket: ', dailyJobTicket)
  },[dailyJobTicket])

  const componentWidth = useBreakpointValue({
    sm: '25%',
    md: '40%',
    lg: '45%',
    xl: '45%',
    '2xl': '45%'
  })

  const sortedeDjtLabor = (labor) => {
    if (!labor) return null
    const sortedLabor = labor.sort(function( a , b ){
      return a.startTime > b.startTime ? 1 : -1;
    })
    return sortedLabor
  }

  const djtTotalBilled = dailyJobTicket?.djtTotalBilled||0.00

  return(
    <Container 
      pb='25px'>

      <ModuleBoxDrawer>
        <Flex>
          <Box>
            <Text textStyle='heading-1'>Job Overview</Text>
          </Box>
          <Spacer />
          <Box>
            <ButtonQuaternaryWithIcon 
              name='closeDrawer'
              iconsize='26px'
              leftIcon='close'
              value='Cancel'
              onClick={onClose}
            />
          </Box>
        </Flex>

        {/* Job date, Job PO */}
        <Flex mb={'25px'}>
          <Box>
            <Text>Date: {dailyJobTicket?.djtDate}</Text>
          </Box>
          <Spacer />
          <Box w={componentWidth}>
            <Text>PO: {dailyJobTicket?.djtPO?.po}</Text>
          </Box>
        </Flex>

        {/* Job description, Job location */}
        <Flex mb={'25px'}>
          <Box w={componentWidth}>
            <Text>Job Description: {dailyJobTicket?.djtNotes}</Text>
          </Box>
          <Spacer />
          <Box w={componentWidth}>
            <Text>Location: {dailyJobTicket?.djtLocation}</Text>
          </Box>
        </Flex>

        <Divider mb='25px'/>

        <Flex mb={'25px'}>
          <Box w={componentWidth}>
            <Text>Client: {dailyJobTicket?.djtClient?.contactCompany}</Text>
          </Box>
          <Spacer />
          <Box w={componentWidth}>
            <Text>Contact Email: {dailyJobTicket?.contact?.email}</Text>
          </Box>
        </Flex>

        <Flex mb={'25px'}>
          <Box w={componentWidth}>
            <Text>Contact Name: {dailyJobTicket?.contact?.name}</Text>
          </Box>
          <Spacer />
          <Box w={componentWidth}>
            <Text>Contact Phone: {dailyJobTicket?.contact?.phone}</Text>
          </Box>
        </Flex>

      </ModuleBoxDrawer>

      <ModuleBoxDrawer mt='25px'>
        <Box>
          <Text mb={'10px'} textStyle='heading-1'>Labor</Text>
          <LaborSummaryTableViewable
            tableData={sortedeDjtLabor(dailyJobTicket?.djtLabor?.items)}
            laborTotalAmount={dailyJobTicket?.djtLaborTotal}
          />
        </Box>
      </ModuleBoxDrawer>

      <ModuleBoxDrawer mt='25px'>
        <Box>
          <Text mb={'10px'} textStyle='heading-1'>Equipment</Text>
          {(dailyJobTicket?.id) && <EquipmentSummaryTableViewable
            tableData={dailyJobTicket?.djtEquipment?.items}
            unitTotalAmount={dailyJobTicket?.djtEquipmentTotal}
            djtId={dailyJobTicket?.id}
          />}
        </Box>
      </ModuleBoxDrawer>

      <ModuleBoxDrawer mt='25px'>
        <Box>
          <Text mb={'10px'} textStyle='heading-1'>Materials</Text>
          <MaterialSummaryTableViewable
            tableData={dailyJobTicket?.djtMaterial?.items}
            materialTotalAmount={dailyJobTicket?.djtMaterialTotal}
          />
        </Box>
        {/* {dailyJobTicket?.djtMaterial?.items?.map((item, index) => {
          return(
            <Box key={`material_${index}`}>
              <Text>{item?.description} {item?.quantity} {item?.totalPrice}</Text>
            </Box>
          )
        })}
        <Box>
          <Text mb={'10px'} textStyle='heading-3'>Total Billed: {USDollar.format(converNumberToDouble(dailyJobTicket?.djtMaterialTotal))}</Text>
        </Box> */}
      </ModuleBoxDrawer>

      <ModuleBoxDrawer mt='25px'>
        <Flex>
          <Box>
            <Text textStyle='heading-1'>Daily Job Ticket Total Billed:</Text>
          </Box>
          <Spacer/>
          <Box>
          {/* <Text textStyle='heading-1'>{USDollar.format(converNumberToDouble(dailyJobTicket?.djtTotalBilled))}</Text> */}
          <Text textStyle='heading-1'>{USDollar.format(converNumberToDouble(djtTotalBilled))}</Text>
          </Box>
        </Flex>
      </ModuleBoxDrawer>

      <ModuleBoxDrawer mt='25px' mb='50px'>
        <Box>
          <Text mb={'10px'} textStyle='heading-1'>Special Billing Instructions</Text>
        </Box>
        <Box w={componentWidth}>
          <Text>Special Instructions for Spike Administrator: {dailyJobTicket?.specialInstructions}</Text>
          <Text>Do Not Bill: {dailyJobTicket?.doNotBill}</Text>
          <Text>Reason for not billing: {dailyJobTicket?.doNotBillDescription}</Text>
        </Box>
      </ModuleBoxDrawer>
    </Container>
  )
}

// Prop validation
ViewDailyJobTicket.propTypes = {
  djtId: PropTypes.string, // Daily Job Ticket ID, optional
  srcId: PropTypes.string, // Source ID (used as fallback for djtId)
  onClose: PropTypes.func.isRequired, // Function to close the view
};