import { createNavMenuUser } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const addUserNavigation = async (props) => {
  if (!props) return;
  console.log('Adding nav menu user with props:', props);

  try {
    const response = await client.graphql({
      query: createNavMenuUser,
      variables: { input: props }
    });

    const result = response.data.createSpkNavMenuUserTbl.id;
    return result;
  } catch (err) {
    console.error('Error in addNavMenuUser:', err);
    return 'Error: Unable to add nav menu user';
  }
};

addUserNavigation.propTypes = {
  props: PropTypes.shape({
    tmId: PropTypes.string.isRequired,
    navMenuId: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
  }).isRequired,
};