import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Button } from '@chakra-ui/react';

export const ButtonQuaternaryPlain = (props) => {
  const {
    name,
    ref,
    value,
    onClick,
    isDisabled=false, 
    mx='0px', 
    my='0px', 
  } = props;

  return (
    <Button 
      {...props}
      name={name}
      ref={ref}
      variant='plainQuaternary' 
      onClick={onClick}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
    >
      {value}
    </Button>
  );
};

// Prop validation
ButtonQuaternaryPlain.propTypes = {
  name: PropTypes.string, 
  ref: PropTypes.oneOfType([ 
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  value: PropTypes.oneOfType([ 
    PropTypes.string,
    PropTypes.number,
  ]).isRequired, 
  onClick: PropTypes.func, 
  isDisabled: PropTypes.bool, 
  mx: PropTypes.oneOfType([ 
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([ 
    PropTypes.string,
    PropTypes.number,
  ]),
};

export const ButtonQuaternaryPlainWithRef = forwardRef(function ButtonQuaternaryPlainWithRef(props, ref) { 
  const {
    name,
    value,
    onClick,
    isDisabled,
    mx,
    my,
  } = props;

  return (
    <Button 
      {...props}
      name={name}
      ref={ref}
      variant='plainQuaternary' 
      onClick={onClick}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
    >
      {value}
    </Button>
  );
});

// Setting the displayName property to avoid the ESLint warning
ButtonQuaternaryPlainWithRef.displayName = 'ButtonQuaternaryPlainWithRef';

// Prop validation for ButtonQuaternaryPlainWithRef
ButtonQuaternaryPlainWithRef.propTypes = {
  name: PropTypes.string, 
  ref: PropTypes.oneOfType([ 
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  value: PropTypes.oneOfType([ 
    PropTypes.string,
    PropTypes.number,
  ]).isRequired, 
  onClick: PropTypes.func, 
  isDisabled: PropTypes.bool, 
  mx: PropTypes.oneOfType([ 
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([ 
    PropTypes.string,
    PropTypes.number,
  ]),
};

// Default props for ButtonQuaternaryPlainWithRef
ButtonQuaternaryPlainWithRef.defaultProps = {
  isDisabled: false, 
  mx: '0px', 
  my: '0px', 
};