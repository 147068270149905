
import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';

// utility functions
import { USDollar,  } from '../functions/currency.jsx';
import { converNumberToDouble } from '../functions/number.jsx';

export const MaterialSummaryTableViewable = (props) => {
  const {
    tableData,
    materialTotalAmount=0.00,
  } = props;

  return (
    <TableContainer>
      <Table variant={'compact'} size={'compact'}>
        <Thead>
          <Tr>
            <Th><Text as="span" className='dark-sfpro-heading-4'>DESCRIPTION</Text></Th>
            <Th><Text as="span" className='dark-sfpro-heading-4'>QUANTITY</Text></Th>
            <Th><Text as="span" className='dark-sfpro-heading-4'>TOTAL</Text></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr colSpan='3' h='10px'><Td></Td></Tr>
          {tableData?.map((materialData, materialIndex) => {

            const description = materialData?.description
            const quantity = materialData?.quantity
            const total = materialData?.totalPrice

            return(
              <React.Fragment key={'fragment_'+materialIndex}>
              <Tr key={'tr_labor_spacer_'+materialIndex} h='6px'><Td colSpan='3'></Td></Tr>
              <Tr h='30px' key={'tr_materials_'+materialIndex} borderBottom='1px solid var(--dark-module-divider)'>
                <Td key={'td_materials_1_'+materialIndex}>
                  {description}
                </Td>
                <Td key={'td_materials_4_'+materialIndex} textAlign={'right'} w={'79px'}>
                  {quantity}
                </Td>
                <Td key={'td_maerials_5_'+materialIndex} w={'90px'} textAlign={'right'}>
                  {total}
                </Td>
              </Tr>
              </React.Fragment>
            )
          })}
          <Tr><Td colSpan='3' h='15px'></Td></Tr>
        </Tbody>
        <Tfoot>
          <Tr>
            <Th colSpan={3} textAlign={'right'}>
              <Text textStyle='heading-3'>Material Total: {USDollar.format(converNumberToDouble(materialTotalAmount))}</Text>
            </Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  )
}

MaterialSummaryTableViewable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    quantity: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    totalPrice: PropTypes.string,
  })),
  materialTotalAmount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default MaterialSummaryTableViewable;