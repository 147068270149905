import { defineStyleConfig } from '@chakra-ui/react'

const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    // border: '0px solid red',
    borderRadius: '6px',
    fontFamily: 'SFPro Text-Regular, Helvetica',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '20px',
    letterSpacing: '0',
    textDecoration: 'none',
    _focus: { outline: 'none', boxShadow: 'none', WebkitTapHighlightColor: 'transparent' }
    // chakra css for button is display: inline-flex
    // justifyContent: 'flex-start'
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4, // <-- px is short for paddingLeft and paddingRight
      py: 3, // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: 'md',
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
    df: {
      fontSize: '15px',
      px: '20px', // <-- these values are tokens from the design system
      py: '10px', // <-- these values are tokens from the design system
    },
  },
  // Two variants: outline and solid
  variants: {
    asButtonMinimal: {
      p: '0px',
      m: '0px',
    },
    // plain button primary
    // plain button secondary
    // action with icon is the same as action
    
    // plain button primary
    plainPrimary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-gold-secondary)',
      color: '--dark-text-grey-1',
      transition: 'all 0.3s ease',
      _hover: { color: '--dark-text-grey-1', bg: 'var(--dark-gold-action-hover)', textDecoration: 'none' },
      _active: { color: '--dark-text-grey-1', bg: 'var(--dark-gold-tertiary)', textDecoration: 'none' }
    },

    // button with icon copyClipboard
    copyClipboard: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-green)',
      color: 'var(--dark-text-white)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-white)', bg: 'var(--medium-green)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-white)', bg: 'var(--dark-green)', textDecoration: 'none' }
    },

    // button with icon primary
    withIconPrimary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-gold-secondary)',
      color: 'var(--dark-text-white)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-gold-action-hover)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-white)', bg: 'var(--dark-gold-tertiary)', textDecoration: 'none' }
    },

    // plain button secondary
    plainSecondary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-button-black)',
      color: 'var(--dark-gold-primary)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      _active: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' }
    },

    // button with icon secondary
    withIconSecondary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-button-black)',
      color: 'var(--dark-gold-primary)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      _active: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' }
    },

    // plain button tertiary
    plainTertiary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-module-bg)',
      color: 'var(--dark-text-grey-1)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-2)', bg: 'var(--dark-button-black)', textDecoration: 'none' }
    },

    // button with icon tertiary
    withIconTertiary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-module-bg)',
      color: 'var(--dark-text-grey-1)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-2)', bg: 'var(--dark-button-black)', textDecoration: 'none' }
    },

    // button with icon tertiary
    iconOnlyTertiary: {
      height: 'var(--button-size-m)',
      px: '10px',
      width: '44px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-module-bg)',
      color: 'var(--dark-text-grey-2)', //--dark-text-grey-2
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-2)', bg: 'var(--dark-button-black)', textDecoration: 'none' },
      _focus: { outline: 'none' }
      // _focus: { color: 'var(--dark-text-grey-2)', bg: 'var(--dark-module-bg)', borderColor: 'var(--dark-module-bg)', border: '1px solid green', textDecoration: 'none' },
    },

    // plain button quaternary
    plainQuaternary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-module-bg)',
      color: 'var(--dark-gold-primary)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      _active: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' }
    },

    // plain button quaternary
    plainQuickBooks: {
      // height: 'var(--button-size-m)',
      // px: '10px',
      // // mx: '10px',
      // fontFamily: 'var(--font-family-sfpro-regular)',
      // fontSize: 'var(--font-size-sm)',
      // fontWeight: '400',
      // lineHeight: 'var(--font-size-sm)',
      bg: '#328538',
      // color: 'var(--dark-gold-primary)',
      // transition: 'all 0.3s ease',
      // _hover: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      // _active: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' }
    },

    // button with icon quaternary
    withIconQuaternary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-module-bg)',
      color: 'var(--dark-gold-primary)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      _active: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' }
    },

    // plain button quinary
    plainQuinary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-gold-secondary)',
      color: 'var(--dark-text-white)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-gold-action-hover)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-white)', bg: 'var(--dark-gold-tertiary)', textDecoration: 'none' }
    },

    // button with icon quinary
    withIconQuinary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-gold-secondary)',
      color: 'var(--dark-text-white)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-gold-action-hover)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-white)', bg: 'var(--dark-gold-tertiary)', textDecoration: 'none' }
    },

    // plain button Senary
    plainSenary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-button-grey-1)',
      color: 'var(--dark-text-grey-1)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-grey-1)', bg: 'var(--dark-button-grey-3)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-1)', bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      _focus: { outline: 'none' }
    },

    // button with icon Senary
    withIconSenary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-button-grey-1)',
      color: 'var(--dark-text-grey-1)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-grey-1)', bg: 'var(--dark-button-grey-3)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-1)', bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      _focus: { outline: 'none' }
    },

    // button with icon only Senary
    withIconOnlySenary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-button-grey-1)',
      color: 'var(--dark-text-grey-2)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-grey-2)', bg: 'var(--dark-button-grey-3)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-2)', bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      _focus: { outline: 'none' }
    },

    // button with icon only Senary
    withIconOnlySeptenary: {
      // height: '64px',
      // width: '64px',
      borderRadius: '50%',
      // px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-gold-secondary)',
      color: 'var(--black)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--black)', bg: 'var(--dark-gold-action-hover)', textDecoration: 'none' },
      _active: { color: 'var(--black)', bg: 'var(--dark-gold-action-hover)', textDecoration: 'none' },
      _focus: { outline: 'none' }
    },

    // button with icon only Senary
    cardEllipses: {
      height: 'var(--button-size-m)',
      px: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      // bg: 'var(--dark-button-grey-1)',
      color: 'var(--dark-text-grey-2)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-grey-2)', bg: 'var(--dark-button-grey-3)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-2)', bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      _focus: { outline: 'none' }
    },


    // plain button Octonary
    plainOctonary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--black)',
      color: 'var(--dark-text-grey-1)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-grey-1)', bg: 'var(--dark-button-grey-3)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-1)', bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      _focus: { outline: 'none' }
    },

    // plain button Octonary with Red text for alerts/delete
    plainOctonaryRed: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--black)',
      color: 'var(--error-red)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--error-red)', bg: 'var(--dark-button-grey-3)', textDecoration: 'none' },
      _active: { color: 'var(--error-red)', bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      _focus: { outline: 'none' }
    },

    // button with icon Octonary
    withIconOctonary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--black)',
      color: 'var(--dark-text-grey-1)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-grey-1)', bg: 'var(--dark-button-grey-3)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-1)', bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      _focus: { outline: 'none' }
    },

    // plain button nonary
    plainNonary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-menu-background)',
      color: 'var(--dark-gold-primary)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-grey-3)', textDecoration: 'none' },
      _active: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-grey-4)', textDecoration: 'none' }
    },

    withIconNonary: {
      height: 'var(--button-size-m)',
      px: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      // bg: 'var(--black)',
      color: 'var(--dark-text-white)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-text-grey-2)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-3)', textDecoration: 'none' },
      _focus: { outline: 'none' }
    },

    // button with icon Octonary
    plainDenary: {
      height: 'var(--button-size-m)',
      px: '10px',
      // mx: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
      bg: 'var(--dark-button-grey-1)',
      color: 'var(--dark-gold-primary)',
      transition: 'all 0.3s ease',
      _hover: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      _active: { color: 'var(--dark-gold-primary)', bg: 'var(--dark-button-black)', textDecoration: 'none' },
      _focus: { outline: 'none' }
    },

    plainDuodenaryDefault: {
      height: 'var(--button-size-m)',
      px: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-md)',
      fontWeight: '600',
      lineHeight: 'var(--font-size-md)',
      bg: 'var(--dark-button-black)',
      color: 'var(--dark-text-white)',
      _focus: { outline: 'none' }
    },

    plainDuodenarySelected: {
      height: 'var(--button-size-m)',
      px: '10px',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-md)',
      fontWeight: '600',
      lineHeight: 'var(--font-size-md)',
      bg: 'var(--dark-button-black)',
      color: 'var(--dark-gold-primary)',
      _focus: { outline: 'none' }
    },

    action: {
      height: '44px',
      mx: '5px',
      fontFamily: 'SFPro Text-Regular, Helvetica',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      position: 'relative !important',
      bg: '#C99131',
      color: '#ffffff',
      transition: '0.3s',
      _hover: { bg: '#B2812B', textDecoration: 'none' },
      _active: { background: '#9A6F25', textDecoration: 'none' }
    },
    actionstacked: {
      height: '44px',
      marginTop: '10px',
      marginBottom: '20px',
      // mx: '5px',
      // my: '20px',
      fontFamily: 'SFPro Text-Regular, Helvetica',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      position: 'relative !important',
      bg: '#C99131',
      color: '#ffffff',
      transition: '0.3s',
      _hover: { bg: '#B2812B', textDecoration: 'none' },
      _active: { background: '#9A6F25', textDecoration: 'none' }
    },
    // submit, reset, cancel
    textonly: {
      height: '44px',
      mx: '5px',
      fontFamily: 'SFPro Text-Regular, Helvetica',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      position: 'relative !important',
      bg: '#000000',
      color: '#C99131',
      transition: '0.3s',
      _hover: { bg: '#3F4143', textDecoration: 'none' },
      _active: { background: '#2D2E2F', textDecoration: 'none' }
    },
    // export as PDF
    // secondarywithicon: {
    //   height: '44px',
    //   mx: '5px',
    //   fontFamily: 'SFPro Text-Regular, Helvetica',
    //   fontSize: '14px',
    //   fontWeight: '400',
    //   lineHeight: '21px',
    //   position: 'relative !important',
    //   bg: '#000000',
    //   color: '#A7ACB1',
    //   transition: '0.3s',
    //   _hover: { bg: '#363839', textDecoration: 'none' },
    //   _active: { background: '#2D2E2F', textDecoration: 'none' }
    // },
    stackedbytwotop: {
      mt: '25px',
      ml: '25px',
      justifyContent: 'flex-start',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '21px',
      bg: '#1E1E1F',
      color: '#BABFC3',
      minWidth: '300px',
      height: '39px',
      _hover: { bg: '#2E2E2F', textDecoration: 'none' },
      _active: { background: '#000', textDecoration: 'none' }
    },
    stackedbytwobottom: {
      mt: '11px',
      ml: '25px',
      justifyContent: 'flex-start',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '21px',
      bg: '#1E1E1F',
      color: '#BABFC3',
      minWidth: '300px',
      height: '39px',
      _hover: { bg: '#2E2E2F', textDecoration: 'none' },
      _active: { background: '#000', textDecoration: 'none' }
    },
    icononlybutton: {
      height: '44px',
      width: '44px',
      // mx: '5px',
      marginRight: '10px',
      justifyContent: 'center',
      position: 'relative !important',
      bg: 'var(--dark-gold-secondary)',
      color: 'var(--dark-text-white)',
      fontSize: '16px',
      _hover: { bg: 'var(--dark-gold-action-hover)', textDecoration: 'none' },
      _active: { background: 'var(--dark-gold-tertiary)', textDecoration: 'none' }
    },
    iconbutton: {
      mx: '5px',
      justifyContent: 'center',
      position: 'relative !important',
      bg: '#1E1E1F',
      color: '#BABFC3',
      fontSize: '28px',
      _hover: { bg: '#2E2E2F', textDecoration: 'none' },
      _active: { background: '#000', textDecoration: 'none' }
    },
    iconbuttonlogout: {
      mx: '5px',
      justifyContent: 'center',
      position: 'relative !important',
      bg: '#1E1E1F',
      color: '#BABFC3',
      fontSize: '20px',
      _hover: { bg: '#2E2E2F', textDecoration: 'none' },
      _active: { background: '#000', textDecoration: 'none' }
    },
    iconButtonSignout: {
      mx: '5px',
      justifyContent: 'center',
      position: 'relative !important',
      bg: 'var(--dark-module-bg)',
      color: 'var(--dark-text-grey-3)',
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontSize: '16px',
      fontWeight: '400',
      _hover: { bg: '#2E2E2F', textDecoration: 'none' },
      _active: { background: '#000', textDecoration: 'none' }
    },
    // iconButtonLogout: {
    //   height: 'var(--button-size-m)',
    //   px: '10px',
    //   // mx: '10px',
    //   fontFamily: 'var(--font-family-sfpro-regular)',
    //   fontSize: 'var(--font-size-sm)',
    //   fontWeight: '400',
    //   lineHeight: 'var(--font-size-sm)',
    //   bg: 'var(--dark-gold-secondary)',
    //   color: 'var(--dark-text-white)',
    //   transition: 'all 0.3s ease',
    //   _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-gold-action-hover)', textDecoration: 'none' },
    //   _active: { color: 'var(--dark-text-white)', bg: 'var(--dark-gold-tertiary)', textDecoration: 'none' }
    // },    
    iconbuttoncolormode: {
      mx: '5px',
      justifyContent: 'center',
      position: 'relative !important',
      bg: '#1E1E1F',
      color: '#BABFC3',
      fontSize: '23px',
      _hover: { bg: '#2E2E2F', textDecoration: 'none' },
      _active: { background: '#000', textDecoration: 'none' }
    },
    navbutton: {
      mx: '5px',
      fontFamily: 'SFPro Text-Medium, Helvetica',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '21px',
      position: 'relative !important',
      bg: '#1E1E1F',
      color: '#9A6F25',
      _hover: { bg: '#2E2E2F', textDecoration: 'none' },
      _active: { background: '#000', textDecoration: 'none' }
    },
    outline: {
      // border: '2px solid',
      // borderColor: 'purple.500',
      // color: 'purple.500',
    },
    solid: {
      bg: '#9A6F25',
      color: 'white',
      _hover: { bg: '#89611C', textDecoration: 'none' },
      _active: { background: '#7B5515', textDecoration: 'none' }
    },
    primary: {
      height: '44px',
      mx: '5px',
      fontFamily: 'SFPro Text-Regular, Helvetica',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      position: 'relative !important',
      bg: 'var(--dark-gold-secondary)',
      color: 'var(--dark-text-white)',
      transition: 'all 0.3s ease',
      _hover: { bg: 'var(--dark-gold-action-hover)', textDecoration: 'none' },
      _active: { background: 'var(--dark-gold-tertiary)', textDecoration: 'none' }
    },
    primarywithicon: {
      // control icon colors separately
      height: '44px',
      mx: '5px',
      fontFamily: 'SFPro Text-Regular, Helvetica',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      position: 'relative !important',
      bg: 'var(--dark-gold-secondary)',
      color: 'var(--dark-text-white)',
      transition: 'all 0.3s ease',
      _hover: { bg: 'var(--dark-gold-action-hover)', textDecoration: 'none' },
      _active: { background: 'var(--dark-gold-tertiary)', textDecoration: 'none' }
    },
    secondary: {
      height: '44px',
      mx: '5px',
      fontFamily: 'SFPro Text-Regular, Helvetica',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      position: 'relative !important',
      bg: 'var(--dark-button-black)',
      color: 'var(--dark-gold-secondary)',
      transition: 'all 0.3s ease',
      _hover: { bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
      _active: { background: 'var(--dark-button-grey-1)', textDecoration: 'none' }
    },
    // secondarywithicon: {
    //   height: '44px',
    //   mx: '5px',
    //   fontFamily: 'SFPro Text-Regular, Helvetica',
    //   fontSize: '14px',
    //   fontWeight: '400',
    //   lineHeight: '21px',
    //   position: 'relative !important',
    //   bg: 'var(--dark-button-black)',
    //   color: 'var(--dark-gold-secondary)',
    //   transition: 'all 0.3s ease',
    //   _hover: { bg: 'var(--dark-button-grey-2)', textDecoration: 'none' },
    //   _active: { background: 'var(--dark-button-grey-1)', textDecoration: 'none' }
    // },
    secondarywithicon: {
      height: '44px',
      mx: '5px',
      fontFamily: 'SFPro Text-Regular, Helvetica',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      position: 'relative !important',
      bg: 'var(--dark-module-bg)',
      color: 'var(--dark-text-grey-1)',
      transition: '0.3s',
      _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-14)', background: 'var(--dark-button-black)', textDecoration: 'none' }
    },
    calendarbutton: {
      border: '1px solid #fff',
      // margin: '16px',
      justifyContent: 'flex-start',
      // bg: '#9A6F25',
      padding: '10px 20px 10px 20px',
      bg: '#1E1E1F',
      color: '#BABFC3',
      _hover: { bg: '#2E2E2F', textDecoration: 'none' },
      _active: { background: '#000', textDecoration: 'none' }
    },
    
  },
  // The default size and variant values
  defaultProps: {
    size: 'df',
    variant: 'primary',
  },
})

export default Button

//primary, secondary, tertiary, quaternary, quinary, senary, septenary, octonary, nonary & denary