// Lambda: authAdminListUserPoolGroups
// Url: https://us-east-1.console.aws.amazon.com/lambda/home?region=us-east-1#/functions/authAdminListUserPoolGroups?newFunction=true&tab=code
// exec_admin	Executive adminsitrators.	10	Last year
// maintenance	OKC Maintenance	10	Last year
// exec_admin_readonly	Users in this group have access to Executive Admin resources but cannot create/put to those resources.	20	Last year
// supervisor	This is the group supervisors and assistant supervisors belong to. This is required for DJT access.	30	Last year
// issued_credit_card	These users have been issued a credit card and will have access to submit receipts.	90	Last year
// team_member	General access to dashboard component needed to login. This is the general, lowest-level access group for new hires.	100	Last year

import { useEffect, useState } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

export const useCognitoUserGroups = (cognitoUserEmail) => {
  const [userPoolGroups, setUserPoolGroups] = useState([]);
  const [isUserPoolGroupsLoading, setIsUserPoolGroupsLoading] = useState(true);
  const [userPoolGroupsError, setUserPoolGroupsError] = useState(null);

  useEffect(() => {
    const fetchUserGroups = async () => {
      try {
        const session = await fetchAuthSession();
        // const groups = session.accessToken.payload['cognito:groups'] || [];
        const groups = ['exec_admin']
        const props = {
          groupname: "exec_admin"
        }
        if (cognitoUserEmail) {
          props.username = cognitoUserEmail;
        }

        if (groups.includes('exec_admin')) {
          const endpointUrl = "https://ugczaq3r89.execute-api.us-east-1.amazonaws.com/dev/adminlistuserpoolgroups";
          const authToken = `Bearer ${session.tokens.idToken}`;
          // console.info('props: ', props)
          const response = await fetch(endpointUrl, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': authToken
            },
            // body: JSON.stringify({ groupname: "exec_admin" }) // Assuming the API needs this body format
            body: JSON.stringify(props) // Assuming the API needs this body format
          });

          // console.info('response from adminlistuserpoolgroups: ', response)

          if (!response.ok) {
            throw new Error('Failed to fetch groups from API.');
          }

          const data = await response.json();
          // console.log('data: ', data.body)
          setUserPoolGroups(data.body); // Adjust according to actual response structure
        } else {
          throw new Error('User is not in the exec_admin group.');
        }
      } catch (error) {
        setUserPoolGroupsError(error.message);
      } finally {
        setIsUserPoolGroupsLoading(false);
      }
    };

    fetchUserGroups();
  }, [cognitoUserEmail]);

  //{userPoolGroups, isUserPoolGroupsLoading, userPoolGroupsError}
  return { userPoolGroups, isUserPoolGroupsLoading, userPoolGroupsError };
};

