

export const getTerms = async (token, environment) => {

  const response = await fetch('https://o5xgssmrxyctoccnvi6cgtvdji0qrrvj.lambda-url.us-east-1.on.aws/?token='+token+'&qbenv='+environment, { 
    method: 'GET', 
    headers: new Headers({
        'Authorization': 'Bearer '+token, 
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Origin': 'https://localhost:3000'
    })
  });

  const dataJSON = await response.json()
  // console.warn(' ----- getTerms dataJSON: ', dataJSON)
  let qbresponse = ''

  // console.log('specific customer data: ', dataJSON)
  // if data.JSON.fault.error[0].code = "3200". we need to refresh
  if (dataJSON.fault?.error.length>0) {
    console.warn('GOT AN ERROR in getTerms: ', dataJSON.fault?.error)
    qbresponse = null
  }
  if (dataJSON?.QueryResponse?.Term) {
    qbresponse = dataJSON?.QueryResponse?.Term
  }  
  // console.log('qbresponse is: ', qbresponse)
  // setCustomers(dataJSON.QueryResponse.Customer)
  return qbresponse
}