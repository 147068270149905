import { createQueuedUnitInspection } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const addQueuedUnitInspection = async (params) => {
  if (!params) {
    return 'Missing params';
  }

  try {
    const response = await client.graphql({
      query: createQueuedUnitInspection,
      variables: { input: params },
    });

    console.log('RESPONSE: ', response);
    const inspection = response.data.createSpkPrimaryUnitInspectionQueueTbl;
    console.log('INSPECTION: ', inspection);
    return inspection;
  } catch (err) {
    return 'error addQueuedUnitInspection: ' + err;
  }
};

addQueuedUnitInspection.propTypes = {
  params: PropTypes.shape({
    unitId: PropTypes.string.isRequired,
    queueStatus: PropTypes.string.isRequired,
    // Add other fields as required
  }).isRequired,
};
