/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Box, VStack, Text, HStack } from '@chakra-ui/react';

// form components
import { TextInput } from '../Form/Input/TextInput';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain';

export const ScrollableBox = (props) => {

  const { 
    items, 
    width, 
    height, 
    handleSelectedObject, 
    handleLoadMore, 
    register, 
    errors, 
    setError, 
    clearErrors,
    suggestedMerchantName,
    showCreateSupplier=true,
   } = props
  const [selectedObject, setSelectedObject] = useState(null);

  const handleClick = (item) => {
    setSelectedObject(item);
  };

  const saveSelection = () => {
    handleSelectedObject(selectedObject)
  }

  const resetSelection = () => {
    setSelectedObject(null)
  }

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      // console.log('reached the bottom of the scrollbox');
      handleLoadMore()
    }
  };

  const handleNewVendorNameChange = (e) => {
    const vendor = e.target.value
    if (vendor.length>=3) {
      clearErrors('newVendorName')
      console.log('newVendorName: ', e.target.value)
      setSelectedObject({
        id: '', 
        name: e.target.value, 
        value: ''
      })
    } else {
      setError('newVendorName', {
        type: 'manual',
        message: 'Vendor name must be at least 3 characters long'
      })
    }
  }

  return (
    <Box width={width}>
      <Box
        height={height||'285px'}
        // width={width||'100%'}
        width={'100%'}
        overflowY="scroll"
        border="1px solid"
        borderColor="var(-dark-component-border)"
        borderRadius="6px"
        p={2}
        onScroll={handleScroll}
        // bg="var(--dark-button-grey-1)"
        sx={{
          '::-webkit-scrollbar': {
            width: '12px',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'var(--dark-button-grey-4)',
            borderRadius: '10px',
          },
          '::-webkit-scrollbar-track': {
            background: 'var(--dark-button-grey-1)',
          },
        }}
      >
        <VStack spacing={2}>
          {items?.map((item, index) => (
            <Box
              key={'box_'+index+'_'+item?.id}
              w="100%"
              p={2}
              bg={selectedObject?.id === item.id ? 'var(--dark-button-grey-4)' : 'var(--dark-button-grey-1)'}
              borderRadius="6px"
              cursor="pointer"
              onClick={() => handleClick(item)}
              _hover={{ bg: 'var(--dark-button-grey-4)' }}
            >
              {/* <Text textStyle='text-1'>{item.id} : {item.name} - {item.value}</Text> */}
              <Text textStyle='text-1'>{item.name}</Text>
            </Box>
          ))}
        </VStack>
      </Box>

      {selectedObject?.id ? 
        <Box  width={width||'100%'} mt={4} p={4} border="1px solid" borderColor="gray.200" borderRadius="md">
          <HStack spacing={3}>
            <ButtonPrimaryPlain
              name="saveSelection"
              value="Update Supplier"
              onClick={saveSelection} />
            <ButtonPrimaryPlain
              name="resetSelection"
              value="Reset"
              onClick={resetSelection} />
            <Text fontSize="lg">{selectedObject.name}</Text>
          </HStack>
          
          
        </Box>
       : 
      <>
        {showCreateSupplier && <Box mt={4} p={4} border="1px solid" borderColor="gray.200" borderRadius="md">
        <Box>
          <TextInput
            register={register}
            errors={errors}
            fieldtype="text"
            fieldname="newVendorName"
            fieldlabel="Create new QuickBooks supplier"
            prettyname="New Vendor Name"
            fieldvalue={suggestedMerchantName}
            isDisabled={suggestedMerchantName}
            placeholder="Enter new QuickBooks supplier name"
            onChange={handleNewVendorNameChange}
          />
          <ButtonPrimaryPlain
            name="saveSelection"
            value="Create New Supplier"
            onClick={saveSelection} />
        </Box>
      </Box>}</>
      }
    </Box>
  );
};