import { stripToNumbersAndDecimal } from '../functions/number.jsx'

export const pushStatementToQuickBooks = async (purchaseObject, filename, key, identityid, token, environment) => {

  const { accountId, vendorId, txnAmount, expensCatId, classId, notes } = purchaseObject

  console.warn(' ----- fileKey: ', key)
  console.warn(' ----- pushFileToQB: ', filename, key, identityid, token, environment, accountId, vendorId, txnAmount, expensCatId, classId)
  // const maxRetries = 3
  // let attempts = 0
  let urlParams = []
  
  urlParams.push('qbenv='+environment)
  // urlParams.push('qbenv=sandbox')  // hardcded to sandbox for now
  urlParams.push('fileName='+filename)
  urlParams.push('fileKey='+key)
  urlParams.push('identityId='+identityid)
  urlParams.push('token='+token)
  urlParams.push('accountId='+accountId)
  urlParams.push('vendorId='+vendorId)
  urlParams.push('txnAmount='+stripToNumbersAndDecimal(txnAmount))
  urlParams.push('expensCatId='+expensCatId)
  urlParams.push('classId='+classId)
  urlParams.push('memo='+encodeURIComponent(notes))
  const urlParamsString = urlParams.join('&')

  const response = await fetch('https://4xwldvmyqrrpgzbb2lrqi4h3t40fnimz.lambda-url.us-east-1.on.aws/?'+urlParamsString, { 
    method: 'GET', 
    headers: new Headers({
        'Authorization': 'Bearer '+token, 
        'Content-Type': 'application/x-www-form-urlencoded',
    })
  });

  const dataJSON = await response.json()
  const qbPurchaseId = dataJSON?.purchaseId
  return qbPurchaseId

  
}