export const isCurrency = (value) => {
  let currencyRegEx = /\$/g
  if (currencyRegEx.test(value)) {
    return true
  }
  return false
}

export const isWholeNumber = (value) => {
  if (Number.isInteger(Number(value))) {
    return true
  }
  return false
}

export const isNumber = (value) => {
  return typeof value === 'number'
}

export const converNumberToDouble = (value) => {
  return Number.parseFloat(value).toFixed(2)
}

export const convertToCurrency = (amount) => {
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return USDollar.format(amount)
}

export function stripToNumbersAndDecimal(input) {
  if (!input) return '';
  return input.replace(/[^\d.]/g, '');
}