import { updateDJT } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const updateDJTDate = async (props) => {
  if (!props) return;

  try {
    const params = {
      id: props.id,
      djtDate: props.djtDate,
    };

    const response = await client.graphql({
      query: updateDJT,
      variables: { input: params }
    });

    const results = response.data.updateSpkDailyJobTicketTbl.id;
    return results;
  } catch (err) {
    return ('error updateDJTDate: ', [err]);
  }
}

updateDJTDate.propTypes = {
  id: PropTypes.string.isRequired,
  djtDate: PropTypes.string.isRequired,
};
