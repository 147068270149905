import { getClientPoById } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchClientPoById = async (poId) => {
  if (!poId) return;

  try {
    const variables = {
      id: poId
    };

    const response = await client.graphql({
      query: getClientPoById,
      variables
    });

    const results = response.data.getSpkDJTPOTbl;
    
    return results;
  } catch (err) {
    return ('error fetchClientPoById: ', [err]);
  }
}

fetchClientPoById.propTypes = {
  poId: PropTypes.string.isRequired,
};
