import { createCreditCardChartOfAccounts } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const addChartOfAccounts = async (props) => {
  if (!props.qbChartOfAccountsId || !props.qbChartOfAccountsName) return;

  const environment = props?.allSort;
  const allSort = (environment === 'sandbox') ? 'sandbox' : 'all';
  // Set default value for props if needed
  props.allSort = allSort;

  try {
    console.log('addChartOfAccounts props: ', props);

    const gqlParams = {
      input: props,
    };

    const response = await client.graphql({
      query: createCreditCardChartOfAccounts,
      variables: gqlParams,
    });

    console.log('addChartOfAccounts response: ', response);
    const results = response.data.createSpkCreditCardChartOfAccountsTbl.qbChartOfAccountsId;
    // console.log('addChartOfAccounts results: ', results);
    return results;
  } catch (err) {
    return('error addChartOfAccounts: ', [err]);
  }
};

addChartOfAccounts.propTypes = {
  props: PropTypes.shape({
    qbChartOfAccountsId: PropTypes.string.isRequired,
    qbChartOfAccountsName: PropTypes.string.isRequired,
    allSort: PropTypes.string, // Automatically set to 'all', so optional here.
    // Add any other fields required in `props` here
  }).isRequired,
};