import { isNumber } from "./number";

export const multiplyIfEaster = (str) => {  
  if (!str) return 0

  // Check for invalid characters
  if (/[^0-9@*]/.test(str)) {
    // return 0;
    return str;
  }

  // Check if the string includes '@' or '*'
  if (!isNumber(str) && (str.includes('@') || str.includes('*'))) {
    // Determine the character to split on
    const splitChar = str.includes('@') ? '@' : '*';
    
    // Split the string on the character
    const [first, second] = str.split(splitChar);
    
    // Check if both split values are numbers
    if (!isNaN(first) && !isNaN(second)) {
      // Multiply the numbers and return the result
      return Number(first) * Number(second);
    }
  }

  // Return null if conditions are not met
  // if (isNaN(str)) return 0
  return str;
};