export const syncTransactions = async (token) => {
  if (!token) {
    console.warn('syncTransactions called without a token');
    return null;
  }

  const buildUrl = (baseUrl, params) => {
    const url = new URL(baseUrl);
    Object.keys(params).forEach(key => {
      if (params[key] != null) {
        url.searchParams.append(key, params[key]);
      }
    });
    return url.toString();
  };

  const lambdaUrl = 'https://fhze7dilbrncqrlwrox57ys5aq0kesnz.lambda-url.us-east-1.on.aws/';
  
  const params = {
    token: token
  };

  const url = buildUrl(lambdaUrl, params);

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log('syncTransactions result: ', result);
    return result;
  } catch (error) {
    console.error('Error in syncTransactions:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};