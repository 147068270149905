export const getCustomer = async (token, customerId, environment) => {
  if (!token) {
    console.warn('NO TOKEN in getCustomer')
    return null
  }

  const response = await fetch('https://das7ne6uh5h3ivq5smozfux5wq0bbhlz.lambda-url.us-east-1.on.aws/?qbenv='+environment+'&token='+token+'&customerId='+customerId, { 
    method: 'GET', 
    headers: new Headers({
        'Authorization': 'Bearer '+token, 
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Origin': 'https://localhost:3000'
    })
  });

  const dataJSON = await response.json()
  // console.warn('getCustomer raw json: ', dataJSON)
  let qbresponse = ''

  // console.log('specific customer data: ', dataJSON)
  // if data.JSON.fault.error[0].code = "3200". we need to refresh
  if (dataJSON.fault?.error.length>0) {
    qbresponse = null
  }
  if (dataJSON.Customer) {
    qbresponse = dataJSON.Customer
  }
  // console.log('qbresponse is: ', qbresponse)
  // setCustomers(dataJSON.QueryResponse.Customer)
  return qbresponse
}