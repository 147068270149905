import {
  Box,
  Text,
  Progress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Center,
  VStack,
} from "@chakra-ui/react";

import { AvailableIcons } from "../Icons/AvailableIcons";
import { ButtonPrimaryPlain } from "../Form/Button/ButtonPrimaryPlain";
import { ButtonDenaryPlain } from "../Form/Button/ButtonDenaryPlain";
// import { TextInputDate } from "../Form/Input/TextInputDate";

// --success-green: #67C336;
// --caution-yellow: #F1CE3C;
// --disabled-red: #FF3C1E;

export const CenteredDeleteRestoreWorkingDJTModal = (props) => {

  const {
    isModalOpen,
    onModalClose,
    content,
    closeButtonDisabled,
    handleDeleteRestoreModalClosed,
    progress=0,
    actionType='delete',
  } = props

  return (
    <>
      <Modal 
        closeOnEsc={false} 
        blockScrollOnMount={true} 
        closeOnOverlayClick={false}  
        onClose={onModalClose} 
        isOpen={isModalOpen} 
        onCloseComplete={handleDeleteRestoreModalClosed} 
        isCentered>
        <ModalOverlay 
          bg='none'
          backdropFilter='auto'
          backdropBrightness='60%'
          backdropBlur='2px'
        />
        <ModalContent width={'380px'} bg={'var(--dark-menu-background)'}>
        {
          (progress<100 && !closeButtonDisabled) ? 
          <><ModalHeader>
            <Box mt={'20px'} h={'50px'}>
              {(!closeButtonDisabled) && 
                <Center>
                  <Box>
                    <AvailableIcons boxSize={'60px'} iconRef={'alerttriangle'} color={'var(--caution-yellow)'}/>
                  </Box>
                </Center>
              }
            </Box>
          </ModalHeader></> 
        : 
          <><ModalHeader mx={'25px'}>
            <Box mt={'5px'} h={'105px'}>
            <Center>
              <VStack p='0px' m='0px'>
              {(progress===100 && !closeButtonDisabled) && <><Box mt='10px'><AvailableIcons boxSize={'60px'} iconRef={'outlinecheckcircle'} color={'var(--success-green)'}/></Box>
                <Box><Text as="span" textStyle='heading-2'>Success!</Text></Box></>}
                <Box h='30px'></Box>
                <Box h='50px'><Text as="span" textStyle='heading-2'>{content}</Text></Box>
              </VStack>
            </Center>
            </Box>
          </ModalHeader></>
        }

        <ModalBody>
          <Progress mt='10px' mb={'35px'} value={progress} size='sm' maxW={'320px'} hasStripe colorScheme='progressbar' />
            {(progress===100 && !closeButtonDisabled) ? 
                <Center>
                  <Box w='250px'>
                    <Text align='center' textStyle='modal-content-text'>Your daily job ticket has been successfully {(actionType==='delete') ? 'deleted' : 'restored'}.</Text>
                  </Box>
                </Center> 
              : 
                <Center><Box height='50px'></Box></Center>}
          </ModalBody>
          <ModalFooter mx={'0px'} px={'0px'} my={'0px'} pt={'20px'} pb={'25px'}>
            {/* enable button on completion of submission */}
            {/* {(progress===100 && !closeButtonDisabled) ?  */}
              <><Box width={'100%'}>
                <Center>
                  <ButtonPrimaryPlain 
                    width='148px'
                    name='closeSubmitModal'
                    value='Continue'
                    onClick={onModalClose}
                    isDisabled={closeButtonDisabled}
                  />
                </Center>
              </Box></> 
            {/* : 
              <><Box h='45px'></Box></>} */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}