import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Center,
} from "@chakra-ui/react";

export const CenteredAllUnitsModal = ({ isModalOpen, onModalClose }) => {
  return (
    <Modal onClose={onModalClose} isOpen={isModalOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>Add Unavailable Primary Unit</Center>
        </ModalHeader>
        {/* <ModalCloseButton /> */}
        <ModalBody>
          <Center>
            You are adding a primary unit that was either not inspected or not located at this division for the date of this job ticket.
          </Center>
        </ModalBody>
        <ModalFooter>
          <Center>
            <Button onClick={onModalClose}>Continue</Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

CenteredAllUnitsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
};
