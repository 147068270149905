import { defineStyleConfig } from '@chakra-ui/react'

const Drawer = defineStyleConfig({
  parts: ['dialog', 'header', 'body'],
  variants: {
    primary: {
      dialog: {
        background: 'black',
      }
    }
  },
  secondary: {
    dialog: {
      background: 'green'
    }
  },
  // The default size and variant values
  defaultProps: {
    variant: 'primary',
  },
})

export default Drawer

