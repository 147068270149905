import { useEffect, useContext, useState, useRef } from 'react';
import { PropTypes } from 'prop-types';

//https://codesandbox.io/p/sandbox/react-image-crop-demo-with-react-hooks-y831o?file=%2Fsrc%2FApp.tsx%3A10%2C10-10%2C23
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Text,
  Flex,
  Spacer,
  Center,
  Input,
  Spinner,
  VStack,
} from "@chakra-ui/react";

// From
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonDenaryPlain } from '../Form/Button/ButtonDenaryPlain.jsx';
import { FormSwitch } from '../Form/Switch/FormSwitch.jsx';

// AWS
import { uploadFileToS3 } from '../AWS/S3/uploadFileToS3.jsx';

// Generic
import { awsDateTimeNow } from '../functions/dateTime.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// graphql
import { addUnitDocument } from '../graphqlCompnents/Unit/addUnitDocument.jsx';
import { updateUnit } from '../graphqlCompnents/Unit/updateUnit.jsx';

export const UploadUnitImages = (props) => {
  const {
    unitid,
    unitname,
    refresh,
  } = props

  // grab global context values
  const { store } = useContext(AppContext);

  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id
  console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)

  const unitDocumentTypes = store?.unitDocumentTypes

  // --- REACT-HOOK-FORM ---
  const { 
    control, 
    register, 
    setValue, 
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {},
  });

  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const [result, setResult] = useState(null);
  const imageRef = useRef(null);
  const borderSize = 300;

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setShowFileUploadSpinner(true)
      const borderedImage = await addBorderToImage(file, borderSize);
      setSrc(URL.createObjectURL(borderedImage));
    }
    setShowFileUploadSpinner(false)
  };

  const addBorderToImage = (file, borderSize) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width + borderSize * 2;
        canvas.height = image.height + borderSize * 2;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, borderSize, borderSize);
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg');
      };
      image.src = URL.createObjectURL(file);
    });
  };

  const handleCropChange = (crop) => {
    setCrop(crop);
  };

  const handleCropComplete = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef.current, crop);
      setResult(croppedImageUrl);
    }
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(URL.createObjectURL(blob));
      }, 'image/jpeg');
    });
  };

  const resizeImage = (imageUrl, width, height) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');

      const image = new Image();
      const timestamp = Date.now();
      const fileName = `${timestamp}.jpg`;
      const imageType = 'image/jpeg';
      image.onload = () => {
        ctx.drawImage(image, 0, 0, width, height);
        canvas.toBlob((blob) => {
          const file = new File([blob], fileName, { type: imageType });
          resolve(file);
        }, imageType);
      };
      image.src = imageUrl;
    });
  };

  // fileinput ref for attached contracts
  const fileInputRef = useRef(null)

  const [ isDefaultImage, setIsDefaultImage ] = useState(false)

  const showUnits = (unitid && unitname) ? false : true

  let unitDocumentTypeId = null;

  const [ showFileUploadSpinner, setShowFileUploadSpinner ] = useState(false)

  let unitId = unitid||null


  const [smallImageTypeId, setSmallImageTypeId] = useState(null);
  const [mediumImageTypeId, setMediumImageTypeId] = useState(null);
  const [largeImageTypeId, setLargeImageTypeId] = useState(null);
  useEffect(() => {
    const findTypeIdByName = (name) => unitDocumentTypes?.find(type => type?.name === name)?.id;

    if (unitDocumentTypes) {
      const smallTypeId = findTypeIdByName('unit-photo-small');
      const mediumTypeId = findTypeIdByName('unit-photo-medium');
      const largeTypeId = findTypeIdByName('unit-photo-large');

      if (smallTypeId) {
        console.info('Set Small Image Type ID: ', smallTypeId);
        setSmallImageTypeId(smallTypeId);
      }

      if (mediumTypeId) {
        console.info('Set Medium Image Type ID: ', mediumTypeId);
        setMediumImageTypeId(mediumTypeId);
      }

      if (largeTypeId) {
        console.info('Set Large Image Type ID: ', largeTypeId);
        setLargeImageTypeId(largeTypeId);
      }
    }
  }, [unitDocumentTypes, setSmallImageTypeId, setMediumImageTypeId, setLargeImageTypeId]);
  
  const resetImageUploadForm = () => {
    // setImgSrc(null);
    // setCroppedImages({ small: null, medium: null });
    setSrc(null);
    setCrop({ aspect: 1 / 1 });
    setResult(null);
    // imageRef.current = null;
    if (imageRef.current) {
      imageRef.current.value = "";
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setValue('isDefaultImage', false)
    setIsDefaultImage(false)
  }

  const saveImages = async () => {
  
    // Function to save image data to DB
    const saveImageToDB = async (documentId, unitDocumentTypeId) => {
      const documentParams = {
        unitId: unitId,
        createdById: currentTeamMemberId,
        uploadedDateTime: awsDateTimeNow(),
        unitDocumentTypeId: unitDocumentTypeId, // Dynamic ID based on image size
        uploadedDocumentId: documentId,
        unitIdUnitDocumentTypeId: `${unitId}#${unitDocumentTypeId}`,
        active: 'active',
        isActive: true,
      };
      console.info(' -----> documentParams: ', documentParams)
      const newUnitDocument = await addUnitDocument(documentParams);
      console.info(' -----> newUnitDocument: ', newUnitDocument);
      const unitDocumentId = newUnitDocument.id;
      // if they have selected set as default image then update the unit with the new image
      if (unitDocumentTypeId === smallImageTypeId && isDefaultImage) {
        // update the unit with the new image unitMediumImageId unitSmallImageId
        await updateUnit({id: unitId, unitSmallImageId: unitDocumentId});
      } else if (unitDocumentTypeId === mediumImageTypeId && isDefaultImage) {
        // update the unit with the new image unitMediumImageId unitSmallImageId
        await updateUnit({id: unitId, unitMediumImageId: unitDocumentId});
      }
    };
  
    const sizes = [172, 364];
    const bucket = 'prod-spike-unit-images  ';

    const croppedImagesTasks = sizes.map((size, index) => {
      const key = index === 0 ? 'small' : index === 1 ? 'medium' : 'large';

      return resizeImage(result, size, size)
        .then(resizedImage => uploadFileToS3({ file: resizedImage, bucket }))
        .then(uploadData => {
          console.warn('uploadData: ', uploadData)
          const typeIds = {
            small: smallImageTypeId,
            medium: mediumImageTypeId
          };
          return saveImageToDB(uploadData.documentId, typeIds[key]);
        })
        .catch(error => console.error(`Error processing ${key} image:`, error));
    });
  
    // Handle the original image (assuming it's considered as 'large')
    const largeUncropped = await resizeImageUncropped(src, 1024, 1024);
    const originalImageTask = uploadFileToS3({ file: largeUncropped, bucket })
      .then(uploadData => saveImageToDB(uploadData.documentId, largeImageTypeId))
      .catch(error => console.error('Error uploading uncropped image:', error));
  
    // Wait for all images to be processed
    await Promise.all([...croppedImagesTasks, originalImageTask]);
    // await Promise.all([...croppedImagesTasks]);
  
    console.log('All images have been processed and data has been saved.');
    // setImgSrc(null);
    // setCroppedImages({ small: null, medium: null });
    resetImageUploadForm();
    refresh();
  };

  const resizeImageUncropped = (imageUrl, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        let width = image.width;
        let height = image.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);

        const timestamp = Date.now();
        const fileName = `${timestamp}.jpg`;
        const imageType = 'image/jpeg';
        canvas.toBlob((blob) => {
          const file = new File([blob], fileName, { type: imageType });
          resolve(file);
        }, imageType);

        // canvas.toBlob((blob) => {
        //   resolve(blob);
        // }, 'image/jpeg');
      };
      image.src = imageUrl;
    });
  };
  
  const [ isSubmitDisabled, setIsSubmitDisabled ] = useState(true)
  useEffect(() => {
    // Define a base condition that must be true in both scenarios
    const hasRequiredImages = result !== null;
    
    // Extend the condition based on whether showUnits is true or not
    const isFormValid = showUnits ? (unitId && hasRequiredImages) : hasRequiredImages;
    
    // Set the disabled state based on the validity of the form
    setIsSubmitDisabled(!isFormValid);
  }, [unitDocumentTypeId, unitId, showUnits, result]);

  return (
    <Container pb='25px' as="form">

      {/* {(showFileUploadSpinner) ? <Center><Spinner color='var(--progress-bar-primary)' /></Center> :  */}
      <>
        <VStack spacing={4} align='stretch'>
          <Box>
            <Text as="span" textStyle='heading-1'>Upload Unit Image</Text>
          </Box>

          <Box mt={'25px'}>
            <Input 
              name='file-upload'
              ref={fileInputRef} 
              type="file"
              accept=".jpeg, .jpg, .png"
              onChange={handleFileChange}
            />
          </Box>
          {(showFileUploadSpinner) ? <Center><Spinner color='var(--progress-bar-primary)' /></Center> : <>
            <Box mt={'25px'}>
              {(src) && (
                <Box> 
                  <ReactCrop
                    src={src}
                    crop={crop}
                    onChange={handleCropChange}
                    onComplete={handleCropComplete}
                    onImageLoaded={(image) => (imageRef.current = image)}
                  />
                </Box>
              )}
              {result && (
                <Box mt={'25px'}>
                  <Text as="span" textStyle='label-1'>Preview:</Text>
                  <div
                    style={{
                      width: '100%',
                      height: '200px',
                      overflow: 'hidden',
                      display: 'flex',
                    }}>
                    <img
                      alt="Cropped"
                      src={result}
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                      }}/>
                  </div>
                </Box>
              )}
              <Box w={'300px'} pt={'25px'}>
                <Flex>
                  <Box>
                    <Text as="span" textStyle='label-1'>Set as default unit image</Text><br/>
                  </Box>
                  <Spacer />
                  <Box>
                    <FormSwitch
                      register={register}
                      control={control}
                      fieldname="isDefaultImage"
                      isChecked={isDefaultImage}
                      onChange={() => {
                        setValue('isDefaultImage', !isDefaultImage)
                        setIsDefaultImage(!isDefaultImage)
                      }}
                    />
                  </Box>
                </Flex>
              </Box>
            </Box>
          </>}


          <Box pt={'25px'}>
            <ButtonDenaryPlain
              width={'250px'}
              mr='25px'
              type="button"
              // onClick={handleSubmit(formSubmit, onError)}
              // onClick={formConfirmation}
              onClick={resetImageUploadForm}
              name='cancel'
              value='Reset'
              // isDisabled={(djtDate > dateToday || isVerifiedReadyState===false)}
            />
            <ButtonPrimaryPlain 
              width={'250px'}
              // onClick={onFileUploadButtonClick}
              onClick={saveImages}
              type="button"
              name='upload-file'
              value='Upload Image'
              isDisabled={isSubmitDisabled}
              // isDisabled={true}
            />
          </Box>
        </VStack>
      </>

    </Container>

  )

}

UploadUnitImages.propTypes = {
  unitid: PropTypes.string.isRequired,
  unitname: PropTypes.string.isRequired,
  refresh: PropTypes.bool,
};