import { listUnitOperatorsByEquipmentId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchUnitOperatorsByEquipmentId = async (equipmentId) => {
  if (!equipmentId) return;

  try {
    const response = await client.graphql({
      query: listUnitOperatorsByEquipmentId,
      variables: { djtEquipmentId: equipmentId },
    });

    const results = response.data.operatorByDJTEquipmentId.items;
    return results;
  } catch (err) {
    return ('error fetchUnitOperatorsByEquipmentId: ', [err]);
  }
};

fetchUnitOperatorsByEquipmentId.propTypes = {
  equipmentId: PropTypes.string.isRequired,
};
