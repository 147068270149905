import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { listAuxillaryUnitOptionsByUnitId } from '../../../graphql/custom_queries';

const client = generateClient();

export const fetchAuxillaryUnitOptionsByUnitId = async (params) => {
  if (params && params.unitId) {
    try {
      console.log('about to query listAuxillaryUnitOptionsByUnitId with params: ', params)
      const { unitId } = params;
      
      const response = await client.graphql({
        query: listAuxillaryUnitOptionsByUnitId,
        variables: { id: unitId }
      });

      console.log('response: ', response?.data?.getSpkPrimaryUnitTbl)
      const validResponse = response?.data?.getSpkPrimaryUnitTbl;
      
      const results = validResponse 
        ? response?.data?.getSpkPrimaryUnitTbl?.auxillaryUnits?.items
          .sort((a, b) => a.spkAuxillaryUnitTbl.name < b.spkAuxillaryUnitTbl.name ? 1 : -1) 
        : null;

      // console.log('sorted results: ', results)
      const mutResults = results?.map(aux => ({
        spkAuxillaryUnitTbl: {
          id: aux.spkAuxillaryUnitTbl.id,
          name: aux.spkAuxillaryUnitTbl.name,
          parentId: unitId
        }
      }));

      // console.log('mutResults: ', mutResults)
      return mutResults;
    } catch (err) {
      return 'error fetchAuxillaryUnitOptionsByUnitId: ' + err;
    }
  } else {
    return 'Missing params';
  }
};

fetchAuxillaryUnitOptionsByUnitId.propTypes = {
  params: PropTypes.shape({
    unitId: PropTypes.string.isRequired,
  }).isRequired,
};
