import { getCapitalOneReceiptByInstantNotificationId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const fetchCapitalOneReceiptByInstantNotificationId = async (id) => {
  try {
    const gqlParams = {
      capitalOneInstantNotificationId: id
    };

    console.info('gqlParams: ', gqlParams);

    const response = await client.graphql({
      query: getCapitalOneReceiptByInstantNotificationId,
      variables: gqlParams,
    });

    console.info('response: ', response);

    const results = response.data.capitalOneCreditCardTransactionReceiptByCapitalOneInstantNotificationId.items

    return results;
  } catch (err) {
    return 'error fetchAccountsByTeamMember: ' + err;
  }
};

fetchCapitalOneReceiptByInstantNotificationId.propTypes = {
  id: PropTypes.string.isRequired,
};