import PropTypes from 'prop-types';
// import { useRef, useState } from 'react';
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
} from '@chakra-ui/react';

import { ButtonSenaryWithIconOnly } from '../Form/Button/ButtonSenaryWithIconOnly.jsx';
import { TextInput } from '../Form/Input/TextInput.jsx';

// decorative components
import { Divider } from '../Divider.jsx';

// generic functions
import { truncateString } from '../functions/strings.jsx';

export const MaterialSummaryTable = (props) => {
  const {
    tableData,
    updateMaterialSummary,
    handleMaterialRowTotalChange,
    handleMaterialQuantityChange,
    handleMaterialPriceChange,
    handleMaterialCustomDescriptionChange,
    materialTotalAmount,
    djtTotalAmount=0.00,
    costItem,
    register,
    errors,
  } = props;

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <TableContainer>
      <Table 
        variant={'compact'}
        size={'compact'}
        >
        <Thead>
          <Tr>
            <Th></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>DESCRIPTION</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>BASELINE RATE</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>PRICE</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>QUANTITY</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>TOTAL</Text></Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableData?.map((data, materialIndex) => {
            const isDefaultMaterial = (data.isDefaultMaterial)
            return(
              <Tr key={'tr_materials_'+materialIndex}>
                <Td key={'td_materials_0_'+materialIndex}><ButtonSenaryWithIconOnly
                  key={'td_0_materials_delete_'+materialIndex}
                  name={'material_delete_row_'+materialIndex}
                  icon='delete'
                  iconsize='22px'
                  onClick={() => {
                    // this has been moved to inside the updateMaterialSummary function to better handle loading saved tickets.
                    updateMaterialSummary('remove', data, materialIndex)
                  }}
                /></Td>
                <Td key={'td_materials_1_'+materialIndex}><Text as="span" className='dark-sfpro-text-1'>
                {(isDefaultMaterial) 
                  ? <><TextInput
                    register={register}
                    errors={errors}
                    width='320px'
                    fieldname={`material[${materialIndex}].Description`}
                    fieldvalue={`material[${materialIndex}].Description`}
                    placeholder='Materials description...'
                    prettyname='Description'
                    isRequired={true} 
                    isReadOnly={true}
                    fieldtype='hidden'
                    /><Text as="span" className='dark-sfpro-text-1'>&nbsp;&nbsp;{truncateString(data?.Description, 30)}</Text>
                    </> 
                  : <><TextInput
                    register={register}
                    errors={errors}
                    width='320px'
                    fieldname={`material[${materialIndex}].Description`}
                    placeholder='Enter custom materials description'
                    prettyname='Description'
                    rules={{ required: "" }}
                    isRequired={true} 
                    isReadOnly={false}
                    onBlur={(e) => handleMaterialCustomDescriptionChange(materialIndex, e.target.value)}/>
                    {errors.material?.[materialIndex]?.Description && errors.material?.[materialIndex]?.Description.type === "required" && (
                      <Text as="span" textStyle={'text-2'} color='var(--error-red)'>Custom material description required</Text>
                    )}</>
                }
                </Text>
                <TextInput
                    fieldname={`material${materialIndex}Description`}
                    fieldvalue={`material[${materialIndex}].Description`}
                    register={register}
                    errors={errors}
                    fieldtype='hidden'
                  /></Td>
                <Td key={'td_materials_2_'+materialIndex}>
                  {(isDefaultMaterial) ? <><Text as="span" textStyle={'text-2'}>&nbsp;&nbsp;{truncateString(data?.Notes, 30)}</Text><TextInput
                    register={register}
                    errors={errors}
                    width='200px'
                    fieldname={`material[${materialIndex}].Notes`}
                    isRequired={false} 
                    isReadOnly={true}
                    placeholder={'N/A'}
                    fieldtype='hidden'
                  /></> : <><Text as="span" textStyle={'text-2'}>&nbsp;&nbsp;{truncateString(data?.Notes, 30)}</Text><TextInput
                    width='200px'
                    register={register}
                    errors={errors}
                    fieldname={`material[${materialIndex}].Notes`}
                    isRequired={false} 
                    isDisabled={true}
                    fieldvalue='N/A'
                    placeholder={'N/A'}
                    fieldtype='hidden'
                  /></>}
                </Td>
                <Td key={'td_materials_3_'+materialIndex}>
                {(isDefaultMaterial) ? <TextInput
                    width='120px'
                    register={register}
                    errors={errors}
                    fieldname={`material[${materialIndex}].Price`}
                    isRequired={false} 
                    isReadOnly={false}
                    placeholder={'N/A'}
                    onBlur={(e) => handleMaterialPriceChange(materialIndex, e.target.value)}
                  /> : <TextInput
                    width='120px'
                    register={register}
                    errors={errors}
                    fieldname={`material[${materialIndex}].Price`}
                    isRequired={false} 
                    isReadOnly={false}
                    isDisabled={false}
                    onBlur={(e) => handleMaterialPriceChange(materialIndex, e.target.value)}
                  />}
                </Td>
                <Td key={'td_materials_4_'+materialIndex}>
                  <TextInput
                    width='120px'
                    register={register}
                    errors={errors}
                    fieldname={`material[${materialIndex}].Quantity`}
                    isReadOnly={false}
                    onChange={(e) => handleMaterialQuantityChange(materialIndex, e.target.value)}
                  />
                </Td>
                <Td key={'td_maerials_5_'+materialIndex}>
                  <TextInput
                    width='120px'
                    register={register}
                    errors={errors}
                    fieldname={`material[${materialIndex}].RowTotal`}
                    fieldvalue={costItem[materialIndex]}
                    placeholder="$0.00"
                    isRequired={true} 
                    isReadOnly={false}
                    onBlur={(e) => handleMaterialRowTotalChange(materialIndex, e.target.value)}
                  />
                  <TextInput
                    fieldname={`material[${materialIndex}].isDefaultMaterial`}
                    // fieldvalue={data.isDefaultMaterial||false}
                    fieldvalue={data.isDefaultMaterial ? 'true' : 'false'}
                    register={register}
                    errors={errors}
                    fieldtype='hidden'
                  />
                  <TextInput
                    fieldname={`material[${materialIndex}].isCustomMaterial`}
                    fieldvalue={data.isCustomMaterial ? 'true' : 'false'}
                    register={register}
                    errors={errors}
                    fieldtype='hidden'
                  />
                  <TextInput
                    fieldname={`material[${materialIndex}].Id`}
                    fieldvalue={data.MaterialId}
                    register={register}
                    errors={errors}
                    fieldtype='hidden'
                  />
                </Td>
              </Tr>
            )
          })}
          {(tableData?.length>0) && <Tr key={'tr_units_divider_end'}><Td colSpan='7' h='15px'><Divider h={'2px'} mt='5px' mb='5px'/></Td></Tr>}
          <Tr><Td colSpan='7' h='15px'></Td></Tr>
        </Tbody>
        <Tfoot>
          <Tr>
            <Th colSpan={8} textAlign={'right'}>
            <Box><Text as="span" textStyle='text-7' pr='10px'>Material Total:&nbsp;&nbsp;{USDollar.format(materialTotalAmount)}</Text></Box>
            <Box mt='5px'><Text as="span" textStyle='text-8' pr='10px'>DJT Total:&nbsp;&nbsp;{USDollar.format(djtTotalAmount)}</Text></Box>
            </Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  )
}

MaterialSummaryTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateMaterialSummary: PropTypes.func.isRequired,
  handleMaterialRowTotalChange: PropTypes.func.isRequired,
  handleMaterialQuantityChange: PropTypes.func.isRequired,
  handleMaterialPriceChange: PropTypes.func.isRequired,
  handleMaterialCustomDescriptionChange: PropTypes.func.isRequired,
  materialTotalAmount: PropTypes.number.isRequired,
  djtTotalAmount: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  costItem: PropTypes.array.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default MaterialSummaryTable;