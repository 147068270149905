import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { increaseDJTLaborPopularity } from '../../../graphql/custom_mutations';

const client = generateClient();

export const updateTMPopularity = async (deptEmp) => {
  if (deptEmp) {
    try {
      // console.log('about to send to updateTMPopularity: ', deptEmp);
      const response = await client.graphql({
        query: increaseDJTLaborPopularity,
        variables: { departmentEmployeeNumber: deptEmp },
      });

      // console.log('updateTMPopularity response: ', response);
      const results = response.data.increaseDJTLaborPopularity.popularity;
      // console.log('updateTMPopularity results: ', results);
      return results;
    } catch (err) {
      return 'error updateTMPopularity: ' + err;
    }
  } else {
    return null;
  }
};

updateTMPopularity.propTypes = {
  deptEmp: PropTypes.string.isRequired,
};

//departmentEmployeeNumber: "02:M03707", department: "02", employeeNumber: "M03707"
//departmentEmployeeNumber: "02:M03707", department: "02", employeeNumber: "M03707"