import { unitCodeByQrCodeValue } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const fetchUnitCodeByQrCodeValue = async (value) => {
  if (!value) return;
  
  try {
    console.log(' !!!!! qrCodeValue: ', value);
    
    const gqlParams = { qrCodeValue: value };
    
    const response = await client.graphql({
      query: unitCodeByQrCodeValue,
      variables: gqlParams,
    });

    console.log(' !!!!! RESPONSE: ', response);
    
    const results = response.data.unitCodeByQrCodeValue.items[0];
    console.log(' !!!!! RESULTS: ', results);
    
    return results;
  } catch (err) {
    return 'error fetchUnitCodeByQrCodeValue: ' + err;
  }
};

fetchUnitCodeByQrCodeValue.propTypes = {
  value: PropTypes.string.isRequired,
};
