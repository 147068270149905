import { listAllPrimaryUnitCodes } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const fetchPrimaryUnitCodes = async (allSort, limit) => {
  try {
    const gqlParams = {
      allSort: allSort,
      limit: limit
    };

    const response = await client.graphql({
      query: listAllPrimaryUnitCodes,
      variables: gqlParams
    });

    console.warn(' ----- fetchAllUnits response: ', response);
    const results = response.data.allPrimaryUnits.items;
    console.warn(' ----- fetchAllUnits results: ', results);
    results.sort((a, b) => a.codeSortable.localeCompare(b.codeSortable));
    return results;
  } catch (err) {
    return ('error fetchAllUnits: ', [err]);
  }
};

fetchPrimaryUnitCodes.propTypes = {
  allSort: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
};
