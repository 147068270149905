
export const listDivisionOptions = /* GraphQL */ `
  query listDivisionOptions(
    $filter: ModelSpkDivisionTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpkDivisionTbls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        prettyname
      }
      nextToken
    }
  }
`;
export const listDivisions = /* GraphQL */ `
  query listDivisions(
    $filter: ModelSpkDivisionTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpkDivisionTbls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        prettyId
        prettyname
        addStreet
        addCity
        addState
        addZipcode
        superVisorId
        asstSupervisorId
        djtCanPerDiem
        djtPerDiemAmt
        djtTrackLabor
        djtTrackEquipment
        djtTrackMaterial
        djtRentalMsgActive
        djtRentalMsg
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTeamMembers = /* GraphQL */ `
  query listTeamMembers(
    $filter: ModelSpkTeamMemberTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpkTeamMemberTbls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        goesBy
      }
      nextToken
    }
  }
`;
export const getTeamMember = /* GraphQL */ `
  query getTeamMember($id: ID!) {
    getSpkTeamMemberTbl(id: $id) {
      loginEmail
      firstName
      middleName
      lastName
      addStreet
      addCity
      addState
      addZipcode
      employeeNumber
      badgeNumber
      isActive
      isLocked
      dob
      hireDate
      divisionId
      division {
        prettyname
      }
      memberOf
      imgUrl
      emergcyContName
      emergcyContPhone
    }
  }
`;
export const getDivisionIdByLoginEmail = /* GraphQL */ `
  query getDivisionIdByLoginEmail(
    $loginEmail: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkTeamMemberTblFilterInput
    # $limit: Int
    # $nextToken: String
  ) {
    teamMemberByLoginEmail(
      loginEmail: $loginEmail
      sortDirection: $sortDirection
      filter: $filter
      # limit: $limit
      # nextToken: $nextToken
    ) {
      items {
        id
        divisionId
        division {
          djtOneClient
          djtEnableAllSupervisors
          djtDefaultStartTime
          djtDefaultLunchDuration
          djtDefaultEndTime
          djtMergeMaterialEquipment
          djtShowDownloadPDF
          djtAllowsEmailCC
        }
        firstName
        middleName
        lastName
        goesBy
        imgUrl
        isGlobalAdmin
      }
      # nextToken
    }
  }
`;
export const getCurrentUserData = /* GraphQL */ `
  query getCurrentUserData(
    $loginEmail: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkTeamMemberTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByLoginEmail(
      loginEmail: $loginEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        loginEmail
        firstName
        middleName
        lastName
        imgUrl
        divisionId
      }
      nextToken
    }
  }
`;
export const getCurrentUserByEmail = /* GraphQL */ `
  query getCurrentUserIdByEmail(
    $loginEmail: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkTeamMemberTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByLoginEmail(
      loginEmail: $loginEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        middleName
        lastName
        imgUrl
      }
      nextToken
    }
  }
`;
export const getUploadedDocument = /* GraphQL */ `
  query getUploadedDocument($id: ID!) {
    getSpkUploadedDocumentTbl(id: $id) {
      id
      bucket
      identityId
      key
    }
  }
`;
export const getPersonalTopicId = /* GraphQL */ `
  query getPersonalTopicId(
    $filter: ModelSpkPubSubTopicTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpkPubSubTopicTbls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
    }
  }
`;
export const listStatementCategories = /* GraphQL */ `
  query listStatementCategories(
    $filter: ModelSpkStatementCategoryTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpkStatementCategoryTbls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        code
        isVisible
        isActive
      }
      nextToken
    }
  }
`;
export const getMessageStatusByStatus = /* GraphQL */ `
  query getMessageStatusByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPubSubMessageStatusTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pubsubMessageStatusByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTopicIdByTopicNameCode = /* GraphQL */ `
  query getTopicIdByTopicNameCode(
    $nameCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPubSubTopicTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topicIdByTopicNameCode(
      nameCode: $nameCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const getUploadedDocumentByKey = /* GraphQL */ `
  query getUploadedDocumentByKey(
    $key: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkUploadedDocumentTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uploadedDocumentByKey(
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        identityId
        key
        bucket
      }
      nextToken
    }
  }
`;
export const getContactByCompanyName = /* GraphQL */ `
  query getContactByCompanyName(
    $companyName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkContactTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactByCompanyName(
      companyName: $companyName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isMachineGenerated
        contactTypeId
        contactType {
          id
          name
          isActive
          createdAt
          updatedAt
        }
        firstName
        middleName
        lastName
        companyName
        phone
        address
        url
        notes
        isActive
        isDisplayed
        searchTags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPrimaryUnitOptions = /* GraphQL */ `
  query listPrimaryUnitOptions(
    $filter: ModelSpkPrimaryUnitTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpkPrimaryUnitTbls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        type {
          name
        }
      }
      nextToken
    }
  }
`;
export const getPrimaryUnit = /* GraphQL */ `
  query getPrimaryUnit($id: ID!) {
    getSpkPrimaryUnitTbl(id: $id) {
      id
      allSort
      code
      codeSortable
      rates {
        hourlyRate
        shiftRate
        dayRate
      }
      name
      year
      make
      model
      color
      tag
      vin
      serial
      irpiftaRegistered
      teamMemberId
      isMarried
      marriageId
      purchDate
      purchAmt
      purchNotes
      soldDate
      soldAmt
      soldNotes
      isActive
      billRateDay
      billRateHour
      typeId
      type {
        id
        name
        maintenanceName
      }
      subTypeId
      subType {
        id
        name
        maintenanceName
        priceCodeId
        priceCode {
          priceCode
          price
          rateType {
            description
            rateType
          }
        }
        qbExpenseCategoryId
        qbExpenseCategory {
          expenseCategory
          description
          QBId
        }
      }
      seatAxle
      cgvw
      unladWt
      isDOTReg
      reqCDLDriver
      unitStatus {
        statusId
        divisionId
        division {
          id
          prettyname
        }
        lastInspected
        lastInspectedAt
      }
      unitSmallImageId
      unitSmallImage {
        id
        unitId
        uploadedDateTime
        uploadedDocumentId
        uploadedDocument {
          id
          identityId
          key
          bucket
        }
        unitDocumentTypeId
        unitDocumentType {
          id
          name
          group
        }
        unitIdUnitDocumentTypeId
        active
        isActive
      }
      unitMediumImageId
      unitMediumImage {
        id
        unitId
        uploadedDateTime
        uploadedDocumentId
        uploadedDocument {
          id
          identityId
          key
          bucket
        }
        unitDocumentTypeId
        unitDocumentType {
          id
          name
          group
        }
        unitIdUnitDocumentTypeId
        active
        isActive
      }
      currentLocation
      isInOperation
      canTransfer
      isInspectedDaily
      requiresOperator
      requiresDriver
      auxillaryUnits {
        items {
          id
          # spkAuxillaryUnitTblID
          # spkPrimaryUnitTblID
          spkAuxillaryUnitTbl {
            id
            allSort
            name
            description
            isBillable
            rateDescription
            price
            priceCodeId
            isMaterialExpense
            isActive
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      qbExpenseCategoryId
      qbExpenseCategoryNumber
      qbExpenseCategory
      odometer
      createdAt
      updatedAt
    }
  }
`;
export const primaryUnitsByMarriageId = /* GraphQL */ `
  query PrimaryUnitsByMarriageId(
    $marriageId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPrimaryUnitTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    primaryUnitsByMarriageId(
      marriageId: $marriageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
      }
      nextToken
    }
  }
`;
export const listUnitMarriagesOptions = /* GraphQL */ `
  query listUnitMarriagesOptions(
    $filter: ModelSpkUnitMarriageTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpkUnitMarriageTbls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;
export const listPrimaryUnitCanTransfer = /* GraphQL */ `
  query listPrimaryUnitCanTransfer(
    $filter: ModelSpkPrimaryUnitTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpkPrimaryUnitTbls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
      }
      nextToken
    }
  }
`;
export const listTeamMembersAvailableNotAtDivision = /* GraphQL */ `
  query listTeamMembersAvailableNotAtDivision(
    $filter: ModelSpkTeamMemberTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpkTeamMemberTbls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        divisionId
        division {
          prettyname
        }
      }
      nextToken
    }
  }
`;
export const listCompaniesByDivisionId = /* GraphQL */ `
  query listCompaniesByDivisionId(
    $divisionId: ID!
    $contactCompany: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTClientTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtClientsByDivisionId(
      divisionId: $divisionId
      contactCompany: $contactCompany
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contactCompany
        contactName
        isActive
        staleAfterDate
      }
      nextToken
    }
  }
`;
export const getContactCompanyByCompanyId = /* GraphQL */ `
  query getContactCompanyByCompanyId($id: ID!) {
    getSpkDJTClientTbl(id: $id) {
      id
      contactCompany
      contactName
      contactEmail
      contactPhone
      # contactLocation
      # contactBillingEmail
    }
  }
`;
export const allPrimaryUnitsForInspection = /* GraphQL */ `
  query allPrimaryUnitsForInspection(
    $allSort: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPrimaryUnitTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allPrimaryUnits(
      allSort: $allSort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        code
        codeSortable
        type {
          name
        }
        subType {
          name
          # priceCode {
          #   name
          #   price
          #   priceCode
          #   rateType {
          #     description
          #     rateType
          #   }
          # }
        }
        unitStatus {
          divisionId
          division {
            prettyname
          }
          inService
          lastInspected
          lastInspectedAt
          status {
            statusQuery
            isAvailable
            status
            id
          }
          unitId
          inspectionQueue {
            items {
              code
              createdAt
              createdById
              createdOn
              unitId
              claimedById
              claimedOn
              completedOn
              inspectedById
              updatedAt
              claimedBy {
                firstName
                goesBy
                lastName
                middleName
              }
              createdBy {
                firstName
                goesBy
                lastName
                middleName
              }
              inspectedBy {
                firstName
                goesBy
                lastName
                middleName
              }
              inspectionId
              inspectedDateTime
              inspection {
                id
                failureCount
                completedInspection {
                  items {
                    didPass
                    failedReason
                  }
                }
              }
            }
          }
        }
      }
      # original
      # items {
      #   codeSortable
      #   unitStatus {
      #     divisionId
      #     division {
      #       prettyname
      #     }
      #     inService
      #     lastInspected
      #     status {
      #       statusQuery
      #       isAvailable
      #       status
      #       id
      #     }
      #     unitId
      #   }
      #   code
      #   type {
      #     name
      #   }
      #   subType {
      #     name
      #   }
      # }
      nextToken
    }
  }
`;
export const listAllPrimaryUnits = /* GraphQL */ `
  query listAllPrimaryUnits(
    $allSort: String!
    $filter: ModelSpkPrimaryUnitTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allPrimaryUnits(
      allSort: $allSort
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        codeSortable
        currentLocation
        unitStatus {
          updatedAt
          divisionId
          division {
            prettyname
          }
          inService
          lastInspected
          status {
            isAvailable
            status
            id
          }
          unitId
        }
        rates {
          dayRate
          hourlyRate
          shiftRate
        }
        type {
          id
          name
        }
        subType {
          id
          name
          priceCode {
            name
            price
            priceCode
            rateType {
              description
              rateType
            }
          }
        }
        requiresOperator
	      requiresDriver
        unitSmallImageId
        unitSmallImage {
          uploadedDocument {
            id
            identityId
            key
            bucket
          }
        }
        unitMediumImageId
        unitMediumImage {
          uploadedDocument {
            id
            identityId
            key
            bucket
          }
        }
        isActive
        irpiftaRegistered
        odometer
      }
      nextToken
    }
  }
`;
export const listAllPrimaryUnitCodes = /* GraphQL */ `
  query listAllPrimaryUnits(
    $allSort: String!
    $filter: ModelSpkPrimaryUnitTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allPrimaryUnits(
      allSort: $allSort
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        codeSortable        
      }
      nextToken
    }
  }
`;
export const listInspectedUnitsByDivisionByDate = /* GraphQL */ `
  query listInspectedUnitsByDivisionByDate(
    $divisionDate: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPrimaryUnitInspectionTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inspectedUnitsByDivisionByDate(
      divisionDate: $divisionDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdById
        createdBy {
          firstName
          goesBy
          lastName
          middleName
        }
        inspectedById
        inspectedBy {
          firstName
          goesBy
          lastName
          middleName
        }
        timeToInspect
        unitId
        unit {
          code
          rates {
            dayRate
            hourlyRate
            shiftRate
          }
          type {
            name
          }
          subType {
            name
            priceCode {
              price
              rateType {
                description
                rateType
              }
            }
          }
          requiresOperator
	        requiresDriver
        }
        status {
          status
          isAvailable
        }
        id
        createdAt
      }
      nextToken
    }
  }
`;
export const billableRatesByDivisionId = /* GraphQL */ `
  query billableRatesByDivisionId(
    $divisionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTJobClassBillableRateTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    billableRatesByDivisionId(
      divisionId: $divisionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        className
        hourlyRate
        overtimeRate
        divisionClassNameHash
      }
      nextToken
    }
  }
`;
export const jobClassRatesByDivisionId = /* GraphQL */ `
  query jobClassRatesByDivisionId(
    $divisionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTJobClassRateTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobClassRatesByDivisionId(
      divisionId: $divisionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        divisionPosition
        className
        appDisplayName
        invoiceDisplayName
        unionDisplayName
        hourlyRate
        overtimeRate
        isActive
        isHiddenByDefault
      }
      nextToken
    }
  }
`;
export const fetchJobClassById = /* GraphQL */ `
  query fetchJobClassById($divisionPosition: String!) {
    getSpkDJTJobClassRateTbl(divisionPosition: $divisionPosition) {
      divisionPosition
      className
      appDisplayName
      invoiceDisplayName
      unionDisplayName
      hourlyRate
      overtimeRate
      isActive
    }
  }
`;
export const listAuxillaryUnitsByUnitId = /* GraphQL */ `
  query listAuxillaryUnitsByUnitId($id: ID!) {
    getSpkPrimaryUnitTbl(id: $id) {
      auxillaryUnits {
        items {
          id
          spkAuxillaryUnitTbl {
            id
            isActive
            isBillable
            name
            rateDescription
            description
            isMaterialExpense
          }
        }
      }
    }
  }
`;
export const listAuxillaryUnitOptionsByUnitId = /* GraphQL */ `
  query listAuxillaryUnitOptionsByUnitId($id: ID!) {
    getSpkPrimaryUnitTbl(id: $id) {
      auxillaryUnits {
        items {
          spkAuxillaryUnitTbl {
            id
            name
          }
        }
      }
    }
  }
`;
export const billableMaterialsByDivisionId = /* GraphQL */ `
  query billableMaterialsByDivisionId(
    $divisionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTDefaultBillableMaterialTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    billableMaterialsByDivisionId(
      divisionId: $divisionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        notes
        price
        quantity
        isActive
        doShowOnLoad
      }
      nextToken
    }
  }
`;
export const getAuxillaryUnitByUnitId = /* GraphQL */ `
  query getAuxillaryUnitByUnitId($id: ID!) {
    getSpkAuxillaryUnitTbl(id: $id) {
      id
      name
      description
      isBillable
      rateDescription
      isMaterialExpense
      price
      priceCode {
        name
        priceCode
        rateType {
          description
          rateType
        }
        price
      }
    }
  }
`;
export const listDJTsByDivisionId = /* GraphQL */ `
  query listDJTsByDivisionId(
    $djtDivisionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtByDivisionId(
      djtDivisionId: $djtDivisionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attachableObjId
        teamMember {
          id
          firstName
          middleName
          lastName
          goesBy
        }
        djtClient {
          contactCompany
          contactName
          qbId
          qbSandboxId
          qbCompany
        }
        djtLaborTotal
        djtEquipmentTotal
        djtMaterialTotal
        djtDate
        djtNotes
        djtTotalBilled
        djtPO {
          po
          poDescription
        }
        djtWO
        djtWODescription
      }
      nextToken
    }
  }
`;
export const getDJTChildrenForDeletion = /* GraphQL */ `
  query getDJTChildrenForDeletion($id: ID!) {
    getSpkDailyJobTicketTbl(id: $id) {
      djtMaterial {
        items {
          id
        }
      }
      djtEquipment {
        items {
          id
        }
      }
      djtLabor {
        items {
          id
        }
      }
    }
  }
`;
export const allTeamMembersSortableByLastName = /* GraphQL */ `
  query AllTeamMembersSortableByLastName(
    $allSort: String!
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkTeamMemberTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allTeamMembersSortableByLastName(
      allSort: $allSort
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        isActive
        isolvedEmail
        isERPUser
        isOnboarded
        employmentCategoryType
        firstName
        middleName
        lastName
        goesBy
        billableDivisionJobClass {
          divisionPosition
          className
          appDisplayName
          hourlyRate
          overtimeRate
        }
        goesBy
        imgUrl
        divisionId
        division {
          prettyname
        }
      }
    }
  }
`;
export const allTeamMemberIds = /* GraphQL */ `
  query allTeamMemberIds(
    $allSort: String!
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkTeamMemberTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allTeamMembersSortableByLastName(
      allSort: $allSort
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
      }
    }
  }
`;
export const getDailyJobTicketByIdForApproval = /* GraphQL */ `
  query getDailyJobTicketByIdForApproval($id: ID!) {
    getSpkDailyJobTicketTbl(id: $id) {
      id
      createDateTime
      djtBillingNotes
      djtClient {
        qbCity
        qbZip
        qbStreetAddress
        qbState
        qbPhone
        qbId
        qbSandboxId
        qbEmail
        qbDisplayName
        qbCustomerType
        qbCustomer
        qbCountry
        qbCompany
        qbClassId
        qbClassName
      }
      djtDate
      djtNonContractPO
      djtNotes
      djtLocation
      doNotBill
      doNotBillDescription
      specialInstructions
      djtWO
      djtWODescription
      djtTotalBilled
      isClosed
      isExported
      exportedById
      exportedDateTime
      isPaid
      isSubmitValid
      isSubmitted
      teamMemberId
      teamMember {
        firstName
        middleName
        lastName
        goesBy
      }
      djtPOid
      djtPO {
        po
        poDescription
      }
      djtMaterialTotal
      djtLaborTotal
      djtEquipmentTotal
      authorizedById
      autorizedDateTime
      authorizedBy {
        firstName
        middleName
        lastName
      }
      attachableObjId
      showLaborHours
      mergeMaterialEquipment
    }
  }
`;
export const getDailyJobTicketByIdForExport = /* GraphQL */ `
  query getDailyJobTicketByIdForExport($id: ID!) {
    getSpkDailyJobTicketTbl(id: $id) {
      id
      createDateTime
      djtBillingNotes
      djtClient {
        qbCity
        qbZip
        qbStreetAddress
        qbState
        qbPhone
        qbId
        qbSandboxId
        qbEmail
        qbDisplayName
        qbCustomerType
        qbCustomer
        qbCountry
        qbCompany
      }
      djtDate
      djtNonContractPO
      djtNotes
      djtLocation
      doNotBill
      doNotBillDescription
      specialInstructions
      djtWO
      djtWODescription
      djtTotalBilled
      isClosed
      isExported
      exportedById
      exportedDateTime
      isPaid
      isSubmitValid
      isSubmitted
      teamMemberId
      teamMember {
        firstName
        middleName
        lastName
        goesBy
      }
      djtPOid
      djtPO {
        po
        poDescription
      }
      djtMaterialTotal
      djtLaborTotal
      djtEquipmentTotal
      authorizedById
      autorizedDateTime
      authorizedBy {
        firstName
        middleName
        lastName
      }
      attachableObjId
      mergeMaterialEquipment
    }
  }
`;
export const listDJTsByStatusId = /* GraphQL */ `
  query listDJTsByStatusId(
    $currentStatusId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtByCurrentStatusId(
      currentStatusId: $currentStatusId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        djtDivisionId
        attachableObjId
        teamMember {
          id
          firstName
          middleName
          lastName
          goesBy
        }
        djtClient {
          id
          contactCompany
          contactName
          contactPhone
          contactEmail
          qbId
          qbSandboxId
          qbCompany
          qbClassId
          qbClassName
        }
        djtLaborTotal
        djtEquipmentTotal
        djtMaterialTotal
        djtDate
        djtNotes
        djtTotalBilled
        djtPO {
          po
          poDescription
        }
        djtWO
        djtWODescription
        attachableObjId
        doNotBill
        doNotBillDescription
        showLaborHours
        specialInstructions
      }
    }
  }
`;
export const getStatusCodeById = /* GraphQL */ `
  query getStatusCodeById($id: ID!) {
    getSpkDJTCurrentStatus(id: $id) {
      id
      allSort
      order
      status
      statusCode
      createdAt
      updatedAt
    }
  }
`;
export const getStatusByStatusCode = /* GraphQL */ `
  query getStatusByStatusCode(
    $statusCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTCurrentStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtStatusByStatusCode(
      statusCode: $statusCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
    }
  }
`;
export const getPubSubCategoryByCode = /* GraphQL */ `
  query getPubSubCategoryByCode(
    $code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPubSubTopicCategoryTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pubsubCategoryByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        desc
      }
      nextToken
    }
  }
`;
export const getMessagesByTopicId = /* GraphQL */ `
  query getMessagesByTopicId(
    $topicId: ID!
    $sentAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPubSubMessageTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pubsubMessagesByTopicId(
      topicId: $topicId
      sentAt: $sentAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        message
        messageJSON
        fromId
        from {
          id
          loginEmail
          firstName
          middleName
          lastName
          goesBy
        }
        sentAt
        publisherId
        publisherType
        statusId
        status {
          id
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeamMemberNameById = /* GraphQL */ `
  query getTeamMemberNameById($id: ID!) {
    getSpkTeamMemberTbl(id: $id) {
      id
      firstName
      middleName
      lastName
      goesBy
      imgUrl
    }
  }
`;
export const getTeamMemberNameAvatarById = /* GraphQL */ `
  query getTeamMemberNameById($id: ID!) {
    getSpkTeamMemberTbl(id: $id) {
      id
      firstName
      middleName
      lastName
      goesBy
      imgUrl
    }
  }
`;
export const cognitoGroupIdByGroupName = /* GraphQL */ `
  query CognitoGroupIdByGroupName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkCognitoGroupTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cognitoGroupIdByGroupName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const menuItemsByCognitoGroupId = /* GraphQL */ `
  query MenuItemsByCognitoGroupId(
    $cognitoGroupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkNavCognitoGroupTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsByCognitoGroupId(
      cognitoGroupId: $cognitoGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoGroupId
        menuItemId
      }
      nextToken
    }
  }
`;
export const getNavMenuByMenuId = /* GraphQL */ `
  query getNavMenuByMenuId($id: ID!) {
    getSpkNavMenuTbl(id: $id) {
      id
      menuItem
      menuClass
      linkTo
      menuAltText
      displayOrder
    }
  }
`;
export const clientContactsByClientId = /* GraphQL */ `
  query clientContactsByClientId(
    $clientId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTClientContactTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientContactsByClientId(
      clientId: $clientId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        name
        email
        emailVerified
        phone
      }
      nextToken
    }
  }
`;
export const getDailyJobTicketById = /* GraphQL */ `
  query getDailyJobTicketById($id: ID!) {
    getSpkDailyJobTicketTbl(id: $id) {
      djtNonContractPO
      djtPOid
      djtPO {
        po
      }
      attachedContractId
      attachedContract {
        name
        createDateTime
        id
        documentId
      }
      parentId
      djtNotes
      djtClientId
      djtClientContactId
      djtClient {
        contactCompany
        qbCompany
        qbEmail
        contactName
        contactPhone
        contactEmail
        id
      }
      djtLabor(filter: {isActive: {eq: true}}) {
        items {
          id
          teamMemberId
          teamMember {
            id
            goesBy
            firstName
            lastName
          }
          customLaborName
          jobClass {
            className
            overtimeRate
            hourlyRate
            appDisplayName
          }
          jobClassId
          startTime
          finishTime
          perDiemAmount
          totalPrice
          lunchDuration
          createdAt
        }
      }
      djtLaborTotal
      djtEquipment(filter: {isActive: {eq: true}}) {
        items {
          id
          unitCode
          requiresDriver
          drivers {
            items {
              drivenById
              drivenBy {
                goesBy
                firstName
                middleName
                lastName
              }
            }
          }
          requiresOperator
          operators {
            items {
              operatedById
              operatedBy {
                goesBy
                firstName
                middleName
                lastName
              }
            }
          }
          billableDescription
          isAuxillary
          isCustom
          isPrimary
          isAuxChild
          quantity
	        auxChildOfParentId
          unitId
          totalBilled
          statusId
          status {
            status
          }
        }
      }
      djtEquipmentTotal
      djtMaterial(filter: {isActive: {eq: true}}) {
        items {
          description
          id
          quantity
          totalPrice
          isCustom
          isDefault
        }
      }
      djtMaterialTotal
      isAuthorized
      isClosed
      isPushedToQB
      isSubmitted
      isSubmitValid
      lastStep
      djtBillingNotes
      currentStatusId
      currentStatus {
        status
      }
      djtClientAuthName
      djtClientAuthSignatureId
      djtDate
      djtLocation
      djtPendingPO
      djtSupervisorAuthName
      djtSupervisorAuthSignatureId
      djtSupervisorAuthSignature {
        id
        identityId
        key
        bucket
      }
      djtTotalBilled
      djtWO
      djtWODescription
      doNotBill
      doNotBillDescription
      id
      specialInstructions
      djtClientAuthSignature {
        bucket
        id
        identityId
        key
      }
      bouncedAdminReason
	    bouncedSupervisorRespone
      mergeMaterialEquipment
      showLaborHours
    }
  }
`;
export const allAuxillaryUnitsSortableByName = /* GraphQL */ `
  query AllAuxillaryUnitsSortableByName(
    $allSort: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkAuxillaryUnitTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allAuxillaryUnitsSortableByName(
      allSort: $allSort
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        name
        description
        isBillable
        rateDescription
        price
        isMaterialExpense
        isActive
      }
      nextToken
    }
  }
`;
export const listNavMenuByTeamMemberId = /* GraphQL */ `
  query listNavMenuByTeamMemberId(
    $tmId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkNavMenuUserTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    navMenuItemsByTeamMemberId(
      tmId: $tmId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        navMenu {
          id
          isPhoneSafe
          menuItem
          menuClass
          menuAltText
          linkTo
          displayOrder
        }
      }
    }
  }
`;
export const unitOperatorsByDjtId = /* GraphQL */ `
  query unitOperatorsByDjtId(
    $djtId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTOperatorTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    operatorByDjtId(
      djtId: $djtId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        unitId
        djtEquipmentId
        operatedBy {
          firstName
          goesBy
          lastName
          middleName
        }
      }
      nextToken
    }
  }
`;
export const unitDriversByDjtId = /* GraphQL */ `
  query unitDriversByDjtId(
    $djtId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTDriverTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    driverByDjtId(
      djtId: $djtId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        unitId
        djtEquipmentId
        drivenBy {
          firstName
          goesBy
          lastName
          middleName
        }
      }
      nextToken
    }
  }
`;
export const getClientContactByClientContactId = /* GraphQL */ `
  query getClientContactByClientContactId($id: ID!) {
    getSpkDJTClientContactTbl(id: $id) {
      id
      clientId
      name
      email
      emailVerified
      phone
    }
  }
`;
export const getClientBillingEmail = /* GraphQL */ `
  query getClientBillingEmail($id: ID!) {
    getSpkDJTClientTbl(id: $id) {
      clientBillingEmail
    }
  }
`;
export const djtDuplicatesByParentId = /* GraphQL */ `
  query djtDuplicatesByParentId(
    $duplicateOfId: ID!
    $djtDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtDuplicatesByParentId(
      duplicateOfId: $duplicateOfId
      djtDate: $djtDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        djtClient {
          qbCompany
          contactCompany
          id
        }
        djtDate
        djtNotes
        teamMemberId
      }
      nextToken
    }
  }
`;
export const listDjtChildrenByParentId = /* GraphQL */ `
  query listDjtChildrenByParentId(
    $duplicateOfId: ID!
    $djtDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtDuplicatesByParentId(
      duplicateOfId: $duplicateOfId
      djtDate: $djtDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        djtClient {
          qbCompany
          contactCompany
          id
        }
        djtDate
        djtNotes
        teamMemberId
      }
      nextToken
    }
  }
`;
export const djtDuplicatesLaborMaterialEquipmentByParentId = /* GraphQL */ `
  query djtDuplicatesLaborMaterialEquipmentByParentId(
    $duplicateOfId: ID!
    $djtDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtDuplicatesByParentId(
      duplicateOfId: $duplicateOfId
      djtDate: $djtDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        djtLabor {
          items {
            id
          }
        }
        djtEquipment {
          items {
            id
          }
        }
        djtMaterial {
          items {
            id
          }
        }
      }
    }
  }
`;
export const djtSearchByDivision = /* GraphQL */ `
  query djtSearchByDivision(
    $djtDivisionId: ID!
    $djtDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtByDivisionId(
      djtDivisionId: $djtDivisionId
      djtDate: $djtDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isParent
        parentId
        isDuplicate
        duplicateOfId
        djtClient {
          qbCompany
          contactCompany
          id
        }
        teamMember {
          goesBy
          firstName
          middleName
          lastName
        }
        djtDate
        djtNotes
        teamMemberId
      }
      nextToken
    }
  }
`;
export const djtSearchByClient = /* GraphQL */ `
  query djtSearchByClient(
    $djtClientId: ID!
    $djtDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtByClientId(
      djtClientId: $djtClientId
      djtDate: $djtDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isParent
        parentId
        isDuplicate
        duplicateOfId
        djtClient {
          qbCompany
          contactCompany
          id
        }
        teamMember {
          goesBy
          firstName
          middleName
          lastName
        }
        djtDate
        djtNotes
        teamMemberId
      }
      nextToken
    }
  }
`;
export const djtSearchByDivisionTeamMember = /* GraphQL */ `
  query djtSearchByDivisionTeamMember(
    $djtDivisionTeamMember: String!
    $djtDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtByDivisionTeamMemberComposite(
      djtDivisionTeamMember: $djtDivisionTeamMember
      djtDate: $djtDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isParent
        parentId
        isDuplicate
        duplicateOfId
        djtClient {
          qbCompany
          contactCompany
          id
        }
        teamMember {
          goesBy
          firstName
          middleName
          lastName
        }
        djtDate
        djtNotes
        teamMemberId
      }
      nextToken
    }
  }
`;
export const djtSearchByDivisionTeamMemberClient = /* GraphQL */ `
  query djtSearchByDivisionTeamMemberClient(
    $djtDivisionTeamMemberClient: String!
    $djtDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtByDivisionTeamMemberClientComposite(
      djtDivisionTeamMemberClient: $djtDivisionTeamMemberClient
      djtDate: $djtDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isParent
        parentId
        isDuplicate
        duplicateOfId
        djtClient {
          qbCompany
          contactCompany
          id
        }
        teamMember {
          goesBy
          firstName
          middleName
          lastName
        }
        djtDate
        djtNotes
        teamMemberId
      }
      nextToken
    }
  }
`;
export const djtSearchByDivisionClient = /* GraphQL */ `
  query djtSearchByDivisionClient(
    $djtDivisionClient: String!
    $djtDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtByDivisionIdClientId(
      djtDivisionClient: $djtDivisionClient
      djtDate: $djtDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isParent
        parentId
        isDuplicate
        duplicateOfId
        djtClient {
          qbCompany
          contactCompany
          id
        }
        teamMember {
          goesBy
          firstName
          middleName
          lastName
        }
        djtDate
        djtNotes
        teamMemberId
      }
      nextToken
    }
  }
`;
export const djtSearchByDivisionTeamMemberStatus = /* GraphQL */ `
  query djtSearchByDivisionTeamMemberStatus(
    $djtDivisionTeamMemberStatus: String!
    $djtDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtByDivisionTeamMemberStatusComposite(
      djtDivisionTeamMemberStatus: $djtDivisionTeamMemberStatus
      djtDate: $djtDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isParent
        parentId
        isDuplicate
        duplicateOfId
        djtClient {
          qbCompany
          contactCompany
          id
        }
        teamMember {
          goesBy
          firstName
          middleName
          lastName
        }
        djtLaborTotal
        djtEquipmentTotal
        djtMaterialTotal
        djtTotalBilled
        djtDate
        djtNotes
        djtLocation
        teamMemberId
        djtDivisionId
        bouncedAdminReason
	      bouncedSupervisorRespone
        currentStatusId
        currentStatus {
          order
          status
          statusCode
        }
      }
      nextToken
    }
  }
`;
export const djtSearchByDivisionTeamMemberClientStatus = /* GraphQL */ `
  query djtSearchByDivisionTeamMemberClientStatus(
    $djtDivisionTeamMemberClientStatus: String!
    $djtDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtByDivisionTeamMemberClientStatus(
      djtDivisionTeamMemberClientStatus: $djtDivisionTeamMemberClientStatus
      djtDate: $djtDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isParent
        parentId
        isDuplicate
        duplicateOfId
        djtClient {
          qbCompany
          contactCompany
          id
        }
        teamMember {
          goesBy
          firstName
          middleName
          lastName
        }
        djtDate
        djtNotes
        djtLaborTotal
        djtEquipmentTotal
        djtMaterialTotal
        djtTotalBilled
        djtLocation
        teamMemberId
        djtDivisionId
        bouncedAdminReason
	      bouncedSupervisorRespone
        currentStatusId
        currentStatus {
          order
        }
      }
      nextToken
    }
  }
`;
export const djtSearchByDivisionClientStatus = /* GraphQL */ `
  query djtSearchByDivisionClientStatus(
    $djtDivisionClientStatus: String!
    $djtDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtByDivisionClientStatus(
      djtDivisionClientStatus: $djtDivisionClientStatus
      djtDate: $djtDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isParent
        parentId
        isDuplicate
        duplicateOfId
        djtClient {
          qbCompany
          contactCompany
          id
        }
        teamMember {
          goesBy
          firstName
          middleName
          lastName
        }
        djtDate
        djtNotes
        djtLaborTotal
        djtEquipmentTotal
        djtMaterialTotal
        djtTotalBilled
        djtLocation
        teamMemberId
        djtDivisionId
        bouncedAdminReason
	      bouncedSupervisorRespone
        currentStatusId
        currentStatus {
          order
          status
          statusCode
        }
      }
      nextToken
    }
  }
`;
export const djtSearchByDivisionStatus = /* GraphQL */ `
  query djtSearchByDivisionStatus(
    $djtDivisionStatus: String!
    $djtDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtByDivisionStatusComposite(
      djtDivisionStatus: $djtDivisionStatus
      djtDate: $djtDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isParent
        parentId
        isDuplicate
        duplicateOfId
        djtClient {
          qbCompany
          contactCompany
          id
        }
        teamMember {
          goesBy
          firstName
          middleName
          lastName
        }
        djtDate
        djtNotes
        djtLaborTotal
        djtEquipmentTotal
        djtMaterialTotal
        djtTotalBilled
        djtLocation
        teamMemberId
        djtDivisionId
        bouncedAdminReason
	      bouncedSupervisorRespone
        currentStatusId
        currentStatus {
          order
          status
          statusCode
        }
      }
      nextToken
    }
  }
`;
export const listDjtIdsByParentId = /* GraphQL */ `
  query listDjtIdsByParentId(
    $parentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtsByParentId(
      parentId: $parentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamMemberId
        djtDate
      }
    }
  }
`;
export const getDJTParentalStatus = /* GraphQL */ `
  query getDJTParentalStatus($id: ID!) {
    getSpkDailyJobTicketTbl(id: $id) {
      id
      parentId
      isParent
      isDuplicate
      duplicateOfId
    }
  }
`;
export const listLaborByDJTIds = /* GraphQL */ `
  query listLaborByDJTIds(
    $djtId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTLaborTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    laborByDJTId(
      djtId: $djtId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        djtId
        isActive
      }
    }
  }
`;
export const listEquipmentByDJTId = /* GraphQL */ `
  query listEquipmentByDJTId(
    $djtId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTEquipmentTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    equipmentByDJTId(
      djtId: $djtId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        djtId
        isActive
      }
    }
  }
`;
export const listMaterialByDJTId = /* GraphQL */ `
  query listMaterialByDJTId(
    $djtId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTMaterialTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    materialsByDJTId(
      djtId: $djtId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        djtId
        isActive
      }
    }
  }
`;
export const listClientPosByDivisionClient = /* GraphQL */ `
  query listClientPosByDivisionClient(
    $poDivisionClient: String!
    $po: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTPOTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    posByDivisionClient(
      poDivisionClient: $poDivisionClient
      po: $po
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        po
        poDescription
        poNotes
        isClosed
        isActive
      }
    }
  }
`;
export const getClientPoById = /* GraphQL */ `
  query getClientPoById($id: ID!) {
    getSpkDJTPOTbl(id: $id) {
      id
      po
      isActive
      isClosed
      poDescription
      poNotes
    }
  }
`;
export const listClientContractsByClientId = /* GraphQL */ `
  query listClientContractsByClientId(
    $clientId: ID!
    $createDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkClientContractTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientContractsByClientId(
      clientId: $clientId
      createDateTime: $createDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createDateTime
        clientId
        documentId
        createdById
        createdBy {
          id
          firstName
          middleName
          lastName
          goesBy
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDjtsByParentId = /* GraphQL */ `
  query listDjtsByParentId(
    $parentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDailyJobTicketTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    djtsByParentId(
      parentId: $parentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        djtDivisionId
        djtClient {
          qbCompany
          contactCompany
          id
        }
        djtLabor {
          items {
            djtId
            id
          }
        }
        djtMaterial {
          items {
            djtId
            id
          }
        }
        djtEquipment {
          items {
            djtId
            id
          }
        }
      }
    }
  }
`;
export const listUnitOperatorsByEquipmentId = /* GraphQL */ `
  query listUnitOperatorsByEquipmentId(
    $djtEquipmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTOperatorTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    operatorByDJTEquipmentId(
      djtEquipmentId: $djtEquipmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        operatedById
        operatedBy {
          firstName
          middleName
          lastName
          goesBy
        }
      }
    }
  }
`;
export const listUnitDriversByEquipmentId = /* GraphQL */ `
  query listUnitDriversByEquipmentId(
    $djtEquipmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTDriverTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    driverByDJTEquipmentId(
      djtEquipmentId: $djtEquipmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        drivenById
        drivenBy {
          id
          firstName
          middleName
          lastName
          goesBy
        }
      }
    }
  }
`;
export const listUnitInspectionPoints = /* GraphQL */ `
  query listUnitInspectionPoints($id: ID!) {
    getSpkPrimaryUnitTbl(id: $id) {
      subType {
        name
        inspectionsPoints {
          items {
            spkUnitInspectionPointTbl {
              order
              point
              topic
              id
            }
            id
          }
        }
      }
    }
  }
`;
export const listAllUnitStatusOptions = /* GraphQL */ `
  query listAllUnitStatusOptions(
    $allSort: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPrimaryUnitStatusOptionTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allUnitStatusOptions(
      allSort: $allSort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        status
        adminOnly
        isAvailable
        doAlert
        alert
        order
        statusQuery
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const allQueuedPrimaryUnitInspections = /* GraphQL */ `
  query allQueuedPrimaryUnitInspections(
    $allSort: String!
    $active: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPrimaryUnitInspectionQueueTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allPrimaryUnitInspectionQueue(
      allSort: $allSort
      active: $active
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        unitStatus {
          status {
            status
            statusQuery
            id
          }
          statusId
        }
        claimedById
        claimedBy {
          firstName
          middleName
          lastName
          goesBy
        }
        claimedOn
        completedOn
        createdById
        createdBy {
          firstName
          middleName
          lastName
          goesBy
        }
        createdOn
        divisionId
        inspectedById
        inspectedDateTime
        inspectionId
        unitId
        unit {
          subType {
            id
            name
          }
          type {
            name
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const allUnitDocumentTypes = /* GraphQL */ `
  query allUnitDocumentTypes(
    $allSort: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkUnitDocumentTypeTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allUnitDocumentTypes(
      allSort: $allSort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        name
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUnitDocumentTypesByGroup = /* GraphQL */ `
  query listUnitDocumentTypesByGroup(
    $group: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkUnitDocumentTypeTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unitDocumentTypesByGroup(
      group: $group
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        name
        group
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUnitDocumentsByUnitId = /* GraphQL */ `
  query listUnitDocumentsByUnitId(
    $unitId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkUnitDocumentTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unitDocumentsByUnitId(
      unitId: $unitId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        unitId
        uploadedDocumentId
        uploadedDocument {
          id
          identityId
          key
          bucket
          createdAt
          updatedAt
        }
        unitDocumentTypeId
        unitDocumentType {
          id
          allSort
          name
          group
          isActive
          createdAt
          updatedAt
        }
        notes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDocumentsByUnitIdUnitDocumentTypeId = /* GraphQL */ `
  query listDocumentsByUnitIdUnitDocumentTypeId(
    $unitIdUnitDocumentTypeId: String!
    $uploadedDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkUnitDocumentTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unitDocumentsByUnitIdUnitDocumentTypeId(
      unitIdUnitDocumentTypeId: $unitIdUnitDocumentTypeId
      uploadedDateTime: $uploadedDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        unitId
        uploadedDateTime
        uploadedDocumentId
        uploadedDocument {
          id
          identityId
          key
          bucket
          createdAt
          updatedAt
        }
        unitDocumentTypeId
        unitDocumentType {
          id
          allSort
          name
          isActive
          createdAt
          updatedAt
        }
        unitIdUnitDocumentTypeId
        notes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUnitDocumentTypes = /* GraphQL */ `
  query listUnitDocumentTypes(
    $filter: ModelSpkUnitDocumentTypeTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpkUnitDocumentTypeTbls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        name
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUnitSubTypeInspectionPoints = /* GraphQL */ `
  query getUnitSubTypeInspectionPoints($id: ID!) {
    getSpkUnitSubTypeTbl(id: $id) {
      id
      inspectionsPoints {
        items {
          spkUnitInspectionPointTbl {
            id
            order
            topic
            point
          }
        }
      }
    }
  }
`;
export const getPOById = /* GraphQL */ `
  query getPOById($id: ID!) {
    getSpkDJTPOTbl(id: $id) {
      id
      divisionId
      djtClientId
      po
      poDivisionClient
      poLocation
      divisionClientPOHash
      poDescription
      poNotes
      isClosed
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const unitCodeByQrCodeValue = /* GraphQL */ `
  query unitCodeByQrCodeValue(
    $qrCodeValue: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkUnitQrMapTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unitCodeByQrCodeValue(
      qrCodeValue: $qrCodeValue
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        unitCode
        qrCodeValue
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const primaryUnitByCode = /* GraphQL */ `
  query primaryUnitByCode(
    $code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPrimaryUnitTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    primaryUnitByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        code
        codeSortable
        rates {
          unitCode
          unitId
          unit {
            id
            allSort
            code
            codeSortable
            name
            year
            make
            model
            color
            tag
            vin
            serial
            teamMemberId
            isMarried
            marriageId
            purchDate
            purchAmt
            purchNotes
            soldDate
            soldAmt
            soldNotes
            isActive
            billRateDay
            billRateHour
            typeId
            subTypeId
            seatAxle
            cgvw
            unladWt
            isDOTReg
            reqCDLDriver
            currentLocation
            isInOperation
            canTransfer
            isInspectedDaily
            requiresOperator
            requiresDriver
            qbExpenseCategoryId
            qbExpenseCategoryNumber
            qbExpenseCategory
            iconId
            createdAt
            updatedAt
          }
          hourlyRate
          shiftRate
          dayRate
          createdAt
          updatedAt
        }
        name
        year
        make
        model
        color
        tag
        vin
        serial
        irpiftaRegistered
        teamMemberId
        primaryDriver {
          id
          allSort
          loginEmail
          firstName
          middleName
          lastName
          goesBy
          dob
          hireDate
          billableDivisionJobClassId
          billableDivisionJobClass {
            divisionPosition
            className
            hourlyRate
            overtimeRate
            divisionId
            isActive
            createdAt
            updatedAt
          }
          djtHideByDefault
          divisionId
          division {
            id
            regionId
            isFeeder
            prettyId
            prettyname
            addStreet
            addCity
            addState
            addZipcode
            superVisorId
            asstSupervisorId
            djtCanPerDiem
            djtPerDiemAmt
            djtTrackLabor
            djtTrackEquipment
            djtTrackMaterial
            djtRentalMsgActive
            djtRentalMsg
            djtAllowsWO
            djtOneClient
            isActive
            isSafeInspection
            createdAt
            updatedAt
          }
          termDate
          imgUrl
          avatarId
          memberOf
          badgeNumber
          employeeNumber
          leaveRequests {
            nextToken
          }
          addStreet
          addCity
          addState
          addZipcode
          phoneNum
          isActive
          emergcyContName
          emergcyContPhone
          canTransfer
          priviledged {
            nextToken
          }
          createdAt
          updatedAt
        }
        isMarried
        marriageId
        unitFamily {
          id
          name
          units {
            nextToken
          }
          isActive
          createdAt
          updatedAt
        }
        purchDate
        purchAmt
        purchNotes
        soldDate
        soldAmt
        soldNotes
        isActive
        billRateDay
        billRateHour
        typeId
        type {
          id
          name
          maintenanceName
          createdAt
          updatedAt
        }
        subTypeId
        subType {
          id
          typeId
          type {
            id
            name
            maintenanceName
            createdAt
            updatedAt
          }
          name
          maintenanceName
          inspectionsPoints {
            nextToken
          }
          priceCodeId
          priceCode {
            priceCode
            price
            year
            rateTypeId
            name
            createdAt
            updatedAt
          }
          qbExpenseCategoryId
          qbExpenseCategory {
            expenseCategory
            description
            QBId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        seatAxle
        cgvw
        unladWt
        isDOTReg
        reqCDLDriver
        unitStatus {
          code
          allSort
          unitId
          unit {
            id
            allSort
            code
            codeSortable
            name
            year
            make
            model
            color
            tag
            vin
            serial
            teamMemberId
            isMarried
            marriageId
            purchDate
            purchAmt
            purchNotes
            soldDate
            soldAmt
            soldNotes
            isActive
            billRateDay
            billRateHour
            typeId
            subTypeId
            seatAxle
            cgvw
            unladWt
            isDOTReg
            reqCDLDriver
            currentLocation
            isInOperation
            canTransfer
            isInspectedDaily
            requiresOperator
            requiresDriver
            qbExpenseCategoryId
            qbExpenseCategoryNumber
            qbExpenseCategory
            iconId
            createdAt
            updatedAt
          }
          statusId
          status {
            id
            allSort
            status
            adminOnly
            isAvailable
            doAlert
            alert
            order
            statusQuery
            createdAt
            updatedAt
          }
          notes
          inService
          inServiceAlert
          divisionId
          division {
            id
            regionId
            isFeeder
            prettyId
            prettyname
            addStreet
            addCity
            addState
            addZipcode
            superVisorId
            asstSupervisorId
            djtCanPerDiem
            djtPerDiemAmt
            djtTrackLabor
            djtTrackEquipment
            djtTrackMaterial
            djtRentalMsgActive
            djtRentalMsg
            djtAllowsWO
            djtOneClient
            isActive
            isSafeInspection
            createdAt
            updatedAt
          }
          lastInspected
          lastInspectedAt
          inspectionQueue {
            nextToken
          }
          inspections {
            nextToken
          }
          createdOn
          createdAt
          updatedAt
        }
        unitDocuments {
          items {
            id
            unitId
            uploadedDateTime
            uploadedDocumentId
            unitDocumentTypeId
            unitIdUnitDocumentTypeId
            createdAt
            updatedAt
          }
          nextToken
        }
        currentLocation
        isInOperation
        canTransfer
        isInspectedDaily
        requiresOperator
        requiresDriver
        auxillaryUnits {
          items {
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        qbExpenseCategoryId
        qbExpenseCategoryNumber
        qbExpenseCategory
        iconId
        images {
          items {
            id
            unitId
            imageDescription
            createdDateTime
            createdById
            uploadedDocumentId
            unitDocumentTypeId
            isActive
            createdAt
            updatedAt
          }
          nextToken
        }
        qrCodeValue {
          unitCode
          qrCodeValue
          createdAt
          updatedAt
        }
        odometer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const iSolvedPunchesByDate = /* GraphQL */ `
  query iSolvedPunchesByDate(
    $punchDate: AWSDate!
    $dateDepartmentEmployeeTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkISolvedDailyDepartmentPunchesTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    iSolvedDailyPunchesByDate(
      punchDate: $punchDate
      dateDepartmentEmployeeTime: $dateDepartmentEmployeeTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        teamMember {
          teamMember {
            firstName
            middleName
            goesBy
            lastName
            divisionId
            division {
	            prettyname
            }
          }
        }
        punchDate
        punchTime
        iSolvedDatePunched
        # iSolvedDepartmentName
        departmentId
      }
      nextToken
      scannedCount
      count
    }
  }
`;
export const getDepartmentCodeByDivisionId = /* GraphQL */ `
  query getDepartmentCodeByDivisionId(
    $divisionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkISolvedMapDepartToDivTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    iSolvedDepartmentByDivisionId(
      divisionId: $divisionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        departmentCode
      }
      nextToken
    }
  }
`;
export const fetchMappedDepartments = /* GraphQL */ `
  query fetchMappedDepartments(
    $allSort: String!
    $departmentCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkISolvedMapDepartToDivTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allISolvedMappedDepartments(
      allSort: $allSort
      departmentCode: $departmentCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        departmentCode
        departmentName
        divisionId
        isPrimary
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const fetchISolvedDepartment = /* GraphQL */ `
  query fetchISolvedDepartment(
    $departmentCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkISolvedMapDepartToDivTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    iSolvedDivisionByDepartmentCode(
      departmentCode: $departmentCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentCode
        departmentName
        divisionId
        isPrimary
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamMemberCountByDivision = /* GraphQL */ `
  query teamMemberCountByDivision(
    $divisionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkTeamMemberTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMembersByDivisionId(
      divisionId: $divisionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
      scannedCount
      count
    }
  }
`;
export const listVerifiedEmailDomainsByClientId = /* GraphQL */ `
  query listVerifiedEmailDomainsByClientId(
    $clientId: ID!
    $domain: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTClientVerifiedEmailDomainTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    verifiedEmailDomainsByClientId(
      clientId: $clientId
      domain: $domain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        domain
        isActive
      }
      nextToken
    }
  }
`;
export const listCCEmailsByClientId = /* GraphQL */ `
  query listCCEmailsByClientId(
    $clientId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTClientSummaryEmailCCEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ccEmailsByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        clientId
      }
      nextToken
    }
  }
`;
export const listAllDJTClients = /* GraphQL */ `
  query listAllDJTClients(
    $allSort: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTClientTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allDjtClients(
      allSort: $allSort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        contactCompany
        clientURL
        clientBillingEmail
        contactName
        contactEmail
        contactPhone
        division {
          defaultQBClass
        }
        isActive
        isSyncedToQB
        qbId
        qbClassName
        qbClassId
        qbSandboxClassName
        qbSandboxClassId
        qbSandboxId
        qbDisplayName
        qbCustomer
        qbCompany
        qbStreetAddress
        qbCity
        qbState
        qbCountry
        qbZip
        qbPhone
        qbEmail
        qbCustomerType
        staleAfterDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const allDivisionsSortedByName = /* GraphQL */ `
  query allDivisionsSortedByName(
    $allSort: String!
    $prettyname: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDivisionTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allDivisionsSortableByName(
      allSort: $allSort
      prettyname: $prettyname
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        isFeeder
        prettyId
        prettyname
        defaultQBClass
        addStreet
        addCity
        addState
        addZipcode
        superVisorId
        asstSupervisorId
        djtCanPerDiem
        djtPerDiemAmt
        djtTrackLabor
        djtTrackEquipment
        djtTrackMaterial
        djtRentalMsgActive
        djtRentalMsg
        djtAllowsWO
        djtOneClient
        djtAllowsEmailCC
        djtDefaultStartTime
        djtDefaultEndTime
        djtDefaultLunchDuration
        djtEnableAllSupervisors
        djtMergeMaterialEquipment
        djtShowDownloadPDF
        laborMultiplier
        isActive
        isSafeInspection
      }
      nextToken
    }
  }
`;
export const allActiveNavMenuItems = /* GraphQL */ `
  query allActiveNavMenuItems(
    $allSort: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkNavMenuTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allNavMenuItems(
      allSort: $allSort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        menuItem
        menuClass
        linkTo
        menuAltText
        displayOrder
        isPhoneSafe
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeamMemberById = /* GraphQL */ `
  query getTeamMemberById($id: ID!) {
    getSpkTeamMemberTbl(id: $id) {
      id
      dob
      hireDate
      addState
      addCity
      addStreet
      addZipcode
      badgeNumber
      avatarId
      billableDivisionJobClassId
      billableDivisionJobClass {
        divisionPosition
        className
        appDisplayName
      }
      canTransfer
      createdAt
      divisionId
      division {
        id
        prettyname
      }
      djtHideByDefault
      doTrackPunches
      emergcyContName
      emergcyContPhone
      employeeNumber
      firstName
      goesBy
      imgUrl
      isActive
      isERPUser
      isLocked
      isGlobalAdmin
      isOnboarded
      isolvedEmail
      lastName
      loginEmail
      middleName
      phoneNum
      updatedAt
    }
  }
`;
export const listUnitsByUnitDocumentType = /* GraphQL */ `
  query listUnitsByUnitDocumentType(
    $unitDocumentTypeId: ID!
    $uploadedDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkUnitDocumentTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unitsByUnitDocumentType(
      unitDocumentTypeId: $unitDocumentTypeId
      uploadedDateTime: $uploadedDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        unitId
        createdById
        createdBy {
          id
          loginEmail
          firstName
          middleName
          lastName
          goesBy
          createdAt
          updatedAt
        }
        uploadedDateTime
        uploadedDocumentId
        uploadedDocument {
          id
          identityId
          key
          bucket
          createdAt
          updatedAt
        }
        unitDocumentTypeId
        unitDocumentType {
          name
          group
          isActive
        }
        unitIdUnitDocumentTypeId
        active
        isActive
        notes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const allUnitTypes = /* GraphQL */ `
  query allUnitTypes(
    $allSort: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkUnitTypeTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allUnitTypesSortableByName(
      allSort: $allSort
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        name
        maintenanceName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const unitSubTypesByType = /* GraphQL */ `
  query unitSubTypesByType(
    $typeId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkUnitSubTypeTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unitSubTypesByTypeId(
      typeId: $typeId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typeId
        name
        maintenanceName
        priceCodeId
        priceCode {
          priceCode
          price
          year
          rateTypeId
          rateType {
            rateType
            description
            createdAt
            updatedAt
          }
          name
          divisionId
          divisionYear
          createdAt
          updatedAt
        }
        qbExpenseCategoryId
        qbExpenseCategory {
          expenseCategory
          description
          QBId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const allUnitSubTypes = /* GraphQL */ `
  query allUnitSubTypes(
    $allSort: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkUnitSubTypeTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allUnitSubTypesSortableByName(
      allSort: $allSort
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        typeId
        name
        maintenanceName
        priceCodeId
        priceCode {
          rateTypeId
          price
        }
        qbExpenseCategoryId
        qbExpenseCategory {
          expenseCategory
          description
          QBId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAllQBExpenseCategories = /* GraphQL */ `
  query listAllQBExpenseCategories(
    $allSort: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkQBExpenseCategoryTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allQBExpenseCategoriesSortableByName(
      allSort: $allSort
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        qbId
        allSort
        acctNum
        name
        appName
        isActive
        isVisible
        isIFTATracked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const statementVendorByQBVendorName = /* GraphQL */ `
  query statementVendorByQBVendorName(
    $qbDisplayNameLower: String!
    $qbDisplayName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkStatementQuickBooksVendorTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statementVendorByQBVendorNameLower(
      qbDisplayNameLower: $qbDisplayNameLower
      qbDisplayName: $qbDisplayName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        qbId
        qbDisplayName
        qbDisplayNameLower
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const allStatementVendorsByQBVendorNameSortable = /* GraphQL */ `
  query allStatementVendorsByQBVendorNameSortable(
    $allSort: String!
    $qbDisplayNameLower: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkStatementQuickBooksVendorTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allStatementQuickBooksVendorSortableByQBVendorName(
      allSort: $allSort
      qbDisplayNameLower: $qbDisplayNameLower
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        qbId
        allSort
        qbDisplayName
        qbDisplayNameLower
      }
      nextToken
    }
  }
`;
export const allStatementVendorsIdsOnly = /* GraphQL */ `
  query allStatementVendorsIdsOnly(
    $allSort: String!
    $qbDisplayNameLower: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkStatementQuickBooksVendorTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allStatementQuickBooksVendorSortableByQBVendorName(
      allSort: $allSort
      qbDisplayNameLower: $qbDisplayNameLower
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        qbId
        qbDisplayName
      }
      nextToken
    }
  }
`;
export const allChartOfAccounts = /* GraphQL */ `
  query allChartOfAccounts(
    $allSort: String!
    $qbChartOfAccountsName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkCreditCardChartOfAccountsTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCreditCardChartOfAccountsSortableByQBChartOfAccountsName(
      allSort: $allSort
      qbChartOfAccountsName: $qbChartOfAccountsName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        qbChartOfAccountsId
        allSort
        qbChartOfAccountsName
        qbChartOfAccountsFullyQualifiedName
        qbChartOfAccountsActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const allTeamMembersSortableByLastNameForSelect = /* GraphQL */ `
  query allTeamMembersSortableByLastNameForSelect(
    $allSort: String!
    $lastName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkTeamMemberTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allTeamMembersSortableByLastName(
      allSort: $allSort
      lastName: $lastName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        isActive
        firstName
        middleName
        lastName
        goesBy
        billableDivisionJobClass {
          divisionPosition
          className
          appDisplayName
          hourlyRate
          overtimeRate
        }
        goesBy
        imgUrl
        divisionId
        division {
          prettyname
        }
      }
    }
  }
`;
export const fetchAllCreditCardTeamMemberMap = /* GraphQL */ `
  query fetchAllCreditCardTeamMemberMap(
    $allSort: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkCreditCardTeamMemberMapTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCreditCardTeamMemberMapId(
      allSort: $allSort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        qbChartOfAccountsId
        qbChartOfAccounts {
          qbChartOfAccountsId
          allSort
          qbChartOfAccountsName
          qbChartOfAccountsFullyQualifiedName
          qbChartOfAccountsActive
          createdAt
          updatedAt
        }
        allSort
        teamMemberId
        teamMember {
          division {
            prettyname
          }
          firstName
          middleName
          lastName
          goesBy
        }
        isActive
        isDefault
        cardLastFour
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const fetchCreditCardByTeamMemberId = /* GraphQL */ `
  query fetchCreditCardByTeamMemberId(
    $teamMemberId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkCreditCardTeamMemberMapTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditCardTeamMemberByTeamMemberId(
      teamMemberId: $teamMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        qbChartOfAccountsId
        qbChartOfAccounts {
          qbChartOfAccountsName
          qbChartOfAccountsFullyQualifiedName
        }
        teamMemberId
        isActive
        isDefault
        cardLastFour
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlaidUser = /* GraphQL */ `
  query getPlaidUser($userId: ID!) {
    getSpkPlaidUserTbl(userId: $userId) {
      userId
      institutions {
        items {
          id
          accessToken
          institutionId
          institution
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserInstitutions = /* GraphQL */ `
  query listUserInstitutions(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPlaidInstitutionTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    institutionsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        accessToken
        institutionId
        institution
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlaidTransaction = /* GraphQL */ `
  query getPlaidTransaction($id: ID!) {
    getSpkPlaidTransactionTbl(id: $id) {
      id
      institution_id
      account_id
      account_owner
      amount
      authorized_date
      date
      location
      merchant_entity_id
      name
      merchant_name
      payment_channel
      pending
      pending_transaction_id
      pending_transaction
      transaction_code
      transaction_id
      transaction_type
      createdAt
      updatedAt
    }
  }
`;
export const getPlaidTransactionsByAccountOwner = /* GraphQL */ `
  query getPlaidTransactionsByAccountOwner(
    $account_owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPlaidTransactionTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    plaidTransactionByAccountOwner(
      account_owner: $account_owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        institution_id
        account_id
        account_owner
        amount
        authorized_date
        date
        location
        merchant_entity_id
        name
        merchant_name
        payment_channel
        pending
        pending_transaction_id
        pending_transaction
        transaction_code
        transaction_id
        transaction_type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlaidTransactionsByDate = /* GraphQL */ `
  query getPlaidTransactionsByDate(
    $date: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPlaidTransactionTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    plaidTransactionsByDate(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        institution_id
        account_id
        account_owner
        amount
        authorized_date
        date
        location
        merchant_entity_id
        name
        merchant_name
        payment_channel
        pending
        pending_transaction_id
        pending_transaction
        transaction_code
        transaction_id
        transaction_type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlaidTransactionsById = /* GraphQL */ `
  query getPlaidTransactionsById(
    $transaction_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPlaidTransactionTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    plaidTransactionsById(
      transaction_id: $transaction_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        institution_id
        account_id
        account_owner
        amount
        authorized_date
        date
        location
        merchant_entity_id
        name
        merchant_name
        payment_channel
        pending
        pending_transaction_id
        pending_transaction
        transaction_code
        transaction_id
        transaction_type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPlaidPendingTransactionsSortableByDate = /* GraphQL */ `
  query listPlaidPendingTransactionsSortableByDate(
    $pendingSort: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPlaidPendingTransactionTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allPlaidPendingTransactionsSortableByDate(
      pendingSort: $pendingSort
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cardNumber
        date
        merchant
        transactionType
        amount
        pendingSort
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPlaidTransactionByMatched = /* GraphQL */ `
  query listPlaidTransactionByMatched(
    $matched: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPlaidTransactionTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    plaidTransactionByMatched(
      matched: $matched
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        institution_id
        account_id
        account_owner
        amount
        authorized_date
        date
        location
        merchant_entity_id
        name
        merchant_name
        payment_channel
        pending
        pending_transaction_id
        pending_transaction
        transaction_code
        transaction_id
        transaction_type
        cursorId
        cursor {
          cursor
          lastUpdated
          previousUpdate
          createdAt
          updatedAt
        }
        matched
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPlaidPendingTransactionByCardNumber = /* GraphQL */ `
  query listPlaidPendingTransactionByCardNumber(
    $cardNumber: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPlaidPendingTransactionTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    plaidPendingTransactionByCardNumber(
      cardNumber: $cardNumber
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cardNumber
        date
        merchant
        transactionType
        amount
        pendingSort
        postedTransactionId
        postedTransaction {
          institution_id
          account_id
          account_owner
          amount
          authorized_date
          date
          location
          merchant_entity_id
          name
          merchant_name
          payment_channel
          pending
          pending_transaction_id
          pending_transaction
          transaction_code
          transaction_id
          transaction_type
          matched
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuickPlaidCreditCardTransaction = /* GraphQL */ `
  query getQuickPlaidCreditCardTransaction($id: ID!) {
    getSpkPlaidCreditCardTransactionTbl(id: $id) {
      id
      accountNumber
      purchaseDate
      purchaseMerchant
      purchaseAmount
      purchaseInstitution
      status
      createdAt
      updatedAt
    }
  }
`;
export const listAllISolvedMapEmpNoTeamMember = /* GraphQL */ `
  query listAllISolvedMapEmpNoTeamMember(
    $allSort: String!
    $employeeName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkISolvedMapEmpNoToTeamMemberTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allISolvedMapEmpNoTeamMember(
      allSort: $allSort
      employeeName: $employeeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        employeeNumber
        allSort
        employeeName
        departmentId
        teamMemberId
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpkCapitalOneInstantNotification = /* GraphQL */ `
  query getSpkCapitalOneInstantNotification($id: ID!) {
    getSpkCapitalOneInstantNotificationTbl(id: $id) {
      id
      capitalOneCreditCardTransactionReceiptId
      capitalOneCreditCardTransactionReceipt {
        id
        units {
          items {
            id
            transactionId
            unitId
          }
        }
        capitalOneInstantNotificationId
        plaidCreditCardTransactionId
        isReceiptUploaded
        quickbooksExpenseId
        teamMemberId
        divisionId
        clientId
        expenseCategoryId
        unitId
        iftaFuelTaxTrackId
        addedNotes
        createdAt
        updatedAt
      }
      plaidTransationId
      plaidTransaction {
        id
        capitalOneInstantNotificationId
        capitalOneCreditCardTransactionReceiptId
        accountNumber
        purchaseDate
        purchaseMerchant
        purchaseAmount
        purchaseInstitution
        accountId
        date
        amount
        merchantEntityId
        merchantEntityName
        store
        categoryId
        category
        location
        paymentChannel
        transactionId
        transactionType
        status
        approvedById
        createdAt
        updatedAt
      }
      isMatched
      isSubmitted
      lastFour
      purchaseDate
      purchaseMerchant
      purchaseAmount
      purchaseInstitution
      createdAt
      updatedAt
    }
  }
`;
export const getCapitalOneReceiptByInstantNotificationId = /* GraphQL */ `
  query getCapitalOneReceiptByInstantNotificationId(
    $capitalOneInstantNotificationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkCapitalOneCreditCardTransactionReceiptTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    capitalOneCreditCardTransactionReceiptByCapitalOneInstantNotificationId(
      capitalOneInstantNotificationId: $capitalOneInstantNotificationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        capitalOneInstantNotificationId
        plaidCreditCardTransactionId
        isReceiptUploaded
        # uploadedDocumentId
        quickbooksExpenseId
        teamMemberId
        divisionId
        clientId
        expenseCategoryId
        unitId
        iftaFuelTaxTrackId
        addedNotes
        hasIssue
        issue
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const fetchCapitalOneInstantNotificationByLastFour = /* GraphQL */ `
  query fetchCapitalOneInstantNotificationByLastFour(
    $lastFour: String!
    $purchaseDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkCapitalOneInstantNotificationTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    capitalOneInstantNotificationByLastFour(
      lastFour: $lastFour
      purchaseDate: $purchaseDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        capitalOneCreditCardTransactionReceiptId
        capitalOneCreditCardTransactionReceipt {
          matchStatus
          hasIssue
          issue
        }
        plaidTransationId
        isMatched
        isSubmitted
        status
        lastFour
        purchaseDate
        purchaseMerchant
        purchaseAmount
        purchaseInstitution
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCapitalOneCreditCardTransactionReceiptsByStatus = /* GraphQL */ `
  query listCapitalOneCreditCardTransactionReceiptsByStatus(
    $allSort: String!
    $matchStatus: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkCapitalOneCreditCardTransactionReceiptTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    capitalOneCreditCardTransactionReceiptsByStatus(
      allSort: $allSort
      matchStatus: $matchStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        capitalOneInstantNotificationId
        capitalOneInstantNotification {
          lastFour
          purchaseAmount
          purchaseDate
          purchaseInstitution
          purchaseMerchant
          id
          isMatched
          isSubmitted
          status
        }
        plaidCreditCardTransactionId
        allSort
        matchStatus
        qbTransactionEntityId
        isReceiptUploaded
        # uploadedDocumentId
        quickbooksExpenseId
        teamMemberId
        divisionId
        division {
          prettyname
        }
        clientId
        expenseCategoryId
        unitId
        iftaFuelTaxTrackId
        addedNotes
        hasIssue
        issue
        uploadedDocuments {
          items {
            uploadedDocument {
              id
              identityId
              key
              bucket
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const fetchPlaidCreditCardTransactionByStatus = /* GraphQL */ `
  query fetchPlaidCreditCardTransactionByStatus(
    $status: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkPlaidCreditCardTransactionTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    plaidCreditCardTransactionByStatus(
      status: $status
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        capitalOneInstantNotificationId
        capitalOneCreditCardTransactionReceiptId
        accountNumber
        purchaseDate
        purchaseMerchant
        purchaseAmount
        purchaseInstitution
        accountId
        date
        amount
        merchantEntityId
        merchantEntityName
        store
        categoryId
        category
        location
        paymentChannel
        transactionId
        transactionType
        status
        approvedById
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCreditCardChartOfAccounts = /* GraphQL */ `
  query getCreditCardChartOfAccounts($qbChartOfAccountsId: String!) {
    getSpkCreditCardChartOfAccountsTbl(
      qbChartOfAccountsId: $qbChartOfAccountsId
    ) {
      qbChartOfAccountsId
      allSort
      qbChartOfAccountsName
      qbChartOfAccountsFullyQualifiedName
      qbChartOfAccountsActive
      cardLastFour
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getTeamMemberByCardLastFour = /* GraphQL */ `
  query getTeamMemberByCardLastFour(
    $cardLastFour: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkCreditCardTeamMemberMapTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditCardTeamMemberByCardLastFour(
      cardLastFour: $cardLastFour
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        allSort
        teamMemberId
        teamMember {
          division {
            prettyname
          }
          firstName
          middleName
          lastName
          goesBy
        }
      }
    }
  }
`;
export const capitalOneInstantNotificationsByStatus = /* GraphQL */ `
  query capitalOneInstantNotificationsByStatus(
    $allSort: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkCapitalOneInstantNotificationTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    capitalOneInstantNotificationByAllSort(
      allSort: $allSort
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        capitalOneCreditCardTransactionReceiptId
        plaidTransationId
        isMatched
        isSubmitted
        status
        lastFour
        purchaseDate
        purchaseMerchant
        purchaseAmount
        purchaseInstitution
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const fetchUploadedReceiptsByReceiptId = /* GraphQL */ `
  query fetchUploadedReceiptsByReceiptId(
    $receiptId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkCapitalOneTransactionReceiptDocumentLinkTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionReceiptDocumentLinksByReceiptId(
      receiptId: $receiptId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uploadedDocument {
          id
          identityId
          bucket
          key
        }
      }
      nextToken
    }
  }
`;
export const listUnitsDivisionPopularity = /* GraphQL */ `
  query listUnitsDivisionPopularity(
    $divisionId: ID!
    $popularity: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSpkDJTUnitPopularityTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unitPopularityByDivisionId(
      divisionId: $divisionId
      popularity: $popularity
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        divisionIdUnitId
        divisionId
        unitId
        popularity
      }
      nextToken
    }
  }
`;
export const getISolvedEmployeeByTeamMemberId = /* GraphQL */ `
  query getISolvedEmployeeByTeamMemberId(
    $teamMemberId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkISolvedMapEmpNoToTeamMemberTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    iSolvedEmployeeByTeamMemberId(
      teamMemberId: $teamMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        employeeNumber
        allSort
        employeeName
        departmentId
        teamMemberId
        isActive
      }
      nextToken
    }
  }
`;
export const listAllNavCognitoGroups = /* GraphQL */ `
  query listAllNavCognitoGroups(
    $allSort: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkNavCognitoGroupTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allNavCognitoGroups(
      allSort: $allSort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        cognitoGroupId
        cognitoGroup {
          id
          name
          description
        }
        menuItemId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAllCognitoGroups = /* GraphQL */ `
  query listAllCognitoGroups(
    $allSort: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkCognitoGroupTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allCognitoGroups(
      allSort: $allSort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        allSort
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listPunchedLaborByDateDepartmentShort = /* GraphQL */ `
  query listPunchedLaborByDateDepartmentShort(
    $dateDepartment: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSpkISolvedDailyDepartmentPunchesTblFilterInput
    $limit: Int
    $nextToken: String
  ) {
    iSolvedPunchesByDateByDepartmentShort(
      dateDepartment: $dateDepartment
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dateDepartmentEmployeeTime
        punchDate
        punchTime
        departmentId
        dateDepartment
        iSolvedDatePunched
        iSolvedDepartmentName
        iSolvedEmployeeNumber
        departmentEmployee
        punchMode
        punchDateClassification
        createdAt
        updatedAt
        __typename
      }
      nextToken
      scannedCount
      count
      __typename
    }
  }
`;