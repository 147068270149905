export function doEmailAddressDomainsMatch(email1, email2) {
  if (!email1 || !email2) return false

  // get the domain from email one
  const emailDomain1 = extractDomainFromEmail(email1)
  // get the domain from email 2
  const emailDomain2 = extractDomainFromEmail(email2)
  // compare the domains
  const match = (emailDomain1===emailDomain2)
  // rteturn match boolean
  return match
}

export function extractNameFromEmail(email) {
  return email.split('@')[0];
}

export function extractDomainFromEmail(email) {
  return email.split('@')[1];
}