import PropTypes from 'prop-types';
import { Button } from '@chakra-ui/react';

export const ButtonDuodenaryPlain = (props) => {
  const {
    name,
    value,
    onClick,
    isDisabled=false,
    ref,
    mx='',
    my='',
    state='default',  //'default'||'selected'
  } = props;

  const variantStyle = (state === 'default') ? 'plainDuodenaryDefault' : 'plainDuodenarySelected'  

  return (
    <Button 
      {...props}
      name={name}
      ref={ref}
      variant={variantStyle} 
      onClick={onClick}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
    >
      {value}
    </Button>
  );
};

// Prop validation
ButtonDuodenaryPlain.propTypes = {
  name: PropTypes.string, // Name is expected to be a string
  value: PropTypes.oneOfType([ // Value can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]).isRequired, // Value is required
  onClick: PropTypes.func, // onClick is a function
  isDisabled: PropTypes.bool, // isDisabled is a boolean
  ref: PropTypes.oneOfType([ // ref can be a function or an object created by React.createRef()
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  mx: PropTypes.oneOfType([ // mx can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([ // my can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  state: PropTypes.string,
};
