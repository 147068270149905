import { allDivisionsSortedByName } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchDivisionOptions = async (allsort) => {
  try {
    let gqlParams = {
      allSort: allsort,
      sortDirection: 'ASC',
    };

    const response = await client.graphql({
      query: allDivisionsSortedByName,
      variables: gqlParams,
    });

    const results = response.data.allDivisionsSortableByName.items;
    return results;
    
  } catch (err) {
    return('error fetchDivisionOptions: ', [err]);
  }
};

fetchDivisionOptions.propTypes = {
  allsort: PropTypes.string.isRequired,
};