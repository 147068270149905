import { iSolvedPunchesByDate } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';

import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const fetchISolvedPunchesByDate = async (date) => {
  if (date) {
    try {
      const gqlQuery = {
        punchDate: date
      };
      const response = await client
      .graphql({
        query: iSolvedPunchesByDate,
        variables: gqlQuery
      })
      console.log('response: ', response);
      const results = response.data.iSolvedDailyPunchesByDate;
      return results;
    } catch (err) {
      return('fetchISolvedPunchesByDate error: ', [err]);
    }
  } else {
    return('Missing date')
  }
}

fetchISolvedPunchesByDate.propTypes = {
  date: PropTypes.node.isRequired,
};