import { useContext } from 'react';
import { FormContext } from '../Form';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
} from "@chakra-ui/react";

export function TextareaInputInspection(props) {
  const { 
    fieldname='', 
    fieldlabel, 
    prettyname, 
    fieldtype='text', 
    fieldvalue='',
    // fieldvalue,
    variant='defaultinput',
    // defaultValue,
    register, 
    errors, 
    placeholder='Placeholder', 
    // isReadOnly=false, 
    // isRequired=false, 
    // isDisabled=false, 
    // maximLength=100, 
    // minimLength=0, 
    // width,
    // onChange,
    // onBlur,
    // onClick,
    // onFocus,
    // onKeyPress,
    // className,
    // textAlign,
    // isInvalid,
    index,
    rows=3,
  } = props;

  const formContext = useContext(FormContext);
  const { form, handleFormChange } = formContext;

  const defaultFieldValue = (fieldvalue) ? fieldvalue : form[fieldname];

  return (
    <FormControl isInvalid={errors[fieldname]}>
      <FormLabel htmlFor={fieldname}>{fieldlabel}</FormLabel>
      <Textarea
        key={`${fieldname}_${index}`}
        variant={variant}
        id={fieldname}
        name={fieldname}
        placeholder={placeholder}
        rows={rows}
        value={defaultFieldValue}
        {...register(fieldname, { 
            required: { 
            value: true, 
            message: `${prettyname||fieldname} is required.`, 
          }, 
        })} 
      />
      <FormErrorMessage>
        {errors[fieldname] && errors[fieldname].message}
      </FormErrorMessage>
    </FormControl>

  )
}

