import { getTeamMemberById } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

// fetches summary data for TeamMembers
export const fetchTeamMemberById = async (teamMemberId) => {
  if (teamMemberId) {
    try {
      const response = await client.graphql({
        query: getTeamMemberById,
        variables: { id: teamMemberId }
      });
      const results = response.data.getSpkTeamMemberTbl;
      return results;
    } catch (err) {
      return ('error fetchTeamMemberById: ', [err]);
    }
  } else {
    return null;
  }
}

fetchTeamMemberById.propTypes = {
  teamMemberId: PropTypes.string.isRequired,
};
