import { uploadData } from 'aws-amplify/storage';
// import { fetchAuthSession } from 'aws-amplify/auth';
import { addFileToUploadedDocuments } from '../../graphqlCompnents/AWS/S3/addFileToUploadedDocuments'
import { v4 as uuidv4 } from 'uuid';
// import { getIdentityId } from './cognitoIdentityService';

export const uploadBlobToS3 = async (props) => {
  if (props) {
    try {

      // console.info('uploadToS3 props: ', props)
      // const authCredentials = await Auth.currentCredentials();  // Get current AWS credentials
      // const authIdentityId = credentials.identityId;  // Retrieve the identity ID from the credentials
    
      // const session = await fetchAuthSession();
      // const credentials = session.credentials;
      // const identityId = credentials.identityId;

      // console.info(' ---  authCredentials:', authCredentials)
      // console.info(' ---  authIdentityId:', authIdentityId)

      // const credentials = await Auth.currentCredentials();
      // const fileobj = props.file
      const fileobj = props.file
      const identityId = props.identityId;

      // console.log('GOT IDENTITY ID!: ', identityId)

      // console.log('fileobj: ', fileobj)
      const filename = props.filename

      // const [file, extension] = fileobj.name.split('.');
      // const [extension] = filename.split('.')[1];
      // console.log('extension: ', extension)
      // const mimeType = fileobj.type;
      const mimeType = props.filetype;
      const bucket = props.bucket
      const fileKey = uuidv4()+':'+filename
      // const identityId = credentials.identityId;

      const fileParams = {
        bucket: bucket,
        identityId: identityId, 
        key: fileKey
      }

      // Add the record to the SpkUploadedDocumentsTbl for tracking all files uploaded
      // console.log('--- uploadBlobToS3 --- about to addFileToUploadedDocuments')
      // const uploadedDocumentId = await addFileToUploadedDocuments(fileParams);
      await addFileToUploadedDocuments(fileParams);

      // console.log('got uploadedDocumentId: ', uploadedDocumentId)

      await uploadData({
        path: 'protected/'+identityId+'/'+fileKey,
        data: fileobj,
        options: {
          contentType: mimeType,
          bucket: {
            bucketName: bucket,
            region: 'us-east-1'
          }
        }
      })

      const returnData = {
        // fileName: fileobj.name,
        fileName: filename,
        identityId: identityId, 
        key: fileKey
      }

      return returnData

    } catch (err) {
      return err
    }
  } else {
    console.log('ERROR: No props sent.')
    return 'ERROR: No props'
  }
  
}
