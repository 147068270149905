import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { listUnitsDivisionPopularity } from '../../../graphql/custom_queries';

const client = generateClient();

export const fetchPopularUnitsByDivision = async (divisionId, limit=10, nexttoken) => {
  try {
    const gqlParams = {
      divisionId: divisionId, 
      limit: limit, 
      sortDirection: 'DESC',
      ...(nexttoken && { nextToken: nexttoken })
    };
    // console.warn('gqlParams: ', gqlParams)
    const response = await client.graphql({
      query: listUnitsDivisionPopularity,
      variables: gqlParams
    });
    // console.warn('RESPONSE: ', response)
    const results = response.data.unitPopularityByDivisionId.items;
    // console.warn('RESULTS: ', results)
    return results;

  } catch (err) {
    return 'error fetchPopularUnitsByDivision: ' + err;
  }
};

fetchPopularUnitsByDivision.propTypes = {
  divisionId: PropTypes.string.isRequired,
  limit: PropTypes.number,
  nexttoken: PropTypes.string,
};
