import { createSpkCapitalOneCreditCardTransactionReceipt } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const createCapitalOneReceipt = async (props) => {
  if (!props) return;

  console.log('createCapitalOneReceipt props: ', props);
  
  try {
    const gqlParams = { input: props };
    
    const response = await client.graphql({
      query: createSpkCapitalOneCreditCardTransactionReceipt,
      variables: gqlParams
    });
    
    console.log('createSpkCapitalOneCreditCardTransactionReceipt response: ', response);
    
    const results = response.data.createSpkCapitalOneCreditCardTransactionReceiptTbl.id;
    console.log('createSpkCapitalOneCreditCardTransactionReceipt results: ', results);
    
    return results;
  } catch (err) {
    return ('error createCapitalOneReceipt: ', [err]);
  }
};

createCapitalOneReceipt.propTypes = {
  props: PropTypes.object.isRequired,
};