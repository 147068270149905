import { createCapitalOneTransactionReceiptDocumentLink } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const addStatementDocumentLink = async (props) => {
  if (!props.receiptId || !props.uploadedDocumentId) return;

  try {
    const gqlParams = {
      receiptId: props.receiptId,
      uploadedDocumentId: props.uploadedDocumentId,
    };

    const response = await client.graphql({
      query: createCapitalOneTransactionReceiptDocumentLink,
      variables: { input: gqlParams },
    });

    const results = response.data.createSpkCapitalOneTransactionReceiptDocumentLinkTbl.id;
    
    return results;
  } catch (err) {
    return ('error addStatementDocumentLink: ', [err]);
  }
};

addStatementDocumentLink.propTypes = {
  props: PropTypes.shape({
    receiptId: PropTypes.string.isRequired,
    uploadedDocumentId: PropTypes.string.isRequired,
  }).isRequired,
};