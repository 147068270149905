import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { createDailyJobTicket } from '../../../graphql/custom_mutations';

const client = generateClient();

export const addDailyJobTicket = async (props) => {
  if (props) {
    try {
      console.log(' >>>>> props: ', props);

      const response = await client.graphql({
        query: createDailyJobTicket,
        variables: { input: props },
      });

      console.log(' >>>>> RESPONSE: ', response);
      const results = response.data.createSpkDailyJobTicketTbl.id;
      console.log(' >>>>> RESULTS: ', results);
      return results;
    } catch (err) {
      return 'error addDailyJobTicket: ' + err;
    }
  } else {
    return null;
  }
};

addDailyJobTicket.propTypes = {
  props: PropTypes.object.isRequired,
};
