/* eslint-disable react/prop-types */
import { useEffect, useState, useContext, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Text,
  Flex,
  Spacer,
  Spinner,
  Center,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  HStack,
  useDisclosure,
  useBreakpointValue,
  TableContainer,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  Tooltip,
  Grid,
  IconButton,
  // Badge,
} from "@chakra-ui/react";

// globals
import { AppContext } from '../AppContext.jsx';

// Icons
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// graphQL queries
import { fetchUploadedReceiptsByStatus } from '../graphqlCompnents/Statement/fetchUploadedReceiptsByStatus.jsx';
import { fetchTransactionsByStatus } from '../graphqlCompnents/Statement/fetchTransactionsByStatus.jsx';
import { fetchQuickBooksExpenseCategories } from '../graphqlCompnents/Statement/fetchQuickBooksExpenseCategories.jsx';
// import { searchQBVendorsByVendorNameLower } from '../graphqlCompnents/Statement/searchQBVendorsByVendorNameLower.jsx';
import { fetchAllQuickBooksVendorNamesIds } from '../graphqlCompnents/Statement/fetchAllQuickBooksVendorNamesIds.jsx';
// import { fetchTeamMemberByAccountNumber } from '../graphqlCompnents/Statement/fetchTeamMemberByAccountNumber.jsx';
import { fetchCapitalOneInstantNotificationsByStatus } from '../graphqlCompnents/Statement/fetchCapitalOneInstantNotificationsByStatus.jsx';

import { fetchAllChartOfAccounts } from '../graphqlCompnents/Statement/fetchAllChartOfAccounts.jsx';
// import { updateChartOfAccounts } from '../graphqlCompnents/Statement/updateChartOfAccounts.jsx';
// import { addChartOfAccounts } from '../graphqlCompnents/Statement/addChartOfAccounts.jsx';
import { addOrUpdateChartOfAccounts } from '../graphqlCompnents/Statement/addOrUpdateChartOfAccounts.jsx';
import { addCreditCardTeamMemberMap } from '../graphqlCompnents/Statement/addCreditCardTeamMemberMap.jsx';
import { updateCreditCardTeamMember } from '../graphqlCompnents/Statement/updateCreditCardTeamMember.jsx';
import { fetchAllCreditCardTeamMemberMaps } from '../graphqlCompnents/Statement/fetchAllCreditCardTeamMemberMaps.jsx';
import { fetchAllTeamMembers } from '../graphqlCompnents/Statement/fetchAllTeamMembers.jsx';
import { fetchAccountsByTeamMember } from '../graphqlCompnents/Statement/fetchAccountsByTeamMember.jsx';
import { updateCreditCardTransactionReceipt } from '../graphqlCompnents/Statement/updateCreditCardTransactionReceipt.jsx';

// QUickBooks Auth
import { QuickBooksAuth } from '../QuickBooks/QuickBooksAuth.jsx';
import { QBLoginLogoutButtons } from '../QuickBooks/QBLoginLogoutButtons.jsx';
import { getChartOfAccounts } from '../QuickBooks/getChartOfAccounts.jsx';

// structural components
import { ModuleBox } from '../Structural/ModuleBox.jsx';
import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';

// drawer components
import { EditTransaction } from './EditTransaction.jsx';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';

// Buttons
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';

// external functions
import { normalizeMerchantName, findMatchesWithQbVendors } from '../functions/transactionMatching.jsx';
import { formatCurrency } from '../functions/currency.jsx';
import { spellOutDate } from '../functions/dateTime.jsx';
import { truncateString } from '../functions/strings.jsx';
import { toTitleCase } from '../functions/strings.jsx';

// drawer components
import { EditTransactionVendor } from './EditTransactionVendor.jsx';

// Modals
import { GalleryModal } from '../Modal/GalleryModal.jsx';

import { getSignedUrlForFile } from '../custom-hooks/useSignedUrlForFile.jsx';

// SpkPlaidCreditCardTransactionTbl
// SpkStatementVendorMapTypeTbl
// SpkStatementQuickBooksVendorTbl
// SpkStatementVendorMapTbl

// icons
// import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// import { VendorMap } from './VendorMap.jsx';

export const ReceiptTransactionManagement = () => {

  // grab global context values
  const { store } = useContext(AppContext);

  // get the current environment sandbox || production
  const qbEnv = store?.qbEnvironment || 'sandbox'

  const currentUserIsGlobalAdmin = store?.userData?.isGlobalAdmin||false

  // set the current users team member id
  // const currentTeamMemberId = store?.userData?.id

  // --- REACT-HOOK-FORM ---
  const { control, register, handleSubmit, setValue, setError, clearErrors, formState: { errors, isSubmitting } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    // reValidateMode: 'onBlur',
    defaultValues: {}
  });
  
  const onError = (errors) => {
    console.log('Form errors:', errors);
  };

  // QUICKBOOKS
  const [tokenObj, setTokenObj] = useState(null);
  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    if (storedToken) {
      setTokenObj(JSON.parse(storedToken));  // Load token from local storage on component mount
    }
  }, []);

  // QUICKBOOKS

  // ----- EDIT TRANSACTION DRAWER -----
  const { isOpen: isEditTransactionOpen , onOpen: onEditTransactionOpen, onClose: editTransactionClose } = useDisclosure()
  // ----- EDIT TRANSACTION VENDOR DRAWER -----
  const { isOpen: isEditTransactionVendorOpen , onOpen: onEditTransactionVendorOpen, onClose: editTransactionVendorClose } = useDisclosure()
  // ----- GALLERY MODAL -----
  const { isOpen: isGalleryModalOpen, onOpen: onGalleryModalOpen, onClose: onGalleryModalClose } = useDisclosure();

  const [ galleryImages, setGalleryImages ] = useState([]);
  const handleOpenGallery = (imagesArray) => {
    console.log('openGallery: ', imagesArray);
    setGalleryImages(imagesArray);  // Set gallery images first
  };
  
  // useEffect to watch galleryImages and open modal only when it's set
  useEffect(() => {
    if (galleryImages.length > 0) {
      console.log('Gallery images set, opening modal...');
      onGalleryModalOpen();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [galleryImages]); // Depend on galleryImages, so it triggers only when set

  const handleGalleryModalClose = () => {
    setGalleryImages([]);  // Clear gallery images
    onGalleryModalClose();
  };

  const [ uploadedReceipts, setUploadedReceipts ] = useState([]);
  const [ syncedTransactions, setSyncedTransactions ] = useState([]);
  const [matchResults, setMatchResults] = useState({
    matches: [],
    unmatchedFromArray1: [],
    unmatchedFromArray2: [],
  });

  const [ refreshIndex, setRefreshIndex ] = useState(0);
  const handleRefresh = (index) => {
    setMatchResults({
      matches: [],
      unmatchedFromArray1: [],
      unmatchedFromArray2: [],
    });
    
    setRefreshIndex(prev => prev + 1); // Increment to trigger useEffect
    setValue('searchInput', '')
    console.log(`Tab index ${index} clicked, refreshing...`);
  };

  const [ expenseCategories, setExpenseCategories ] = useState([])
  // const [ selectedExpenseCategory, setSelectedExpenseCategory ] = useState('')
  // const [ isIFTATracked, setIsIFTATracked ] = useState(false)
  // Function to get expense category based on qbId
  const getExpenseCategory = (qbId) => {
    // if (!expenseCategories.length) return '';
    // Find the expense that matches the given qbId
    const expense = expenseCategories.find((category) => category.value === qbId);

    // If no expense is found, return null or a default value
    if (!expense) return '';

    // Return the formatted object based on the found expense
    return {
      value: expense.value,
      label: expense.label,
      isIFTATracked: expense?.isIFTATracked === true ? true : false,
    };
  };

  const [qbVendors, setQBVendors] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    let isMounted = true; // Track if the component is still mounted

    const fetchQuickBooksVendors = async () => {
      try {
        // setLoading(true);
        // setErrorMsg(null); // Reset any previous error
        const vendors = await fetchAllQuickBooksVendorNamesIds({ startsWith: '', limit: 6000 });
        
        if (isMounted) {
          // Normalize the vendor names and add a `normalizedName` property
          const normalizedVendors = vendors?.items.map(vendor => ({
            ...vendor,
            normalizedName: normalizeMerchantName(vendor.qbDisplayName)
          }));

          // console.log('Normalized QuickBooks Vendors: ', normalizedVendors);
          setQBVendors(normalizedVendors || []);
        }
      } catch (err) {
        if (isMounted) {
          console.error('Failed to fetch QuickBooks vendors:', err);
          // setErrorMsg(err.message || 'Failed to fetch vendors');
        }
      } finally {
        if (isMounted) {
          // setLoading(false);
        }
      }
    };

    if (qbEnv) {
      fetchQuickBooksVendors();
    }

    // Cleanup function to set isMounted to false
    return () => {
      isMounted = false;
    };
  }, [qbEnv, refreshIndex]);

  useEffect(() => {
    const fetchExpenseCategories = async () => {
      const results = await fetchQuickBooksExpenseCategories(qbEnv);
      const categories = results.map(expense => ({
        value: expense.qbId,
        label: expense.appName,
        isIFTATracked: (expense?.isIFTATracked===true) ? true : false,
      }));
      setExpenseCategories(categories);
    }

    fetchExpenseCategories();
  },[qbEnv, refreshIndex])

  const [ capitalOneTransactionsNeedingReceipt, setCapitalOneTransactionsNeedingReceipt ] = useState([])
  useEffect(() => {
    // Define the function to fetch and process transactions
    const fetchUploadedReceipts = async () => {
      const results = await fetchCapitalOneInstantNotificationsByStatus('needs-receipt', 750);

      // Filter out entries with purchaseDate before "2024-10-28"
      const cutoffDate = new Date("2024-10-28");
      const filteredResults = results.filter(item => {
        const purchaseDate = new Date(item.purchaseDate);
        return purchaseDate >= cutoffDate; // Keep entries on or after cutoff
      });

      // Sort the filtered array by purchaseDate in descending order
      const sortedArray = filteredResults.sort((a, b) => {
        const dateA = new Date(a.lastFour);
        const dateB = new Date(b.lastFour);
        return dateB - dateA; // Descending order: latest date first
      });

      console.log('fetchCapitalOneInstantNotificationsByStatus results: ', sortedArray);
      setCapitalOneTransactionsNeedingReceipt(sortedArray);
    };

    // Trigger fetch only if qbEnv is true
    qbEnv && fetchUploadedReceipts();
  }, [qbEnv, refreshIndex]);

  useEffect(() => {
    const fetchUploadedReceipts = async () => {
      try {
        const results = await fetchUploadedReceiptsByStatus('ready', 750);
        
        // Iterate through results to get signed URLs for each item
        const resultsWithSignedUrls = await Promise.all(
          results.map(async (item) => {
            const uploadedDocuments = item?.uploadedDocuments?.items || [];
            // Fetch signed URLs for each document in parallel
            const signedUrls = await Promise.all(
              uploadedDocuments.map(async (doc) => {
                const { identityId, key, bucket } = doc?.uploadedDocument || {};
                if (identityId && key && bucket) {
                  try {
                    const url =  await getSignedUrlForFile({
                      bucketName: bucket,
                      identityId: identityId,
                      fileName: key,
                      fileSecurity: 'protected',
                    });
                    return url;
                  } catch (error) {
                    console.error(`Error fetching signed URL for document ${key}:`, error);
                    return null; // Skip if the URL fetch fails
                  }
                }
                return null; // Skip if any value is missing
              })
            );
  
            // Attach signed URLs to the current item, filtering out any null results
            return {
              ...item,
              signedUrls: signedUrls.filter(Boolean),
            };
          })
        );
  
        // Store processed data with signed URLs in state
        setUploadedReceipts(resultsWithSignedUrls);
      } catch (error) {
        console.error('Error fetching uploaded receipts or signed URLs:', error);
      }
    };
  
    if (currentUserIsGlobalAdmin) {
      fetchUploadedReceipts();
    }
  }, [currentUserIsGlobalAdmin, refreshIndex]);

  useEffect(() => {
    const fetchSyncedTransactions = async () => {
      const results = await fetchTransactionsByStatus('pending_match', 750);
      // console.log('fetchSyncedTransactions results: ', results);
      setSyncedTransactions(results);
    }

    (currentUserIsGlobalAdmin) && fetchSyncedTransactions();
  },[currentUserIsGlobalAdmin, refreshIndex]); // change this to user is in group (creadit_card_receipt_manager) allowed to manage receipts or global admin

  useEffect(() => {
    if (uploadedReceipts.length && syncedTransactions.length && expenseCategories.length && qbVendors.length && refreshIndex) {
      const config = {
        amountField1: 'capitalOneInstantNotification.purchaseAmount',
        amountField2: 'amount',
        accountField1: 'capitalOneInstantNotification.lastFour',
        accountField2: 'accountNumber',
        dateField1: 'capitalOneInstantNotification.purchaseDate',
        dateField2: 'date',
        merchantField1: 'capitalOneInstantNotification.purchaseMerchant',
        merchantField2: 'merchantEntityName',

        amountWeight: 15,               // Reduced to lower the impact of amount matching
        amountTolerancePercentage: 20,  // Allow for tipping up to 20% of the amount (only applied on categoryIds for restaurants)
        accountWeight: 25,              // Keep accountWeight high for strict matching
        dateWeight: 10,                 // Slightly lower to emphasize merchant over date
        dateProximity: 3,               // Date proximity as 3 days for vendor to batch transactions
        merchantWeight: 50,             // Significantly increased to prioritize merchant similarity

        minimumScoreThreshold: 5,      // Keep minimum threshold low to allow for more matches

        // Separate similarity configurations for merchant and vendor matching
        minimumMerchantSimilarity: 0.8,  // Keep this high for merchantEntityName matching
        minimumQbVendorSimilarity: 0.6,  // New value for qbVendor matching to loosen it up

        // Array of category IDs representing Plaid categories for 
        // [ { "S" : "Food and Drink" }, { "S" : "Restaurants" } ] 13005000
        // [ { "S" : "Food and Drink" }, { "S" : "Bar" } ] 13001000
        // [ { "S" : "Travel" }, { "S" : "Lodging" }, { "S" : "Hotels and Motels" } ] 22012003
        // Find category for hotel and lodging.
        categoryIds: ["13005000", "13001000"], 
      };

      // Find matches using the utility function and update state
      const result = findMatchesWithQbVendors(uploadedReceipts, syncedTransactions, qbVendors, config);
      console.log('magic results: ', result);
      // const result = findMatches(uploadedReceipts, syncedTransactions, config);
      // console.log('magic results: ', result);
      setMatchResults(result);
    }
  }, [uploadedReceipts, syncedTransactions, expenseCategories, qbVendors, refreshIndex]);

  const handleScroll = (e) => {
    // if (!nextToken) return;
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      // console.log('reached the bottom of the scrollbox: ', nextToken);
      // setNextNextToken(nextToken)
    }
  };

  // const showResultsSpinner = false;
  // const [ currentTab, setCurrentTab ] = useState(0)
  // const [ currentTabName, setCurrentTabName ] = useState('Inbox')
  const [ previousTabName, setPreviousTabName ] = useState('Inbox')
  const handleTabChange = async (tab) => {
    
    // setCurrentTab(tab)
    let currentTabNames = ['Inbox', 'Archived']
    // setCurrentTabName(currentTabNames[tab])

    if (currentTabNames[tab] !== previousTabName) {
      setPreviousTabName(currentTabNames[tab])
    }
  }

  const searchHeaderTextClass = useBreakpointValue({
    sm: 'dark-sfpro-heading-2',
    md: 'dark-sfpro-heading-2',
    lg: 'dark-sfpro-heading-1',
    xl: 'dark-sfpro-heading-1',
    '2xl': 'dark-sfpro-heading-1'
  })

  const [ selectedExpenseCategoryOption, setSelectedExpenseCategoryOption ] = useState('');
  const updateSelectedExpenseCategory = (event, index) => {
    console.log('event: ', event)
    console.log('index: ', index)
    // setIsIFTATracked(event?.isIFTATracked===true ? true : false)
    setSelectedExpenseCategoryOption(event);
    setValue('selectExpenseCategory', event.value)
    clearErrors('selectExpenseCategory')
  }

  const [ currentEditTransaction, setCurrentEditTransaction ] = useState(null)
  const [ currentEditTransactionIndex, setCurrentEditTransactionIndex ] = useState(null)
  const handleOpenEditTransaction = (transaction, index) => {
    // console.log('transaction: ', transaction)
    // console.log('index: ', index)
    setCurrentEditTransaction(transaction)
    setCurrentEditTransactionIndex(index)
    // console.log('currentEditTransaction token: ', tokenObj)
  }

  useEffect(() => {
    if (currentEditTransaction && tokenObj) {
      onEditTransactionOpen();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentEditTransaction, tokenObj])

  const onEditTransactionClose = () => {
    resetMatches();
    setCurrentEditTransaction(null);
    // handleRefresh()
    editTransactionClose();
  }

  const generateProcessedRow = (match, index, isLoggedInQB) => {
    // Pre-process each value
    const matchedDate = spellOutDate(match.matchedItem.date, 'apple-ny');
    const merchantName = truncateString(match.capitalOneInstantNotification.purchaseMerchant, 19);
    const matchedMerchantName = truncateString(match.matchedItem.merchantEntityName,19);
    const qbSupplierName = match?.qbVendor?.qbDisplayName||'Unknown Supplier';
    const qbSupplierNameTruncated = truncateString(qbSupplierName, 19);
    const receiptDivision = match.division.prettyname||'';
    const signedUrls = match.signedUrls||[];
    const receiptStatus = match.capitalOneInstantNotification.status||'';
    const purchaseAmount = formatCurrency(match.capitalOneInstantNotification.purchaseAmount); // Format amount
    const matchedAmount = formatCurrency(match.matchedItem.amount); // Assume formatCurrency is a helper function
    const displayAmount = (purchaseAmount===matchedAmount) ? purchaseAmount : <>{purchaseAmount}<br/>{matchedAmount}</>;

    const lastFour = match.capitalOneInstantNotification.lastFour;
    const cardHolder = mappedTeamMembers?.find(record => record.cardLastFour === lastFour);
    // console.log('cardHolder: ', cardHolder)
    let firstName = '';
    let lastName = '';
    let cardHolderName = ''
    let division = ''
    if (cardHolder?.teamMember) {
      division = cardHolder?.teamMember?.division?.prettyname;
      const name = cardHolder?.teamMember;
      firstName = name.goesBy||name.firstName||'';
      lastName = name.lastName||'';
      cardHolderName = `${firstName} ${lastName}`
    } else {
      cardHolderName = `${lastFour}`;
      // console.log('cardHolder?.teamMember: ', cardHolder)
    }

    const cardHolderNameTruncated = truncateString(cardHolderName, 10);
    const expenseCategoryId = match.expenseCategoryId||'';
    const expenseCategoryOption = getExpenseCategory(expenseCategoryId)||'';
    const expenseCategoryName = truncateString(expenseCategoryOption?.label,19)||'Unknown Expense';
    const qbVendorMatchScore = match.qbVendorMatchScore.toFixed(4);
    const plaidMerchantMatchScore = match.matchingScore.toFixed(4);
    const qbSupplierTooltipLabel = <>Supplier: {qbSupplierName}<br />Score: {qbVendorMatchScore}</>;
    const qbMerchantTooltipLabel = <>Merchant Name: {matchedMerchantName}<br />Merchant Store: {merchantName}<br />Score: {plaidMerchantMatchScore}</>;
    const cardHolderTooltipLabel = <>User: {cardHolderName}<br />Card: {lastFour}<br />Division: {division}</>;

    const purchaseValue = match.capitalOneInstantNotification.purchaseAmount;
    const matchedValue = match.matchedItem.amount;
    const difference = Math.abs(purchaseValue - matchedValue);
    const higherAmount = Math.max(purchaseValue, matchedValue);
    const tipPercentage = (difference / higherAmount) * 100;
    const amountTooltipLabel = <>
      Receipt Amount: {purchaseAmount}<br />
      Billed Amount: {matchedAmount}<br />
      {(difference!==0) && `Difference: $${difference.toFixed(2)}`}
      {(difference!==0) && <br />}
      {(difference!==0) && `Tip Percentage: ${tipPercentage.toFixed(2)}%`}
      </>;

    // Return the entire row element
    return (
      <Fragment key={'transaction-frag_1_'+index}>
        <Tr key={`transaction-row-${index}`}
          _hover={{ 
            bg: qbSupplierName === 'Unknown' ? '#4B3400' : 'var(--table-row-hover)', 
            cursor: 'pointer' 
          }} // Conditionally set hover bg to gold if qbSupplierName is 'Unknown'
          // published
          bg={qbSupplierName === 'Unknown' ? '#3E2B00' : receiptStatus === 'fixed-issue' ? '#115611' : 'transparent'} // Change background based on condition
          onClick={() => {
            if (isLoggedInQB) {
              if (qbSupplierName==='Unknown') {
                handleOpenEditTransactionVendor(match, index)
              } else {
                handleOpenEditTransaction(match, index)
              }
            }
          }}>
          <Td verticalAlign={'top'} py={'10px'}>
            <Text as="span" className='dark-sfpro-text-2'>{matchedDate}</Text><br/>
            {(signedUrls?.length>0) && <IconButton 
              h={'36px'}
              w={'36px'}
              variant='iconOnlyTertiary' 
              icon={<AvailableIcons boxSize={'24px'} iconRef={'image'} />}
              onClick={(e) => {
                e.stopPropagation();  // Stop event from bubbling to <Tr>
                handleOpenGallery(signedUrls);
                // handleOpenGallery(["/images/placeholder.jpg", "/images/placeholder2.jpg"]);
              }}
              // isDisabled={isDisabled}
              // mx={mx}
              // my={my}
            />}
          </Td>
          <Td verticalAlign={'top'} py={'10px'}>
            <Tooltip label={qbSupplierTooltipLabel}>
              <Text as="span" className='dark-sfpro-text-2'>{qbSupplierNameTruncated}</Text>
            </Tooltip>
          </Td>
          <Td verticalAlign={'top'} py={'10px'}>
            <Tooltip label={qbMerchantTooltipLabel}>
              <Text as="span" className='dark-sfpro-text-2'>{matchedMerchantName}<br />{toTitleCase(merchantName)}</Text>
            </Tooltip>
          </Td>
          <Td verticalAlign={'top'} py={'10px'}>
            <Text as="span" className='dark-sfpro-text-2'>{expenseCategoryName}<br />{receiptDivision}</Text>
          </Td>
          <Td verticalAlign={'top'} align='right' py={'10px'}>
            <Tooltip label={amountTooltipLabel}>
              <Text as="span" className='dark-sfpro-text-2'>{displayAmount}</Text>
            </Tooltip>
          </Td>
          <Td verticalAlign={'top'} py={'10px'}>
            <Tooltip label={cardHolderTooltipLabel}>
              <Text as="span" className='dark-sfpro-text-2'>{cardHolderNameTruncated}</Text>
            </Tooltip>
          </Td>
          <Td verticalAlign={'middle'}>
            <ButtonSecondaryPlain 
              width="120px"
              type="button"
              name='test'
              value={isSubmitting ? 'Publishing...' : 'Publish'}
              isDisabled={isSubmitting===true || qbSupplierName==='Unknown' || !isLoggedInQB || expenseCategoryOption===''}
              onClick={(e) => {
                e.stopPropagation();  // Stop event from bubbling to <Tr>
                handleSubmit(() => {
                  console.log('published');
                  handleRowPublish(match)
                }, onError)();
              }}
            />
          </Td>
        </Tr>
        <Tr key={`divider-row-${index}`}><Td colSpan={7} borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr>
      </Fragment>
    );
  };
  
  // CHART OF ACCOUNTS
  const [ dynamodbChartOfAccounts, setDynamoDBChartOfAccounts ] = useState([])
  const [ mappedTeamMembers, setMappedTeamMembers ] = useState([])

  const fetchAndProcessChartOfAccounts = async () => {
    // fetch local chart of accounts aand store in state
    const ddbChartOfAccounts = await fetchAllChartOfAccounts()
    setDynamoDBChartOfAccounts(ddbChartOfAccounts)

    const quickBooksChartOfAccounts = await getChartOfAccounts(tokenObj?.accessToken, qbEnv)
    console.log('quickBooksChartOfAccounts: ', quickBooksChartOfAccounts)
    if (quickBooksChartOfAccounts?.code==="3200") {
      alert("QuickBooks Token Expired. Relogin to QuickBooks");
      return;
    }
    const { creates, updates } = compareDatasets(ddbChartOfAccounts, quickBooksChartOfAccounts?.Account);

    if (creates.length > 0 || updates.length > 0) {
      await updateDynamoDBChartOfAccounts(creates, updates);
    }
  }

  const updateDynamoDBChartOfAccounts = async (creates, updates) => {
    for (const item of creates) {
      const input = {
        qbChartOfAccountsId: item.Id,
        qbChartOfAccountsName: item.Name,
        qbChartOfAccountsFullyQualifiedName: item.FullyQualifiedName,
        qbChartOfAccountsActive: item.Active.toString(),
        allSort: (qbEnv === 'sandbox') ? 'sandbox' : 'all',
      };
  
      try {
        // const result = await addChartOfAccounts(input);
        const result = await addOrUpdateChartOfAccounts(input);
        console.log('DynamoDB created:', result)
      } catch (error) {
        console.error('Error creating DynamoDB:', error);
      }
    }
    for (const item of updates) {
      const input = {
        qbChartOfAccountsId: item.Id,
        qbChartOfAccountsName: item.Name,
        qbChartOfAccountsFullyQualifiedName: item.FullyQualifiedName,
        qbChartOfAccountsActive: item.Active.toString(),
        allSort: (qbEnv === 'sandbox') ? 'sandbox' : 'all',
      };
  
      try {
        // await updateChartOfAccounts(input);
        const result = await addOrUpdateChartOfAccounts(input);
        console.log('DynamoDB updated:', result)
      } catch (error) {
        console.error('Error updating DynamoDB:', error);
      }
    }
  };
  
  const compareDatasets = (dynamoDBData, quickBooksData) => {
    const updates = [];
    const creates = [];
  
    quickBooksData.forEach(qbItem => {
      const match = dynamoDBData.find(dynamoItem => dynamoItem.qbChartOfAccountsId === qbItem.Id);
  
      if (!match) {
        creates.push(qbItem);
      } else if (!areObjectsEqual(match, qbItem)) {
        updates.push(qbItem);
      }
    });
  
    return { creates, updates };
  };
  
  const areObjectsEqual = (dynamoItem, qbItem) => {
    return (
      dynamoItem.qbChartOfAccountsId === qbItem.Id &&
      dynamoItem.qbChartOfAccountsName === qbItem.Name &&
      dynamoItem.qbChartOfAccountsFullyQualifiedName === qbItem.FullyQualifiedName &&
      dynamoItem.qbChartOfAccountsActive === qbItem.Active.toString()
    );
  };

  const removeTeamMemberMapEntry = (qbChartOfAccountsId) => {
    setValue(`selectTeamMember_${qbChartOfAccountsId}`, {label: '', id: ''})
    setSelectedTeamMemberMap((prev) => {
      const updatedMap = { ...prev };
      delete updatedMap[qbChartOfAccountsId];
      return updatedMap;
    });
  };

  const [ selectedTeamMemberMap, setSelectedTeamMemberMap ] = useState([])

  const updateSelectedTeamMemberMap = (
    qbChartOfAccountsId, 
    selectedTeamMemberId, 
  ) => {
    setSelectedTeamMemberMap((prev) => ({
      ...prev,
      [qbChartOfAccountsId]: selectedTeamMemberId
    }));
  }

  const updateTeamMemberMap = async (accountId) => {
    console.log('accountId: ', accountId)
    const isExistingMapping = mappedTeamMembers.find(mapping => mapping.qbChartOfAccountsId === accountId);
    console.log('mappedTeamMembers: ', mappedTeamMembers)
    console.log('isExistingMapping: ', isExistingMapping)
    const input = {
      qbChartOfAccountsId: accountId,
      teamMemberId: selectedTeamMemberMap[accountId],
      isActive: true,
      isDefault: true
    };
    if (isExistingMapping) {
      // update ddb then refresh
      try {
        await updateCreditCardTeamMember(input);
        fetchMappedTeamMembers();
      } catch (error) {
        console.error('Error updating DynamoDB:', error);
      }
    } else {
      // create new mapping
      try {
        await addCreditCardTeamMemberMap(input);
        fetchMappedTeamMembers();
      } catch (error) {
        console.error('Error creating DynamoDB:', error);
      }
    }
  }

  const [ allTeamMemberNames, setAllTeamMemberNames ] = useState([])
  const fetchAllTeamMemberNames = async () => {
    const fetchedTeamMembers = await fetchAllTeamMembers();
    // console.log('fetchedTeamMembers: ', fetchedTeamMembers)
    const teamMembers = [{ id: '00000', firstName: 'Select Team Member', goesBy: '', lastName: '' }, ...fetchedTeamMembers];
    setAllTeamMemberNames(teamMembers);
  };

  useEffect(() => {
    if (currentUserIsGlobalAdmin) {
      const fetchData = async () => {
        await fetchAllTeamMemberNames();
        const mappedMembers = await fetchMappedTeamMembers();
        await fetchDynamoDbChartOfAccounts(mappedMembers);
      };

      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserIsGlobalAdmin]);

  const fetchMappedTeamMembers = async (tmId) => {
    if (currentUserIsGlobalAdmin) {
      const mappedTeamMembers = await fetchAllCreditCardTeamMemberMaps();
      setMappedTeamMembers(mappedTeamMembers);
      return mappedTeamMembers;
    } else {
      const mappedTeamMembers = await fetchAccountsByTeamMember(tmId);
      setMappedTeamMembers(mappedTeamMembers);
      return mappedTeamMembers;
    }
  };

  useEffect(() => {
    const fetchTeamMemberMap = async (environment) => {
      const mappedTeamMembers = await fetchAllCreditCardTeamMemberMaps(environment);
      // console.log('mappedTeamMembers: ', mappedTeamMembers)
      setMappedTeamMembers(mappedTeamMembers);
    };
    if (qbEnv) {
      fetchTeamMemberMap(qbEnv);
    }
  },[qbEnv])

  const fetchDynamoDbChartOfAccounts = async (mappedTeamMembers) => {
    const ddbChartOfAccounts = await fetchAllChartOfAccounts();
    // console.warn('ddbChartOfAccounts: ', ddbChartOfAccounts);

    // Initialize the selectedTeamMemberMap with existing mappings
    const initialMap = {};
    ddbChartOfAccounts.forEach(account => {
      initialMap[account.qbChartOfAccountsId] = '';
    });
    mappedTeamMembers.forEach(mapping => {
      initialMap[mapping.qbChartOfAccountsId] = mapping.teamMemberId;
    });

    setSelectedTeamMemberMap(initialMap);
    setDynamoDBChartOfAccounts(ddbChartOfAccounts);
  };

  const generateChartOfAccountsRows = (accounts, mappedtms) => {
    return accounts?.map((item, index) => {
      const mappedMember = mappedtms.find(mapping => mapping.qbChartOfAccountsId === item.qbChartOfAccountsId);

      let selectedTeamMember = '';
      if (mappedMember && selectedTeamMemberMap[item?.qbChartOfAccountsId] && selectedTeamMemberMap[item?.qbChartOfAccountsId] !== mappedMember?.teamMemberId) {
        const matchedTeamMember = allTeamMemberNames.find(tm => tm.id === selectedTeamMemberMap[item?.qbChartOfAccountsId]);
        const matchedGoesBy = (matchedTeamMember?.goesBy) ? `"${matchedTeamMember?.goesBy}"` : '';
        const matchedName = `${matchedTeamMember?.firstName} ${matchedGoesBy} ${matchedTeamMember?.lastName}`;
        selectedTeamMember = {label: matchedName, value: `${matchedTeamMember?.id}`};
      } else if (mappedMember?.teamMember) {
        const mappedGoesBy = (mappedMember?.teamMember?.goesBy) ? `"${mappedMember?.teamMember?.goesBy}"` : '';
        const mappedName = `${mappedMember?.teamMember?.firstName} ${mappedGoesBy} ${mappedMember?.teamMember?.lastName}`;
        selectedTeamMember = {label: mappedName, value: mappedMember?.teamMemberId};
      } 

      // let showButtonCell = false;
      const teamMemberIsMapped = (selectedTeamMemberMap[item?.qbChartOfAccountsId]?.length>0) ? true : false
      const selectedTeamMemberCOAId = selectedTeamMemberMap[item?.qbChartOfAccountsId]
      const mappedTeamMemberCOAId = mappedMember?.qbChartOfAccountsId
      const accountCOAId = item?.qbChartOfAccountsId
      const mappedTeamMemberId = mappedMember?.teamMemberId

      return (
        <Fragment key={`frag_${index}`}>
          <Tr key={`tr_${index}`}>
            {/* <Td>
              <Box>
                <FormSwitch
                  register={register}
                  control={control}
                  size={'md'}
                  fieldname={`accountActive_${item.qbChartOfAccountsId}`}  // unique field name
                  isChecked={isAccountActive}
                  onChange={(e) => {
                    setValue(`accountActive_${item.qbChartOfAccountsId}`, !isAccountActive);
                    setAccountActiveState(prev => ({
                      ...prev,
                      [item.qbChartOfAccountsId]: !isAccountActive
                    }));
                  }}
                />
              </Box>
            </Td> */}
            {/* <Td>{item?.qbChartOfAccountsId}</Td> */}
            {/* <Td>{item?.qbChartOfAccountsActive}</Td> */}
            <Td>{item?.qbChartOfAccountsName}</Td>
            <Td>
              <Box>
                <FormSelectSimple
                  key={`teamMember_${accountCOAId}`}
                  register={register}
                  control={control}
                  errors={errors}
                  isRequired={true}
                  fieldName={`selectTeamMember_${accountCOAId}`}
                  placeholder={'Select Team Member'}
                  optionsArray={allTeamMemberNames?.map((tm, index) => ({
                    key: index,
                    value: tm.id,
                    label: tm.goesBy?.length>0 
                      ? `${tm.firstName} "${tm.goesBy}" ${tm.lastName}` 
                      : `${tm.firstName} ${tm.lastName}`
                  }))}
                  onChange={(e) => updateSelectedTeamMemberMap(accountCOAId, e?.value, mappedTeamMemberCOAId)}
                  selectedoption={selectedTeamMember}
                />
              </Box>
            </Td>
            <Td>
              {(teamMemberIsMapped) && 
                <Box display={'flex'} gap={'5px'}>
                  {((selectedTeamMemberCOAId 
                      && mappedTeamMemberCOAId !== accountCOAId) 
                      || mappedTeamMemberId !== selectedTeamMemberMap[accountCOAId])
                    && 
                    <ButtonPrimaryPlain 
                      type="button"
                      name='updateTeamMemberMap'
                      value={isSubmitting ? 'Updating...' : 'Update'}
                      isDisabled={isSubmitting===true}
                      onClick={handleSubmit(() => {
                        updateTeamMemberMap(accountCOAId);
                      }, onError)}
                    />
                  }
                  {((selectedTeamMemberCOAId 
                      && mappedTeamMemberCOAId !== accountCOAId) 
                      || mappedTeamMemberId !== selectedTeamMemberMap[accountCOAId])
                    && <ButtonPrimaryPlain 
                    type="button"
                    name='resetTeamMemberMap'
                    value={'Cancel'}
                    isDisabled={isSubmitting===true}
                    onClick={handleSubmit(() =>{
                      removeTeamMemberMapEntry(accountCOAId);
                    })}
                  />}
                </Box>
              }
            </Td>
          </Tr>
          <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
        </Fragment>
      );
    });
  }
  // CHART OF ACCOUNTS

  //onSubmit={handleSubmit(updateReceipt)}
  const handlePublish = async (formData) => {

    console.log('formData: ', formData)
    // setIsPublishing(true);
  
    try {
      // Collect necessary data for submission
      const props = {
        matchStatus: formData.matchStatus,
        id: formData.id,  // The current transaction ID
        // vendorId: formData.selectQuickBooksVendor || transaction?.qbVendor?.qbId,  // Vendor selected in the form or existing one
        vendorId: formData.selectQuickBooksVendor||formData.vendorId,  // Vendor selected in the form or existing one
        issue: formData.issue||'',
      };

      props.hasIssue = formData.hasIssue 
      ? (formData.hasIssue === 'true' || formData.hasIssue === true)  // Convert 'true' (string) or true (boolean) to true
      : false;  // Default to false if formData.hasIssue does not exist

      if (formData.selectExpenseCategory !== 'undefined') {
        props.expenseCategoryId = formData.selectExpenseCategory||formData.expenseCategoryId||'';
      }
  
      // Call the updateReceipt function, passing the necessary data
      console.log('sending props to updateReceipt: ', props)
      const results = await updateReceipt(props);
      
      // Handle successful publishing
      console.log('Transaction action results:', results);
      // setIsPublishing(false);
      // onClose();  // Optionally close the form or refresh the state
  
    } catch (error) {
      console.error('Error publishing transaction:', error);
      // setIsPublishing(false);
    }

    onEditTransactionClose();
  };

  // When publish button is clicked from within a row
  const handleRowPublish = async (transaction) => {
    // You can directly call handlePublish with predefined data
    const formData = {
      selectExpenseCategory: transaction.expenseCategoryId,
      selectQuickBooksVendor: transaction.qbVendor?.qbId,
    };
    await handlePublish(formData, transaction.id);  // Call handlePublish with row transaction data
  };

  const updateReceipt = async (props) => {
    // eslint-disable-next-line react/prop-types
    const { 
      id, 
      matchStatus, 
      hasIssue, 
      issue, 
      expenseCategoryId, 
      // vendorId 
    } = props;
    try {
      
        const response = await updateCreditCardTransactionReceipt({
          id,
          matchStatus,
          hasIssue,
          issue,
          expenseCategoryId,
          // vendorId,
        });
        
        return response;

      // if (matchStatus === 'fixed-issue') {
      //   console.log('Receipt updated successfully');
      // }

      // if (matchStatus === 'has-issue') {
      //   const response = await updateCreditCardTransactionReceipt({
      //     id,
      //     matchStatus,
      //     // expenseCategoryId,
      //     // vendorId,
      //   });
    
      //   if (response.success) {
      //     console.log('Receipt updated successfully');
      //     return response;
      //   } else {
      //     throw new Error('Failed to update receipt');
      //   }
      // }
      
      
    } catch (error) {
      console.error('Error updating receipt:', error);
    }
  };

  
  

  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  const onSortSubmittedReceipts = (field) => {
    let direction = 'asc';
    if (sortConfig.key === field && sortConfig.direction === 'asc') {
      direction = 'desc'; // Toggle to descending if already sorted ascending
    }
  
    setSortConfig({ key: field, direction });

    // Sorting logic based on field and direction
    const sortedData = [...matchResults.matches].sort((a, b) => {
      let valA, valB;
  
      // Custom sorting logic for different fields
      if (field === 'card') {
        valA = a.capitalOneInstantNotification.lastFour;
        valB = b.capitalOneInstantNotification.lastFour;
      } else if (field === 'supplier') {
        valA = a.qbVendor?.qbDisplayName;
        valB = b.qbVendor?.qbDisplayName;
      } else if (field === 'expense-category') {
        valA = a?.expenseCategoryId;
        valB = b?.expenseCategoryId;
      } else if (field === 'merchant') {
        valA = a.matchedItem.merchantEntityName;
        valB = b.matchedItem.merchantEntityName;
      } else if (field === 'date') {
        valA = new Date(a.matchedItem.date);
        valB = new Date(b.matchedItem.date);
      } else if (field === 'amount') {
        // Remove $ and commas, then parse as float for numeric comparison
        const parseAmount = (amount) => {
          // Handle cases where amount is a string (e.g., "$10.91")
          if (typeof amount === 'string') {
            return parseFloat(amount.replace(/[^0-9.-]+/g, ''));
          }
          return amount; // If already a number, return as is
        };
        valA = parseAmount(a.capitalOneInstantNotification.purchaseAmount);
        valB = parseAmount(b.capitalOneInstantNotification.purchaseAmount);
      } else {
        valA = a[field];
        valB = b[field];
      }
  
      // Compare values based on the direction
      if (direction === 'asc') {
        return valA > valB ? 1 : valA < valB ? -1 : 0;
      } else {
        return valA < valB ? 1 : valA > valB ? -1 : 0;
      }
    });

    console.log('sortedData: ', sortedData)
  
    // Update only the matches array in the state while preserving other arrays
    setMatchResults((prevResults) => ({
      ...prevResults,  // Preserve the other arrays (unmatchedFromArray1, unmatchedFromArray2)
      matches: sortedData,  // Update only the matches array with sorted data
    }));
  };

  const [ currentEditTransactionVendor, setCurrentEditTransactionVendor ] = useState(null)
  const [ currentEditTransactionVendorIndex, setCurrentEditTransactionVendorIndex ] = useState(null)
  const handleOpenEditTransactionVendor = (transaction, index) => {
    // console.log('handleOpenEditTransactionVendor: ', transaction);
    setCurrentEditTransactionVendor(transaction);
    setCurrentEditTransactionVendorIndex(index);
  }

  useEffect(() => {
    currentEditTransactionVendor && onEditTransactionVendorOpen();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentEditTransactionVendor])

  const onEditTransactionVendorClose = () => {
    setCurrentEditTransactionVendor(null)
    // handleRefresh()
    editTransactionVendorClose()
  }

  // const handleOpenGallery = (imagesArray) => {
  //   console.log('openGallery: ', imagesArray)
  //   setGalleryImages(imagesArray)
  // }
  // useEffect(() => {
  //   if (galleryImages.length && galleryImages.length>0) {
  //     console.log('about to open gallery')
  //     onGalleryModalOpen();
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[galleryImages])
    
  // const [ manuallyMappedVendor, setManuallyMappedVendor ] = useState({});
  // const [ isNewQBVendor, setIsNewQBVendor ] = useState(false);
  // const [ newQBVendorName, setNewQBVendorName ] = useState('');
  // const [ newVendorId, setNewVendorId ] = useState('');
  const mapQBVendorToStatementVendor = (vendorObj, index) => {

    console.log('vendorObj: ', vendorObj)
    console.log('index: ', index)

    // setManuallyMappedVendor(vendorObj);

    if (!vendorObj?.id && vendorObj?.name) {
      // setNewQBVendorName(vendorObj.name);
      // setIsNewQBVendor(true);
    }
  };

  // function to filter results based on searchterm
  const [ showSearchReset, setShowSearchReset ] = useState(false);
  const filterMatchedData = (e) => {
    const searchTerm = e.target.value;
    const searchTermLength = searchTerm.length;

    console.log('searchTerm: ', searchTerm)
    console.log('searchTermLength: ', searchTermLength)
    
    if (searchTermLength > 0) {
      setShowSearchReset(true);
    }
    const filteredResults = matchResults.matches.filter((match) => {
      const merchantName = match.matchedItem.merchantEntityName.toLowerCase();
      const supplierName = match?.qbVendor?.qbDisplayName.toLowerCase()||'';
      const amount = match.capitalOneInstantNotification.purchaseAmount.toString();
      const card = match.capitalOneInstantNotification.lastFour.toString();
      const searchLower = searchTerm.toLowerCase();
      
      return (
        merchantName.includes(searchLower) ||
        supplierName.includes(searchLower) ||
        amount.includes(searchLower) ||
        card.includes(searchLower)
      );
    });

    console.log('filteredResults: ', filteredResults)
    setMatchResults((prevResults) => ({
      ...prevResults,
      matches: filteredResults,
    }));
  }

  const resetMatches = () => {
    setValue('searchInput', '')
    setRefreshIndex(prev => prev + 1)
    setShowSearchReset(false);
  }

  useEffect(() => {
    resetMatches();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <>
      <QuickBooksAuth 
        qbAppId='ManageExpenses'
        qbEnv={qbEnv}
        onTokenChange={setTokenObj}
        >
        {({ isLoggedInQB, loginQB, logoutQB }) => (
          <>
            <Container 
              // maxW="1900" 
              mb={'50px'} as="form" onSubmit={handleSubmit(updateReceipt, onError)}>
                
              <QBLoginLogoutButtons 
                isLoggedInQB={isLoggedInQB}
                loginQB={loginQB}
                logoutQB={logoutQB}
              />

              <ModuleBox>
                <Box>
                  <Text as="span" textStyle="heading-1">Search Receipts</Text>
                </Box>
                <Box mt={'10px'}>
                  <Text>Search visible results by amount, vendor.</Text>
                </Box>
                <Box w={'40%'} my={'25px'}>
                  <Grid templateColumns="1fr auto" gap={4} alignItems="end">
                    <TextInput
                      register={register}
                      errors={errors}
                      fieldname="searchInput"
                      fieldlabel="Search"
                      prettyname="Search"
                      placeholder="Enter search term"
                      onChange={filterMatchedData}
                      height={'40px'}
                    />

                    {showSearchReset && (
                      <ButtonPrimaryPlain
                        type="button"
                        onClick={resetMatches}
                        height={'40px'}
                        name="resetMatches"
                        value="Reset"
                        px={6}  // Add padding for button
                      />
                    )}
                  </Grid>
                </Box>
                
              </ModuleBox>

              <ModuleBox>
                <Tabs 
                  borderColor='var(--dark-module-divider)' 
                  variant='enclosed' 
                  colorScheme='spikedarktabs' 
                  onChange={(index) => handleTabChange(index)}>
                  <TabList>
                    <Tab><Text>Ready ({matchResults?.matches?.length})</Text></Tab>
                    <Tab><Text>Pending Receipt ({capitalOneTransactionsNeedingReceipt?.length})</Text></Tab>
                    <Tab><Text>Pending Match ({matchResults?.unmatchedFromArray1?.length})</Text></Tab>
                    <Tab><Text>Issues</Text></Tab>
                    <Tab><Text>Posted ({syncedTransactions?.length})</Text></Tab>
                    <Tab><Text>Chart of Accounts</Text></Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <TableContainer>
                        <HStack paddingBottom={'10px'}>
                          <Box w={'100%'}>
                            <Text className={searchHeaderTextClass}>Ready</Text>
                          </Box>
                          <Box>
                            <ButtonQuaternaryWithIcon
                              name='refresh'
                              leftIcon='refresh'
                              iconsize='22px'
                              isDisabled={!isLoggedInQB}
                              onClick={() => { handleRefresh(); }}
                              value='Refresh page'
                            />
                          </Box>
                        </HStack>
                          <Box
                            height={'600px'}
                            overflowY={isEditTransactionVendorOpen||isEditTransactionOpen ? "hidden" : "scroll"} // Control Y overflow
                            overflowX={isEditTransactionVendorOpen||isEditTransactionOpen ? "hidden" : "auto"} // Control X overflow
                            // border="1px solid"
                            // borderColor="var(-dark-component-border)"
                            borderRadius="6px"
                            // p={2}
                            // onScroll={handleScroll}
                          >
                          <Table variant={'compact'} size={'compact'}>
                            <Thead>
                              <Tr>
                                {/* Date Header */}
                                <Th verticalAlign={'top'}>
                                  <Text
                                    as="span"
                                    textStyle='dark-heading-4'
                                    cursor="pointer"
                                    onClick={() => onSortSubmittedReceipts('date')}
                                  >Date</Text>
                                </Th>
                                {/* QB Supplier Header */}
                                <Th verticalAlign={'top'}>
                                  <Text
                                    as="span"
                                    textStyle='dark-heading-4'
                                    cursor="pointer"
                                    onClick={() => onSortSubmittedReceipts('supplier')}
                                  >Supplier</Text>
                                </Th>
                                {/* Merchant Name Header */}
                                <Th verticalAlign={'top'}>
                                  <Text
                                    as="span"
                                    textStyle='dark-heading-4'
                                    cursor="pointer"
                                    onClick={() => onSortSubmittedReceipts('merchant')}
                                  >Merchant Name<br />Merchant Store</Text>
                                </Th>
                                {/* Expense Category Header */}
                                <Th verticalAlign={'top'}>
                                  <Text
                                    as="span"
                                    textStyle='dark-heading-4'
                                    cursor="pointer"
                                    onClick={() => onSortSubmittedReceipts('expense-category')}
                                  >Expense Category</Text>
                                </Th>

                                {/* Amount Header */}
                                <Th verticalAlign={'top'}>
                                  <Text
                                    as="span"
                                    textStyle='dark-heading-4'
                                    cursor="pointer"
                                    onClick={() => onSortSubmittedReceipts('amount')}
                                  >Amount</Text>
                                </Th>
                                {/* Card Header */}
                                <Th verticalAlign={'top'}>
                                  <Text
                                    as="span"
                                    textStyle='dark-heading-4'
                                    cursor="pointer"
                                    onClick={() => onSortSubmittedReceipts('card')}
                                  >User</Text>
                                </Th>
                                <Th></Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {!matchResults.matches.length && <Tr><Td colSpan={'6'} py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                              {matchResults?.matches?.map((match, index) => (
                                generateProcessedRow(match, index, isLoggedInQB)
                              ))}
                            </Tbody>
                          </Table>
                        </Box>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel>
                      <TableContainer>
                        <Text className={searchHeaderTextClass}></Text>
                        <Box
                          height={'600px'}
                          // width={width||'100%'}
                          overflowY="scroll"
                          border="1px solid"
                          borderColor="var(-dark-component-border)"
                          borderRadius="6px"
                          p={2}
                          onScroll={handleScroll}
                        >
                          <Table variant={'compact'} size={'compact'} >
                            <Thead>
                              <Tr>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Date</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Merchant</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Amount</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Card</Th>
                                {/* <Th><Text as="span" textStyle='dark-heading-4'></Text>Receipt Uploaded</Th> */}
                              </Tr>
                            </Thead>
                            <Tbody>
                                {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                                {(filteredData?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr> }
                              
                              {generateRows(filteredData)} */}
                              {capitalOneTransactionsNeedingReceipt?.map((needReceipt, index) => {
                                const lastFour = needReceipt?.lastFour;
                                const cardHolder = mappedTeamMembers?.find(record => record.cardLastFour === lastFour);
                                // console.log('cardHolder: ', cardHolder)
                                let firstName = '';
                                let lastName = '';
                                let cardHolderName = ''
                                if (cardHolder?.teamMember) {
                                  const name = cardHolder?.teamMember;
                                  firstName = name.goesBy||name.firstName||'';
                                  lastName = name.lastName||'';
                                  cardHolderName = `${firstName} ${lastName}`
                                } else {
                                  cardHolderName = `${lastFour}`;
                                  // console.log('cardHolder?.teamMember: ', cardHolder)
                                }
                            
                                const cardHolderNameTruncated = truncateString(cardHolderName, 10);
                                return (
                                <Tr key={index}>
                                  <Td>{needReceipt?.purchaseDate}</Td>
                                  <Td>{needReceipt?.purchaseMerchant}</Td>
                                  <Td>{needReceipt?.purchaseAmount}</Td>
                                  <Td>{cardHolderNameTruncated} {needReceipt?.lastFour}</Td>
                                </Tr>
                              )})}
                              
                              <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                            </Tbody>
                            {/* {(showResultsSpinner) && <Tbody><Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr></Tbody>} */}

                          </Table>
                        </Box>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel>
                      <TableContainer>
                        <Text className={searchHeaderTextClass}></Text>
                        <Box
                          height={'600px'}
                          // width={width||'100%'}
                          overflowY="scroll"
                          border="1px solid"
                          borderColor="var(-dark-component-border)"
                          borderRadius="6px"
                          p={2}
                          onScroll={handleScroll}
                        >
                          <Text as="span" textStyle='dark-heading-3'>
                            Submitted receipts pending a match to a posted transaction. <br />
                            Transaction may not have posted yet or is missed by the matching algorithm. <br />
                            Need a way to match to trasnaction manually.</Text>
                          <Table variant={'compact'} size={'compact'} >
                            <Thead>
                              <Tr>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Date</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Merchant</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Expense Category</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Amount</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Card</Th>
                                {/* <Th><Text as="span" textStyle='dark-heading-4'></Text>Receipt Uploaded</Th> */}
                              </Tr>
                            </Thead>
                            <Tbody>
                                {/* {(showResultsSpinner===true) && <Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr>}
                                {(filteredData?.length===0 && showResultsSpinner===false) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr> }
                              
                              {generateRows(filteredData)} */}
                              {matchResults?.unmatchedFromArray1?.map((unamtched, index) => (
                                // console.log('receipt: ', receipt),
                                <Tr key={index}>
                                  <Td>{unamtched?.capitalOneInstantNotification?.purchaseDate}</Td>
                                  <Td>{unamtched?.capitalOneInstantNotification?.purchaseMerchant}</Td>
                                  <Td>{unamtched?.expenseCategoryId}</Td>
                                  <Td>{unamtched?.capitalOneInstantNotification?.purchaseAmount}</Td>
                                  <Td>{unamtched?.capitalOneInstantNotification?.lastFour}</Td>
                                  {/* <Td>{(unamtched?.capitalOneInstantNotification?.isSubmitted) ? 'Yes' : 'No'}</Td> */}
                                </Tr>
                              ))}
                              
                              <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                            </Tbody>
                            {/* {(showResultsSpinner) && <Tbody><Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr></Tbody>} */}

                          </Table>
                        </Box>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel>issues</TabPanel>
                    <TabPanel>
                      <TableContainer>
                        <Text className={searchHeaderTextClass}></Text>
                        <Box
                          height={'600px'}
                          // width={width||'100%'}
                          overflowY="scroll"
                          border="1px solid"
                          borderColor="var(-dark-component-border)"
                          borderRadius="6px"
                          p={2}
                          onScroll={handleScroll}
                        >
                          <Text as="span" textStyle='dark-heading-3'>Posted transactions that are unmatched and have no submitted receipts. <br />
                          These fall outside of the matching filter and any of these older than 5 days should probably be flagged.<br />
                          {syncedTransactions.length}</Text>
                          <Table variant={'compact'} size={'compact'} >
                            <Thead>
                              <Tr>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Posted Date</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Plaid Merchant</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Plaid Store</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Amount</Th>
                                <Th><Text as="span" textStyle='dark-heading-4'></Text>Card</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                            {syncedTransactions?.map((transaction, index) => (
                              // console.log('transaction: ', transaction),
                              <Tr key={index}>
                                <Td>{transaction.date}</Td>
                                <Td>{transaction.merchantEntityName}</Td>
                                <Td>{transaction.store}</Td>
                                <Td>{transaction.amount}</Td>
                                <Td>{transaction.accountNumber}</Td>
                              </Tr>
                            ))}

                              
                              <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                            </Tbody>
                            {/* {(showResultsSpinner) && <Tbody><Tr><Td py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr></Tbody>} */}

                          </Table>
                        </Box>
                      </TableContainer>
                    </TabPanel>
                    {/* <TabPanel>
                      <VendorMap 
                        handleMapVendor={mapQBVendorToStatementVendor} 
                        vendors={qbVendors} 
                        environment={qbEnv}
                        token={tokenObj?.accessToken}
                        control={control}
                        register={register}
                        errors={errors}
                        setError={setError}
                        clearErrors={clearErrors}
                      />
                    </TabPanel> */}
                    <TabPanel>
                      <>
                        <Box>
                          <Flex alignItems="center" justifyContent="space-between" pb={'25px'}>
                            <Box>
                              <Text className={searchHeaderTextClass}>Sync Accounts</Text>
                            </Box>
                            <Spacer />
                            <Box>
                              <ButtonQuaternaryWithIcon 
                                name='closeDrawer'
                                iconsize='26px'
                                leftIcon='close'
                                value='Get QuickBooks Accounts'
                                onClick={fetchAndProcessChartOfAccounts}
                              />
                            </Box>
                          </Flex>
                          <Box>
                            <TableContainer>
                              <HStack mb='15px'>
                                <Text className={searchHeaderTextClass}>QuickBooks Accounts - Team Members</Text>
                                <Spacer/>
                              </HStack>
                              <Table variant={'compact'} size={'compact'} >
                                <Tbody>
                                  {(dynamodbChartOfAccounts?.length===0) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                                  
                                  {dynamodbChartOfAccounts && generateChartOfAccountsRows(dynamodbChartOfAccounts, mappedTeamMembers)}
                                  
                                  <Tr><Td borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                                </Tbody> 
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>
                      </>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </ModuleBox>
            </Container>

            <ModuleDrawer
              onClose={onEditTransactionClose}
              isOpen={isEditTransactionOpen}
              bodyContent={
                <EditTransaction 
                  handleMapVendor={mapQBVendorToStatementVendor} 
                  transaction={currentEditTransaction} 
                  onClose={onEditTransactionClose} 
                  environment={qbEnv}
                  expenseCategories={expenseCategories}
                  selectedExpenseCategory={selectedExpenseCategoryOption}
                  qbVendors={qbVendors}
                  token={tokenObj?.accessToken||tokenObj?.access_token}
                  register={register}
                  control={control}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  updateExpenseCategory={updateSelectedExpenseCategory}
                  rowIndex={currentEditTransactionIndex}
                  updateReceipt={handlePublish}
                  handleSubmit={handleSubmit}
                />}
              size={'full'}
            />

            <ModuleDrawer
              onClose={onEditTransactionVendorClose}
              isOpen={isEditTransactionVendorOpen}
              bodyContent={<EditTransactionVendor 
                  transaction={currentEditTransactionVendor} 
                  onClose={onEditTransactionVendorClose} 
                  environment={qbEnv}
                  qbVendors={qbVendors}
                  mapVendor={mapQBVendorToStatementVendor}
                  token={tokenObj?.accessToken||tokenObj?.access_token}
                  register={register}
                  control={control}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  rowIndex={currentEditTransactionVendorIndex}/>
              }
              size={'full'}
            />

            {(galleryImages?.length>0) && <GalleryModal
              isModalOpen={isGalleryModalOpen}   // Corrected prop name to match GalleryModal expectations
              onModalClose={handleGalleryModalClose}
              images={galleryImages}
            />}
          </>
        )}
      </QuickBooksAuth>
    </>
  )
}


// show transactions with uploaded receipts and their matching transactions from plaid. (fetch both and amtch on lastFour, vendorName, purchaseAmount, purchaseDate)
// for each transaction:
//    - show the transaction date.
//    - show the vendor name.
//    - show the receipt image.
//    - show the transaction amount.
//    - show the transaction category.
//    - show the transaction account.
// show tooltip with details for a quick view of the transaction.
// show approve/deny buttons for each transaction.
// show edit button for each transaction.
// approving a transaction will mark it as approved, upload it to quickbooks (match vendor to merchant?) and will be visible in the approved transactions tab.
// when a merchant does not exist, it gets created in quickbooks

// vendor dropdown
// tabs

// qb vendor dropdown and auto-match using mapped vendor table
// match to vendors, when a merchant does not exist, it gets created in quickbooks
// button to push to QB (approve or deny)
// get receipt image
// mutable detail view
// handle if uploaded receipt is invoice to be paid? table has refund, invoice, receipt
// tabs for ready, denied, pending
// X    no_match_found to pending_match
// plaidSyncTransactions on timer or trigger
// onTabChange does not reload, add refresh button

// X    reset transactions
// X    resync transactions
// verify cursor is there and no duplciate transactions

// getChartOfAccounts calls the Lambda to query quickbooks, follow up on this.

// the goal is to clear out credit card transactions with uploaded receipts and push them to quickbooks.
// all transactions
// all receipts uploaded
// what is left

// assign credit cards to users, need to fetch chartOfAccounts and assign to users

// No modal, drawer opens instantly
// add supplier column with auto-match to vendor
// Tooltip with specs and team member name

// Create table row alert styles:

// dark-table-row-alert
// color: #3E2B00

// dark-table-row-alert-hover
// color: #4B3400