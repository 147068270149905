import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  HStack
} from "@chakra-ui/react";

// icons
import { AvailableIcons } from './Icons/AvailableIcons';

// export const MainNavMenuItem = (props) => {

//   // console.log('nav props: ', props)
//   const { index, navlinkTo, itemText, itemIcon } = props

//   const renderIcon = (icon, index, isNavAcive, isNavPending) => {
//     const pendingNav = (isNavPending) ? "" : "navlink-text"
//     const iconClassName = (isNavAcive) ? "navlink-text-selected" : pendingNav
//     return (
//       <AvailableIcons key={'_i_'+index} boxSize={'18px'} iconRef={icon} className={iconClassName} />
//     )
//   }

//   return(
//     <NavLink key={`_n_${index}`} to={`${navlinkTo}`}>
//       {({ isActive, isPending }) => (
//         <Box 
//           key={`_b1_${index}`}
//           className={isActive ? "navlink-selected" : isPending ? "" : "navlink"} >
//           <HStack 
//             key={`_hs_${index}`}
//             mt={'9px'}
//             align={'left'} >
//             <Box 
//               key={`_bs_${index}`}
//               marginLeft={'8px'} 
//               marginRight={'2px'} >
//                 {renderIcon(itemIcon, index, isActive, isPending)}
//             </Box>
//             <Text key={`_t_${index}`} className={isActive ? "navlink-text-selected" : isPending ? "" : "navlink-text"}>{itemText}</Text>
//           </HStack>
//         </Box>
//       )}
//     </NavLink>

//   )
// }

export const MainNavMenuItem = (props) => {

  // console.log('nav props: ', props)
  const { index, navlinkTo, itemText, itemIcon } = props

  const renderIcon = (icon, index, isNavAcive, isNavPending) => {
    const pendingNav = (isNavPending) ? "" : "navlink-text"
    const iconClassName = (isNavAcive) ? "navlink-text-selected" : pendingNav
    const bgcolor = (isNavAcive) ? 'var(--dark-gold-tertiary)' : null
    const iconColor = (isNavAcive) ? 'var(--dark-text-white)' : 'var(--dark-gold-primary)'
    return (
      <AvailableIcons 
        key={'_i_'+index} 
        boxSize={'18px'} 
        iconRef={icon} 
        color={iconColor}
        className={iconClassName} />
    )
  }

  return(
    <NavLink key={`_n_${index}`} to={`${navlinkTo}`}>
      {({ isActive, isPending }) => (
        <Box 
          key={`_b1_${index}`}
          className={isActive ? "navlink-selected" : isPending ? "" : "navlink"} >
          <HStack 
            key={`_hs_${index}`}
            mt={'9px'}
            align={'left'} >
            <Box 
              key={`_bs_${index}`}
              marginLeft={'8px'} 
              marginRight={'2px'} >
                {renderIcon(itemIcon, index, isActive, isPending)}
            </Box>
            <Text key={`_t_${index}`} className={isActive ? "navlink-text-selected" : isPending ? "" : "navlink-text"}>{itemText}</Text>
          </HStack>
        </Box>
      )}
    </NavLink>
  )
}

MainNavMenuItem.propTypes = {
  index: PropTypes.number.isRequired,
  navlinkTo: PropTypes.string.isRequired,
  itemText: PropTypes.string.isRequired,
  itemIcon: PropTypes.string.isRequired,
};