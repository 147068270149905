import { listUnitDriversByEquipmentId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchUnitDriversByEquipmentId = async (equipmentId) => {
  if (!equipmentId) return;

  try {
    console.log('equipmentId: ', equipmentId);
    
    const response = await client.graphql({
      query: listUnitDriversByEquipmentId,
      variables: { djtEquipmentId: equipmentId },
    });

    const results = response.data.driverByDJTEquipmentId.items;
    return results;
  } catch (err) {
    return ('error fetchUnitDriversByEquipmentId: ', [err]);
  }
};

fetchUnitDriversByEquipmentId.propTypes = {
  equipmentId: PropTypes.string.isRequired,
};
