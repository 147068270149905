import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  FormLabel,
  FormControl,
  Skeleton,
  Text,
  Flex,
  Spacer,
  Stack,
  HStack,
  useDisclosure,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";

// uuid
import { v4 as uuidv4 } from 'uuid';

// lambda components for API Gateway
// import { LambdaListUserPoolGroups } from "../APIGateway/LambdaListUserPoolGroups";
import { useCognitoUserGroups} from '../APIGateway/fetchCognitoGroups.jsx';
import { createCognitoUser } from '../APIGateway/createCognitoUser.jsx';
import { addCognitoUserToGroup } from '../APIGateway/addCognitoUserToGroup.jsx';

// globals
// import { AppContext } from '../AppContext.js';
// import { LambdaListUsers } from '../APIGateway/LambdaListUsers.js';
import { fetchCognitoUsers } from '../APIGateway/fetchCognitoUsers.jsx';
import { suggestAddress } from '../APIGateway/suggestAddress.jsx';
import { getLocationDetails } from '../APIGateway/getLocationDetails.jsx';
import { fetchTimeCoExtendedEmployees } from '../APIGateway/fetchTimeCoExtendedEmployees.jsx';

// graphql components
import { fetchAllDivisions } from '../graphqlCompnents/Billing/fetchAllDivisions.jsx';
// import { listAllMappedTimeCoTeamMembers } from '../graphqlCompnents/TeamMember/listAllMappedTimeCoTeamMembers.jsx'
import { fetchJobClassRatesByDivisionId } from '../graphqlCompnents/JobClass/fetchJobClassRatesByDivisionId.jsx';
// import { addNoTimeCoTeamMember } from '../graphqlCompnents/TeamMember/addNoTimeCoTeamMember.jsx';
import { listAllActiveMenuItems } from '../graphqlCompnents/TeamMember/listAllActiveMenuItems.jsx';
import { addUserNavigation } from '../graphqlCompnents/TeamMember/addUserNavigation.jsx';
import { addTeamMember } from '../graphqlCompnents/TeamMember/addTeamMember.jsx';

// form components
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSwitch } from '../Form/Switch/FormSwitch.jsx';
// import { FormSelectMulti } from '../Form/Select/FormSelectMulti';
import { TextInputV2 } from '../Form/Input/TextInputV2.jsx';

// buttons
import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';

// icons
// import { AvailableIcons } from '../Icons/AvailableIcons.js';

// structural components
// import { ModuleBox } from '../Structural/ModuleBox.js';
// import { ModuleDrawer } from '../Structural/ModuleDrawer.js';
import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';

// generic functions
import { formatToPhone } from '../functions/formatPhoneNumber.jsx';
import { convertStandardDateToAWSDate, formatDateMDY } from '../functions/dateTime.jsx';

// modals
import { CenteredSubmitWithProgressModal } from '../Modal/CenteredSubmitWithProgressModal.jsx';
import { delay } from '../functions/generic.jsx';
// import { RiContactsBookLine } from 'react-icons/ri';

export const CreateTeamMember = (props) => {

  const { 
    // adminId, 
    onClose 
  } = props

  const { 
    setError, control, register, handleSubmit, clearErrors, setValue, trigger, formState: { 
      errors, 
    }} = useForm({
      mode: 'onChange',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });
  
  const onError = (errors, e) => console.log(errors, e);

  const { isOpen: isSubmitModalOpen , onOpen: onSubmitModalOpen, onClose: onSubmitModalClose } = useDisclosure()
  const [ submitModalContent, setSubmitModalContent ] = useState(null)
  const [ submitModalCloseDisabled, setSubmitModalCloseDisabled ] = useState(true)
  const [ submissionProgress, setSubmissionProgress ] = useState(0)
  async function handleSubmitModalClosed() {
    onSubmitModalClose()
    await delay(500)
    onClose()
  }

  // const cognitoGroups = LambdaListUserPoolGroups();
  const { userPoolGroups } = useCognitoUserGroups();
  // console.log('test: ', {userPoolGroups, isUserPoolGroupsLoading, userPoolGroupsError})
  // const [ userPoolGroupOptions, setUserPoolGroupOptions ] = useState([]);
  const [ userPoolGroupCheckboxes, setUserPoolGroupCheckboxes ] = useState([]);
  
  const [ timecoEmployeesExtended, setTimeCoEmployeesExtended ] = useState([]);

  const [ divisions, setDivisions ] = useState([])
  const [ selectedDivision, setSelectedDivision ] = useState(null)
  // const [ selectedDivisionId, setSelectedDivisionId ] = useState(null)

  // const [ jobClasses, setJobClasses ] = useState([])
  const [ jobClassOptions, setJobClassOptions ] = useState([])
  const [ selectedJobClass, setSelectedJobClass ] = useState(null)
  // const [ selectedJobClassId, setSelectedJobClassId ] = useState(null)

  // const [ allMappedTimeCoTeamMembers, setAllMappedTimeCoTeamMembers ] = useState([])
  
  // const [ selectedActionButton, setSelectedActionButton ] = useState(null)
  // const [ submitButtonText, setSubmitButtonText ] = useState(null)

  // const [ selectedGroups, setSelectedGroups ] = useState([])

  const [ isTempToHire, setIsTempToHire ] = useState(true)

  const [ groupIsSelected, setGroupIsSelected ] = useState('')
  const [ groupsSelected, setGroupsSelected ] = useState([])
  const [ isIssuedCreditCard, setIsIssuedCreditCard ] = useState('')

  const [ selectedTCUser, setSelectedTCUser ] = useState('')

  const [ dateOfBirth, setDateOfBirth ] = useState('')
  const [ dateOfHire, setDateOfHire ] = useState('')

  const listUsersParams = {
    // "UserPoolId": process.env.REACT_APP_USERPOOL_ID,
    "AttributesToGet": [
      "email"
    ]
  }

  const [ currentCognitoUsers, setCurrentCognitoUsers ] = useState([])
  useEffect(() => {
    const fetchUsers = async () => {
      // setIsLoading(true);
      try {
        const userList = await fetchCognitoUsers(listUsersParams);
        console.log(' ----- userList: ', userList)
        setCurrentCognitoUsers(userList || []);
        console.warn(' ----- userList: ', userList)
      } catch (err) {
        setError(err.message);
        setCurrentCognitoUsers([]);
      } finally {
        // setIsLoading(false);
      }
    };

    fetchUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [ allActiveMenuItems, setAllActiveMenuItems ] = useState([])
  useEffect(() => {
    const fetchAllActiveMenuItems = async () => {
      try {
        const menuItems = await listAllActiveMenuItems();
        console.log('menuItems: ', menuItems)
        setAllActiveMenuItems(menuItems);
      } catch (error) {
        console.error('Failed to fetch active menu items:', error);
      }
    }

    fetchAllActiveMenuItems();
  }, [])

  const formSubmit = async (values, event) => {
    // reference AddTeamMember.js for Cognito Add User functionality

    if (event.key === 'Enter' || event.type === 'submit') {
      console.log('Prevent form from submitting/reloading the page');
      event.preventDefault();
    }

    // event.preventDefault();
    console.warn(' ----- formSubmit values: ', values)

    try {
    
      onSubmitModalOpen();
      setSubmitModalContent('Processing team member data...')
      setSubmissionProgress(10)

      // Convert dates and setup variables
      const isTempToHire = values?.isTempToHire
      const groupNames = values?.oneGroupIsSelected || [];
      const isGlobalAdmin = groupNames.includes('exec_admin')
      const userAccount = values?.userAccount;
      const userPhone = values?.userPhone;
      const issuedCreditCard = values?.issuedCreditCard===true ? true : false

      if (isTempToHire!==true) {
        // Create Cognito User
        await createAndSetupUser(userAccount, userPhone, isTempToHire, groupNames);
        console.log('Added user to Cognito and Cogniro groups')
      }

      // Add user to DB (NoTimeCo or SpkTeamMembersTbl)
      const teamMemberId = await addUserToDatabase(values, isTempToHire, isGlobalAdmin);

      if (isTempToHire===false) {
        console.log('adding menu navigation', issuedCreditCard, teamMemberId, groupNames)
        // Set up user navigation menus
        await setupUserNavigation(issuedCreditCard, teamMemberId, groupNames);
      }
      
      setSubmissionProgress(100)
      setSubmitModalCloseDisabled(false)

    } catch (error) {
      console.error('Error during form submission:', error);
      setSubmitModalContent('An error occurred: ' + error.message);
      setSubmitModalCloseDisabled(false);
    }

  }

  async function createAndSetupUser(userAccount, userPhone, isTempToHire, groupNames) {
    try {
      setSubmitModalContent('Creating user account...');
      setSubmissionProgress(20);
    
      // ----- COGNITO ----- //
      const created = await createCognitoAccount(userAccount, userPhone);
      if (!created) throw new Error('Failed to create user account.');
    
      setSubmitModalContent('Adding user to groups...');
      setSubmissionProgress(30);
      await manageUserGroups(userAccount, isTempToHire, groupNames);
    } catch (error) {
      console.error('Error creating user account:', error);
      setSubmitModalContent('Error creating user account.');
      setSubmissionProgress(100);
      setSubmitModalCloseDisabled(false);
      return
    }
  }
  
  async function manageUserGroups(userAccount, isTempToHire, groupNames) {
    // ----- COGNITO GROUPS ----- //
    if (isTempToHire) {
      try {
        setSubmitModalContent(`Adding user to team member group...`)
        setSubmissionProgress(40)
        await addCognitoAccountToGroup(userAccount, 'team_member');
      } catch (error) {
        setSubmitModalContent('Error adding user to team member group.')
        setSubmissionProgress(100)
        setSubmitModalCloseDisabled(false)
        return
      }
    } else {
      try {
        setSubmitModalContent(`Adding user to ${groupNames?.length} group${(groupNames?.length===1) ? '' : 's'}...`)
        setSubmissionProgress(40)
        let progress = 40
        for (const groupName of groupNames) {
          setSubmitModalContent(`Adding user to ${groupName} group...`)
          progress += 5
          setSubmissionProgress(progress)

          await addCognitoAccountToGroup(userAccount, groupName);        
          await delay(3000)
        }
      } catch (error) {
        setSubmitModalContent('Error adding user to group.')
        setSubmissionProgress(100)
        setSubmitModalCloseDisabled(false)
        return
      }
    }
  }
  
  async function addUserToDatabase(values, isTempToHire, isGlobalAdmin) {
    if (isTempToHire===true) { 
      console.log('Adding user to NoTimeCo')
      setSubmitModalContent('Adding user to temp hire table...')
      setSubmissionProgress(70)

      // try {
      //   const props = {
      //     allSort: 'all',
      //     billableDivisionJobClassId: `${values?.selectJobClass?.value}`,
      //     costPerHour: '32.20',
      //     createdOn: `${todaysDateYMD()}`,
      //     divisionId: `${values?.selectDivision?.value}`,
      //     endDate: `${todaysDatePlusNDaysYMD(90,'-')}`,
      //     estimatedHoursPerDay: '8',
      //     extendedEndDate: `${todaysDatePlusNDaysYMD(90,'-')}`,
      //     firstName: `${values?.firstName}`,
      //     isActive: true,
      //     isConverted: false,
      //     lastName: `${values?.lastName}`,
      //     middleName: `${values?.middleName}`,
      //     startDate: `${convertStandardDateToAWSDate(values?.dateOfHire)}`,
      //   }

      //   await addNoTimeCoTeamMember(props);
      // } catch (error) {
      //   console.error('Error adding user to temp hire.')
      //   setSubmitModalContent('Error adding user to temp hires.')
      //   setSubmissionProgress(100)
      //   setSubmitModalCloseDisabled(false)
      //   return
      // }

    } else {
      console.log('Adding user to SpkTeamMembersTbl')
      setSubmitModalContent('Adding user to team member table...')
      setSubmissionProgress(70)
      
      let teamMemberId = null
      try {
        const props = {
          loginEmail: `${values?.userAccount}`,
          firstName: `${values?.firstName}`,
          middleName: `${values?.middleName}`,
          lastName: `${values?.lastName}`,
          goesBy: `${values.goesBy}||''`,
          dob: `${convertStandardDateToAWSDate(values?.dateOfBirth)}`,
          hireDate: `${convertStandardDateToAWSDate(values?.dateOfHire)}`,
          billableDivisionJobClassId: `${values?.selectJobClass?.value}`,
          doTrackPunches: false,
          djtHideByDefault: false,
          divisionId: `${values?.selectDivision?.value}`,
          badgeNumber: `${values?.selectTimeCoUser?.value}`,
          employeeNumber: `${values?.selectTimeCoUser?.value}`,
          addCity: `${values?.addCity}`,
          addState: `${values?.addState}`,
          addStreet: `${values?.addStreet}`,
          addZipcode: `${values?.addZipcode}`,
          phoneNum: `${values?.userPhone}`,
          isActive: true,
          isGlobalAdmin: isGlobalAdmin, // gives admin ability to view all divisions at once in modules
          emergcyContName: `${values?.emergcyContName}`,
          emergcyContPhone: `${values?.emergencyContPhoneNumber}`,
          canTransfer: true
        }

        teamMemberId = await addTeamMember(props);
        console.log('teamMemberId: ', teamMemberId)
        return teamMemberId

      } catch (error) {
        console.error('Error adding user to team members.')
        setSubmitModalContent('Error adding user to team members.')
        setSubmissionProgress(100)
        setSubmitModalCloseDisabled(false)
        return
      }
    }
  }
  
  async function setupUserNavigation(issuedCreditCard, teamMemberId, groupNames) {
    // Now, handle the menu items to add by default per the group selected
    setSubmitModalContent(`Creating team member navigation...`)
    setSubmissionProgress(80)
    try {
      // Executive Admin - Invoices, Team Members, Manage Clients, Upload Receipt
      const executiveAdminMenuItems = allActiveMenuItems.filter(menuItem => 
        menuItem.linkTo === 'teammembers' || 
        menuItem.linkTo === 'viewdjt' || 
        menuItem.linkTo === 'syncqbcustomer' || 
        menuItem.linkTo === 'daily-job-ticket' || 
        menuItem.linkTo === 'receipt-upload' ||
        menuItem.linkTo === 'transfers' || 
        menuItem.linkTo === 'units' || 
        menuItem.linkTo === 'credentials')
      // Supervisor - Daily Job Ticket, Estimator, Dashboard, Queue Inspections, Daily Inspections, Upload Receipt
      const supervisorMenuItems = allActiveMenuItems.filter(menuItem =>
        menuItem.linkTo === 'dashboard' ||
        menuItem.linkTo === 'daily-job-ticket' ||
        menuItem.linkTo === 'estimator' ||
        menuItem.linkTo === 'receipt-upload' ||
        menuItem.linkTo === 'transfers' ||
        menuItem.linkTo === 'units' ||
        menuItem.linkTo === 'daily-inspections' ||
        menuItem.linkTo === 'tm-unit-inspections' ||
        menuItem.linkTo === 'credentials')
      // Maintenance - Daily Job Ticket, Estimator, Dashboard, Queue Inspections, Daily Inspections, Upload Receipt
      const maintenanceMenuItems = allActiveMenuItems.filter(menuItem =>
        menuItem.linkTo === 'dashboard' ||
        menuItem.linkTo === 'daily-job-ticket' ||
        menuItem.linkTo === 'estimator' ||
        menuItem.linkTo === 'receipt-upload' ||
        menuItem.linkTo === 'transfers' ||
        menuItem.linkTo === 'units' ||
        menuItem.linkTo === 'daily-inspections' ||
        menuItem.linkTo === 'tm-unit-inspections' ||
        menuItem.linkTo === 'credentials')
      // Issued Credit Card - Upload Receipt
      // Team Member - Daily Inspections
      const teamMemberMenuItems = allActiveMenuItems.filter(menuItem =>
        menuItem.linkTo === 'daily-inspections')
      const teamMemberCreditCardMenuItems = allActiveMenuItems.filter(menuItem =>
        menuItem.linkTo === 'daily-inspections' ||
        menuItem.linkTo === 'receipt-upload')
      
      // 67c392cf-cbbb-4f90-9893-eed738c7ec7d	1	  dashboard	      Dashboard	iconDashboard	Dashboard
      // 20dc0e78-f19a-487c-9089-b565bff324fc	5	  teammembers	    Team Members	iconTeamMembers	Team Members
      // 9196a024-a7c1-4eff-bcf7-8f4fce83b941	12	viewdjt	        Process Invoice	iconAr	Invoices
      // fb0d227c-bca4-4026-beb2-d56c9b4ec204	13	syncqbcustomer	Sync Quickbooks Customer to ERP	gear	Manage Clients
      // dfeaadbd-d930-4554-8dfe-98fd133d9568	21	daily-job-ticket	Daily Job Ticket	iconDailyJobTicket	Daily Job Ticket
      // 8dab2fae-345e-46e5-b38e-e4bf48b50d87	23	estimator	      Estimator	iconEstimator	Estimator
      // 537f058d-f2b1-4c96-b123-745730b99a5f	41	receipt-upload	Upload Receipt	iconReceiptUpload	Upload Receipt
      // bd909ce3-6543-4641-8fbc-3886b8d942ca	50	transfers	      Transfers	iconTransfers	Transfers
      // f382fe87-4def-4712-895f-7939e15e6516	60	units	          Units	iconUnits	Units
      // a89cbd88-e779-4014-9bf1-95f434155f80	70	daily-inspections	Queue Inspections	queue	Queue Inspections
      // 16f7a4f1-ba53-4bc3-9ae4-84e73a1879c8	75	tm-unit-inspections	Daily Inspections	iconDailyInspections	Daily Inspections
      // 60a7ba83-ccaf-433a-ac34-365e0f539e5e	80	credentials	    Credentials	iconCredentials	Credentials
      // 22efc2b4-0c41-4694-a6de-8d67a4f85601	900	layout	        Test	iconTeamMembers	Dev Test

      // allActiveMenuItems

      // create SpkNavMenuUserTbl records for each menu item selected
      // for each group, addUserNavigation

      //const selectedNavMenuItems = [...new Set([...arrayA, ...arrayB, ...arrayC])];
      //oneGroupIsSelected
      // oneGroupIsSelected: Array (4)
      // 0 "exec_admin"
      // 1 "supervisor"
      // 2 "maintenance"
      // 3 "team_member"
      const selectedGroups = {
        executiveAdminMenuItems: (groupNames.includes('exec_admin')) ? true : false,
        supervisorMenuItems: (groupNames.includes('supervisor')) ? true : false,
        maintenanceMenuItems: (groupNames.includes('maintenance')) ? true : false,
        teamMemberMenuItems: (groupNames.includes('team_member')) ? true : false,
        teamMemberCreditCardMenuItems: (issuedCreditCard===true) ? true : false,
      }

      // Function to combine selected group arrays and remove duplicates
      // function combineSelectedGroups(selections) {
      //   const combined = [];

      //   if (selections.executiveAdminMenuItems) combined.push(...executiveAdminMenuItems);
      //   if (selections.supervisorMenuItems) combined.push(...supervisorMenuItems);
      //   if (selections.maintenanceMenuItems) combined.push(...maintenanceMenuItems);
      //   if (selections.teamMemberMenuItems) combined.push(...teamMemberMenuItems);
      //   if (selections.teamMemberCreditCardMenuItems) combined.push(...teamMemberCreditCardMenuItems);

      //   return [...new Set(combined)];
      // }

      const combined = [];

      if (selectedGroups.executiveAdminMenuItems) combined.push(...executiveAdminMenuItems);
      if (selectedGroups.supervisorMenuItems) combined.push(...supervisorMenuItems);
      if (selectedGroups.maintenanceMenuItems) combined.push(...maintenanceMenuItems);
      if (selectedGroups.teamMemberMenuItems) combined.push(...teamMemberMenuItems);
      if (selectedGroups.teamMemberCreditCardMenuItems) combined.push(...teamMemberCreditCardMenuItems);

      const resultGroups = [...new Set(combined)]
      // const resultGroups = combineSelectedGroups(selectedGroups);

      // Add the user to the menu items isActive: false, navMenuId: "", tmId: ""
      for (const menuItem of resultGroups) {
        const params = {
          isActive: true,
          navMenuId: menuItem.id,
          tmId: teamMemberId
        }
        await addUserNavigation(params);
      }
    } catch (error) {
      console.error('Error creating team member navigation.')
      setSubmitModalContent('Error creating team member navigation.')
      setSubmissionProgress(100)
      setSubmitModalCloseDisabled(false)
      return
    }
  }

  const updateJobClassRates = async (divisionId) => {
    try {
      const jobClassRates = await fetchJobClassRatesByDivisionId(divisionId);
      console.warn('----- jobClassRates: ', jobClassRates)
      return jobClassRates;
    } catch (error) {
      console.error('Failed to fetch job class rates:', error);
    }
  }

  // const handleAccountChanged = async (e) => {
  //   // if (!e) return;
  //   // setError('userAccount', {
  //   //   type: 'manual',
  //   //   message: 'Email already exists.'
  //   // });
  //   // function checkUserEmailExists(emailToCheck) {
  //   //   return currentCognitoUsers.some(user => 
  //   //     user.Attributes.some(attribute => 
  //   //       attribute.Name === "email" && attribute.Value.toLowerCase() === emailToCheck.toLowerCase()
  //   //     )
  //   //   );
  //   // }
  //   // const email = e.target.value
  //   // const emailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  //   // // const emailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
  //   // if (emailRegEx.test(email)) {
  //   //   // check if this email matches one of the existing accounts
  //   //   const alreadyExists = checkUserEmailExists(email)
  //   //   if (alreadyExists) {
  //   //     console.warn(' +----- alreadyExists, setting error: ', alreadyExists)
  //   //     setError("userAccount", {
  //   //       type: "manual",
  //   //       message: "Email already exists."
  //   //     });
  //   //   } else {
  //   //     console.warn(' +----- valid email')
  //   //     clearErrors('userAccount')
  //   //   }
  //   // } else {
  //   //   console.warn(' ^----- invalid email')
  //   //   setError('userAccount', { type: "manual", message: 'Invalid email format' })
  //   // }
  // }

  const [ validatedEmailAddress, setValidatedEmailAddress ] = useState('')
  const validateEmail = async (event) => {
    if (!event) return;
    const emailInput = event.target.value;
    console.log('emailInput: ', emailInput)
    let hasError = false;
    let errorMessage = '';
    
    const emailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
    // const emailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    function checkUserEmailExists(emailToCheck) {
      console.log('currentCognitoUsers: ', currentCognitoUsers)
      return currentCognitoUsers.some(user => 
        user.Attributes.some(attribute => 
          attribute.Name === "email" && attribute.Value.toLowerCase() === emailToCheck.toLowerCase()
        )
      );
    }

    if (emailRegEx.test(emailInput)) {
      const alreadyExists = checkUserEmailExists(emailInput)
      console.log('alreadyExists: ', alreadyExists)
      if (alreadyExists) {
        hasError = true;
        errorMessage = "This email login is already in use. Email addresses must be unique for each team member to log in.";
      }
    } else {
      hasError = true;
      errorMessage = "This is not a valid email address";
    }
    
    if (hasError) {
      setValue('userAccount', '')
      setError("userAccount", {
        type: "manual",
        message: errorMessage
      });
    } else {
      clearErrors('userAccount');
    }
    
    setValidatedEmailAddress(emailInput)
    return
  }

  // const handlePhoneChanged = async (e) => {
  //   if (!e) return;
  //   if (e.target.value.length===16) {
  //     // console.log('hit 16')
  //     clearErrors(['userPhone'])
  //   }
  //   const phone = e.target.value
  //   const phoneRegEx = /^\d*$/;
  //   console.log('phoneRegEx.test(phone): ', phoneRegEx.test(phone))
  // }

  // const handleFirstNameChanged = async (e) => {
  //   if (!e) return;
  //   const firstName = e.target.value
  //   const firstNameRegEx = /^[a-zA-Z]+$/;
  //   console.log('firstNameRegEx.test(firstName): ', firstNameRegEx.test(firstName))
  // }

  // const handleMiddleNameChanged = async (e) => {
  //   if (!e) return;
  //   const middleName = e.target.value
  //   const middleNameRegEx = /^[a-zA-Z]+$/;
  //   console.log('middleNameRegEx.test(middleName): ', middleNameRegEx.test(middleName))
  // }

  // const handleLastNameChanged = async (e) => {
  //   if (!e) return;
  //   const lastName = e.target.value
  //   const flastNameRegEx = /^[a-zA-Z]+$/;
  //   console.log('flastNameRegEx.test(lastName): ', flastNameRegEx.test(lastName))
  // }

  const handleAddStreetChanged = async (e) => {
    if (!e) return;
    const addStreet = e.target.value;
    const addStreetRegEx = /^[a-zA-Z0-9\s.,'-]+$/;
    console.log('addStreetRegEx.test(addStreet): ', addStreetRegEx.test(addStreet));
  }

  const handleAddCityChanged = async (e) => {
    if (!e) return;
    const addCity = e.target.value;
    const addCityRegEx = /^[a-zA-Z\s.-]+$/;
    console.log('addCityRegEx.test(addCity): ', addCityRegEx.test(addCity));
  }

  const handleAddStateChanged = async (e) => {
    if (!e) return;
    const addState = e.target.value;
    const addStateRegEx = /^[a-zA-Z\s]+$/;
    console.log('addStateRegEx.test(addState): ', addStateRegEx.test(addState));
  }

  // const handleAddZipcodeChanged = async (e) => {
  //   if (!e) return;
  //   const addZipcode = e.target.value;
  //   const addZipcodeRegEx = /^\d{5}(-\d{4})?$/;
  //   console.log('addZipcodeRegEx.test(addZipcode): ', addZipcodeRegEx.test(addZipcode));
  // }

  // const handlePhoneNumChanged = async (e) => {
  //   if (!e) return;
  //   const phoneNum = e.target.value;
  //   const phoneNumRegEx = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  //   console.log('phoneNumRegEx.test(phoneNum): ', phoneNumRegEx.test(phoneNum));
  // }

  const handleEmergcyContNameChanged = async (e) => {
    if (!e) return;
    const emergcyContName = e.target.value;
    const emergcyContNameRegEx = /^[a-zA-Z\s]+$/;
    console.log('emergcyContNameRegEx.test(emergcyContName): ', emergcyContNameRegEx.test(emergcyContName));
  }

  // const handleEmergcyContPhoneChanged = async (e) => {
  //   if (!e) return;
  //   const emergcyContPhone = e.target.value;
  //   const emergcyContPhoneRegEx = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  //   console.log('emergcyContPhoneRegEx.test(emergcyContPhone): ', emergcyContPhoneRegEx.test(emergcyContPhone));
  // }

  const handleDivisionChanged = async (option) => {
    if (!option) return;

    setValue('selectDivision', option);
    clearErrors(['selectDivision'])
    setSelectedDivision(option)
    // setSelectedDivisionId(option?.value)

    const jobClassRates = await updateJobClassRates(option?.value);
    const activePositionsOptions = jobClassRates
    .filter(jobClassRate => jobClassRate.isActive && !jobClassRate.isHiddenByDefault)
    .sort((a, b) => a.hourlyRate.localeCompare(b.hourlyRate))
    .map(jobClassRate => {
        const [name] = jobClassRate.divisionPosition.split('#')[1];
        const displayName = jobClassRate.appDisplayName || name;
        return {
            value: jobClassRate.divisionPosition, // or id, depending on what you need
            label: displayName,
        };
    });

    // setJobClasses(jobClassRates);
    setJobClassOptions(activePositionsOptions);
  }

  const handleJobClassChanged = (option) => {
    if (!option) return;

    setSelectedJobClass(option)
    // setSelectedJobClassId(option?.value)
    setValue('selectJobClass', option);
    clearErrors(['selectJobClass'])
  }

  // const handleSelectAction = (action) => {
  //   if (!action) {
  //     return
  //   }
  //   if (action === "create") {
  //     setSubmitButtonText('Create New Team Member')
  //     setSelectedActionButton("create")
  //   }
  //   if (action === "update") {
  //     setSubmitButtonText('Update Existing Team Member')
  //     setSelectedActionButton("update")
  //   }
  // }

  // const handleGroupsChange = async (event, action) => {
  //   // take the event object and assign it to our state array
  //   setSelectedGroups(event);
  //   if (!event) return;
  //   console.warn('event and action: ', {event, action})
  //   if (action === "create") {
  //     console.warn('create')
  //   }
  //   if (action === "update") {
  //     console.warn('update')
  //   }

  // }

  const handleCheckboxChange = (checkedValues) => {
    console.log('checkedValues: ', checkedValues)
    // Directly set whether 'issued_credit_card' is included
    setIsIssuedCreditCard(checkedValues.includes('issued_credit_card'));
    setValue('issuedCreditCard', checkedValues.includes('issued_credit_card'));

    // Filter out 'issued_credit_card' and update the selected groups
    const nonCreditCardGroups = checkedValues.filter(group => group !== 'issued_credit_card');
    setGroupsSelected(nonCreditCardGroups);
  
    // Update state to check if at least one non-credit card group is selected
    setGroupIsSelected(nonCreditCardGroups.length > 0);

    // If nonCreditCardGroups.length > 0 then clear the error
    if (nonCreditCardGroups.length > 0) {
      clearErrors(['oneGroupIsSelected'])
      setValue('oneGroupIsSelected', nonCreditCardGroups)
    } else {
      setValue('oneGroupIsSelected', '')
      setError("oneGroupIsSelected", {
        type: "manual",
        message: "At least one Team Member Group is required."
      });
    }
  };

  const handleUserPhoneOnChange = (event) => {
    const numericValue = event.target.value.replace(/\D/g, '');
    event.target.value = numericValue
    formatToPhone(event);  // Format the input on change
    setValue('userPhone', event.target.value, { shouldValidate: true });

  };

  const handleUserPhoneOnBlur = () => {
    trigger("userPhone");
  };

  const handleContactPhoneOnChange = (event) => {
    const numericValue = event.target.value.replace(/\D/g, '');
    event.target.value = numericValue
    formatToPhone(event);  // Format the input on change
    setValue('emergencyContPhoneNumber', event.target.value, { shouldValidate: true });

  };

  const handleContactPhoneOnBlur = () => {
    trigger("emergencyContPhoneNumber");
  };

  const handleTimeCoUserChanged = (e) => {
    if (!e) return;
    setSelectedTCUser(e)
    // setSelectedTCUser(e.value)
    setValue('selectTimeCoUser', e);
    clearErrors(['selectTimeCoUser'])
  }
  
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    
    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay);
        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
  }
  
  const [input, setInput] = useState('');
  const debouncedInput = useDebounce(input, 300);
  // const debouncedSearchTerm = useDebounce(input, 500); // 500ms delay
  // const [suggestions, setSuggestions] = useState([]);

  const [suggestedAddresses, setSuggestedAddresses] = useState([]);
  useEffect(() => {
    (suggestedAddresses) && console.log('suggestedAddresses: ', suggestedAddresses?.Results)
  },[suggestedAddresses])

  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async (debouncedInput) => {
      
      // setIsLoading(true);
      try {
        const query = {"Query": `${debouncedInput}`};
        const addresses = await suggestAddress(query);
        let addressesJSON = JSON.parse(addresses);
        const excludedTexts = ["united states", "united states postal service"];
        const filteredResults = addressesJSON?.Results?.filter((item) => {
          const text = item?.Text.toLowerCase(); // Convert text to lowercase for case-insensitive comparison
          return !excludedTexts.includes(text); // Return true if text is NOT in the excludedTexts array
        });
        if (filteredResults?.length>0) {
          addressesJSON.Results = filteredResults;
          console.log('addressesJSON: ', addressesJSON)
          setSuggestedAddresses(addressesJSON);
        } 
        
      } catch (error) {
        // setSuggestedAddresses([]);
        console.error('Failed to fetch addresses:', error);
      } finally {
        // setIsLoading(false);
      }
    };

    (debouncedInput) && fetchData(debouncedInput);
  }, [debouncedInput]); // Dependency array depends on when you want to trigger this effect

  const [ selectedAddress, setSelectedAddress ] = useState(null);
  const [ locationDetails, setLocationDetails ] = useState([]);

  const formatAddressDetails = (details) => {
    const { AddressNumber, Street, Municipality, Region, PostalCode } = details;
    return {
      street: `${AddressNumber ?? ''} ${Street ?? ''}`.trim(),
      city: Municipality ?? '',
      state: Region ?? '',
      zipcode: PostalCode ?? '',
    };
  };
  
  useEffect(() => {
    if (locationDetails?.Label?.length > 0) {
      const { street, city, state, zipcode } = formatAddressDetails(locationDetails);
      setValue('addStreet', street);
      clearErrors(['addStreet']);
      setValue('addCity', city);
      clearErrors(['addCity']);
      setValue('addState', state);
      clearErrors(['addState']);
      setValue('addZipcode', zipcode);
      clearErrors(['addZipcode']);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationDetails]);

  useEffect(() => {
    if (selectedAddress) {
      // console.log('selectedAddress: ', selectedAddress)
      const fetchData = async (selectedAddress) => {
        // setIsLoading(true);
        try {
          const query = {"Query": `${selectedAddress}`};
          const locationDetails = await getLocationDetails(query);
          setLocationDetails(locationDetails);
          
        } catch (error) {
          console.error('Failed to fetch location details:', error);
        } finally {
          // setIsLoading(false);
        }
      }
      fetchData(selectedAddress);
    }
  },[selectedAddress])

  const handleSuggestedLocationChanged = (e) => {
    if (!e) return;
    setSelectedAddress(e?.value);
  }

  // const normalizeString = (str) => {
  //   return str.toLowerCase()
  //     .replace(/\s+/g, ' ')
  //     .replace(/\b(east|e)\b/g, 'e')
  //     .replace(/\b(west|w)\b/g, 'w')
  //     .replace(/\b(north|n)\b/g, 'n')
  //     .replace(/\b(south|s)\b/g, 's')
  //     .replace(/\b(northeast|ne)\b/g, 'ne')
  //     .replace(/\b(northwest|nw)\b/g, 'nw')
  //     .replace(/\b(southeast|se)\b/g, 'se')
  //     .replace(/\b(southwest|sw)\b/g, 'sw')
  //     .replace(/\b(street|st)\b/g, 'st')
  //     .replace(/\b(avenue|ave)\b/g, 'ave')
  //     .replace(/\b(road|rd)\b/g, 'rd')
  //     .replace(/\b(boulevard|blvd)\b/g, 'blvd')
  //     .replace(/\b(lane|ln)\b/g, 'ln')
  //     .replace(/\b(drive|dr)\b/g, 'dr')
  //     .replace(/\b(terrace|ter)\b/g, 'ter')
  //     .replace(/\b(place|pl)\b/g, 'pl')
  //     .replace(/\b(court|ct)\b/g, 'ct')
  //     .replace(/\b(apartment|apt)\b/g, 'apt')
  //     .replace(/\b(unit)\b/g, 'unit')
  //     .replace(/\b(floor|fl)\b/g, 'fl')
  //     .replace(/\b(suite|ste)\b/g, 'ste')
  //     .replace(/\b(building|bldg)\b/g, 'bldg')
  //     .replace(/\b(department|dept)\b/g, 'dept')
  //     .trim();
  // };
  
  // const customFilterOption = (option, rawInput) => {
  //   const normalizedLabel = normalizeString(option.label);
  //   const normalizedInput = normalizeString(rawInput);
  //   return normalizedLabel.includes(normalizedInput);
  // };

  const alwaysShowOptionsFilter = () => {
    return true; // Always return true to bypass any filtering based on input
  };
  
  useEffect(() => {
    // A utility function to convert strings to Title Case
    const toTitleCase = (str) => str
      .toLowerCase()
      .split(/-|\s+/) // Split on hyphens or whitespace
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('-'); // Rejoin with hyphens where necessary

    // Async function to fetch, sort, and title case employee names
    const listTimeCoUsers = async () => {
      try {
        const results = await fetchTimeCoExtendedEmployees();

        // Sorting by last name, then by first name
        const sortedResults = results?.sort((a, b) => {
          // First, compare by last name
          const lastNameComparison = a.LastName.toLowerCase().localeCompare(b.LastName.toLowerCase());
          if (lastNameComparison !== 0) return lastNameComparison;

          // If last names are equal, then compare by first name
          return a.FirstName.toLowerCase().localeCompare(b.FirstName.toLowerCase());
        });

        // Mapping to apply title case and other transformations
        const employees = sortedResults.map(employee => ({
          ...employee,
          FirstName: toTitleCase(employee.FirstName),
          MiddleName: employee.MiddleName ? toTitleCase(employee.MiddleName) : '',
          LastName: toTitleCase(employee.LastName),
          // BadgeNumber: employee.BadgeNumber, // Uncomment if modification is needed
        }));

        // for testing
        employees.push({
          FirstName: 'Developer',
          MiddleName: 'M',
          LastName: 'Test',
          BadgeNumber: '00000',
        });
        // Update your state or context with the processed list
        setTimeCoEmployeesExtended(employees);
      } catch (error) {
        console.error('Failed to fetch and process TimeCo employees:', error);
      }
    };

    listTimeCoUsers();
  }, []); // Ensure the effect runs once on component mount
  
  // useEffect(() => {
    
  //   const normalizeName = (name) => {
  //     // Function to convert a name part to Title Case and add a period for middle initials
  //     const toTitleCaseAndAddPeriod = (str, index, array) => {
  //       // Convert to Title Case
  //       let part = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    
  //       // If this part is a middle initial (single letter and not the first or last part), add a period
  //       if (part.length === 1 && index > 0 && index < array.length - 1) {
  //         part += '.';
  //       }
    
  //       return part;
  //     };
    
  //     // Trim and prepare name for processing
  //     let parts = name.trim().includes(',') ?
  //       name.trim().split(',').reverse().join(' ').split(/\s+/) :
  //       name.trim().split(/\s+/);
    
  //     // Convert each part to Title Case and add period for middle initials
  //     parts = parts.map(toTitleCaseAndAddPeriod);
    
  //     // Reassemble the name, ensuring no leading/trailing spaces
  //     return parts.filter(Boolean).join(' ').trim();
  //   };
    


  //   // fetch all mapped team members
  //   const loadMappedTeamMembers = async () => {
  //     try {
  //       const results = await listAllMappedTimeCoTeamMembers();

  //       const mappedResults = results?.map(({ employeeNumber, employeeName }) => ({
  //         name: employeeName,
  //         number: employeeNumber,
  //       }));

  //       const normalizedEmployees = mappedResults.map(emp => ({
  //         ...emp,
  //         name: normalizeName(emp.name),
  //         number: emp.number,
  //       }));

  //       // const sortedResults = results?.sort((a, b) => {
  //       //   // First, compare by last name
  //       //   const lastNameComparison = a.LastName.toLowerCase().localeCompare(b.LastName.toLowerCase());
  //       //   if (lastNameComparison !== 0) return lastNameComparison;

  //       //   // If last names are equal, then compare by first name
  //       //   return a.FirstName.toLowerCase().localeCompare(b.FirstName.toLowerCase());
  //       // });

  //       console.warn('-------------------------: ');
  //       console.warn('----- sortedResults: ', normalizedEmployees);
  //       console.warn('-------------------------: ');
        
  //       // setAllMappedTimeCoTeamMembers(normalizedEmployees);
  //     } catch (error) {
  //       console.error('Failed to fetch mapped team members:', error);
  //     }
  //   };
  
  //   loadMappedTeamMembers();
  // }, []); 

  useEffect(() => {
    const loadDivisions = async () => {
      try {
        console.info('HERE')
        const divisionsAll = await fetchAllDivisions('all');
        // console.warn('----- divisionsAll: ', divisionsAll);

        const divisionsSpecial = await fetchAllDivisions('special');
        // console.warn('----- divisionsSpecial: ', divisionsSpecial);

        const mergedArray = [...divisionsAll, ...divisionsSpecial];

        console.warn('----- mergedArray: ', mergedArray);

        setDivisions(mergedArray);
      } catch (error) {
        console.error('Failed to fetch divisions:', error);
      }
    };
  
    loadDivisions();
  }, []);

  useEffect(() => {
    const formatGroupName = (groupName) => {
      return groupName
        .replace('exec_admin', 'executive_admin')
        .split('_')  // Split the name by underscores
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize the first letter of each word
        .join(' ');  // Join words with spaces
    };
    
    let options = [];
    if (userPoolGroups?.length > 0) options = JSON.parse(userPoolGroups)
    if (Array.isArray(options)) {
      options.sort((a, b) => a.Precedence - b.Precedence)
      .map(group => {
        return {
          value: group.GroupName,
          label: formatGroupName(group.GroupName),
        }
      });
      // setUserPoolGroupOptions(options);

      const checkboxes = options
      .sort((a, b) => a.Precedence - b.Precedence)
      .map((group, index) => {
        if (group.GroupName === 'issued_credit_card') {
          return (
            <Stack 
              key={`group_stack_${index}`}
              spacing={[1, 1]} 
              direction={['row', 'column']}>
              <Box>
                <Text as="span" textStyle='label-2'>Issued Credit Card</Text>
              </Box>
              <Box>
                <Checkbox 
                  paddingTop={'4px'}
                  borderColor='spikeorange.300'
                  colorScheme='spikeorange'
                  value={`${group.GroupName}`}
                  // onChange={(e) => handleCheckboxChange(e.target.checked)}
                >Team member has been issued a credit card</Checkbox>
              </Box>
            </Stack>
          )
        } else {
          return (
            <Checkbox
              key={`group_cb_${index}`}
              borderColor='spikeorange.300'
              colorScheme='spikeorange'
              value={`${group.GroupName}`}
              // onChange={(e) => handleCheckboxToggle(e.target.checked)}
            >{`${formatGroupName(group.GroupName)}`}</Checkbox>
          )
        }
      });
      setUserPoolGroupCheckboxes(checkboxes);
    }
  },[userPoolGroups, groupIsSelected])

  // const [ createCognitoUserError, setCreateCognitoUserError ] = useState(null)
  // const [ createCognitoUserSuccess, setCreateCognitoUserSuccess ] = useState(null)
  const createCognitoAccount = async (emailAddress, phoneNumber) => {
    const user = {
      "emailAddress": emailAddress,
      "phoneNumber": phoneNumber,
    }

    const response = await createCognitoUser(user);

    try {
      if (response?.statusCode === 200) {
        // setCreateCognitoUserSuccess(response?.User);
        return true;
      }
      if (response?.statusCode === 400) {
        // const errorMessage = JSON.parse(response?.body)?.message;
        const errorMessage = JSON.parse(response?.body)?.errorMessage
        console.log({errorMessage})
        // setCreateCognitoUserError(errorMessage);
        setSubmitModalContent(errorMessage)
        setSubmitModalCloseDisabled(false)
        // setError('userAccount', { type: "manual", message: errorMessage })
        return false
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Failed to create Cognito user:", error);
      const errorMessage = error?.response?.data?.message || "Failed to connect to the service";
      // setCreateCognitoUserError(errorMessage);
      setSubmitModalContent(errorMessage);
      setSubmitModalCloseDisabled(false);
  
      // Uncomment and adjust if using React Hook Form:
      // setError('userAccount', { type: "manual", message: errorMessage });
  
      return false;
    }
  }

  const addCognitoAccountToGroup = async (emailAddress, groupName) => {
    const params = {
      "userName": emailAddress,
      "groupName": groupName,
      "groupaction": "add",
    }
    const response = await addCognitoUserToGroup(params);

    // console.warn('----- addCognitoAccountToGroup response: ', response)

    if (response.$metadata.httpStatusCode === 200) {
      return true;
    }
    if (response.$metadata.httpStatusCode === 400) {
      const errorMessage = JSON.parse(response?.body)?.errorMessage
      console.log({errorMessage})
      // setCreateCognitoUserError(errorMessage);
      setSubmitModalContent(errorMessage)
      setSubmitModalCloseDisabled(false)
      return false
    }
  }

  const handleDateOfBirthChanged = (event) => {
    const dateInput = event.target.value
    const numericValue = dateInput.replace(/\D/g, '');
    if (numericValue==='') {
      setDateOfBirth('');
      // setValue('dateOfBirth', '')
      return true
    } else {
      const formatted = formatDateMDY(numericValue);
      setDateOfBirth(formatted);
      // setValue('dateOfBirth', formatted)
      return true
    }
  }

  const handleDateOfHireChanged = (event) => {
    const dateInput = event.target.value
    const numericValue = dateInput.replace(/\D/g, '');
    if (numericValue==='') {
      setDateOfHire('')
      // setValue('dateOfHire', '')
      return true
    } else {
      const formatted = formatDateMDY(numericValue);
      setDateOfHire(formatted);
      // setValue('dateOfHire', formatted)
      return true
    }
  }

  const handleKeyDown = (event) => {
    // Check if the key pressed is 'Enter'
    if (event.key === 'Enter' && event.target.type !== 'textarea') {
      event.preventDefault();  // Prevent form submission
    }
  };

  return (
    <>
    <Container as="form" maxW="1600" mb={12} onKeyDown={handleKeyDown} onSubmit={handleSubmit(formSubmit, onError)}>

      {/* <ButtonPrimaryPlain value='test create' onClick={() => {let x = createCognitoAccount("webadmin+test@spikeenterprise.com","4058876403")}} /> */}

      {/* <ModuleBoxDrawer>
        <Box p={4} borderWidth='1px' borderRadius='lg'>
          <Heading as='h1' size='lg'>----- THIS IS LIVE! EMAILS AND TEXT MESSAGES WILL BE SENT! -----</Heading>
        </Box>
      </ModuleBoxDrawer> */}

      <ModuleBoxDrawer mt='25px'>
        <Flex>
          <Box>
            <Text as="span" textStyle='heading-1'>Create Team Member Account</Text>
          </Box>
          <Spacer />
          <Box>
            <ButtonQuaternaryWithIcon 
              name='closeDrawer'
              iconsize='26px'
              leftIcon='close'
              value='Cancel'
              onClick={onClose}
            />
          </Box>
        </Flex>
        
        <Box w={'40%'} my={'25px'}>
          <Flex>
            <Box>
              <Text as="span" textStyle='label-1'>New Team Member is Temp-to-Hire</Text><br/>
              {/* <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>Temp-to-Hire employees do not use TimeCo for tracking punches.</Text> */}
            </Box>
            <Spacer />
            <Box>
              <FormSwitch
                register={register}
                control={control}
                fieldname="isTempToHire"
                isChecked={isTempToHire}
                onChange={() => {
                  setValue('isTempToHire', !isTempToHire)
                  setIsTempToHire(!isTempToHire)
                  setSelectedTCUser('')
                }}
              />
            </Box>
          </Flex>
        </Box>

        {(!isTempToHire) && <>
          <Box w={'40%'}>
            <FormControl isInvalid={errors.userAccount}>
              {!currentCognitoUsers?.length>0 ? (
                <FormLabel htmlFor='userAccount'>Fetching Team Member Email Accounts...</FormLabel>
              ) : (
                <FormLabel htmlFor='userAccount'>Team Member Login Email</FormLabel>
              )}
              <Skeleton isLoaded={currentCognitoUsers?.length>0}>
              <TextInput
                autoComplete="off"
                register={register}
                errors={errors}
                fieldtype="text"
                fieldname="userAccount"
                prettyname="Login Email"
                fieldvalue={validatedEmailAddress}
                // onChange={(e) => handleAccountChanged(e)}
                // onChange={handleAccountChanged}
                placeholder="Enter team member email address"
                isRequired={true}
                onChange={validateEmail}
              />
              </Skeleton>
            </FormControl>
          </Box>

          <Box w={'40%'} my={'25px'}>
            <TextInput
              register={register}
              errors={errors}
              fieldname="userPhone"
              fieldlabel="Team Member Cell Phone"
              fieldtype="tel"
              prettyname="Team Member Cell Phone"
              placeholder="Enter New Phone"
              isRequired={true} 
              maximLength="16"
              minimLength="16"
              onBlur={handleUserPhoneOnBlur}
              onChange={handleUserPhoneOnChange}
            />
          </Box>
        </>}

        <Box w={'40%'} my={'25px'}>
          <FormSelectSimple
            key='select_division'
            register={register}
            control={control}
            errors={errors}
            isRequired={true}
            rules={{ required: "Division is required" }}
            fieldname="selectDivision"
            fieldlabel="Select Division"
            placeholder={'Select division or start typing'}
            optionsArray={divisions?.map(({ prettyname, id }) => ({
              label: prettyname,
              value: id,
            }))}
            // optionsArray={divisions?.map(({ defaultQBClass, id }) => ({
            //   label: defaultQBClass,
            //   value: id,
            // }))}
            onChange={(e) => handleDivisionChanged(e)}
            selectedoption={selectedDivision||{}}
          />
        </Box>

        {(!isTempToHire) && <>
          <Box w={'40%'} my={'25px'}>
            <FormSelectSimple
              key='select_tcuser'
              register={register}
              control={control}
              errors={errors}
              isRequired={true}
              rules={{ required: "TimeCo employee is required" }}
              fieldname="selectTimeCoUser"
              fieldlabel="TimeCo Current Employee"
              optionsArray={timecoEmployeesExtended?.map(({ BadgeNumber, FirstName, MiddleName, LastName }) => ({
                label: LastName+', '+FirstName+' '+MiddleName,
                value: BadgeNumber,
              }))}
              onChange={(e) => handleTimeCoUserChanged(e)}
              selectedoption={selectedTCUser||{}}
              placeholder={'List of current TimeCo employees.'}
              // isDisabled={(!customers?.length > 0) || !isLoggedInQB}
            />
          </Box>

          <Box w={'40%'} my={'25px'}>
            <Box>
              <Text as="span" textStyle='label-2'>Team Member Groups</Text>
            </Box>
            <Box pb={'10px'}>
              <TextInput
                register={register}
                errors={errors}
                fieldname="oneGroupIsSelected"
                value={groupsSelected||''}
                fieldtype="hidden"
                isRequired={(groupsSelected?.length===0)}
                regexPattern={/^[a-z]$/}
                regexMessage="At least one Team Member Group is required."
              />
              <TextInput
                register={register}
                errors={errors}
                fieldname="issuedCreditCard"
                value={isIssuedCreditCard}
                fieldtype="hidden"
              />
            </Box>
            <CheckboxGroup onChange={handleCheckboxChange}>
              {(userPoolGroupCheckboxes?.length>0) && <Stack spacing={[1,userPoolGroupCheckboxes?.length]} direction={['row', 'column']}>
                {userPoolGroupCheckboxes}
              </Stack>}
            </CheckboxGroup>
          </Box>

          <Box w={'40%'} my={'25px'}>
            <TextInputV2
              register={register}
              errors={errors}
              fieldname="dateOfBirth"
              fieldlabel="Date of Birth"
              prettyname="Date of Birth"
              placeholder="mm/dd/yyyy"
              onChange={handleDateOfBirthChanged}
              isRequired={true}
              fieldvalue={dateOfBirth}
              maxLength="10"
              minimLength="10"
              maximLength="10"
              regexMessage="A birth date is required."
            />
          </Box>

        </>}

        <Box w={'40%'} my={'25px'}>
          <FormSelectSimple
            key='select_jobclass'
            register={register}
            control={control}
            errors={errors}
            isRequired={true}
            rules={{ required: "Job Class is required" }}
            fieldname="selectJobClass"
            fieldlabel="Select Default Job Class"
            placeholder={'Select job class or start typing'}
            optionsArray={jobClassOptions?.map(option  => ({
              label: option.label,
              value: option.value,
            }))}
            onChange={(e) => handleJobClassChanged(e)}
            selectedoption={selectedJobClass||{}}
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <TextInputV2
            register={register}
            errors={errors}
            fieldname="dateOfHire"
            fieldlabel="Date of Hire"
            prettyname="Date of Hire"
            onChange={handleDateOfHireChanged}
            fieldvalue={dateOfHire}
            placeholder="mm/dd/yyyy"
            isRequired={true}
            maxLength="10"
            minimLength="10"
            maximLength="10"
            regexMessage="A hire date is required."
          />
        </Box>

        <Box w={'40%'} mb={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="firstName"
            fieldlabel="First Name"
            prettyname="First Name"
            placeholder="Enter first name"
            isRequired={true}
            regexPattern={/^[a-zA-Z\s]+$/}
            regexMessage="First name is required and must be letters only."
          />
        </Box>

        {(!isTempToHire) && <Box w={'40%'} mb={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="goesBy"
            fieldlabel="Goes By or Nickname"
            // onChange={handleFirstNameChanged}
            placeholder="Enter name if different than first name."
          />
        </Box>}

        <Box w={'40%'} mb={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="middleName"
            fieldlabel="Middle Name"
            prettyname="Middle Name"
            placeholder="Enter middle name"
            regexPattern={/^[a-zA-Z-\s.]+$/}
            regexMessage="Middle name must only contain letters, spaces, periods or hyphens."
          />
        </Box>

        <Box w={'40%'} mb={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="lastName"
            fieldlabel="Last Name"
            prettyname="Last Name"
            // onChange={handleLastNameChanged}
            placeholder="Enter last name"
            isRequired={true}
            regexPattern={/^[a-zA-Z-\s]+$/}
            regexMessage="Last name is required and must only contain letters, spaces or hyphens."
          />
        </Box>

        {(!isTempToHire) && <>
          <Box paddingTop={'5px'} paddingBottom={'25px'} w={'40%'}>
            <FormSelectSimple
              // autocomplete="off"
              // key={`select_location_${suggestedAddresses.length}`}  // try and keep the component fresh
              key={'select_location'}  // try and keep the component fresh
              register={register}
              control={control}
              errors={errors}
              // isRequired={true}
              fieldname="selectLocation"
              fieldlabel="Address Lookup"
              placeholder="Start typing address..."
              optionsArray={suggestedAddresses?.Results?.map((suggestion) => ({
                label: suggestion.Text,
                value: suggestion.Text,
              }))}
              onChange={(e) => { handleSuggestedLocationChanged(e) }}
              issearchable={true}
              filterOption={alwaysShowOptionsFilter}
              onKeyDown={(e) => {
                if (e.key === '.') {
                  e.preventDefault(); // Prevents the period from being entered which is causing issues with location lookup
                }
              }}
              onInputChange={(value) => {
                setInput(value)
                setSelectedAddress(value)
                setValue('selectLocation', value)
              }}
            />
          </Box>
          
          <Box w={'40%'} mb={'25px'}>
            <TextInput
              register={register}
              errors={errors}
              fieldname="addStreet"
              fieldlabel="Street"
              prettyname="Street"
              onChange={handleAddStreetChanged}
              placeholder="Enter street"
              isRequired={true}
            />
          </Box>

          <Box w={'40%'} mb={'25px'}>
            <TextInput
              register={register}
              errors={errors}
              fieldname="addCity"
              fieldlabel="City"
              prettyname="City"
              onChange={handleAddCityChanged}
              placeholder="Enter city"
              isRequired={true}
            />
          </Box>

          <Box w={'40%'} mb={'25px'}>
            <TextInput
              register={register}
              errors={errors}
              fieldname="addState"
              fieldlabel="State"
              prettyname="State"
              onChange={handleAddStateChanged}
              placeholder="Enter state"
              isRequired={true}
            />
          </Box>

          <Box w={'40%'} mb={'25px'}>
            <TextInput
              register={register}
              errors={errors}
              fieldname="addZipcode"
              fieldlabel="Zip Code"
              prettyname="Zip Code"
              // onChange={handleAddZipcodeChanged}
              placeholder="Enter zip code"
              isRequired={true}
              regexPattern={/^\d{5}(-\d{4})?$/}
              regexMessage="Invalid zip code format. Must be 5 digits or 5 digits followed by a hyphen and 4 digits."
            />
          </Box>

          <Box w={'40%'} mb={'25px'}>
            <TextInput
              register={register}
              errors={errors}
              fieldname="emergcyContName"
              fieldlabel="Emergency Contact Name"
              prettyname="Emergency Contact Name"
              onChange={handleEmergcyContNameChanged}
              placeholder="Enter emergency contact name"
            />
          </Box>

          <Box w={'40%'} my={'25px'}>
            <TextInput
              register={register}
              errors={errors}
              fieldname="emergencyContPhoneNumber"
              fieldlabel="Emergency Contact Phone"
              fieldtype="tel"
              prettyname="Emergency Contact Phone"
              placeholder="Enter emergency contact phone number"
              maximLength="16"
              minimLength="16"
              onBlur={handleContactPhoneOnBlur}
              onChange={handleContactPhoneOnChange}
            />
          </Box>
        </>}

        <HStack my={'25px'} key={uuidv4()}>
          <Box w={'40%'}>
            <ButtonSecondaryPlain 
              width={'100%'}
              name='cancel'
              value='Cancel'
              // onClick={() => {
              //   setSelectedActionButton(null)
              // }}
              // onClick={() =>  handleInvoiceReset()}
              // isDisabled={!isFormLoaded}
            />  
          </Box>

          <Box w={'60%'}>
            <ButtonPrimaryPlain
              type="submit"
              onClick={handleSubmit(formSubmit, onError)}
              width={'100%'}
              name='submit'
              value={(isTempToHire) ? 'Add New Team Member' : 'Create New Team Member & Send New Account Email'}
              // isDisabled={!isFormLoaded || djtFields?.length===0 || (attachableObjects?.length!==djtFields?.length)}
            />
          </Box>
        </HStack>

      </ModuleBoxDrawer>

    </Container>

    <CenteredSubmitWithProgressModal
      isModalOpen={isSubmitModalOpen}
      onModalClose={onSubmitModalClose}
      content={submitModalContent||''}
      closeButtonDisabled={submitModalCloseDisabled}
      handleSubmitModalClosed={handleSubmitModalClosed}
      progress={submissionProgress}
      successMessage={'Team member has been created.'}
      closeButtonVisible={true}
    />
    </>
  )
}

CreateTeamMember.propTypes = {
  // adminId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};