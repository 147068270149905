import { allDivisionsSortedByName } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const fetchDivisionOptions = async (allSort, sortDirection) => {
  try {

    const gqlParams = {
      allSort: allSort||"all",
      sortDirection: sortDirection
    };

    const response = await client.graphql({
      query: allDivisionsSortedByName,
      variables: gqlParams
    });

    const results = response.data.allDivisionsSortableByName.items;

    // Uncomment the following lines if sorting by contactCompany is required
    // results.sort(function(a, b) {
    //   if (a.contactCompany > b.contactCompany) return 1;
    //   if (a.contactCompany < b.contactCompany) return -1;
    //   return 0; // explicitly handling the case where they are equal
    // });

    // console.warn(' ----- fetchDivisions results: ', results)
    return results;
  } catch (err) {
    return ('error fetchDivisionOptions: ', [err]);
  }
};

fetchDivisionOptions.propTypes = {
  allSort: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
};
