import { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Divider,
  RadioGroup,
  Radio,
  Flex,
  Spacer,
  Box,
  Center,
  Text,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";

import { TextareaInputInspection } from '../Input/TextareaInputInspection';
import { TextareaInput } from '../Input/TextareaInput';
import { TextInput } from '../Input/TextInput';

export const PassFailRadioGroup = (props) => {

  const {
    register,
    control,
    errors,
    fieldname,
    prettyname,
    rules,
    fieldlabel,
    // key,
    index,
    onChange,
    getValues,
    setValue,
    isRequired,
  } = props

  const FailedReasonInput = (index) => {
    
    return (
      <Box mt={'20px'}>
        <TextareaInputInspection
          register={register}
          errors={errors}
          rules={{ required: "Required" }}
          fieldname={`failedReason_${index}`}
          fieldlabel="Describe the reason for failed inspection"
          prettyname="Failed descripiton"
          placeholder="Enter description"
          isRequired
        />
      </Box>
    )
  }


  const [ showFailedReason, setShowFailedReason ] = useState(false)
  const { getRadioProps } = useRadioGroup({
    // onChange: console.log,
    // onChange: (newValue) => {console.log('newValue: ', newValue+' index: '+index+' '+getValues('pass_fail_buttons_group_'+index))},
    onChange: (newValue) => {
      setValue('pass_fail_buttons_group_'+index, newValue)
      // setValue('passfailRadioGroup_'+index, newValue)
      if (newValue==='Fail') {
        setShowFailedReason(true)
      } else {
        setShowFailedReason(false)
      }
    },
  })

  const radioPass = getRadioProps({ value: 'Pass' })
  const radioFail = getRadioProps({ value: 'Fail' })

  const passProps = useRadio(radioPass)
  const failProps = useRadio(radioFail)

  const selectedValue = getValues('pass_fail_buttons_group_'+index)

  return (

    <Controller
      errors={errors}
      control={control}
      name={fieldname}
      rules={rules}
      render={({ 
        field: { onChange, value, name, ref },
        fieldState: { error }
      }) => (
        <FormControl 
          id={fieldname} 
          isInvalid={!!errors[fieldname]}> {/* errors[fieldname] exists after changing */}
          {(fieldlabel!=='') && <FormLabel>{fieldlabel}</FormLabel>}


            <RadioGroup 
              key={'passfailRadioGroup_'+index}
              name={`passfailRadioGroup_${index}`}
              // spacing={4}
              value={selectedValue}
              {...register(fieldname, { 
                required: { 
                  value: true, 
                  message: `${prettyname||fieldname} required.`, 
                }, 
              })}>
              <Flex key={'flex_'+index}>
 
                <Box 
                  key={'pass_wrapper_'+index}
                  w={'44%'} 
                  border='1px solid var(--dark-button-grey-4)'
                  borderRadius='12px'
                  as='label'>
                  <input key={'input_pass_'+index} {...passProps.getInputProps()} hidden />
                  <Box
                    key={'pass_button_'+index}
                    {...passProps.getRadioProps()}
                    cursor='pointer'                  
                    _checked={{
                      border: '1px solid var(--success-green)',
                      borderRadius: '12px',
                      bg: 'var(--success-green)',
                      color: 'var(--light-text-grey-1)',
                    }}
                    _focus={{
                      boxShadow: 'outline',
                    }}
                    px={5}
                    py={3}
                    // onChange={onChange}
                    >
                    <Center key={'pass_center_'+index}>
                      <Text key={`pass_button_${index}`} as="span" color={'var(--dark-text-grey-1)'} textStyle='text-2'>Pass</Text>
                    </Center>
                  </Box>
                </Box>
                <Spacer/>
                <Box 
                  key={'fail_wrapper_'+index} 
                  w={'44%'} 
                  border='1px solid var(--dark-button-grey-4)'
                  borderRadius='12px'
                  as='label'>
                  <input key={'input_fail_'+index} {...failProps.getInputProps()} hidden />
                  <Box
                    key={'fail_button_'+index}
                    {...failProps.getRadioProps()}
                    cursor='pointer'                  
                    _checked={{
                      border: '1px solid var(--error-red)',
                      borderRadius: '12px',
                      bg: 'var(--error-red)',
                      color: 'var(--light-text-grey-1)',
                    }}
                    _focus={{
                      boxShadow: 'outline',
                    }}
                    px={5}
                    py={3}
                    // onChange={onChange}
                    >
                    <Center key={'fail_center_'+index}>
                      <Text key={`fail_button_${index}`} as="span" color={'var(--dark-text-grey-1)'} textStyle='text-2'>Fail</Text>
                    </Center>
                  </Box>
                </Box>

                <Radio {...register(fieldname)} className='hidden_radio' key={'radio_pass_'+index} value="Pass"></Radio>
                <Radio {...register(fieldname)} className='hidden_radio' key={'radio_fail_'+index} value="Fail"></Radio>
                {/* <Radio key={'radio_pass_'+index} value="Pass"></Radio>
                <Radio key={'radio_fail_'+index} value="Fail"></Radio> */}

              </Flex>
            </RadioGroup>
            {(showFailedReason) && FailedReasonInput(index)}


            <FormErrorMessage>
              {errors[fieldname]?.message}
            </FormErrorMessage>
          </FormControl>
      )}
    />

    
  )
}
