import { updateCreditCardTeamMemberMap } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const updateCreditCardTeamMember = async (props) => {
  if (!props) return;

  props.allSort = 'all';

  try {
    const gqlParams = {
      input: props,
    };

    const response = await client.graphql({
      query: updateCreditCardTeamMemberMap,
      variables: gqlParams,
    });

    const results = response.data.updateSpkCreditCardTeamMemberMapTbl.id;
    return results;
  } catch (err) {
    return 'error updateCreditCardTeamMember: ' + err;
  }
};

updateCreditCardTeamMember.propTypes = {
  props: PropTypes.shape({
    allSort: PropTypes.string,
    // Add any other fields in `props` that are required here.
  }).isRequired,
};