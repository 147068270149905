import { useState, useEffect } from 'react';
import { PlaidLink } from 'react-plaid-link';
import PropTypes from 'prop-types';

export const PlaidAuth = ({ onTokenChange }) => {
  const [plaidTokenObj, setPlaidTokenObj] = useState(null);
  const [linkToken, setLinkToken] = useState(null);

  const fetchLinkToken = async () => {
    try {
      const response = await fetch('https://bpns2w73m2lo6pkw5ovwrsqdwi0xoxpt.lambda-url.us-east-1.on.aws/create_link_token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({}), // Send an empty JSON body
      });

      if (!response.ok) {
        const errorBody = await response.text();
        console.error('Response error body:', errorBody);
        throw new Error(`HTTP error! Status: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      setLinkToken(data.link_token);
    } catch (error) {
      console.error('Error fetching link token:', error);
    }
  };

  useEffect(() => {
    fetchLinkToken();
  }, []);

  const onSuccess = async (public_token, metadata) => {
    console.log('Public Token: ', public_token);
    console.log('Metadata: ', metadata);
    // setInstitutions([...institutions, { public_token, metadata }]);
    try {
      const response = await fetch('https://bpns2w73m2lo6pkw5ovwrsqdwi0xoxpt.lambda-url.us-east-1.on.aws/exchange_public_token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ public_token }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Data: ', data);
      
      // setPlaidTokenObj(data);
      // localStorage.setItem('plaidAccessToken', JSON.stringify({ accessToken: data.access_token, expirationTime: new Date().getTime() + (Number(data.expires_in) * 1000) }));
      localStorage.setItem('plaidRefreshToken', data.refresh_token);

      // Reset link token for adding another institution
      fetchLinkToken();
    } catch (error) {
      console.error('Error exchanging public token:', error);
    }
  };

  useEffect(() => {
    if (plaidTokenObj) {
      console.warn(' ----- plaidTokenObj changed: ', plaidTokenObj);
      onTokenChange(plaidTokenObj); // Update parent state with new plaidTokenObj
    }
  }, [plaidTokenObj, onTokenChange]);

  useEffect(() => {
    const storedTokenString = localStorage.getItem('plaidAccessToken');
    console.log('Stored Token: ', storedTokenString);
    if (storedTokenString) {
      const accessTokenObject = JSON.parse(storedTokenString);
      const { expirationTime } = accessTokenObject;
      if (new Date().getTime() < expirationTime) {
        setPlaidTokenObj(accessTokenObject);
      }
    }
  }, []);

  if (!linkToken) {
    return (<div>Loading...</div>);
  }

  return (
    <div>
      <PlaidLink
        token={linkToken}
        onSuccess={onSuccess}
        onExit={(error, metadata) => {
          console.log('Exit: ', error, metadata);
        }}
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '6px',
          fontSize: '14px',
          cursor: 'pointer'
        }}
      >
        Connect Institution
      </PlaidLink>
    </div>
  );
};

PlaidAuth.propTypes = {
  onTokenChange: PropTypes.func.isRequired,
}