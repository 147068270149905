import { useEffect, useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  HStack,
  Text,
  Flex,
  Spacer,
  useDisclosure,
  Grid, 
  Image,
} from "@chakra-ui/react";

// custom hooks
import { getSignedUrlForFile } from '../custom-hooks/useSignedUrlForFile.jsx';

// custom components
import { UploadUnitImages } from './UploadUnitImages.jsx';

// Icons
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// uuid
import { v4 as uuidv4 } from 'uuid';

// modals
import { CenteredSubmitWithProgressModal } from '../Modal/CenteredSubmitWithProgressModal.jsx';
import { delay } from '../functions/generic.jsx';

// Buttons
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';

// graphQl
import { fetchPrimaryUnitById } from '../graphqlCompnents/Unit/fetchPrimaryUnitById.jsx';
import { fetchDivisionOptions } from '../graphqlCompnents/Unit/fetchDivisionOptions.jsx';
import { fetchUnitTypes } from '../graphqlCompnents/Unit/fetchUnitTypes.jsx';
import { fetchUnitSubTypes } from '../graphqlCompnents/Unit/fetchUnitSubTypes.jsx';
import { updateUnit } from '../graphqlCompnents/Unit/updateUnit.jsx';
import { fetchUnitDocumentsByUnitId } from '../graphqlCompnents/Unit/fetchUnitDocumentsByUnitId.jsx';

// structural components
import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

export const EditUnitImages = ({ unitId, imageId, onClose }) => {

  // State to track the default image ID
  const [defaultImageId, setDefaultImageId] = useState(imageId);

  // grab global context values
  const { store } = useContext(AppContext);

  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id
  console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)

  // --- REACT-HOOK-FORM ---
  const { 
    handleSubmit, 
    setValue } = useForm({
      mode: 'onSubmit',
      // reValidateMode: 'onChange',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const onError = (errors, e) => console.log(errors, e);

  const { isOpen: isSubmitModalOpen , onOpen: onSubmitModalOpen, onClose: onSubmitModalClose } = useDisclosure()
  const [ submitModalContent, setSubmitModalContent ] = useState(null)
  const [ submitModalCloseDisabled, setSubmitModalCloseDisabled ] = useState(true)
  const [ submissionProgress, setSubmissionProgress ] = useState(0)
  async function handleSubmitModalClosed() {
    onSubmitModalClose()
    await delay(500)
    onClose()
  }

  const unitDocumentTypes = store?.unitDocumentTypes
  const [smallImageTypeId, setSmallImageTypeId] = useState(null);
  useEffect(() => {
    const findTypeIdByName = (name) => unitDocumentTypes?.find(type => type?.name === name)?.id;

    if (unitDocumentTypes) {
      const smallTypeId = findTypeIdByName('unit-photo-small');

      if (smallTypeId) {
        console.info('Set Small Image Type ID: ', smallTypeId);
        setSmallImageTypeId(smallTypeId);
      }
    }
  }, [unitDocumentTypes, setSmallImageTypeId]);

  const [ refreshIndex, setRefreshIndex ] = useState(0);
  const handleRefresh = () => {
    setRefreshIndex(prev => prev + 1); // Increment to trigger useEffect
  };

  const [ unitDocuments, setUnitDocuments ] = useState(null)
  useEffect(() => {
    const fetchUnitDocuments = async () => {
      const documents = await fetchUnitDocumentsByUnitId(unitId)
      setUnitDocuments(documents)
      console.info('documents: ', documents)
    }
    fetchUnitDocuments()
  },[unitId, refreshIndex])

  const [ unitImages, setUnitImages ] = useState(null)
  useEffect(() => {
    if (unitDocuments) {
      (async () => {
        const smallImageDocuments = unitDocuments.filter((unit) => unit?.unitDocumentType.id === smallImageTypeId);
        console.log('smallImageDocuments: ', smallImageDocuments);
        const unitImagesData = await Promise.all(
          smallImageDocuments.map(async (unit) => {
            console.log('unit: ', unit)
            const isDefault = unit.id === defaultImageId;
            const unitId = unit.unitId;
            const key = unit?.uploadedDocument?.key||null;
            const identityId = unit?.uploadedDocument?.identityId||null;
            const bucket = unit?.uploadedDocument?.bucket || null;

            if (key && identityId && bucket) {

              const signedUrl = await getSignedUrlForFile({
                bucketName: bucket,
                identityId: identityId,
                fileName: key,
                fileSecurity: 'protected', // Optional if you want to make this dynamic
              });

              return {
                unitId: unitId,
                signedUrl: signedUrl,
                isDefault: isDefault,
                imageId: unit.id,
              };
            } else {
              return {
                unitId: unitId,
                signedUrl: null,
                isDefault: isDefault,
                imageId: unit.id,
              };
            }
          })
        );
        setUnitImages(unitImagesData);
        console.log('unitImagesData: ', unitImagesData)
      })();
    }
  }, [unitDocuments, smallImageTypeId, defaultImageId]);

  // Function to toggle the default image
  const toggleDefaultImage = (id) => {
    setDefaultImageId(prevId => prevId === id ? null : id);
  }

  const ImageViewer = ({ unitImages }) => {
    console.log('unitImages: ', unitImages)
    return (
      <Grid templateColumns="repeat(4, 1fr)" gap={6}>
        {unitImages && unitImages.map((unit, index) => (
          <Box key={index} mt='25px' 
            display="flex" flexDirection="column" alignItems="center"
            cursor="pointer"
            onClick={() => toggleDefaultImage(unit.imageId)}>
            <Box key={index} mt='25px' display="flex" flexDirection="column" alignItems="center">
              <Image border={'1px solid var(--dark-unit-image-border)'} src={unit.signedUrl} alt={`Unit Image ${unit.unitId}`} />
              <Box mt={'10px'} display="flex" justifyContent="center">
                {unit?.isDefault ? 
                  <AvailableIcons boxSize={'22px'} iconRef={'checkboxon'} color={'var(--dark-gold-primary)'} /> : 
                  <AvailableIcons boxSize={'22px'} iconRef={'checkboxoff'} color={'var(--dark-grey-text-1)'} />
                }
              </Box>
            </Box>
          </Box>
        ))}
      </Grid>
    );
  }

  ImageViewer.propTypes = {
    unitImages: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          imageId: PropTypes.string,
          signedUrl: PropTypes.string,
          unitId: PropTypes.string,
          isDefault: PropTypes.bool,
        })
      )
    ),
  };

  const [ fetchedUnit, setFetchedUnit ] = useState(null)
  const [ unitCode, setUnitCode ] = useState(null)

  const [ divisionOptions, setDivisionOptions ] = useState([])
  useEffect(() => {
    const fetchDivisions = async () => {
      try {
        const response = await fetchDivisionOptions();
        const options = response.map(division => ({
          value: division.id,
          label: division.prettyname,
        }))
        setDivisionOptions(options);

      } catch (error) {
        console.error('Failed to fetch divisions:', error);
      }
    };

    fetchDivisions();
  }, []);

  const [ unitTypeOptions, setUnitTypeOptions ] = useState([])
  useEffect(() => {
    const listUnitTypes = async () => {
      try {
        const response = await fetchUnitTypes();
        const options = response.map(unit => ({
          value: unit.id,
          label: unit.name,
        }))
        setUnitTypeOptions(options);
        
      } catch (error) {
        console.error('Failed to fetch unit types:', error);
      }
    };
    
    listUnitTypes();
  }, []);

  const [ unitSubTypes, setUnitSubTypes ] = useState([])
  useEffect(() => {
    const listUnitSubTypes = async () => {
      try {
        const response = await fetchUnitSubTypes();
        console.log('fetchUnitSubTypes response: ', response);
        setUnitSubTypes(response);

      } catch (error) {
        console.error('Failed to fetch unit sub-types:', error);
      }
    };

    listUnitSubTypes();
  }, []);

  let auxiliaryUnitsToAdd = [];
  let auxiliaryUnitsToRemove = [];

  useEffect(() => {
    if (unitId) {
      const fetchUnit = async () => {
        const unit = await fetchPrimaryUnitById(unitId)
        if (unit) {
          setFetchedUnit(unit)
          console.log(' ----- unit: ', unit)

          setValue('code', unit.code)
          setUnitCode(unit.code)
          setValue('make', unit.make)
          setValue('model', unit.model)
          setValue('color', unit.color)
          setValue('year', unit.year)
          setValue('tag', unit.tag)
          setValue('vin', unit.vin)
          setValue('serial', unit.serial)
          setValue('isActive', unit.isActive)
          setValue('isInspectedDaily', unit.isInspectedDaily)
          setValue('requiresDriver', unit.requiresDriver)
          setValue('requiresOperator', unit.requiresOperator)
          if (imageId !== unit?.unitSmallImageId && unit?.unitSmallImageId) {
            setDefaultImageId(unit?.unitSmallImageId)
          }
        }
      }

      fetchUnit()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[unitId, refreshIndex])

  useEffect(() => {
    if (fetchedUnit && divisionOptions.length>0) {
      setValue('selectDivision', fetchedUnit?.unitStatus?.divisionId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchedUnit, divisionOptions])

  useEffect(() =>{
    if (fetchedUnit && unitTypeOptions.length>0) {
      setValue('selectUnitType', fetchedUnit?.typeId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchedUnit, unitTypeOptions])

  useEffect(() => {
    if (fetchedUnit && unitSubTypes.length>0) {
      const fetchUnitSubTypes = async () => {
        setValue('selectUnitSubType', fetchedUnit.subTypeId)
      }

      fetchUnitSubTypes(fetchedUnit?.typeId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchedUnit, unitSubTypes])

  const handleKeyDown = (event) => {
    // Check if the key pressed is 'Enter'
    if (event.key === 'Enter' && event.target.type !== 'textarea') {
      event.preventDefault();  // Prevent form submission
    }
  };

  const formSubmit = async (values, event) => {
    // reference AddTeamMember.js for Cognito Add User functionality
    event.preventDefault();
    console.warn(' ----- formSubmit values: ', values)
    console.log(' ----- fetchedUnit: ', fetchedUnit)
    console.log(' ----- auxiliaryUnitsToRemove: ', auxiliaryUnitsToRemove)
    console.log(' ----- auxiliaryUnitsToAdd: ', auxiliaryUnitsToAdd)
    
    onSubmitModalOpen()
    setSubmitModalContent('Updating unit...')
    setSubmitModalCloseDisabled(true)
    setSubmissionProgress(10)

    const unitParams = {
      id: unitId,
      unitSmallImageId: defaultImageId,
      unitMediumImageId: defaultImageId,
    }

    console.warn(' ----- sending unitParams: ', unitParams)
    const response = await updateUnit(unitParams)
    console.warn(' ----- updateUnit response: ', response)

    setSubmitModalContent('Unit has been updated.')
    setSubmissionProgress(100)
    setSubmitModalCloseDisabled(false)

  }

  return (
    <>
    <Container as="form" maxW="1600" mb={12} onKeyDown={handleKeyDown} onSubmit={handleSubmit(formSubmit, onError)}>

      <ModuleBoxDrawer mt='25px'>
        <Flex>
          <Box>
            <Text as="span" textStyle='heading-1'>Select Default Image for Unit: {fetchedUnit?.code}</Text>
          </Box>
          <Spacer />
          <Box>
            <ButtonQuaternaryWithIcon 
              name='closeDrawer'
              iconsize='26px'
              leftIcon='close'
              value='Cancel'
              onClick={onClose}
            />
          </Box>
        </Flex>

        <ImageViewer unitImages={unitImages} />

        <HStack my={'25px'} key={uuidv4()}>
          <Box w={'40%'}>
            <ButtonSecondaryPlain 
              width={'100%'}
              name='cancel'
              value='Cancel'
              onClick={onClose}
            />  
          </Box>

          <Box w={'60%'}>
            <ButtonPrimaryPlain
              type="submit"
              onClick={handleSubmit(formSubmit, onError)}
              width={'100%'}
              name='submit'
              value={'Update Unit'}
            />
          </Box>
        </HStack>


      </ModuleBoxDrawer>

      {(unitId && unitCode) && <ModuleBoxDrawer mt='25px'>
        <UploadUnitImages 
          unitid={unitId}
          unitname={unitCode}
          refresh={handleRefresh}
        />
      </ModuleBoxDrawer>}

    </Container>
    <CenteredSubmitWithProgressModal
      isModalOpen={isSubmitModalOpen}
      onModalClose={onSubmitModalClose}
      content={submitModalContent}
      closeButtonDisabled={submitModalCloseDisabled}
      handleSubmitModalClosed={handleSubmitModalClosed}
      progress={submissionProgress}
      successMessage={'Unit has been updated.'}
      closeButtonVisible={true}
    />
    </>
  )
}

EditUnitImages.propTypes = {
  unitId: PropTypes.string,
  imageId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};