import { useContext, useState, useEffect, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
// import { useSearchParams } from 'react-router-dom';
import {
  Container,
  Box,
  Text,
  Center,
  Image,
  VStack,
  useRadioGroup,
  useDisclosure,
  Flex,
  Spacer,
} from "@chakra-ui/react";

// idel timer --- https://idletimer.dev/docs/features/idle-detection

// globals
import { AppContext } from '../AppContext.jsx';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { PassFailRadioGroup } from '../Form/RadioGroup/PassFailRadioGroup.jsx';
import { PassFailNARadioGroup } from '../Form/RadioGroup/PassFailNARadioGroup.jsx';

import { getSignedUrlForFile } from '../custom-hooks/useSignedUrlForFile.jsx';

// buttons
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonOctonaryRed } from '../Form/Button/ButtonOctonaryRed.jsx';
import { ButtonSenaryWithIconOnly } from '../Form/Button/ButtonSenaryWithIconOnly.jsx';

// graphql
// import { updatePrimaryUnitInspectionStatus } from '../graphqlCompnents/Inspection/updatePrimaryUnitInspectionStatus.jsx';
import { addPrimaryUnitInspection } from '../graphqlCompnents/Inspection/addPrimaryUnitInspection.jsx';
import { fetchUnitDocumentsByUnitIdDocumentTypeId } from '../graphqlCompnents/Unit/fetchUnitDocumentsByUnitIdDocumentTypeId.jsx';

// temp image pending image for development
import ImagePending from "/images/image_pending_icon_medium.png"

// functions
import { todaysDateYMD, awsDateTimeNow, timeDifference } from '../functions/dateTime.jsx';
import { delay } from '../functions/generic.jsx';

// modals
import { CenteredSubmitWithProgressModal } from '../Modal/CenteredSubmitWithProgressModal.jsx';

// get the subtype for the current unit

// when we load, update the status of this item to being inspected by the current user
// get the inspection points needed for this inspection
// start a timer to kick the incomplete inspection back to the queue if the user loses focus for n mount of time
// load up the table inside a form for the inspection points
// submit data to database

export const UnitInspectionForm = (props) => {
  // console.warn('UnitInspection props: ', props)
  const startTime = new Date();
  const drawerOnClose = props?.onClose
  // const inspectionId = props?.inspectionParams?.id
  const unitId = props?.inspectionParams?.unitId
  const unitCode = props?.inspectionParams?.code
  const unitTypeSubTypeName = props?.inspectionParams?.unit?.type?.name+'/'+props?.inspectionParams?.unit?.subType.name||null
  const createdById = props?.inspectionParams?.createdById
  // const updateInspectionQueue = props?.updateInspectionQueue

  // grab global context values
  const { store } = useContext(AppContext);
  // set the users current division id
  // const currentDivisionId = store?.userData?.divisionId
  const currentUserId = store?.userData?.id
  // const inspectionStatuses = store?.inspectionStatuses
  // console.warn('inspectionStatuses: ', inspectionStatuses)
  const unitDocumentTypes = store?.unitDocumentTypes
  const currentDivisionId = store?.userData?.divisionId||null

  const { isOpen: isSubmitModalOpen , onOpen: onSubmitModalOpen, onClose: onSubmitModalClose } = useDisclosure()
  const [ submitModalContent, setSubmitModalContent ] = useState(null)
  let submitModalCloseDisabled = true
  const [ submissionProgress, setSubmissionProgress ] = useState(0)
  function handleSubmitModalClosed() {
    // onClose()
    onSubmitModalClose()
  }

  const [ mediumImageTypeId, setMediumImageTypeId ] = useState(null)
  const [ unitImage, setUnitImage ] = useState(ImagePending)
  const [ unitDocument, setUnitDocument ] = useState(null)

  // console.log('inspection points: ', props.inspectionParams.inspectionPoints)
  const [ unitInspectionPoints, setUnitInspectionPoints ] = useState(null)
  useEffect(() => {
    (unitInspectionPoints===null) && setUnitInspectionPoints(props.inspectionParams.inspectionPoints)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[unitInspectionPoints])

  // const [ inspectionQueuedStatusId, setInspectionQueuedStatusId ] = useState(null)
  // const [ inspectedInServiceStatusId, setInspectedInServiceStatusId ] = useState(null)
  // useEffect(() => {
  //   // we have what we need.
  //   // if (inspectionStatuses) {
  //     // we have statuses, update the inspection status to claimed and being inspected
      
  //     const inspectionQueuedId = inspectionStatuses?.filter(status => status?.statusQuery==='inspection_queued')[0]?.id
  //     setInspectionQueuedStatusId(inspectionQueuedId)

  //     const inspectedInServiceId = inspectionStatuses?.filter(status => status?.statusQuery==='inspected_in_service')[0]?.id
  //     setInspectedInServiceStatusId(inspectedInServiceId)

  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  // medium image type id
  useEffect(() => {
    if (unitDocumentTypes) {
      const medImageTypeId = unitDocumentTypes?.filter(type => type?.name==='unit-photo-medium')[0]?.id
      // console.info('setMediumImageTypeId: ', medImageTypeId)
      setMediumImageTypeId(medImageTypeId)
    }
  },[unitDocumentTypes])

  // unit document
  useEffect(() => {
    if (unitId && mediumImageTypeId) {
      const unitDocumentParams = `${unitId}#${mediumImageTypeId}`
      const fetchDocument = async (params) => {
        const document = await fetchUnitDocumentsByUnitIdDocumentTypeId(params)
        return document[0]
      }
      
      fetchDocument(unitDocumentParams).then((data) => {
        if (data) {
          const key = data?.uploadedDocument?.key
          const identityId = data?.uploadedDocument?.identityId
  
          setUnitDocument({
            key: key,
            identityId: identityId,
          })
        }
      })
      // console.info('unitId: ', unitId)
      // console.info('mediumImageTypeId: ', mediumImageTypeId)
    }
  },[unitId, mediumImageTypeId])

  // --- REACT-HOOK-FORM ---
  const { 
    control, 
    register, 
    handleSubmit, 
    setValue, 
    getValues, 
    formState: { 
      errors, 
    }} = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const onError = (errors, e) => console.log(errors, e);

  const formSubmit = async (values, event) => {
    // console.log(' >>> VALUES: ', values)
    setSubmitButtonDisabled(true)

    event.preventDefault();
    // console.info('about to open modal')
    onSubmitModalOpen();
    // console.info('modal opened')

    setSubmissionProgress(10)
    const endTime = new Date();
    // define the time it took to complete the inspection
    const timeDiff = timeDifference(startTime, endTime)

    // const now = new Date();
    setSubmissionProgress(20)

    // console.log(' >>> EVENT: ', event.target.name)
    // console.log(' >>> EVENT: ', event.target)
    // console.log(' >>> VALUES: ', values)

    setSubmitModalContent('processing...')
    setSubmissionProgress(30)

    const unitInspectionPointAndValue = [];
    let failureCnt = 0
    let tmpProgress = 0
    for (const [key, value] of Object.entries(values)) {
      
      tmpProgress = submissionProgress + 1
      setSubmissionProgress(tmpProgress)
      if (typeof key === 'string' && key.startsWith('pass_fail_buttons_group_')) {
        
        // Extract the number after the last underscore
        const match = key.match(/_(\d+)$/);
        if (match) {
          // create an object to insert into the database
          const newObj = {
            point_id: values[`point_id_${match[1]}`],
            point_value: value,
          }
          // if the point failed, also add the reason
          if (value==='Fail') {
            failureCnt++;
            newObj.failed_reason = values[`failedReason_${match[1]}`]
          }
          // push the obj into the array
          unitInspectionPointAndValue.push(newObj);
        }
      }
    }
    setSubmitModalContent('wrapping up...')

    // console.log('unitInspectionPointAndValue: ', unitInspectionPointAndValue)
    setSubmissionProgress(90)


    const inspectionParams = {
      divisionId: currentDivisionId,
      createdById: createdById,
      unitId: unitId,
      inspectedOn: todaysDateYMD(),
      inspectedAt: awsDateTimeNow(),
      inspectedById: currentUserId,
      statusId: '2c9d91af-9c77-4206-872f-9924b54ce790',
      timeToInspect: timeDiff,
      inspectionPoints: unitInspectionPointAndValue,
      failureCount: failureCnt,
    }
    // console.warn(' UPDATING INSPECTION STATUS inspectionParams: ', inspectionParams)
    const addInspectionData = await addPrimaryUnitInspection(inspectionParams)
    console.warn(' UPDATING INSPECTION STATUS addInspectionData: ', addInspectionData)


    updateUnitInspectionStatus(values.unit_code, '2c9d91af-9c77-4206-872f-9924b54ce790', currentDivisionId)
    // console.warn('UNIT INSPECTION STATUS SHOULD BE UPDATED BY NOW.')
    setSubmissionProgress(100)
    // setSubmitModalCloseDisabled(false)
    setSubmitModalContent('closing...')
    delay(3000)

  }

  // we might never get here if there is no unit document
  useEffect(() => {
    if (unitDocument) {
      const fetchImage = async (unitDocument) => {
        const signedUrl = await getSignedUrlForFile({
          bucketName: 'prod-spike-unit-images', 
          identityId: unitDocument?.identityId, 
          fileName: unitDocument?.key, 
          fileSecurity: 'protected'
        });
        setUnitImage(signedUrl)
      }
      fetchImage(unitDocument)
      // console.info('unitDocument: ', unitDocument)
    }
  },[unitDocument])

  // update this inspection status to show that is claimed by this user and is now status of being_inspected
  // make sure to doublecheck that the unit isn't sniped by someone else

  const updateUnitInspectionStatus = async () => {
    // console.log('unitCode: ', unitCode)
    // update this inspection status to show it is clear to be inspected now 'inspection_queued'
    // console.info('SHOULD BE UPDATING INSPECTION STATUS TO COMPLETED', statusId)
    //lastInspectedAt: awsDateTimeNow(),
    // const updatedUnitStatus = await updatePrimaryUnitInspectionStatus({ 
    //   code: unitCode, 
    //   statusId: statusId, 
    //   divisionId: divisionId,
    //   lastInspectedAt: awsDateTimeNow() })
    // console.warn(' !!!!! - DID WE UPDATE THE UNIT STATUS TO COMPLETED?: ', updatedUnitStatus)
    
    // if (updateInspectionQueue) {
    //   // only update the queue if this was a queued inspection
    //   const updatedQueuedInspeciton = await updateQueuedPrimaryUnitInspection({ id: inspectionId, claimedOn: awsDateTimeNow(), claimedById: '' })
    //   console.log('NOW WE HAVE UPDATED QUEUED PRIMARY UNIT INSPECTION: ', updatedQueuedInspeciton)
    // }
    drawerOnClose()
  }

  let lastTopic = ''
  let showTopicHeader = false
  const TopicHeader = (props) => {
    const {
      topic,
      index,
    } = props
    return (
      <Box key={`topic_category_${index}`} w={'100%'}>
        <Text key={`topic_category_text_${index}`} as="span" color={'var(--dark-text-grey-1)'} textStyle='heading-2'>{topic}</Text>
      </Box>
    )
  }

  TopicHeader.propTypes = {
    topic: PropTypes.string,
    index: PropTypes.number,
  }

  const handleChange = (value) => {
    console.log('onchange value: ', value)
  }

  const { getRadioProps } = useRadioGroup({
    onChange: handleChange,
  })

  const [ submitButtonDisabled, setSubmitButtonDisabled ] = useState(false)

  return (<>
    <CenteredSubmitWithProgressModal
      isModalOpen={isSubmitModalOpen}
      onModalClose={onSubmitModalClose}
      content={submitModalContent}
      closeButtonDisabled={submitModalCloseDisabled}
      handleSubmitModalClosed={handleSubmitModalClosed}
      progress={submissionProgress}
      successMessage={'Your inpsection has been submitted.'}
      closeButtonVisible={false}
    />

    <Container as="form" mb={'50px'} pb='25px' onSubmit={handleSubmit(formSubmit, onError)}>

      <Center>
      <Box pl='6px'>
        <Box pb='25px'>

          <Flex alignItems="flex-end"> {/* Aligns both text and button to the bottom */}
            <Box>
              <Text as="span" mt={'6px'} mb={'3px'} color={'var(--dark-text-grey-1)'} textStyle='heading-4' textAlign="left" display="block">
                Unit {unitCode}
              </Text>
              <Text as="span" color={'var(--dark-text-grey-1)'} textStyle='text-2' textAlign="left" display="block">
                {unitTypeSubTypeName}
              </Text>
            </Box>
            <Spacer />
            <Box>
              <ButtonSenaryWithIconOnly
                name='close'
                icon='close'
                iconsize='22px'
                onClick={() => {
                  updateUnitInspectionStatus()
                }}
              />
            </Box>
          </Flex>
        </Box>

        <Box mb={'25px'} w={'100%'}>
          <Center>
            <Image 
              src={unitImage} 
              borderRadius='12px'
              alt='Unit Image'
              w={'100%'}
            />
          </Center>
        </Box>

        <TextInput
          register={register}
          errors={errors}
          fieldname={'unit_code'}
          fieldvalue={`${unitCode}`}
          fieldtype={'hidden'}
        /> 

        <Box w={'100%'}>
          <VStack>
            {unitInspectionPoints?.map((point, index) => {

              let buttonType = (point?.topic==='Pressure Washer') ? 'passFailNA' : 'passFail'

              // display the topic when needed.
              showTopicHeader = (lastTopic === '' || lastTopic !== point?.topic) ? true : false
              lastTopic = point?.topic
              // const showFailedReason = (getValues(`failedReason_${index}`)) ? true : false

              // https://chakra-ui.com/docs/hooks/use-radio-group
              
              return (
              <Fragment key={`fragment_${index}`}>
                {(showTopicHeader) && 
                <TopicHeader key={`topic_header_${index}`} topic={point?.topic} index={index} />}
                <Box 
                  py={'12px'} 
                  px={'12px'} 
                  mb={'20px'} 
                  w={'100%'} 
                  borderRadius={'12px'} 
                  bgColor={'var(--dark-module-bg)'} 
                  key={`point_wrapper_box_${index}`}>
                  <Text 
                    as="span" 
                    color={'var(--dark-text-grey-1)'} 
                    textStyle='heading-3' 
                    key={`point_point__${index}`}>{point?.point}</Text>
                  <Box 
                    key={`point_radio_group_wrapper_box_${index}`} 
                    pt='12px' 
                    w={'100%'}>
                    {(buttonType==='passFail') ? <><PassFailRadioGroup
                      register={register}
                      fieldname={`pass_fail_buttons_group_${index}`}
                      prettyname={'Inspection Point'}
                      isRequired={true}
                      control={control}
                      errors={errors}
                      point={point} 
                      index={index}
                      getRadioProps={getRadioProps}
                      onChange={handleChange}
                      getValues={getValues}
                      setValue={setValue}
                      {...getRadioProps({ value: point.point })}
                    />
                    <TextInput
                      register={register}
                      errors={errors}
                      fieldname={`point_id_${index}`}
                      fieldvalue={`${point?.id}`}
                      fieldtype={'hidden'}
                    /></> : <><PassFailNARadioGroup
                    register={register}
                    fieldname={`pass_fail_buttons_group_${index}`}
                    prettyname={'Inspection Point'}
                    isRequired={true}
                    control={control}
                    errors={errors}
                    point={point} 
                    index={index}
                    getRadioProps={getRadioProps}
                    onChange={handleChange}
                    getValues={getValues}
                    setValue={setValue}
                    {...getRadioProps({ value: point.point })}
                  />
                  <TextInput
                    register={register}
                    errors={errors}
                    fieldname={`point_id_${index}`}
                    fieldvalue={`${point?.id}`}
                    fieldtype={'hidden'}
                  /></>}
                  </Box>
                </Box>
              </Fragment>
              )
            })}
          </VStack>
        </Box>

        <Box py={'12px'} px={'12px'} mb={'20px'} w={'100%'} borderRadius={'12px'} 
          bgColor={'var(--dark-module-bg)'} 
          key={'submit_wrapper'}>

          <Box pb={'25px'} pt={'25px'} w={'100%'}>
            <Center>
              <ButtonPrimaryPlain 
                w={'90%'}
                name='submit'
                value='Submit Inspection'
                isDisabled={submitButtonDisabled}
                onClick={handleSubmit(formSubmit, onError)}
            />
            </Center>
          </Box>
          <Box pb={'25px'} pt={'25px'} w={'100%'}>
            <Center>
              <ButtonOctonaryRed 
                width='90%'
                name='closeDrawer'
                value='Discard Inspection'
                onClick={() => {
                  updateUnitInspectionStatus()
                }}
                mr={'40px'}
                isDisabled={submitButtonDisabled}
              />
            </Center>
          </Box>

        </Box>

      </Box>
      </Center>
      

    </Container>
    </>)

}

UnitInspectionForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  updateInspectionQueue: PropTypes.bool,
  inspectionParams: PropTypes.shape({
    // id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    unitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    code: PropTypes.string.isRequired,
    unit: PropTypes.shape({
      type: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      subType: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    createdById: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    inspectionPoints: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      point: PropTypes.string.isRequired,
      topic: PropTypes.string.isRequired,
    })).isRequired,
    
  }),
};


