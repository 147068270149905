import { updatePrimaryUnitStatus } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const updatePrimaryUnitInspectionStatus = async (props) => {
  if (!props) return;

  try {
    // console.log(' TRACK - !!!!! PROPS: ', props);

    const gqlParams = { input: props };

    const response = await client.graphql({
      query: updatePrimaryUnitStatus,
      variables: gqlParams,
    });

    // console.log(' TRACK - !!!!! RESPONSE: ', response);

    const results = response.data.updateSpkPrimaryUnitStatusTbl;
    // console.log(' TRACK - !!!!! RESULTS: ', results);

    return results;
  } catch (err) {
    return 'error updatePrimaryUnitInspectionStatus: ' + err;
  }
};

updatePrimaryUnitInspectionStatus.propTypes = {
  props: PropTypes.shape({
    code: PropTypes.string.isRequired,
    statusId: PropTypes.string.isRequired,
  }).isRequired,
};
