import { createDailyJobTicketJournal } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const addDJTJournal = async (props) => {
  if (!props) return;

  try {
    const response = await client.graphql({
      query: createDailyJobTicketJournal,
      variables: { input: props },
    });

    const results = response.data.createSpkDJTCurrentStatusJournal.id;

    return results;
  } catch (err) {
    return ('error addDJTJournal: ', [err]);
  }
};

addDJTJournal.propTypes = {
  props: PropTypes.object.isRequired,
};
