import { 
  SimpleGrid, 
  VStack } from "@chakra-ui/react";
import { ReceiptsModule } from "./Modules/ReceiptsModule.jsx";
import { InspectionsModule } from "./Modules/InspectionsModule.jsx";
import { TimeCardModule } from "./Modules/TimeCardModule.jsx";
// import { PunchDetails } from "./Modules/PunchDetails.jsx";
import PropTypes from 'prop-types';

export const DashboardLayout = ({ 
  modules, 
  totalHoursWorked, 
  currentWeekHours, 
  mostRecentPunchIn, 
  unsubmittedReceiptCount, 
  punchEntries 
}) => {
  return (
    <VStack spacing={4} 
      width="100%"
      maxWidth={["100%", null, "400px"]}
      paddingLeft={'25px'}
      paddingRight={'25px'}
      >
      {/* Render the TimeCardModule in its own row */}
      {modules.includes("TeamMemberModule") && 
        <>
          <TimeCardModule 
            totalHoursWorked={totalHoursWorked} 
            currentWeekHours={currentWeekHours} 
            mostRecentPunchIn={mostRecentPunchIn}
            punchEntries={punchEntries}
          />
        </>
        }

      {/* Render Receipts and Inspections side by side */}
      <SimpleGrid columns={[2, null, 2]} spacing="25px" width="100%">
        {modules.includes("TeamMemberModule") && <InspectionsModule />}
        {modules.includes("IssuedCreditCardModule") && <ReceiptsModule unsubmittedReceiptCount={unsubmittedReceiptCount}/>}
      </SimpleGrid>
    </VStack>
  );
};

DashboardLayout.propTypes = {
  modules: PropTypes.array.isRequired, // Adjust the type as needed
  totalHoursWorked: PropTypes.string,
  currentWeekHours: PropTypes.string,
  mostRecentPunchIn: PropTypes.object,
  unsubmittedReceiptCount: PropTypes.string,
  punchEntries: PropTypes.array,
};

// credit_card_manager: 'CreditCardManagerModule',
//   credit_card_receipt_manager: 'CreditCardReceiptManagerModule',
//   exec_admin: 'ExecAdminModule',
//   issued_credit_card: 'CreditCardModule',
//   maintenance: 'MaintenanceModule',
//   supervisor: 'SupervisorModule',
//   team_member: 'TeamMemberModule',