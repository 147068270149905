import { allStatementVendorsByQBVendorNameSortable } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const searchQBVendorsByVendorNameLower = async (props) => {
  try {
    const { startsWith = '', limit = 100, nextToken = '' } = props;
    console.log('props: ', props);

    const searchTermLen = startsWith?.length;

    if (searchTermLen === 0 || searchTermLen >= 3) {
      let gqlParams = {
        allSort: 'all',
        limit: limit,
        sortDirection: 'ASC',
      };

      if (searchTermLen >= 3) {
        gqlParams.qbDisplayNameLower = {
          beginsWith: startsWith.toLowerCase(),
        };
      }

      if (nextToken) {
        gqlParams.nextToken = nextToken;
      }

      console.log('gqlParams: ', gqlParams);

      const response = await client.graphql({
        query: allStatementVendorsByQBVendorNameSortable,
        variables: gqlParams,
      });

      console.log('response: ', response);

      const results = response.data.allStatementQuickBooksVendorSortableByQBVendorName;
      console.log('results: ', results);

      return results;
    } else {
      return { message: 'Search term must be at least 3 characters long.' };
    }
  } catch (err) {
    console.error('Error in searchQBVendorsByVendorNameLower: ', err);
    return { error: 'An error occurred while searching for vendors.', details: err };
  }
};

searchQBVendorsByVendorNameLower.propTypes = {
  startsWith: PropTypes.string,  // Optional string for search term
  limit: PropTypes.number,       // Optional number for result limit
  nextToken: PropTypes.string,   // Optional string for pagination token
};