import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

export const DateInput = (props) => {
  const {
    name,
    dateFormat,
    selected,
    onChange,
    customInput,
    isDisabled,
  } = props;
  
  return (
    <DatePicker
      name={name}
      dateFormat={dateFormat}
      selected={selected}
      onChange={onChange}
      customInput={customInput}
      disabled={isDisabled} // Note: `isDisabled` prop is actually `disabled` in DatePicker
    />
  );
};

DateInput.propTypes = {
  name: PropTypes.string,
  dateFormat: PropTypes.string,
  selected: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  customInput: PropTypes.element,
  isDisabled: PropTypes.bool
};