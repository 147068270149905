import { tabsAnatomy } from '@chakra-ui/anatomy'
import { mode } from '@chakra-ui/theme-tools'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

// define the base component styles
// https://chakra-ui.com/docs/components/tabs/theming
const baseStyle = definePartsStyle({
  // define the part you're going to style
  root: {
    // color: mode('spikedark.500', 'spikedark.50'),
  },

  tab: {
    fontFamily: 'var(--font-family-sfpro-medium)',
    fontSize: 'var(--font-size-m)',
    fontWeight: '500',
    letterSpacing: '0',
    color: mode('var(--light-text-grey-1)', 'var(--black-primary)'),
    // borderColor: 'transparent',
    // use colorScheme to change background color with dark and light mode options
    // bg: mode('spikedarktabs.300', 'spikedarktabs.600'),
    // borderTopRadius: 'lg',
    // borderBottom: 'none',
    _hover: {
      color: mode('var(--dark-gold-primary)', 'var(--dark-gold-primary)'),
    },
    // _active: {
    //   color: mode('#00ff00', '#ff0000'),
    // },
    _selected: {
      // bg: mode('#fff', 'gray.800'),
      color: mode('var(--dark-gold-primary)', 'var(--dark-gold-primary)'),
      // color: 'spikedarktabs.500'
      // borderColor: 'inherit',
      // borderBottom: 'none',
      // mb: '-2px',
    },
  },
  tablist: {
    color: mode('var(--light-text-grey-1)', 'var(--black-primary)'),
    // borderBottom: '2x solid',
    // borderColor: 'inherit',
  },
  tabpanel: {
    // border: '2px solid',
    // borderColor: 'inherit',
    // borderBottomRadius: 'lg',
    // borderTopRightRadius: 'lg',
  },

  // tab: {
  //   // fontWeight: 'semibold', // change the font weight
  //   // color: var(--dark-text-grey-1);
  //   _dark: {
  //     // border: '2px solid',
  //     // borderColor: 'transparent',
  //     // use colorScheme to change background color with dark and light mode options
  //     // bg: mode(`${c}.300`, `${c}.600`)(props),
  //     // borderTopRadius: 'lg',
  //     // borderBottom: 'none',
  //     // color: mode('spikedark.500', 'spikedark.50'),
  //     // color: mode('spikedarktabs.800', 'spikedarktabs.50'),
  //     color: 'red',
  //     _selected: {
  //       // bg: mode('#fff', 'gray.800')(props),
  //       // color: mode('spikedark.100', 'spikedark.50'),
  //       // color: mode('spikedarktabs.300', 'spikedarktabs.50'),
  //       color: 'green',
  //       // color: mode('green', 'red'),
  //       // bg: mode('green', 'red'),
  //       // borderColor: 'inherit',
  //       // borderBottom: '1px solid green',
  //       // mb: '-2px',    // changes the visibility of the borfer under the selected tab
  //     },

  //     fontFamily: "SFPro Text-Semibold",
  //     fontSize: "17px",
  //     fontWeight: 400,
  //     letterSpacing: 0,
  //   }
    
  // },
  // tabpanel: {
  //   // fontFamily: 'mono', // change the font family
  // },
})

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ baseStyle })