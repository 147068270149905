import { unitOperatorsByDjtId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchUnitOperatorsByDjtId = async (djtId) => {
  try {
    const response = await client.graphql({
      query: unitOperatorsByDjtId,
      variables: { djtId },
    });

    const results = response.data.operatorByDjtId.items;
    return results;
    
  } catch (err) {
    return ('error fetchUnitOperatorsByDjtId: ', [err]);
  }
}

fetchUnitOperatorsByDjtId.propTypes = {
  djtId: PropTypes.string.isRequired,
};
