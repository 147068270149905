import { getISolvedEmployeeByTeamMemberId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';

import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const fetchiSolvedEmployeeByTeamMemberId = async (teamMemberId) => {
  if (teamMemberId) {
    try {
      const gqlQuery = {
        teamMemberId: teamMemberId
      };
      const response = await client
      .graphql({
        query: getISolvedEmployeeByTeamMemberId,
        variables: gqlQuery
      })
      console.log('response: ', response)
      const results = response.data.iSolvedEmployeeByTeamMemberId.items[0];
      return results;
    } catch (err) {
      return('fetchiSolvedEmployeeByTeamMemberId error: ', [err]);
    }
  } else {
    return('Missing teamMemberId')
  }
}

fetchiSolvedEmployeeByTeamMemberId.propTypes = {
  teamMemberId: PropTypes.string.isRequired,
};