export const stateOptions = [
  { label: "Oklahoma", value: "ok" },
  { label: "Missouri", value: "mo" },
  { label: "Illinois", value: "il" },
  { label: "Indiana", value: "in" },
  { label: "Tennessee", value: "tn" },
  { label: " ", value: "" },
  { label: "Alabama", value: "al" },
  { label: "Alaska", value: "ak" },
  { label: "Arizona", value: "az" },
  { label: "Arkansas", value: "ar" },
  { label: "California", value: "ca" },
  { label: "Colorado", value: "co" },
  { label: "Connecticut", value: "ct" },
  { label: "Delaware", value: "de" },
  { label: "Florida", value: "fl" },
  { label: "Georgia", value: "ga" },
  { label: "Hawaii", value: "hi" },
  { label: "Idaho", value: "id" },
  { label: "Iowa", value: "ia" },
  { label: "Kansas", value: "ks" },
  { label: "Kentucky", value: "ky" },
  { label: "Louisiana", value: "la" },
  { label: "Maine", value: "me" },
  { label: "Maryland", value: "md" },
  { label: "Massachusetts", value: "ma" },
  { label: "Michigan", value: "mi" },
  { label: "Minnesota", value: "mn" },
  { label: "Mississippi", value: "ms" },
  { label: "Montana", value: "mt" },
  { label: "Nebraska", value: "ne" },
  { label: "Nevada", value: "nv" },
  { label: "New Hampshire", value: "nh" },
  { label: "New Jersey", value: "nj" },
  { label: "New Mexico", value: "nm" },
  { label: "New York", value: "ny" },
  { label: "North Carolina", value: "nc" },
  { label: "North Dakota", value: "nd" },
  { label: "Ohio", value: "oh" },
  { label: "Oregon", value: "or" },
  { label: "Pennsylvania", value: "pa" },
  { label: "Rhode Island", value: "ri" },
  { label: "South Carolina", value: "sc" },
  { label: "South Dakota", value: "sd" },
  { label: "Texas", value: "tx" },
  { label: "Utah", value: "ut" },
  { label: "Vermont", value: "vt" },
  { label: "Virginia", value: "va" },
  { label: "Washington", value: "wa" },
  { label: "West Virginia", value: "wv" },
  { label: "Wisconsin", value: "wi" },
  { label: "Wyoming", value: "wy" }
];