import { fetchAuthSession } from 'aws-amplify/auth';

// // Configure the URL for the api query.
const endpointUrl = 'https://ugczaq3r89.execute-api.us-east-1.amazonaws.com/dev/adminaddusertogroup';

export const addCognitoUserToGroup = async (props) => {

    const {
        userName,
        groupName,
        groupAction="add",
    } = props
  
    const params = {
        "username": userName,
        "groupname": groupName,
        "groupaction": groupAction,
    };

    // Refresh the session to ensure it's current and to get the latest authentication tokens
    const session = await fetchAuthSession();
    const authToken = `Bearer ${session.tokens.idToken}`;
    
    // Prepare the request payload
    let inputJSON = JSON.stringify(params);  

    try {
        const response = await fetch(endpointUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authToken,
        },
        body: inputJSON,
        })
        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }
        const data = await response.json();  // Parses the JSON body and waits for it
        return data;  // Returns the data to the caller
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;  // Re-throw the error if you want to handle it further up the call stack
    }

};
