import React, { useContext } from 'react';
import { FormContext } from '../Form';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";

export const TextInputWithRef = React.forwardRef((props, ref) => {
  const { 
    fieldname='', 
    fieldlabel, 
    prettyname, 
    fieldtype='text', 
    fieldvalue='',
    variant='defaultinput',
    defaultValue,
    register, 
    errors, 
    placeholder='Placeholder', 
    isReadOnly=false, 
    isRequired=false, 
    isDisabled=false, 
    maximLength=100, 
    minimLength=0, 
    width,
    onChange,
    onBlur,
    onClick,
    onFocus,
    onKeyPress,
    className,
    color,
    textAlign,
    isInvalid,
  } = props;

  // (props.fieldname==="contactPhoneChanged") && console.log('TextInput props: ', props)
  // if (fieldname==='labor[0].TeamMemberName') {
  //   console.log('TextInput props: ', props)
  // }
  const formContext = useContext(FormContext);
  const { form, handleFormChange } = formContext;

  const handleChange = (onChange) ? onChange : (event) => {}
  const handleBlur = (onBlur) ? onBlur : (event) => {}
  const handleClick = (onClick) ? onClick : (event) => {}
  const handleFocus = (onFocus) ? onFocus : (event) => {}
  const handleKeyPress = (onKeyPress) ? onKeyPress : (event) => {}

  const defaultFieldValue = (fieldvalue) ? fieldvalue : form[fieldname];
  const defaultFieldType = (fieldtype===undefined) ? 'text' : fieldtype

  // console.warn(' CHECK defaultFieldValue: '+fieldname+' '+defaultFieldValue)
  let regexPattern = null
  if (fieldtype==="time") {
    regexPattern = {
      value: /^\d*$/,
      message: "Invalid time format"
    }
  }
  if (fieldtype==="tel") {
    regexPattern = {
      value: /^\d*$/,
      message: "Only numbers allowed"
    }
  }
  return (
    <FormControl isInvalid={errors[fieldname]}>
      <FormLabel htmlFor={fieldname}>{fieldlabel}</FormLabel>
      <Input
        ref={ref} 
        width={width}
        length={maximLength}
        textAlign={textAlign}
        className={className}
        color={color}
        defaultValue={defaultValue}
        id={fieldname}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        type={defaultFieldType}
        variant={variant}
        name={fieldname}
        placeholder={placeholder}
        value={defaultFieldValue}
        onFocus={(e) => e.target.select()}
        onMouseUp={(e) => e.preventDefault()}
        onKeyPress={(e) => handleKeyPress(e)}
        {...register(fieldname, { 
            onChange: (e) => handleChange(e),
            onBlur: (e) => handleBlur(e),
            onClick: (e) => handleClick(e),
            required: { 
              value: isRequired, 
              // message: rules?.required || `${prettyname||fieldname} is required.`, 
              message: `${prettyname||fieldname} required.`, 
            }, 
            maxLength: { 
              value: maximLength, 
              message: `${prettyname} must be at most ${maximLength} characters`, 
            }, 
            minLength: { 
              value: minimLength, 
              message: `${prettyname} must be at least ${minimLength} characters`, 
            },
            pattern: {regexPattern},
        })} 
      />
      <FormErrorMessage>
        {errors[fieldname] && errors[fieldname].message}
      </FormErrorMessage>
    </FormControl>

  )
})