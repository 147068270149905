import { updateDailyJobTicketMaterial } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const updateDailyJobTicketAttachedMaterial = async (params) => {
  if (!params) return;

  try {
    const response = await client.graphql({
      query: updateDailyJobTicketMaterial,
      variables: { input: params },
    });

    const results = response.data.updateSpkDJTMaterialTbl.id;
    return results;
  } catch (err) {
    return ('error updateDailyJobTicketAttachedMaterial: ', [err]);
  }
};

updateDailyJobTicketAttachedMaterial.propTypes = {
  params: PropTypes.object.isRequired,
};
