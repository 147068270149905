import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';

import PropTypes from 'prop-types';

// decorative components
import { Divider } from '../../Divider';

import { truncateString } from '../../functions/truncateString';
import { awsDateTimeToTime } from '../../functions/dateTime';

export const InspectionCompletedTable = (props) => {
  const {
    completedInspections,
  } = props;

  console.info(' -----> completedInspections: ', completedInspections)

  return (
    <TableContainer>
      <Table 
        variant={'compact'}
        size={'compact'}
        >
        <Thead>
          <Tr>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>UNIT</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>CREATED BY</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>INSPECTED BY</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>INSPECTED AT</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>STATUS</Text></Th>
          </Tr>
        </Thead>
        <Tbody>
          {completedInspections?.map((data, inspectionIndex) => {
            const claimedByFirstName = (data?.claimedBy?.goesBy && data?.claimedBy?.goesBy!=="") ? data?.claimedBy?.goesBy : data?.claimedBy?.firstName
            const claimedByName = (data?.claimedBy) ? claimedByFirstName + ' ' + data?.claimedBy?.lastName : 'Unclaimed  '
            const creatdByFirstName = (data?.createdBy?.goesBy && data?.createdBy?.goesBy!=="") ? data?.createdBy?.goesBy : data?.createdBy?.firstName
            const createdBy = (data?.createdBy) ? creatdByFirstName + ' ' + data?.createdBy?.lastName : ''
            const inspectedByFirstName = (data?.inspectedBy?.goesBy && data?.inspectedBy?.goesBy!=="") ? data?.inspectedBy?.goesBy : data?.inspectedBy?.firstName
            const inspectedByName = (data?.inspectedBy) ? `${inspectedByFirstName} ${data?.inspectedBy?.lastName}` : ''

            // const claimedByName = (data?.claimedBy) ? data?.claimedBy?.firstName + ' ' + data?.claimedBy?.lastName : 'Unclaimed  '
            // const inspectedByName = (data?.inspectedBy) ? `${data?.inspectedBy?.firstName} ${data?.inspectedBy?.lastName}` : ''
            // const createdBy = (data?.createdBy) ? data?.createdBy?.firstName + ' ' + data?.createdBy?.lastName : ''
            const status = data?.status?.status

            const teamMemberInspecting = (inspectedByName!=='') ? inspectedByName : claimedByName

            const unitDescription = `${data?.unit?.code} - ${data?.unit?.type?.name}/${data?.unit?.subType?.name}`
            const unitDescriptionTruncated = truncateString(unitDescription, 25)

            const inspectedTime = awsDateTimeToTime(data?.createdAt)

            return(
              <Tr key={'tr_inspections_'+inspectionIndex}>
                <Td key={'td_inspections_1_'+inspectionIndex}>
                  <Text as="span" className='dark-sfpro-text-1'>{unitDescriptionTruncated}</Text>
                </Td>
                <Td>
                  <Text as="span" className='dark-sfpro-text-1'>{createdBy}</Text>
                </Td>
                <Td>
                  <Text as="span" className='dark-sfpro-text-1'>{teamMemberInspecting}</Text>
                </Td>
                <Td>
                  <Text as="span" className='dark-sfpro-text-1'>{inspectedTime}</Text>
                </Td>
                <Td>
                  <Text as="span" className='dark-sfpro-text-1'>{status}</Text>
                </Td>
              </Tr>
            )
          })}
          {(completedInspections?.length>0) && <Tr key={'tr_inspections_divider_end'}><Td colSpan='7' h='15px'><Divider h={'2px'} mt='5px' mb='5px'/></Td></Tr>}
          <Tr><Td colSpan='7' h='15px'></Td></Tr>
        </Tbody>
        {/* <Tfoot>
          <Tr>
            <Th colSpan={8} textAlign={'right'}>
            <Box><Text as="span" textStyle='text-7' pr='10px'>Material Total:&nbsp;&nbsp;{USDollar.format(materialTotalAmount)}</Text></Box>
            <Box mt='5px'><Text as="span" textStyle='text-8' pr='10px'>DJT Total:&nbsp;&nbsp;{USDollar.format(djtTotalAmount)}</Text></Box>
            </Th>
          </Tr>
        </Tfoot> */}
      </Table>
    </TableContainer>
    
  )
}

InspectionCompletedTable.propTypes = {
  completedInspections: PropTypes.arrayOf(PropTypes.object).isRequired,
}