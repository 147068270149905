import { defineStyleConfig } from '@chakra-ui/react'

const Textarea = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    // border: '1px solid red',
    borderWidth: '1px',
    borderColor: 'var(--dark-component-border)',
    borderRadius: '6px',
    fontFamily: 'SFPro Text-Regular, Helvetica',
    fontWeight: '400',
    fontSize: '20px',
    // lineHeight: '20px',
    // lineHeight: '1.5',
    letterSpacing: '0',
    textDecoration: 'none',
    _placeholder: {
      color: '#ffffff',
      lineHeight: '1.5',
    },
    // chakra css for button is display: inline-flex
    // justifyContent: 'flex-start'
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4, // <-- px is short for paddingLeft and paddingRight
      py: 3, // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: 'md',
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
    df: {
      fontSize: '15px',
      px: '20px', // <-- these values are tokens from the design system
      py: '10px', // <-- these values are tokens from the design system
    },
  },
  // Two variants: outline and solid
  variants: {
    primary: {
      // height: '44px',
      // mx: '5px',
      // border: '1px solid green',
      // borderColor: 'var(--dark-module-divider)',
      fontFamily: 'SFPro Text-Regular',
      fontSize: '16px',
      fontWeight: '400',
      // lineHeight: '15px',
      lineHeight: '1.5',
      bg: 'var(--dark-module-bg)',
      color: 'var(--dark-text-grey-1)',
      transition: 'all 0.3s ease',
      _placeholder: {
        color: '#ffffff',
      },
      // _hover: { bg: 'var(--dark-gold-action-hover)', textDecoration: 'none' },
      // _active: { background: 'var(--dark-gold-tertiary)', textDecoration: 'none' }
    },
    
    
  },
  // The default size and variant values
  defaultProps: {
    variant: 'primary',
  },
})

export default Textarea

