import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { getStatusCodeById } from '../../../graphql/custom_queries';

const client = generateClient();

export const getStatusCodeByStatusId = async (statusId) => {
  if (!statusId) return;

  try {
    // console.log('---statusCode: ', statusId);

    const response = await client.graphql({
      query: getStatusCodeById,
      variables: { id: statusId },
    });

    // console.log('---RESPONSE: ', response);
    
    const results = response.data.getSpkDJTCurrentStatus.statusCode;

    // console.log('status id x: ', results);
    
    return results;
  } catch (err) {
    return 'error getStatusCodeByStatusId: ' + err;
  }
};

getStatusCodeByStatusId.propTypes = {
  statusId: PropTypes.string.isRequired,
};
