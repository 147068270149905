import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { getAuxillaryUnitByUnitId } from '../../../graphql/custom_queries';

const client = generateClient();

export const fetchAuxillaryUnitByUnitId = async (params) => {
  if (params && params.unitId) {
    try {
      // console.log('about to query with params: ', params)
      const { unitId } = params;
      
      const response = await client.graphql({
        query: getAuxillaryUnitByUnitId,
        variables: { id: unitId }
      });

      // console.log('response: ', response)
      const results = response.data.getSpkAuxillaryUnitTbl;
      return results;
    } catch (err) {
      return 'error fetchAuxillaryUnitByUnitId: ' + err;
    }
  } else {
    return 'Missing params';
  }
};

fetchAuxillaryUnitByUnitId.propTypes = {
  params: PropTypes.shape({
    unitId: PropTypes.string.isRequired,
  }).isRequired,
};
