import { updateDJT } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const updateDJTAttachedPDF = async (djtId, id) => {
  if (djtId && id) {
    try {
      
      const params = {
        id: djtId,
        attachableObjId: id
      };

      const response = await client.graphql({
        query: updateDJT,
        variables: { input: params }
      });

      const results = response.data.updateSpkDailyJobTicketTbl.id;
      return results;
    } catch(err) {
      return ('error updateDJTAttachedPDF: ', [err]);
    }
  } else {
    return null;
  }
};

updateDJTAttachedPDF.propTypes = {
  djtId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
