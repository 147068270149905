export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function formatCurrency(value) {

  const stripped = stripToNumbersAndDot(value)
  // Ensure the input is a number by trying to convert it.
  const number = parseFloat(stripped);
  
  // Check if the conversion was successful, just to avoid printing money out of thin air.
  if (isNaN(number)) {
    return
  }
  return "$" + parseFloat(number).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
}

const stripToNumbersAndDot = (str) => {
  if (typeof str !== 'string') {
    return ''; // or any default value you'd like to return when input is not a string
  }
  return str.replace(/[^0-9.]/g, '');
};

export function convertCurrencyToDouble(currency){
  if (!currency) return 0
  // console.log('calculating currency: ', currency)
  if (currency) {
    try {
      let replaced = currency.toString().replace(/[^0-9.-]+/g,"");
      if (replaced) {
        return parseFloat(replaced)
      } else {
        return 0
      }
    } catch (err) {
      console.log(err)
      return currency
    } finally {
      // setIsLaborDataLoading(false)
    }
  }
  return 0;
}

// This function converts numerical rates to formatted currency strings
export const formatCurrencyNumeric = (amount) => {
  // If the amount is missing or falsy, return "N/A"
  if (!amount) return "N/A";

  // Remove commas from the amount, if any, and ensure it is a valid number
  const numericAmount = parseFloat(amount.toString().replace(/,/g, ''));
  
  // Check if the amount is a valid number after cleaning
  if (isNaN(numericAmount)) return "Invalid amount";

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(numericAmount);
};

export const convertCurrencyToFloat = (currencyString) => {
  // Remove any non-digit characters from the string
  const cleanString = currencyString.toString().replace(/[^0-9.-]+/g, '');

  // Parse the cleaned string as a float
  const floatValue = parseFloat(cleanString);

  return floatValue;
};