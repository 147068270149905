import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { listAllPrimaryUnits } from '../../../graphql/custom_queries';
// import { allPrimaryUnitsSortableByCode } from '../../../graphql/custom_queries';

const client = generateClient();

export const fetchAllUnits = async (nextoken, limit) => {
  try {
    const gqlParams = {
      allSort: "all",
      sortDirection: 'ASC',
      limit: limit,
      ...(nextoken && { nextToken: nextoken })
    };
    // console.info('fetchAllUnits gqlParams: ', gqlParams)
    const response = await client.graphql({
      query: listAllPrimaryUnits,
      variables: gqlParams
    });


    // console.info('fetchAllUnits RESPONSE: ', response)
    const results = response.data.allPrimaryUnits;
    // console.log('fetchAllUnits RESULTS: ', results)
    return results;

  } catch (err) {
    return 'error fetchAllUnits: ' + err;
  }
};

fetchAllUnits.propTypes = {
  nextoken: PropTypes.string,
  limit: PropTypes.number.isRequired,
};
