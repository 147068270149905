import { listEquipmentByDJTId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchEquipmentByDJTId = async (djtId) => {
  if (!djtId) return;

  try {
    const response = await client.graphql({
      query: listEquipmentByDJTId,
      variables: { djtId: djtId },
    });

    const results = response.data.equipmentByDJTId.items;
    
    return results;
  } catch (err) {
    return ('error fetchEquipmentByDJTId: ', [err]);
  }
};

fetchEquipmentByDJTId.propTypes = {
  djtId: PropTypes.string.isRequired,
};
