import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
} from '@chakra-ui/react';

// utility functions
import { USDollar, convertCurrencyToDouble, formatCurrency } from '../functions/currency.jsx';
import { converNumberToDouble } from '../functions/number.jsx';

export const LaborSummaryTableViewable = (props) => {
  const {
    tableData=[],
    laborTotalAmount = 0.00,
  } = props;

  return (
    <TableContainer>
      <Table variant={'compact'} size={'compact'}>
        <Thead>
          <Tr>
            <Th><Text as="span" className='dark-sfpro-heading-4'>NAME</Text></Th>
            <Th><Text as="span" className='dark-sfpro-heading-4'>JOB CLASS</Text></Th>
            <Th><Text as="span" className='dark-sfpro-heading-4'>START TIME</Text></Th>
            <Th><Text as="span" className='dark-sfpro-heading-4'>FINISH TIME</Text></Th>
            <Th><Text as="span" className='dark-sfpro-heading-4'>PER DIEM</Text></Th>
            <Th><Text as="span" className='dark-sfpro-heading-4'>LUNCH</Text></Th>
            <Th><Text as="span" className='dark-sfpro-heading-4'>TOTAL</Text></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr colSpan='7' h='10px'><Td></Td></Tr>
          {tableData?.map((data, index) => {
            const isTempLabor = data?.isTempLabor;

            let firstName = '';
            let goesBy = '';
            let lastName = '';
            let teamMemberName = '';

            if (data?.teamMember === null) {
              teamMemberName = 'Team Member';
              if (isTempLabor) {
                firstName = data?.tempEmployee?.firstName;
                goesBy = data?.tempEmployee?.goesBy;
                lastName = data?.tempEmployee?.lastName;
                teamMemberName = (goesBy) ? `${firstName} "${goesBy}" ${lastName}` : `${firstName} ${lastName}`;
              }
            } else {
              firstName = (!isTempLabor) ? data?.teamMember?.firstName : data?.tempEmployee?.firstName;
              goesBy = data?.teamMember?.goesBy;
              lastName = (!isTempLabor) ? data?.teamMember?.lastName : data?.tempEmployee?.lastName;
              teamMemberName = (goesBy) ? `${firstName} "${goesBy}" ${lastName}` : `${firstName} ${lastName}`;
            }

            const jobClass = data?.jobClass?.appDisplayName;
            const startTime = data?.startTime;
            const finishTime = data?.finishTime;
            const perDiemAmount = convertCurrencyToDouble(data?.perDiemAmount);
            const lunchDuration = (data?.lunchDuration === "00") ? "0" : data?.lunchDuration;
            const rowTotal = data?.totalPrice;

            return (
              <React.Fragment key={'fragment_' + index}>
                <Tr h='6px' key={'tr_labor_spacer_' + index}><Td key={'td_labor_spacer_' + index} colSpan='7'></Td></Tr>
                <Tr h='30px' key={'tr_labor_' + index} borderBottom='1px solid var(--dark-module-divider)'>
                  <Td key={'td_labor_1_' + index}>
                    {teamMemberName}
                  </Td>
                  <Td key={'td_labor_2_' + index}>
                    <Box key={'td_labor_bx_2_' + index} width='190px'>{jobClass}</Box>
                  </Td>
                  <Td key={'td_labor_3_' + index} textAlign={'right'} w={'89px'}>{startTime}</Td>
                  <Td key={'td_labor_4_' + index} textAlign={'right'} w={'89px'}>{finishTime}</Td>
                  <Td key={'td_labor_5_' + index} textAlign={'right'} w={'72px'}>{formatCurrency(perDiemAmount)}</Td>
                  <Td key={'td_labor_6_' + index} textAlign={'right'}>
                    <Box key={'td_labor_bx_6_' + index} width='45px'>{lunchDuration}</Box>
                  </Td>
                  <Td key={'td_labor_7_' + index} textAlign={'right'} w={'90px'}>{rowTotal}</Td>
                </Tr>
              </React.Fragment>
            );
          })}
          <Tr><Td colSpan='7' h='15px'></Td></Tr>
        </Tbody>
        <Tfoot>
          <Tr>
            <Th colSpan={7} textAlign={'right'}>
              <Text textStyle='heading-3'>Labor Total: {USDollar.format(converNumberToDouble(laborTotalAmount))}</Text>
            </Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

LaborSummaryTableViewable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({
    isTempLabor: PropTypes.bool,
    teamMember: PropTypes.shape({
      firstName: PropTypes.string,
      goesBy: PropTypes.string,
      lastName: PropTypes.string,
    }),
    tempEmployee: PropTypes.shape({
      firstName: PropTypes.string,
      goesBy: PropTypes.string,
      lastName: PropTypes.string,
    }),
    jobClass: PropTypes.shape({
      appDisplayName: PropTypes.string,
    }),
    startTime: PropTypes.string,
    finishTime: PropTypes.string,
    perDiemAmount: PropTypes.string,
    lunchDuration: PropTypes.string,
    totalPrice: PropTypes.string,
  })),
  laborTotalAmount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default LaborSummaryTableViewable;