import { Box, Text, VStack } from "@chakra-ui/react";
import { AvailableIcons } from "../../Icons/AvailableIcons";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const ReceiptsModule = (props) => (
  <Link to="/user-receipts">
    <Box
      borderRadius="12px"
      bg="var(--dark-module-bg)"
      width="100%"
      height="126px">
      <VStack align="start">
        <Box paddingTop="25px" paddingLeft="25px" paddingBottom="5px">
          {/* Gold box with icon and positioned relative to anchor the red circle */}
          <Box 
            position="relative" 
            borderRadius="6px" 
            width="50px" 
            height="50px" 
            bg="var(--icon-gold-bg)" 
            display="flex" 
            alignItems="center" 
            justifyContent="center"
          >
            <AvailableIcons 
              boxSize="28px" 
              color="var(--dark-gold-primary)" 
              bgcolor="var(--icon-gold-bg)" 
              iconRef="iconStatements" 
            />

            {/* Red circle, positioned to hover over the top-right corner */}
            <Box 
              as="span"
              color="white"
              position="absolute"
              top="-8px"
              right="-8px"
              fontSize="0.8rem"
              bg="red"
              borderRadius="50%" // Perfectly round
              height="25px"
              width="25px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text>{props.unsubmittedReceiptCount || '...'}</Text>
            </Box>
          </Box>
        </Box>

        <Text paddingLeft="25px" className="sfprotext-semi-bold-white-15px">Receipts</Text>
      </VStack>
    </Box>
  </Link>
);

ReceiptsModule.propTypes = {
  unsubmittedReceiptCount: PropTypes.string, // Expects a number for the receipt count, required
};

export { ReceiptsModule };