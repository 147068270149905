
import { IconButton, Tooltip } from '@chakra-ui/react';

// icons
import { AvailableIcons } from '../../Icons/AvailableIcons';

export const ShortcutsBadge = () => {
  return (
    <Tooltip 
      openDelay={1000} 
      closeDelay={100} 
      arrowSize={15} 
      placement='top' 
      // hasArrow 
      label='Add New'
      wrapper="span">
      <IconButton 
        variant={'iconbutton'}
        aria-label={'Shortcuts'}
        size={'lg'}
        icon={<><AvailableIcons boxSize={'28px'} iconRef={'tasks'} /></>}
    /></Tooltip>
  );
}