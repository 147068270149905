import {
  Box
} from "@chakra-ui/react";

export const  ModuleBoxDrawerAttention = (props) => {
  const {
    border,
    children,
  } = props;

  return (
    <Box 
      border={border} //used only for debugging placement
      // borderColor={{ 
      //   base: 'red', 
      //   sm: 'blue', 
      //   md: 'green',  // iPad 7th gen
      //   lg: 'purple', 
      //   xl: 'white', 
      //   '2xl': 'yellow'
      // }}
      w={{ 
        base: '100px', //< 400 screen width
        sm: '380px', 
        md: '1035px', // ipad 7th gen target - 810 when with enu nav
        lg: '1366px', // macbook air and ipad
        xl: '1505px',
        '2xl': '1625px'
      }}
      borderRadius='6px'
      p='25px'
      // mt='25px'
      // ml='25px'
      {...props}
      bg='var(--light-button-grey-4)'>
        {children}
    </Box>
  )
}

// breakpoints: {
  // sm: '600px',
  // md: '1080px', // iPad 7th gen
  // lg: '1440px', // macbook pro 15
  // xl: '1920px', // HD
  // '2xl': '3840px',  //4K
// },