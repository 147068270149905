import { allActiveNavMenuItems } from '../../../graphql/custom_queries'
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const listAllActiveMenuItems = async () => {
  try {

    let gqlParams = {
      allSort: "all", 
      filter: {
        isActive: {
          eq: true
        }
      }
    }

    const response = await client
    .graphql({
      query: allActiveNavMenuItems,
      variables: gqlParams
    })

    const results = response.data.allNavMenuItems.items
    return results
    
  } catch(err) {
    return('error listAllActiveMenuItems: ', [err]);
  }
}
