export const pushFileToQB = async (filename, key, identityid, token, environment) => {

  const response = await fetch('https://ootvr3afgwbdu3q6pb67llc7oq0xrlby.lambda-url.us-east-1.on.aws/?qbenv='+environment+'&fileName='+filename+'&fileKey='+key+'&identityId='+identityid+'&token='+token, { 
    method: 'GET', 
    headers: new Headers({
        'Authorization': 'Bearer '+token, 
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Origin': 'https://localhost:3000'
    })
  });

  const dataJSON = await response.json()

  // console.info('QB Attachable Response: ', dataJSON)
  // get attachable ref id
  const attachableId = dataJSON?.AttachableResponse[0]?.Attachable?.Id
  // setAttachableId(attachableId)
  const attachableFileName = dataJSON?.AttachableResponse[0]?.Attachable?.FileName
  // setAttachableFileName(attachableFileName)
  const newAttachableFile = {"Id": attachableId, "FileName": attachableFileName}

  return newAttachableFile
}