import { listAllUnitStatusOptions } from '../../../graphql/custom_queries';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchAllUnitStatusOptions = async () => {
  try {

    const response = await client.graphql({
      query: listAllUnitStatusOptions,
      variables: { allSort: "all" },
    });

    const results = response.data.allUnitStatusOptions.items.sort((a, b) => 
      a.order > b.order ? 1 : -1
    );

    return results;
  } catch (err) {
    return ('error fetchAllUnitStatusOptions: ', [err]);
  }
}
