import { createInitialPrimaryUnit } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const addPrimaryUnit = async (props) => {
  if (!props) return;

  try {
    console.log('addPrimaryUnit props: ', props);

    const gqlParams = {
      input: props
    };

    const response = await client.graphql({
      query: createInitialPrimaryUnit,
      variables: gqlParams
    });

    console.log('addPrimaryUnit response: ', response);
    const results = response.data.createSpkPrimaryUnitTbl.id;
    console.log('addPrimaryUnit results: ', results);

    return results;
  } catch (err) {
    return ('error addPrimaryUnit: ', [err]);
  }
};

addPrimaryUnit.propTypes = {
  props: PropTypes.object.isRequired,
};
