import { useState, useRef, useEffect } from 'react';
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Tooltip,
  HStack,
} from '@chakra-ui/react';

import PropTypes from 'prop-types';

import { ButtonSenaryWithIconOnly } from '../../Form/Button/ButtonSenaryWithIconOnly.jsx';

// graphql
import { fetchAllUnitStatusOptions } from '../../graphqlCompnents/Inspection/fetchAllUnitStatusOptions.jsx';

// decorative components
import { Divider } from '../../Divider.jsx';

import { truncateString } from '../../functions/strings.jsx';

export const InspectionQueueTable = (props) => {
  const {
    tableData,
    activeInspections,
    handleDeleteQueuedInspection,
  } = props;

  console.warn('InspectionQueueTable activeInspections: ', activeInspections)
  console.warn('InspectionQueueTable tableData: ', tableData)  

  const [ inspectionStatuses, setInspectionStatuses ] = useState(null)
  const [ inspectedInServiceStatusId, setInspectedInServiceStatusId ] = useState(null)
  const fetchStatusOptions = () => {
    const fetchStatuses = async () => {
      const unitStatuses = await fetchAllUnitStatusOptions()
      return unitStatuses
    }
    fetchStatuses().then((data) => {
      const inspectedInService = data.filter((status) => status.statusQuery==='inspected_in_service')
      setInspectedInServiceStatusId(inspectedInService[0].id)
      setInspectionStatuses(data)
    })
  }
  useEffect(() => {
    if (inspectionStatuses) {
      console.warn(' +++++ inspectionStatuses: ', inspectionStatuses)
    }
  },[inspectionStatuses])

  const Toolbar = (props) => {
    const { unitId, unitCode, index } = props
    
    // console.log('TOOLBAR PROPS: ', props)
    return(
      <HStack 
        // w={toolbarWidth} // removed this for approval required width, may need to fine tune it later.
        // bg='var(--dark-module-divider)' 
        // bg='red'
        bg='var(--dark-main-background)'
        my='5px' 
        spacing='2px' 
        // border='1px solid var(--dark-module-divider)' 
        border='1px solid var(--dark-main-background)'
        // border='1px solid orange'
        borderRadius='6px' >

        <Tooltip 
          openDelay={500} 
          closeDelay={500} 
          arrowSize={15} 
          placement='bottom' 
          label='Delete'
          // label={`${isParent} ${parentId}`}
          >
          <span style={{ zIndex: 0 }}>
            <Box 
              borderRadius={'0px 6px 6px 0px'}
              // backgroundColor={'var(--dark-button-grey-1)'}
              backgroundColor={'var(--dark-main-background)'}
              >
              <ButtonSenaryWithIconOnly
                w={'60px'}
                key={'delete_button_'+index}
                name={'delete_button_'+index}
                icon='delete'
                iconsize='18px'
                height='28px'
                onClick={() => {
                  // setToolbarDisabled(true)
                  // setQueuedInspectionDisabled(true)
                  handleDeleteQueuedInspection({
                    id: unitId,
                    unitCode: unitCode,
                    statusId: inspectedInServiceStatusId,
                  })
                }}
                // isDisabled={toolbarDisabled}
                backgroundColor={'var(--dark-main-background)'}
              />
            </Box>
          </span>
        </Tooltip>
      </HStack>
    )
  }

  Toolbar.propTypes = {
    unitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Can be string or number
    unitCode: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired, // Index of the current inspection
  };

  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // 👈️ return early if initial render
    }
    fetchStatusOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInspections])

  let queuedInspectionDisabled = false;
  return (
    <fieldset disabled={queuedInspectionDisabled}>
    <TableContainer>
      <Table 
        variant={'compact'}
        size={'compact'}
        >
        <Thead>
          <Tr>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>UNIT</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>CREATED BY</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>CLAIMED BY</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>STATUS</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>ACTION</Text></Th>
          </Tr>
        </Thead>
        <Tbody>
          {activeInspections?.map((data, inspectionIndex) => {
            // (queuedInspectionDisabled===true) && setQueuedInspectionDisabled(false)
            console.log(' !!!!! DATA: ', data)
            const claimedByFirstName = (data?.claimedBy?.goesBy && data?.claimedBy?.goesBy!=="") ? data?.claimedBy?.goesBy : data?.claimedBy?.firstName
            const claimedByName = (data?.claimedBy) ? claimedByFirstName + ' ' + data?.claimedBy?.lastName : 'Unclaimed  '
            const creatdByFirstName = (data?.createdBy?.goesBy && data?.createdBy?.goesBy!=="") ? data?.createdBy?.goesBy : data?.createdBy?.firstName
            const createdBy = (data?.createdBy) ? creatdByFirstName + ' ' + data?.createdBy?.lastName : ''
            const inspectedByFirstName = (data?.inspectedBy?.goesBy && data?.inspectedBy?.goesBy!=="") ? data?.inspectedBy?.goesBy : data?.inspectedBy?.firstName
            const inspectedByName = (data?.inspectedBy) ? `${inspectedByFirstName} ${data?.inspectedBy?.lastName}` : ''
            
            // const status = data.unitStatus?.status?.status
            //inspection_queued
            //inspection_active
            let currentStatus = null
            if (data.unitStatus?.status?.statusQuery==='inspection_queued') currentStatus = "Queued"
            if (data.unitStatus?.status?.statusQuery==='inspection_active') currentStatus = "Active"

            const teamMemberInspecting = (inspectedByName!=='') ? inspectedByName : claimedByName

            const unitDescription = `${data?.code} - ${data?.unit?.type?.name}/${data?.unit?.subType?.name}`
            const unitDescriptionTruncated = truncateString(unitDescription, 30)

            return(
              <Tr key={'tr_inspections_'+inspectionIndex}>
                <Td key={'td_inspections_1_'+inspectionIndex}>
                  <Text as="span" className='dark-sfpro-text-1'>{unitDescriptionTruncated}</Text>
                </Td>
                <Td>
                  <Text as="span" className='dark-sfpro-text-1'>{createdBy}</Text>
                </Td>
                <Td>
                  <Text as="span" className='dark-sfpro-text-1'>{teamMemberInspecting}</Text>
                </Td>
                <Td>
                  <Text as="span" className='dark-sfpro-text-1'>{currentStatus}</Text>
                </Td>
                <Td>
                <Box w='100%' align='right'>
                  <Toolbar 
                    unitId={data?.id}
                    unitCode={data?.code}
                    index={inspectionIndex}
                    // type={'delete'}
                  />
                  </Box>
                </Td>
              </Tr>
            )
          })}
          {(activeInspections?.length>0) && <Tr key={'tr_inspections_divider_end'}><Td colSpan='7' h='15px'><Divider h={'2px'} mt='5px' mb='5px'/></Td></Tr>}
          <Tr><Td colSpan='7' h='15px'></Td></Tr>
        </Tbody>
      </Table>
    </TableContainer>
    </fieldset>
  )
}

InspectionQueueTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired, // Assuming tableData is an array of objects
  activeInspections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Unique ID
    code: PropTypes.string.isRequired, // Unit code
    claimedBy: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      goesBy: PropTypes.string,
    }),
    createdBy: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      goesBy: PropTypes.string,
    }),
    inspectedBy: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      goesBy: PropTypes.string,
    }),
    unit: PropTypes.shape({
      type: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      subType: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    unitStatus: PropTypes.shape({
      status: PropTypes.shape({
        statusQuery: PropTypes.string.isRequired,
      }).isRequired,
    }),
  })).isRequired,
  handleDeleteQueuedInspection: PropTypes.func.isRequired, // Function to handle deletion of queued inspection
};