import { defineStyleConfig } from '@chakra-ui/react'

const Table = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    // border: '1px solid red',
    // borderRadius: '6px',
    // fontFamily: 'SFPro Text-Regular, Helvetica',
    // fontWeight: '400',
    // fontSize: '20px',
    // lineHeight: '20px',
    // letterSpacing: '0',
    // textDecoration: 'none',
    // chakra css for button is display: inline-flex
    // justifyContent: 'flex-start'
  },
  // Two sizes: sm and md
  sizes: {
    compact: {
      th: {
        // px: "10px",
        py: "10px",
        // lineHeight: "4",
        // fontSize: "12px",
        textTransform: 'none',
      },
      td: {
        // px: "10px",
        // py: "10px",
        // fontSize: "sm",
        // lineHeight: "4",
      },
      // mx: 20,
      // my: 20,
      // px: 24,
      // py: 23,
    },
    zeroTolerance: {
      td: {
        px: "0px",
        py: "0px",
        // fontSize: "sm",
        // lineHeight: "4",
      },
    },
    // sm: {
    //   fontSize: 'sm',
    //   px: 4, // <-- px is short for paddingLeft and paddingRight
    //   py: 3, // <-- py is short for paddingTop and paddingBottom
    // },
    // md: {
    //   fontSize: 'md',
    //   px: 6, // <-- these values are tokens from the design system
    //   py: 4, // <-- these values are tokens from the design system
    // },
    // df: {
    //   fontSize: '15px',
    //   px: '20px', // <-- these values are tokens from the design system
    //   py: '10px', // <-- these values are tokens from the design system
    // },
  },
  // Two variants: outline and solid
  variants: {
    primary: {
      th: {
        // color: "red",
        borderBottom: "1px",
        borderColor: "var(--dark-module-divider)",
      },
      td: {
        borderBottom: "1px",
        borderColor: "var(--dark-module-divider)",
      },
      caption: {
        // color: "blue",
      },
      tfoot: {
        tr: {
          "&:last-of-type": {
            th: { borderBottomWidth: 0 },
          },
        },
      },
    },
    compact: {
      // border: '3px solid red',
      th: {
        borderTop: "1px",
        borderBottom: "1px",
        borderColor: "var(--dark-module-divider)",
        px: "5px",
      },
      // tr: {
      //   _hover: { bg: 'var(--table-row-hover)'},
      // },
      td: {
        border: "0",
        px: "5px",
        // borderBottom: "1px",
        // borderColor: "var(--dark-module-divider)",
      },
      // tr: {
      //   "&:last-of-type": {
      //     border: "3px solid orange",
      //   },
      // },
      caption: {
        // color: "blue",
      },
      tfoot: {
        tr: {
          "&:last-of-type": {
            th: { 
              borderBottomWidth: 0,
              borderTopWidth: 0 
            },
          },
        },
      },
    },
    phone: {
      th: {
        borderTop: "1px",
        borderBottom: "1px",
        borderColor: "var(--dark-module-divider)",
        px: "5px",
      },
      // tr: {
      //   _hover: { bg: 'var(--table-row-hover)'},
      // },
      td: {
        border: "0",
        px: "0px",
        // borderBottom: "1px",
        // borderColor: "var(--dark-module-divider)",
      },
      // tr: {
      //   "&:last-of-type": {
      //     border: "3px solid orange",
      //   },
      // },
      caption: {
        // color: "blue",
      },
      tfoot: {
        tr: {
          "&:last-of-type": {
            th: { 
              borderBottomWidth: 0,
              borderTopWidth: 0 
            },
          },
        },
      },
    }
  },
  
  // The default size and variant values
  defaultProps: {
    variant: 'primary',
  },
})

export default Table

