import { updateDailyJobTicketEquipment } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const updateDailyJobTicketAttachedEquipment = async (params) => {
  if (!params) return;

  try {
    const response = await client.graphql({
      query: updateDailyJobTicketEquipment,
      variables: { input: params },
    });

    const results = response.data.updateSpkDJTEquipmentTbl.id;
    return results;
  } catch (err) {
    return ('error updateDailyJobTicketAttachedEquipment: ', [err]);
  }
};

updateDailyJobTicketAttachedEquipment.propTypes = {
  params: PropTypes.object.isRequired,
};
