import { 
  extendTheme, 
  // useColorMode, 
  // useColorModeValue, 
  // withDefaultColorScheme 
} from '@chakra-ui/react'

// import { mode, Styles } from "@chakra-ui/theme-tools"

// Component style overrides
import { dividerTheme } from './components/divider'
import Button from './components/button'
import Container from './components/container'
import Table from './components/table'
import Drawer from './components/drawer'
import { switchTheme } from './components/switch'
import { selectTheme } from './components/select'
import { inputTheme } from './components/input'
import Textarea from './components/textarea'
import { FormTheme } from './formTheme'
import { tabsTheme } from './components/tabs'
// import { tagTheme } from './components/tag'
import { progressbar } from './components/progressbar'

// THIS HAS ALL OF THE INFO FOR HOW TO CUSOMIZE THIS
// https://codesandbox.io/s/chakra-ui-theme-extension-w5u2n?file=/src/sections/FormSection.js


// Global style overrides
// import styles from './styles'

// Foundational style overrides
// import borders from './foundations/borders'

// import customTextStyles from './textStyles.js'


// import Text from './components/text'

const overrides = {
  semanticTokens: {
    colors: {
      leftNavWrapperBg: {
        default: "var(--light-main-background)",
        _dark: "var(--dark-main-background)",
      },
      navBarUserSeparator: {
        default: "var(--dark-module-divider)",
        _dark: "var(--dark-module-divider)"
      }
    },
  },
  colors: {
    // https://code.pieces.app/blog/learn-how-to-utilize-chakra-uis-color-mode-in-your-nextjs-application
    // --dark-gold-primary: #FEAC00;
    // --dark-gold-secondary: #B2812B;
    // --dark-gold-tertiary: #9A6F25;
    // --dark-gold-action-hover: #C99131;
    // --dark-text-white: #FFFFFF;
    // --dark-text-grey-1: #DEE2EA;
    // --dark-text-grey-2: #A7ACB1;
    // --dark-text-grey-3: #898c90;
    // --dark-button-grey-1: #2D2E2F;
    // --dark-button-grey-2: #363839;
    // --dark-button-grey-3: #3C3F40;
    // --dark-button-grey-4: #565A5C;
    // --dark-button-black: #000000;
    // --dark-divider: #55555D;
    // --dark-module-bg: #1E1E1F;
    // --dark-module-divider: #42424A;
    // --dark-module-black-divider: #000000;
    // --dark-component-border: #42424A;
    // --dark-menu-background: #353638;
    // --dark-main-background: #1e1e1f;
    goldPrimary: {
      dark: "var(dark-gold-primary)",
      light: "var(dark-gold-primary)",
    },
    testColor: {
      dark: "purple",
      light: "green",
    },
    primary: {
      dark: "#003100",
      light: "#e6f6e6",
    },
    whiteAlpha: {
      50: "rgba(255, 255, 255, 0.04)",
      100: "rgba(255, 255, 255, 0.06)",
      200: "rgba(255, 255, 255, 0.08)",
      300: "rgba(255, 255, 255, 0.16)",
      // 400: "rgba(255, 255, 255, 0.24)",
      400: "#898C90",   // This one change is for the placeholder text color in chakra-react-select, cannot find another way to do this.
      500: "rgba(255, 255, 255, 0.36)",
      600: "rgba(255, 255, 255, 0.48)",
      700: "rgba(255, 255, 255, 0.64)",
      800: "rgba(255, 255, 255, 0.80)",
      900: "rgba(255, 255, 255, 0.92)",
    },
    // gray based on color from root vars
    // find #ff0000
    spikegray: {
      50: '#f2f2f8',
      100: '#d8d9da',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#595959',
      700: '#404040',
      800: '#252627',
      900: '#040f0f',
    },
    spiketaggray: {
      50: 'green',
      100: 'green',
      200: 'green',
      300: 'green',
      400: 'green',
      500: '#595959', // <- tag bg color
      600: 'green',
      700: 'green',
      800: 'green',
      900: 'green',
    },
    // tabbed uses these
    //--dark-module-divider
    // spikedark: {
    //   50: '#f2f2f8',
    //   100: '#d8d9da',
    //   200: '#bfbfbf',
    //   300: '#a6a6a6',
    //   400: '#8c8c8c',
    //   500: '#737373',
    //   600: '#595959',
    //   700: '#404040',
    //   800: '#252627',
    //   900: '#040f0f',
    // },
    spikedarktabs: {
      50: 'red',
      100: 'var(--dark-module-divider)',
      200: 'red',
      300: 'red',
      400: 'red',
      500: 'red',
      600: 'pink',
      700: 'pink',
      800: 'pink',
      900: 'pink',
    },
    // new progress bar colors are gray light - #6D6F72 and dark - #4D4F52
    progressbar: {
      50: 'white',
      100: 'pink',
      200: 'var(--progress-bar-primary)',
      // 200: '#ffd67d',
      // 200: '#0C84FF',
      // 200: '#4D4F52', // progress bar
      300: 'yellow', // checkbox border
      400: 'red',
      500: 'purple',
      // 500: '#e69b00',
      600: 'orange',
      700: 'black',
      800: 'gray',
      900: 'brown',
    },
    switch: {
      50: 'white',
      100: 'blue',
      200: '#2DD057', // progress bar
      300: 'yellow', // checkbox border
      400: 'red',
      500: 'purple',
      600: 'orange',
      700: 'black',
      800: 'gray',
      900: 'brown',
    },
    spikeorange: {
      50: '#fff7da',
      100: '#ffe6ad',
      200: '#ffd67d',
      300: '#B2812B', // checkbox border
      400: '#ffb51a',
      500: '#FEAC00',
      // 500: '#e69b00',
      600: '#b37900',
      700: '#815600',
      800: '#4e3400',
      900: '#1e1000',
    },
    // pink for testing
    spiketest: {
      50: '#ffe2ff',
      100: '#ffb1ff',
      200: '#ff7fff',
      300: '#ff4cff',
      400: '#ff1aff',
      500: '#e600e5',
      600: '#b400b3',
      700: '#810080',
      800: '#4f004e',
      900: '#1e001d',
    }
  },
  styles: {
    global: {
      ':not(.chakra-dont-set-collapse) > .chakra-collapse': {
        overflow: 'initial !important',
      },
      // styles for the `body`
      body: {
        bg: '#000000',
        color: 'var(--dark-sfpro-text-1)',  // this applies to option text in select
        textDecoration: 'none',
        // color: 'var(--dark-sfpro-text-1)', // class="chakra-text dark-sfpro-text-1 css-0"
        _dark: {
          bg: '#000000',
          color: 'var(--dark-sfpro-text-1)',  // this applies to option text in select
          textDecoration: 'none',
          // color: 'orange',
        }
      },
    },
  },
  // breakpoints: {
  //   base: "0em", // 0px
  //   sm: "37.5em", // ~480px. em is a relative unit and is dependant on the font size.
  //   md: "67.5em", // ~768px
  //   lg: "80em", // ~992px
  //   xl: "90em", // ~1280px
  //   "2xl": "96em", // ~1536px
  // },
  breakpoints: {
    // base: '100%', //< 400 screen width
    sm: '380px', 
    // md: '1035px', // ipad 7th gen target - 810 when with enu nav
    // lg: '1395px', 
    // xl: '1505px',
    // '2xl': '1625px'
    // sm: '600px',  // less than */phone
    ipadv: '810px', // ipad vertical
    md: '1080px', // iPad 7th gen
    lg: '1350px', // macbook air at 1366
    lg2: '1600px', // 
    xl: '1920px', // HD
    '2xl': '2400px',  //4K
  },

        

  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    "2md": "0.9rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  // styles,
  // borders,
  // Other foundational style overrides go here
  textStyles: {
    // large: {
    //   fontFamily: "Comic Sans MS",
    //   fontSize: "40px",
    //   lineHeight: "2"
    // },
    // small: {
    //   fontFamily: "Times New Roman",
    //   fontSize: "12px",
    //   lineHeight: "1.25"
    // },

    // CHANGE ALL lineHeight from px to 1.2,
    navbtn: {
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: '0',
    },
    moduleaction: {
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '21px',
      letterSpacing: '0',
    },
    'title-clock-ins': {
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '20px',
      letterSpacing: '0',
    },
    'title-circle-1': {
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '16px',
      letterSpacing: '0',
    },
    'inner-circle-1': {
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '16px',
      letterSpacing: '0',
    },
    'heading-1': {
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '24px',
      letterSpacing: '0',
    },
    'heading-2': {
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '20px',
      letterSpacing: '0',
    },
    'heading-3': {
      fontFamily: 'var(--font-family-sfpro-bold)',
      fontWeight: '700',
      fontSize: '17px',
      lineHeight: '17px',
      letterSpacing: '0',
    },
    'heading-4': {
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '20px',
      letterSpacing: '0',
    },
    'dark-heading-3': {
      color: 'var(--dark-text-grey-1)',
      fontFamily: 'var(--font-family-sfpro-medium)',
      fontSize: 'var(--font-size-m)',
      fontWeight: '500',
      letterSpacing: '0',
    },
    'dark-heading-4': {
      color: 'var(--dark-text-grey-1)',
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontSize: 'var(--font-size-xsm)',
      fontWeight: '600',
      letterSpacing: '0',
    },
    'label-1': {
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontWeight: '400',
      fontSize: '16px',
    },
    'label-2': {
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '500',
      fontSize: '16px',
      color: 'var(--dark-text-white)',
    },
    'text-1': {
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '600',
      fontSize: '15px',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    'alert-1': {
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '600',
      fontSize: '15px',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    'text-2': {
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    'text-2-grey-1': {
      color: 'var(--dark-text-grey-1)',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    'text-2-grey-2': {
      color: 'var(--dark-text-grey-2)',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    'text-3': {
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontWeight: '400',
      fontSize: '13px',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    'text-4': {
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    'text-5': {
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '600',
      fontSize: '13px',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    'text-6': { 
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    'text-7': { 
      color: 'var(--dark-text-grey-1)',
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    'text-8': { 
      color: 'var(--dark-text-grey-3)',
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    'text-9': {
      color: 'var(--dark-text-grey-3)',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    'text-10': { 
      color: 'var(--dark-text-grey-3)',
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '1.5',
      letterSpacing: '0',
    },
    'modal-content-header': { 
      fontFamily: 'var(--font-family-sfpro-bold)',
      fontWeight: '700',
      fontSize: '17px',
      lineHeight: '1.5',
      letterSpacing: '0',
      color: 'var(--dark-text-grey-1)',
    },
    'modal-content-text': { 
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '1.5',
      letterSpacing: '0',
      color: 'var(--dark-text-grey-1)',
    },
    // 'text-10': { 
    //   color: 'var(--dark-text-grey-3)',
    //   fontFamily: 'var(--font-family-sfpro-semibold)',
    //   fontWeight: '400',
    //   fontSize: '16px',
    //   letterSpacing: '0',
    // },
    'manual-form-element-error': {
      color: 'var(--error-red)',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      fontSize: '14px',
      fontWeight: '400',
      letterSpacing: '0',
    },
    'dark-sfpro-text-1': {
      color: 'var(--dark-text-grey-1)',
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '600',
      letterSpacing: '0',
    },
    'sfprotext-semi-bold-gold-24px': {
      color: 'var(--dark-gold-primary)',
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontSize: 'var(--font-size-xl)',
      fontWeight: '600',
      letterSpacing: '0',
    },
    'sfprotext-semi-bold-white-15px': {
      color: 'var(—-white)',
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontSize: 'var(—-font-size-xl)',
      fontWeight: '600',
      letterSpacing: '0',
    },
    'sfprotext-semi-bold-gold-15px': {
      color: 'var(--dark-gold-primary)',
      fontFamily: 'var(--font-family-sfpro-semibold)',
      fontSize: 'var(—-font-size-xl)',
      fontWeight: '600',
      letterSpacing: '0',
    },
    'accordian-gold-header-text': {
      color: 'var(--dark-gold-primary)',
      fontFamily: 'var(--font-family-sfpro-regular)',
      fontSize: 'var(--font-size-sm)',
      fontWeight: '400',
      lineHeight: 'var(--font-size-sm)',
    }

  },
  // styles: {
  //   global: {
  //     body: {
  //       textStyle: "small"
  //     }
  //   }
  // },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  components: {
    Divider: dividerTheme,
    Button,
    Container,
    Table,
    Drawer,
    Switch: switchTheme,
    Select: selectTheme,
    Input: inputTheme,
    Textarea,
    Tabs: tabsTheme,
    Progress: progressbar,
    // Tag: tagTheme, // something broke this and turned selects selected to orange, fix this later.
    // Other components go here
  },
}

// export default extendTheme(overrides)
export const theme = extendTheme(
  // withDefaultColorScheme({ colorScheme: 'pink' }), 
  overrides,
  FormTheme
)

// issues with focus border for accessibility
// https://github.com/chakra-ui/chakra-ui/issues/708