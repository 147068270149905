import { createStatementQuickBooksVendor } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const addQuickBooksVendor = async (props) => {
  if (!props.id || !props.name) return;

  try {
    const gqlParams = {
      allSort: 'all',
      qbId: props.id,
      qbDisplayNameLower: props.name.toLowerCase(),
      qbDisplayName: props.name,
    };

    const response = await client.graphql({
      query: createStatementQuickBooksVendor,
      variables: { input: gqlParams },
    });

    const results = response.data.createSpkStatementQuickBooksVendorTbl.qbId;
    
    return results;
  } catch (err) {
    return ('error addQuickBooksVendor: ', [err]);
  }
};

addQuickBooksVendor.propTypes = {
  props: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};