import { useState, useRef, useEffect } from 'react';
import {
  Box,
  // Text,
  // Button,
  Checkbox,
  // Stack,
  HStack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  // AlertDialogCloseButton,

  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalHeader,
  // ModalFooter,
  // ModalBody,
  Center,
  // Divider,
  // VStack
} from "@chakra-ui/react";

import { ButtonPrimaryPlain } from "../Form/Button/ButtonPrimaryPlain";
import { ButtonDenaryPlain } from "../Form/Button/ButtonDenaryPlain"; 
import { TextInput } from '../Form/Input/TextInput';
import { enforceFormat, formatToPhone, formatToPhoneData } from '../functions/formatPhoneNumber.jsx';

export const CenteredConfirmContactDataChange = (props) => {

  const {
    isAlertOpen,
    cancelRef,
    onAlertClose,
    triggerType,
    closeButtonDisabled,
    handleConfirmContactDataChangeClosed,
    register,
    errors,
    handleClientContactEmailChanged,
    handleSaveChanges,
    setValue,
    getValues,
    setFocus,
    clearErrors,
    trigger,
    isUserMobile,
    scrollBehavior,
  } = props

  // console.log('props: ', props)

  const focusedFieldChanged = useRef();
  
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  // useEffect(() => {
  //   // focusedFieldChanged.onfocus = function () {     window.scrollTo(0, 0);     document.body.scrollTop = 0; }
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0
  //   console.log('did i scroll?')
  // }, [])

  // console.log('props: ', props)

  const phoneElement = document.getElementById('contactPhoneChanged');
  (phoneElement) && phoneElement.addEventListener('keydown',enforceFormat);
  (phoneElement) && phoneElement.addEventListener('keyup',formatToPhone);

  const [ newData, setNewData ] = useState(null)
  // useEffect if data has changed
  // do this on initial load
  // setValue('contactPhoneChanged', newData)
  // clearErrors(['contactPhoneChanged'])
  // console.log('isCentered: ', (!isUserMobile))

  return (
    <>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
        blockScrollOnMount={true} 
        closeOnOverlayClick={false}
        isCentered={(!isUserMobile)}
        // scrollBehavior={scrollBehavior}
        // isCentered={false}
        scrollBehavior={'outside'}
      >
        <AlertDialogOverlay
          bg='none'
          backdropFilter='auto'
          backdropBrightness='60%'
          backdropBlur='2px'>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            A{(triggerType==='email') ? 'n email address' : ' telephone number'} is required. You are about to make a system-wide change to this contact.
            </AlertDialogHeader>

            <AlertDialogBody>
            The changes you make to this contact will be reflected in all previous and future tickets.
              <HStack mt='15px'>
                <Box w={'300px'}>
                  {(triggerType==='email') ? 
                    <TextInput
                      register={register}
                      errors={errors}
                      reference={focusedFieldChanged}
                      fieldname="contactEmailChanged"
                      fieldlabel="Email"
                      fieldtype="email"
                      prettyname="Email"
                      placeholder="Enter New Email"
                      onChange={(e) => setNewData(e.target.value)}
                      onBlur={handleClientContactEmailChanged}
                    />
                  :
                    <TextInput
                      register={register}
                      errors={errors}
                      fieldname="contactPhoneChanged"
                      fieldlabel="Phone"
                      fieldtype="tel"
                      // fieldvalue={newData}
                      prettyname="Phone"
                      // rules={{ minimLength: 16, maximLength: 16 }}
                      placeholder="Enter New Phone"
                      isRequired={true} 
                      maximLength="16"
                      minimLength="16"
                      onBlur={(e) => {
                        (e.length>0) && trigger("contactPhoneChanged");
                      }}
                      onKeyPress={(e) => { if (e.target.value.length >= 16) e.preventDefault() }}
                      onChange={(e) => {
                        // console.info('xxx: ', trigger("contactPhoneChanged"))
                        trigger("contactPhoneChanged"); 
                        setNewData(e.target.value)
                      }}
                    />
                  }
                </Box>
              </HStack>
            </AlertDialogBody>

            <AlertDialogFooter>
            <Box width={'500px'}>
              <Center>
              <ButtonDenaryPlain 
                reference={cancelRef} 
                width='148px'
                name='cancelSubmitAlert'
                value='Cancel'
                onClick={() => {
                  setNewData('')
                  handleConfirmContactDataChangeClosed()}}
              />
              <ButtonPrimaryPlain 
                width='148px'
                name='confirmSubmitAlert'
                value='Submit'
                onClick={() => {
                  // alert(formatToPhoneData(newData))
                  if (triggerType==='email') {
                    handleSaveChanges(triggerType, newData)
                  } else {
                    handleSaveChanges(triggerType, formatToPhoneData(newData))
                  }
                }} 
                ml={'40px'}
              />
              </Center>
              </Box>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      
    </>
  )
}