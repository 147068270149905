import { useEffect, useContext, useState, useRef } from 'react';
import { PropTypes } from 'prop-types';
//https://codesandbox.io/p/sandbox/react-image-crop-demo-with-react-hooks-y831o?file=%2Fsrc%2FApp.tsx%3A10%2C10-10%2C23
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Text,
  Flex,
  Spacer,
  Center,
  Input,
  Spinner,
  VStack,
} from "@chakra-ui/react";

// Form
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonDenaryPlain } from '../Form/Button/ButtonDenaryPlain.jsx';
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { TextInput } from '../Form/Input/TextInput.jsx';
import { UnitDocumentTypes } from '../Form/Components/UnitDocumentTypes.jsx';

// AWS
import { uploadFileToS3 } from '../AWS/S3/uploadFileToS3.jsx';

// generic functions
import { awsDateTimeNow } from '../functions/dateTime.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// graphql
import { addUnitDocument } from '../graphqlCompnents/Unit/addUnitDocument.jsx';

export const UploadUnitDocuments = (props) => {
  const {
    unitid,
    unitdocumenttypeid,
    unitname,
    refresh,
    onClose,
  } = props

  // grab global context values
  const { store } = useContext(AppContext);

  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id
  // console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)


  // --- REACT-HOOK-FORM ---
  const { 
    control, 
    register, 
    setValue, 
    getValues, 
    formState: { 
      errors, 
    }} = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });


  const [src, setSrc] = useState(null);
  const [fileObj, setFileObj] = useState(null);
  const [crop, setCrop] = useState({ unit: '%', width: 100, height: 100 });
  const [result, setResult] = useState(null);
  const imageRef = useRef(null);

  const [ uploadedDocumentType, setUploadedDocumentType ] = useState(null)
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // console.log('file: ', file?.type?.split('/')[0])
      const fileMimeType = file?.type?.split('/')[0]
      if (fileMimeType === 'image') {
        setUploadedDocumentType('image')
        setShowFileUploadSpinner(true)
        // need to handle if image, then show image preview, if not image then show file name
        setSrc(URL.createObjectURL(file));
      } else {
        setUploadedDocumentType('document')
        setFileObj(file);
      }
    }
    setShowFileUploadSpinner(false)
  };

  const handleCropChange = (crop) => {
    setCrop(crop);
  };

  const handleCropComplete = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef.current, crop);
      setResult(croppedImageUrl);
    }
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(URL.createObjectURL(blob));
      }, 'image/jpeg');
    });
  };


  // fileinput ref for attached contracts
  const fileInputRef = useRef(null)
  const [ unitDocumentTypeId, setUnitDocumentTypeId ] = useState(unitdocumenttypeid||'')
  const [ showFileUploadSpinner, setShowFileUploadSpinner ] = useState(false)
  const [ unitDocumentTypeSelectedOption, setUnitDocumentTypeSelectedOption ] = useState('')

  const handleUnitDocumentTypeChanged = async (e) => {
    setUnitDocumentTypeId(e.value)
    setUnitDocumentTypeSelectedOption({label: e.label, value: e.value})
  }

  let unitId = unitid||null;
  

  const resetDocumentUploadForm = () => {
    // setImgSrc(null);
    // setCroppedImages({ small: null, medium: null });
    setUnitDocumentTypeSelectedOption('')
    setUploadedDocumentType(null);
    setSrc(null);
    setCrop({ unit: '%', width: 100, height: 100 });
    setResult(null);
    // imageRef.current = null;
    if (imageRef.current) {
      imageRef.current.value = "";
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setValue('notes', '');
  }

  const saveDocument = async () => {
  
    // Function to save document data to DB
    const saveDocumentToDB = async (documentId, unitDocumentTypeId) => {
      const documentParams = {
        unitId: unitId,
        createdById: currentTeamMemberId,
        uploadedDateTime: awsDateTimeNow(),
        unitDocumentTypeId: unitDocumentTypeId,
        uploadedDocumentId: documentId,
        unitIdUnitDocumentTypeId: `${unitId}#${unitDocumentTypeId}`,
        active: 'active',
        isActive: true,
        notes: getValues('notes'),
      };
      // console.info(' -----> documentParams: ', documentParams)
      await addUnitDocument(documentParams);
      // console.info(' -----> newUnitDocument: ', newUnitDocument);
    };
  
    // const sizes = [172, 364];
    const bucket = 'prod-spike-unit-images  ';
  
    // if the document is an image we need to resize it
    if (uploadedDocumentType === 'image') {
      // Handle the  image
      const largeUncropped = await resizeImageUncropped(src, 2048, 2048);
      const originalImageTask = uploadFileToS3({ file: largeUncropped, bucket })
        .then(uploadData => saveDocumentToDB(uploadData.documentId, unitDocumentTypeId))
        .catch(error => console.error('Error uploading image:', error));

      await originalImageTask;

    } else {
      const originalImageTask = uploadFileToS3({ file: fileObj, bucket })
      .then(uploadData => saveDocumentToDB(uploadData.documentId, unitDocumentTypeId))
      .catch(error => console.error('Error uploading document:', error));

      await originalImageTask;
    }

    resetDocumentUploadForm();
    refresh();
  };

  const resizeImageUncropped = (imageUrl, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        let width = image.width;
        let height = image.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);

        const timestamp = Date.now();
        const fileName = `${timestamp}.jpg`;
        const imageType = 'image/jpeg';
        canvas.toBlob((blob) => {
          const file = new File([blob], fileName, { type: imageType });
          resolve(file);
        }, imageType);

        // canvas.toBlob((blob) => {
        //   resolve(blob);
        // }, 'image/jpeg');
      };
      image.src = imageUrl;
    });
  };
  
  const [ isSubmitDisabled, setIsSubmitDisabled ] = useState(true)
  useEffect(() => {
    // Extend the condition based on whether showUnits is true or not
    const isFormValid = unitId && fileInputRef.current.value && unitDocumentTypeId && unitDocumentTypeSelectedOption;
    
    // Set the disabled state based on the validity of the form
    setIsSubmitDisabled(!isFormValid);
  }, [unitDocumentTypeId, unitId, unitDocumentTypeSelectedOption, uploadedDocumentType]);

  return (
    <Container pb='25px' maxW="1600">

      <>
        <Flex>
          <Box>
            <Text as="span" textStyle='heading-1'>Upload Unit Document for Unit ID {unitname}</Text>
          </Box>
          <Spacer />
          <Box>
            <ButtonQuaternaryWithIcon 
              name='closeDrawer'
              iconsize='26px'
              leftIcon='close'
              value='Cancel'
              onClick={onClose}
            />
          </Box>
        </Flex>
    
        <VStack align='stretch'>
          <Box maxW={'600px'} minW={'300px'} w={'40%'} mt={'25px'}>
            <UnitDocumentTypes 
              handleUnitDocumentTypeChanged={handleUnitDocumentTypeChanged}
              unitDocumentTypeSelectedOption={unitDocumentTypeSelectedOption}
              documentType={'essential'}
              register={register}
              control={control}
              errors={errors}
            />
          </Box>

          <Box maxW={'600px'} minW={'300px'} w={'40%'} mt={'25px'}>
            <Input 
              name='file-upload'
              ref={fileInputRef} 
              type="file"
              accept=".jpeg, .jpg, .png, .gif, .doc, .docx, .txt, .xls, .xlsx, .pdf, .ppt, .pptx, .csv"  //.zip, .rar, .7z, .mp4, .mov, .avi, .mp3, .wav, .flac, .ogg, .wma, .m4a, .aac, .flv, .mkv, .webm, .wmv, .m4v, .3gp, .3g2, .ogv, .heic, .heif, .hevc
              onChange={handleFileChange}
            />
          </Box>

          {(showFileUploadSpinner) ? <Center><Spinner color='var(--progress-bar-primary)' /></Center> : <>
            <Box mt={'25px'}>
              {(src) && (
                <Box> 
                  <ReactCrop
                    src={src}
                    crop={crop}
                    onChange={handleCropChange}
                    onComplete={handleCropComplete}
                    onImageLoaded={(image) => (imageRef.current = image)}
                  />
                </Box>
              )}
              {result && (
                <Box mt={'25px'}>
                  <Text as="span" textStyle='label-1'>Preview:</Text>
                  <div
                    style={{
                      width: '100%',
                      height: '200px',
                      overflow: 'hidden',
                      display: 'flex',
                    }}>
                    <img
                      alt="Cropped"
                      src={result}
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                      }}/>
                  </div>
                </Box>
              )}

            </Box>
          </>}

          <Box maxW={'600px'} minW={'300px'} w={'40%'}>
            <TextInput
              register={register}
              errors={errors}
              fieldname="notes"
              fieldlabel="Searchable Notes"
              prettyname="Notes"
              placeholder="Enter searchable notes here."
            />
          </Box>

          <Box pt={'25px'}>
            <ButtonDenaryPlain
              width={'48%'}
              mr={'25px'}
              type="button"
              // onClick={handleSubmit(formSubmit, onError)}
              // onClick={formConfirmation}
              onClick={resetDocumentUploadForm}
              name='cancel'
              value='Reset'
              // isDisabled={(djtDate > dateToday || isVerifiedReadyState===false)}
            />
            <ButtonPrimaryPlain 
              width={'48%'}
              // onClick={onFileUploadButtonClick}
              onClick={saveDocument}
              type="button"
              name='upload-file'
              value='Upload Document'
              isDisabled={isSubmitDisabled}
              // isDisabled={true}
            />
          </Box>
        </VStack>
      </>

    </Container>

  )

}

UploadUnitDocuments.propTypes = {
  unitid: PropTypes.string.isRequired,
  unitdocumenttypeid: PropTypes.number.isRequired,
  unitname: PropTypes.string.isRequired,
  refresh: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
