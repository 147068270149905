import PropTypes from 'prop-types';
import { Button } from '@chakra-ui/react';

export const ButtonPrimaryPlain = (props) => {
  const {
    name,
    value,
    onClick,
    isDisabled=false,
    ref,
    reference,
    mx='',
    my='',
    type = 'button',
  } = props;

  return (
    <Button 
      {...props}
      name={name}
      ref={reference || ref}
      variant='plainPrimary' 
      onClick={onClick}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
      type={type}
    >
      {value}
    </Button>
  );
};

// Prop validation
ButtonPrimaryPlain.propTypes = {
  name: PropTypes.string, // Name is expected to be a string
  value: PropTypes.oneOfType([ // Value can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]).isRequired, // Value is required
  onClick: PropTypes.func, // onClick is a function
  isDisabled: PropTypes.bool, // isDisabled is a boolean
  ref: PropTypes.oneOfType([ // ref can be a function or an object created by React.createRef()
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  reference: PropTypes.oneOfType([ // reference can also be a function or an object
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  mx: PropTypes.oneOfType([ // mx can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([ // my can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.oneOf(['button', 'submit', 'reset']), // type is limited to these values
};