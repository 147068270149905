import { Amplify } from '@aws-amplify/core';

export function SetS3Config(bucket, level) {
  Amplify.configure({
    Storage: {
      AWSS3: {
        bucket: bucket,
        level: level,
        region: 'us-east-1',
        identityPoolId: 'us-east-1:9b337b3c-ff2f-4a5b-9ca3-1760a93e1f0b',
      },
    },
  });
}