const isNumericInput = (event) => {
	const key = event.keyCode;
	return ((key >= 48 && key <= 57) || // Allow number line
		(key >= 96 && key <= 105) // Allow number pad
	);
};

const isModifierKey = (event) => {
	const key = event.keyCode;
	return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
		(key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
		(key > 36 && key < 41) || // Allow left, up, right, down
		(
			// Allow Ctrl/Command + A,C,V,X,Z
			(event.ctrlKey === true || event.metaKey === true) &&
			(key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
		)
};

export const enforceFormat = (event) => {
	// Input must be of a valid number format or a modifier key, and not longer than ten digits
	if(!isNumericInput(event) && !isModifierKey(event)){
		event.preventDefault();
	}
};

export const formatToPhone = (event) => {
	if(isModifierKey(event)) {return;}

	// I am lazy and don't like to type things more than once
	const target = event.target;
	const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
	const zip = input.substring(0,3);
	const middle = input.substring(3,6);
	const last = input.substring(6,10);

	if(input.length > 6){target.value = `(${zip}) ${middle}-${last}`;}
	else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
	else if(input.length > 0){target.value = `(${zip}`;}
};

//if (data===null || data==='') return ''
export const formatToPhoneData = (data) => {

	const input = data.replace(/\D/g,'').substring(0,10); // First ten digits of input only
	const zip = input.substring(0,3);
	const middle = input.substring(3,6);
	const last = input.substring(6,10);

	const formatted = `(${zip}) ${middle} - ${last}`
	return formatted
}

// const inputElement = document.getElementById('contactPhone');
// inputElement.addEventListener('keydown',enforceFormat);
// inputElement.addEventListener('keyup',formatToPhone);

/**
 * Normalizes a phone number to E.164 format.
 * @param {string} phoneNumber The phone number in any format.
 * @param {string} defaultCountryCode The default country code to prepend if not present.
 * @returns {string} The phone number in E.164 format.
 */
export function normalizePhoneNumber(phoneNumber, defaultCountryCode = '+1') {
	// Remove all non-digit characters from the phone number
	let cleanNumber = phoneNumber.replace(/\D/g, '');

	// Check if the country code is already present
	if (cleanNumber.startsWith('1') && cleanNumber.length === 10) {
			// Prepend the US country code
			cleanNumber = defaultCountryCode + cleanNumber;
	} else if (!cleanNumber.startsWith('+')) {
			// Assume the number is US format and prepend the country code
			cleanNumber = defaultCountryCode + cleanNumber;
	}

	return cleanNumber;
}