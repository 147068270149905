// // chakra ui
// import {
//   Button,
// } from '@chakra-ui/react';

// // icons
// // import { BiAtom, BiPlusCircle, BiExport } from "react-icons/bi";

// import { AvailableIcons } from '../../Icons/AvailableIcons'

// export const ButtonSignOutWithIcon = (props) => {
//   const {
//     name,
//     ref,
//     leftIcon=null,
//     rightIcon=null,
//     iconsize='20px',
//     value,
//     onClick,
//     isDisabled,
//     mx,
//     my,
//   } = props;

//   const leftIconComponent = (leftIcon) ? <AvailableIcons boxSize={iconsize} iconRef={leftIcon} /> : null
//   const rightIconComponent = (rightIcon) ? <AvailableIcons boxSize={iconsize} iconRef={rightIcon} /> : null

//   return (
//     <><Button 
//       {...props}
//       name={name}
//       ref={ref}
//       variant="iconButtonSignout"
//       leftIcon={leftIconComponent}
//       rightIcon={rightIconComponent}
//       onClick={onClick}
//       isDisabled={isDisabled}
//       mx={mx}
//       my={my}
//       >{value}</Button></>
//   )
// }

import PropTypes from 'prop-types';
import { Button } from '@chakra-ui/react';
import { AvailableIcons } from '../../Icons/AvailableIcons';

export const ButtonSignOutWithIcon = (props) => {
  const {
    name,
    ref,
    leftIcon = null,
    rightIcon = null,
    iconsize = '20px',
    value,
    onClick,
    isDisabled,
    mx,
    my,
  } = props;

  const leftIconComponent = leftIcon ? <AvailableIcons boxSize={iconsize} iconRef={leftIcon} /> : null;
  const rightIconComponent = rightIcon ? <AvailableIcons boxSize={iconsize} iconRef={rightIcon} /> : null;

  return (
    <Button 
      {...props}
      name={name}
      ref={ref}
      variant="iconButtonSignout"
      leftIcon={leftIconComponent}
      rightIcon={rightIconComponent}
      onClick={onClick}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
    >
      {value}
    </Button>
  );
};

// Prop validation
ButtonSignOutWithIcon.propTypes = {
  name: PropTypes.string, // Name is expected to be a string
  ref: PropTypes.oneOfType([ // ref can be a function or an object created by React.createRef()
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  leftIcon: PropTypes.string, // Icon name is expected to be a string
  rightIcon: PropTypes.string, // Icon name is expected to be a string
  iconsize: PropTypes.string, // Icon size should be a string like "20px"
  value: PropTypes.oneOfType([ // Value can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]).isRequired, // Value is required
  onClick: PropTypes.func, // onClick is a function
  isDisabled: PropTypes.bool, // isDisabled is a boolean
  mx: PropTypes.oneOfType([ // mx can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([ // my can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
};

// Default props
ButtonSignOutWithIcon.defaultProps = {
  leftIcon: null, // Default to no left icon
  rightIcon: null, // Default to no right icon
  iconsize: '20px', // Default icon size
  isDisabled: false, // Default to not disabled
  mx: '0px', // Default margin x
  my: '0px', // Default margin y
};