import { useState } from 'react';
import { AvailableIcons } from "../Icons/AvailableIcons";

import {
  Box,
  Text,
  Checkbox,
  HStack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Center,
} from "@chakra-ui/react";

// import { AvailableIcons } from "../Icons/AvailableIcons";
// import { ButtonNonaryPlain } from "../Form/Button/ButtonNonaryPlain";
import { ButtonPrimaryPlain } from "../Form/Button/ButtonPrimaryPlain";
import { ButtonOctonaryRed } from '../Form/Button/ButtonOctonaryRed';
import { ButtonDenaryPlain } from "../Form/Button/ButtonDenaryPlain";
// import { TextInputDate } from "../Form/Input/TextInputDate";

export const CenteredConfirmDeleteDJTAlert = (props) => {

  const {
    isAlertOpen,
    cancelRef,
    onAlertClose,
    content,
    closeButtonDisabled,
    handleConfirmDeleteAlertClosed,
  } = props

  const [ confirmIsChecked, setConfirmIsChecked ] = useState(false)
  const handleCheckboxToggle = async (state) => {
    if (state===true) {
      setConfirmIsChecked(true)
    } else {
      setConfirmIsChecked(false)
    }
  }

  // console.log('props: ', props)
  // console.log('confirmIsChecked: ', confirmIsChecked)
  //width={(hasLaborIssue && hasUnitIssue) ? '710px' : '380px'}  maxH={'486px'} maxW={'710px'}

  return (
    <>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
        blockScrollOnMount={true} 
        closeOnOverlayClick={false}  
        isCentered
      >
        <AlertDialogOverlay
          bg='none'
          backdropFilter='auto'
          backdropBrightness='60%'
          backdropBlur='2px'>
          <AlertDialogContent>
            <AlertDialogHeader>
              <Center>
                <Box h={'50px'} mt={'10px'}>
                  <AvailableIcons boxSize={'60px'} iconRef={'alerttriangle'} color={'var(--error-red)'}/>
                </Box>
              </Center>              
            </AlertDialogHeader>

            <AlertDialogBody mt={'0px'}>
              <Center><Text textStyle={'modal-content-header'}>Delete Daily Job Ticket(s)</Text></Center>
              <Box border={'1px solid var(--dark-divider)'} p={'25px'} m={'20px'}>Deleted Daily Job Tickets can be restored from the Deleted tab.</Box>
              <HStack ml={'35px'} mt={'15px'} mb={'10px'}>
                <Box verticalAlign='top' height='15px'><Checkbox 
                  borderColor='spikeorange.300'
                  colorScheme='spikeorange'
                  onChange={(e) => handleCheckboxToggle(e.target.checked)}
                  /></Box>
                  <Box>I confirm.</Box>
              </HStack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Box width={'500px'}>
                <Center>
                  <ButtonOctonaryRed 
                    width='148px'
                    name='confirmDeleteAlert'
                    value='Delete'
                    onClick={() => {setConfirmIsChecked(false); handleConfirmDeleteAlertClosed()}} 
                    mr={'40px'}
                    // isdisabled={confirmIsChecked ? 0 : 1}
                    isdisabled={confirmIsChecked ? false : true}
                  />

                  <ButtonPrimaryPlain 
                    reference={cancelRef} 
                    width='148px'
                    name='cancelDeleteAlert'
                    value='Cancel'
                    onClick={() => {setConfirmIsChecked(false); onAlertClose()}}
                  />
                  
                </Center>
              </Box>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      
    </>
  )
}