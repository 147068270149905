/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
// import { Storage } from 'aws-amplify';
import {
  Box,
  Button,
  // Image,
  Flex,
  Spacer,
  Text,
  Container,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";

// QuickBooks
import { QuickBooksAuth } from '../QuickBooks/QuickBooksAuth.jsx';
import { QBLoginLogoutButtons } from '../QuickBooks/QBLoginLogoutButtons.jsx';
import { createQBVendor } from '../QuickBooks/createQBVendor.jsx';
import { pushStatementToQuickBooks } from '../QuickBooks/pushStatementToQuickBooks.jsx';
import { getClasses } from '../QuickBooks/getClasses.jsx';

// react-hook-form
import { useForm } from 'react-hook-form';

import { getTaxableQuarter,
  spellOutDate, 
  awsFormatUnknownDate,
  dateTimeToAWSDate,
  todaysDateYMD } from '../functions/dateTime.jsx';
import { stripToNumbersAndDecimal } from '../functions/number.jsx';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
import { TextareaInput } from '../Form/Input/TextareaInput.jsx';

// form buttons
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';

// structural components
import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';

// graphql and external components
import { fetchDivisionOptions } from '../graphqlCompnents/Statement/fetchDivisionOptions.jsx';
import { fetchContactCompaniesByDivision } from '../graphqlCompnents/DJT/fetchContactCompaniesByDivision.jsx';
import { fetchQuickBooksExpenseCategories } from '../graphqlCompnents/Statement/fetchQuickBooksExpenseCategories.jsx';
// import { updateStatementDocument } from '../graphqlCompnents/Statement/updateStatementDocument.jsx';
// import { fetchVendorByPhoneNumber } from '../graphqlCompnents/Statement/fetchVendorByPhoneNumber.jsx'
import { addQuickBooksVendor } from '../graphqlCompnents/Statement/addQuickBooksVendor.jsx';
import { addVendorMap } from '../graphqlCompnents/Statement/addVendorMap.jsx';
import { updateVendorMap } from '../graphqlCompnents/Statement/updateVendorMap.jsx';
import { fetchAccountsByTeamMember } from '../graphqlCompnents/Statement/fetchAccountsByTeamMember.jsx';
import { updateIFTAFuelTracking } from '../graphqlCompnents/Statement/updateIFTAFuelTracking.jsx';

// external components
import { VendorMap } from './VendorMap.jsx';

export const StatementDetail = (props) => {
  const { qbEnv, selectedStatement, screenWidth, userData, onClose } = props;

  // console.log('qbEnv: ', qbEnv);
  console.log('userData: ', userData);
  console.log('selectedStatement: ', selectedStatement);
  const { 
    id, addedNotes, 
    amountPaid, categoryId, clientId,
    divisionId, invoiceReceiptDate, statementTotal, fixedTotal, 
    submittedDate, teamMemberId, teamMember, uploadedDocument, 
    vendorMapKey, vendorName, 
    // vendorPhone, 
    iftaFuelTaxTrackId, iftaFuelTaxTrack, isDuplicate
  } = selectedStatement;

  (iftaFuelTaxTrack) && console.log('iftaFuelTaxTrack: ', iftaFuelTaxTrack)
  // console.log('id: ', id)

  const { 
    isOpen: isVendorMapOpen, 
    onOpen: onVendorMapOpen, 
    onClose: vendorMapClose 
  } = useDisclosure();

  const onVendorMapClose = () => {
    vendorMapClose();
  };

  const handleOpenDrawer = (type) => {
    if (type === 'map') {
      onVendorMapOpen();
    }
  };

  // --- REACT-HOOK-FORM ---
  const { 
    control, 
    register, 
    handleSubmit, 
    clearErrors,
    setValue,
    formState: { 
      errors, 
      isSubmitting, 
    }} = useForm({
      mode: 'onSubmit',
      // reValidateMode: 'onChange',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const onError = (errors, e) => console.log(errors, e);

  const [vendorNameIsEmpty, setVendorNameIsEmpty] = useState(false);
  const [vendorNameWasEmpty, setVendorNameWasEmpty] = useState(false);

  const [ teamMemberCards, setTeamMemberCards ] = useState([]);
  const [ selectedTeamMemberCard, setSelectedTeamMemberCard] = useState('');
  useEffect(() => {
    if (teamMemberId) {
      const fetchAccounts = async () => {
        const response = await fetchAccountsByTeamMember(qbEnv, teamMemberId);
        const options = response.map(cards => ({
          value: cards?.qbChartOfAccountsId,
          label: cards?.qbChartOfAccounts?.qbChartOfAccountsName,
        }));
        setSelectedTeamMemberCard(options[0] || '');
        setTeamMemberCards(options || []);
        setValue('selectTeamMemberCard', options[0]?.value || '');
        
      };
      fetchAccounts();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[teamMemberId, qbEnv])

  useEffect(() => {
    if (vendorName?.length === 0) {
      setVendorNameIsEmpty(true);
      setVendorNameWasEmpty(true);
    } else {
      setVendorNameIsEmpty(false);
    }
  }, [vendorName]);

  // const [ potentialVendorName, setPotentialVendorName ] = useState({});
  // useEffect(() => {
  //   if (!vendorMapKey && !vendorName && vendorPhone) {
  //     const fetchPotentialVendor = async (phone) => {
  //       const vendor = await fetchVendorByPhoneNumber(phone);
  //       setPotentialVendorName(vendor);
  //     };
      
  //     fetchPotentialVendor(vendorPhone);
  //   }
  // }, [vendorMapKey, vendorName, vendorPhone]);

  const [ selectedDivision, setSelectedDivision] = useState('');
  const [ divisionOptions, setDivisionOptions ] = useState([]);
  useEffect(() => {
    const fetchDivisions = async () => {
      try {
        const responseAll = await fetchDivisionOptions('all');
        const optionsAll = responseAll.map(division => ({
          value: division.id,
          label: division.prettyname,
        }));
  
        const responseSpecial = await fetchDivisionOptions('special');
        const optionsSpecial = responseSpecial.map(division => ({
          value: division.id,
          label: division.prettyname,
        }));
  
        const combinedOptions = [...optionsAll, ...optionsSpecial].sort((a, b) => 
          a.label.localeCompare(b.label)
        );
        setDivisionOptions(combinedOptions);
  
        const matchingDivision = combinedOptions.find(option => option.value === divisionId);
  
        if (matchingDivision) {
          setSelectedDivision(matchingDivision);
          setValue('selectDivision', matchingDivision.value);
        }
  
      } catch (error) {
        console.error('Failed to fetch divisions:', error);
      }
    };
  
    fetchDivisions();
  }, [divisionId, setValue]);

  const [selectedClient, setSelectedClient] = useState('');
  const [clients, setClients] = useState([]);
  useEffect(() => {
    const fetchClients = async () => {
      if (selectedDivision.value || divisionId) {
        try {
          const currentDivision = selectedDivision.value || divisionId;
          const response = await fetchContactCompaniesByDivision(currentDivision);
          const clientOptions = response.map(client => ({
            value: client.value,
            label: client.label,
          }));
  
          clientOptions.unshift({ value: '', label: ' - None -' });
  
          setClients(clientOptions);
  
          // Find the selected client object
          const selectedClient = clientOptions.find(client => client.value === clientId) || { value: '', label: ' - None -' };
  
          setSelectedClient(selectedClient);
          setValue('selectClient', selectedClient.value);
        } catch (error) {
          console.error('Failed to fetch clients:', error);
        }
      }
    };
  
    fetchClients();
  }, [selectedDivision, divisionId, clientId, setValue]);

  // const [ statementImage, setStatementImage ] = useState('');
  useEffect (() => {
    if (uploadedDocument) {
      // const fetchImage = async (document) => {
      //   try {
      //     // const signedUrl = await getSignedUrl(document);
      //     // setStatementImage(signedUrl);
      //   } catch (error) {
      //     console.error('Failed to fetch image:', error);
      //   }
      // };

      // fetchImage(uploadedDocument);
    }
  }, [uploadedDocument]);

  const [ expenseCategories, setExpenseCategories ] = useState([]);
  const [ selectedExpenseCategory, setSelectedExpenseCategory ] = useState('');
  const [ isIFTATracked, setIsIFTATracked ] = useState(false)
  useEffect(() => {
    const fetchAllExpenseCategories = async () => {
      try {
        const response = await fetchQuickBooksExpenseCategories(qbEnv);
        console.log('response: ', response)
        const categories = response.map(expense => ({
          value: expense.qbId,
          label: expense.appName,
          isIFTATracked: (expense?.isIFTATracked===true) ? 'true' : 'false',
        }));
  
        // categories.unshift({ value: '', label: ' - None -' });
  
        setExpenseCategories(categories);
        console.log('categories: ', categories)
  
        // Find the selected category object
        const selectedCategory = categories.find(category => category.value === categoryId);
        setSelectedExpenseCategory(selectedCategory || '');
        setValue('selectExpenseCategory', selectedCategory?.value || '');
        
        if (selectedCategory?.isIFTATracked === "true") {
          console.error('selectedCategory?.isIFTATracked: ', selectedCategory?.isIFTATracked)
          setIsIFTATracked(true);
        }
        // updateSelectedExpenseCategory({
        //   label: selectedCategory?.label, 
        //   value: selectedCategory?.value, 
        //   isIFTATracked: selectedCategory?.isIFTATracked
        // })
      } catch (error) {
        console.error('Failed to fetch expense categories:', error);
      }
    };
  
    fetchAllExpenseCategories();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, qbEnv]);

  const [ selectedStatementType, setSelectedStatementType ] = useState({label: 'Receipt', value: 'receipt'});
  const statementTypeOptions = [
    {label: 'Receipt', value: 'receipt'},
    {label: 'Invoice', value: 'invoice'}, 
    {label: 'Refund', value: 'refund'}
  ];

  const handleStatementTypeChange = (event) => {
    setSelectedStatementType(event);
    setValue('selectStatementType', event.value);
  };

  const updateSelectedExpenseCategory = (event) => {
    console.log('event: ', event);
    setIsIFTATracked(event?.isIFTATracked==="true" ? true : false)
    setSelectedExpenseCategory(event);
    setValue('selectExpenseCategory', event);
    clearErrors('selectExpenseCategory');
  };


  const updateSelectedDivision = (event) => {
    setSelectedDivision(event);
  };

  const updateSelectedClass = (event) => {
    setSelectedQBClass(event);
    setValue('selectClass', event);
    clearErrors('selectClass');
  };

  const updateSelectedTeamMemberCard = (event) => {
    setSelectedTeamMemberCard(event);
  }

  const updateSelectedClient = (event) => {
    setSelectedClient(event);
    setValue('selectClient', event.value);
  };

  // const getSignedUrl = async (uploadedDocument) => {
  //   // const bucketName = uploadedDocument?.bucket;
  //   // const signedUrl = await Storage.get(uploadedDocument?.key, {
  //   //   level: "protected",
  //   //   identityId: uploadedDocument?.identityId,
  //   //   bucket: bucketName,
  //   // });
  //   // return signedUrl;
  // };

  const [ manuallyMappedVendor, setManuallyMappedVendor ] = useState({});
  const [ isNewQBVendor, setIsNewQBVendor ] = useState(false);
  const [ newQBVendorName, setNewQBVendorName ] = useState('');
  const [ newVendorId, setNewVendorId ] = useState('');
  const mapQBVendorToStatementVendor = (vendorObj) => {

    setManuallyMappedVendor(vendorObj);

    if (!vendorObj?.id && vendorObj?.name) {
      setNewQBVendorName(vendorObj.name);
      setIsNewQBVendor(true);
    }
  };

  // const mapVendorToQuickBooks = (statementVendor, qbId) => {
  //   console.log('mapping: ', statementVendor+' '+qbId);
  // };

  const handleAddedNotesChanged = (e) => {
    console.log('e: ', e.target.value);
    setValue('addedNotes', e.target.value);
  };

  const handleStatementTotalChanged = (e) => {
    console.log('e: ', e.target.value);
    const total = stripToNumbersAndDecimal(e.target.value);
    setValue('statementTotalInput', total);

  };

  const [ invoiceReceiptDateSubmitted, setInvoiceReceiptDateSubmitted ] = useState('');


  const pushStatementToQB = async (purchaseObj) => {
    
    const { key, identityId } = uploadedDocument;

    const uploadRedults = await pushStatementToQuickBooks(purchaseObj, key, key, identityId, tokenObj?.access_token, qbEnv)
    console.warn('uploadResults: ', uploadRedults)
    return uploadRedults;
  }

  const deleteDuplicate = async () => {
    // update the statement 
    // const statementParams = {
    //   id: selectedStatement.id,
    //   approvedById: userData.id,
    //   isApproved: true,
    //   statusTab: 'Deleted',
    // }

    // const updateStatementResults = await updateStatementDocument(statementParams);
    // console.log('Update Statement Results:', updateStatementResults);

    onClose();
  }

  const formSubmit = async (values) => {
    if (qbEnv!=='sandbox') return;
    
    if (!values.selectDivision) return;

    // fileKey in push is messed up
    // remove . from uploaded filename except last dot

    console.log('Form Values:', values);
    console.log('vendorName: ', vendorName)
    console.log('vendorNameWasEmpty: ', vendorNameWasEmpty)
    console.log('manuallyMappedVendor: ', manuallyMappedVendor)
    console.log('selectTeamMemberCard: ', selectedTeamMemberCard)
    console.log('statementTotal: ', values?.statementTotalInput)
    console.log('selectedExpenseCategory: ', selectedExpenseCategory)
    console.log('selectedExpenseCategory?.value: ', selectedExpenseCategory?.value)
    console.log('selectedDivision?.value: ', selectedDivision?.value)
    console.log('selectedQBClass?.value: ', selectedQBClass?.value)
    console.log('expenseNotes: ', values?.expenseNotes)

    console.log('isNewQBVendor: ', isNewQBVendor)
    console.log('newQBVendorName: ', newQBVendorName)
    console.log('newVendorId: ', newVendorId)
    console.log('manuallyMappedVendor: ', manuallyMappedVendor)
    console.log('vendorNameWasEmpty: ', vendorNameWasEmpty)

    // return

    const updatedInvoiceReceiptDate = values?.invoiceReceiptDateSubmitted;

    // only call createVendorName if we are adding a new vendor
    if (isNewQBVendor === true && newQBVendorName!=='') {
      
      const results = await createQBVendor(tokenObj?.access_token, newQBVendorName, qbEnv);

      console.log('add vendor results: ', results);
      console.log('add vendor results: ', results?.vendorId);
    
      if (results.error) {
          // Handle the error accordingly
          if (results.error === 'Vendor already exists') {
              // Handle the specific case where the vendor already exists
              console.log('Vendor already exists. Please choose a different name or use the existing vendor.');
              // Display an error message to the user
              alert('Vendor already exists. Please choose a different name or use the existing vendor.');
          } else {
              console.error('Error in form submission:', results.error);
          }
      } else {
          // Proceed with further processing
          // Continue processing the rest of the form
          // Assuming you have other form processing code here
          if (results.vendorId) setNewVendorId(results.vendorId);
          console.log('Form processing continues...');
          console.log('results (need vendor id): ', results)
      }
    }

    // if the vendor is new, we need to create the record in QB Vendor table
    if (newVendorId && manuallyMappedVendor.name) {
      // run the add vendor to qb vendor table and update the modal
      console.log('New Vendor ID:', newVendorId);
      const params = {
        id: newVendorId,
        name: manuallyMappedVendor.name,
      };
      const newQBVendorResults = await addQuickBooksVendor(params);
      console.log('New QB Vendor Results:', newQBVendorResults);

      // if the vendor name we are mapping to is blank, we don't need to create the mapping table entry
      // if the vendor name we are mapping to is not blank, we need to create the mapping table entry
      if (!vendorNameIsEmpty) {
        console.log('Creating mapping entry');
        // run the add vendor to map and update the modal
        const addVendorMapResults = await addVendorMap({
          vendorName: vendorName, 
          vendorTypeKey: selectedStatementType?.value, 
          qbVendorId: newVendorId
        });
        console.log('Add Vendor Map Results:', addVendorMapResults);
      } else {
        console.log('We have a vendor, may need to update map with qbVendorId');
        // we might need to update the map record if they changed the qb vendor
        // manuallyMappedVendor.id
        // vendorName

        // then update SpkStatementDocumentTbl with statementDetail.id, vendorMapKey
      }
    }

    // if the vendor is existing, we need to update the map table

    // now we either have newVendorId or an existing vendorId we can update the map table
    // run the add vendor to map and update the modal

    // if the extracted vendorName is blank, this is just a one-off match
    // if the extracted vendorName is not blank, we need to update the map table

    // map all of the lower case vendor names from the qbvendornames into the vendorm aptbl with the id's?

    // this needs to change, we have put the vendorName in the vendorMapKey.name
    // we also need to determine if we need to update the mapping with the full vendor name from 
    // QB and the id.
    if (vendorNameWasEmpty && manuallyMappedVendor.id && manuallyMappedVendor?.name?.length>3) {
      // we should now have either a new vendor (detrmine) or an existing vendor (with name and id)
      // we need to get the name and the id into the vendor map
      // we need to figure out if we need to create or update the existing vendor map.

      console.log('Doing one-off vendor match')
      // map this statement to the vendor
      // update SpkStatementDocumentTbl with statementDetail.id, vendorMapKey
      const statementParams = {
        id: selectedStatement.id,
        // vendorMapKey: manuallyMappedVendor.id||currentMappedVendorId||'',
        vendorMapKey: manuallyMappedVendor.value||'',
        vendorName: manuallyMappedVendor.value||'',
        // invoiceReceiptDate: invoiceReceiptDateSubmitted,
        divisionId: selectedDivision?.value,
        statementType: selectedStatementType?.value,
        statementTotal: statementTotal,
        amountPaid: amountPaid,
        addedNotes: addedNotes,
      }
      if (invoiceReceiptDateSubmitted) {
        statementParams.invoiceReceiptDate = invoiceReceiptDateSubmitted;
      }
      if (selectedClient?.value) {
        statementParams.clientId = selectedClient?.value;
      }
      if (selectedExpenseCategory?.value) {
        statementParams.categoryId = selectedExpenseCategory?.value;
      }
      // const updateStatementResults = await updateStatementDocument(statementParams);
      // console.log('Update Statement Results:', updateStatementResults);


    } else {
      
      // does manuallyMappedVendor.value exist in the vendorMapKey table?

      // vendorName: "oncue 105", qbVendorId: "1363"
      // updateVendorMap({vendorName: vendorName, qbVendorId: newVendorId})
      if (manuallyMappedVendor.id && vendorName?.length>3) {
        // still need to check if this is update or create.

        const updateVendorMapResults = await updateVendorMap({
          vendorName: vendorName.toLowerCase(), 
          vendorTypeKey: selectedStatementType?.value, 
          qbVendorId: manuallyMappedVendor.id
        });
        console.log('Update Vendor Map Results:', updateVendorMapResults);

        const addVendorMapResults = await addVendorMap({
          vendorName: vendorName.toLowerCase(), 
          vendorTypeKey: selectedStatementType?.value, 
          qbVendorId: manuallyMappedVendor.id
        });
        console.log('Add Vendor Map Results:', addVendorMapResults);
      }


      console.log('Mapping ' + vendorName + ' to new or existing vendor')
    }

    // {
    //   "PaymentType": "CreditCard",
    //   "AccountRef": {
    //     "value": "91"						<- CreditCard must specify credit card account. Validation Rules:Valid and Active Account Reference of an appropriate type.
    //   },
    //   "EntityRef": {
    //     "name": "Vendor", 
    //     "value": "43"						<- Vendor ID
    //   }, 
    //   "PrivateNote": "Test memo.", 
    //   "Line": [
    //     {
    //       "DetailType": "AccountBasedExpenseLineDetail",
    //       "Amount": 10.0,						<- Amount
    //       "AccountBasedExpenseLineDetail": {
    //         "AccountRef": {
    //           "value": "13"						<- ExpenseCategoryId
    //         },
    //         "ClassRef": {
    //           "value": "5000000000000344554"			<- ClassId (Division QB Class)
    //         }
    //       }
    //     }
    //   ]
    // } 
    // return


    const purchaseObj = {
      accountId: selectedTeamMemberCard.value,
      vendorId: selectedStatement?.vendorMap?.qbVendorId || manuallyMappedVendor.id,
      txnAmount: values?.statementTotalInput,
      expensCatId: selectedExpenseCategory?.value,
      classId: selectedQBClass?.value,
      notes: values?.expenseNotes
    }

    console.log('purchaseObj: ', purchaseObj)

    const results = await pushStatementToQB(purchaseObj);
    console.warn('results: ', results);

    // if this is IFTA tracked we need to update
    if (iftaFuelTaxTrackId) {
      const taxYearQuarter = getTaxableQuarter(updatedInvoiceReceiptDate);
      const iftaObj = {
        id: iftaFuelTaxTrackId,
        statementDate: updatedInvoiceReceiptDate,
        yearQuarter: taxYearQuarter,
        approvedById: userData.id,
        isApproved: true,
      }

      const iftaResults = await updateIFTAFuelTracking(iftaObj);
      console.log('iftaResults: ', iftaResults);
    }

    // update the statement 
    const statementParams = {
      id: selectedStatement.id,
      vendorMapKey: manuallyMappedVendor.value||selectedStatement?.vendorMap?.qbVendor?.qbDisplayName?.toLowerCase()||vendorName?.toLowerCase(),
      vendorName: manuallyMappedVendor.value||selectedStatement?.vendorMap?.qbVendor?.qbDisplayName?.toLowerCase()||vendorName?.toLowerCase(),
      invoiceReceiptDate: updatedInvoiceReceiptDate,
      statementType: selectedStatementType?.value,
      statementTotal: values?.statementTotalInput,
      addedNotes: addedNotes,
      approvedById: userData.id,
      isApproved: true,
      statusTab: 'Archived',
      statusKey: 'Ready',
      categoryId: selectedExpenseCategory?.value,
    }

    console.log('statementParams: ', statementParams)

    if (selectedClient?.value) {
      statementParams.clientId = selectedClient?.value;
    }

    // const updateStatementResults = await updateStatementDocument(statementParams);
    // console.log('Update Statement Results:', updateStatementResults);
    
    onClose();
    
  };

  const [tokenObj, setTokenObj] = useState(null);
  useEffect(() => {
    if (tokenObj) {
      console.log('Token Object Updated On Child:', tokenObj);
    }
  }, [tokenObj]);

  const [ qbClasses, setQBClasses ] = useState([]);
  const [ selectedQBClass, setSelectedQBClass ] = useState('');
  useEffect(() => {
    const loadQBClasses = async (token, environment) => {
      if (token && environment && selectedDivision?.label) {
        const qbClasses = await getClasses(token, environment)
        console.log('qbClasses: ', qbClasses)
        const options = qbClasses?.map(qbclass => ({
          value: qbclass.Id,
          label: qbclass.Name,
        }));
        // This really needs a mapping table created that can be synced with the QB classes
        let selectedDivisionLabel = selectedDivision?.label;
        if (selectedDivisionLabel === 'B.I. Valero/Sunoco') {
          selectedDivisionLabel = 'Blue Island';
        }
        if (selectedDivisionLabel === 'OKC Office') {
          selectedDivisionLabel = 'Oklahoma';
        }
        if (selectedDivisionLabel === 'OKC Maintenance') {
          selectedDivisionLabel = 'OKC-Maintenance';
        }
        if (selectedDivisionLabel === 'ExxonMobil') {
          selectedDivisionLabel = 'Exxon/Mobil';
        }
        if (selectedDivisionLabel === 'Tennessee Valero') {
          selectedDivisionLabel = 'Memphis';
        }
        const matchedClass = options.find(option => option.label === selectedDivisionLabel);
        setSelectedQBClass(matchedClass);
        setQBClasses(options);
        setValue('selectClass', matchedClass.value);
        
      }
      return
    }

    loadQBClasses(tokenObj?.access_token, qbEnv)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tokenObj, qbEnv, selectedDivision])
      
  return (
    <QuickBooksAuth 
      qbAppId='ManageExpenses'
      qbEnv={qbEnv}
      onTokenChange={setTokenObj}
      >
    {({ isLoggedInQB, loginQB, logoutQB }) => (
      <Container as="form" onSubmit={handleSubmit(formSubmit, onError)}>
        <QBLoginLogoutButtons 
          isLoggedInQB={isLoggedInQB}
          loginQB={loginQB}
          logoutQB={logoutQB}
        />
        <Box paddingTop={'25px'}>
          <Flex alignItems="center" justifyContent="space-between" pb={'25px'}>
            <Box>
              <Text as="span" textStyle='heading-1'>Item Details {(isDuplicate) && ' - POSSIBLE DUPLICATE'}</Text>
            </Box>
            <Spacer />
            <Box>
              <Button
                type="button"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Box>
          </Flex>

          <Box paddingTop={'25px'}>
            {/* <Image 
              border={'1px solid var(--dark-unit-image-border)'} 
              src={statementImage} 
            /> */}
          </Box>

          <Box paddingTop={'25px'}>
            <Text>Vendor Name: {!vendorName ? 'No Vendor Name was extracted from statement. This will be a one-off match' : vendorName} {(vendorName && !vendorMapKey) && 'Needs to be mapped to existing QB'}</Text>
          </Box>

          {/* <Box paddingTop={'25px'}>
            <Text>Summary:</Text>
            {!vendorName && <Text>No vendor name was able to be extracted from the receipt. Could be new vendor or just missing vendor from the extraction. Requires mapping to existing or creating new.</Text>}
            {vendorName && !vendorMapKey && <Text>Vendor name was extracted but there was no current QB vendor mapped to this extracted vendor.</Text>}
            {vendorName && potentialVendorName?.vendorMap?.qbVendor?.qbDisplayName && 
              <Text>Vendor name was extracted but has not been mapped to an exsiting QB vendor but there was a potential candidate: {potentialVendorName?.vendorMap?.qbVendor?.qbDisplayName}</Text>}
            {!vendorName && potentialVendorName?.vendorMap?.qbVendor?.qbDisplayName && 
              <Text>Vendor name was NOT extracted but has not been mapped to an exsiting QB vendor but there was a potential candidate: {potentialVendorName?.vendorMap?.qbVendor?.qbDisplayName}</Text>}
            {potentialVendorName?.vendorMap?.qbVendor?.qbDisplayName && 
              <Text>potential candidate: {potentialVendorName?.vendorMap?.qbVendor?.qbDisplayName}</Text>}
            {vendorName && <Text>There will always be the potential that the extracted vendor name needs to be remapped to a different existing QB vendor.</Text>}
          </Box> */}

          {/* there is no vendor name extracted */}
          {!vendorName && !manuallyMappedVendor?.name && !selectedStatement?.vendorMap?.qbVendor?.qbDisplayName && <Box paddingTop={'25px'}>
            <HStack>
              {/* <Box>
                {
                  (!manuallyMappedVendor?.id && manuallyMappedVendor?.name) 
                ? 
                  <Text>Vendor will be created in QuickBooks: {manuallyMappedVendor.name}</Text>
                :
                  <Text>Vendor will be mapped to QuickBooks Vendor: {manuallyMappedVendor.id}: {manuallyMappedVendor?.name}</Text>
                }
              </Box> */}
              <Box>
                <Button
                  type="button"
                  onClick={() => handleOpenDrawer('map', {id: id, vendorName: vendorName})}
                  width="100%"
                >
                  Map to QB
                </Button>
              </Box>          
            </HStack>
          </Box>}

          {/* has been mapped to something */}
          {manuallyMappedVendor?.name && <Box paddingTop={'25px'}>
            <HStack>
              <Box>
                {
                  (!manuallyMappedVendor?.id && manuallyMappedVendor?.name) 
                ? 
                  <Text>Vendor will be created in QuickBooks: {manuallyMappedVendor.name}</Text>
                :
                  <Text>Vendor {vendorName} will be mapped to QuickBooks Vendor: {manuallyMappedVendor.id} -  {manuallyMappedVendor?.name}</Text>
                }
              </Box>
              <Box>
                <Button
                  type="button"
                  onClick={() => handleOpenDrawer('map', {id: id, vendorName: vendorName})}
                  width="100%"
                >
                  Map to QB
                </Button>
              </Box>          
            </HStack>
          </Box>}

          {/* has been mapped to existing qb vendor */}
          {vendorName && !manuallyMappedVendor?.name && !selectedStatement?.vendorMap?.qbVendorId && <Box paddingTop={'25px'}>
            <HStack>
              <Box>
                <Text>Vendor {vendorName} is not connected to a QB Vendor: {selectedStatement?.vendorMap?.qbVendorId} - {selectedStatement?.vendorMap?.qbVendor?.qbDisplayName}</Text>
              </Box>
              <Box>
                <Button
                  type="button"
                  onClick={() => handleOpenDrawer('map', {id: id, vendorName: vendorName})}
                  width="100%"
                >
                  Connect to QB Vendor
                </Button>
              </Box>
            </HStack>
          </Box>}


          {/* has been mapped to existing qb vendor */}
          {selectedStatement?.vendorMap?.qbVendor?.qbDisplayName && <Box paddingTop={'25px'}>
            <HStack>
              <Box>
                <Text>Vendor {vendorName} is currently mapped to QB Vendor: {selectedStatement?.vendorMap?.qbVendorId} - {selectedStatement?.vendorMap?.qbVendor?.qbDisplayName}</Text>
              </Box>
              <Box>
                <Button
                  type="button"
                  onClick={() => handleOpenDrawer('map', {id: id, vendorName: vendorName})}
                  width="100%"
                >
                  Remap
                </Button>
              </Box>
            </HStack>
          </Box>}

          {/* {vendorName && potentialVendorName?.vendorMap?.qbVendor?.qbDisplayName && <Box paddingTop={'25px'}>
            <Text>Potential Vendor Match: {potentialVendorName?.vendorMap?.qbVendor?.qbDisplayName}</Text>
            <Button
              type="button"
              onClick={() => mapVendorToQuickBooks(vendorName, potentialVendorName?.vendorMap?.qbVendor?.qbId)}
              width="100%"
            >
              QuickMap to QuickBooks
            </Button>
          </Box>} */}

          <Box paddingTop={'25px'}>
            <Text>Notes: </Text><Text>{addedNotes}</Text>
          </Box>

          {/* <Box paddingTop={'25px'}>
            <TextInput
              autoComplete="off"
              register={register}
              errors={errors}
              fieldtype="text"
              fieldname="amountPaidInput"
              fieldlabel="Amount Paid"
              prettyname="Amount Paid"
              fieldvalue={amountPaid}
              placeholder="Enter amount paid"
              isRequired={true}
              onChange={handleAmountPaidChanged}
            />
          </Box> */}

          <Box paddingTop={'25px'}>
            <TextInput
              autoComplete="off"
              register={register}
              errors={errors}
              fieldtype="text"
              fieldname="statementTotalInput"
              fieldlabel="Statement Total"
              prettyname="Statement Total"
              defaultValue={fixedTotal}
              placeholder="Enter total amount"
              isRequired={true}
              onChange={handleStatementTotalChanged}
            />
          </Box>

          <Box paddingTop={'25px'}>
            <TextareaInput
              register={register}
              errors={errors}
              // rules={{ required: "Required" }}
              fieldname="expenseNotes"
              fieldlabel="Expense Notes"
              prettyname="Expense Notes"
              placeholder="Enter any notes about this expense"
              onChange={handleAddedNotesChanged}
              defaultvalue={addedNotes}
              // onBlur={handleJobDescriptionChange}
              // isRequired
            />
          </Box>

          <Box paddingTop={'25px'}>
            <Text>Submitted Date: {dateTimeToAWSDate(submittedDate)}</Text>
          </Box>

          <Box paddingTop={'25px'}>
            <Text>Invoice Receipt Date: {awsFormatUnknownDate(invoiceReceiptDate || '')} {(invoiceReceiptDate) ? spellOutDate((awsFormatUnknownDate(invoiceReceiptDate || '')), 'apple-fy') : 'Empty Date'}</Text>
            <Box>
              <TextInput
                register={register}
                errors={errors}
                fieldname="invoiceReceiptDateSubmitted"
                fieldlabel="Invoice/Receipt Date"
                prettyname="Invoice/Receipt Date"
                onChange={event => setInvoiceReceiptDateSubmitted(event.target.value)}
                isRequired={true} 
                fieldtype='date'
                defaultValue={awsFormatUnknownDate(invoiceReceiptDate || '') || todaysDateYMD()}
              />
            </Box>
          </Box>

          <Box paddingTop={'25px'}>
            <Text>Expensed by: {teamMember?.firstName} {(teamMember.goesBy) && `"${teamMember.goesBy}"`} {(teamMember?.middleName) && teamMember?.middleName} {teamMember?.lastName}
            </Text>
          </Box>

          <Box paddingTop={'25px'}>
            <FormSelectSimple
              key='Division'
              register={register}
              control={control}
              errors={errors}
              isRequired={true}
              rules={{ required: "Division is required" }}
              fieldname="selectDivision"
              fieldlabel="Team Members Division"
              placeholder={'Select division'}
              optionsArray={divisionOptions?.map((division, index) => ({
                key: index,
                value: division.value,
                label: division.label
              }))}
              onChange={updateSelectedDivision}
              selectedoption={selectedDivision || ''}
              issearchable={false}
              isDisabled={selectedDivision || false}
            />
          </Box>

          <Box paddingTop={'25px'}>
            <FormSelectSimple
              key='Class'
              register={register}
              control={control}
              errors={errors}
              isRequired={true}
              rules={{ required: "Class is required" }}
              fieldname="selectClass"
              fieldlabel="QuickBooks Class"
              placeholder={'Select class'}
              optionsArray={qbClasses?.map((qbclass, index) => ({
                key: index,
                value: qbclass.value,
                label: qbclass.label
              }))}
              onChange={updateSelectedClass}
              selectedoption={selectedQBClass || ''}
              issearchable={false}
            />
          </Box>

          <Box paddingTop={'25px'}>
            <FormSelectSimple
              key='Card'
              register={register}
              control={control}
              errors={errors}
              isRequired={true}
              rules={{ required: "Team Member Card is required" }}
              fieldname="selectTeamMemberCard"
              fieldlabel="Team Member Card"
              placeholder={'Select card account'}
              optionsArray={teamMemberCards?.map((card, index) => ({
                key: index,
                value: card.value,
                label: card.label
              }))}
              onChange={updateSelectedTeamMemberCard}
              selectedoption={selectedTeamMemberCard || ''}
              issearchable={false}
            />
          </Box>

          {isIFTATracked ? <>
            <Box paddingTop={'25px'}>
              <FormSelectSimple
                register={register}
                control={control}
                errors={errors}
                isRequired={true}
                rules={{ required: "Expense Category is required" }}
                onChange={e => updateSelectedExpenseCategory(e)}
                selectedoption={selectedExpenseCategory||''}
                optionsArray={expenseCategories}
                fieldname='selectExpenseCategory'
                prettyname='Expense Category'
                fieldlabel="Expense Category"
                placeholder={'Select expense category'}
                issearchable={false}
              />
            </Box>

            {/* These can be disabled form fields or just labels. */}
            {/* These will get created in the tracking table now that they have been approved. */}
            <Box paddingTop={'25px'}>
              <TextInput
                register={register}
                errors={errors}
                fieldname="otrUnit"
                fieldlabel="OTR Unit"
                fieldvalue={iftaFuelTaxTrack?.unit?.code+' - '+iftaFuelTaxTrack?.unit?.type?.name+' / '+iftaFuelTaxTrack?.unit?.subType?.name}
                isDisabled={true}
              />
            </Box>

            <Box paddingTop={'25px'}>
              <TextInput
                register={register}
                errors={errors}
                fieldname="otrUnitOdometer"
                fieldlabel="Current OTR Unit Odometer"
                fieldvalue={iftaFuelTaxTrack?.unit?.odometer}
                isDisabled={true}
              />
            </Box>

            <Box paddingTop={'25px'}>
              <TextInput
                register={register}
                errors={errors}
                fieldtype="tel"
                fieldname="odometer"
                fieldlabel="Odometer"
                prettyname="Odometer"
                fieldvalue={iftaFuelTaxTrack?.odometer}
                isDisabled={true}
              />
            </Box>

            <Box paddingTop={'25px'}>
              <TextInput
                register={register}
                errors={errors}
                fieldtype="tel"
                fieldname="gallons"
                fieldlabel="Gallons"
                prettyname="Gallons"
                fieldvalue={iftaFuelTaxTrack?.gallons}
                isDisabled={true}
              />
            </Box>

            <Box paddingTop={'25px'}>
              <TextInput
                register={register}
                errors={errors}
                fieldname="stateTwoChar"
                fieldlabel="State"
                prettyname="State"
                fieldvalue={iftaFuelTaxTrack?.stateTwoChar?.toUpperCase()}
                isDisabled={true}
              />
            </Box>

          </>
          
          : <Box paddingTop={'25px'}>
              <FormSelectSimple
                register={register}
                control={control}
                errors={errors}
                isRequired={true}
                rules={{ required: "Expense Category is required" }}
                onChange={e => updateSelectedExpenseCategory(e)}
                selectedoption={selectedExpenseCategory||''}
                optionsArray={expenseCategories}
                fieldname='selectExpenseCategory'
                prettyname='Expense Category'
                fieldlabel="Expense Category"
                placeholder={'Select expense category'}
                issearchable={false}
              />
            </Box>}

          <Box paddingTop={'25px'}>
            <FormSelectSimple
              key='Client'
              register={register}
              control={control}
              errors={errors}
              // isRequired={true}
              fieldname="selectClient"
              fieldlabel="Client"
              placeholder={'Select client'}
              optionsArray={clients}
              onChange={updateSelectedClient}
              selectedoption={selectedClient}
              issearchable={false}
            />
          </Box>

          <Box paddingTop={'25px'}>
            <FormSelectSimple
              key='Statement Type'
              register={register}
              control={control}
              errors={errors}
              // isRequired={true}
              fieldname="selectStatementType"
              fieldlabel="Statement Type"
              placeholder={'Select type'}
              optionsArray={statementTypeOptions}
              onChange={handleStatementTypeChange}
              selectedoption={selectedStatementType}
              issearchable={false}
            />
          </Box>

          <Box paddingTop={'25px'} display="flex" alignItems="center">
            <Box paddingRight={'25px'}><ButtonPrimaryPlain 
              width={'300px'}
              type="submit"
              onClick={handleSubmit(formSubmit, onError)}
              name='submit'
              value={isSubmitting ? 'Approving...' : 'Approve'}
              // isDisabled={(vendorSelected===false) || (isSubmitting===true)}
              isDisabled={(isSubmitting===true)}
            /></Box>

            {(isDuplicate) && <Box>
              <ButtonPrimaryPlain 
                width={'300px'}
                type="button"
                onClick={deleteDuplicate}
                name='delete'
                value={'Delete Duplicate'}
                // isDisabled={(vendorSelected===false) || (isSubmitting===true)}
                // isDisabled={(isSubmitting===true)}
              /></Box>}
          </Box>
        </Box>

        <ModuleDrawer
          onClose={onVendorMapClose}
          isOpen={isVendorMapOpen}
          bodyContent={<VendorMap handleMapVendor={mapQBVendorToStatementVendor} screenWidth={screenWidth} selectedStatement={selectedStatement} userData={userData} onClose={onVendorMapClose}/>}
          size={'full'}
        />

        <Box height={'50px'}></Box>
      </Container>
    )}
  </QuickBooksAuth>
  );
};

