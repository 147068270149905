import { createSpkPrimaryUnitStatus } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const addPrimaryUnitStatus = async (props) => {
  if (!props) return;

  try {
    console.log('addPrimaryUnitStatus props: ', props);

    const gqlParams = {
      input: props
    };

    const response = await client.graphql({
      query: createSpkPrimaryUnitStatus,
      variables: gqlParams
    });

    console.log('addPrimaryUnitStatus response: ', response);
    const results = response.data.createSpkPrimaryUnitStatusTbl.code;
    console.log('addPrimaryUnitStatus results: ', results);

    return results;
  } catch (err) {
    return ('error addPrimaryUnitStatus: ', [err]);
  }
};

addPrimaryUnitStatus.propTypes = {
  props: PropTypes.object.isRequired,
};
