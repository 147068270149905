import { useNavigate } from 'react-router-dom';
// import { useContext } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
// import { AppContext } from '../../AppContext'
import {
  Popover, PopoverTrigger, PopoverContent, PopoverArrow, 
  PopoverBody,  PopoverHeader,  PopoverCloseButton,
  Button,
  Avatar, 
  // AvatarBadge,
  // Menu, MenuButton, MenuItem, MenuList
} from "@chakra-ui/react";
// import { ChevronDownIcon } from '@chakra-ui/icons';

import PropTypes from 'prop-types';

export const AvatarNoPopover = (props) => {
  const imageSrc = props.imgSrc
  const name = props.name
  // const {store, actions} = useContext(AppContext);

  // const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  
  const { 
    // route, 
    signOut, 
    // user 
  } = useAuthenticator((context) => [
    // context.route,
    context.signOut,
    // context.user
  ]);
  const navigate = useNavigate();

  function logOut() {
    // store.setStore({})
    signOut();
    navigate('/login');
  }

  return (
    <Popover placement='bottom-end'>
      <PopoverTrigger>
        <Avatar mx='5px' bg='black' color='var(--dark-gold-primary)' size='md' name={name} src={imageSrc}>
          {/* <AvatarBadge boxSize='1em' bg='green.500' /> */}
        </Avatar>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight='semibold'>Popover placement</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
        <Button onClick={() => logOut()}>Logout</Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

//   return (
//     <Popover placement='bottom-end'>
//       <PopoverTrigger>
//         <Avatar mx='5px' bg='yellow.500' size='md' name={name} src={imageSrc}>
//           {/* <AvatarBadge boxSize='1em' bg='green.500' /> */}
//         </Avatar>
//       </PopoverTrigger>
//       <PopoverContent>
//         <PopoverHeader fontWeight='semibold'>Popover placement</PopoverHeader>
//         <PopoverArrow />
//         <PopoverCloseButton />
//         <PopoverBody>
//         <Button onClick={() => logOut()}>Logout</Button>
//         </PopoverBody>
//       </PopoverContent>
//     </Popover>
//   )
// }

// <Menu>
//   <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
//     Actions
//   </MenuButton>
//   <MenuList>
//     <MenuItem>Download</MenuItem>
//     <MenuItem>Create a Copy</MenuItem>
//     <MenuItem>Mark as Draft</MenuItem>
//     <MenuItem>Delete</MenuItem>
//     <MenuItem>Attend a Workshop</MenuItem>
//   </MenuList>
// </Menu>

AvatarNoPopover.propTypes = {
  imgSrc: PropTypes.node,
  name: PropTypes.node,
};