import { createSpikeClient } from '../../../graphql/custom_mutations';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const addBillableClient = async (props) => {
  if (!props) return;

  try {
    const response = await client.graphql({
      query: createSpikeClient,
      variables: { input: props },
    });

    const results = response?.data?.createSpkDJTClientTbl?.id;
    return results;
  } catch (err) {
    console.error('error addBillableClient: ', err);
    return null;
  }
};
