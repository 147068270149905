import { listAllPrimaryUnits } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const fetchAllUnits = async (documentType, limit = 250) => {
  try {
    const gqlParams = {
      allSort: "all",
      limit: limit
    };

    const response = await client.graphql({
      query: listAllPrimaryUnits,
      variables: gqlParams
    });

    const results = response.data.allPrimaryUnits.items
      .sort((a, b) => (a.code > b.code ? 1 : -1));

    return results;
  } catch (err) {
    return ('error fetchAllUnits: ', [err]);
  }
};

fetchAllUnits.propTypes = {
  documentType: PropTypes.string.isRequired,
  limit: PropTypes.number
};
