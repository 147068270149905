import { listDocumentsByUnitIdUnitDocumentTypeId } from '../../../graphql/custom_queries';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchUnitDocumentsByUnitIdDocumentTypeId = async (unitIdTypeId) => {
  if (!unitIdTypeId) return;

  try {
    const response = await client.graphql({
      query: listDocumentsByUnitIdUnitDocumentTypeId,
      variables: {
        unitIdUnitDocumentTypeId: unitIdTypeId,
        sortDirection: 'DESC',
      },
    });

    const results = response.data.unitDocumentsByUnitIdUnitDocumentTypeId.items;

    // Optional sorting by name or description, commented out for now
    // const results = response.data.unitDocumentsByUnitIdUnitDocumentTypeId.items.sort((a, b) =>
    //   a.name > b.name ? 1 : -1
    // );
    // const results = response.data.unitDocumentsByUnitIdUnitDocumentTypeId.items.sort((a, b) =>
    //   a.description > b.description ? 1 : -1
    // );

    return results;
  } catch (err) {
    return ('error fetchUnitDocumentsByUnitIdDocumentTypeId: ', [err]);
  }
}
