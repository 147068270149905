/* eslint-disable react/prop-types */
// import React from 'react';
import {
  Text,
  Box,
} from '@chakra-ui/react';

import { useScreenSize } from '../custom-hooks/useScreenSize.jsx';

import { PropTypes } from 'prop-types';

import { UnitInspectionsScrollableBox } from '../reactComponents/UnitInspectionsScrollableBox.jsx';
// import { awsFormatUnknownDate, todaysDateYMD } from '../functions/dateTime.jsx';

export const InspectedUnits = (props) => {
  const {
    unitsInspectedToday,
    userIsMobile,
  } = props;
  
  const screenSize = useScreenSize();
  const screenHeight = screenSize.height;
  const calculatedHeight = screenHeight - 200;
  const resultsBoxHeight = Math.max(calculatedHeight, 250) + 'px';
  const boxPaddingX = '10px';
  // const boxHeight = userIsMobile ? resultsBoxHeight : '650px';
  const boxHeight = userIsMobile ? resultsBoxHeight : 'auto';

  return (
  <>
    <Box 
      mb={'25px'} 
      w={'100%'} 
      px={boxPaddingX} >
      <Box>
        <Box paddingBottom={'10px'}>
          <Text as="span" textStyle="heading-3">Inspected Units</Text>
        </Box>
        <UnitInspectionsScrollableBox
          // items={unitsData}
          items={unitsInspectedToday||[]}
          width={'100%'}
          // height={userIsMobile && resultsBoxHeight}
          height={boxHeight}
          maxHeight={'650px'}
          // handleSelectedObject={loadUnitInspection}
        />
      </Box>
    </Box>

    </>
  )
}

InspectedUnits.propTypes = {
  unitsInspectedToday: PropTypes.array.isRequired,
  userIsMobile: PropTypes.bool,
};