import { allPrimaryUnitsForInspection } from '../../../graphql/custom_queries';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const fetchAllUnitsForInspection = async () => {
  try {
    const gqlParams = { allSort: 'all', limit: 999 };

    const response = await client.graphql({
      query: allPrimaryUnitsForInspection,
      variables: gqlParams,
    });

    const results = response.data.allPrimaryUnits.items;
    console.warn('RESULTS FOR INSPECTABLE UNITS: ', results);
    return results;
  } catch (err) {
    return 'error fetchAllUnitsForInspection: ' + err;
  }
};
