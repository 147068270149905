import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

export const Divider = ({ h = '3px', bg = 'black', ...props }) => {
  return (
    <Box h={h} bg={bg} {...props}></Box>
  );
};

// Define propTypes for the Divider component
Divider.propTypes = {
  h: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  bg: PropTypes.string,
};