import React, { useEffect, useState, useCallback, useContext, Fragment } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import {
  Container,
  Box,
  Text,
  Flex,
  Spacer,
  Center,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  HStack,
  Tooltip,
  useDisclosure,
  IconButton,
  useBreakpointValue,
  TableContainer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

// decorative components
import { Divider } from '../Divider.jsx';

// modals
import { CenteredStatusProgressModal } from '../Modal/CenteredStatusProgressModal.jsx';

// buttons
import { ButtonSenaryWithIconOnly } from '../Form/Button/ButtonSenaryWithIconOnly.jsx';
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';
import { ButtonSecondaryPlain } from '../Form/Button/ButtonSecondaryPlain.jsx';

// graphql
import { fetchDJTsByStatusId } from '../graphqlCompnents/DJT/fetchDJTsByStatusId.jsx';
import { getStatusIdByStatusCode } from '../graphqlCompnents/DJT/getStatusIdByStatusCode.jsx';
import { fetchDailyJobTicketByIdForApproval } from '../graphqlCompnents/DJT/fetchDailyJobTicketByIdForApproval.jsx';
import { updateDJTStatus } from '../graphqlCompnents/DJT/updateDJTStatus.jsx';
import { updateDailyJobTicket } from '../graphqlCompnents/DJT/updateDailyJobTicket.jsx';

// icons
import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// structural
import { ModuleBox } from '../Structural/ModuleBox.jsx';
import { ModuleDrawer } from '../Structural/ModuleDrawer.jsx';

// components
import { ViewDailyJobTicket } from '../DailyJobTicket/ViewDailyJobTicket.jsx';

// form components
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
import { TextInput } from '../Form/Input/TextInput.jsx';
import { TextareaInput } from '../Form/Input/TextareaInput.jsx';
import { FormSwitch } from '../Form/Switch/FormSwitch.jsx';

// Lambdas
import { getCustomers } from '../QuickBooks/getCustomers.jsx';
import { getCustomer } from '../QuickBooks/getCustomer.jsx';
import { getTerms } from '../QuickBooks/getTerms.jsx';
import { getItems } from '../QuickBooks/getItems.jsx';
import { getClasses } from '../QuickBooks/getClasses.jsx';
// import { getRecentInvoices } from '../QuickBooks/getRecentInvoices.jsx';

// uuid
import { v4 as uuidv4 } from 'uuid';

// generic functions
import { todaysDateYMD, spellOutDate, netDateDueMutator } from '../functions/dateTime.jsx';
import { truncateString } from '../functions/strings.jsx';
import { convertCurrencyToDouble } from '../functions/currency.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

// PDF
import { generatePdf } from '../PDF/GeneratePDF.jsx';
import { getIdentityId } from '../AWS/cognitoIdentityService.jsx';

// QuickBooks
import { QuickBooksAuth } from '../QuickBooks/QuickBooksAuth.jsx';
import { QBLoginLogoutButtons } from '../QuickBooks/QBLoginLogoutButtons.jsx';
// import { a } from 'aws-amplify';

//  proip-types validation
import PropTypes from 'prop-types';

export const ViewApproveDJT = () => {
  
  // grab global context values
  const { store } = useContext(AppContext);
  // quickbooks environment, either sandbox or production
  const qbEnv = store?.qbEnvironment;

  const [identityId, setIdentityId] = useState(null);

  useEffect(() => {
    const fetchIdentityId = async () => {
      try {
        const id = await getIdentityId();
        setIdentityId(id);
      } catch (error) {
        console.error("Error fetching identity ID:", error);
      }
    };

    fetchIdentityId();
  }, []);
  
  const [tokenObj, setTokenObj] = useState(null);
  // useEffect(() => {
  //   if (tokenObj!==null) {
  //     // console.warn(' ----- tokenObj now set: ', tokenObj)
  //   } else {
  //     // console.warn(' ----- tokenObj could be anything: ', tokenObj)
  //     handleInvoiceReset()
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[tokenObj])

  // updated useEffect for token
  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    if (storedToken) {
      setTokenObj(JSON.parse(storedToken));  // Load token from local storage on component mount
      console.log('current token: ',storedToken )
    }
  }, []);

  
  // ----- VIEW DJT DRAWER -----
  const { isOpen: isViewDjtOpen , onOpen: onViewDjtOpen, onClose: onViewDjtClose } = useDisclosure()

  // ----- MODAL -----
  const [ statusModalCloseDisabled, setStatsuModalCloseDisabled ] = useState(true)
  const [ statusProgress, setStatusProgress ] = useState(0)
  const [ statusModalContent, setStatusModalContent ] = useState('')
  const [ successMessage, setSuccessMessage ] = useState('')
  function updateStatusModal(progress, status) {
    setStatusProgress(progress)
    setStatusModalContent(status)
  }

  const { isOpen: isStatusModalOpen , onOpen: onStatusModalOpen, onClose: onStatusModalClose } = useDisclosure()
  
  function handleStatusModalClosed() {
    onStatusModalClose()
  }

  // ----- END MODAL -----

  // const [ availableDJTs, setAvailableDJTs ] = useState(null)
  // const [ currentDJTId, setCurrentDJTId ] = useState(null)
  const [ currentDJT, setCurrentDJT ] = useState(null)
  // const [ attachedFile, setAttachedFile ] = useState(null)
  const [ classes, setClasses ] = useState(null)
  const [ attachFilesChecked, setAttachFilesChecked ] = useState(false)

  // useEffect(() => {
  //   (customers) && console.warn(' ----- customers changed: ', customers)
  // },[customers])
  // const [ customer, setCustomer ] = useState(null)
  const [ selectedCustomer, setSelectedCustomer ] = useState(null)
  const [ selectedClass, setSelectedClass ] = useState(null)
  // useEffect(() => {
  //   (selectedClass) && console.warn(' ----- selectedClass changed: ', selectedClass)
  // },[selectedClass])
  const [ attachableObjects, setAttachableObjects ] = useState([])
  // useEffect(() => {
  //   (attachableObjects) && console.warn(' ----- attachableObjects changed: ', attachableObjects)
  // },[attachableObjects])
  const [ invoiceDate, setInvoiceDate ] = useState(todaysDateYMD())
  const [ invoiceDueDate, setInvoiceDueDate ] = useState(todaysDateYMD())
  const [ terms, setTerms ] = useState([])
  const [ selectedTerm, setSelectedTerm ] = useState('')
  const [ laborItemRefId, setLaborItemRefId ] = useState(null)
  const [ equipmentItemRefId, setEquipmentItemRefId ] = useState(null)
  const [ materialItemRefId, setMaterialItemRefId ] = useState(null)
  // const [ companyDJTs, setCompanyDJTs ] = useState(null)

  // const [ disabledButton, setDisabledButton ] = useState([])

  const CustomCloseDrawerButton = (type) => {
    let closeDrawer = null
    if (type==='view') {
      closeDrawer = onViewDjtClose
    }

    return(
      <IconButton 
        variant={'icononlybutton'}
        icon={<AvailableIcons boxSize={'22px'} iconRef={'close'} />} 
        onClick={closeDrawer}
      />
    )
  }

  // const [ statusId, setStatusId ] = useState(null)
  const [ pendingResponseStatusId, setPendingResponseStatusId ] = useState(null)
  const [ approvedStatusId, setApprovedStatusId ] = useState(null)
  const [ unapprovedStatusId, setUnapprovedStatusId ] = useState(null)
  const [ invoicedStatusId, setInvoicedStatusId ] = useState(null)

  // character length of client and description text
  const clientTruncateValue = useBreakpointValue({
    sm: 10,
    md: 15,
    lg: 35,
    xl: 45,
    '2xl': 60
  })

  const descriptionTruncateValue = useBreakpointValue({
    sm: 10,
    md: 14,
    lg: 35,
    xl: 45,
    '2xl': 60
  })

  const searchHeaderTextClass = useBreakpointValue({
    sm: 'dark-sfpro-heading-2',
    md: 'dark-sfpro-heading-2',
    lg: 'dark-sfpro-heading-1',
    xl: 'dark-sfpro-heading-1',
    '2xl': 'dark-sfpro-heading-1'
  })


  useEffect(() => {
    const getStatusId = async () => {
      const statusId =  await getStatusIdByStatusCode('invoiced-via-quickbooks-native');
      return statusId
    }
    getStatusId();
    // getStatusId().then((id) => {
    //   // setNativeStatusId(id)
    //   // console.log('id: ', id)
    // })
  }, [])

  useEffect(() => {
    const getStatusId = async () => {
      const statusId =  await getStatusIdByStatusCode('invoiced-via-quickbooks');
      return statusId
    }
    getStatusId().then((id) => {
      setInvoicedStatusId(id)
    })
  }, [])

  useEffect(() => {
    const getStatusId = async () => {
      const statusId =  await getStatusIdByStatusCode('queued-pending-approval');
      return statusId
    }
    getStatusId().then((id) => {
      setUnapprovedStatusId(id)
    })
  }, [])

  const [ djtId, setDjtId ] = useState(null)
  const handleOpenDrawer = (type, id) => {
    // id of djt to view, edit or duplicate
    // type of action: new djt, duplicate djt, view djt, edit djt
    if (id) {
      if (type==='view') {
        setDjtId(id, onViewDjtOpen())
      }
    }
  }

  const [ isRejectDJTVisible, setIsRejectDJTVisible ] = useState(false)
  const [ isInvoiceDJTVisible, setIsInvoiceDJTVisible ] = useState(false)
  // useEffect(() => {
  //   (isInvoiceDJTVisible) && console.warn(' ----- isInvoiceDJTVisible changed: ', isInvoiceDJTVisible)
  // },[isInvoiceDJTVisible])

  // const [ isInvoiceDJTLocked, setIsInvoiceDJTLocked ] = useState(false)

  // const [ rejectedDjtId, setRejectedDjtId ] = useState(null)
  const showRejection = (id, index) => {
    // console.log('unapprovedDJTs[index]: ', unapprovedDJTs[index])
    setCurrentDJT(unapprovedDJTs[index])
    setIsRejectDJTVisible(true)
    setIsInvoiceDJTVisible(false)
    // setRejectedDjtId(id)
    setDisableUnapprovedTable(false)
  }

  // const [ approvedDjtId, setApprovedDjtId ] = useState(null)
  const [ djtClientCompanyId, setDjtClientCompanyId ] = useState(null)
  const [ djtClientCompanyName, setDjtClientCompanyName ] = useState(null)
  const [ djtClientComtactCompanyName, setDjtClientContactCompanyName ] = useState(null)
  const [ djtClientCompanyEmail, setDjtClientCompanyEmail ] = useState(null)
  const [ djtClientNeedsToSync, setDjtClientNeedsToSync ] = useState(false)
  const [ isFormLoaded, setIsFormLoaded ] = useState(false)
  const [ isMenuDisabled, setIsMenuDisabled ] = useState(false)
  const [ firstDjtLoaded, setFirstDjtLoaded ] = useState(null)

  const showApproval = async (id, index, token, environment, identityId) => {
    onStatusModalOpen();
    updateStatusModal(10, 'Loading invoice')

    // console.warn(' ----- RUNNING index: ', index)
    // console.warn(' ----- RUNNING showApproval: ', token)
    // console.warn(' ----- RUNNING showApproval: ', environment)
    // console.warn(' ----- RUNNING showApproval: ', unapprovedDJTs[index])
    // console.warn(' ----- RUNNING identityId: ', identityId)

    setIsFormLoaded(false)
    setIsMenuDisabled(true)
    if (!firstDjtLoaded) {
      // console.warn('LOADING DATA FOR FIRST INVOICE: ', unapprovedDJTs[index])
      setFirstDjtLoaded(unapprovedDJTs[index])
    }
    await loadDJTForQB(unapprovedDJTs[index])

    updateStatusModal(20, 'Fetching invoice data...')

    let qbContactCompanyName = unapprovedDJTs[index].djtClient?.contactCompany||''
    setDjtClientContactCompanyName(qbContactCompanyName)
    let qbCompanyName = unapprovedDJTs[index].djtClient?.qbCompany||''
    let qbCompanyEmail = unapprovedDJTs[index].djtClient?.qbEmail||''
    setDjtClientCompanyName(qbCompanyName)
    setDjtClientCompanyEmail(qbCompanyEmail)
    let qbCompanyId = ''
    if (environment==='sandbox') {
      qbCompanyId = unapprovedDJTs[index].djtClient?.qbSandboxId||''
    } else {
      qbCompanyId = unapprovedDJTs[index].djtClient?.qbId||''
    }
    if (qbCompanyName==='' || qbCompanyId==='' || qbCompanyEmail==='') {
      setDjtClientNeedsToSync(true)
    }
    setDjtClientCompanyId(qbCompanyId)
    // console.warn(' ----- unapprovedDJTs[index]: ', unapprovedDJTs[index])

    updateStatusModal(30, 'Generating PDF for QuickBooks...')

    await approveThisDJT(unapprovedDJTs[index], tokenObj, qbEnv, identityId)

    updateStatusModal(40, 'Loading QuickBooks customers...')

    await loadQBCustomers(tokenObj, environment)
    
    updateStatusModal(50, 'Loading QuickBooks classes...')
    
    await loadQBClasses(tokenObj, environment);

    updateStatusModal(90, 'Wrapping up...')
    setStatsuModalCloseDisabled(false)
    updateStatusModal(100, 'Invoice loaded')
    
    setCurrentDJT(unapprovedDJTs[index])
    // console.warn(' ----- FIND UNDEFINED: ', unapprovedDJTs[index])
    setIsRejectDJTVisible(false)
    setIsInvoiceDJTVisible(true)
    // setApprovedDjtId(id)
    setDisableUnapprovedTable(false)
    setIsFormLoaded(true)
    setIsMenuDisabled(false)
    // console.warn('should be all loaded')
    setSuccessMessage('Your invoice has been loaded.')
    
  }

  const disableToolbar = (djtId) => {
    setDisabledToolbars(prev => ({...prev, [djtId]: true}));
  };

  const enableToolbar = (djtId) => {
    setDisabledToolbars(prev => ({...prev, [djtId]: false}));
  };

  // async function fetchContact(djtClientContactId) {
  //   const contact = await fetchClientContactByClientContactId(djtClientContactId);
  //   console.warn(' --- contact: ', contact)
  //   if (contact?.phone !== '' && contact?.phone !== null) {
  //       contact.phone = formatToPhoneData(contact.phone);
  //   }
  //   return contact
  // }

  

  // FILE UPLOAD TO CLEAN UP
  // handle draggable
  //https://www.freecodecamp.org/news/reactjs-implement-drag-and-drop-feature-without-using-external-libraries-ad8994429f1a/

  // drag state
  
  // const [dragActive, setDragActive] = useState(false);

  //ref
  // const inputRef = useRef(null);
  
  // handle drag events
  // const handleDrag = function(e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (e.type === "dragenter" || e.type === "dragover") {
  //     setDragActive(true);
  //   } else if (e.type === "dragleave") {
  //     setDragActive(false);
  //   }
  // };

  // const onButtonClick = () => {
  //   inputRef?.current?.click();
  // };
  
  // this is no longer being used since we have auto upload working. Need to know if still needed.
  // const handleChange = async (e) => {
  //   console.warn('----- RUNNING handleChange: ', e.target.files)
  //   e.preventDefault();
  //   if (e.target.files && e.target.files[0]) {
  //     // at least one file has been selected so do something
  //     // handleFiles(e.target.files);
  //     //setFileToUpload(e.target.files);
  //     // console.log('handleChange', e.target.files[0])
  //     const params = {file: e.target.files[0], bucket: 'qb-invoice-attachments'}
  //     console.warn('before sending this is file object: ', e.target.files[0])
  //     const result = await uploadFileToS3(params);
  //     setAttachedFile(result)
  //     console.warn('should be result needed: ', result)
  //     // setAttachedFile(result.fileName)
  //     // setValue('attachedFile', result.fileName+' '+result.key+' '+result.identityId)
  //     setValue('attachedFile', result.fileName)

  //     // now push to QB
  //     // fileName='+attachedFile.fileName+'&fileKey='+attachedFile.key+'&identityId='+attachedFile.identityId+'&token='+tokenObj?.access_token
  //     //[Log] should be params needed:  – {fileName: "DJT Land O Lakes Purina 2.pdf", identityId: "us-east-1:9edb29c0-de0b-4722-be60-96601fba90c0", key: "87444edb-ece5-4601-a12b-5deb7e0ccd66.pdf"} (bundle.js, line 30186)
  //     // fileName, fileKey, identityId, access_token
  //     const qbResults = await pushFileToQB(result.fileName, result.key, result.identityId, tokenObj.access_token, qbEnv)
  //     setAttachableObjects(attachableObjects => [...attachableObjects, qbResults])
  //     console.log('qbResults: ', qbResults)
  //   }
  // }

  const { control, register, handleSubmit, clearErrors, setValue, getValues, formState: { errors } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {}
  });

  const {
    fields: djtFields,
    append: djtAppend,
    remove: djtRemove,
  } = useFieldArray({ control, name: "djtrow" });

  const onError = (errors, e) => console.log(errors, e);

  // get djts with statusId ...
  // const djts =  useQuery(['fetchDJTsByDivisionId', currentDivisionId], () => fetchDJTsByDivisionId(currentDivisionId), {
  //   enabled: !!currentDivisionId, refetchOnWindowFocus: false
  // });

  const [ unapprovedDJTs, setUnapprovedDJTs ] = useState(null)
  const [ refreshUnapprovedDJTs, setRefreshUnapprovedDJTs ] = useState(null)
  const [ selectedClientId, setSelectedClientId ] = useState(null)
  const [ selectedPO, setSelectedPO ] = useState(null)
  const [disabledToolbars, setDisabledToolbars] = useState({});
  // useEffect(() => {
  //   (disabledToolbars) && console.warn(' ----- disabledToolbars changed: ', disabledToolbars)
  // },[disabledToolbars])

  // this also needs to filter by po.
  const [ disableUnapprovedTable, setDisableUnapprovedTable ] = useState(false)
  // useEffect(() => {
  //   (disableUnapprovedTable) && console.warn(' ----- disableUnapprovedTable changed: ', disableUnapprovedTable)
  // },[disableUnapprovedTable])
  
  useEffect(() => {
    function groupAndSort(arr) {
      const groupedByPo = arr.reduce((acc, obj) => {
        // Use 'No PO' as a fallback key when po is blank or undefined
        const key = obj?.djtPO?.po || 'No PO';
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    
      return Object.keys(groupedByPo).map(key => ({
        po: key,
        data: groupedByPo[key].sort((a, b) => new Date(a.djtDate) - new Date(b.djtDate)),
      }));
    }
    function flattenGroupedData(groupedData) {
      return groupedData.reduce((acc, group) => {
        // Map over each group's data to add the 'po' property to each item
        const extendedData = group.data.map(item => ({
          ...item,
          po: group.po // Add the 'po' property to each item
        }));
        // Concatenate these extended items to the accumulator, flattening the structure
        return acc.concat(extendedData);
      }, []);
    }      

    function flattenAndFilterData(groupedData, clientId, po) {
      // Flatten the grouped data
      const flattenedData = groupedData.reduce((acc, group) => {
        const extendedData = group.data.map(item => ({
          ...item,
          po: group.po // Add the po property to each item
        }));
        return acc.concat(extendedData);
      }, []);
    
      // Filter the flattened data based on clientId and po
      const filteredData = flattenedData.filter(item => {
        const matchesPo = po ? item.po === po : true; // Check if po matches, if provided
        const matchesClientId = clientId ? item.djtClient.id === clientId : true; // Check if clientId matches, if provided
        return matchesPo && matchesClientId;
      });
    
      return filteredData;
    }

    // console.warn('RUNNING useEffect: ', unapprovedStatusId, refreshUnapprovedDJTs, selectedClientId, selectedPO)
    if (unapprovedStatusId) {
      
      const filterByClientId = (array, clientId) => {
        return array.filter(item => item.djtClient.id === clientId);
      };
      
      const fetchDJTs = async () => {
        setIsFormLoaded(false)
        setIsMenuDisabled(true)
        setDisableUnapprovedTable(true)
        const data = await fetchDJTsByStatusId(unapprovedStatusId)
        // console.warn(' ----- unapproved djts: ', data)

        if (selectedClientId) {
          const filteredArray = filterByClientId(data, selectedClientId);
          const groupedAndSorted = groupAndSort(filteredArray)
          // const flattenedGroupedData = flattenGroupedData(groupedAndSorted)
          // console.warn(' ----- flattenedGroupedData: ', flattenedGroupedData)
          const flattenedFilteredData = flattenAndFilterData(groupedAndSorted, selectedClientId, selectedPO)
          // console.warn(' ----- flattenedFilteredData: ', flattenedFilteredData)
          setUnapprovedDJTs(flattenedFilteredData)
        } else {
          const groupedAndSorted = groupAndSort(data)
          // console.warn(' ----- groupedAndSorted: ', groupedAndSorted)
          const flattenedGroupedData = flattenGroupedData(groupedAndSorted)
          // console.warn(' ----- flattenedGroupedData: ', flattenedGroupedData)
          setUnapprovedDJTs(flattenedGroupedData)
        }
        setIsFormLoaded(true)
        setIsMenuDisabled(false)
        setDisableUnapprovedTable(false)
      }
      fetchDJTs()
        .catch(console.error);
    }
  },[unapprovedStatusId, refreshUnapprovedDJTs, selectedClientId, selectedPO])

  const fetchDJTs = useCallback(async () => {
    if (approvedStatusId) {
      // const data = await fetchDJTsByStatusId(approvedStatusId)
      await fetchDJTsByStatusId(approvedStatusId)
      // setApprovedDJTs(data)
      // setAvailableDJTs(data)
      // console.log('approved djts: ', data)
    }
  }, [approvedStatusId])

  useEffect(() => {
    fetchDJTs()
      .catch(console.error);
  }, [fetchDJTs])

  const getApprovedId = useCallback(async () => {
    const data = await getStatusIdByStatusCode('queued-billing-approved')
    setApprovedStatusId(data)
    // console.log('approved data: ', data)
  }, [])

  useEffect(() => {
    getApprovedId()
      .catch(console.error);
  }, [getApprovedId])

  // const getStatusId = useCallback(async () => {
  //   const data = await getStatusIdByStatusCode('queued-pending-approval')
  //   // setStatusId(data)
  //   console.log('status data: ', data)
  // }, [])

  // useEffect(() => {
  //   getStatusId()
  //     .catch(console.error);;
  // }, [getStatusId])

  const getPendingResponseId = useCallback(async () => {
    const data = await getStatusIdByStatusCode('queued-unapproved-with-issue')
    setPendingResponseStatusId(data)
    // console.log('pending response data: ', data)
  }, [])

  useEffect(() => {
    getPendingResponseId()
      .catch(console.error);
  }, [getPendingResponseId])

  const bounceDJT = async (ticket, reason) => {
    let djtId = ticket.id
    let supervisorId = ticket?.teamMember?.id
    let divisionId = ticket?.djtDivisionId
    let clientId = ticket?.djtClient?.id

    // console.log('ticket: ', ticket)
    // console.log('djtId: ', djtId)
    // console.log('supervisorId: ', supervisorId)
    // console.log('divisionId: ', divisionId)
    // console.log('clientId: ', clientId)

    // update the status of the djt to PENDING-RESPONSE from supervisor
    if (djtId && pendingResponseStatusId) {
      //djtId, statusId, divisionId, clientId, teamMemberId
      await updateDJTStatus(djtId, pendingResponseStatusId, divisionId, clientId, supervisorId)
      // console.log('updated '+result+' with new status queued-unapproved-with-issue')
    }

    const updateProps = {id: djtId, bouncedAdminReason: reason}
    const updateResult= await updateDailyJobTicket(updateProps)
    console.log('update result: ', updateResult)

    // now send message to supervisor
    // get the id for message sent
    // const sentStatusId = await getMessageStatusIdByStatus('sent')
    // console.log('sentStatusId: ', sentStatusId)
    // get the category code - well, do we really need this here right now?

    // props needed: subject, message, topicId, publisherId, publisherType
    // const topicId = await getTeamMemberTopicId(currentDJT?.teamMemberId)

    // const topicId = await getTopicIdByNameCode('user#'+supervisorId)
    // console.log('topicNameCode: ', 'user#'+supervisorId)
    // console.log('topicId: ', topicId)

    // const subject = "Daily Job Ticket - Not Approved - Please Review"
    // //djt date, client, total, link to supervisors screen of djt form with this id loaded
    // const msgTop = "From: "+currentAdminName+" DJT Date: "+ticket?.djtDate+" Client: "+ticket?.company+" Total Billed: "+ticket?.totalAmount+" "
    // const msgBottom = ""
    // const message = msgTop+reason+msgBottom
    // const publisherId = currentTeamMemberId
    // const fromId = currentTeamMemberId
    // const publisherType = "admin"
    
    // const msgJSON = [{
    //   'from': currentAdminName,
    //   'datetime': awsDateTimeNow(),
    //   'message': reason,
    //   'subject': subject
    // }]

    // const props = {
    //   subject: subject,
    //   message: message,
    //   messageJSON: JSON.stringify(msgJSON),
    //   fromId: fromId,
    //   sentAt: awsDateTimeNow(),
    //   topicId: topicId,
    //   publisherId: publisherId,
    //   publisherType: publisherType,
    //   statusId: sentStatusId  // 'sent'
    // }

    // const messageId = await sendMessage(props)
    // console.log('messageId: ', messageId)

    setRefreshUnapprovedDJTs(uuidv4())
    setSelectedClientId(null)
    setSelectedPO(null)
    setValue('message', '')
    setCurrentDJT(null)
    setIsRejectDJTVisible(false)

  }

  const formSubmit = async (values, event) => {
    event.preventDefault();
    setIsFormLoaded(false)
    setIsMenuDisabled(true)
    // console.log('form values: ', values)
    // console.warn('values?.selectTerms?.value: ', values?.selectTerms?.value)

    // let djtId = values.djtId
    let supervisorId = firstDjtLoaded?.teamMember?.id
    let divisionId = firstDjtLoaded?.djtDivisionId
    let clientId = firstDjtLoaded?.djtClient?.id
    // console.warn('firstDjtLoaded: ', firstDjtLoaded)
    // let reason = values?.message
    

    const djtCount = values?.djtrow?.length
    const djtPO = values?.poNumber||''
    let urlParams = []
    let djtids = []

    // DueDate
    // Optional
    // Date , filterable , sortable
    // Date when the payment of the transaction is due. If date is not provided, the number of days specified in SalesTermRef added the transaction date will be used.
    // "DueDate": "2024-02-29",
    // "TxnDate": "2024-02-29",
    // invoiceDate: "2024-02-29"
    // invoiceDueDate: "2024-03-14"
    
    urlParams.push('cnt='+djtCount)
    urlParams.push('classname='+values?.selectClass?.label)
    urlParams.push('classvalue='+values?.selectClass?.value)
    urlParams.push('memo='+encodeURIComponent(values?.customerMemo))
    urlParams.push('sendto='+encodeURIComponent(values?.customerEmail))
    urlParams.push('devsendto='+encodeURIComponent('webadmin@spikeenterprise.com'))
    urlParams.push('po='+encodeURIComponent(djtPO))
    urlParams.push('attachpdf='+values?.attachFiles)

    values?.djtrow?.map((item, index) => {
      urlParams.push(index+'_desc='+encodeURIComponent(item?.djtNotes))
      urlParams.push(index+'_qbcid='+values?.customerId)
      urlParams.push(index+'_qbterm='+values?.selectTerms?.value)
      urlParams.push(index+'_invd='+values?.invoiceDate)
      urlParams.push(index+'_invdd='+values?.invoiceDueDate)
      urlParams.push(index+'_subtot='+convertCurrencyToDouble(item?.DJTSubtotal))
      urlParams.push(index+'_est='+convertCurrencyToDouble(item?.EquipmentTotal))
      urlParams.push(index+'_lst='+convertCurrencyToDouble(item?.LaborTotal))
      urlParams.push(index+'_mst='+convertCurrencyToDouble(item?.MaterialTotal))
      urlParams.push(index+'_sd='+item?.LaborServiceDate)
      urlParams.push(index+'_lir='+laborItemRefId)
      urlParams.push(index+'_eir='+equipmentItemRefId)
      urlParams.push(index+'_mir='+materialItemRefId)
      // urlParams.push(index+'_fid='+item?.AttachableId)
      urlParams.push(index+'_fid='+attachableObjects[index]?.Id)
      urlParams.push(index+'_fn='+item?.LaborServiceDate+'.pdf')
      djtids.push(item?.djtId)
    })

    // console.warn(' ----- urlParams: ', urlParams)

    const urlParamsString = urlParams.join('&')

    console.info('djtParams for createInvoice: ', urlParamsString)

    const qbResults = await createInvoice(urlParamsString, tokenObj?.accessToken)
    // await createInvoice(urlParamsString, tokenObj?.access_token)
    console.info('qbResults: ', qbResults)

    // check the results for success and make sure we get the status of the djt updated for each invoice.

    console.info('parameters: ', {djtId, invoicedStatusId, divisionId, clientId, supervisorId})
    for (const djt of djtids) {
      // console.log('djt: ', djt)
      let djtId = djt
      const updateResults = await updateDJTStatus(djtId, invoicedStatusId, divisionId, clientId, supervisorId)
      // await updateDJTStatus(djtId, invoicedStatusId, divisionId, clientId, supervisorId)
      console.log('updateResults: ', updateResults)
    }

    handleInvoiceReset()
  }

  // const [ djtNotes, setDjtNotes ] = useState(null)
  const [ djtGrandTotal, setDjtGrandTotal ] = useState(0)
  // useEffect(() => {
  //   (djtGrandTotal) && console.warn(' ----- djtGrandTotal changed: ', djtGrandTotal)
  // },[djtGrandTotal])
  // const [ djtCompanyFileName, setDjtCompanyFileName ] = useState(null)
  // const [ djtDateFileName, setDjtDateFileName ] = useState(null)

  const [ customerDataLoaded, setCustomerDataLoaded ] = useState(false)

  // const setUpStateWithDJTData = ({
  //   clientId,
  //   djtCompanyFileName,
  //   djtDateFileName,
  //   djtNotes,
  //   djtId,
  // }) => {
  //   setSelectedClientId(clientId);
  //   setDjtCompanyFileName(djtCompanyFileName);
  //   setDjtDateFileName(djtDateFileName);
  //   setDjtNotes(djtNotes);
  //   setCurrentDJTId(djtId);
  // };

  // const createDjtObject = (djt) => ({
  //   djtId: djt.id,
  //   djtPoId: djt.djtPOId,
  //   djtNotes: djt.djtNotes,
  //   AttachableId: djt.attachableObjId,
  //   LaborServiceDate: djt.djtDate, 
  //   EquipmentServiceDate: djt.djtDate, 
  //   MaterialServiceDate: djt.djtDate, 
  //   LaborTotal: djt.djtLaborTotal, 
  //   LaborQuantity: '1', 
  //   LaborRate: djt.djtLaborTotal,
  //   EquipmentTotal: djt.djtEquipmentTotal, 
  //   EquipmentQuantity: '1', 
  //   EquipmentRate: djt.djtEquipmentTotal,
  //   MaterialTotal: djt.djtMaterialTotal, 
  //   MaterialQuantity: '1', 
  //   MaterialRate: djt.djtMaterialTotal,
  //   DJTSubtotal: Number(djt.djtLaborTotal) + Number(djt.djtEquipmentTotal) + Number(djt.djtMaterialTotal)
  // });

  const loadDJTForQB = async (data) => {
    setCustomerDataLoaded(false)
    console.info(' -----> data: ', data)
    const djtPONumber = data.djtPO?.po||null
    const djtId = data.id
    // const djtNotes = data.djtNotes
    const clientId = data.djtClient?.id
    setSelectedClientId(clientId)
    setSelectedPO(djtPONumber)

    // setDjtCompanyFileName(data.djtClient?.qbCompany)
    // setDjtDateFileName(data.djtDate)
    // setDjtNotes(djtNotes)

    // get djt by djtid and populate form fields for qb push
    // setCurrentDJTId(djtId)

    const djt = await fetchDailyJobTicketByIdForApproval(djtId)
    console.warn('------> DJT: ', djt)

    const djtCustomerId = (qbEnv === 'sandbox') ? djt?.djtClient?.qbSandboxId : djt?.djtClient?.qbId
    console.warn('djtCustomerId: ', djtCustomerId)

    // filter the available djts for field array from the company
    // and set their button state to false (not disabled)
    // let tmpButtonArray = []
    // const availableDJTs = djts.data.filter((item) => {
    //   if (item.djtClient.qbId === djtCustomerId) {  // add all of these customer djt's to available djts
    //     if (item.id !== djtId) {
    //       tmpButtonArray.push({id: item.id, state: false})
    //     } else {
    //       tmpButtonArray.push({id: item.id, state: true})
    //     } // add all but selected to button state false, adding button state true down below
    //     return item
    //   }
    //   return null
    // })
    // setDisabledButton(tmpButtonArray)
    // availableDJTs.sort(function( a , b ){
    //   return a.djtDate > b.djtDate ? 1 : -1;
    // })
    // setCompanyDJTs(availableDJTs)

    // const djtPONumber = djt.djtNonContractPO||djt.djtPO  <- when dealing with Exxon/Citgo, this will be from a contracted PO list
    // const djtPONumber = djt.djtNonContractPO
    // console.log('djtCustomerId: ', djtCustomerId)
    // setCurrentDJT(djt)

    const qbTerms = await getQBTerms()
    const qbItems = await getQBItems()
    console.warn(' ----- qbTerms: ', qbTerms)
    console.warn(' ----- qbItems: ', qbItems)

    // now get data for Labor, Equipment, Material
    const laborItem = qbItems?.Item?.find(item => item.Description === "Labor");
    setLaborItemRefId(laborItem?.Id);
    const equipmentItem = qbItems?.Item?.find(item => item.Description === "Equipment");
    setEquipmentItemRefId(equipmentItem?.Id);
    const materialItem = qbItems?.Item?.find(item => item.Description === "Material");
    setMaterialItemRefId(materialItem?.Id);

    const customerData = await getQBCustomer(djtCustomerId)
    // console.warn(' ---- QB CUSTOMER ID: ', djtCustomerId)
    console.warn(' ---- QB CUSTOMER ARRAY: ', customerData)
    
    // ----- COMMENINTING OUT AS NOT NEEDED 02-29-2024 -----
    // // pass in attachableId instead
    // // console.log('should have aid now: ', djt?.attachableObjId)
    // const attachables = await getQBAttachables(djt.djtDate, djt?.attachableObjId)
    // console.warn(' ----- THIS IS WHAT WE ARE SETTING attachables: ', attachables)
    // // const attachables = await getQBAttachables(djt.djtDate, djt?.djtClient?.qbCompany)
    // // setAttachableObjects(attachableObjects => [...attachableObjects, attachables])
    // setAttachableObjects(attachables)

    // may need to revisit this for multiple pdf attachments
    // setAttachableObjects([])
    // -----

    const SalesTermRef = customerData?.SalesTermRef?.value||null

    // if the customer has a sales term, match it to the qbTerms options
    const condition = term => SalesTermRef ? term.Id === SalesTermRef : term.Term === '30';
    const selectedTermData = qbTerms.filter(condition);

    // const selectedTermData = (SalesTermRef) ? 
    // qbTerms.filter((term) => { return term.Id === SalesTermRef }) : 
    // qbTerms.filter((term) => { return term.Term === '30' })

    // and then set selectedTerm?

    // console.log('selectedTermData: ', selectedTermData)
    const selectedTermOption = {
      label: selectedTermData[0]?.Name, 
      value: selectedTermData[0]?.Id,
      term: selectedTermData[0]?.Term,
    }
    // console.warn(' ----- selectedTermOption: ', selectedTermOption)
    // setValue('selectTerms', selectedTermOption)
    await handleTermsChanged(selectedTermOption)
    // setSelectedTerm(selectedTermOption)
    // setValue('selectTerms', selectedTermOption)
    
    const qbCustomerId = (qbEnv === 'sandbox') ? djt.djtClient?.qbSandboxId : djt.djtClient?.qbId
    // console.warn(' ----- customerData: ', customerData)
    // console.warn(' ----- djt.djtClient: ', djt.djtClient)
    setValue('customerId', qbCustomerId)
    // setValue('customerName', djt.djtClient?.qbDisplayName)
    // setValue('customerName', customerData?.GivenName+' '+customerData?.MiddleName+' '+customerData?.FamilyName)
    setValue('customerName', customerData?.FullyQualifiedName||'')
    setValue('customerEmail', customerData?.PrimaryEmailAddr?.Address||'')
    
    // console.warn(' ----- customerData: ', customerData)
    let companyName = customerData?.DisplayName+'\n'
    let companyAddressLine1 = (customerData?.BillAddr?.Line1) ? customerData?.BillAddr?.Line1+'\n' : ''
    let companyCity = (customerData?.BillAddr?.City) ? customerData?.BillAddr?.City : ''
    let companyState = (customerData?.BillAddr?.CountrySubDivisionCode) ? ', '+customerData?.BillAddr?.CountrySubDivisionCode+'\n' : ''
    let companyPostalCode = (customerData?.BillAddr?.PostalCode) ? customerData?.BillAddr?.PostalCode : ''

    let billingFormatted = companyName+companyAddressLine1+companyCity+companyState+companyPostalCode
    // console.warn(' ----- billingFormatted: ', billingFormatted)
    
    setValue('billingAddress', billingFormatted||'')
    setValue('poNumber', djtPONumber||'')

    // set customer selected option to customerId
    const selected = {label: djt.djtClient?.qbDisplayName, value: djt.djtClient?.qbId}
    setSelectedCustomer(selected)

    // console.warn('ABOUT TO CALUCLATE ON THESE: ', djt)
    // add this djt to the first item in the djt field array
    const djtSubtotal = (Number(djt.djtLaborTotal) + Number(djt.djtEquipmentTotal) + Number(djt.djtMaterialTotal))
    const djtObject = {
      djtId: djtId,
      doNotBill: djt.doNotBill,
      doNotBillDescription: djt.doNotBillDescription,
      showLaborHours: djt.showLaborHours,
      specialInstructions: djt.specialInstructions,
      djtPoId: djtPONumber,
      djtNotes: djt?.djtNotes,
      AttachableId: djt?.attachableObjId,
      LaborServiceDate: djt.djtDate, 
      EquipmentServiceDate: djt.djtDate, 
      MaterialServiceDate: djt.djtDate, 
      LaborTotal: djt.djtLaborTotal, 
      LaborQuantity: '1', 
      LaborRate: djt.djtLaborTotal,
      LaborAmount: USDollar.format(djt.djtLaborTotal),
      EquipmentTotal: djt.djtEquipmentTotal, 
      EquipmentQuantity: '1', 
      EquipmentRate: djt.djtEquipmentTotal,
      EquipmentAmount: USDollar.format(djt.djtEquipmentTotal),
      MaterialTotal: djt.djtMaterialTotal, 
      MaterialQuantity: '1', 
      MaterialRate: djt.djtMaterialTotal,
      MaterialAmount: USDollar.format(djt.djtMaterialTotal),
      DJTSubtotal: USDollar.format(djtSubtotal)
    }
    djtAppend(djtObject)

    // console.warn(' __________ setting djtGrandTotal amount: ', djtGrandTotal)
    // console.warn(' __________ setting djtSubtotal amount: ', djtSubtotal)
    // parseFloat(convertCurrencyToDouble(djtSubtotal))
    let totalAmount = parseFloat(djtGrandTotal)+parseFloat(djtSubtotal)
    // console.warn(' __________ setting total amount: ', totalAmount)
    setDjtGrandTotal(totalAmount)
    setValue('TotalAmount', USDollar.format(totalAmount)||'')

    // let qbClassId = djt?.djtClient?.qbClassId||''
    // let qbClassName = djt?.djtClient?.qbClassName||''
    // console.warn(' ---------------------------------------- qbClassId: ', qbClassId)
    // console.warn(' ---------------------------------------- djt?.djtClient: ', djt?.djtClient)
    // setSelectedClass(qbClassId)
    // setSelectedClassName(qbClassName)
    // setValue('selectClass', qbClassId);
    // if ( qbClassId && qbClassName ) {
    //   handleClassChanged({label: `${qbClassName}`, value: `${qbClassId}`})
    // }

    setCustomerDataLoaded(true)
    return true
  }

  const getQBCustomer = async (customerId) => {
    if (!tokenObj?.accessToken) {
      return
    }
    const qbCustomer = await getCustomer(tokenObj?.accessToken, customerId, qbEnv)
    return qbCustomer
  }

  const loadQBClasses = async (token, environment) => {
    if (!token && !token.accessToken) {
      return
    }
    const qbClasses = await getClasses(token, environment)
    setClasses(qbClasses)
    return
  }

  const getQBItems = async () => {
    try {
      // Ensure there's an access token before making the call
      if (!tokenObj?.accessToken) {
        throw new Error('Access token is undefined.');
      }
      const qbItems = await getItems(tokenObj?.accessToken, qbEnv);

      // Optionally, add a check here if qbItems must have a specific structure or not be empty
      if (!qbItems) {
        // Handle case where qbItems is null, undefined, or not what you expect
        throw new Error('No items returned or bad response from getItems.');
      }
  
      return qbItems;
    } catch (error) {
      // Log the error or handle it as per your application's error handling strategy
      console.error('Failed to fetch QB items:', error);
      // Consider what should be returned in case of error. Depending on your app, it might be an empty array, null, or something else.
      return null; // Or [] if an empty array makes more sense for your app
    }
  };

  const getQBTerms = async () => {
    try {
      // Ensure we have a token before making the call
      if (!tokenObj?.accessToken) {
        throw new Error("No access token available.");
      }
      // console.warn(' ----- accessToken: ', tokenObj?.accessToken)
      const qbTerms = await getTerms(tokenObj?.accessToken, qbEnv);
  
      // Guard against a falsy return value from getTerms
      if (!qbTerms) {
        throw new Error("Failed to retrieve terms.");
      }
  
      const enhancedQbTerms = qbTerms.map(obj => {
        const match = obj.Name.match(/\d+/); // Extract digits from the "Name" string
        const termValue = match ? match[0] : '0'; // Use '0' as a fallback if no digits found
  
        return {
          ...obj, // Spread existing properties
          Term: termValue, // Add or overwrite the "Term" property
        };
      });
  
      setTerms(enhancedQbTerms);
      return enhancedQbTerms;
    } catch (error) {
      console.error("Error fetching QB Terms:", error);
      // Consider setting a fallback or error state here as well
      return []; // Return an empty array as a safe fallback
    }
  }

  // we may not have any customers at this point
  const loadQBCustomers = async (token, environment) => {
    // console.warn(' ----- loadQBCustomers: ', token, environment)
    // const qbCustomers = await getCustomers(token, environment)
    await getCustomers(token, environment)
    // console.warn(' ----- qbCustomers: ', qbCustomers)
    // console.warn(' ----- localStorage: ', localStorage)

    // setCustomers(qbCustomers)
    return
  }

  const createInvoice = async (urlParams, token) => {
    // Lambda: createQBInvoice
    // qbEnv is pulled from .env file and needs to be production or sandbox
    
    // const encodedParams = encodeURI(urlParams)
    const encodedParams = urlParams;
    // console.log(' ----- encodedParams: ', encodedParams)

    const response = await fetch('https://dcg2yffmn5mw4s2panwjsgn4ca0ebara.lambda-url.us-east-1.on.aws/?'+encodedParams+'&token='+token+'&qbenv='+qbEnv, { 
        method: 'GET', 
      headers: new Headers({
          'Authorization': 'Bearer '+token, 
          'Content-Type': 'application/x-www-form-urlencoded',
      })
    });

    const dataJSON = await response.json()

    return dataJSON
  }
 
  const approveThisDJT = async (djt, token, environment, identityId) => {

    console.warn(' ----- token: ', token)
    
    // const djtId = djt.id
  
    // generate PDF and upload to S3/QB
    // const attachedId = await handleGeneratePdf(djt)
    //djt, token, environment, doDownloadPdf, doUploadPdf
    const attachedObj = await generatePdf(djt, token, environment, false, true, identityId)
    // const attachedObj = await generatePdf(djt, token, environment, true, true, identityId)
    // console.warn(' WE NEED THIS TO BE FILLED: ', attachedObj)
    // const attachedId = attachedObj?.Id
    // const attachedFileName = attachedObj?.FileName
    // setAttachableFileName(attachedFileName)
    // setAttachableId(attachedId)
    // console.log('attachedId: ', attachedId)
    setAttachableObjects(attachableObjects => [...attachableObjects, attachedObj])
    
    
    // if (djtId && approvedStatusId) {
    //   await updateDJTAttachedPDF(djtId, attachedId)
    // }

    await fetchDJTs()
    setCurrentDJT(null)

    return true
  }
  

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const [ term, setTerm ] = useState(0)
  const handleTermsChanged = async (option) => {
    const currentInvoiceDate = invoiceDate
    // console.warn(' ----- currentInvoiceDate: ', currentInvoiceDate)
    // update the invoiceDueDate based on the term selected
    // set the selected term state to update the selected option
    const selectedTerm = option?.term
    setTerm(selectedTerm)
    const newDueDate = netDateDueMutator(currentInvoiceDate, selectedTerm, '-')
    // console.warn(' ----- newDueDate: ', newDueDate)
    setSelectedTerm(option)
    setValue('invoiceDueDate', newDueDate)
    setInvoiceDueDate(newDueDate)
    setValue('selectTerms', option)
    return
  }

  const handleInvoiceDueDateChanged = (date) => {
    // if the invoice due date changes just update the state
    setInvoiceDueDate(date)
    setValue('invoiceDueDate', date)
  }

  const handleInvoiceDateChanged = (newInvoiceDate) => {
    // we need to make sure that newInvoiceDate is greater than today
    // const currentInvoiceDate = invoiceDate
    // const todaysDate = todaysDateYMD()
    // if the invoice date changes, we need to update the invoice due date based on the terms selected
    let currentTerm = Number(term)
    const newDueDate = netDateDueMutator(newInvoiceDate, currentTerm, '-')
    setValue('invoiceDueDate', newDueDate)
    setInvoiceDueDate(newDueDate)
    setInvoiceDate(newInvoiceDate)
    setValue('invoiceDate', newInvoiceDate)

  }

  const [ isLoadingComplete, setIsLoadingComplete ] = useState(false)
  useEffect(() => {
    if ( selectedTerm!=='' && attachableObjects?.length>0 && customerDataLoaded===true && tokenObj?.accessToken ) {
        // console.warn(' ----- access token: ', tokenObj?.access_token)
        setIsLoadingComplete(true);
      }
  },[ attachableObjects, selectedTerm, customerDataLoaded, tokenObj?.accessToken ])

  const handleInvoiceReset = async () => {
    // clean this up later
    // window.location.reload();
    setDjtGrandTotal(0)
    setValue('TotalAmount', 0)
    setValue('customerMemo', 'Thank you for your business.')
    setValue('attachFiles', false)
    setAttachFilesChecked(false)
    setAttachableObjects([])
    setRefreshUnapprovedDJTs(uuidv4())
    setCurrentDJT(null)
    setIsInvoiceDJTVisible(false)
    // setCompanyDJTs(null)
    setSelectedClientId(null)
    setSelectedPO(null)
    setSelectedClass(null)
    setValue('selectClass', '');
    setSelectedClassName(null)
    setDisabledToolbars({})
    djtRemove();
    
    setFirstDjtLoaded(null)
    // setPdfPagesArray([])
    setIsFormLoaded(true)
    setIsMenuDisabled(false)
    return
  }


  // const [ selectedQBClassObj, setSelectedQBClassObj ] = useState(null)
  const [ selectedClassName, setSelectedClassName ] = useState(null)
  const handleClassChanged = (option) => {
    // console.warn(' ----- option: ', option)
    setSelectedClass(option.value)
    setSelectedClassName(option.label)
    // const id = option?.value
    // const qbclass = classes.find((item) => item.Value === id)
    // console.warn(' ----- qbclass: ', qbclass)
    // setSelectedQBClassObj(qbclass)
    // setValue('selectClass', option.value);
    setValue('selectClass', option);
    clearErrors(['selectClass'])
  }

  const ObjectRenderer = ({ obj }) => {
    if (!obj) {
      return null;
    }
  
    const renderValue = (value) => {
      if (typeof value === 'object' && value !== null && !(value instanceof Array)) {
        // Use <div> instead of <p> for nested objects to avoid nesting violation.
        return <div style={{marginLeft: '20px'}}><ObjectRenderer obj={value} /></div>;
      } else if (value instanceof Array) {
        // Handle arrays separately to ensure each item is rendered correctly.
        return value.map((item, index) => (
          <div key={index} style={{marginLeft: '20px'}}>
            {renderValue(item)}
          </div>
        ));
      }
      return value.toString();
    };
  
    return (
      <div>
        {Object.entries(obj).map(([key, value]) => (
          // Wrap key with <span> to maintain inline display, then render value.
          <div key={key}>
            <span>{`${key}: `}</span>
            {renderValue(value)}
          </div>
        ))}
      </div>
    );
  };
  
  ObjectRenderer.propTypes = {
    obj: PropTypes.object.isRequired
  };
  // const handleSyncQBClientData = async (qbCustomer) => {
  //   //selectedCustomer data gets pushed to SpkDJTClientTbl
  //   console.warn(' ----- qbCustomer: ', qbCustomer)
  // }

  // const [ isDebug, setIsDebug ] = useState(false)
  let isDebug = false;
  useEffect(() => {
    // Check if any item in djtFields has doNotBill set to true
    const shouldDisableMenu = djtFields.some(item => item?.doNotBill);
    setIsMenuDisabled(shouldDisableMenu);
  }, [djtFields]); // Dependency array ensures this effect runs only when djtFields changes

  
  return(
    <>
      <QuickBooksAuth 
        qbAppId='Invoices'
        qbEnv={qbEnv}
        onTokenChange={setTokenObj}
        >
        {({ isLoggedInQB, loginQB, logoutQB }) => (
          <Container as="form" maxW="1600" mb={12}>
            <>
              <QBLoginLogoutButtons 
                isLoggedInQB={isLoggedInQB}
                loginQB={loginQB}
                logoutQB={logoutQB}
              />

              <fieldset disabled={!isLoggedInQB || disableUnapprovedTable}>

                <ModuleBox>
                  <TableContainer>
                  <HStack paddingBottom={'10px'}>
                    <Box w={'100%'}>
                      {/* Queue for QuickBooks */}
                      <Text className={searchHeaderTextClass}>{(isInvoiceDJTVisible===true) ? 'Queue for QuickBooks' : 'View & Invoice Daily Job Tickets'}</Text>
                    </Box>
                    <Box>
                      <ButtonQuaternaryWithIcon
                        name='refresh'
                        leftIcon='refresh'
                        iconsize='22px'
                        isDisabled={!isLoggedInQB}
                        onClick={() => { handleInvoiceReset(); }}
                        value='Refresh page'
                      />
                    </Box>
                    
                  </HStack>
                                    
                  <Table variant={'compact'} size={'compact'} >
                    <Thead>
                      <Tr>
                        <Th><Text as="span" textStyle='dark-heading-4'>CLIENT</Text></Th>
                        <Th><Text as="span" textStyle='dark-heading-4'>DATE</Text></Th>
                        <Th><Text as="span" textStyle='dark-heading-4'>PO</Text></Th>
                        <Th><Text as="span" textStyle='dark-heading-4'>DESCRIPTION</Text></Th>
                        <Th textAlign='left' pl={'38px'}><Text as="span" textStyle='dark-heading-4'>ACTIONS</Text></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {(unapprovedDJTs?.length===0) && <Tr><Td py='6px' colSpan={4}><Center>No results found</Center></Td></Tr>}
                      {unapprovedDJTs?.map((djt, index) => {

                        const goesBy = djt?.teamMember?.goesBy
                        const firstName = (goesBy && goesBy!=="") ? goesBy : djt?.teamMember?.firstName
                        const supervisorName = `${firstName} ${djt?.teamMember?.lastName}`
                        // const djtDivision = djt?.djtDivisionId
                        // const djtClient = djt?.djtClient?.id
                        // const djtTeamMember = djt?.teamMemberId
                        // const doNotInvoice = djt?.doNotBill||false
                        // setIsReview(false)
                        return(
                          <Fragment key={'frag_1_'+index}>
                          <Tr key={'tr_'+index}>
                            <Td py='6px' key={'td_2_'+index}>
                              <HStack>
                                {(disabledToolbars[djt?.id]) && <Box paddingTop={'2px'}>
                                  <AvailableIcons boxSize={'15px'} iconRef={'outlinecheckcircle'} />
                                </Box>}
                                <Box >
                                  <Text paddingLeft={'3px'} as="span" className='dark-sfpro-text-2'>
                                    <Tooltip label={djt?.djtClient?.contactCompany}>
                                      {truncateString(djt?.djtClient?.contactCompany, clientTruncateValue)}
                                    </Tooltip>
                                  </Text>
                                </Box>
                              </HStack>
                              
                              
                            </Td>
                            <Td py='6px' key={'td_1_'+index}>
                              <Text as="span" className='dark-sfpro-text-2'>{spellOutDate(djt?.djtDate, 'apple-fy')}</Text>
                            </Td>
                            <Td py='6px' key={'td_6_'+index}>
                              <Text as="span" className='dark-sfpro-text-2'>{djt?.djtPO?.po}</Text>
                            </Td>
                            <Td py='6px' key={'td_3_'+index} my='5px'>
                              {/* {(doNotInvoice) && <Text as="span" color='var(--error-red)' className='dark-sfpro-text-2' my='5px'>DO NOT INVOICE - </Text>} */}
                              <Text as="span" className='dark-sfpro-text-2' my='5px'>
                                <Tooltip label={
                                  <Stack m='10px'>
                                    <Text>{`Supervisor: ${supervisorName}`}</Text>
                                    <Text>{`${djt?.djtNotes}`}</Text>
                                  </Stack>
                                  }>
                                  {truncateString(djt?.djtNotes, descriptionTruncateValue)}
                                </Tooltip>
                              </Text>
                            </Td>
                            <Td key={'td_4_'+index}>
                              <Box w='100%' align='right'>
                                <Menu>
                                  <MenuButton
                                    as={IconButton}
                                    aria-label='Options'
                                    icon={<AvailableIcons boxSize={'22px'} iconRef={'more'} />} 
                                    variant='withIconOnlySenary' 
                                    backgroundColor={'var(--dark-main-background)'}
                                    w={'60px'}
                                    iconsize='18px'
                                    height='28px'
                                    mb='3px'
                                    isDisabled={!tokenObj || !isFormLoaded || isMenuDisabled || disabledToolbars[djt?.id] || !isLoggedInQB}
                                  />
                                  <MenuList>
                                    <MenuItem 
                                      onClick={() => handleOpenDrawer('view', djt?.id)}
                                      icon={<AvailableIcons boxSize={'22px'} iconRef={'view'} />} >
                                      View Job Ticket
                                    </MenuItem>

                                    <MenuItem 
                                      // isDisabled={disabledToolbars[djt?.id]}
                                      onClick={() => {
                                        setDisableUnapprovedTable(true)
                                        showRejection(djt?.id, index);
                                        disableToolbar(djt?.id);
                                      }}
                                      icon={<AvailableIcons boxSize={'22px'} iconRef={'reject'} />} >
                                      Return to Supervisor
                                    </MenuItem>

                                    <MenuItem 
                                      isDisabled={disabledToolbars[djt?.id] || !isLoggedInQB || !qbEnv || !identityId}
                                      onClick={() => {
                                        setIsMenuDisabled(true)
                                        setDisableUnapprovedTable(true)
                                        //id, index, token, environment
                                        // console.warn(' ----- access token: ', tokenObj?.access_token)
                                        showApproval(djt?.id, index, tokenObj?.accessToken, qbEnv, identityId);
                                        disableToolbar(djt?.id);
                                      }}
                                      icon={<AvailableIcons boxSize={'22px'} iconRef={'upload'} />} >
                                      Queue for QuickBooks
                                    </MenuItem>

                                  </MenuList>
                                </Menu>
                              </Box>
                            </Td>
                          </Tr>
                          <Tr key={'tr_divider_b_'+index}>
                            <Td key={'td_divider_b_'+index} colSpan={5} borderBottom={'1px solid var(--dark-module-divider)'}></Td>
                          </Tr>
                          </Fragment>
                        )
                      })}
                      <Tr><Td colSpan={5}  borderBottom={'1px solid var(--dark-module-divider)'}></Td></Tr> 
                    </Tbody>
                      {/* : <Tbody><Tr><Td colSpan={5} py={'25px'} borderBottom={'1px solid var(--dark-module-divider)'}><Center><Spinner color='var(--progress-bar-primary)' /></Center></Td></Tr></Tbody>} */}
                  </Table>
                  </TableContainer>
                </ModuleBox>

                {(isRejectDJTVisible) && <ModuleBox>
                  <Box>
                    <Text>Date: {currentDJT?.djtDate}</Text>
                    <Text>Company: {currentDJT?.djtClient?.contactCompany || currentDJT?.djtClient?.qbCompany}</Text>
                    <Text>Job Description: {currentDJT?.djtNotes}</Text>
                    <Text>Labor Total Amount: {USDollar.format(currentDJT?.djtLaborTotal || null)}</Text>
                    <Text>Equipment Total Amount: {USDollar.format(currentDJT?.djtEquipmentTotal || null)}</Text>
                    <Text>Material Total Amount: {USDollar.format(currentDJT?.djtMaterialTotal || null)}</Text>
                    <Text>Total Amount: {USDollar.format(currentDJT?.djtTotalBilled || null)}</Text>
                    <Text mb='20px'>Supervisor: {currentDJT?.teamMember?.goesBy} {currentDJT?.teamMember?.firstName} {currentDJT?.teamMember?.lastName}</Text>
                    
                    <TextInput
                      fieldname="message"
                      fieldlabel="Approval Rejection Reason"
                      prettyname="Message"
                      register={register}
                      errors={errors}
                      placeholder="Reson for approval rejection"
                      isRequired={true} 
                      isReadOnly={false}
                    />

                    <TextInput
                      fieldname='djtId'
                      fieldvalue={currentDJT?.id||''}
                      register={register}
                      errors={errors}
                      fieldtype='hidden'
                    />
                    <TextInput
                      fieldname='supervisorId'
                      fieldvalue={currentDJT?.teamMember?.id||''}
                      register={register}
                      errors={errors}
                      fieldtype='hidden'
                    />
                    <TextInput
                      fieldname='djtDate'
                      fieldvalue={currentDJT.djtDate||''}
                      register={register}
                      errors={errors}
                      fieldtype='hidden'
                    />
                    <TextInput
                      fieldname='divisionId'
                      fieldvalue={currentDJT.djtDivisionId||''}
                      register={register}
                      errors={errors}
                      fieldtype='hidden'
                    />
                    <TextInput
                      fieldname='company'
                      fieldvalue={currentDJT?.djtClient?.contactCompany || currentDJT?.djtClient?.qbCompany||''}
                      register={register}
                      errors={errors}
                      fieldtype='hidden'
                    />
                    <TextInput
                      fieldname='clientId'
                      fieldvalue={currentDJT?.djtClient?.id||''}
                      register={register}
                      errors={errors}
                      fieldtype='hidden'
                    />
                    <TextInput
                      fieldname='totalAmount'
                      fieldvalue={USDollar.format(currentDJT?.djtTotalBilled || '')}
                      register={register}
                      errors={errors}
                      fieldtype='hidden'
                    />

                    <HStack my={'25px'} key={uuidv4()}>

                      <Box w={'25%'}>
                        <ButtonSecondaryPlain 
                          width={'100%'}
                          name='cancel'
                          value='Cancel'
                          onClick={() =>  handleInvoiceReset()}
                          isDisabled={!isFormLoaded}
                        />
                      </Box>

                      <Box w={'75%'}>
                        <ButtonPrimaryPlain 
                          width={'100%'}
                          name='reject'
                          value='Reject & Return to Supervisor'
                          onClick={() =>  bounceDJT(currentDJT, getValues('message'))}
                          isDisabled={!isRejectDJTVisible}
                        />
                        {/* <ButtonPrimaryPlain 
                          mt='20px'
                          name="deny"
                          isDisabled={!isRejectDJTVisible}
                          // type="submit"
                          onClick={() => {
                            bounceDJT(currentDJT, getValues('message'))
                            // viewDJT(item.id)
                          }}
                          >Reject & Return to Supervisor</ButtonPrimaryPlain> */}
                      </Box>

                    </HStack>
                    
                  </Box>
                </ModuleBox>}
              </fieldset>
              <fieldset disabled={!isLoadingComplete}>
                
                {(isInvoiceDJTVisible) && <>
                  {(isDebug) && <><ModuleBox>
                    <Box>
                      <Text as="span" textStyle='dark-heading-1'>DEBUG ONLY, WILL BE REMOVED</Text>
                    </Box>
                    <Box>
                      <Text as="span" textStyle='dark-heading-2'>Environment: {qbEnv}</Text>
                    </Box>
                    <Box>
                      <Text as="span" textStyle='dark-heading-2'>djtClientCompanyName: {djtClientCompanyName}</Text>
                    </Box>
                    <Box>
                      <Text as="span" textStyle='dark-heading-2'>djtClientCompanyId: {djtClientCompanyId}</Text>
                    </Box>
                    <Box>
                      <Text as="span" textStyle='dark-heading-2'>djtClientCompanyEmail: {djtClientCompanyEmail}</Text>
                    </Box>
                    <Box>
                      <Text as="span" textStyle='dark-heading-2'>djtClientNeedsToSync: {djtClientNeedsToSync?.toString()}</Text>
                    </Box>
                    <Box>
                      <Text as="span" textStyle='dark-heading-2'>djtClientComtactCompanyName: {djtClientComtactCompanyName}</Text>
                    </Box>
                    
                    <Box>
                      <Text as="span" textStyle='dark-heading-2'>djtClient: {JSON.stringify(currentDJT?.djtClient)}</Text>
                    </Box>

                    <Box>
                      <Text as="span" textStyle='dark-heading-2'>QB Selected Client: {JSON.stringify(selectedCustomer)}</Text>
                    </Box>

                    {/* <Box m={'10px'}>
                      <Text as="span" textStyle='dark-heading-2'>If qbId or qbCustomer is null, we need to update the SpkDJTClientTbl EquipmentSummaryTable
                        for this client.</Text>
                    </Box>
                    <Box m={'10px'}>
                      <Text as="span" textStyle='dark-heading-2'>If qbId for profuction or qbSandboxId for sandbox is null (or wrong) we need to sync 
                        down from Quicknooks and update the client tbl.</Text>
                    </Box> */}

                    {/* <Box p={4} >
                      <Text as="span" color='red' textStyle='dark-heading-2'>{(djtClientNeedsToSync) && 'QB DATA DIFFERENT THAN CLIENT DATA - WE NEED TO SYNC'}</Text>
                    </Box> */}

                    {/* <Box p={4} borderWidth='1px' borderRadius='lg'>
                      <Box w='400px'>
                      {(customers?.length > 0) && <FormSelectSimple
                        key='select_customer'
                        register={register}
                        control={control}
                        errors={errors}
                        fieldname="selectCustomer"
                        fieldlabel="Customers"
                        optionsArray={customers?.map(({ Id, FullyQualifiedName }) => ({
                          label: `${Id} ${FullyQualifiedName}`,
                          value: Id,
                        }))}
                        onChange={(e) => handleCustomerChanged(e)}
                        selectedOption={selectedCustomer}
                      />}
                      </Box>
                    </Box>

                    <Box>
                      <Text as="span" textStyle='dark-heading-2'>selectedQBCustomerObj: <ObjectRenderer obj={selectedQBCustomerObj} /></Text>
                    </Box>
                    <Box>
                      <Text as="span" textStyle='dark-heading-2'>ID: {selectedQBCustomerObj?.Id}</Text>
                    </Box>
                    <Box>
                      <Text as="span" textStyle='dark-heading-2'>FullyQualifiedName: {selectedQBCustomerObj?.FullyQualifiedName}</Text>
                    </Box>
                    <Box>
                      <Text as="span" textStyle='dark-heading-2'>PrimaryEmailAddr: {selectedQBCustomerObj?.PrimaryEmailAddr?.Address}</Text>
                    </Box>

                    <Box>
                      <Button
                        isDisabled={(!Number(selectedQBCustomerObj?.Id))}
                        type="button"
                        // w="100%"
                        onClick={() => {
                          handleSyncQBClientData(selectedQBCustomerObj)
                        }}
                        >Sync QB Data DJT Client</Button>
                    </Box> */}
                    

                    {/* <Box h='20px'></Box>
                    <Box>
                      <Input 
                        ref={inputRef} 
                        type="file" 
                        id="input-file-upload" 
                        multiple={false} 
                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                        // onChange={handleChange} 
                      /> */}

                      {/* <HStack spacing={4}>
                        <Button
                          onClick={onButtonClick}
                          isDisabled={false}
                          // colorScheme="green"
                          // w="full"
                        >Upload a file</Button>
                        <TextInput
                          fieldname="attachedFile"
                          fieldlabel="Attached File"
                          prettyname="Attached File"
                          register={register}
                          errors={errors}
                          placeholder="Attached File"
                          isRequired={false} 
                          isReadOnly={true}
                          // isDisabled={true}
                        />
                      </HStack>
                      {attachableObjects?.map((attachedObj, index) => {
                        return(
                          <Box key={`attachable_${index}`}>
                            <TextInput
                              key={`attachableFilename_${index}`}
                              fieldname={'attachableFilename'}
                              fieldvalue={attachedObj?.FileName}
                              register={register}
                              errors={errors}
                              fieldtype='hidden'
                            /> 
                            <TextInput
                              key={`attachableId_${index}`}
                              fieldname={'attachableId'}
                              fieldvalue={attachedObj.Id}
                              register={register}
                              errors={errors}
                              fieldtype='hidden'
                            /> 
                          </Box>
                        )
                      })} */}
                    {/* </Box> */}

                    {/* <Box h='20px'></Box>
                    <Box>
                      <TextInput
                        fieldname="customerId"
                        // fieldlabel="QB Customer Id"
                        prettyname="QB Customer Id"
                        register={register}
                        errors={errors}
                        placeholder="QB customer Id"
                        isRequired={!isInvoiceDJTVisible} 
                        isReadOnly={true}
                        fieldtype='hidden'
                      />
                    </Box> */}
                    
                  </ModuleBox></>}

                  {/* {(isDebug) && <><ModuleBox>
                    <Box>
                      <Text as="span" textStyle='dark-heading-1'>DEBUG ONLY, WILL BE REMOVED</Text>
                    </Box>
                    <VStack>
                      <Box>
                        {previousSubmittedInvoices?.map((item, index) => {
                          return(
                            <Box key={uuidv4()+`_${index}`}>{item?.TxnDate} : {item?.Line[0]?.Description} : {item?.Line[0]?.ServiceDate}  : {item?.TotalAmt} </Box>
                          )
                        })}
                      </Box>
                      <Box>attachableObjects length: {attachableObjects?.length}</Box>
                        {attachableObjects?.map((item, index) => {
                          return(
                            <Box key={uuidv4()+`_${index}`}>attachableObject: {item?.TxnDate}  </Box>
                          )
                        })}
                      <Box>selectedTermOption: {selectedTerm?.label} </Box>
                      <Box>customerDataLoaded: {(customerDataLoaded) ? 'true' : 'false'}</Box>
                      <Box>debug screen width: {debugWidth}</Box>
                    </VStack>
                  </ModuleBox>
                  </>} */}

                  <ModuleBox>
                    <HStack>
                      <Box w='50%'>
                        <TextInput
                          fieldname="customerName"
                          fieldlabel="QB Customer Name"
                          prettyname="QB Customer Name"
                          register={register}
                          errors={errors}
                          placeholder="QB Customer Name"
                          isRequired={true} 
                          isReadOnly={true}
                        />
                      </Box>
                      <Box w='50%'>
                        <TextInput
                          fieldname="customerEmail"
                          fieldlabel="QB Customer Email"
                          prettyname="QB Customer Email"
                          register={register}
                          errors={errors}
                          placeholder="QB Customer Email"
                          isRequired={true} 
                          isReadOnly={true}
                        />
                      </Box>
                    </HStack>

                    <Flex my={'25px'}>
                      <Box w={'30%'} mr={'10px'}>
                        <TextareaInput
                          fieldname="billingAddress"
                          fieldlabel="Billing Address"
                          prettyname="Billing Address"
                          register={register}
                          errors={errors}
                          placeholder="Billing Address"
                          isRequired={true}
                          isReadOnly={true}
                          rows={5}
                        />
                      </Box>
                      <Spacer/>
                      <Box w={'15%'} mr={'10px'}>
                        {(terms && terms!==null && terms!==undefined) && 
                          <FormSelectSimple
                            register={register}
                            control={control}
                            errors={errors}
                            isRequired={true}
                            rules={{ required: "Terms is required" }}
                            // onChange={e => setSelectedTerm(e)}
                            onChange={e => handleTermsChanged(e)}
                            selectedoption={selectedTerm||''}
                            optionsArray={
                              terms?.map((options) => ({ 
                                label: options?.Name, value: options?.Id, term: options?.Term
                              }))
                            }
                            fieldname='selectTerms'
                            prettyname='Terms'
                            fieldlabel="Terms"
                            placeholder={'Select Terms'}
                          />}
                      </Box>
                      <Spacer/>
                      <Box w={'15%'} mr={'10px'}>
                        <TextInput
                          register={register}
                          errors={errors}
                          fieldname="invoiceDate"
                          fieldlabel="Invoice Date"
                          fieldvalue={invoiceDate||''}
                          prettyname="Invoice Date"
                          onChange={event => handleInvoiceDateChanged(event.target.value)}
                          isRequired={true} 
                          fieldtype='date'
                        />
                      </Box>
                      <Spacer/>
                      <Box w={'15%'} mr={'10px'}>
                        <TextInput
                          register={register}
                          errors={errors}
                          fieldname="invoiceDueDate"
                          fieldlabel="Due Date"
                          fieldvalue={invoiceDueDate||''}
                          prettyname="Due Date"
                          onChange={event => handleInvoiceDueDateChanged(event.target.value)}
                          isRequired={true} 
                          fieldtype='date'
                        />
                      </Box>
                      <Spacer/>
                      <Box w={'15%'}>
                        <TextInput
                          fieldname="poNumber"
                          fieldlabel="P.O. Number"
                          prettyname="P.O. Number"
                          register={register}
                          errors={errors}
                          placeholder="P.O. Number"
                          isRequired={false}
                          type='date'
                        />
                      </Box>
                    </Flex>

                    <Box w='30%'>
                      <FormSelectSimple
                        key='select_class'
                        register={register}
                        control={control}
                        errors={errors}
                        isRequired={true}
                        rules={{ required: "Class is required" }}
                        onChange={(e) => handleClassChanged(e)}
                        selectedOption={selectedClass||''}
                        optionsArray={classes?.map(({ Id, FullyQualifiedName }) => ({
                          label: `${FullyQualifiedName}`,
                          value: Id,
                        }))}
                        fieldname="selectClass"
                        fieldlabel='QuickBooks Class'
                        prettyname='Class'
                        placeholder={'Select class or start typing'}
                      />                        
                      </Box>

                      <Flex my={'25px'}>
                      <Box>
                        <Text as="span" textStyle='heading-1'>Attach Files</Text><br/>
                        <Text as="span" color={'var(--dark-text-grey-2)'} textStyle='text-2'>Choose to attach the Daily Job Tickets to the emailed invoice.</Text>
                      </Box>
                      <Spacer />
                      <Box>
                        <FormSwitch
                          register={register}
                          control={control}
                          fieldname="attachFiles"
                          isChecked={attachFilesChecked||false}
                          // onChange={(e) => {
                          //   setAttachFilesChecked(!attachFilesChecked)
                          // }}
                          onChange={() => {
                            setValue('attachFiles', !attachFilesChecked)
                            setAttachFilesChecked(!attachFilesChecked)
                          }}
                        />
                      </Box>
                    </Flex>

                  </ModuleBox>

                  <ModuleBox>
                    {/* Legacy, remove later */}
                    {/* <Box>
                      {(companyDJTs) && companyDJTs?.map((item, index) => {
                        // console.warn('company djts line items: ', item)
                        const djtSubtotal = (Number(item.djtLaborTotal) + Number(item.djtEquipmentTotal) + Number(item.djtMaterialTotal))
                        let djtObj = {
                          djtId: item.id,
                          djtPoId: item.djtPOId,
                          AttachableId: item.attachableObjId,
                          LaborServiceDate: item.djtDate,
                          EquipmentServiceDate: item.djtDate,
                          MaterialServiceDate: item.djtDate,
                          LaborTotal: item.djtLaborTotal,
                          LaborQuantity: '1',
                          LaborRate: item.djtLaborTotal,
                          LaborAmount: item.djtLaborTotal,
                          EquipmentTotal: item.djtEquipmentTotal,
                          EquipmentQuantity: '1',
                          EquipmentRate: item.djtEquipmentTotal,
                          EquipmentAmount: item.djtEquipmentTotal,
                          MaterialTotal: item.djtMaterialTotal,
                          MaterialQuantity: '1',
                          MaterialRate: item.djtMaterialTotal,
                          MaterialAmount: item.djtMaterialTotal,
                          DJTSubtotal: djtSubtotal
                        }

                        // which item matches?
                        let elementIndex = disabledButton.findIndex( element => element.id === item.id );

                        return(
                          <Button m={2}
                            key={uuidv4()}
                            name={`djtrow[${index}].Button`}
                            isDisabled={disabledButton[elementIndex].state}
                            type="button"
                            onClick={() => {
                              const totalAmount = getValues('TotalAmount')
                              const newTotalAmount = Number(totalAmount) + Number(djtSubtotal)
                              setValue('TotalAmount', newTotalAmount)
                              let tmpArray = [...disabledButton]
                              tmpArray[elementIndex].state = true
                              setDisabledButton(tmpArray)

                              djtAppend(djtObj);
                            }}
                            >{item.djtDate}
                          </Button>
                        )
                      })}
                    </Box> */}
                    
                    {/* <TextInput
                      fieldname='supervisorId'
                      fieldvalue={currentDJT?.teamMember?.id||''}
                      register={register}
                      errors={errors}
                      fieldtype='hidden'
                    />
                    <TextInput
                      fieldname='divisionId'
                      fieldvalue={currentDJT?.djtDivisionId||''}
                      register={register}
                      errors={errors}
                      fieldtype='hidden'
                    />
                    <TextInput
                      fieldname='clientId'
                      fieldvalue={currentDJT?.djtClient?.id||''}
                      register={register}
                      errors={errors}
                      fieldtype='hidden'
                    /> */}

                    <Box mt={5}>
                      {(djtFields) && djtFields.map((item, index) => {
                        const doNotBill = item?.doNotBill||false
                        const doNotBillDescription = item?.doNotBillDescription||''
                        const specialInstructions = item?.specialInstructions||null
                        // console.warn('djtFields: ', djtFields)
                        return (
                          <React.Fragment key={uuidv4()}>
                            {(index>0) && <Divider marginTop={'25px'} marginBottom={'15px'} />}
                            <HStack width={'full'} paddingBottom={'25px'}>
                              <Box>
                                <Text as="span" textStyle='dark-text-1'>{spellOutDate(item?.LaborServiceDate, 'apple-fy')}</Text>
                                <Text as="span" color='var(--error-red)' textStyle='dark-text-1'>{(doNotBill) && ' - DO NOT INVOICE: '+doNotBillDescription}</Text>
                              </Box>
                              <Spacer />
                              <Box>
                                <ButtonSenaryWithIconOnly
                                  key={index+'_button_'+item.id}
                                  name='remove'
                                  icon='delete'
                                  iconsize='22px'
                                  onClick={() => {
                                    // get this rows match in the disabledButtons array and set false
                                    // let elementIndex = disabledButton.findIndex( element => element.id === item.djtId );
                                    // let tmpArray = [...disabledButton]
                                    // tmpArray[elementIndex].state = false
                                    // setDisabledButton(tmpArray)

                                    let subTotal = getValues(`djtrow[${index}].DJTSubtotal`)
                                    let totalAmount = getValues('TotalAmount')
                                    
                                    subTotal = convertCurrencyToDouble(subTotal)                                    
                                    totalAmount = convertCurrencyToDouble(totalAmount)

                                    let grandTotal = Number(totalAmount) - Number(subTotal)
                                    setDjtGrandTotal(grandTotal)

                                    const newTotalAmount = Number(totalAmount) - Number(subTotal)
                                    setValue('TotalAmount', USDollar.format(newTotalAmount))
                                    
                                    // let tmpArr = pdfPagesArray
                                    // tmpArr.splice(index, 1)
                                    // setPdfPagesArray(tmpArr)
                                    djtRemove(index)
                                    attachableObjects.splice(index, 1)
                                    enableToolbar(item?.djtId)
                                    setIsMenuDisabled(false)
                                  }}
                                />
                              </Box>
                            </HStack>

                            {(specialInstructions) && <Box paddingBottom={'25px'}>
                              <Text as="span" textStyle='dark-text-1'>SPECIAL BILLING INSTRUCTIONS: </Text>
                              <Text as="span" textStyle='dark-text-1'>{specialInstructions}</Text>
                            </Box>}
                            
                            <HStack spacing={2} key={uuidv4()}>
                                
                              <Box w='15%' key={uuidv4()}><TextInput
                                key={index+'_1_1_'+item.id}
                                fieldname={`djtrow[${index}].LaborServiceDate`}
                                placeholder="Service Date"
                                register={register}
                                errors={errors}
                                isRequired={true} 
                                isReadOnly={true}
                                fieldlabel="Service Date"
                              /></Box>

                              <Box w='15%' key={uuidv4()}><TextInput
                                key={index+'_1_2_'+item.id}
                                fieldname={`djtrow[${index}].LaborDescription`}
                                fieldlabel="Product/Service"
                                fieldvalue={'Labor'}
                                register={register}
                                errors={errors}
                                isRequired={true} 
                                isReadOnly={true}
                              /></Box>

                              <Box key={uuidv4()}><TextInput
                                key={index+'_1_3_'+item.id}
                                fieldname={`djtrow[${index}].LaborNotes`}
                                fieldlabel="Description"
                                prettyname="Description"
                                fieldvalue={item?.djtNotes||''}
                                register={register}
                                errors={errors}
                                isReadOnly={true}
                              /></Box>

                              <Box w='15%' key={uuidv4()}><TextInput
                                key={index+'_1_4_'+item.id}
                                fieldname={`djtrow[${index}].LaborClass`}
                                fieldlabel="Class (hidden)"
                                fieldvalue={selectedClassName||''}
                                register={register}
                                errors={errors}
                                isReadOnly={true}
                                placeholder={selectedClassName||''}
                              /></Box>

                              <Box w='15%' key={uuidv4()}><TextInput
                                key={index+'_1_5_'+item.id}
                                fieldname={`djtrow[${index}].LaborAmount`}
                                fieldlabel="Amount"
                                textAlign='right'
                                register={register}
                                errors={errors}
                                isRequired={true} 
                                isReadOnly={true}
                              /></Box>

                            </HStack>
                            
                            <TextInput
                              key={index+'_1_6_'+item.id}
                              fieldname={`djtrow[${index}].LaborQuantity`}
                              register={register}
                              errors={errors}
                              isRequired={true} 
                              isReadOnly={true}
                              fieldtype='hidden'
                            />

                            <TextInput
                              key={index+'_1_7_'+item.id}
                              fieldname={`djtrow[${index}].LaborRate`}
                              register={register}
                              errors={errors}
                              fieldtype='hidden'
                            />

                            <HStack spacing={2} key={uuidv4()}>

                              <Box w='15%' key={uuidv4()}><TextInput
                                key={index+'_2_1_'+item.id}
                                fieldname={`djtrow[${index}].EquipmentServiceDate`}
                                placeholder="Service Date"
                                register={register}
                                errors={errors}
                                isRequired={true} 
                                isReadOnly={true}
                              /></Box>

                              <Box w='15%' key={uuidv4()}><TextInput
                                key={index+'_2_2_'+item.id}
                                fieldname={`djtrow[${index}].EquipmentDescription`}
                                fieldvalue={'Equipment'}
                                register={register}
                                errors={errors}
                                isRequired={true} 
                                isReadOnly={true}
                              /></Box>

                              <Box key={uuidv4()}><TextInput
                                key={index+'_2_3_'+item.id}
                                fieldname={`djtrow[${index}].EquipmentNotes`}
                                fieldvalue={''}
                                register={register}
                                errors={errors}
                                isReadOnly={true}
                                placeholder={''}
                              /></Box>

                              <Box w='15%' key={uuidv4()}><TextInput
                                key={index+'_2_4_'+item.id}
                                fieldname={`djtrow[${index}].EquipmentClass`}
                                fieldvalue={selectedClassName||''}
                                register={register}
                                errors={errors}
                                isReadOnly={true}
                                placeholder={selectedClassName||''}
                              /></Box>

                              <Box w='15%' key={uuidv4()}><TextInput
                                key={index+'_2_5_'+item.id}
                                fieldname={`djtrow[${index}].EquipmentAmount`}
                                register={register}
                                textAlign='right'
                                errors={errors}
                                isRequired={true} 
                                isReadOnly={true}
                              /></Box>

                            </HStack>

                            <TextInput
                              key={index+'_2_6_'+item.id}
                              fieldname={`djtrow[${index}].EquipmentQuantity`}
                              register={register}
                              errors={errors}
                              isRequired={true} 
                              isReadOnly={true}
                              fieldtype='hidden'
                            />

                            <TextInput
                              key={index+'_2_7_'+item.id}
                              fieldname={`djtrow[${index}].EquipmentRate`}
                              register={register}
                              errors={errors}
                              fieldtype='hidden'
                            />

                            <HStack spacing={2} key={uuidv4()}>

                              <Box w='15%' key={uuidv4()}><TextInput
                                key={index+'_3_1_'+item.id}
                                fieldname={`djtrow[${index}].MaterialServiceDate`}
                                placeholder="Service Date"
                                register={register}
                                errors={errors}
                                isRequired={true} 
                                isReadOnly={true}
                              /></Box>

                              <Box w='15%' key={uuidv4()}><TextInput
                                key={index+'_3_2_'+item.id}
                                fieldname={`djtrow[${index}].MaterialDescription`}
                                fieldvalue={'Material'}
                                register={register}
                                errors={errors}
                                isRequired={true} 
                                isReadOnly={true}
                              /></Box>

                              <Box key={uuidv4()}><TextInput
                                key={index+'_3_3_'+item.id}
                                fieldname={`djtrow[${index}].MaterialNotes`}
                                fieldvalue={''}
                                register={register}
                                errors={errors}
                                isReadOnly={true}
                                placeholder={''}
                              /></Box>

                              <Box w='15%' key={uuidv4()}><TextInput
                                key={index+'_3_4_'+item.id}
                                fieldname={`djtrow[${index}].MaterialClass`}
                                fieldvalue={selectedClassName||''}
                                register={register}
                                errors={errors}
                                isReadOnly={true}
                                placeholder={selectedClassName||''}
                              /></Box>

                              <Box w='15%' key={uuidv4()}><TextInput
                                key={index+'_3_5_'+item.id}
                                fieldname={`djtrow[${index}].MaterialAmount`}
                                register={register}
                                textAlign='right'
                                errors={errors}
                                isRequired={true} 
                                isReadOnly={true}
                              /></Box>

                            </HStack>

                            <TextInput
                              key={index+'_3_6_'+item.id}
                              fieldname={`djtrow[${index}].MaterialQuantity`}
                              register={register}
                              errors={errors}
                              isRequired={true} 
                              isReadOnly={true}
                              fieldtype='hidden'
                            />

                            <TextInput
                              key={index+'_3_7_'+item.id}
                              fieldname={`djtrow[${index}].MaterialRate`}
                              register={register}
                              errors={errors}
                              fieldtype='hidden'
                            />

                            <Flex key={uuidv4()}>
                              <Box key={uuidv4()}></Box>
                              <Spacer />
                              <Box w={'25%'} key={uuidv4()} mt={5}>
                                <TextInput
                                  key={index+'_6_'+item.id}
                                  fieldname={`djtrow[${index}].DJTSubtotal`}
                                  fieldlabel="Subtotal"
                                  textAlign='right'
                                  register={register}
                                  errors={errors}
                                  isRequired={true} 
                                  isReadOnly={true}/>
                              </Box>
                            </Flex>

                            <TextInput
                              key={uuidv4()}
                              fieldname={`djt[${index}].djtNotes`}
                              fieldvalue={item.djtNotes||''}
                              register={register}
                              errors={errors}
                              fieldtype='hidden'
                            />
                          </React.Fragment>

                        );
                      })}
                      <Flex>
                        <Box></Box>
                        <Spacer />
                        <Box w={'25%'} mt={5}>
                          <TextInput
                            fieldname='TotalAmount'
                            fieldlabel="Total"
                            register={register}
                            errors={errors}
                            textAlign='right'
                            isRequired={true} 
                            isReadOnly={true}
                            defaultValue='$0.00'
                          />
                        </Box>
                      </Flex>

                      <Box w={'40%'}>
                        <TextareaInput
                          fieldname="customerMemo"
                          fieldlabel="Customer Note"
                          prettyname="Customer Note"
                          register={register}
                          errors={errors}
                          // placeholder="Billing Address"
                          defaultvalue={'Thank you for your business.'}
                          rows={3}
                        />
                      </Box>
                    </Box>
                    
                    <HStack my={'25px'} key={uuidv4()}>

                      <Box w={'40%'}>
                        <ButtonSecondaryPlain 
                          width={'100%'}
                          name='cancel'
                          value='Cancel'
                          onClick={() =>  handleInvoiceReset()}
                          isDisabled={!isFormLoaded}
                        />  
                      </Box>

                      {/* This has changed to auto setting to uninvoiced on ticket submission */}
                      {/* <Box w={'25%'}>
                        <ButtonSecondaryPlain 
                          width={'100%'}
                          name='invoiced-native'
                          value='Already Invoiced via QB'
                          // onClick={() => handleBilledOther(currentDJT)}
                          onClick={() => handleBilledOther(djtFields)}
                          isDisabled={!isFormLoaded}
                        />
                      </Box> */}
                      
                      <Box w={'60%'}>
                        <ButtonPrimaryPlain
                          type="submit"
                          onClick={handleSubmit(formSubmit, onError)}
                          width={'100%'}
                          name='submit-invoice'
                          value='Submit & Email Invoice via QuickBooks'
                          isDisabled={!isFormLoaded || djtFields?.length===0 || (attachableObjects?.length!==djtFields?.length)}
                        />
                      </Box>
                    </HStack>
                  </ModuleBox>
                </>}
              
              </fieldset>

            </>
          </Container>
        )}
      </QuickBooksAuth>

      <ModuleDrawer
        onClose={onViewDjtClose}
        isOpen={isViewDjtOpen}
        bodyContent={<ViewDailyJobTicket srcId={djtId} onClose={onViewDjtClose} drawerCloseButton={CustomCloseDrawerButton('view')}/>}
        size={'full'}
      />

      <CenteredStatusProgressModal
        isModalOpen={isStatusModalOpen}
        onModalClose={onStatusModalClose}
        content={statusModalContent}
        closeButtonDisabled={statusModalCloseDisabled}
        handleSubmitModalClosed={handleStatusModalClosed}
        progress={statusProgress}
        successMessage={successMessage}
      />
    </>
  )
}