import { getDailyJobTicketByIdForApproval } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const fetchDailyJobTicketByIdForApproval = async (id) => {
  if (!id) return 'Missing DJT ID';

  try {
    const response = await client.graphql({
      query: getDailyJobTicketByIdForApproval,
      variables: { id: id }
    });

    const results = response.data.getSpkDailyJobTicketTbl;    
    return results;
  } catch (err) {
    return ('error fetchDailyJobTicketByIdForApproval: ', [err]);
  }
};

fetchDailyJobTicketByIdForApproval.propTypes = {
  id: PropTypes.string.isRequired,
};
