export const getVendors = async (token, environment, startPosition, maxResults) => {
  if (!token) {
    console.warn('NO TOKEN in getVendors')
    return null
  }

  const query = encodeURIComponent(`query?query=SELECT * FROM Vendor STARTPOSITION ${startPosition} MAXRESULTS ${maxResults}&minorversion=70`)

  const response = await fetch('https://ye5zper44kdptmvqao3fiyfzhe0tzcab.lambda-url.us-east-1.on.aws/?token='+token+'&query='+query+'&qbenv='+environment, { 
    method: 'GET', 
    headers: new Headers({
        'Authorization': 'Bearer '+token, 
        'Content-Type': 'application/x-www-form-urlencoded',
    })
  });

  const dataJSON = await response.json()
  let qbresponse = ''

  if (dataJSON.fault?.error.length>0) {
    qbresponse = 'refresh_token'
  }
  if (dataJSON.QueryResponse) {
    qbresponse = dataJSON.QueryResponse
  }
  return qbresponse
}