import { createUnitDocument } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const addUnitDocument = async (props) => {
  if (!props) return;
  
  try {
    const response = await client.graphql({
      query: createUnitDocument,
      variables: { input: props }
    });

    console.log('addClient response: ', response);
    const results = response.data.createSpkUnitDocumentTbl;
    console.log('addClient results: ', results);
    return results;
  } catch (err) {
    return ('error addUnitDocument: ', [err]);
  }
}

addUnitDocument.propTypes = {
  props: PropTypes.object.isRequired
};
