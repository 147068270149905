import { defineStyleConfig } from '@chakra-ui/react'

export const progressbar = defineStyleConfig({
    baseStyle: {
        colorScheme: 'progressbar',
        filledTrack: {
            // bg: '#0C84FF',
            // bg: '#00ff00',
            
            // colorScheme: 'progressbar',
        }
    }
})