import { listPlaidPendingTransactionsSortableByDate } from '../../../graphql/custom_queries';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const fetchPlaidPendingTransactions = async () => {
  try {
    const gqlParams = {
      pendingSort: 'pending',
      sortDirection: 'DESC',
    };

    const response = await client.graphql({
      query: listPlaidPendingTransactionsSortableByDate,
      variables: gqlParams,
    });

    const results = response.data.allPlaidPendingTransactionsSortableByDate?.items;

    return results;
  } catch (err) {
    return 'error fetchPlaidPendingTransactions: ' + err;
  }
};