import { createCreditCardTeamMemberMap } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const addCreditCardTeamMemberMap = async (props) => {
  if (!props) return;

  props.allSort = 'all';

  try {
    // console.warn('addCreditCardTeamMemberMap props: ', props);

    const gqlParams = {
      input: props,
    };

    const response = await client.graphql({
      query: createCreditCardTeamMemberMap,
      variables: gqlParams,
    });

    // console.warn('addCreditCardTeamMemberMap response: ', response);
    const results = response.data.createSpkCreditCardTeamMemberMapTbl.qbChartOfAccountsId;
    // console.warn('addCreditCardTeamMemberMap results: ', results);
    return results;
  } catch (err) {
    return('error addCreditCardTeamMemberMap: ', [err]);
  }
};

addCreditCardTeamMemberMap.propTypes = {
  props: PropTypes.shape({
    qbChartOfAccountsId: PropTypes.string.isRequired,
    qbChartOfAccountsName: PropTypes.string.isRequired,
    allSort: PropTypes.string, // Automatically set to 'all', so optional here.
  }).isRequired,
};