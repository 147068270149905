import { generateClient } from 'aws-amplify/api';
import { 
  djtSearchByDivisionStatus, 
  djtSearchByDivisionTeamMemberStatus, 
  djtSearchByDivisionTeamMemberClientStatus, 
  djtSearchByDivisionClientStatus 
} from '../../../graphql/custom_queries';

const client = generateClient();

export const searchDjtsByDivision = async (props) => {
  if (!props) return;

  try {
    const { djtStatusId, divisionId, teamMemberId, djtClientId, djtDate, djtEndDate, limit, nextToken } = props;

    const queryFilters = [
      //0
      {
        djtDivisionStatus: `${divisionId}#${djtStatusId}`, 
        limit, 
        sortDirection: 'DESC',
        nextToken,
      },
      //1
      {
        djtDivisionClientStatus: `${divisionId}#${djtClientId}#${djtStatusId}`, 
        limit, 
        sortDirection: 'DESC',
        nextToken,
      },
      //2
      {
        djtDivisionStatus: `${divisionId}#${djtStatusId}`, 
        limit, 
        sortDirection: 'DESC',
        nextToken,
        djtDate: { eq: djtDate }
      },
      //3
      {
        djtDivisionStatus: `${divisionId}#${djtStatusId}`, 
        limit, 
        sortDirection: 'DESC',
        nextToken,
        djtDate: { between: [djtDate, djtEndDate] }
      },
      //4
      {
        djtDivisionClientStatus: `${divisionId}#${djtClientId}#${djtStatusId}`, 
        limit, 
        sortDirection: 'DESC',
        nextToken,
        djtDate: { eq: djtDate }, 
      },
      //5
      {
        djtDivisionClientStatus: `${divisionId}#${djtClientId}#${djtStatusId}`, 
        limit, 
        sortDirection: 'DESC',
        nextToken,
        djtDate: { between: [djtDate, djtEndDate] }, 
      },
      //6
      {
        djtDivisionTeamMemberClientStatus: `${divisionId}#${teamMemberId}#${djtClientId}#${djtStatusId}`,
        limit, 
        sortDirection: 'DESC',
        nextToken,
      },
      //7
      {
        djtDivisionTeamMemberClientStatus: `${divisionId}#${teamMemberId}#${djtClientId}#${djtStatusId}`,
        limit, 
        sortDirection: 'DESC',
        nextToken,
        djtDate: { eq: djtDate }, 
      },
      //8
      {
        djtDivisionTeamMemberClientStatus: `${divisionId}#${teamMemberId}#${djtClientId}#${djtStatusId}`,
        limit, 
        sortDirection: 'DESC',
        nextToken,
        djtDate: { between: [djtDate, djtEndDate] }, 
      },
      //9
      {
        djtDivisionTeamMemberStatus: `${divisionId}#${teamMemberId}#${djtStatusId}`,
        limit, 
        sortDirection: 'DESC',
        nextToken,
        djtDate: { eq: djtDate }
      },
      //10
      {
        djtDivisionTeamMemberStatus: `${divisionId}#${teamMemberId}#${djtStatusId}`,
        limit, 
        sortDirection: 'DESC',
        nextToken,
        djtDate: { between: [djtDate, djtEndDate] }
      },
      //11
      {
        djtDivisionTeamMemberStatus: `${divisionId}#${teamMemberId}#${djtStatusId}`,
        limit, 
        sortDirection: 'DESC',
        nextToken,
      },
    ];

    const hasClient = !!djtClientId;
    const hasDjtDate = !!djtDate;
    const hasDjtEndDate = !!djtEndDate;
    const hasTeamMember = !!teamMemberId;

    let queryFilter = 0;

    if (hasTeamMember) {
      queryFilter = 11;
    }
    if (hasClient && !hasDjtDate && !hasDjtEndDate) {
      queryFilter = hasTeamMember ? 6 : 1;
    }
    if (hasClient && hasDjtDate && !hasDjtEndDate) {
      queryFilter = hasTeamMember ? 7 : 4;
    }
    if (hasClient && hasDjtDate && hasDjtEndDate) {
      queryFilter = hasTeamMember ? 8 : 5;
    }
    if (!hasClient && hasDjtDate && !hasDjtEndDate) {
      queryFilter = hasTeamMember ? 9 : 2;
    }
    if (!hasClient && hasDjtDate && hasDjtEndDate) {
      queryFilter = hasTeamMember ? 10 : 3;
    }

    let response, results;
    
    if ([9, 10, 11].includes(queryFilter)) {
      response = await client.graphql({
        query: djtSearchByDivisionTeamMemberStatus,
        variables: queryFilters[queryFilter]
      });
      results = response?.data?.djtByDivisionTeamMemberStatusComposite;
    } else if ([6, 7, 8].includes(queryFilter)) {
      response = await client.graphql({
        query: djtSearchByDivisionTeamMemberClientStatus,
        variables: queryFilters[queryFilter]
      });
      results = response?.data?.djtByDivisionTeamMemberClientStatus;
    } else if ([0, 2, 3].includes(queryFilter)) {
      response = await client.graphql({
        query: djtSearchByDivisionStatus,
        variables: queryFilters[queryFilter]
      });
      results = response?.data?.djtByDivisionStatusComposite;
    } else if ([1, 4, 5].includes(queryFilter)) {
      response = await client.graphql({
        query: djtSearchByDivisionClientStatus,
        variables: queryFilters[queryFilter]
      });
      results = response?.data?.djtByDivisionClientStatus;
    }

    return results;

  } catch (err) {
    console.error('error searchDjtsByDivision: ', err);
    return null;
  }
};
