import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Select } from "chakra-react-select";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Divider,
} from "@chakra-ui/react";
import { chakraStyles } from './SelectStyles';

export function FormSelectClearable(props) {
  const { 
    placeholder='',
    control, 
    fieldname, 
    fieldlabel='', 
    optionsArray=[], 
    selectedoption,
    isDisabled = false, 
    onChange, 
    isMulti = false,
    defaultValue=null,
    className='',
    classNamePrefix='',
  } = props;
  
  return (
    <Controller
      control={control}
      name={fieldname}
      render={({ field: { onChange: fieldOnChange, onBlur, ref, value }, fieldState: { error } }) => (
        <FormControl isInvalid={!!error} id={fieldname}>
          {fieldlabel && <FormLabel>{fieldlabel}</FormLabel>}

          <Select
            menuPortalTarget={document.body}
            selectedOptionColorScheme="spikegray"
            components={{
              IndicatorSeparator: () => <Divider orientation='vertical' color='spikegray.600' />,
            }}
            placeholder={placeholder}
            name={fieldname}
            ref={ref}
            onBlur={onBlur}
            value={selectedoption || value}
            options={optionsArray}
            closeMenuOnSelect={!isMulti}
            onChange={onChange || fieldOnChange}
            isDisabled={isDisabled}
            isMulti={isMulti}
            defaultValue={defaultValue}
            isClearable
            chakraStyles={chakraStyles}
            className={className}
            classNamePrefix={classNamePrefix}
          />

          <FormErrorMessage>{error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}

// Prop validation
FormSelectClearable.propTypes = {
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  fieldname: PropTypes.string.isRequired,
  fieldlabel: PropTypes.string,
  optionsArray: PropTypes.arrayOf(PropTypes.object),
  selectedoption: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
};

