import { listAllQBExpenseCategories } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchQuickBooksExpenseCategories = async (environment) => {
  try {
    let env = 'all';
    if (!environment || environment === 'sandbox') {
      env = 'sandbox';
    }

    const gqlParams = {
      allSort: env,
      filter: { isVisible: { eq: true } },
      limit: 250,
    };

    const response = await client.graphql({
      query: listAllQBExpenseCategories,
      variables: gqlParams,
    });

    // console.log('debug response: ', response);

    const results = response.data.allQBExpenseCategoriesSortableByName.items
      .sort((a, b) => (a.appName > b.appName ? 1 : -1));

    // console.log('debug results: ', results);
    
    return results;
  } catch (err) {
    return ('error fetchQuickBooksExpenseCategories: ', [err]);
  }
};

fetchQuickBooksExpenseCategories.propTypes = {
  environment: PropTypes.string,  // Optional string, no `isRequired` because it can be undefined
};