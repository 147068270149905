import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";
import { useState } from "react";

export function TextInputV2(props) {
  const { 
    fieldname='', 
    fieldlabel, 
    prettyname, 
    fieldtype='text', 
    regexPattern='',
    regexMessage='',
    fieldvalue='',
    variant='defaultinput',
    defaultValue,
    register, 
    errors, 
    placeholder='Placeholder', 
    isReadOnly=false, 
    isRequired=false, 
    isDisabled=false, 
    maximLength=100, 
    minimLength=0, 
    width,
    onChange,
    onBlur,
    onClick,
    onKeyPress,
    className,
    color,
    textAlign,
    autoComplete='off',
  } = props;

  const handleBlur = (onBlur) ? onBlur : () => {}
  const handleClick = (onClick) ? onClick : () => {}
  const handleKeyPress = (onKeyPress) ? onKeyPress : () => {}

  const [inputValue, setInputValue] = useState(fieldvalue);

  const defaultOnChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleChange = onChange || defaultOnChange;

  return (
    <FormControl isInvalid={errors[fieldname]}>
      <FormLabel htmlFor={fieldname}>{fieldlabel}</FormLabel>
      <Input
        width={width}
        length={maximLength}
        maxLength={maximLength}
        textAlign={textAlign}
        className={className}
        color={color}
        defaultValue={defaultValue}
        id={fieldname}
        autoComplete={autoComplete}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        type={fieldtype}
        variant={variant}
        name={fieldname}
        placeholder={placeholder}
        value={onChange ? fieldvalue : inputValue}
        onChange={handleChange}
        onMouseUp={(e) => e.preventDefault()}
        onKeyPress={(e) => handleKeyPress(e)}
        {...register(fieldname, { 
            onChange: (e) => handleChange(e),
            onBlur: (e) => handleBlur(e),
            onClick: (e) => handleClick(e),
            required: { 
              value: isRequired, 
              pattern: regexPattern,
              message: `${regexMessage}`||`${prettyname||fieldname} required.`, 
            }, 
            maxLength: { 
              value: maximLength, 
              message: `${prettyname} must be at most ${maximLength} characters`, 
            }, 
            minLength: { 
              value: minimLength, 
              message: `${prettyname} must be at least ${minimLength} characters`, 
            },
            pattern: {
              value: regexPattern,
              message: `${regexMessage}`||`${prettyname||fieldname} required.`, 
            },
        })} 
      />
      <FormErrorMessage>
        {errors[fieldname] && errors[fieldname].message}
      </FormErrorMessage>
    </FormControl>
  );
}

// Prop validation
TextInputV2.propTypes = {
  fieldname: PropTypes.string, // Fieldname is a string
  fieldlabel: PropTypes.string, // Fieldlabel is a string
  prettyname: PropTypes.string, // Prettyname is a string
  fieldtype: PropTypes.string, // Fieldtype is a string (e.g., 'text', 'email')
  regexPattern: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]), // Regex pattern can be a string or a RegExp
  regexMessage: PropTypes.string, // Regex message is a string
  fieldvalue: PropTypes.string, // Fieldvalue is a string
  variant: PropTypes.string, // Variant is a string
  defaultValue: PropTypes.string, // Default value is a string
  register: PropTypes.func.isRequired, // Register is a required function (from React Hook Form)
  errors: PropTypes.object, // Errors is an object, typically from React Hook Form
  placeholder: PropTypes.string, // Placeholder is a string
  isReadOnly: PropTypes.bool, // isReadOnly is a boolean
  isRequired: PropTypes.bool, // isRequired is a boolean
  isDisabled: PropTypes.bool, // isDisabled is a boolean
  maximLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minimLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Width can be a string or a number
  onChange: PropTypes.func, // onChange is a function
  onBlur: PropTypes.func, // onBlur is a function
  onClick: PropTypes.func, // onClick is a function
  onFocus: PropTypes.func, // onFocus is a function
  onKeyPress: PropTypes.func, // onKeyPress is a function
  className: PropTypes.string, // className is a string
  color: PropTypes.string, // Color is a string
  textAlign: PropTypes.string, // TextAlign is a string
  isInvalid: PropTypes.bool, // isInvalid is a boolean
  autoComplete: PropTypes.string, // autoComplete is a string
};

