import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  Switch
} from "@chakra-ui/react";

export function FormSwitch(props) {
  const { 
    control, 
    fieldname, 
    fieldlabel, 
    colorScheme, 
    size, 
    // register, 
    isDisabled=false, 
    isChecked=false, 
    onChange 
  } = props;

  const defaultColorScheme = (colorScheme) ? colorScheme : 'green';
  const defaultSize = (size) ? size : 'lg';

  return (
    <FormControl display='flex' alignItems='center'>
      <FormLabel htmlFor={fieldname}>{fieldlabel} </FormLabel>
      <Controller
        name={fieldname}
        control={control}
        render={({ field: props }) => (
          <Switch
            {...props}
            isChecked={isChecked}
            isDisabled={isDisabled}
            colorScheme={defaultColorScheme}
            size={defaultSize}
            onChange={(e) => {
              props.onChange(e.target.checked);
              if (onChange) onChange(e.target.checked);
            }}
          />
        )}
      />
    </FormControl>
  )
}

FormSwitch.propTypes = {
  control: PropTypes.object.isRequired, 
  fieldname: PropTypes.string.isRequired, 
  fieldlabel: PropTypes.string, 
  colorScheme: PropTypes.string, 
  size: PropTypes.string, 
  isDisabled: PropTypes.bool, 
  isChecked: PropTypes.bool, 
  onChange: PropTypes.func 
};