import { Controller } from 'react-hook-form';
import { Select } from "chakra-react-select";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Divider,
} from "@chakra-ui/react";
import { chakraStyles, customStyles } from './SelectStyles';

//https://stackoverflow.com/questions/55264659/how-do-i-figure-out-which-react-select-style-keys-map-to-which-components
//https://stackoverflow.com/questions/58801252/possible-to-change-font-color-on-react-select

export function FormSelectMulti(props) {
  
  const { 
    placeholder,
    control, 
    fieldname='', 
    fieldlabel, 
    optionsArray, 
    // selectedOption, 
    selectedoption, 
    register, 
    isDisabled=false, 
    isChecked=false, 

    // onChange, 
    onBlur,

    isMulti=false,
    defaultValue,
    // defaultvalue,
    className,
    classNamePrefix,
    // variant="brandPrimary",
    width,
    rules,
    ref,
    isclearable=false,
    iscreatable,
    issearchable,
    ismulti,
    errors,
  } = props;
  
  
  // const errorMessage = (errors[fieldname]?.message && errors[fieldname]?.message!=="") ?  errors[fieldname]?.message : "";

  // console.log('errorMessage: ', fieldname+' '+errors[fieldname]?.message)
  return (
    <Controller
      errors={errors}
      control={control}
      name={fieldname}
      rules={rules}
      render={({ 
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error }
      }) => (
        <FormControl 
          id={fieldname} 
          isInvalid={!!errors[fieldname]}> {/* errors[fieldname] exists after changing */}
        {(fieldlabel!=='') && <FormLabel>{fieldlabel}</FormLabel>}
            <Select
              // size="md"
              // variant={variant}  // spikeSelect, brandPrimary, defaultselect, outline, filled, flushed
              components={{ 
                IndicatorSeparator: () => <Divider orientation='vertical' color='spikegray.600'/>,
              }}
              {...props}
              name={fieldname}
              ref={ref}
              // onChange={onChange}
              onChange={props.onChange}
              onBlur={onBlur}
              // onChange={(e)=>props.onChange(e)}
              value={selectedoption}
              options={optionsArray}
              placeholder={placeholder}
              // _placeholder={{color: '#ffffff'}}
              // these 2 lines are needed for selects in chakra accordian to push it to the top level and not hide the dropdown behind the accrodian
              styles={customStyles}
              chakraStyles={chakraStyles}
              // styles={{ menuPortal: base => ({ ...base, zIndex: 9999, color:'#08699B', }) }}
              menuPortalTarget={document.body}

              // selectedOptionColorScheme="spikegray"
              selectedOptionColorScheme="spikegray"
              // focusBorderColor="spikeorange.500"
              // className={className}
              className="select"
              classNamePrefix={classNamePrefix}
              closeMenuOnSelect={true}
              isDisabled={isDisabled}
              // isMulti={ismulti}
              isClearable={isclearable}
              isSearchable={issearchable}
              // isCreatable={iscreatable}
              defaultValue={defaultValue}
            />

            <FormErrorMessage>
              {errors[fieldname]?.message}
            </FormErrorMessage>
          </FormControl>
      )}
    />
  )
}