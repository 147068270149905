export const getChartOfAccounts = async (token, environment) => {
  if (!token) {
    console.warn('NO TOKEN in getClasses')
    return null
  }

  const query = encodeURIComponent('query?query=select Id, Name, FullyQualifiedName, Active from Account where AccountType = \'Credit Card\'&minorversion=73')

  const response = await fetch('https://ye5zper44kdptmvqao3fiyfzhe0tzcab.lambda-url.us-east-1.on.aws/?token='+token+'&query='+query+'&qbenv='+environment, { 
    method: 'GET', 
    headers: new Headers({
        'Authorization': 'Bearer '+token, 
        'Content-Type': 'application/x-www-form-urlencoded',
      })
  });

  const dataJSON = await response.json()
  let qbresponse = ''

  if (dataJSON.fault?.error.length>0) {
    console.warn('GOT AN ERROR in getChartOfAccounts: ', dataJSON.fault?.error)
    qbresponse = null
  }
  if (dataJSON.QueryResponse) {
    qbresponse = dataJSON.QueryResponse
  }
  return qbresponse
}