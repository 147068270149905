import { jobClassRatesByDivisionId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchJobClassRatesByDivisionId = async (divisionId) => {
  try {
    const response = await client.graphql({
      query: jobClassRatesByDivisionId,
      variables: {
        divisionId: divisionId,
      },
    });

    const results = response.data.jobClassRatesByDivisionId.items;
    results.sort((a, b) => (a.hourlyRate > b.hourlyRate ? 1 : -1));
    return results;
  } catch (err) {
    return 'error fetchJobClassRatesByDivisionId: ' + err;
  }
};

fetchJobClassRatesByDivisionId.propTypes = {
  divisionId: PropTypes.string.isRequired,
};
