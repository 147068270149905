/* eslint-disable react/prop-types */
// import React from 'react';
import { useState } from 'react';
import {
  Center,
  Text,
  Box,
  IconButton,
  Spinner,
} from '@chakra-ui/react';

// import { ButtonQuaternaryPlain } from '../Form/Button/ButtonQuaternaryPlain.jsx';

import { useScreenSize } from '../custom-hooks/useScreenSize.jsx';

// decorative components
// import { Divider } from '../Divider.jsx';

import { AvailableIcons } from '../Icons/AvailableIcons.jsx';
// import { ScanQRCode } from '../ScanQR/ScanQRCode.jsx';

// import { TextInput } from '../Form/Input/TextInput.jsx';
import { TextInputGroup } from '../Form/Input/TextInputGroup.jsx';

import { PropTypes } from 'prop-types';

// import { fetchAllUnits } from '../graphqlCompnents/DJT/fetchAllUnits.jsx';
// import { fetchPopularUnitsByDivision } from '../graphqlCompnents/DJT/fetchPopularUnitsByDivision.jsx';

import { UnitsScrollableBox } from '../reactComponents/UnitsScrollableBox.jsx';
// import { awsFormatUnknownDate, todaysDateYMD } from '../functions/dateTime.jsx';

export const UnitsToInspect = (props) => {
  const {
    // tableData,
    // currentUserId,
    loadUnitInspection,
    register,
    errors,
    setValue,
    // currentDivisionId,
    userIsMobile,
    // screenWidth,
    unitsData,
    // popularUnitsWithDetails,
    displayedUnits,
    fullUnitsData,
    unitsDataIsLoading,
  } = props;

  // const [ showSpinner, setShowSpinner ] = useState(true)
  
  // tableData?.sort(function( a , b ){
  //   return a.createdById < b.createdById ? -1 : 1;
  // })
  
  const screenSize = useScreenSize();
  const screenHeight = screenSize.height;
  const screenWidth = (userIsMobile) ? '100%' : screenSize.width;
  // const calculatedHeight = screenHeight - 200;
  const calculatedHeight = screenHeight - 360;
  const resultsBoxHeight = Math.max(calculatedHeight, 250) + 'px';
  const boxPaddingX = '10px';
  const boxHeight = userIsMobile ? resultsBoxHeight : 'auto';

  // const [ unitsData, setUnitsData ] = useState([]);
  // const [ popularUnitsWithDetails, setPopularUnitsWithDetails] = useState([]);
  // const [ displayedUnits, setDisplayedUnits ] = useState([]);
  const [ searchedUnits, setSearchedUnits ] = useState([]);
  // const [ fullUnitsData, setFullUnitsData ] = useState([]);
  // const [ unitsDataIsLoading, setIsUnitsDataLoading ] = useState(false);
  const [searchInputText, setSearchInputText] = useState('');

  const filterData = (event) => {

    // If there's no event (or no search term), return the original data (reset)
    if (!event || !event.target?.value || event.target?.value?.length===0) {
      setSearchInputText('');
      setValue('searchInput', '');
      setSearchedUnits(displayedUnits);
    } else {
      // Continue with filtering logic
      const searchTerm = event?.target?.value;
      // console.log('searchTerm: ', searchTerm);
      const lowercasedTerm = searchTerm.toLowerCase();

      const tempData = fullUnitsData?.filter(item =>
        item?.code?.toLowerCase().includes(lowercasedTerm) ||
        item?.type?.name?.toLowerCase().includes(lowercasedTerm) || 
        item?.subType?.name?.toLowerCase().includes(lowercasedTerm)
      );

      setSearchInputText(searchTerm);
      setSearchedUnits(tempData);
      // setUnitsData(tempData);
      // setDisplayedUnits(tempData);
    }

  };

  // useEffect(() => {
  //   const fetchUnits = async () => {
  //     setIsUnitsDataLoading(true);
  //     try {
  //       // Fetch popular units by division
  //       let popularUnits = await fetchPopularUnitsByDivision(currentDivisionId, 10);

  //       // console.log('popularUnits: ', popularUnits);
  
  //       // Fetch all units
  //       let units = await fetchAllUnits(null, 500);
  //       if (units?.items?.length > 0) {
  //         // Add the 'inspectedViaSearch' property to each item and sort by item.code
  //         const updatedItems = units.items
  //           .map((item) => ({
  //             ...item, // Spread the existing item properties
  //             inspectedViaSearch: true, // Add the new property
  //           }))
  //           .sort((a, b) => a.codeSortable.localeCompare(b.codeSortable)); // Sort by code
    
  //         console.log('unitsData: ', updatedItems);
  
  //         // Ensure we update units data as before
  //         setUnitsData(updatedItems);
  //         setFullUnitsData(updatedItems);
  
  //         // Now create a lookup for the unitsData array
  //         const unitLookup = updatedItems.reduce((lookup, unit) => {
  //           lookup[unit.id] = unit;
  //           return lookup;
  //         }, {});
  
  //         // Map over popularUnits and combine with full unit details using the lookup
  //         const popularUnitsWithDetails = popularUnits.map((popularUnit) => {
  //           const matchingUnit = unitLookup[popularUnit.unitId]; // Use the lookup object for fast access
            
  //           return matchingUnit 
  //             ? { ...matchingUnit, popularity: popularUnit.popularity } 
  //             : null; // Return null if no match is found
  //         });
  
  //         // Clean up any nulls and set the resulting popular units with full details
  //         const popularUnitsCleaned = popularUnitsWithDetails.filter(unit => unit !== null);

  //         // Sort by popularity in descending order
  //         const sortedPopularUnits = popularUnitsCleaned.sort((a, b) => b.popularity - a.popularity);

  //         setDisplayedUnits(sortedPopularUnits);
  
  //         // Set the state with the detailed popular units
  //         setPopularUnitsWithDetails(sortedPopularUnits);

          
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     } finally {
  //       setIsUnitsDataLoading(false);
  //     }
  //   };
  
  //   fetchUnits();
  // }, [currentDivisionId, userIsMobile]);

  const searchIconCancel = <IconButton
    isDisabled={searchInputText?.length===0}
    variant='withIconQuaternary' 
    bg='var(--dark-module-bg)'
    h={'24px'}
    icon={<AvailableIcons boxSize={'24px'} iconRef={'searchReset'} color={'var(--dark-text-grey-1)'} />}
    onClick={() => {
      filterData({target: {value: ''}}, displayedUnits)
    }}
  />
  const searchIcon = <AvailableIcons boxSize={'24px'} iconRef={'searchMagnify'} color={'var(--dark-text-grey-1)'} />
  
  const resultsLabel = (searchInputText?.length===0) ? 'Top Units' : 'Search Results'

  return (
  <>
    <Box 
      mb={'25px'} 
      w={'100%'} 
      px={boxPaddingX} >
      <Box  w={'100%'} mt={'25px'} mb={'25px'}>
        {(unitsDataIsLoading) ? 
          <Center>
            <Spinner color='var(--progress-bar-primary)' />
          </Center>
        : <>
          <Box paddingBottom={'10px'}>
            <Text as="span" textStyle="heading-3">Find a unit to inspect</Text>
          </Box>
          <TextInputGroup
            register={register}
            errors={errors}
            fieldname="searchInput"
            // fieldlabel="Search units by unit name, type or subtype."
            fieldvalue={searchInputText}
            prettyname="Search"
            placeholder="Search by ID, type or subtype"
            onChange={(e) => filterData(e, unitsData)}
            leftButtonElement={searchIcon}
            rightButtonElement={searchIconCancel}
            buttonDisabled={searchInputText?.length===0}
            buttonElementOnClick={() => filterData({ target: { value: '' } }, fullUnitsData)}
          />
          </>
        }
      </Box>
      <Box>
        <Box paddingBottom={'10px'}>
          <Text as="span" textStyle="heading-3">{resultsLabel}</Text>
        </Box>
        <UnitsScrollableBox
          // items={unitsData}
          items={(searchedUnits?.length>0) ? searchedUnits : displayedUnits||[]}
          // width={'100%'}
          width={screenWidth.toString()}
          // height={userIsMobile && resultsBoxHeight}
          height={boxHeight}
          maxHeight={'650px'}
          handleSelectedObject={loadUnitInspection}
        />
      </Box>
    </Box>

    </>
  )
}

UnitsToInspect.propTypes = {
  // tableData: PropTypes.arrayOf(PropTypes.shape({
  //   createdById: PropTypes.string.isRequired,
  //   code: PropTypes.string.isRequired,
  //   createdBy: PropTypes.shape({
  //     firstName: PropTypes.string.isRequired,
  //     lastName: PropTypes.string.isRequired,
  //     goesBy: PropTypes.string, // Optional field
  //   }).isRequired,
  //   unitStatus: PropTypes.shape({
  //     status: PropTypes.shape({
  //       statusQuery: PropTypes.string,
  //     }),
  //   }),
  //   claimedById: PropTypes.string,
  //   thumbnailSignedUrl: PropTypes.string,
  // })),
  currentUserId: PropTypes.string.isRequired,
  loadUnitInspection: PropTypes.func.isRequired,
  userIsMobile: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  currentDivisionId: PropTypes.string.isRequired,
  // screenWidth: PropTypes.number,
  unitsData: PropTypes.array.isRequired,
  popularUnitsWithDetails: PropTypes.array.isRequired,
  displayedUnits: PropTypes.array.isRequired,
  fullUnitsData: PropTypes.array.isRequired,
  unitsDataIsLoading: PropTypes.bool.isRequired,
};