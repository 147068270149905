import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';

// graphql functions
import { fetchUnitOperatorsByDjtId } from '../graphqlCompnents/DJT/fetchUnitOperatorsByDjtId.jsx';
import { fetchUnitDriversByDjtId } from '../graphqlCompnents/DJT/fetchUnitDriversByDjtId.jsx';

// utility functions
import { USDollar } from '../functions/currency.jsx';
import { converNumberToDouble } from '../functions/number.jsx';

export const EquipmentSummaryTableViewable = (props) => {
  const {
    tableData=[],
    unitTotalAmount = 0.00,
    djtId='',
  } = props;

  const unitOperators = useQuery(['fetchUnitOperatorsByDjtId', djtId], () => fetchUnitOperatorsByDjtId(djtId), {
    enabled: !!djtId, refetchOnWindowFocus: false
  });

  const unitDrivers = useQuery(['fetchUnitDriversByDjtId', djtId], () => fetchUnitDriversByDjtId(djtId), {
    enabled: !!djtId, refetchOnWindowFocus: false
  });

  return (
    <TableContainer>
      <Table variant={'compact'} size={'compact'} >
        <Thead>
          <Tr>
            <Th><Text as="span" className='dark-sfpro-heading-4'>DESCRIPTION</Text></Th>
            <Th><Text as="span" className='dark-sfpro-heading-4'>UNIT ID</Text></Th>
            <Th><Text as="span" className='dark-sfpro-heading-4'>TOTAL</Text></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr colSpan='3' h='10px'><Td></Td></Tr>
          {tableData?.map((data, index) => {

            let description = data?.billableDescription;
            let unitCode = data?.unitCode;
            let total = data?.totalBilled;
            let showDriverRow = false;
            let driversArr = [];
            unitDrivers?.data?.forEach((item) => {
              if (item.unitId === data?.unitId) {
                showDriverRow = true;
                const driver = item?.drivenBy?.firstName + ' ' + item?.drivenBy?.lastName;
                driversArr.push(driver);
              }
            });

            let showOperatorRow = false;
            let operatorsArr = [];
            unitOperators?.data?.forEach((item) => {
              if (item.unitId === data?.unitId) {
                showOperatorRow = true;
                const operator = item?.operatedBy?.firstName + ' ' + item?.operatedBy?.lastName;
                operatorsArr.push(operator);
              }
            });

            return (
              <React.Fragment key={'fragment_' + index}>
                <Tr h='6px'><Td key={'td_labor_spacer_' + index} colSpan='3'></Td></Tr>
                <Tr h='30px' key={'tr_units_' + index} borderBottom={(showDriverRow || showOperatorRow) ? '' : '1px solid var(--dark-module-divider)'}>
                  <Td key={'td_units_1_' + index}>
                    {description}
                  </Td>
                  <Td key={'td_units_2_' + index} w={'50px'} textAlign={'right'}>
                    {unitCode}
                  </Td>
                  <Td w={'90px'} key={'td_units_5_' + index} textAlign={'right'}>
                    {total}
                  </Td>
                </Tr>
                {showDriverRow && 
                  <Tr h='30px' key={'tr_units_d_' + index} borderBottom={(showDriverRow && !showOperatorRow) ? '1px solid var(--dark-module-divider)' : ''}>
                    <Td key={'td_units_d_' + index} colSpan='3'><Text pl='20px'>Driver{(driversArr.length > 1) ? 's' : ''}: {driversArr.join(", ")}</Text></Td>
                  </Tr>
                }
                {showOperatorRow && 
                  <Tr h='30px' key={'tr_units_o_' + index} borderBottom={(showOperatorRow) ? '1px solid var(--dark-module-divider)' : ''}>
                    <Td key={'td_units_o_' + index} colSpan='3'><Text pl='20px'>Operator{(operatorsArr.length > 1) ? 's' : ''}: {operatorsArr.join(", ")}</Text></Td>
                  </Tr>
                }
              </React.Fragment>
            );
          })}
          <Tr><Td colSpan='3' h='15px'></Td></Tr>
        </Tbody>
        <Tfoot>
          <Tr>
            <Th colSpan='3' textAlign={'right'}>
              <Text textStyle='heading-3'>Equipment Total: {USDollar.format(converNumberToDouble(unitTotalAmount))}</Text>
            </Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

EquipmentSummaryTableViewable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({
    billableDescription: PropTypes.string,
    unitCode: PropTypes.string,
    totalBilled: PropTypes.string,
    unitId: PropTypes.string,
  })),
  unitTotalAmount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  djtId: PropTypes.string.isRequired,
};

export default EquipmentSummaryTableViewable;