import { listClientPosByDivisionClient } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchClientPOsByDivisionClient = async (props) => {
  if (!props) return;

  try {
    const variables = {
      poDivisionClient: `${props.division}#${props.client}`
    };

    const response = await client.graphql({
      query: listClientPosByDivisionClient,
      variables
    });

    const results = response.data.posByDivisionClient.items;
    
    return results;
  } catch (err) {
    return ('error fetchClientPOsByDivisionClient: ', [err]);
  }
}

fetchClientPOsByDivisionClient.propTypes = {
  props: PropTypes.object.isRequired,
};
