import { allDivisionsSortedByName } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const fetchAllDivisions = async (divisions) => {
  try {
    let filteredDivisions = divisions || "all";

    const response = await client.graphql({
      query: allDivisionsSortedByName,
      variables: {
        allSort: filteredDivisions,
        sortDirection: 'ASC'
      }
    });

    // console.warn(' ----- fetchAllDivisions response: ', response);
    const results = response.data.allDivisionsSortableByName.items
      .sort((a, b) => {
        if (a.prettyName > b.prettyName) return 1;
        if (a.prettyName < b.prettyName) return -1;
        return 0; // explicitly handling the case where they are equal
      });

    // console.warn(' ----- fetchAllDivisions results: ', results);
    return results;

  } catch (err) {
    console.error('error fetchAllDivisions: ', err);
    return [];
  }
};

fetchAllDivisions.propTypes = {
  divisions: PropTypes.string,
};
