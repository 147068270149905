import { updateDJTEquipment } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const updateDJTEquipmentUsed = async (props) => {
  if (!props || !props.id) return;

  try {
    console.log('running update equipment with props: ', props);
    
    const response = await client.graphql({
      query: updateDJTEquipment,
      variables: { input: props },
    });

    console.log('response: ', response);

    const results = response.data.updateSpkDJTEquipmentTbl.id;

    console.log('results: ', results);
    return results;
  } catch (err) {
    return ('error updateDJTEquipmentUsed: ', [err]);
  }
};

updateDJTEquipmentUsed.propTypes = {
  props: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // Add other fields as required by your input structure
  }).isRequired,
};
