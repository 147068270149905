import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import { Amplify } from 'aws-amplify'
import config from './aws-exports'
import './globals.css'
import './styleguide.css'
import './index.css'
import './framework.css'
import 'react-datepicker/dist/react-datepicker.css';
import { SetS3Config } from './services';
SetS3Config('prod-spike-unit-images', 'protected');

Amplify.configure(config)

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)