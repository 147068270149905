import PropTypes from 'prop-types';
import { IconButton } from '@chakra-ui/react';
import { AvailableIcons } from '../../Icons/AvailableIcons';

export const ButtonQuaternaryWithIconOnly = (props) => {
  const {
    name = '',
    ref,
    icon,
    iconsize = '20px',
    onClick = () => {},
    isDisabled = false,
    mx = '0px',
    my = '0px',
    w = '40px',
  } = props;

  return (
    <IconButton 
      {...props}
      w={w}
      name={name}
      ref={ref}
      variant='withIconQuaternary' 
      icon={<AvailableIcons boxSize={iconsize} iconRef={icon} />}
      onClick={onClick}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
    />
  );
};

// Prop validation
ButtonQuaternaryWithIconOnly.propTypes = {
  name: PropTypes.string, // Name is a string, defaulting to an empty string
  ref: PropTypes.oneOfType([ // ref can be a function or an object created by React.createRef()
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  icon: PropTypes.string.isRequired, // Icon is required and should be a string
  iconsize: PropTypes.string, // Icon size should be a string like "20px"
  onClick: PropTypes.func, // onClick is a function
  isDisabled: PropTypes.bool, // isDisabled is a boolean
  mx: PropTypes.oneOfType([ // mx can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([ // my can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  w: PropTypes.oneOfType([ // w (width) can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
};

//v3
// import PropTypes from 'prop-types';
// import { IconButton } from '@chakra-ui/react';
// import { AvailableIcons } from '../../Icons/AvailableIcons';
// import React from 'react';

// export const ButtonQuaternaryWithIconOnly = React.forwardRef(({
//   name = '',
//   icon,
//   iconsize = '20px',
//   onClick = () => {},
//   isDisabled = false,
//   mx = '0px',
//   my = '0px',
//   w = '40px',
//   ...rest // Spread the rest of the props to IconButton
// }, ref) => (
//   <IconButton
//     ref={ref}
//     name={name}
//     variant="withIconQuaternary"
//     icon={<AvailableIcons boxSize={iconsize} iconRef={icon} />}
//     onClick={onClick}
//     isDisabled={isDisabled}
//     mx={mx}
//     my={my}
//     w={w}
//     {...rest} // Spread the remaining props
//   />
// ));

// // Prop validation
// ButtonQuaternaryWithIconOnly.propTypes = {
//   name: PropTypes.string, // Name is a string, defaulting to an empty string
//   icon: PropTypes.string.isRequired, // Icon is required and should be a string
//   iconsize: PropTypes.string, // Icon size should be a string like "20px"
//   onClick: PropTypes.func, // onClick is a function
//   isDisabled: PropTypes.bool, // isDisabled is a boolean
//   mx: PropTypes.oneOfType([ // mx can be a string or a number
//     PropTypes.string,
//     PropTypes.number,
//   ]),
//   my: PropTypes.oneOfType([ // my can be a string or a number
//     PropTypes.string,
//     PropTypes.number,
//   ]),
//   w: PropTypes.oneOfType([ // w (width) can be a string or a number
//     PropTypes.string,
//     PropTypes.number,
//   ]),
// };

// ButtonQuaternaryWithIconOnly.displayName = 'ButtonQuaternaryWithIconOnly';