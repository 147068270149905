import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { allTeamMembersSortableByLastName } from '../../../graphql/custom_queries';

const client = generateClient();

export const fetchUnitOperators = async (limit) => {
  try {
    const response = await client.graphql({
      query: allTeamMembersSortableByLastName,
      variables: {
        allSort: "all",
        sortDirection: 'ASC',
        filter: { isActive: { eq: true } },
        limit: limit,
      },
    });

    const results = response.data.allTeamMembersSortableByLastName;
    return results;
  } catch (err) {
    return 'error fetchUnitOperators: ' + err;
  }
};

fetchUnitOperators.propTypes = {
  limit: PropTypes.number.isRequired,
};
