import { allTeamMembersSortableByLastNameForSelect } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const fetchAllTeamMembers = async (allsort) => {
  try {
    const gqlParams = {
      allSort: allsort || 'all',
      filter: { isActive: { eq: true } },
      sortDirection: 'ASC',
    };

    const response = await client.graphql({
      query: allTeamMembersSortableByLastNameForSelect,
      variables: gqlParams,
    });

    const results = response.data.allTeamMembersSortableByLastName.items;
    return results;
  } catch (err) {
    return 'error fetchAllTeamMembers: ' + err;
  }
};

fetchAllTeamMembers.propTypes = {
  allsort: PropTypes.string,
};