import { teamMemberCountByDivision } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';

import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const fetchTeamMemberCountByDivision = async (divisionId) => {
  if (divisionId) {
    try {
      const gqlParams = {
        divisionId: divisionId
      };
      const response = await client
      .graphql({
        query: teamMemberCountByDivision,
        variables: gqlParams
      })
      const results = response.data.teamMembersByDivisionId;
      // console.log('results: ', results);
      return results;
    } catch (err) {
      return('fetchTeamMemberCountByDivision error: ', [err]);
    }
  } else {
    return('Missing date')
  }
}

fetchTeamMemberCountByDivision.propTypes = {
  divisionId: PropTypes.node.isRequired,
};