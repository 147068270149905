import { listVerifiedEmailDomainsByClientId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const fetchVerifiedEmailDomainsByClientId = async (clientId) => {
  if (clientId) {
    try {
      const response = await client.graphql({
        query: listVerifiedEmailDomainsByClientId,
        variables: {
          clientId: clientId, 
          filter: { isActive: { eq: true } }
        }
      });

      // console.log('response: ', response)
      const results = response.data.verifiedEmailDomainsByClientId.items;
      return results;
    } catch(err) {
      return ('error fetchVerifiedEmailDomainsByClientId: ', [err]);
    }
  } else {
    return 'Missing params';
  }
};

fetchVerifiedEmailDomainsByClientId.propTypes = {
  clientId: PropTypes.string.isRequired,
};
