import { listDjtIdsByParentId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchDJTsByParentId = async (parentId) => {
  if (!parentId) return;

  try {
    const variables = { parentId: parentId };

    const response = await client.graphql({
      query: listDjtIdsByParentId,
      variables
    });

    const results = response.data.djtsByParentId.items;
        
    return results;
  } catch (err) {
    return ('error fetchDJTsByParentId: ', [err]);
  }
}

fetchDJTsByParentId.propTypes = {
  parentId: PropTypes.string.isRequired,
};
