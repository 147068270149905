import { updateDJTMaterial } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const updateDJTMaterialUsed = async (props) => {
  if (!props || !props.id) return;

  try {
    console.log('running update material with props: ', props);
    
    const response = await client.graphql({
      query: updateDJTMaterial,
      variables: { input: props },
    });

    console.log('response: ', response);

    const results = response.data.updateSpkDJTMaterialTbl.id;

    console.log('results: ', results);
    return results;
  } catch (err) {
    return ('error updateDJTMaterialUsed: ', [err]);
  }
};

updateDJTMaterialUsed.propTypes = {
  props: PropTypes.object.isRequired,
};
