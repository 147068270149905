import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    // background: 'purple.100',

    fontSize: '25px',
    _placeholder: {
      // color: '#898C90',
      color: 'red',
    },
    _focusWithin: {
      // ringColor: "purple.200",
      // ring: "2px",
      // ringOffset: "1px",
      // ringOffsetColor: "purple.100",
      // borderColor: "purple.50"
    }
  },
  icon: {
    // color: 'green.400',
  },
})


const spikeSelect = definePartsStyle({
  // define the part you're going to style
  field: {
    // background: 'green.100',

    fontSize: '25px',
    _placeholder: {
      color: '#898C90',
    },
    _focusWithin: {
      ringColor: "green.200",
      ring: "2px",
      ringOffset: "1px",
      ringOffsetColor: "green.100",
      borderColor: "green.50"
    }
  },
  icon: {
    color: 'red.400',
  },
})

const defaultselect = definePartsStyle({
  // define the part you're going to style
  field: {
    background: 'green.100',
    _placeholder: {
      color: '#898C90',
    },
    _focusWithin: {
      ringColor: "green.200",
      ring: "2px",
      ringOffset: "1px",
      ringOffsetColor: "green.100",
      borderColor: "green.50"
    }
  },
  icon: {
    color: 'red.400',
  },
})

const outline = definePartsStyle({
  field: {
    fontFamily: "mono",
    fontWeight: "bold",
    borderRadius: 0,
    borderColor: "red.100",

    _focusWithin: {
      ringColor: "purple.200",
      ring: "2px",
      ringOffset: "1px",
      ringOffsetColor: "purple.100",
      borderColor: "purple.50"
    }
  }
});

const filled = definePartsStyle({
  field: {
    background: "gray.300",
    _dark: {
      background: "gray.600"
    }
  },
  icon: {
    color: "gray.800",
    _dark: {
      color: "gray.200"
    }
  }
});

const flushed = definePartsStyle({
  field: {
    borderBottom: "1px dashed",
    borderColor: "purple.300"
  },
  icon: {
    fontSize: "6xl"
  }
});

const brandPrimary = definePartsStyle({
  field: {
    fontFamily: 'SFPro Text-Regular, Helvetica',
    // fontSize: '14px',
    fontSize: '18px',
    fontWeight: '400',
    // _placeholder: {
    //   color: '#898C90',
    // },
    
    background: "purple",
    border: "1px dashed",
    borderColor: "purple",
    borderRadius: "full",
    _dark: {
      background: "purple",
      border: "1px dashed",
      borderColor: "purple",
      borderRadius: "full",
    }
  },
  icon: {
    color: "purple",
    _dark: {
      color: "purple",
    }
  }
});

const xl = defineStyle({
  fontSize: "lg",
  px: "2",
  h: "12"
});

const df = defineStyle({
  fontSize: "15px",
  px: "2",
  h: "12"
});

const sizes = {
  xl: definePartsStyle({ field: xl }),
  df: definePartsStyle({ field: df }),
};

// df: {
//   fontSize: '15px',
//   px: '20px', // <-- these values are tokens from the design system
//   py: '10px', // <-- these values are tokens from the design system
// },

export const selectTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { spikeSelect, brandPrimary, defaultselect, outline, filled, flushed },
  sizes
});