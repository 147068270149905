import { getUnitSubTypeInspectionPoints } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const fetchInspectionPoints = async (id) => {
  if (!id) {
    return 'Missing params';
  }

  try {
    console.log('GOT ID: ', id);
    let pointsObjArr = [];

    const gqlParams = { id: id };

    const response = await client.graphql({
      query: getUnitSubTypeInspectionPoints,
      variables: gqlParams,
    });

    const results = response.data.getSpkUnitSubTypeTbl?.inspectionsPoints.items;
    console.warn('RESULTS: ', results);

    for (const pointsArr of results) {
      pointsObjArr.push(pointsArr.spkUnitInspectionPointTbl);
    }

    pointsObjArr.sort((a, b) => (a.order > b.order ? 1 : -1));

    return pointsObjArr;
  } catch (err) {
    return 'error fetchInspectionPoints: ' + err;
  }
};

fetchInspectionPoints.propTypes = {
  id: PropTypes.string.isRequired,
};
