import { Box, Text, VStack } from "@chakra-ui/react";
import { AvailableIcons } from "../../Icons/AvailableIcons";
import { Link } from "react-router-dom";

const InspectionsModule = () => (
  <Link to="/tm-unit-inspections">
    <Box
      borderRadius="12px"
      bg='var(--dark-module-bg)'
      width="100%"
      minWidth={'140px'}
      height={"126px"}
    >
      <VStack align="start">
        <Box paddingTop={'25px'} paddingLeft={'25px'} paddingBottom={'5px'}>
          <Box 
            borderRadius={'6px'} 
            w={'50px'} 
            h={'50px'} 
            bg={'var(--icon-gold-bg)'} 
            display="flex" 
            justifyContent="center" 
            alignItems="center"
          >
            <AvailableIcons 
              boxSize={'28px'} 
              color={'var(--dark-gold-primary)'} 
              bgcolor={'var(--icon-gold-bg)'} 
              iconRef={'iconDailyInspections'} 
            />
          </Box>
        </Box>  
        <Text paddingLeft={'25px'} className="sfprotext-semi-bold-white-15px">Inspections</Text>
      </VStack>
    </Box>
  </Link>
);

export { InspectionsModule };