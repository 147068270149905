import { updateIFTAFuelTaxTracking } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const updateIFTAFuelTracking = async (props) => {
  if (!props) return;
  
  console.log('updateIFTAFuelTaxTracking props: ', props);
  
  try {
    const response = await client.graphql({
      query: updateIFTAFuelTaxTracking,
      variables: { input: props },
    });

    console.log('updateIFTAFuelTaxTracking response: ', response);

    const results = response.data.updateSpkIFTAFuelTaxTrackingTbl.id;
    console.log('updateIFTAFuelTaxTracking results: ', results);

    return results;
  } catch (err) {
    return ('error updateIFTAFuelTaxTracking: ', [err]);
  }
};

updateIFTAFuelTracking.propTypes = {
  props: PropTypes.shape({
    // Define the structure of "props" based on what is expected by the mutation.
  }).isRequired,
};
