import { createCreditCardChartOfAccounts } from '../../../graphql/custom_mutations';
import { updateCreditCardChartOfAccounts } from '../../../graphql/custom_mutations';
import { getCreditCardChartOfAccounts } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const addOrUpdateChartOfAccounts = async (props) => {
  if (!props.qbChartOfAccountsId || !props.qbChartOfAccountsName) return;

  try {
    // Step 1: Query the item first to see if it already exists
    const existingItemResponse = await client.graphql({
      query: getCreditCardChartOfAccounts, // Replace with your actual query
      variables: { qbChartOfAccountsId: props.qbChartOfAccountsId },
    });

    const existingItem = existingItemResponse.data.getSpkCreditCardChartOfAccountsTbl;

    if (existingItem) {
      // Step 2: If the item exists, update it
      const updateResponse = await client.graphql({
        query: updateCreditCardChartOfAccounts,
        variables: { input: props },
      });

      const updateResults = updateResponse.data.updateSpkCreditCardChartOfAccountsTbl.qbChartOfAccountsId;
      return updateResults;
    } else {
      // Step 3: If the item does not exist, create it
      const createResponse = await client.graphql({
        query: createCreditCardChartOfAccounts,
        variables: { input: props },
      });

      const createResults = createResponse.data.createSpkCreditCardChartOfAccountsTbl.qbChartOfAccountsId;
      return createResults;
    }
  } catch (error) {
    console.error('Error in addOrUpdateChartOfAccounts:', JSON.stringify(error, null, 2));
    return `Error in addOrUpdateChartOfAccounts: ${JSON.stringify(error, null, 2)}`;
  }
};

addOrUpdateChartOfAccounts.propTypes = {
  props: PropTypes.shape({
    qbChartOfAccountsId: PropTypes.string.isRequired,
    qbChartOfAccountsName: PropTypes.string.isRequired,
    allSort: PropTypes.string, // Automatically set to 'all', so optional here.
  }).isRequired,
};