import PropTypes from 'prop-types';
import { useContext } from 'react';
import { FormContext } from '../Form';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";

export function TextInput(props) {
  const { 
    fieldname = '', 
    fieldlabel, 
    prettyname, 
    fieldtype = 'text', 
    regexPattern = '',
    regexMessage = '',
    fieldvalue = '',
    variant = 'defaultinput',
    defaultValue,
    register, 
    errors, 
    placeholder = 'Placeholder', 
    isReadOnly = false, 
    isRequired = false, 
    isDisabled = false, 
    maximLength = 1000, 
    minimLength = 0, 
    width,
    onChange,
    onBlur,
    onClick,
    // onFocus,
    onKeyPress,
    className,
    color,
    textAlign,
    autoComplete = 'off',
  } = props;

  // if (typeof fieldvalue === 'boolean') {
  //   console.warn('fieldname: ', fieldname)
  // }

  const formContext = useContext(FormContext);
  const { form } = formContext;

  const handleChange = onChange || (() => {});
  const handleBlur = onBlur || (() => {});
  const handleClick = onClick || (() => {});
  // const handleFocus = onFocus || (() => {});
  const handleKeyPress = onKeyPress || (() => {});

  const defaultFieldValue = fieldvalue || form[fieldname];
  const defaultFieldType = fieldtype || 'text';

  return (
    <FormControl isInvalid={errors[fieldname]}>
      {fieldlabel && <FormLabel htmlFor={fieldname}>{fieldlabel}</FormLabel>}
      <Input
        width={width}
        maxLength={maximLength}
        textAlign={textAlign}
        className={className}
        color={color}
        defaultValue={defaultValue}
        id={fieldname}
        autoComplete={autoComplete}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        type={defaultFieldType}
        variant={variant}
        name={fieldname}
        placeholder={placeholder}
        value={defaultFieldValue}
        onFocus={(e) => e.target.select()}
        onMouseUp={(e) => e.preventDefault()}
        onKeyPress={handleKeyPress}
        {...register(fieldname, { 
          onChange: handleChange,
          onBlur: handleBlur,
          onClick: handleClick,
          required: isRequired && {
            value: true,
            message: regexMessage || `${prettyname || fieldname} is required.`,
          },
          maxLength: {
            value: maximLength,
            message: `${prettyname} must be at most ${maximLength} characters.`,
          },
          minLength: {
            value: minimLength,
            message: `${prettyname} must be at least ${minimLength} characters.`,
          },
          pattern: regexPattern && {
            value: regexPattern,
            message: regexMessage || `${prettyname || fieldname} is invalid.`,
          },
        })} 
      />
      <FormErrorMessage>
        {errors[fieldname]?.message}
      </FormErrorMessage>
    </FormControl>
  );
}

// Prop validation
TextInput.propTypes = {
  fieldname: PropTypes.string,
  fieldlabel: PropTypes.string,
  prettyname: PropTypes.string,
  fieldtype: PropTypes.string,
  regexPattern: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)]),
  regexMessage: PropTypes.string,
  fieldvalue: PropTypes.string,
  variant: PropTypes.string,
  defaultValue: PropTypes.string,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  maximLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minimLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  // onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
  textAlign: PropTypes.string,
  autoComplete: PropTypes.string,
};
