import { Controller } from 'react-hook-form';
import { CreatableSelect } from "chakra-react-select";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Divider,
} from "@chakra-ui/react";
import { chakraStyles, customStyles } from './SelectStyles';

export function FormSelectCreatableSingle(props) {
  const { 
    reference,
    placeholder,
    control, 
    fieldname, 
    fieldlabel, 
    optionsArray, 
    // selectedOption, 
    selectedoption,
    width,
    register, 
    isDisabled=false, 
    isChecked=false, 
    onChange, 
    onBlur,
    onFocus,
    onMenuOpen,
    isMulti=false,
    value,
    defaultValue,
    className,
    classNamePrefix,
    variant="brandPrimary",
    errors,
    rules,
    maxAuxillaryOptionsSelected=false,
    maxAuxillaryOptionLabel,
    maxAuzillaryOptionValue,
  } = props;

  // const customStyles = {
    // dropdownIndicator: base => ({
    //   ...base,
    //   color: "red" // Custom colour
    // }),
    // indicatorSeparator: base => ({
    //   ...base,
    //   innerProps: {colorSceme: 'red'}
    // })
    // singleValue: base => ({
    //   ...base,
    //   color:'green',
    //   fontSize: '23px',
    // }),
    // valueContainer: base => ({
    //   ...base,
    //   color:'green',
    //   fontSize: '23px',
    // }),
    // input: base => ({
    //   ...base,
    //   color:'#08699B',
    //   paddingTop:'3px',
    //   fontSize: '23px',
    // }),
    // option: base => ({
    //   ...base,
    //   color:'green',
    //   paddingTop:'3px',
    //   fontSize: '23px',
    //   border: '3px, solid, green',
    // }),
    // placeholder: base => ({
    //   ...base,
    //   color:'red',
    //   paddingTop:'3px',
    //   fontSize: '23px',
    // }),
  // };
  // const { fieldname, fieldlabel, prettyname, fieldtype='text', register, errors, placeholder='Placeholder', isReadOnly=false, isRequired=false, maximLength=100, minimLength=0 } = props;
  // console.log('placeholder: ', placeholder)
  // console.log('creatable props: ', props)

//   //https://react-select.com/advanced
//   The action argument is a string with the following possible values

// 'select-option': Selecting an option from the list
// 'deselect-option': (Multiple) Deselecting an option from the list
// 'remove-value': (Multiple) Removing a selected option with the remove button
// 'pop-value': Removing options using backspace
// 'set-value': Calling setValue from a component without an action
// 'clear': Removing all selected options with the clear button
// 'create-option': (Creatable) Creating a new option
// By explicitly passing you what type of change event has been fired, we allow you to have more granular control over how the select behaves after an onChange even is fired.

  // const chakraStyles = {
  //   placeholder: base => ({ 
  //     ...base, 
  //     color: '#ffffff'
  //   }),
  //   option: base => ({
  //     ...base,
  //     color:'spikegray.100',
  //     bg:'spikegray.800',
  //     _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' },
  //   }),
  //   menuList: base => ({
  //     ...base,
  //     color:'spikegray.100',
  //     bg:'spikegray.800',
  //     _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' },
  //   }),
  // }
  // const customStyles = {
  //   menuPortal: base => ({ 
  //     ...base, 
  //     fontSize: '15px',
  //     zIndex: 9999, 
  //   })
  // }

  return (
    
    <Controller
      errors={errors}
      control={control}
      name={fieldname}
      rules={rules}
      render={({ 
        field: { onBlur, value, name, ref },
        fieldState: { error }
      }) => (
        <FormControl 
          width={width}
          id={fieldname} 
          isInvalid={!!errors[fieldname]}> {/* errors[fieldname] exists after changing */}
        {(fieldlabel!=='') && <FormLabel>{fieldlabel}</FormLabel>}
            <CreatableSelect
              // styles={customStyles}
              // components={{ ClearIndicator: () => <div>Clear</div> }}
              components={{ 
                // ClearIndicator: () => <div>Clear</div>
                ClearIndicator: null,
                IndicatorSeparator: () => <Divider orientation='vertical' color='spikegray.600'/>,
              }}
              // {...props}
              name={fieldname}
              // ref={ref}
              ref={reference}
              // onChange={onChange}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              onMenuOpen={onMenuOpen}
              // onChange={(e)=>props.onChange(e)}
              // value={selectedoption}
              // value={value}
              value={(selectedoption) ? selectedoption : value}
              options={optionsArray}
              placeholder={placeholder}
              // menuPortalTarget={document.body} 
              selectedOptionColorScheme="spikegray"
              // focusBorderColor="spikeorange.500"
              className={className}
              classNamePrefix={classNamePrefix}
              closeMenuOnSelect={true}
              isDisabled={isDisabled}
              // isMulti
              // ADD showCloseX in Select
              // isClearable={true}
              isClearable
              // isCreatable
              defaultValue={defaultValue}
              styles={customStyles}
              chakraStyles={chakraStyles}
            />
            <FormErrorMessage>{errors[fieldname]?.message}</FormErrorMessage>
          </FormControl>
      )}
    />
  )
}