import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Select } from "chakra-react-select";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Divider,
} from "@chakra-ui/react";
import { chakraStyles, customStyles } from './SelectStyles';

export function FormSelectSimple(props) {
  const { 
    placeholder,
    control, 
    fieldname='', 
    fieldlabel='', 
    optionsArray=[], 
    selectedoption, 
    isDisabled=false, 
    onChange, 
    onBlur,
    isRequired=false,
    isMulti=false,
    defaultValue,
    classNamePrefix,
    rules,
    isclearable=false,
    issearchable=true,
    errors,
    autoComplete='off',
    onInputChange,
    isAsync=false,
    onKeyDown,
    filterOption,
  } = props;
  
  return (
    <Controller
      control={control}
      name={fieldname}
      rules={rules}
      render={({ 
        field: { ref }
      }) => (
        <FormControl id={fieldname} isInvalid={!!errors[fieldname]}>
          {(fieldlabel !== '') && <FormLabel>{fieldlabel}</FormLabel>}
            <Select
              components={{ 
                IndicatorSeparator: () => <Divider orientation='vertical' color='spikegray.600'/>,
              }}
              name={fieldname}
              autoComplete={autoComplete}
              ref={ref} // Pass ref directly here
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              onInputChange={onInputChange}
              filterOption={filterOption}
              isAsync={isAsync}
              value={selectedoption}
              options={optionsArray}
              placeholder={placeholder}
              styles={customStyles}
              chakraStyles={chakraStyles}
              menuPortalTarget={document.body}
              selectedOptionColorScheme="spikegray"
              className="select"
              classNamePrefix={classNamePrefix}
              closeMenuOnSelect={!isMulti}
              isDisabled={isDisabled}
              isClearable={isclearable}
              isSearchable={issearchable}
              isMulti={isMulti}
              defaultValue={defaultValue}
              required={isRequired}
            />

            <FormErrorMessage>
              {errors[fieldname]?.message}
            </FormErrorMessage>
          </FormControl>
      )}
    />
  );
}

// Prop validation
FormSelectSimple.propTypes = {
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  fieldname: PropTypes.string,
  fieldlabel: PropTypes.string,
  optionsArray: PropTypes.arrayOf(PropTypes.object),
  selectedoption: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  // selectedoption: PropTypes.object,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isRequired: PropTypes.bool,
  isMulti: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classNamePrefix: PropTypes.string,
  rules: PropTypes.object,
  isclearable: PropTypes.bool,
  issearchable: PropTypes.bool,
  errors: PropTypes.object,
  autoComplete: PropTypes.string,
  onInputChange: PropTypes.func,
  isAsync: PropTypes.bool,
  onKeyDown: PropTypes.func,
  filterOption: PropTypes.func,
};

