// import { useState } from 'react'
// import { SetS3Config } from './services';
// SetS3Config('prod-spike-unit-images', 'protected');
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { QueryClient, QueryClientProvider } from "react-query";
import { RequireAuth } from './RequireAuth';
import { Login } from './components/Login.jsx';
// import { ColorModeScript } from '@chakra-ui/react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { ContextWrapper } from './components/AppContext'
import { OAuthPopup } from "@tasoskakour/react-use-oauth2";
import './App.css'

import { PreviousLocationProvider } from './PreviousLocationContext.jsx';

// 
// import { Estimator } from './components/Estimator';
import { MainMain } from './components/MainMain'
import { PunchTimes } from './components/TimeAttendance/PunchTimes.jsx';
import { Dashboard } from './components/Dashboard/Dashboard.jsx';
import { TeamMembers } from './components/TeamMember/TeamMembers.jsx';
import { ViewApproveDJT } from './components/Billing/ViewApproveDJT.jsx';
import { SyncQBCustomer } from './components/Billing/SyncQBCustomer.jsx';
import { DailyJobTicket } from './components/DailyJobTicket.jsx'
import { Estimator } from './components/Estimator.jsx';
import { Units } from './components/Units/Units.jsx';
import { QueueDailyInspections } from './components/Inspections/QueueDailyInspections.jsx'
import { UnitInspections } from './components/Inspections/UnitInspections.jsx';
import { Receipt } from './components/ReceiptUpload/Receipt.jsx';
import { UserReceipts } from './components/ReceiptUpload/UserReceipts.jsx';
import { QuickCameraUploadStatement } from './components/ReceiptUpload/QuickCameraUploadStatement.jsx';
import { AccountsPayable } from './components/AccountsPayable/AccountsPayable.jsx';
import { ReceiptTransactionManagement } from './components/ReceiptTransactionManagement/ReceiptTransactionManagement.jsx';

// import { AuthProvider } from './components/iSolved/AuthProvider.jsx';
// import { LegalIds } from './components/iSolved/DevComponent.jsx'
// import { QuickBooksCallback } from './components/QuickBooks/QuickBooksCallback.jsx';

// import { ViewDJT } from './components/ViewDJT'
// import { CreateQBInvoiceFromDJT } from './components/CreateQBInvoiceFromDJT'
// import { CreatePDFFromDJT } from './components/CreatePDFFromDJT'
// import { CreatePDFFromTemplate } from './components/CreatePDFFromTemplate'
// import { ApproveDJT } from './components/ApproveDJT'
// import { QueueDailyInspections } from './components/Inspections/QueueDailyInspections'
// import { CreateUserFull } from './components/CreateUserFull';
// import { AddTeamMember } from './components/AddTeamMember';
import { theme } from './theme/index.js';
import PropTypes from 'prop-types';

const queryClient = new QueryClient();

function MyRoutes() {
  const { route } = useAuthenticator((context) => [context.route]);
  // console.log('MyRoutes route: ', route);

  return (
    
    <BrowserRouter>
      <PreviousLocationProvider>
        <LocationBasedRoutes route={route} />
      </PreviousLocationProvider>
    </BrowserRouter>
  );
}

// This is a new functional component that handles the routes and can now use the useLocation hook properly
function LocationBasedRoutes({ route }) {

  const location = useLocation();
  const navigate = useNavigate();
  const [redirectPath, setRedirectPath] = useState(null);

  // Check if the current route is for 'receipt-upload' and if query param exists
  const isReceiptUpload = location.pathname.includes('/receipt-upload');
  const hasIdParam = location.search.includes('id=');

  useEffect(() => {
    // Save the path if the user is navigating to receipt-upload
    if (isReceiptUpload && hasIdParam && !redirectPath) {
      setRedirectPath(location.pathname + location.search);
      // console.log("Saving redirect path: ", location.pathname + location.search);
    }
  }, [location, isReceiptUpload, hasIdParam, redirectPath]);

  useEffect(() => {
    // After authentication, navigate back to the saved receipt-upload URL
    if (route === 'authenticated' && redirectPath) {
      // console.log("Redirecting back to: ", redirectPath);
      navigate(redirectPath);
    }
  }, [route, redirectPath, navigate]);


  return (
    <Routes>
      {/* If not authenticated, redirect to login */}
      {route !== 'authenticated' ? (
        <>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </>
      ) : (
        <>
          {/* Authenticated routes */}
          {isReceiptUpload && hasIdParam ? (
            // Directly render the receipt upload route if detected
            <Route
              path="/receipt-upload"
              element={<RequireAuth><QuickCameraUploadStatement /></RequireAuth>}
            />
          ) : (
            <Route path="/" element={<RequireAuth><MainMain /></RequireAuth>}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/daily-punches" element={<PunchTimes />} />
              <Route path="/teammembers" element={<TeamMembers />} />
              <Route path="/viewdjt" element={<ViewApproveDJT />} />
              <Route path="/syncqbcustomer" element={<SyncQBCustomer />} />
              <Route path="/daily-job-ticket" element={<DailyJobTicket />} />
              <Route path="/estimator" element={<Estimator />} />
              <Route path="/units" element={<Units />} />
              <Route path="/daily-inspections" element={<QueueDailyInspections />} />
              <Route path="/tm-unit-inspections" element={<UnitInspections />} />
              {/* <Route path="/manage-credit-cards" element={<Receipt />} /> */}
              <Route path="/playground" element={<Receipt />} />
              <Route path="/user-receipts" element={<UserReceipts />} />
              <Route path="/accounts-payable" element={<AccountsPayable />} />
              <Route path="/cc-trans-management" element={<ReceiptTransactionManagement />} />
              <Route path="/tasks" element={<>disabled temporarily</>} />
              <Route path="/statements" element={<>disabled temporarily</>} />
              <Route path="/transfers" element={<>disabled temporarily</>} />
              <Route path="/credentials" element={<>disabled temporarily</>} />
              <Route path="/team-members" element={<>disabled temporarily</>} />
              <Route path="/contracts-and-bids" element={<>disabled temporarily</>} />
              <Route path="/divisions" element={<>disabled temporarily</>} />
              <Route path="/careers" element={<>disabled temporarily</>} />
              <Route path="/contacts" element={<>disabled temporarily</>} />
            </Route>
          )}
        </>
      )}
      <Route path="/callback" element={<OAuthPopup />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

LocationBasedRoutes.propTypes = {
  route: PropTypes.string,
}

function App() {
  // console.log("App Loaded")
  return (
    // <PreviousLocationProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
        {/* <ColorModeScript initialColorMode={theme.config.initialColorMode} /> */}
          <Authenticator.Provider>
            <ContextWrapper>
              <MyRoutes />
            </ContextWrapper>
          </Authenticator.Provider>
          {/* <ColorModeSwitch /> */}
        </ChakraProvider>
      </QueryClientProvider>
    // </PreviousLocationProvider>
  )
}

export default App
