import { getDailyJobTicketById } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const fetchDailyJobTicketById = async (id) => {
  if (!id) return;
  try {
    const response = await client.graphql({
      query: getDailyJobTicketById,
      variables: { id: id }
    });

    const results = response.data.getSpkDailyJobTicketTbl;
    return results;
  } catch (err) {
    return ('error fetchDailyJobTicketById: ', [err]);
  }
};

fetchDailyJobTicketById.propTypes = {
  id: PropTypes.string.isRequired,
};
