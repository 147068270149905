import { deletePrimaryUnitAuxUnits } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const removePrimaryUnitAuxiliaryUnits = async (id) => {
  if (!id) return;

  try {
    const gqlParams = {
      input: { id: id }
    };

    // console.log('removeAuxUnitFromUnit props: ', gqlParams);

    const response = await client.graphql({
      query: deletePrimaryUnitAuxUnits,
      variables: gqlParams
    });

    // console.log('removeAuxUnitFromUnit response: ', response);
    const results = response.data.deletePrimaryUnitAuxillaryUnits.id;
    // console.log('removeAuxUnitFromUnit results: ', results);

    return results;
  } catch (err) {
    return ('error removePrimaryUnitAuxiliaryUnits: ', [err]);
  }
};

removePrimaryUnitAuxiliaryUnits.propTypes = {
  id: PropTypes.string.isRequired,
};
