import { useState, useRef, useEffect, useContext } from 'react';
// import { Storage } from 'aws-amplify';
import {
  Box,
  Button,
  Image,
  Flex,
  Image as ChakraImage,  // Rename the Chakra UI Image import
  Spacer,
  VStack,
  Text,
  Container,
  Input,
} from "@chakra-ui/react";

// uuid
import { v4 as uuid } from 'uuid';

// react-hook-form
import { useForm } from 'react-hook-form';


// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
import { TextareaInput } from '../Form/Input/TextareaInput.jsx';
import { FormSelectMulti } from '../Form/Select/FormSelectMulti.jsx';
import { stateOptions } from '../Form/Select/stateOptions.jsx';

// form buttons
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';

// graphql and external components
import { uploadFileToS3 } from '../AWS/S3/uploadFileToS3.jsx';
import { fetchDivisionOptions } from '../graphqlCompnents/Statement/fetchDivisionOptions.jsx';
// import { addStatementDocument } from '../graphqlCompnents/Statement/addStatementDocument.jsx';
import { addStatementDocumentUnit } from '../graphqlCompnents/Statement/addStatementDocumentUnit.jsx';
import { fetchContactCompaniesByDivision } from '../graphqlCompnents/DJT/fetchContactCompaniesByDivision.jsx';
import { fetchAllUnits } from '../graphqlCompnents/Unit/fetchAllUnits.jsx';
import { fetchQuickBooksExpenseCategories } from '../graphqlCompnents/Statement/fetchQuickBooksExpenseCategories.jsx';
// import { updateStatementDocument } from '../graphqlCompnents/Statement/updateStatementDocument.jsx';
import { addIFTAFuelTracking } from '../graphqlCompnents/Statement/addIFTAFuelTracking.jsx';

// utils
import { stripToNumbersAndDecimal } from '../functions/number.jsx';

// globals
import { AppContext } from '../AppContext.jsx';

import PropTypes from 'prop-types';

export const CameraUploadStatement = (props) => {
  const { onClose } = props;

  // deconstruct userData here
  // name: currentTeamMemberName,
  // id: currentTeamMemberId,
  // isGlobalAdmin: currentUserIsGlobalAdmin
  
  // grab global context values
  const { store } = useContext(AppContext);
  // console.log('store: ', store)

  // get the current environment sandbox || production
  const qbEnv = store?.qbEnvironment || 'sandbox'
  // console.warn(' ----- qbEnv: ', qbEnv)
  // set the current users team member id
  const currentTeamMemberId = store?.userData?.id
  // console.warn(' ----- currentTeamMemberId: ', currentTeamMemberId)

  // current users default division
  const usersDivisionId = store?.userData?.divisionId
  // console.warn(' ----- usersDivisionId: ', usersDivisionId)

  const fileInputRef = useRef(null);

  // --- REACT-HOOK-FORM ---
  const { 
    setError, 
    control, 
    register, 
    handleSubmit, 
    clearErrors,
    setValue, 
    formState: { 
      errors
    }} = useForm({
      mode: 'onSubmit',
      // reValidateMode: 'onChange',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const onError = (errors, e) => console.log(errors, e);

  const [combinedImageFile, setCombinedImageFile] = useState(null);
  
  const [ file, setFile ] = useState(null)
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [streaming, setStreaming] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [previewMode, setPreviewMode] = useState(false);
  const [mode, setMode] = useState('');  // Track user selection of Single or Combine and now File
  const [showUploadForm, setShowUploadForm] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const [selectedUnits, setSelectedUnits] = useState([]);
  const [unitOptions, setUnitOptions] = useState([])
  const [ iftaUnitOptions, setIFTAUnitOptions ] = useState([])
  const [ selectedIFTAUnitOption, setSelectedIFTAUnitOption ] = useState([])
  useEffect(() => {
    const fetchAndSetUnits = async () => {
      try {
        const units = await fetchAllUnits();
        console.log('units: ', units)
        units.sort((a, b) => a.codeSortable.localeCompare(b.codeSortable));

        // create IFTA unit options
        const iftaUnits = units.filter(unit => unit.irpiftaRegistered === true && unit.isActive === true);
        
        const iftaUnitOptions = iftaUnits.map(unit => ({
          value: unit.id,
          label: `${unit.code} - ${unit.type.name} / ${unit.subType.name}`
        }));

        setIFTAUnitOptions(iftaUnitOptions)

        // reduce down to active units
        const activeUnits = units.filter(unit => unit.isActive === true);
        // Create unitOptions
        const options = activeUnits.map(unit => ({
          value: unit.id,
          label: `${unit.code} - ${unit.type.name} / ${unit.subType.name}`
        }));
        
        // console.log('units: ', units)
        setUnitOptions(options)

      } catch (error) {
        console.error('Failed to fetch units:', error);
      }
    };

    fetchAndSetUnits();

  }, []); 

  const [selectedDivision, setSelectedDivision] = useState('');
  const [ divisionOptions, setDivisionOptions ] = useState([])
  useEffect(() => {
    const fetchDivisions = async () => {
      try {
        // Fetch divisions for 'all'
        const responseAll = await fetchDivisionOptions('all');
        const optionsAll = responseAll.map(division => ({
          value: division.id,
          label: division.prettyname,
        }));
  
        // Fetch divisions for 'special'
        const responseSpecial = await fetchDivisionOptions('special');
        const optionsSpecial = responseSpecial.map(division => ({
          value: division.id,
          label: division.prettyname,
        }));
  
        // Combine and sort options
        const combinedOptions = [...optionsAll, ...optionsSpecial].sort((a, b) => 
          a.label.localeCompare(b.label)
        );
        setDivisionOptions(combinedOptions);
  
        // Find the division that matches usersDivisionId
        const matchingDivision = combinedOptions.find(option => option.value === usersDivisionId);
  
        // Set selectedDivision if a match is found
        if (matchingDivision) {
          setSelectedDivision(matchingDivision);
          setValue('selectDivision', matchingDivision.value);
        }
  
      } catch (error) {
        console.error('Failed to fetch divisions:', error);
      }
    };
  
    fetchDivisions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersDivisionId]); // Add usersDivisionId to the dependency array to rerun if it changes

  const [selectedClient, setSelectedClient] = useState('');
  const [clients, setClients] = useState([])

  // Fetch clients whenever selectedDivisionId changes
  useEffect(() => {
    const fetchClients = async () => {
      if (selectedDivision?.value) {
        try {
          const response = await fetchContactCompaniesByDivision(selectedDivision.value);
          const clientOptions = response.map(client => ({
            value: client.value,
            label: client.label,
          }));

          // Add the ' -- None --' option at the beginning
          clientOptions.unshift({ value: '', label: ' - None -' });
        
          setClients(clientOptions);
          setSelectedClient(clientOptions[0])
          setValue('selectClient', clientOptions[0])
        } catch (error) {
          console.error('Failed to fetch clients:', error);
        }
      }
    };

    fetchClients();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDivision]); // Add selectedDivisionId to the dependency array

  const [ expenseCategories, setExpenseCategories ] = useState([])
  const [ selectedExpenseCategory, setSelectedExpenseCategory ] = useState('')
  const [ isIFTATracked, setIsIFTATracked ] = useState(false)
  useEffect(() => {
    const fetchAllExpenseCategories = async () => {
      try {
        const response = await fetchQuickBooksExpenseCategories(qbEnv);
        console.log('response: ', response)
        const categories = response.map(expense => ({
          value: expense.qbId,
          label: expense.appName,
          isIFTATracked: (expense?.isIFTATracked===true) ? true : false,
        }));

        // Add the ' -- None --' option at the beginning
        // categories.unshift({ value: '', label: ' - None -' });

        console.log('categories: ', categories)

        setExpenseCategories(categories);
        // setSelectedExpenseCategory(categories[0])
        // setValue('selectExpenseCategory', categories[0])
      } catch (error) {
        console.error('Failed to fetch expense categories:', error);
      }
    };

    fetchAllExpenseCategories();
  }, [qbEnv]);

  const updateSelectedExpenseCategory = (event) => {
    console.log('event: ', event)
    setIsIFTATracked(event?.isIFTATracked===true ? true : false)
    setSelectedExpenseCategory(event);
    setValue('selectExpenseCategory', event.value)
    clearErrors('selectExpenseCategory')
  }

  const updateSelectedDivision = (event) => {
    setSelectedDivision(event);
  };

  const updateSelectedClient = (event) => {
    setSelectedClient(event);
    setValue('selectClient', event.value)
  };

  const handleUnitsChange = (event, action) => {
    if (action?.action === 'select-option') {
      const addedOption = { value: action?.option?.value, label: action?.option?.label, key: action?.option?.value };
      setSelectedUnits([...selectedUnits, addedOption]);
    }
    if (action?.action === 'remove-value') {
      const removedOption = { value: action?.removedValue?.value, label: action?.removedValue?.label, key: action?.removedValue?.value };
      const newUnits = selectedUnits.filter((unit) => unit.value !== removedOption.value);
      setSelectedUnits(newUnits);
    }
  };

  const [ selectedState, setSelectedState ] = useState('')
  const updateSelectedState = (event) => {
    if (event.value==='') {
      setSelectedState(event)
      setValue('selectState', event)
      setError('selectState', {
        type: 'manual',
        message: 'State is required'
      })
    } else {
      setSelectedState(event)
      setValue('selectState', event)
      clearErrors('selectState')
    }
  }

  const handleIFTAUnitChange = (event) => {
    setSelectedIFTAUnitOption(event);
    setValue('selectOTRUnit', event.value)
    clearErrors('selectOTRUnit')
  };
  
  const startCamera = async () => {
    const hasPermission = await checkAndRequestCameraPermissions();
    if (!hasPermission) return;

    if (selectedDevice) {
      try {
        setStreaming(true);
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: selectedDevice ? { exact: selectedDevice } : undefined }
        });
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
      } catch (error) {
        console.error("Error starting camera: ", error);
        setStreaming(false);
      }
    }
  };
  
  const getVideoDevices = async () => {
    const deviceInfos = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = deviceInfos.filter(device => device.kind === 'videoinput');
    
    // Select the default back-facing camera
    const backCamera = videoDevices.find(device =>
      device.label.toLowerCase().includes('back') || device.label.toLowerCase().includes('rear')
    );

    if (backCamera) {
      setSelectedDevice(backCamera.deviceId);
    } else if (videoDevices.length > 0) {
      // If no back camera is found, select the first device
      setSelectedDevice(videoDevices[0].deviceId);
    }
  };

  const capturePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
  
    const width = video.videoWidth;
    const height = video.videoHeight;
  
    // Set canvas dimensions to match video dimensions
    canvas.width = width;
    canvas.height = height;
  
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, width, height);
    const dataUrl = canvas.toDataURL('image/png');
  
    setImages([...images, dataUrl]);
    setPreviewMode(true);
    video.srcObject.getTracks().forEach(track => track.stop());
    setStreaming(false);
  };
  
  const handleRetake = () => {
    setImages(images.slice(0, -1));  // Remove the last image
    setPreviewMode(false);
    startCamera();
  };

  const handleKeep = () => {
    setPreviewMode(false);
    if (mode === 'Single') {
      handleDone();
    }
  };

  const handleDone = async () => {
    const combinedImage = await combineImages(images);
    setImages([combinedImage]);
    const file = dataURLtoFile(combinedImage, uuid()+'.png');
    setCombinedImageFile(file);
    setShowUploadForm(true);
  };
  
  const combineImages = async (images) => {
    const loadedImages = await Promise.all(images.map(src => {
      return new Promise((resolve, reject) => {
        const img = new window.Image();  // Use the global Image constructor
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = reject;
      });
    }));
  
    const totalHeight = loadedImages.reduce((sum, img) => sum + img.height, 0);
    const maxWidth = Math.max(...loadedImages.map(img => img.width));
  
    const canvas = document.createElement('canvas');
    canvas.width = maxWidth;
    canvas.height = totalHeight;
    const context = canvas.getContext('2d');
  
    let yOffset = 0;
    loadedImages.forEach(img => {
      context.drawImage(img, 0, yOffset);
      yOffset += img.height;
    });
  
    return canvas.toDataURL('image/png');
  };
  
  const checkAndRequestCameraPermissions = async () => {
    try {
      const permission = await navigator.permissions.query({ name: 'camera' });
      if (permission.state === 'denied') {
        alert('Camera access is denied. Please enable it in your browser settings.');
        return false;
      } else if (permission.state === 'prompt' || permission.state === 'granted') {
        // Request camera access if prompted
        await navigator.mediaDevices.getUserMedia({ video: true });
        await getVideoDevices(); // Refresh the devices list
      }
      return true;
    } catch (error) {
      console.error('Error requesting camera permissions:', error);
      return false;
    }
  };
  
  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      videoRef.current.srcObject = null;
      setStreaming(false);
    }
    setMode('');
  };  

  useEffect(() => {
    const checkPermissionsAndFetchDevices = async () => {
      const hasPermission = await checkAndRequestCameraPermissions();
      if (hasPermission) {
        await getVideoDevices();
        startCamera();  // Start the camera after devices are fetched and permissions are granted
      }
    };
  
    checkPermissionsAndFetchDevices();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    stopCamera();
    onClose();
  };

  const resetForm = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setImage(null)
    setFile(null)
    setImages([]);
    setStreaming(false);
    setSelectedDevice('');
    setPreviewMode(false);
    setShowUploadForm(false);
    setMode('');
    setSelectedUnits([]);
    setSelectedDivision('');
    setSelectedClient('');
    setValue('odometer', '');
    setValue('gallons', '');
    setSelectedIFTAUnitOption('');
    setSelectedExpenseCategory('');
    clearErrors();
  };

  const updateStatement = async () => {
    // const statementId = await updateStatementDocument(params)
    // return statementId
  }

  const handleOdometerKeyPress = (e) => {
    // Regular expression for allowed characters (digits and period)
    const allowedChars = /^[0-9.]$/;
    
    if (!allowedChars.test(e.key)) {
      e.preventDefault();
    }
  }

  const handleOdometerChange = (e) => {
    if (e.target.value==='') return
    const miles = stripToNumbersAndDecimal(e.target.value);
    setValue('odometer', miles);
    clearErrors('odometer');
  };

  const handleGallonsKeyPress = (e) => {
    // Regular expression for allowed characters (digits and period)
    const allowedChars = /^[0-9.]$/;
    
    if (!allowedChars.test(e.key)) {
      e.preventDefault();
    }
  }

  const handleGallonsChange = (e) => {
    if (e.target.value==='') return
    const gallons = stripToNumbersAndDecimal(e.target.value);
    setValue('gallons', gallons);
    clearErrors('gallons');
  };
  
  // const handleUpload = () => {
  //   if (combinedImageFile) {
  //     console.log('combinedImageFile: ', combinedImageFile)
  //     // const statementResult = await addStatementDocument()
  //     // await the insert record into statements with the client, division
  //     // then using the statementId create the records for the units
  //     // then using the statement id we can pass the statementId to the uploadStatement function
  //     const statementId = '01234567890'
  //     uploadStatement(combinedImageFile, statementId);
  //   } else {
  //     alert('No image to upload.');
  //   }
  // };

  const formSubmit = async (values) => {
    if (qbEnv!=='sandbox') return;
    if (!values.selectDivision) return
    console.log('Form Values:', values);
    console.log('currentTeamMemberId: ', currentTeamMemberId)
    console.log('selectedExpenseCategory: ', selectedExpenseCategory)
    console.log('selectedClient: ', selectedClient)
    
    // update the SpkIFTAFuelTaxTrackingTbl table
    // allSort, satementDate, statementId, yearQuarter, unitId, teamMemberId, stateTwoChar, gallons, odometer
    // selectOTRUnit, odometer, gallons

    if (combinedImageFile || file) {
      console.log('receipt file: ', combinedImageFile||file);

      const statementId = uuid()

      // clientId, expenseCategory
      const statementParams = {
        id: statementId,
        addedNotes: values?.addedNotes,
        divisionId: selectedDivision?.value,
        ...(selectedClient?.value && { clientId: selectedClient.value }),
        teamMemberId: currentTeamMemberId,
        ...(selectedExpenseCategory?.value && { categoryId: selectedExpenseCategory.value }),
        statusTab: "Inbox",
        isApproved: false,
        statementType: "Receipt",
        statusKey: 'Processing',
        // iftaUnitId: selectedIFTAUnitOption?.value||'',
        // iftaGallons: values?.gallons||'',
        // iftaOdometer: values?.odometer||'',
        // iftaStateTwoChar: values?.selectState?.value||'',
        // submittedDate: awsDateTimeNow(),
      };

      
  
      try {

        // if this is diesel for OTR, update the iftaTracking table
        if (isIFTATracked) {
          const iftaTrackingJSON = {
            allSort: 'all',
            statementId: statementId,
            unitId: selectedIFTAUnitOption?.value,
            teamMemberId: currentTeamMemberId,
            stateTwoChar: values?.selectState?.value,
            gallons: values?.gallons,
            odometer: values?.odometer,
          }
  
          // update iftaTracking table
          console.log('iftaTrackingJSON: ', iftaTrackingJSON)
          const iftaFuelTaxTrackId = await addIFTAFuelTracking(iftaTrackingJSON)
          console.log('iftaFuelTaxTrackId: ', iftaFuelTaxTrackId)
          statementParams.iftaFuelTaxTrackId = iftaFuelTaxTrackId
        }

        // create the record with the uuid
        // const createResult = await addStatementDocument(statementParams);
        // console.log('statementId: ', createResult)

        // Upload the statement
        const uploadedDocument = await uploadStatement(combinedImageFile||file, statementId);
        statementParams.uploadedDocumentId = uploadedDocument?.documentId
        console.log('uploadedDocument: ', uploadedDocument)

        // now update the record with the uploadedDocumentId
        const updateResult = await updateStatement(statementParams)
        console.log('update results: ', updateResult)
        
        // Create an array of promises for inserting unit records if this is NOT ifta
        const unitPromises = selectedUnits.map(unit => {
          const unitParams = {
            unitId: unit.value,
            statementDocumentId: statementId,
          };
          return addStatementDocumentUnit(unitParams);
        });
  
        // Wait for all unit insertions to complete
        await Promise.all(unitPromises);
  
        console.log('Upload and database operations completed successfully.');
      } catch (error) {
        console.error('Error during form submission:', error);
        alert('There was an error processing your request. Please try again.');
      }
    } else {
      alert('No image to upload.');
    }
    handleClose();
  };
  
  async function uploadStatement(file, statementDocumentId) {
    // Referernce for when multi-file receipt upload is defined
    // https://medium.com/@steven_creates/uploading-multiple-files-to-aws-s3-using-react-js-hooks-react-aws-s3-1dd29221c91c
    // It might work better to sack the images into one image and THEN process that image
    // https://github.com/lukechilds/merge-images

    // setIsUploadStatementDisabled(true)
    // console.log('uploaded file info: ', file)
    // This needs to be a UUID not TM id, we don't want to overwrite what is already there.
    // const imgKey = teamMemberId + '-' + file.name;
    const imgKey = uuid() + ':' + currentTeamMemberId + ':' + file.name + ':' + statementDocumentId;
    
    // let credentials = await Auth.currentCredentials();
    console.log("imgKey: ",imgKey)
    // let identityId = credentials.identityId;
    let userBucket = 'analyze-expense-statements';
    // const params = {
    //   bucket: userBucket,
    //   identityId: identityId, 
    //   key: imgKey
    // }

    // 1. add the document to SpkUploadedDocumentsTbl, return the documentId
    // 2. upload to 'analyze-expense-statements'
    // 3. duplicate file to 'textract-analyzexpense-sourcebucket-1rors02oulldp' for textract

    const uploadData = await uploadFileToS3({ file: file, bucket: userBucket, key: imgKey })
    console.log('uploadData: ', uploadData)

    // const duplicateFile = await Storage.put(imgKey, file, {
    //   bucket: 'textract-analyzexpense-sourcebucket-1rors02oulldp',
    //   contentType: file.type
    // })
    // console.log('File has been dropped in source bucket for processing: ', duplicateFile)

    return uploadData

    // At this point:
    // 1.) The uploaded file has been given a unique key as uuid:teamMemberId:fileName
    // AND a record has been created in SpkDocumentUploadsTbl with the key for reference
    // 2.) The file has been saved to protected S3 for owner/admin access
    // 3.) The file has ALSO been put in the S3 bucket 'textract-analyzexpense-sourcebucket-1rors02oulldp'
    // for processing
  }
  
  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
  };

  useEffect(() => {
    if (mode !== '' && mode !== 'File') {
      startCamera();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);


  const handleFileChange = (event) => {
    setFile(null);
    setImage(null);
    setPreviewMode(false);

    const file = event.target.files[0];
    
    if (!file) return; // Guard clause in case no file is selected

    // Extract file extension
    const extension = file.name.split('.').pop();
    // Generate new filename with UUID
    const newFileName = `${uuid()}.${extension}`;

    // Create a new file object with the new name
    const renamedFile = new File([file], newFileName, { type: file.type });

    setFile(renamedFile);
    console.log('renamedFile: ', renamedFile);

    setShowUploadForm(true);
    setMode('File');

    // if the uploaded file is an image, then do this
    // if (renamedFile?.type?.includes('image')) {
    const reader = new FileReader();
    reader.onloadend = () => {
        if (reader.result) {
            setImage(reader.result);
            setPreviewMode(true);
        }
    };
    reader.readAsDataURL(renamedFile);
    // }
};
  // const handleFileChange = (event) => {
  //   setFile(null)
  //   setImage(null)
  //   setPreviewMode(false)

  //   const file = event.target.files[0];
  //   setFile(event.target.files[0]);
  //   console.log('file: ', file)

  //   setShowUploadForm(true);
  //   setMode('File')

  //   // if the uploaded file is an image, then do this
  //   // if (file?.type?.includes('image')) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       if (reader.result) {
  //         setImage(reader.result);
  //         setPreviewMode(true);
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   // }
  // };

  return (
    <>
    <Container pb='25px' as="form" onSubmit={handleSubmit(formSubmit, onError)}>
      <Box>
        <Flex alignItems="center" justifyContent="space-between" pb={'25px'}>
          <Box>
            <Text as="span" textStyle='heading-1'>Upload Receipt</Text>
          </Box>
          <Spacer />
          <Box>
            <ButtonQuaternaryWithIcon 
              name='closeDrawer'
              iconsize='26px'
              leftIcon='close'
              value='Cancel'
              onClick={handleClose}
            />
          </Box>
        </Flex>

        <VStack spacing={4}>
          <Box >
            <Text as="span" textStyle='label-2'>Upload PDF or Image</Text>
            <Input 
              name='fileUpload'
              id='fileUpload'
              paddingTop={'6px'} 
              paddingLeft={'8px'} 
              placeholder='Choose File' 
              type="file" 
              accept="image/jpeg, image/jpg, image/png, application/pdf"
              onChange={handleFileChange}
              ref={fileInputRef} />
          </Box>
          <Spacer />
          {image && (
            <Box>
              <Image src={image} alt="Receipt" />
            </Box>
          )}
        </VStack>

        <Box>
          <VStack spacing={4}>
            {mode === '' && (
              <Box>
                <Button onClick={() => { handleModeChange('Single'); }}>Single</Button>
                <Button onClick={() => { handleModeChange('Combine'); }}>Long</Button>
                <Button onClick={() => { handleModeChange('SalesOrder'); }}>2 Stapled</Button>
              </Box>
            
            )}

            {mode !== '' && mode !== 'File' && (
              <>
                {images.length > 0 && (
                  <Box>
                    {images.map((image, index) => (
                      <ChakraImage key={index} src={image} alt={`Receipt ${index + 1}`} />
                    ))}
                    {previewMode && (
                      <Box>
                        <Button onClick={handleRetake} colorScheme="red" m={2}>Retake</Button>
                        <Button onClick={handleKeep} colorScheme="green" m={2}>Keep</Button>
                      </Box>
                    )}
                  </Box>
                )}

                {streaming && (
                  <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                    <Box width="100%" position="relative">
                      <Box>
                      <video
                        ref={videoRef}
                        autoPlay
                        playsInline
                        onLoadedMetadata={() => setIsVideoLoaded(true)}
                      ></video>
                      </Box>
                    </Box>
                    {isVideoLoaded && (
                      <Box mt={4} width="100%" textAlign="center">
                        <Button onClick={stopCamera} mr={2}>Cancel</Button>
                        <Button onClick={capturePhoto}>Capture</Button>
                      </Box>
                    )}
                  </Box>
                )}

                {/* image after capture */}
                <canvas ref={canvasRef} width={640} height={480} style={{ display: 'none' }}></canvas>

                {images.length > 0 && !previewMode && mode === 'Combine' && !showUploadForm && (
                  <Button onClick={startCamera}>Take Another Picture</Button>
                )}
                {images.length > 0 && !showUploadForm && mode === 'Combine' && (
                  <Button onClick={handleDone}>I am done taking pictures</Button>
                )}
              </>
            )}
          </VStack>
        </Box>
      </Box>

      {showUploadForm && (
        <Box>

          <Box paddingTop={'25px'}>
            <TextareaInput
              register={register}
              errors={errors}
              // rules={{ required: "Required" }}
              fieldname="addedNotes"
              fieldlabel="Expense Notes"
              prettyname="Expense Notes"
              placeholder="Enter any notes about this expense"
              // onChange={handleJobDescriptionChange}
              // onBlur={handleJobDescriptionChange}
              // isRequired
            />
          </Box>
          
          <Box paddingTop={'25px'}>
            <FormSelectSimple
              key='Division'
              register={register}
              control={control}
              errors={errors}
              isRequired={true}
              rules={{ required: "Division is required" }}
              fieldname="selectDivision"
              fieldlabel="Division"
              placeholder={'Select division'}
              optionsArray={divisionOptions?.map((division, index) => ({
                key: index,
                value: division.value,
                label: division.label
              }))}
              onChange={(e) => updateSelectedDivision(e)}
              selectedoption={selectedDivision||''}
              issearchable={false}
            />
          </Box>

          <Box paddingTop={'25px'}>
            <FormSelectSimple
              key='Client'
              register={register}
              control={control}
              errors={errors}
              isRequired={true}
              fieldname="selectClient"
              fieldlabel="Client"
              placeholder={'Select client'}
              optionsArray={clients}
              onChange={(e) => updateSelectedClient(e)}
              selectedoption={selectedClient||''}
              issearchable={false}
            />
          </Box>

          <Box paddingTop={'25px'}>
            <FormSelectSimple
              register={register}
              control={control}
              errors={errors}
              isRequired={true}
              rules={{ required: "Expense Category is required" }}
              onChange={e => updateSelectedExpenseCategory(e)}
              selectedoption={selectedExpenseCategory||''}
              optionsArray={expenseCategories}
              fieldname='selectExpenseCategory'
              prettyname='Expense Category'
              fieldlabel="Expense Category"
              placeholder={'Select expense category'}
            />
          </Box>

          {isIFTATracked ? (<>
            <Box paddingTop={'25px'}>
              <FormSelectSimple
                register={register}
                control={control}
                errors={errors}
                isRequired={true}
                rules={{ required: "OTR Unit is required" }}
                onChange={(event, action) => handleIFTAUnitChange(event, action)}
                selectedoption={selectedIFTAUnitOption}
                optionsArray={iftaUnitOptions.map(unit => ({
                  value: unit.value,
                  label: unit.label
                }))}
                fieldname="selectOTRUnit"
                placeholder={'Select OTR unit'}
                fieldlabel="OTR Unit"
                issearchable={false}
              />
            </Box>

            <Box paddingTop={'25px'}>
              <FormSelectSimple
                register={register}
                control={control}
                errors={errors}
                isRequired={true}
                rules={{ required: "State is required" }}
                onChange={e => updateSelectedState(e)}
                selectedoption={selectedState||''}
                optionsArray={stateOptions}
                fieldname='selectState'
                prettyname='State'
                fieldlabel="State"
                placeholder={'Select state'}
              />
            </Box>

            <Box paddingTop={'25px'}>
              <TextInput
                register={register}
                errors={errors}
                fieldtype="tel"
                fieldname="odometer"
                fieldlabel="Odometer"
                prettyname="Odometer"
                placeholder="Enter odometer miles"
                onChange={handleOdometerChange}
                onKeyPress={handleOdometerKeyPress}
                isRequired
              />
            </Box>

            <Box paddingTop={'25px'}>
              <TextInput
                register={register}
                errors={errors}
                fieldtype="tel"
                fieldname="gallons"
                fieldlabel="Gallons"
                prettyname="Gallons"
                placeholder="Enter gallons purchased"
                onChange={handleGallonsChange}
                onKeyPress={handleGallonsKeyPress}
                isRequired
              />
            </Box>

            </>
          ) : 
            <Box paddingTop={'25px'}>
              <FormSelectMulti
                register={register}
                control={control}
                errors={errors}
                onChange={(event, action) => handleUnitsChange(event, action)}
                selectedoption={selectedUnits}
                optionsArray={unitOptions.map((unit) => ({
                  value: unit.value,
                  label: unit.label
                }))}
                fieldname="units"
                placeholder={'Select units if applicable'}
                fieldlabel="Units"
                issearchable={false}
                isMulti
              />
            </Box>
          }

          <Box paddingTop={'25px'}>
            <Flex gap={4} justifyContent="center" width="100%">
              <Button onClick={resetForm} colorScheme="red" width="100%">Reset</Button>
              <ButtonPrimaryPlain
                type="submit"
                onClick={handleSubmit(formSubmit, onError)}
                width="100%"
                name="submit"
                value="Upload"
                // isDisabled={!isFormLoaded || djtFields?.length===0 || (attachableObjects?.length!==djtFields?.length)}
              />
            </Flex>
          </Box>

        </Box>
        
      )}
      <Box h={'300px'}></Box>

    </Container>
    </>
  );
};

CameraUploadStatement.propTypes = {
  onClose: PropTypes.func.isRequired
}