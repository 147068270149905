import { useEffect, useState, useCallback } from 'react';
import {
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Tooltip,
} from '@chakra-ui/react';

// decorative components
import { Divider } from '../Divider.jsx';

// generic functions
import { truncateString } from '../functions/strings.jsx';
import { debounce } from '../functions/generic.jsx';
import { convertCurrencyToDouble, formatCurrency } from '../functions/currency.jsx';

// form components
import { ButtonSenaryWithIconOnly } from '../Form/Button/ButtonSenaryWithIconOnly.jsx';
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
import { TextInputWithRef } from '../Form/Input/TextInputWithRef.jsx';

import PropTypes from 'prop-types';

export const LaborSummaryTable = (props) => {
  const {
    tableData,
    jobClasses,
    laborSelectOptions,
    ticketHasConvertedLabor=false,
    updateLaborSummary,
    handleLaborConversion,
    handleLaborCustomNameChange,
    handleLaborJobClassChange,
    jobClassSelected,
    handleLaborStartTimeChange,
    handleLaborFinishTimeChange,
    handleLaborPerDiemChange,
    handleLaborLunchChange,
    handleLaborRowTotalChange,
    toggleLaborButtonState,
    toggleAllLaborButtonState,
    laborTotalAmount,
    calculateLaborTotal,
    djtTotalAmount,
    control,
    register,
    errors,
    useDebounce,
    isEstimate,
  } = props;

  // console.log('tableData: ', tableData)

  const thirdPartyLaborJobClass = jobClasses?.filter((jobClass) => jobClass?.className==='3rd Party Labor')[0]
  const thirdPartyJobClassSelected = {label: thirdPartyLaborJobClass?.appDisplayName, value: thirdPartyLaborJobClass?.divisionPosition}
  
  const lunchOptions = [
    {label: '00', value: '0'},
    {label: '15', value: '15'},
    {label: '30', value: '30'},
    {label: '45', value: '45'},
    {label: '60', value: '60'},
  ]

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const [customNameInputValue, setCustomNameInputValue] = useState('');
  const debouncedInputValue = useDebounce(customNameInputValue, 500); // 500 ms delay

  useEffect(() => {
    const customInput = debouncedInputValue.split(':#:')
    handleLaborCustomNameChange(customInput[0], customInput[1])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue]);

  const handleLaborSelectionChange = (e, index) => {
    const removeItemFromArray = (theArray, theValue) => {
      let filteredArr = [{ label: " --- Available Labor ---", options: theArray[0].options?.filter((item) => item.value!==theValue.value)},
        { label: " --- Unavailable Labor ---", options: theArray[1].options?.filter((item) => item.value!==theValue.value)}]
      return filteredArr
    }

    const previousSelected = selectedLaborArr[index]
    // const isTempLabor = e?.isTempLabor

    // set the selected, then just remove from there.
    let tmpSelectedArr = selectedLaborArr
    tmpSelectedArr[index] = e
    setSelectedLaborArr(tmpSelectedArr)

    let filteredWorkingArr = laborSelectOptions

    // now get the ones that were previously selected.
    let tmpHoldingArr = []
    selectedLaborArr?.forEach(selected=> {
      if (selected) {
        tmpHoldingArr = removeItemFromArray(filteredWorkingArr, selected)
        filteredWorkingArr = tmpHoldingArr
      }
    })

    // now set each row to the filtered array
    setLaborSelectionArr(Array(convertedLaborCount).fill(filteredWorkingArr))

    // now send the labor info upstream to update the labor object for this row
    // in that function delete the row and add the row back in with the new labor info
    // send the previous selected item and the new one
    handleLaborConversion(e, index, previousSelected)
  }

  const [ laborSelectionArr, setLaborSelectionArr ] = useState([])
  const [ selectedLaborArr, setSelectedLaborArr ] = useState(() => Array(tableData?.length).fill(null))
  const [ convertedLaborCount, setConvertedLaborCount ] = useState(0)
  useEffect(() => {
    if (tableData?.length>0 && laborSelectOptions?.length>0 && ticketHasConvertedLabor===true) {
      let tmpArr = []
      let tmpSelected = []
      let convertedCnt = 0
      tableData.forEach((labor, index) => {
        tmpArr[index] = laborSelectOptions
        tmpSelected[index] = null
        convertedCnt++
      })
      setConvertedLaborCount(convertedCnt)
      setLaborSelectionArr(tmpArr)
      setSelectedLaborArr(tmpSelected)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, laborSelectOptions, ticketHasConvertedLabor]);

  // Wrap the parent's function with debounce in the child component
  const debouncedHandleLaborStartTimeChange = useCallback(
    debounce((index, value) => handleLaborStartTimeChange(index, value), 2500),
    [handleLaborStartTimeChange] // Re-create if handleLaborStartTimeChange changes
  );
  
  // Wrap the parent's function with debounce in the child component
  const debouncedHandleLaborFinishTimeChange = useCallback(
    debounce((index, value) => handleLaborFinishTimeChange(index, value), 2500),
    [handleLaborStartTimeChange] // Re-create if handleLaborStartTimeChange changes
  );

  return (
    <TableContainer>
      <Table 
        variant={'compact'}
        size={'compact'}
        >
        <Thead>
          <Tr>
            <Th></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>NAME</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>JOB CLASS</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>START TIME</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>FINISH TIME</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>PER DIEM</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>LUNCH</Text></Th>
            <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>TOTAL</Text></Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableData?.map((data, index) => {
            if (!data) return null
            // console.info('data: ', data)
            
            let lunchData = data?.Lunch
            if ( lunchData?.value) {
              lunchData = lunchData.value
              data.Lunch = lunchData+':'+lunchData
            }
            let selectedLunchDuration = ''
            if (lunchData?.includes(':')) {
              selectedLunchDuration = lunchData?.split(':')[1]
            } else {
              selectedLunchDuration = data?.Lunch
            }

            const selectedLunchOption = {label: `${selectedLunchDuration}`, value: `${selectedLunchDuration}`}

            const is3rdPartyLabor = (data?.JobClass?.value === thirdPartyJobClassSelected?.value) ? true : false
            const isConvertedLabor = (data?.IsConvertedLabor===true) ? true : false
            const isStandardLabor = (!is3rdPartyLabor && !isConvertedLabor) ? true : false
            const isTempLabor = data?.IsTempLabor||false
            const currentRowTotal = convertCurrencyToDouble(data?.RowTotal)
            const calculatedRawLaborTotal = calculateLaborTotal(data)
            const tooltipTotal = formatCurrency(calculatedRawLaborTotal)
            const inputVariant = (currentRowTotal!==calculatedRawLaborTotal) ? 'defaultinputred' : 'defaultinput'

            return(
              <Tr key={'tr_labor_'+index}>
                <Td key={'td_labor_0_'+index}><ButtonSenaryWithIconOnly
                  key={'td_labor_0_delete_'+index}
                  name={'labor_delete_row_'+index}
                  icon='delete'
                  iconsize='22px'
                  isDisabled={ticketHasConvertedLabor}
                  onClick={() => {
                    (data.LaborCategory==='available') ? 
                      toggleLaborButtonState(data.OriginalIndex) : 
                      toggleAllLaborButtonState(data.OriginalIndex, data.ButtonIndex); // use teammemberid instead?
                      updateLaborSummary('remove', data, index)
                  }}
                /></Td>
                <Td key={'td_labor_1_'+index}>
                  {(isConvertedLabor && !isEstimate) && <>
                    <FormSelectSimple
                      register={register}
                      control={control}
                      errors={errors}
                      optionsArray={laborSelectionArr[index]}
                      onChange={(e) => handleLaborSelectionChange(e, index)}
                      issearchable={true}
                      fieldname={`labor[${index}].TeamMember`}
                      selectedoption={selectedLaborArr[index]||''}
                      isRequired={true}
                    />
                    <TextInput
                        register={register}
                        errors={errors}
                        fieldname={`labor[${index}].TeamMemberId`}
                        fieldvalue={data.TeamMemberId||''}
                        fieldtype={'hidden'}
                      />
                      <TextInput
                        register={register}
                        errors={errors}
                        fieldname={`labor[${index}].TeamMemberName`}
                        fieldvalue={data.TeamMemberName||''}
                        fieldtype={'hidden'}
                      />
                      <TextInput
                        register={register}
                        errors={errors}
                        fieldname={`labor[${index}].IsTempLabor`}
                        fieldvalue={isTempLabor||false}
                        fieldtype={'hidden'}
                      />
                  </>}

                  {(is3rdPartyLabor) && <>
                    <TextInput
                      register={register}
                      errors={errors}
                      width='135px'
                      fieldname={`labor[${index}].CustomLaborName`}
                      defaultValue={data?.CustomLaborName||'3rd Party Labor'}
                      prettyname="3rd Party Labor"
                      placeholder="3rd Party Labor"
                      isRequired={true}
                      onBlur={(event) => {
                        setCustomNameInputValue(event?.target?.value+':#:'+index)
                      }}
                    /></>}
                    
                  {((isStandardLabor || isEstimate) && !is3rdPartyLabor) && <>
                      <TextInput
                        register={register}
                        errors={errors}
                        fieldname={`labor[${index}].IsTempLabor`}
                        fieldvalue={isTempLabor||false}
                        fieldtype={'hidden'}
                      />
                      <TextInput
                        register={register}
                        errors={errors}
                        fieldname={`labor[${index}].TeamMemberName`}
                        fieldvalue={data.TeamMemberName||''}
                        isRequired={true} 
                        isReadOnly={true}
                        fieldtype={'hidden'}
                      />
                      <Text width='120px' as="span" className='dark-sfpro-text-1'>&nbsp;&nbsp;{truncateString(data.TeamMemberName, 15)}</Text>
                    </>
                  }

                </Td>
                <Td key={'td_labor_2_'+index}>
                  <Box width='190px'>
                  {(is3rdPartyLabor) ? 
                    <>
                    <FormSelectSimple
                      register={register}
                      control={control}
                      errors={errors}
                      optionsArray={
                        jobClasses.map((options) => ({ 
                          label: options.appDisplayName, value: options.divisionPosition
                          // label: options.className, value: options.divisionPosition
                        }))
                      }
                      onChange={(e) => handleLaborJobClassChange(e, index)}
                      selectedoption={thirdPartyJobClassSelected||''}
                      issearchable={false}
                      fieldname={`labor[${index}].JobClass`}
                      isDisabled={(is3rdPartyLabor)}
                    />                    
                    </>
                    : 
                    <><FormSelectSimple
                      register={register}
                      control={control}
                      errors={errors}
                      // optionsArray={
                      //   jobClasses?.map((options) => ({ 
                      //     label: options.appDisplayName, value: options.divisionPosition
                      //     // label: options.className, value: options.divisionPosition
                      //   }))
                      // }
                      optionsArray = {
                        jobClasses
                          ?.filter(options => options.appDisplayName !== '3rd Party Labor')  // Filter out entries with '3rd Party Labor'
                          .map(options => ({
                            label: options.appDisplayName,  // Assuming you're using appDisplayName for the label
                            value: options.divisionPosition
                          }))
                      }
                      onChange={(e) => handleLaborJobClassChange(e, index)}
                      selectedoption={jobClassSelected[index]||data.JobClass||''}
                      issearchable={false}
                      fieldname={`labor[${index}].JobClass`}
                    /></>}
                  
                  </Box>
                </Td>
                <Td key={'td_labor_3_'+index}>
                  <TextInput
                    register={register}
                    errors={errors}
                    width='111px'
                    fieldtype='time'
                    fieldname={`labor[${index}].StartTime`}
                    isRequired={true} 
                    isReadOnly={false}
                    onBlur={(e) => handleLaborStartTimeChange(index, e.target.value) }
                    onChange={(e) => debouncedHandleLaborStartTimeChange(index, e.target.value)}
                  />
                </Td>
                <Td key={'td_labor_4_'+index}>
                  <TextInput
                    register={register}
                    errors={errors}
                    width='111px'
                    fieldtype='time'
                    fieldname={`labor[${index}].FinishTime`}
                    isRequired={true} 
                    isReadOnly={false}
                    onBlur={(e) => handleLaborFinishTimeChange(index, e.target.value)}
                    onChange={(e) => debouncedHandleLaborFinishTimeChange(index, e.target.value)}
                  />
                </Td>
                <Td key={'td_labor_5_'+index}>
                  <TextInput
                    register={register}
                    errors={errors}
                    width='90px'
                    fieldname={`labor[${index}].PerDiemAmount`}
                    placeholder="$0.00"
                    isRequired={false} 
                    isReadOnly={false}
                    onBlur={(e) => handleLaborPerDiemChange(index, e.target.value)}
                  />
                </Td>
                <Td key={'td_labor_6_'+index}>
                  <Box width='112px'>
                  <FormSelectSimple
                    register={register}
                    control={control}
                    errors={errors}
                    optionsArray={lunchOptions}
                    onChange={e => { handleLaborLunchChange(e, index)} }
                    defaultValue={selectedLunchOption}
                    selectedoption={selectedLunchOption}
                    fieldname={`labor[${index}].Lunch`}
                  />
                  </Box>
                </Td>
                <Td key={'td_labor_7_'+index}>
                  <Tooltip 
                    // sx={{ fontSize: '20px' }}
                    label={`Base Total: ${tooltipTotal}`} ><Box>
                    <TextInputWithRef
                      register={register}
                      errors={errors}
                      variant={inputVariant} // 'defaultinput' or 'defaultinputred'
                      key={'td_labor_8_'+index}
                      textAlign='right'
                      width='112px'
                      fieldname={`labor[${index}].RowTotal`}
                      isRequired={false} 
                      isReadOnly={false}
                      onBlur={(e) => handleLaborRowTotalChange(index, e.target.value)}
                    /></Box>
                  </Tooltip>
                  <TextInput
                    register={register}
                    errors={errors}
                    key={'td_labor_9_'+index}
                    fieldname={`labor[${index}].TeamMemberId`}
                    fieldvalue={data.TeamMemberId||''}
                    fieldtype='hidden'
                  />
                  <TextInput
                    register={register}
                    errors={errors}
                    key={'td_labor_10_'+index}
                    fieldname={`labor[${index}].DepartmentEmployee`}
                    fieldvalue={data.DepartmentEmployee||''}
                    fieldtype='hidden'
                  />
                </Td>
              </Tr>
            )
          })}
          {(tableData?.length>0) && 
            <Tr key={'tr_units_divider_end'}>
              <Td colSpan='8' h='15px'>
                <Divider h={'2px'} mt='5px' mb='5px'/>
              </Td>
            </Tr>}
          <Tr><Td colSpan='8' h='15px'></Td></Tr>
        </Tbody>
        <Tfoot>
          <Tr>
            <Th colSpan={8} textAlign={'right'}>
            <Box><Text as="span" textStyle='text-7' pr='10px'>Labor Total:&nbsp;&nbsp;{USDollar.format(laborTotalAmount)}</Text></Box>
            <Box mt='5px'><Text as="span" textStyle='text-8' pr='10px'>DJT Total:&nbsp;&nbsp;{USDollar.format(djtTotalAmount)}</Text></Box>
            </Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  )
}

LaborSummaryTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  jobClasses: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]).isRequired,
  laborSelectOptions: PropTypes.array.isRequired,
  ticketHasConvertedLabor: PropTypes.bool,
  // jobClassRef: PropTypes.object,
  // laborRemove: PropTypes.func.isRequired,
  // updateLaborState: PropTypes.func.isRequired,
  updateLaborSummary: PropTypes.func.isRequired,
  handleLaborConversion: PropTypes.func.isRequired,
  handleLaborCustomNameChange: PropTypes.func.isRequired,
  handleLaborJobClassChange: PropTypes.func.isRequired,
  jobClassSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleLaborStartTimeChange: PropTypes.func.isRequired,
  handleLaborFinishTimeChange: PropTypes.func.isRequired,
  handleLaborPerDiemChange: PropTypes.func.isRequired,
  handleLaborLunchChange: PropTypes.func.isRequired,
  handleLaborRowTotalChange: PropTypes.func.isRequired,
  toggleLaborButtonState: PropTypes.func.isRequired,
  toggleAllLaborButtonState: PropTypes.func.isRequired,
  laborTotalAmount: PropTypes.number.isRequired,
  calculateLaborTotal: PropTypes.func.isRequired,
  djtTotalAmount: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  useDebounce: PropTypes.func.isRequired,
  isEstimate: PropTypes.bool,
};

export default LaborSummaryTable;