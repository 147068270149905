import { fetchAuthSession } from 'aws-amplify/auth';

export const fetchCognitoUserGroupsByUser = async (cognitoUserEmail) => {
    const session = await fetchAuthSession();
    const authToken = `Bearer ${session.tokens.idToken}`;
    
    // const groups = session.accessToken.payload['cognito:groups'] || [];
    const groups = ['exec_admin']
    const props = {
      groupname: "exec_admin"
    }
    if (cognitoUserEmail) {
      props.username = cognitoUserEmail;
    }

    if (groups.includes('exec_admin')) {
      const endpointUrl = 'https://ugczaq3r89.execute-api.us-east-1.amazonaws.com/dev/adminlistuserpoolgroups';
      
      const response = await fetch(endpointUrl, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': authToken
        },
        body: JSON.stringify(props)
      });

      if (!response.ok) {
        throw new Error('Failed to fetch groups from API.');
      }

      const data = await response.json();
      return data.body; // Assuming data.body is the array of groups
    } else {
      throw new Error('User is not in the exec_admin group.');
    }

};
