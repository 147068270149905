import { listMaterialByDJTId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchMaterialByDJTId = async (djtId) => {
  if (!djtId) return;

  try {
    const response = await client.graphql({
      query: listMaterialByDJTId,
      variables: { djtId: djtId },
    });

    const results = response.data.materialsByDJTId.items;
    
    return results;
  } catch (err) {
    return ('error fetchMaterialByDJTId: ', [err]);
  }
};

fetchMaterialByDJTId.propTypes = {
  djtId: PropTypes.string.isRequired,
};
