

export const AccountsPayable = () => {
  return (
    <div>
      <h1>AccountsPayable</h1>
    </div>
  );
}


