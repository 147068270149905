/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import {
  Box,
  Image,
  Flex,
  Spacer,
  Text,
  Container,
  HStack,
} from "@chakra-ui/react";

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';

// form buttons
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';

// react-hook-form
import { useForm } from 'react-hook-form';

// graphql
import { searchQBVendorsByVendorNameLower } from '../graphqlCompnents/Statement/searchQBVendorsByVendorNameLower.jsx';

// custom components
import { ScrollableBox } from '../reactComponents/ScrollableBox.jsx'

export const VendorMap = (props) => {
  const { selectedStatement, screenWidth, handleMapVendor, onClose } = props;
  const { vendorName, signedUrl } = selectedStatement
console.log('selectedStatement: ', selectedStatement)
  // --- REACT-HOOK-FORM ---
  const { 
    setError, 
    register, 
    handleSubmit, 
    clearErrors,
    setValue, 
    formState: { 
      errors, 
    }} = useForm({
      mode: 'onSubmit',
      // reValidateMode: 'onChange',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const onError = (errors, e) => console.log(errors, e);

  const formSubmit = async (event) => {
    console.log('event: ', event)
    // if (!data.selectDivision) return
    // console.log('Form Values:', data);
  }

  const [ searchTerm, setSearchTerm ] = useState('')
  const [ nextToken, setNextToken ] = useState('')
  const [ nextNextToken, setNextNextToken ] = useState('')
  const [ qbVendorsResults, setQBVendorsResults ] = useState([])

  useEffect(() => {
    const searchVendors = async () => {
      const searchParams = {
        limit: 30,
        startsWith: searchTerm
      }
      if (nextNextToken) {
        searchParams.nextToken = nextNextToken
      } else {
        searchParams.nextToken = ''
      }
      const results = await searchQBVendorsByVendorNameLower(searchParams)

      const searchResults = results?.items || []
      setNextToken(results?.nextToken)
      
      const transformedData = transformData(searchResults) || []
      console.log('transformedData: ', transformedData)
      // setQBVendorsResults(transformedData)
      if (searchTerm) {
        setQBVendorsResults(transformedData);
      } else {
        setQBVendorsResults((prevVendors = []) => [...(prevVendors || []), ...transformedData]);
      }
      
    }
    searchVendors()
  },[searchTerm, nextNextToken, vendorName])

  const searchNext = () => {
    setNextNextToken(nextToken)
  }
  // const boxWidth = (screenWidth>480) ? screenWidth*.8 : screenWidth
  const boxWidth = screenWidth*.8
  console.log('screenWidth: ', screenWidth)
  console.log('boxWidth: ', boxWidth)

  // Transform function
  const transformData = (data) => {
    return data?.map(item => ({
      id: item.qbId,
      name: item.qbDisplayName,
      value: item.qbDisplayNameLower
    }));
  };
  const resetVendor = () => {
    setValue('searchInput', '')
    setSearchTerm('')
  }

  const updateSearchTerm = (event) => {
    if (!event) return
    
    if (event?.target?.value?.length>=3) {
      setSearchTerm(event.target.value)
    }
  }

  const assignVendor = (vendor) => {
    console.log('assigned vendor: ', vendor)
    handleMapVendor(vendor)
    onClose()
  }

  return(
    <>
      <Container pb='25px' as="form" onSubmit={handleSubmit(formSubmit, onError)}>
        <Box>
          <Flex alignItems="center" justifyContent="space-between" pb={'25px'}>
            <Box>
              <Text as="span" textStyle='heading-1'>Map Vendor to QuickBooks</Text>
            </Box>
            <Spacer />
            <Box>
              <ButtonQuaternaryWithIcon 
                name='closeDrawer'
                iconsize='26px'
                leftIcon='close'
                value='Cancel'
                onClick={onClose}
              />
            </Box>
          </Flex>
        </Box>

        <Box paddingTop={'25px'}>
          <Image 
            border={'1px solid var(--dark-unit-image-border)'} 
            src={signedUrl} />
        </Box>

        <Box maxWidth={boxWidth} paddingTop={'25px'}>
          <HStack alignItems="flex-end" height="100%">
            <TextInput
              register={register}
              errors={errors}
              fieldname="searchInput"
              fieldlabel="Search"
              prettyname="Search"
              placeholder="Enter search term"
              onChange={updateSearchTerm}
            />
            <ButtonPrimaryPlain
              type="button"
              onClick={resetVendor}
              height={'40px'}
              name="resetVendor"
              value="Reset"
            />
          </HStack>
        </Box>

        <Box maxWidth={boxWidth} paddingTop={'25px'}>
          <Text>Vendor name as extracted: {!vendorName ? 'Empty - Only this instance will be mapped.' : vendorName}</Text>
          {vendorName && <Text>Receipts with the vendor name extracted as {vendorName} will be auto assigned to the selected QuickBooks Vendor going forward.</Text>}
        </Box>

        <Box width={boxWidth} paddingTop={'25px'}>
          <ScrollableBox handleLoadMore={searchNext} handleSelectedObject={assignVendor} items={qbVendorsResults} width={boxWidth} height='300px' register={register} errors={errors} setError={setError} clearErrors={clearErrors} />
        </Box>

        <Box height={'50px'}></Box>

      </Container>
      <Box height={'50px'}></Box>
    </>
  )
}