import { deleteUnitOperator } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const removeUnitOperator = async (operatorId) => {
  if (!operatorId) return;

  try {
    const params = {
      id: operatorId,
    };

    const response = await client.graphql({
      query: deleteUnitOperator,
      variables: { input: params },
    });

    const results = response.data.deleteSpkDJTOperatorTbl.id;
    return results;
  } catch (err) {
    return ('error removeUnitOperator: ', [err]);
  }
};

removeUnitOperator.propTypes = {
  operatorId: PropTypes.string.isRequired,
};
