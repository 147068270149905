import { getDJTParentalStatus } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchDJTParentalStatusById = async (djtId) => {
  if (!djtId) return;

  try {
    const variables = { id: djtId };

    const response = await client.graphql({
      query: getDJTParentalStatus,
      variables
    });
    
    const results = response.data.getSpkDailyJobTicketTbl;
    
    return results;
  } catch (err) {
    return ('error fetchDJTParentalStatusById: ', [err]);
  }
}

fetchDJTParentalStatusById.propTypes = {
  djtId: PropTypes.string.isRequired,
};
