import { normalizePhoneNumber } from '../functions/formatPhoneNumber.jsx'
import { fetchAuthSession } from 'aws-amplify/auth';

// // Configure the URL for the api query.
const endpointUrl = 'https://ugczaq3r89.execute-api.us-east-1.amazonaws.com/dev/adminupdateuser';

export const updateCognitoUser = async (props) => {
  const {
    oldEmailAddress,
    newEmailAddress,
    newPhoneNumber,
  } = props

  // console.log('updateCognitoUser props', props)

  const normalizedPhoneNumber = (newPhoneNumber?.length>0) ? normalizePhoneNumber(newPhoneNumber) : '';
  // console.log('normalizedPhoneNumber', normalizedPhoneNumber)

  const params = {
    "Username": oldEmailAddress,
    "UserAttributes": [
      {
        "Name": "email",
        "Value": newEmailAddress
      },
      { "Name": "email_verified", 
        "Value": "true" 
      },
      // {
      //   "Name": "phone_number",
      //   "Value": phoneNumber // User's phone number, e.g., "+11234567890"
      // },
      // {
      //   "Name": "phone_number_verified",
      //   "Value": "true" // Set this to true if you verify the phone number externally or want it pre-verified
      // }
    ],
  }

  if (normalizedPhoneNumber?.length>0)  {
    params.UserAttributes.push({
      "Name": "phone_number",
      "Value": normalizedPhoneNumber,
    });
    params.UserAttributes.push({
      "Name": "phone_number_verified",
      "Value": "true",
    });
  }
  // console.log('updateCognitoUser params', params)
  // console.log('updateCognitoUser params.UserAttributes', params.UserAttributes)
  try {

    // Refresh the session to ensure it's current and to get the latest authentication tokens
    const session = await fetchAuthSession();
    const authToken = `Bearer ${session.tokens.idToken}`;
    
    // Prepare the request payload
    let inputJSON = JSON.stringify(params);
    
    // Fetch suggested addresses from your API
    const response = await fetch(endpointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authToken,
      },
      body: inputJSON,
    });

    // console.info('<<<<< RESPONSE >>>>>', response);

    if (response.ok) {
      return response.json(); // This line reads the response stream and parses it as JSON.
    } else {
      throw new Error(response.errorMessage || "Unknown error occurred");
    }

    // const data = await response.json();
    // console.info('***** data: ', data); // Log the parsed data
    // console.info('***** data.body: ', data?.body); // Log the parsed data
    
    // // // Assuming data?.body contains the suggested addresses
    // return JSON.parse(data?.User);
  } catch (error) {
    console.error("Error creating user:", error);
    return error.message || "Unknown error occurred";
  }
};
