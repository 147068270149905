import { useState } from 'react';
import { useQuery } from 'react-query';
import { PropTypes } from 'prop-types';

// graphQl
import { fetchUnitDocumentTypesByGroup } from '../../graphqlCompnents/Unit/fetchUnitDocumentTypesByGroup.jsx';

// Form
import { FormSelectSimple } from '../Select/FormSelectSimple.jsx';

export const UnitDocumentTypes = (props) => {
  const {
    handleUnitDocumentTypeChanged,
    unitDocumentTypeSelectedOption,
    documentType,
    register,
    control,
    errors,
  } = props

  const [ unitDocumentTypeOptions, setUnitDocumentTypeOptions ] = useState(null)
  useQuery([ 'fetchUnitDocumentTypesByGroup', documentType ], () => fetchUnitDocumentTypesByGroup(documentType), {
    enabled: !!documentType, 
    refetchOnWindowFocus: false,
    onSuccess: (documentTypes) => {
      // console.log('documentTypes: ', documentTypes)
      setUnitDocumentTypeOptions(documentTypes)
    }
  });

  return(
    <>
      <FormSelectSimple
        register={register}
        control={control}
        errors={errors}
        optionsArray={
          unitDocumentTypeOptions?.map((options) => ({ 
            label: options.name, value: options.id
          }))
        }
        // onChange={(e, a) => {handleClientContractChanged(e, a)}}
        onChange={handleUnitDocumentTypeChanged}
        defaultValue={unitDocumentTypeSelectedOption}
        selectedoption={unitDocumentTypeSelectedOption}
        // value={jobClassSelected[index]}
        fieldname="selectUnitDocumentType"
        id="selectUnitDocumentType"
        fieldlabel="Unit Document Type"
        placeholder={(unitDocumentTypeOptions?.length>0) ? 'Select document type' : 'No available types'}
        // isSearchable={true}
        // issearchable={(!isUserMobile)}
        // isClearable={true}
        // isclearable={true}
        // isDisabled={(availableClientContracts?.length>0) ? false : true}
      />
    </>
  )
}

UnitDocumentTypes.propTypes = {
  handleUnitDocumentTypeChanged: PropTypes.func.isRequired,
  unitDocumentTypeSelectedOption: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
  documentType: PropTypes.string,
  register: PropTypes.func,
  control: PropTypes.object,
  errors: PropTypes.array,
};

