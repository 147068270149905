import { getTeamMemberByCardLastFour } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchTeamMemberByAccountNumber = async (cardLastFour) => {
  if (!cardLastFour) {
    console.warn('No cardLastFour provided');
    return null;
  }

  try {
    // const allSort = (environment==="sandbox") ? "sandbox" : "all";
    const gqlParams = {
      // filter: {allSort: {eq: allSort}},
      cardLastFour: cardLastFour,
    };

    const response = await client.graphql({
      query: getTeamMemberByCardLastFour,
      variables: gqlParams,
    });

    // Ensure response is as expected
    if (!response || !response.data || !response.data.creditCardTeamMemberByCardLastFour) {
      console.warn(`No data returned for status ${status}`);
      return null; // Return null if the response structure is unexpected
    }

    const results = response.data.creditCardTeamMemberByCardLastFour.items;

    // If results is not an array or is empty, return null
    if (!Array.isArray(results) || results.length === 0) {
      console.warn(`No transactions found for status ${status}`);
      return null;
    }

    return results; // Return the items array if data is present
  } catch (err) {
    console.error('Error in fetchTeamMemberByAccountNumber:', err);
    return null; // Return null on error
  }
};

fetchTeamMemberByAccountNumber.propTypes = {
  cardLastFour: PropTypes.string.isRequired,
};