import { updateSpikeClient } from '../../../graphql/custom_mutations';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const updateBillableClient = async (props) => {
  if (!props) return;

  try {
    const response = await client.graphql({
      query: updateSpikeClient,
      variables: { input: props },
    });

    const results = response?.data?.updateSpkDJTClientTbl?.id;
    return results;
  } catch (err) {
    console.error('error updateBillableClient: ', err);
    return null;
  }
};
