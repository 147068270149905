import { useEffect, useCallback } from 'react';

const usePreventBackNavigation = (shouldPrevent = true) => {
  const preventNavigation = useCallback((event) => {
    if (shouldPrevent) {
      event.preventDefault();
      window.history.pushState(null, null, window.location.href);
      alert('Navigation is disabled on this page. Please use in-app navigation.');
    }
  }, [shouldPrevent]);

  useEffect(() => {
    if (shouldPrevent) {
      window.history.pushState(null, null, window.location.href);
      window.addEventListener('popstate', preventNavigation);
    }

    return () => {
      window.removeEventListener('popstate', preventNavigation);
    };
  }, [shouldPrevent, preventNavigation]);
};

export default usePreventBackNavigation;