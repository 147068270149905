import { getUploadedDocument } from '../../../../graphql/custom_queries';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchUploadedDocument = async (documentId) => {
  if (!documentId) {
    return null;
  }

  try {
    const response = await client.graphql({
      query: getUploadedDocument,
      variables: { id: documentId },
    });

    const uploadedDocument = response?.data?.getSpkUploadedDocumentTbl;
    return uploadedDocument;
  } catch (err) {
    console.error('fetchUploadedDocument error: ', err);
    return null;
  }
};
