// import { getUrl } from 'aws-amplify/storage';
// export const getSignedURLFromS3File = async (document) => {
  
//   if (document) {
//     const s3key = document.key;
//     const s3identityId = document.identityId;

//     const signedUrl = await getUrl({
//       path: s3key,
//       level: "protected",
//       identityId: s3identityId,
//     });

//     console.log('signed URL: ', signedUrl.url);
//     console.log('URL expires at: ', signedUrl.expiresAt);
  
//     return signedUrl.url;
//   }
//   return null
  
// }


// s3SignedUrlHelper.js
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { fetchAuthSession } from 'aws-amplify/auth'; // Import fetchAuthSession for retrieving credentials

/**
 * Fetches credentials using Amplify's `fetchAuthSession` and initializes the S3 client.
 * @returns {Promise<S3Client>} A configured S3 client with credentials.
 */
const getS3Client = async () => {
  try {
    // Fetch the current authentication session
    const session = await fetchAuthSession();
    const credentials = session?.credentials;

    // If no credentials are found, throw an error
    if (!credentials) {
      throw new Error('No credentials found in the session.');
    }

    const { accessKeyId, secretAccessKey, sessionToken } = credentials;

    // Return the configured S3Client instance
    return new S3Client({
      region: 'us-east-1', // Replace with your region
      credentials: {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      },
    });
  } catch (error) {
    console.error('Error fetching credentials:', error);
    throw new Error('Could not get AWS credentials');
  }
};

/**
 * Generates a signed URL for accessing an S3 file.
 * @param {Object} document - The document object containing bucket and key.
 * @param {string} document.bucket - The name of the S3 bucket.
 * @param {string} document.key - The key (path) of the object in the S3 bucket.
 * @param {number} [expiresIn=60] - URL expiration time in seconds (default is 60 seconds).
 * @returns {Promise<string>} The generated signed URL.
 */
export const getSignedURLFromS3File = async (document, expiresIn = 60) => {
  const { bucket, key } = document;

  if (!bucket || !key) {
    console.error('Missing bucket or key in the document');
    throw new Error('Missing bucket or key in the document');
  }

  try {
    // Get the S3 client with credentials
    const s3Client = await getS3Client();

    // Create a new GetObjectCommand with the bucket and key
    const command = new GetObjectCommand({
      Bucket: bucket,
      Key: key,
    });

    // Generate a signed URL with the specified expiration
    const signedUrl = await getSignedUrl(s3Client, command, { expiresIn });
    // console.log('Generated Signed URL:', signedUrl);
    return signedUrl;
  } catch (error) {
    console.error('Error generating signed URL:', error);
    throw new Error(`Error generating signed URL: ${error.message}`);
  }
};