import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    // border: '3px dashed',
    border: '1px solid',  // this also controls select borders
    borderRadius: '6px',
    fontFamily: 'SFPro Text-Regular, Helvetica',
    fontSize: '14px',
    fontWeight: '400',
    // _placeholder: {
    //   color: '#898C90',
    // },
    _placeholder: {
      color: '#ffffff',
    },
    borderColor: 'var(--dark-component-border)',
    // errorBorderColor: 'red',
    // background: 'var(--dark-module-bg)',
    color: '#000000',
    transition: '0.3s',
    _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' },
    _active: { color: 'var(--dark-text-grey-14)', background: 'var(--dark-button-grey-1)', textDecoration: 'none' },

    _focusWithin: {
      ringColor: "white.200",
      ring: "2px",
      ringOffset: "1px",
      ringOffsetColor: "white.100",
      borderColor: "white.50"
    },

    _dark: {
      borderColor: 'var(--dark-component-border)',
      background: 'var(--dark-module-bg)',
      color: 'var(--dark-text-grey-1)',
      transition: '0.8s',
      _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-14)', background: 'var(--dark-button-grey-1)', textDecoration: 'none' },
    },
  },
  addon: {
    border: '1px solid',
    borderRadius: '6px',

    borderColor: 'var(--dark-component-border)',
    background: 'var(--dark-module-bg)',
    color: 'var(--dark-text-grey-1)',

    _dark: {
      borderColor: 'var(--dark-component-border)',
      background: 'var(--dark-module-bg)',
      color: 'var(--dark-text-grey-1)',
      _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-14)', background: 'var(--dark-button-grey-1)', textDecoration: 'none' },
    },
  },
})

const defaultinputred = definePartsStyle({
  field: {
    border: '1px solid',
    borderRadius: '6px',
    fontFamily: 'SFPro Text-Regular, Helvetica',
    // fontSize: '14px',
    fontSize: '16px',
    fontWeight: '400',
    borderColor: 'var(--error-red)',
    // background: 'var(--dark-module-bg)',
    color: '#000000',
    transition: '0.8s',
    _hover: { 
      color: 'var(--error-red)', 
      bg: 'var(--dark-button-grey-1)', 
      textDecoration: 'none' },
    _active: { 
      color: 'var(--error-red)', 
      background: 'var(--dark-button-grey-1)', 
      textDecoration: 'none' 
    },

    _focusWithin: {
      ringColor: "white.200",
      ring: "2px",
      ringOffset: "1px",
      ringOffsetColor: "white.100",
      borderColor: "white.50",
      // background: "pink"
    },

    _dark: {
      borderColor: 'var(--dark-component-border)',
      background: 'var(--dark-module-bg)',
      color: 'var(--error-red)',
      transition: '0.8s',
      _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-14)', background: 'var(--dark-button-grey-1)', textDecoration: 'none' },
    },
  },
  addon: {
    border: '1px solid',
    borderRadius: '6px',

    borderColor: 'var(--dark-component-border)',
    background: 'var(--dark-module-bg)',
    color: 'var(--dark-text-grey-1)',

    _dark: {
      borderColor: 'var(--dark-component-border)',
      background: 'var(--dark-module-bg)',
      color: 'var(--dark-text-grey-1)',
    },
  },
})

const defaultinput = definePartsStyle({
  field: {
    border: '1px solid',
    borderRadius: '6px',
    fontFamily: 'SFPro Text-Regular, Helvetica',
    // fontSize: '14px',
    fontSize: '16px',
    fontWeight: '400',
    borderColor: 'var(--dark-component-border)',
    // background: 'var(--dark-module-bg)',
    color: '#000000',
    transition: '0.8s',
    _hover: { 
      color: 'var(--dark-text-white)', 
      bg: 'var(--dark-button-grey-1)', 
      textDecoration: 'none' },
    _active: { 
      color: 'var(--dark-text-grey-14)', 
      background: 'var(--dark-button-grey-1)', 
      textDecoration: 'none' 
    },

    _focusWithin: {
      ringColor: "white.200",
      ring: "2px",
      ringOffset: "1px",
      ringOffsetColor: "white.100",
      borderColor: "white.50",
      // background: "pink"
    },

    _dark: {
      borderColor: 'var(--dark-component-border)',
      background: 'var(--dark-module-bg)',
      color: 'var(--dark-text-grey-1)',
      transition: '0.8s',
      _hover: { color: 'var(--dark-text-white)', bg: 'var(--dark-button-grey-1)', textDecoration: 'none' },
      _active: { color: 'var(--dark-text-grey-14)', background: 'var(--dark-button-grey-1)', textDecoration: 'none' },
    },
  },
  addon: {
    border: '1px solid',
    borderRadius: '6px',

    borderColor: 'var(--dark-component-border)',
    background: 'var(--dark-module-bg)',
    color: 'var(--dark-text-grey-1)',

    _dark: {
      borderColor: 'var(--dark-component-border)',
      background: 'var(--dark-module-bg)',
      color: 'var(--dark-text-grey-1)',
    },
  },
})

export const inputTheme = defineMultiStyleConfig({
  baseStyle, variants: { defaultinput, defaultinputred },
})

