import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Button } from '@chakra-ui/react';

export const ButtonSecondaryPlain = (props) => {
  const {
    name,
    ref,
    value,
    onClick,
    isDisabled=false,
    mx='',
    my='',
    width='auto',
  } = props;

  return (
    <Button 
      name={name}
      ref={ref}
      variant='plainSecondary' 
      onClick={onClick}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
      width={width}
    >
      {value}
    </Button>
  );
};

// Prop validation
ButtonSecondaryPlain.propTypes = {
  name: PropTypes.string, // Name is expected to be a string
  ref: PropTypes.oneOfType([ // ref can be a function or an object created by React.createRef()
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  value: PropTypes.oneOfType([ // Value can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]).isRequired, // Value is required
  onClick: PropTypes.func, // onClick is a function
  isDisabled: PropTypes.bool, // isDisabled is a boolean
  mx: PropTypes.oneOfType([ // mx can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([ // my can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  width: PropTypes.oneOfType([ // width can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
};


// export const ButtonSecondaryPlainWithRef = forwardRef(function ButtonSecondaryPlainWithRef(props, ref) {
//   const {
//     name,
//     value,
//     onClick,
//     isDisabled,
//     mx,
//     my,
//     width,
//   } = props;

//   return (
//     <Button 
//       {...props}
//       name={name}
//       ref={ref}
//       variant='plainSecondary' 
//       onClick={onClick}
//       isDisabled={isDisabled}
//       mx={mx}
//       my={my}
//       width={width}
//     >
//       {value}
//     </Button>
//   );
// });

// // Setting the displayName property to avoid the ESLint warning
// ButtonSecondaryPlainWithRef.displayName = 'ButtonSecondaryPlainWithRef';

// // Prop validation for ButtonSecondaryPlainWithRef
// ButtonSecondaryPlainWithRef.propTypes = {
//   name: PropTypes.string, 
//   ref: PropTypes.oneOfType([ 
//     PropTypes.func, 
//     PropTypes.shape({ current: PropTypes.instanceOf(Element) })
//   ]),
//   value: PropTypes.oneOfType([ 
//     PropTypes.string,
//     PropTypes.number,
//   ]).isRequired, 
//   onClick: PropTypes.func, 
//   isDisabled: PropTypes.bool, 
//   mx: PropTypes.oneOfType([ 
//     PropTypes.string,
//     PropTypes.number,
//   ]),
//   my: PropTypes.oneOfType([ 
//     PropTypes.string,
//     PropTypes.number,
//   ]),
//   width: PropTypes.oneOfType([ 
//     PropTypes.string,
//     PropTypes.number,
//   ]),
// };

export const ButtonSecondaryPlainWithRef = forwardRef(function ButtonSecondaryPlainWithRef(props, ref) {
  const {
    name,
    value,
    onClick,
    isDisabled,
    mx,
    my,
    width,
    ...restProps // Collect any other props
  } = props;

  return (
    <Button
      {...restProps} // Spread the rest of the props
      name={name}
      ref={ref}
      variant='plainSecondary'
      onClick={onClick}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
      width={width}
    >
      {value}
    </Button>
  );
});

// Setting the displayName property to avoid the ESLint warning
ButtonSecondaryPlainWithRef.displayName = 'ButtonSecondaryPlainWithRef';

// Prop validation for ButtonSecondaryPlainWithRef
ButtonSecondaryPlainWithRef.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  mx: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};