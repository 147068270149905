import PropTypes from 'prop-types';
import {
  Box,
  Text,
  Progress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Center,
  Divider,
  VStack
} from "@chakra-ui/react";
import { AvailableIcons } from "../Icons/AvailableIcons";
import { ButtonNonaryPlain } from "../Form/Button/ButtonNonaryPlain";

export const CenteredSubmitWithProgressModal = (props) => {
  const {
    isModalOpen,
    onModalClose,
    content='',
    closeButtonDisabled = false,
    handleSubmitModalClosed,
    progress = 0,
    successMessage,
    closeButtonVisible = true,
  } = props;

  return (
    <Modal 
      closeOnEsc={false} 
      blockScrollOnMount={true} 
      closeOnOverlayClick={false}  
      onClose={onModalClose} 
      isOpen={isModalOpen} 
      onCloseComplete={handleSubmitModalClosed} 
      isCentered
    >
      <ModalOverlay 
        bg='none'
        backdropFilter='auto'
        backdropBrightness='60%'
        backdropBlur='2px'
      />
      <ModalContent maxH={'310px'} maxW={'500px'} bg={'var(--dark-menu-background)'}>
        {progress === 100 ? (
          <ModalHeader>
            <Box mt={'5px'} h={'105px'}>
              <Center>
                <VStack p='0px' m='0px'>
                  <Box>
                    <AvailableIcons boxSize={'60px'} iconRef={'outlinecheckcircle'} color={'var(--success-green)'} />
                  </Box>
                  <Box>
                    <Text as="span" textStyle='heading-2'>Success!</Text>
                  </Box>
                </VStack>
              </Center>
            </Box>
          </ModalHeader>
        ) : (
          <ModalHeader mx={'25px'}>
            <Box mt={'5px'} h={'105px'}>
              <Center>
                <VStack p='0px' m='0px'>
                  <Box h='70px'></Box>
                  <Box>
                    <Text as="span" textStyle='heading-2'>{content}</Text>
                  </Box>
                </VStack>
              </Center>
            </Box>
          </ModalHeader>
        )}

        <ModalBody mx={'25px'} mb={'20px'}>
          <Progress mb={'25px'} value={progress} size='sm' hasStripe colorScheme='progressbar' />
          {progress === 100 ? (
            <Center>
              <Box w='250px'>
                <Text align='center' textStyle='modal-content-text'>{successMessage}</Text>
              </Box>
            </Center>
          ) : (
            <Center><Box height='50px'></Box></Center>
          )}
        </ModalBody>
        
        <ModalFooter mx={'0px'} px={'0px'} my={'0px'} py={'0px'}>
          {progress === 100 && (
            <Box width={'500px'}>
              <Divider color='var(--dark-divider)' />
              <Center>
                {closeButtonVisible && (
                  <ButtonNonaryPlain 
                    width='100px'
                    name='closeSubmitModal'
                    value='Close'
                    onClick={onModalClose}
                    isDisabled={closeButtonDisabled}
                  />
                )}
              </Center>
            </Box>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

// Prop validation
CenteredSubmitWithProgressModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired, // Modal open state is required
  onModalClose: PropTypes.func.isRequired, // Function to close the modal is required
  content: PropTypes.string, // Content text is required
  closeButtonDisabled: PropTypes.bool, // Boolean to disable the close button
  handleSubmitModalClosed: PropTypes.func, // Function after modal close complete
  progress: PropTypes.number, // Progress value is a number
  successMessage: PropTypes.string.isRequired, // Success message is required
  closeButtonVisible: PropTypes.bool, // Boolean to show/hide close button
};

