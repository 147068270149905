import { useState, useEffect, useContext } from 'react';
// import { useQuery } from 'react-query';
import {
  Container,
  Box,
  // useDisclosure,
} from '@chakra-ui/react';
import { AppContext } from './AppContext.jsx';
import { useForm } from 'react-hook-form';

// graphql components
import { searchDjtsByDivision } from './graphqlCompnents/DJT/searchDjtsByDivision.jsx';
import { getStatusIdByStatusCode } from './graphqlCompnents/DJT/getStatusIdByStatusCode.jsx';
import { fetchAllDivisions } from './graphqlCompnents/Billing/fetchAllDivisions.jsx';

// // generic functions
import { isValidDate } from './functions/dateTime.jsx';
import { formatDateFn } from './functions/dateTime.jsx';
import { isNumber } from './functions/number.jsx';

// components
import { SearchDailyJobTicketsByClientByDate } from './DailyJobTicket/SearchDailyJobTicketsByClientByDate.jsx';
import { ResultsTableEstimatesByClientByDate } from './DailyJobTicket/ResultsTableEstimatesByClientByDate.jsx';

// PDF
import { generatePdf } from './PDF/GeneratePDF.jsx';

// design components
import { ModuleBox } from './Structural/ModuleBox.jsx';
import { FormSelectSimple } from './Form/Select/FormSelectSimple.jsx';

const TableRowHeight = 40; // Assuming each table row is 50px high
const TableHeaderHeight = 410;

// export const Estimator = () => {

//   const { store } = useContext(AppContext);
//   const currentUserId = store?.userData?.id
//   const currentUserIsGlobalAdmin = store?.userData?.isGlobalAdmin||false
//   const doShowAllSupervisorsSwitch = store?.enableAllSupervisors
//   const showDownloadPDFButton = store?.showDownloadPDF||false
//   const [ clientSelected, setClientSelected ] = useState(null)
//   const [ clientIdSelected, setClientIdSelected ] = useState('')
//   const [ djtStartDate, setDjtStartDate ] = useState('')
//   const [ djtEndDate, setDjtEndDate ] = useState('')
//   const [ endDateDisabled, setEndDateDisabled ] = useState(true)
//   const [ showAllResults, setShowAllResults ] = useState(false)
//   const [ djtSearchResultsNextToken, setDjtSearchResultsNextToken ] = useState(null)
//   const [ currentDivisionId, setCurrentDivisionId ] = useState(()=> {
//     return(store?.userData?.divisionId||null)
//   })
//   const [ loadMoreDisabled, setLoadMoreDisabled ] = useState(false)
//   let filteredDjts = []
//   const [ sortedFilteredDjts, setSortedFilteredDjts ] = useState([])

//   // const openDoNotBillDjts =  useQuery(['fetchOpenDoNotBillQueueByDivisionId', currentDivisionId], () => fetchOpenDoNotBillQueueByDivisionId({divisionId: currentDivisionId, isSettled: false}), {
//   //   enabled: !!currentDivisionId, refetchOnWindowFocus: false
//   // });

//   const { control, register, setValue, formState: { errors } } = useForm({
//     mode: 'onSubmit',
//     reValidateMode: 'onChange',
//     defaultValues: {}
//   });

//   const [ divisions, setDivisions ] = useState([])
//   const [ selectedDivision, setSelectedDivision ] = useState(null)
//   // const [ selectedDivisionId, setSelectedDivisionId ] = useState(null)
//   useEffect(() => {
//     // The spell to summon all divisions from the ether
//     const loadDivisions = async () => {
//       try {
//         const divisions = await fetchAllDivisions();
//         console.warn('----- divisions: ', divisions);
//         setDivisions(divisions);
//       } catch (error) {
//         // In case the spell fizzles, you know why
//         console.error('Failed to fetch divisions:', error);
//       }
//     };
  
//     // And... poof! Let the magic happen.
//     loadDivisions();
//   }, []); // The empty array makes sure this happens once, right after the initial curtain lift
  
//   const handleDivisionChanged = (option) => {
//     setSelectedDivision(option)
//     // setSelectedDivisionId(option?.value)
//     setCurrentDivisionId(option?.value)
//   }

//   const [numberOfRows, setNumberOfRows] = useState(0);

//   const updateTableRows = () => {
//     const screenHeight = window.innerHeight;
//     // Calculate the number of rows that can fit, subtract header height from screen height before division
//     let rowsThatCanFit = Math.floor((screenHeight - TableHeaderHeight) / TableRowHeight);
//     if (rowsThatCanFit > 8) {
//         rowsThatCanFit = 75;
//     }
//     setNumberOfRows(rowsThatCanFit);
// };

//   useEffect(() => {
//       updateTableRows();
//       window.addEventListener('resize', updateTableRows);
//       return () => window.removeEventListener('resize', updateTableRows);
//   }, []);

//     //numberOfRows
//   useEffect(() => {
//     (numberOfRows>0) && console.info('numberOfRows: ', numberOfRows)
//   },[numberOfRows])
//   // const searchLimit = 11
//   const searchLimit = numberOfRows

//   const onCustomerChanged = async (event) => {
//     // console.log('event: ', event)
//     setLoadMoreDisabled(false)
//     if (event===null) {
//       // console.log('EVENT IS NULL')
//       setClientSelected(null)
//       setClientIdSelected(null)
//       searchDjts(
//         {
//           djtStatusId: searchStatusTypeId, 
//           divisionId: currentDivisionId, 
//           djtClientId: '', 
//           djtDate: djtStartDate, 
//           djtEndDate: djtEndDate, 
//           limit: searchLimit, 
//           nextToken: null 
//         }, showAllResults, true)
//         .catch(console.error);
//     } else {
//       setClientSelected({label: event.label, value: event.value})
//       setClientIdSelected(event.value)
//       // now search djts for customer
//       searchDjts(
//         {
//           djtStatusId: searchStatusTypeId, 
//           divisionId: currentDivisionId, 
//           djtClientId: event.value, 
//           djtDate: djtStartDate, 
//           djtEndDate: djtEndDate, 
//           limit: searchLimit, 
//           nextToken: null 
//         }, showAllResults, true)
//         .catch(console.error);
//     }
//   }

//   const onStartDateChanged = async (date) => {
//     setLoadMoreDisabled(false)
//     if (date===null) {
//       setEndDateDisabled(true)
//       setDjtStartDate(null)
//       searchDjts(
//         {
//           djtStatusId: searchStatusTypeId, 
//           divisionId: currentDivisionId, 
//           djtClientId: clientIdSelected, 
//           djtDate: '', 
//           djtEndDate: '', 
//           limit: searchLimit, 
//           nextToken: null 
//         }, showAllResults, true)
//         .catch(console.error);
//     } else {
//       const startDate = date
//       if (isValidDate(date)) {
//         setEndDateDisabled(false)
//         setDjtStartDate(startDate)
//         searchDjts(
//           {
//             djtStatusId: searchStatusTypeId, 
//             divisionId: currentDivisionId, 
//             djtClientId: clientIdSelected, 
//             djtDate: startDate, 
//             djtEndDate: djtEndDate, 
//             limit: searchLimit, 
//             nextToken: null 
//           }, showAllResults, true)
//           .catch(console.error);
//       }
//     }
//   }

//   const onEndDateChanged = async (date) => {
//     setLoadMoreDisabled(false)
//     if (date===null) {
//       setDjtEndDate(null)
//       searchDjts(
//         {
//           djtStatusId: searchStatusTypeId, 
//           divisionId: currentDivisionId, 
//           djtClientId: clientIdSelected, 
//           djtDate: djtStartDate, 
//           djtEndDate: '', 
//           limit: searchLimit, 
//           nextToken: null 
//         }, showAllResults, true)
//         .catch(console.error);
//     } else {
//       const endDate = date
//       if (isValidDate(endDate)) {
//         setEndDateDisabled(false)
//         setDjtEndDate(endDate)
//         searchDjts(
//           {
//             djtStatusId: searchStatusTypeId, 
//             divisionId: currentDivisionId, 
//             djtClientId: clientIdSelected, 
//             djtDate: djtStartDate, 
//             djtEndDate: endDate, 
//             limit: searchLimit, 
//             nextToken: null 
//           }, showAllResults, true)
//           .catch(console.error);
//       }
//     }
//   }

//   const [ createdAsEstimateStatusId, setCreatedAsEstimateStatusId ] = useState(null)  // created as estimate
//   // const [ pendingSubmissionStatusId, setPendingSubmissionStatusId ] = useState(null)  // drafts
//   // const [ pendingApprovalStatusId, setPendingApprovalStatusId ] = useState(null)      // submitted
//   // const [ approvalRequiredStatusId, setApprovalRequiredStatusId ] = useState(null)    // approval issue
//   let approvalRequiredStatusId = null;
//   const [ deletedStatusId, setDeletedStatusId ] = useState(null)                      // deleted
//   const [ archivedStatusId, setArchivedStatusId ] = useState(null)                    // archived
//   const [ showSearchResutlsTable, setShowSearchResutlsTable ] = useState(true)
//   // const [ statusRecentFetched, setStatusRecentFetched ] = useState(false) // use these when enabling actions on daily job tickets
//   // const [ statusSavedSet, setStatusSavedSet ] = useState(false)
//   const [ searchStatusTypeId, setSearchStatusTypeId] = useState(null)

//   useEffect(() => {
//     const fetchStatusIds = async () => {
//       if (!createdAsEstimateStatusId) {
//         const createdAsEstimateId = await getStatusIdByStatusCode('created-as-estimate')  // estimates
//         setCreatedAsEstimateStatusId(createdAsEstimateId)
//         // setStatusRecentFetched(true)
//       }
//     }
//     fetchStatusIds()
//   },[createdAsEstimateStatusId])

//   useEffect(() => {
//     const fetchStatusIds = async () => {
//       if (!archivedStatusId) {
//         const archivedId = await getStatusIdByStatusCode('archived-estimate')  // archived-estimate
//         setArchivedStatusId(archivedId)
//         // setStatusRecentFetched(true)
//       }
//     }
//     fetchStatusIds()
//   },[archivedStatusId])

//   useEffect(() => {
//     const fetchStatusIds = async () => {
//       if (!deletedStatusId) {
//         const deletedId = await getStatusIdByStatusCode('deleted-estimate')      // deleted-estimate
//         setDeletedStatusId(deletedId)
//         // setStatusSavedSet(true)
//       }
//     }
//     fetchStatusIds()
//   },[deletedStatusId])

//   // should be initial load only - set to saved submissions
//   useEffect(() => {
//     (!searchStatusTypeId && createdAsEstimateStatusId) && setSearchStatusTypeId(createdAsEstimateStatusId)
//   },[searchStatusTypeId, createdAsEstimateStatusId])

//   // useEffect(() => {
//   //   (!searchStatusTypeId && pendingSubmissionStatusId) && setSearchStatusTypeId(pendingSubmissionStatusId)
//   // },[searchStatusTypeId, pendingSubmissionStatusId])

//   const searchStatusId = [
//     createdAsEstimateStatusId,  // 'created-as-estimate', 153a7d46-b025-486e-8417-eca2af57d8bb
//     archivedStatusId,           // 'archived-estimate', 6a9af4ee-a185-4f3d-a810-eb5d2f9843d8
//     deletedStatusId,            // 'deleted', 15b03dbc-14bb-47fa-a240-44d0992afb2b
//     // pendingSubmissionStatusId,  // 'queued-pending-submission', 7fd6f20e-9698-4646-ac68-beebab1d0d5b
//     // pendingApprovalStatusId,    // 'queued-pending-approval', 2a279c1e-110c-4223-a2bd-e411fc51f89e
//     // approvalRequiredStatusId,   // 'queued-unapproved-with-issue', 468eb65a-8471-47e9-98cb-3258570f71fa
//   ]

//   // const [ isReviewing, setIsReviewing ] = useState(false)
//   let isReviewing = false;
//   const [ currentSelectedTab, setCurrentSelectedTab ] = useState(0)
//   const handleTabChange = async (tab) => {
//     setShowSearchResutlsTable(false)
//     // if (tab===2) {
//     //   setIsReviewing(true)
//     // } else {
//     //   setIsReviewing(false)
//     // }

//     // tab index 0 is estimates
//     // tab index 1 is archived - statusCode is archived-estimate

//     // clear results table and wait for new data.
//     const status = searchStatusId[tab]
//     setCurrentSelectedTab(tab)
//     // let status = null
//     // if (tab===0) {
//     //   status = searchStatusId[0]
//     // }
//     // if (tab===1) {
//     //   status = searchStatusId[5]
//     // }
//     // const status = searchStatusId[0]
//     setSearchStatusTypeId(status)
//     // const searchProps = {
//     //   djtStatusId: status,
//     //   divisionId: currentDivisionId, 
//     //   djtClientId: clientIdSelected, 
//     //   djtDate: djtStartDate, 
//     //   djtEndDate: djtEndDate, 
//     //   limit: searchLimit, 
//     //   nextToken: null,
//     // }
//     // const isLoaded = await searchDjts(searchProps, showAllResults, true)
//     setShowSearchResutlsTable(true)
//   }

//   // useEffect(() => {
//   //   (searchStatusTypeId) && console.log('searchStatusTypeId: ', searchStatusTypeId)
//   // },[searchStatusTypeId])

//   const [ approvalRequiredCount, setApprovalRequiredCount ] = useState(0)
//   const [ djtSearchProps, setDjtSearchProps ] = useState(null)
//   const searchDjts = async (props, optShowAll, optClearAll) => {
//     // console.log('performing search with props: ', props)
//     // depending on the tab we are in, pass one of the following to the search function
//     //pendingSubmissionStatusId
//     //pendingApprovalStatusId
//     // openDoNotBillDjts?.refetch()
//     let searchProps = null
//     if (!props) {
//       searchProps = {
//         djtStatusId: searchStatusTypeId,
//         divisionId: currentDivisionId, 
//         djtClientId: clientIdSelected, 
//         djtDate: djtStartDate, 
//         djtEndDate: djtEndDate, 
//         limit: searchLimit, 
//         nextToken: null,
        
//         // statusId: pendingApprovalStatusId,
//       }
//       optClearAll=true
//     } else {
//       searchProps = props
//     }

//     // default to show only super djts
//     let doShowAllResults = false
//     if (optShowAll===true) {
//       doShowAllResults = true
//     }
//     if (optShowAll===false) {
//       doShowAllResults = false
//     }
//     if (optShowAll===undefined) {
//       doShowAllResults = showAllResults
//     }

//     // Either show all results or only supervisor submitted djts
//     if (doShowAllResults) {
//       searchProps.teamMemberId = null
//     } else {
//       searchProps.teamMemberId = currentUserId
//     }

//     setDjtSearchProps(searchProps)
//     setShowAllResults(doShowAllResults)

//     // we shouldn't run this every time.
//     const approvalRequiredPending = await searchDjtsByDivision({
//       djtStatusId: approvalRequiredStatusId,
//       divisionId: currentDivisionId, 
//       djtClientId: "", 
//       teamMemberId: currentUserId,
//       djtDate: "", 
//       djtEndDate: "", 
//       limit: searchLimit, 
//       nextToken: null,
//     })

//     const approvalRequiredPendingCount = approvalRequiredPending?.items?.length
//     if (isNumber(approvalRequiredPendingCount)) {
//       setApprovalRequiredCount(approvalRequiredPendingCount)
//     }
    
//     // we need to merge the existing array in with the search results unless this is a new search
//     let allDjtData = []
//     const allNewDjtData = await searchDjtsByDivision(searchProps)

//     // serchProps.djtStatusId = approvalRequiredStatusId
//     // this doesn't even get called until we click the tab. Need to call this sooner.
//     // if (allNewDjtData.data.items.length>0 && isReviewing===true) setApprovalRequiredCount(allNewDjtData.data.items.length)
//     // we need to run the approval required query to get the count before the first tab is loaded
//     // console.log('SEARCH PROPS: ', searchProps)
//     // console.log(' >>>>> DATA FROM SEARCH: ', allNewDjtData)
    
//     const allNewDjtDataNextToken = allNewDjtData?.nextToken

//     // if clearAll, then wipe previous data
//     if (optClearAll===true) {
//       allDjtData = allNewDjtData?.items
//       allDjtData.nextToken = allNewDjtDataNextToken
//       setSortedFilteredDjts(null)
//     } else {
//       if (sortedFilteredDjts!==undefined && sortedFilteredDjts!==null && sortedFilteredDjts?.length>0) {
//         // clear out the waitingForParent flag
//         // console.log('clearing')
//         for (const djt of sortedFilteredDjts) {
//           djt.waitingForParent = false
//         }

//         const mergedArray = sortedFilteredDjts.concat(allNewDjtData.items)
//         allDjtData = mergedArray
//         allDjtData.nextToken = allNewDjtDataNextToken
//       } else {
//         // console.log('not clearing')
//         allDjtData = allNewDjtData?.items
//         allDjtData.nextToken = allNewDjtDataNextToken
//       }
//     }

//     // console.log('should be all waiting:', allDjtData)

//     // filter allDjtData for parents or childless
//     // these are coming in as most recent first
//     const djtDataParentOrChildless = allDjtData?.filter( 
//       djt => djt?.isDuplicate===false 
//     )

//     // since we want to display these as newest first, we need to flip this array
//     // so as we filter it, we end up with newest first
//     djtDataParentOrChildless.sort((a, b) => a.djtDate - b.djtDate)

//     // console.log('djtDataParentOrChildless: ', djtDataParentOrChildless)
//     const djtChildren = allDjtData?.filter(
//       djt => djt?.isDuplicate===true
//     )
    
//     // iterate through non-duplicates
//     djtDataParentOrChildless?.forEach( djt => {
//       const thisDjtId = djt.id

//       // this is NOT a child/duplicate, it is a parent -  merge into the all allray
//       filteredDjts.push(djt)

//       // now check if it has any children - some of these children may not have parents loaded yet.
//       const childrenOfThisParentUnsorted = djtChildren?.filter(e => e.parentId===thisDjtId)

//       // now we need to reverse the sorting on these. parents get sorted from most recent forward
//       // children get sorted from oldest to newest forward
//       const childrenOfThisParent = childrenOfThisParentUnsorted?.sort((a, b) => a.djtDate > b.djtDate)

//       if (childrenOfThisParent?.length>0) {
//         // this current djt has kids
//         childrenOfThisParent.forEach( child => {
//           filteredDjts.push(child)
//           const indexOfRemovedChild = djtChildren.findIndex(object => {
//             return object.id === child.id
//           })
//           djtChildren.splice(indexOfRemovedChild, 1)
//         })
//       }
//     })

//     // for each of the orphaned children, re-insert them in the correct order
//     djtChildren.forEach( djtChild => {
//       // console.log(' >>>>> child nodes of parent djtNotes: ', djtChild.djtNotes)
//       djtChild.waitingForParent = true

//       // did we find somewhere to put it?
//       let insertedDjt = false
//       // if the orphaned child djtDate is <= filtered djt date && the 
//       // filteredDjt is not a duplciate, insert
//       for (const [djtIndex, filteredDjt] of filteredDjts.entries()) {
        
//         if (djtChild.djtDate>=filteredDjt.djtDate && filteredDjt.isDuplicate!==true) {
//           // console.log('THIS IS WHERE IT GETS INSERTED')
//           filteredDjts.splice(djtIndex, 0, djtChild)
//           insertedDjt = true
//           break
//         }
//       }
//       // if we made it here we have a straggler that didn't match any of the current
//       if (insertedDjt===false) {
//         const filteredDjtsInsertAt = filteredDjts.length+1
//         filteredDjts.splice(filteredDjtsInsertAt, 0, djtChild)
//       }
//     })

//     // console.log(' ---- filteredDjts: ', filteredDjts)
//     // console.log(' ---- all djts: ', allDjtData)
    
//     setLoadMoreDisabled(false)
//     if (allDjtData?.nextToken===null || allDjtData?.length===0 || allDjtData?.length<searchLimit ) {
//       setLoadMoreDisabled(true)
//     }

//     setDjtSearchResultsNextToken(allDjtData?.nextToken)
//     let clearExistingResults = (optClearAll===true) ? true : false
//     if (searchProps.nextToken!==null && clearExistingResults===false) {
//       // setDjtSearchResults(searchResults => searchResults.concat(filteredDjts))

//       // mergin up above before here
//       // setSortedFilteredDjts(searchResults => searchResults.concat(filteredDjts))
//       setSortedFilteredDjts(filteredDjts)
//     } else {
//       // setDjtSearchResults(filteredDjts)
//       setSortedFilteredDjts(filteredDjts)
//     }

//     // check if everything loaded and return loaded status
//     return(true)
//   }

//   // initial search, hard coded for recent submissions, show pending approvals
//   useEffect(() => {
//     if (currentDivisionId && createdAsEstimateStatusId) {
//       setValue('searchDate', null)
//       setValue('endDate', null)
//       setValue('selectCustomer', null)
//       // setLoadMoreDisabled(false)
//       setLoadMoreDisabled(true)
//       searchDjts({djtStatusId: createdAsEstimateStatusId, divisionId: currentDivisionId, djtClientId: '', djtDate: '', djtEndDate: '', limit: searchLimit, nextToken: null }, showAllResults)
//         .catch(console.error);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [currentDivisionId, createdAsEstimateStatusId])

//   const [startDate, setStartDate] = useState(new Date());
//   const [endDate, setEndDate] = useState(new Date());

//   const handleLoadMore = () => {
//     searchDjts({
//       djtStatusId: searchStatusTypeId,
//       divisionId: currentDivisionId, 
//       djtClientId: clientIdSelected, 
//       djtDate: djtStartDate, 
//       djtEndDate: djtEndDate, 
//       limit: searchLimit, 
//       nextToken: djtSearchResultsNextToken 
//     }, showAllResults).catch(console.error);
//   }

//   const handleShowAllToggle = async (showAll) => {
//     // clear out the existing serach results
//     // setSortedFilteredDjts(null)

//     // console.log(' <<<<<< showAll in load more:', showAll)
//     // toggle the data getting sent down to results
//     setShowAllResults(showAll)
//     // console.log('djtSearchProps before: ', djtSearchProps)
//     djtSearchProps.nextToken = null
//     setLoadMoreDisabled(true)
//     // console.log('djtSearchProps after: ', djtSearchProps)
//     searchDjts(djtSearchProps, showAll, true)
//   }

//   return (

//     <Container as="form">

//       <SearchDailyJobTicketsByClientByDate 
//         control={control}
//         register={register}
//         endDateDisabled={endDateDisabled}
//         onChange={(event, action) => onCustomerChanged(event, action)}
//         startDate={startDate}
//         startDateOnChange={(date) => {
//           setStartDate(date);
//           onStartDateChanged(formatDateFn(date), "selected");
//         }}
//         endDate={endDate}
//         endDateOnChange={(date) => {
//           setEndDate(date);
//           onEndDateChanged(formatDateFn(date), "selected");
//         }}
//         clientSelected={clientSelected}
//         djtStartDate={djtStartDate}
//         djtEndDate={djtEndDate}
//         clientIconOnClick={() => {
//           setClientIdSelected(null);
//           setClientSelected(null);
//           const action = {action: "clear", removedValues: [clientSelected], name: "selectCustomer"};
//           onCustomerChanged(null, action);
//         }}
//         startDateIconOnClick={() => {
//           setStartDate(new Date())
//           setDjtStartDate(null);
//           onStartDateChanged(null);
//         }}
//         endDateIconOnClick={() => {
//           setEndDate(new Date())
//           setDjtEndDate(null);
//           onEndDateChanged(null);
//         }}
//       />

//       {(currentUserIsGlobalAdmin) && <ModuleBox>
//         <Box w={'50%'}>
//           <FormSelectSimple
//             key='select_division'
//             register={register}
//             control={control}
//             errors={errors}
//             isRequired={true}
//             fieldname="selectDivision"
//             fieldlabel="Select Division"
//             placeholder={'Select division or start typing'}
//             optionsArray={divisions?.map(({ defaultQBClass, id }) => ({
//               label: defaultQBClass,
//               value: id,
//             }))}
//             onChange={(e) => handleDivisionChanged(e)}
//             selectedoption={selectedDivision}
//             />
//           </Box>
//       </ModuleBox>}

//       <ResultsTableEstimatesByClientByDate
//         djtSearchResults={sortedFilteredDjts}
//         loadMoreOnClick={() => handleLoadMore()}
//         loadMoreDisabled={loadMoreDisabled}
//         handleRefresh={searchDjts}
//         handleShowAllToggle={handleShowAllToggle}
//         currentUserId={currentUserId}
//         handleTabChange={handleTabChange}
//         showSearchResutlsTable={showSearchResutlsTable}
//         isReviewing={isReviewing}
//         approvalRequiredCount={approvalRequiredCount}
//         doShowAllSupervisorsSwitch={doShowAllSupervisorsSwitch}
//         showDownloadPDFButton={showDownloadPDFButton}
//         // handleGeneratePdf={handleGeneratePdf}
//         handleGeneratePdf={generatePdf}
//         currentTab={currentSelectedTab}
//         register={register}
//         errors={errors}
//       />

//       <Box mt='50px'></Box>

//     </Container>


//   );
// }

export const Estimator = () => {

  const { store } = useContext(AppContext);
  const currentUserId = store?.userData?.id
  const currentUserIsGlobalAdmin = store?.userData?.isGlobalAdmin||false
  const doShowAllSupervisorsSwitch = store?.enableAllSupervisors
  const showDownloadPDFButton = store?.showDownloadPDF||false
  const [ clientSelected, setClientSelected ] = useState(null)
  const [ clientIdSelected, setClientIdSelected ] = useState('')
  const [ djtStartDate, setDjtStartDate ] = useState('')
  const [ djtEndDate, setDjtEndDate ] = useState('')
  const [ endDateDisabled, setEndDateDisabled ] = useState(true)
  const [ showAllResults, setShowAllResults ] = useState(false)
  const [ djtSearchResultsNextToken, setDjtSearchResultsNextToken ] = useState(null)
  const currentDivisionId = store?.userData?.divisionId
  const [ loadMoreDisabled, setLoadMoreDisabled ] = useState(false)
  // all of the djts get merged into this array
  let filteredDjts = []
  // the final object to store this filtered array
  const [ sortedFilteredDjts, setSortedFilteredDjts ] = useState([])

  // const { isOpen: isUpdateDNBWaitingOnPOModalOpen , onOpen: onUpdateDNBWaitingOnPOModalOpen, onClose: onUpdateDNBWaitingOnPOModalClose } = useDisclosure()
  // const { isOpen: isUpdateDNBWaitingOnMaterialCostModalOpen , onOpen: onUpdateDNBWaitingOnMaterialCostModalOpen, onClose: onUpdateDNBWaitingOnMaterialCostModalClose } = useDisclosure()
  // const { isOpen: isUpdateDNBWaitingOnSignatureModalOpen , onOpen: onUpdateDNBWaitingOnSignatureModalOpen, onClose: onUpdateDNBWaitingOnSignatureModalClose } = useDisclosure()

  // const [ hasOpenDoNotBillDjts, setHasOpenDoNotBillDjts ] = useState(false)


  const { control, register, setValue, formState: { errors } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {}
  });




  const [ divisions, setDivisions ] = useState([])
  const [ selectedDivision, setSelectedDivision ] = useState(null)
  useEffect(() => {
    // The spell to summon all divisions from the ether
    const loadDivisions = async () => {
      try {
        const divisions = await fetchAllDivisions();
        console.warn('----- divisions: ', divisions);
        setDivisions(divisions);
      } catch (error) {
        // In case the spell fizzles, you know why
        console.error('Failed to fetch divisions:', error);
      }
    };
  
    // And... poof! Let the magic happen.
    loadDivisions();
  }, []); // The empty array makes sure this happens once, right after the initial curtain lift
  
  const handleDivisionChanged = (option) => {
    setSelectedDivision(option)

  }

  const [numberOfRows, setNumberOfRows] = useState(0);

  const updateTableRows = () => {
    const screenHeight = window.innerHeight;
    // Calculate the number of rows that can fit, subtract header height from screen height before division
    let rowsThatCanFit = Math.floor((screenHeight - TableHeaderHeight) / TableRowHeight);
    if (rowsThatCanFit > 8) {
        rowsThatCanFit = 75;
    }
    setNumberOfRows(rowsThatCanFit);
};

  useEffect(() => {
      updateTableRows();
      window.addEventListener('resize', updateTableRows);
      return () => window.removeEventListener('resize', updateTableRows);
  }, []);

    //numberOfRows
  useEffect(() => {
    (numberOfRows>0) && console.info('numberOfRows: ', numberOfRows)
  },[numberOfRows])
  // const searchLimit = 11
  const searchLimit = numberOfRows

  const onCustomerChanged = async (event) => {
    // console.log('event: ', event)
    setLoadMoreDisabled(false)
    if (event===null) {
      // console.log('EVENT IS NULL')
      setClientSelected(null)
      setClientIdSelected(null)
      searchDjts(
        {
          djtStatusId: searchStatusTypeId, 
          divisionId: currentDivisionId, 
          djtClientId: '', 
          djtDate: djtStartDate, 
          djtEndDate: djtEndDate, 
          limit: searchLimit, 
          nextToken: null 
        }, showAllResults, true)
        .catch(console.error);
    } else {
      setClientSelected({label: event.label, value: event.value})
      setClientIdSelected(event.value)
      // now search djts for customer
      searchDjts(
        {
          djtStatusId: searchStatusTypeId, 
          divisionId: currentDivisionId, 
          djtClientId: event.value, 
          djtDate: djtStartDate, 
          djtEndDate: djtEndDate, 
          limit: searchLimit, 
          nextToken: null 
        }, showAllResults, true)
        .catch(console.error);
    }
  }

  const onStartDateChanged = async (date) => {
    setLoadMoreDisabled(false)
    if (date===null) {
      setEndDateDisabled(true)
      setDjtStartDate(null)
      searchDjts(
        {
          djtStatusId: searchStatusTypeId, 
          divisionId: currentDivisionId, 
          djtClientId: clientIdSelected, 
          djtDate: '', 
          djtEndDate: '', 
          limit: searchLimit, 
          nextToken: null 
        }, showAllResults, true)
        .catch(console.error);
    } else {
      const startDate = date
      if (isValidDate(date)) {
        setEndDateDisabled(false)
        setDjtStartDate(startDate)
        searchDjts(
          {
            djtStatusId: searchStatusTypeId, 
            divisionId: currentDivisionId, 
            djtClientId: clientIdSelected, 
            djtDate: startDate, 
            djtEndDate: djtEndDate, 
            limit: searchLimit, 
            nextToken: null 
          }, showAllResults, true)
          .catch(console.error);
      }
    }
  }

  const onEndDateChanged = async (date) => {
    setLoadMoreDisabled(false)
    if (date===null) {
      setDjtEndDate(null)
      searchDjts(
        {
          djtStatusId: searchStatusTypeId, 
          divisionId: currentDivisionId, 
          djtClientId: clientIdSelected, 
          djtDate: djtStartDate, 
          djtEndDate: '', 
          limit: searchLimit, 
          nextToken: null 
        }, showAllResults, true)
        .catch(console.error);
    } else {
      const endDate = date
      if (isValidDate(endDate)) {
        setEndDateDisabled(false)
        setDjtEndDate(endDate)
        searchDjts(
          {
            djtStatusId: searchStatusTypeId, 
            divisionId: currentDivisionId, 
            djtClientId: clientIdSelected, 
            djtDate: djtStartDate, 
            djtEndDate: endDate, 
            limit: searchLimit, 
            nextToken: null 
          }, showAllResults, true)
          .catch(console.error);
      }
    }
  }

  const [ createdAsEstimateStatusId, setCreatedAsEstimateStatusId ] = useState(null)  // created as estimate
  let approvalRequiredStatusId = null;
  const [ deletedStatusId, setDeletedStatusId ] = useState(null)                      // deleted
  const [ archivedStatusId, setArchivedStatusId ] = useState(null)                    // archived
  const [ showSearchResutlsTable, setShowSearchResutlsTable ] = useState(true)
  const [ searchStatusTypeId, setSearchStatusTypeId] = useState(null)

  useEffect(() => {
    const fetchStatusIds = async () => {
      if (!createdAsEstimateStatusId) {
        const createdAsEstimateId = await getStatusIdByStatusCode('created-as-estimate')  // estimates
        setCreatedAsEstimateStatusId(createdAsEstimateId)
      }
    }
    fetchStatusIds()
  },[createdAsEstimateStatusId])

  useEffect(() => {
    const fetchStatusIds = async () => {
      if (!archivedStatusId) {
        const archivedId = await getStatusIdByStatusCode('archived-estimate')  // archived-estimate
        setArchivedStatusId(archivedId)
      }
    }
    fetchStatusIds()
  },[archivedStatusId])

  useEffect(() => {
    const fetchStatusIds = async () => {
      if (!deletedStatusId) {
        const deletedId = await getStatusIdByStatusCode('deleted-estimate')      // deleted-estimate
        setDeletedStatusId(deletedId)
      }
    }
    fetchStatusIds()
  },[deletedStatusId])

  // useEffect(() => {
  //   const fetchStatusIds = async () => {
  //     if (!approvalRequiredStatusId) {
  //       const pendingApprovalRequiredId = await getStatusIdByStatusCode('queued-unapproved-with-issue') // approval issue
  //       setApprovalRequiredStatusId(pendingApprovalRequiredId)
  //       // might need to enable something here to pass to ticket so only display one button and textarea for message to admin
  //     }
  //   }
  //   fetchStatusIds()
  // },[approvalRequiredStatusId])

  // useEffect(() => {
  //   const fetchStatusIds = async () => {
  //     if (!deletedStatusId) {
  //       const deletedId = await getStatusIdByStatusCode('deleted') // deleted
  //       setDeletedStatusId(deletedId)
  //       // might need to enable something here to pass to ticket so only display one button and textarea for message to admin
  //     }
  //   }
  //   fetchStatusIds()
  // },[deletedStatusId])

  // should be initial load only - set to saved submissions
  useEffect(() => {
    (!searchStatusTypeId && createdAsEstimateStatusId) && setSearchStatusTypeId(createdAsEstimateStatusId)
  },[searchStatusTypeId, createdAsEstimateStatusId])

  // useEffect(() => {
  //   (!searchStatusTypeId && pendingSubmissionStatusId) && setSearchStatusTypeId(pendingSubmissionStatusId)
  // },[searchStatusTypeId, pendingSubmissionStatusId])

  const searchStatusId = [
    createdAsEstimateStatusId,  // 'created-as-estimate', 153a7d46-b025-486e-8417-eca2af57d8bb
    archivedStatusId,           // 'archived-estimate', 6a9af4ee-a185-4f3d-a810-eb5d2f9843d8
    deletedStatusId,            // 'deleted', 15b03dbc-14bb-47fa-a240-44d0992afb2b
    // pendingSubmissionStatusId,  // 'queued-pending-submission', 7fd6f20e-9698-4646-ac68-beebab1d0d5b
    // pendingApprovalStatusId,    // 'queued-pending-approval', 2a279c1e-110c-4223-a2bd-e411fc51f89e
    // approvalRequiredStatusId,   // 'queued-unapproved-with-issue', 468eb65a-8471-47e9-98cb-3258570f71fa
  ]

  let isReviewing = false
  const [ currentSelectedTab, setCurrentSelectedTab ] = useState(0)
  const handleTabChange = async (tab) => {
    setShowSearchResutlsTable(false)
    const status = searchStatusId[tab]
    setCurrentSelectedTab(tab)
    setSearchStatusTypeId(status)
    const searchProps = {
      djtStatusId: status,
      divisionId: currentDivisionId, 
      djtClientId: clientIdSelected, 
      djtDate: djtStartDate, 
      djtEndDate: djtEndDate, 
      limit: searchLimit, 
      nextToken: null,
    }
    await searchDjts(searchProps, showAllResults, true)
    setShowSearchResutlsTable(true)
  }

  const [ approvalRequiredCount, setApprovalRequiredCount ] = useState(0)
  const [ djtSearchProps, setDjtSearchProps ] = useState(null)
  const searchDjts = async (props, optShowAll, optClearAll) => {
    let searchProps = null
    if (!props) {
      searchProps = {
        djtStatusId: searchStatusTypeId,
        divisionId: currentDivisionId, 
        djtClientId: clientIdSelected, 
        djtDate: djtStartDate, 
        djtEndDate: djtEndDate, 
        limit: searchLimit, 
        nextToken: null,
        
        // statusId: pendingApprovalStatusId,
      }
      optClearAll=true
    } else {
      searchProps = props
    }

    // default to show only super djts
    let doShowAllResults = false
    if (optShowAll===true) {
      doShowAllResults = true
    }
    if (optShowAll===false) {
      doShowAllResults = false
    }
    if (optShowAll===undefined) {
      doShowAllResults = showAllResults
    }

    // Either show all results or only supervisor submitted djts
    if (doShowAllResults) {
      searchProps.teamMemberId = null
    } else {
      searchProps.teamMemberId = currentUserId
    }

    setDjtSearchProps(searchProps)
    setShowAllResults(doShowAllResults)

    // we shouldn't run this every time.
    const approvalRequiredPending = await searchDjtsByDivision({
      djtStatusId: approvalRequiredStatusId,
      divisionId: currentDivisionId, 
      djtClientId: "", 
      teamMemberId: currentUserId,
      djtDate: "", 
      djtEndDate: "", 
      limit: searchLimit, 
      nextToken: null,
    })

    const approvalRequiredPendingCount = approvalRequiredPending?.items?.length
    if (isNumber(approvalRequiredPendingCount)) {
      setApprovalRequiredCount(approvalRequiredPendingCount)
    }
    
    // we need to merge the existing array in with the search results unless this is a new search
    let allDjtData = []
    const allNewDjtData = await searchDjtsByDivision(searchProps)

    // serchProps.djtStatusId = approvalRequiredStatusId
    // this doesn't even get called until we click the tab. Need to call this sooner.
    // if (allNewDjtData.data.items.length>0 && isReviewing===true) setApprovalRequiredCount(allNewDjtData.data.items.length)
    // we need to run the approval required query to get the count before the first tab is loaded
    // console.log('SEARCH PROPS: ', searchProps)
    // console.log(' >>>>> DATA FROM SEARCH: ', allNewDjtData)
    
    const allNewDjtDataNextToken = allNewDjtData?.nextToken

    // if clearAll, then wipe previous data
    if (optClearAll===true) {
      allDjtData = allNewDjtData?.items
      allDjtData.nextToken = allNewDjtDataNextToken
      setSortedFilteredDjts(null)
    } else {
      if (sortedFilteredDjts!==undefined && sortedFilteredDjts!==null && sortedFilteredDjts?.length>0) {
        // clear out the waitingForParent flag
        // console.log('clearing')
        for (const djt of sortedFilteredDjts) {
          djt.waitingForParent = false
        }

        const mergedArray = sortedFilteredDjts.concat(allNewDjtData.items)
        allDjtData = mergedArray
        allDjtData.nextToken = allNewDjtDataNextToken
      } else {
        // console.log('not clearing')
        allDjtData = allNewDjtData?.items
        allDjtData.nextToken = allNewDjtDataNextToken
      }
    }

    // console.log('should be all waiting:', allDjtData)

    // filter allDjtData for parents or childless
    // these are coming in as most recent first
    const djtDataParentOrChildless = allDjtData?.filter( 
      djt => djt?.isDuplicate===false 
    )

    // since we want to display these as newest first, we need to flip this array
    // so as we filter it, we end up with newest first
    djtDataParentOrChildless.sort((a, b) => a.djtDate - b.djtDate)

    // console.log('djtDataParentOrChildless: ', djtDataParentOrChildless)
    const djtChildren = allDjtData?.filter(
      djt => djt?.isDuplicate===true
    )
    
    // iterate through non-duplicates
    djtDataParentOrChildless?.forEach( djt => {
      const thisDjtId = djt.id

      // this is NOT a child/duplicate, it is a parent -  merge into the all allray
      filteredDjts.push(djt)

      // now check if it has any children - some of these children may not have parents loaded yet.
      const childrenOfThisParentUnsorted = djtChildren?.filter(e => e.parentId===thisDjtId)

      // now we need to reverse the sorting on these. parents get sorted from most recent forward
      // children get sorted from oldest to newest forward
      const childrenOfThisParent = childrenOfThisParentUnsorted?.sort((a, b) => a.djtDate > b.djtDate)

      if (childrenOfThisParent?.length>0) {
        // this current djt has kids
        childrenOfThisParent.forEach( child => {
          filteredDjts.push(child)
          const indexOfRemovedChild = djtChildren.findIndex(object => {
            return object.id === child.id
          })
          djtChildren.splice(indexOfRemovedChild, 1)
        })
      }
    })

    // for each of the orphaned children, re-insert them in the correct order
    djtChildren.forEach( djtChild => {
      // console.log(' >>>>> child nodes of parent djtNotes: ', djtChild.djtNotes)
      djtChild.waitingForParent = true

      // did we find somewhere to put it?
      let insertedDjt = false
      // if the orphaned child djtDate is <= filtered djt date && the 
      // filteredDjt is not a duplciate, insert
      for (const [djtIndex, filteredDjt] of filteredDjts.entries()) {
        
        if (djtChild.djtDate>=filteredDjt.djtDate && filteredDjt.isDuplicate!==true) {
          // console.log('THIS IS WHERE IT GETS INSERTED')
          filteredDjts.splice(djtIndex, 0, djtChild)
          insertedDjt = true
          break
        }
      }
      // if we made it here we have a straggler that didn't match any of the current
      if (insertedDjt===false) {
        const filteredDjtsInsertAt = filteredDjts.length+1
        filteredDjts.splice(filteredDjtsInsertAt, 0, djtChild)
      }
    })

    // console.log(' ---- filteredDjts: ', filteredDjts)
    // console.log(' ---- all djts: ', allDjtData)
    
    setLoadMoreDisabled(false)
    if (allDjtData?.nextToken===null || allDjtData?.length===0 || allDjtData?.length<searchLimit ) {
      setLoadMoreDisabled(true)
    }

    setDjtSearchResultsNextToken(allDjtData?.nextToken)
    let clearExistingResults = (optClearAll===true) ? true : false
    if (searchProps.nextToken!==null && clearExistingResults===false) {
      // setDjtSearchResults(searchResults => searchResults.concat(filteredDjts))

      // mergin up above before here
      // setSortedFilteredDjts(searchResults => searchResults.concat(filteredDjts))
      setSortedFilteredDjts(filteredDjts)
    } else {
      // setDjtSearchResults(filteredDjts)
      setSortedFilteredDjts(filteredDjts)
    }

    // check if everything loaded and return loaded status
    return(true)
  }

  // initial search, hard coded for recent submissions, show pending approvals
  useEffect(() => {
    if (currentDivisionId && createdAsEstimateStatusId) {
      setValue('searchDate', null)
      setValue('endDate', null)
      setValue('selectCustomer', null)
      // setLoadMoreDisabled(false)
      setLoadMoreDisabled(true)
      searchDjts({djtStatusId: createdAsEstimateStatusId, divisionId: currentDivisionId, djtClientId: '', djtDate: '', djtEndDate: '', limit: searchLimit, nextToken: null }, showAllResults)
        .catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDivisionId, createdAsEstimateStatusId])

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleLoadMore = () => {
    searchDjts({
      djtStatusId: searchStatusTypeId,
      divisionId: currentDivisionId, 
      djtClientId: clientIdSelected, 
      djtDate: djtStartDate, 
      djtEndDate: djtEndDate, 
      limit: searchLimit, 
      nextToken: djtSearchResultsNextToken 
    }, showAllResults).catch(console.error);
  }

  const handleShowAllToggle = async (showAll) => {
    // clear out the existing serach results
    // setSortedFilteredDjts(null)

    // console.log(' <<<<<< showAll in load more:', showAll)
    // toggle the data getting sent down to results
    setShowAllResults(showAll)
    // console.log('djtSearchProps before: ', djtSearchProps)
    djtSearchProps.nextToken = null
    setLoadMoreDisabled(true)
    // console.log('djtSearchProps after: ', djtSearchProps)
    searchDjts(djtSearchProps, showAll, true)
  }





  return (

    <Container as="form">

      <SearchDailyJobTicketsByClientByDate 
        control={control}
        register={register}
        endDateDisabled={endDateDisabled}
        onChange={(event, action) => onCustomerChanged(event, action)}
        startDate={startDate}
        startDateOnChange={(date) => {
          setStartDate(date);
          onStartDateChanged(formatDateFn(date), "selected");
        }}
        endDate={endDate}
        endDateOnChange={(date) => {
          setEndDate(date);
          onEndDateChanged(formatDateFn(date), "selected");
        }}
        clientSelected={clientSelected}
        djtStartDate={djtStartDate}
        djtEndDate={djtEndDate}
        clientIconOnClick={() => {
          setClientIdSelected(null);
          setClientSelected(null);
          const action = {action: "clear", removedValues: [clientSelected], name: "selectCustomer"};
          onCustomerChanged(null, action);
        }}
        startDateIconOnClick={() => {
          setStartDate(new Date())
          setDjtStartDate(null);
          onStartDateChanged(null);
        }}
        endDateIconOnClick={() => {
          setEndDate(new Date())
          setDjtEndDate(null);
          onEndDateChanged(null);
        }}
      />

      {(currentUserIsGlobalAdmin) && <ModuleBox>
        <Box w={'50%'}>
          <FormSelectSimple
            key='select_division'
            register={register}
            control={control}
            errors={errors}
            isRequired={true}
            fieldname="selectDivision"
            fieldlabel="Select Division"
            placeholder={'Select division or start typing'}
            optionsArray={divisions?.map(({ defaultQBClass, id }) => ({
              label: defaultQBClass,
              value: id,
            }))}
            onChange={(e) => handleDivisionChanged(e)}
            selectedoption={selectedDivision}
            />
          </Box>
      </ModuleBox>}

      <ResultsTableEstimatesByClientByDate
        djtSearchResults={sortedFilteredDjts}
        loadMoreOnClick={() => handleLoadMore()}
        loadMoreDisabled={loadMoreDisabled}
        handleRefresh={searchDjts}
        handleShowAllToggle={handleShowAllToggle}
        currentUserId={currentUserId}
        handleTabChange={handleTabChange}
        showSearchResutlsTable={showSearchResutlsTable}
        isReviewing={isReviewing}
        approvalRequiredCount={approvalRequiredCount}
        doShowAllSupervisorsSwitch={doShowAllSupervisorsSwitch}
        showDownloadPDFButton={showDownloadPDFButton}
        // handleGeneratePdf={handleGeneratePdf}
        handleGeneratePdf={generatePdf}
        currentTab={currentSelectedTab}
        register={register}
        errors={errors}
      />

      <Box mt='50px'></Box>

    </Container>


  );
}