import { listUnitDocumentTypesByGroup } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const fetchUnitDocumentTypesByGroup = async (groupName) => {
  if (!groupName) return;
  
  try {
    // console.warn('groupName: ', groupName)
    const response = await client.graphql({
      query: listUnitDocumentTypesByGroup,
      variables: {
        group: groupName,
        filter: {
          isActive: {
            eq: true
          }
        }
      }
    });
    
    // console.warn('response: ', response)
    const results = response.data.unitDocumentTypesByGroup.items
      .sort((a, b) => a.name > b.name ? 1 : -1);
    
    return results;
  } catch (err) {
    return ('error fetchUnitDocumentTypesByGroup: ', [err]);
  }
}

fetchUnitDocumentTypesByGroup.propTypes = {
  groupName: PropTypes.string.isRequired
};
