import { deleteNavMenuUser } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const removeNavMenuUser = async (props) => {
  if (!props) return;
  console.log('Removing nav menu user with props:', props);

  try {
    const response = await client.graphql({
      query: deleteNavMenuUser,
      variables: { input: props }
    });

    const result = response.data.deleteSpkNavMenuUserTbl.id;
    return result;
  } catch (err) {
    console.error('Error in removeNavMenuUser:', err);
    return 'Error: Unable to remove nav menu user';
  }
};

removeNavMenuUser.propTypes = {
  props: PropTypes.shape({
    id: PropTypes.string.isRequired, // The ID of the record to delete
  }).isRequired,
};