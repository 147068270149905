import { getStatusByStatusCode } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const getStatusIdByStatusCode = async (code) => {
  if (!code) return;

  try {
    const response = await client.graphql({
      query: getStatusByStatusCode,
      variables: { statusCode: code }
    });

    const results = response.data.djtStatusByStatusCode.items[0].id;
    
    return results;
  } catch (err) {
    return ('error fetchDJTsByStatusId: ', [err]);
  }
};

getStatusIdByStatusCode.propTypes = {
  code: PropTypes.string.isRequired,
};
