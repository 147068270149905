import { listDJTsByStatusId } from '../../../graphql/custom_queries';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const fetchDJTsByStatusId = async (statusId) => {
  if (!statusId) return 'Missing params';

  try {
    const response = await client.graphql({
      query: listDJTsByStatusId,
      variables: { currentStatusId: statusId }
    });

    const results = response.data.djtByCurrentStatusId.items
      .sort((a, b) => (a.djtDate > b.djtDate ? 1 : -1));

    return results;
  } catch (err) {
    return ('error fetchDJTsByStatusId: ', [err]);
  }
};

fetchDJTsByStatusId.propTypes = {
  statusId: PropTypes.string.isRequired,
};
