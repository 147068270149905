/* eslint-disable react/prop-types */
import { useRef } from 'react';
import {
  Box,
  Text,
  Flex,
  Spacer,
  Center,
  Input,
  Spinner,
} from "@chakra-ui/react";

// utility functions
import { todaysDateYMD } from '../functions/dateTime.jsx';

// structural components
import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { TextareaInput } from '../Form/Input/TextareaInput.jsx';
import { FormSelect } from '../Form/Select/FormSelect.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';
import { FormSelectCreatableSingle } from '../Form/Select/FormSelectCreatableSingle.jsx';
import { FormSelectAsyncCreatableSingle } from '../Form/Select/FormSelectAsyncCreatableSingle.jsx';
import { FormSelectCreatableClearableSingle } from '../Form/Select/FormSelectCreatableClearableSingle.jsx';
import { TextInputWithConfirmationModal } from '../Form/Input/TextInputWithConfirmationModal.jsx';
// decorative components
import { Divider } from '../Divider.jsx';

// button components
import { ButtonQuaternaryWithIcon } from '../Form/Button/ButtonQuaternaryWithIcon.jsx';
import { ButtonPrimaryPlain } from '../Form/Button/ButtonPrimaryPlain.jsx';

export const JobOverview = (props) => {

  const {
    register,
    errors,
    control,
    componentWidth,
    onClose,
    isDuplicate,
    isEdit,
    isChild,
    isEstimate,
    clientContactDisabled,
    handleClientContactEmailChanged,
    handleDjtPoChanged,
    setTypedDjtDate,
    handleJobDescriptionChange,
    handlePhoneEmailChanged,
    companySelectedOption,
    clientCompanies=[],
    handleClientChanged,
    clientPOs,
    clientPoSelectedOption,
    clientContractSelectedOption,
    availableClientContracts,
    contactClientSelectRef,
    clientSelectRef,
    clientContacts,
    handleClientContactChanged,
    clientContactSelected,
    fileInputRef,
    handleFileInputChange,
    onContractUploadButtonClick,
    handleClientContractChanged,
    showFileUploadSpinner,
    isUserMobile,
  } = props

  const clientFocusTarget = useRef(null);
  const clientFocusTargetAfter = useRef(null);
  const contactFocusTarget = useRef(null);
  const contactFocusTargetAfter = useRef(null);

  // do clientPOs
  // console.log(' isChild: ', isChild)

  // if isChild = true then disabled
  // if nothing selected for client disabled = true
  // the only way to enable it is to select a client and this not be a child/duplicate
  // if it's a child with an existing po it needs to be text input with the hidden value set
  let isPODisabled = (!companySelectedOption) ? true : false
  isPODisabled = (isChild) ? true : isPODisabled

  return(
    <ModuleBoxDrawer>
        <Flex>
          <Box>
            <Text as="span" textStyle='heading-1'>Job Overview</Text>
          </Box>
          <Spacer />
          <Box>
            <ButtonQuaternaryWithIcon 
              name='closeDrawer'
              iconsize='26px'
              leftIcon='close'
              value='Cancel'
              onClick={onClose}
            />
          </Box>
        </Flex>

        {/* Job date, Job PO */}
        {/* <div id='target' ref={clientFocusTarget} /> */}
        
        <Flex mb={'25px'}>
          <Box>
            <TextInput
              register={register}
              errors={errors}
              fieldname="djtDate"
              fieldlabel="Job Date"
              prettyname="Job Date"
              // onChange={handleDjtDateChanged}
              onChange={event => setTypedDjtDate(event.target.value)}
              isRequired={true} 
              // isReadOnly={false}
              isReadOnly={isEdit}
              // isDisabled={isEdit}
              fieldtype='date'
              defaultValue={todaysDateYMD()}
            />
          </Box>
          <Spacer />
          <Box w={componentWidth}>
            {/* Parent DJT will have option to attach bid/estime. Children of that DJT will be able to click to view bid/estimate. */}
            {/* <Flex>
              <Center w='220px' > */}
                {(clientPOs && clientPOs?.length>0) ?                   
                  <FormSelectCreatableClearableSingle
                    register={register}
                    errors={errors}
                    control={control}
                    value={clientPoSelectedOption||''}
                    defaultValue={clientPoSelectedOption||''}
                    optionsArray={
                      clientPOs?.map((options) => ({ 
                        label: options.po, value: options.id
                      }))
                    }
                    onChange={(e, a) => {handleDjtPoChanged(e, a)}}
                    fieldname="clientPO"
                    fieldlabel="PO #"
                    placeholder="Select or start typing to search or create new"
                    isDisable={isPODisabled}
                    /> 
                  : 
                    <TextInput
                      register={register}
                      errors={errors}
                      fieldname="clientPO"
                      fieldlabel="PO #"
                      prettyname="PO #"
                      placeholder="Enter Client PO #"
                      onChange={handleDjtPoChanged}
                      isDisabled={isPODisabled}
                    />
                  }
                {/* {(clientPOs && clientPOs?.length>0) ?                   
                  (!isPODisabled) ? 
                  <FormSelectCreatableClearableSingle
                    register={register}
                    errors={errors}
                    control={control}
                    value={clientPoSelectedOption||''}
                    defaultValue={clientPoSelectedOption||''}
                    optionsArray={
                      clientPOs?.map((options) => ({ 
                        label: options.po, value: options.id
                      }))
                    }
                    onChange={(e, a) => {handleDjtPoChanged(e, a)}}
                    fieldname="clientPO"
                    fieldlabel="PO #"
                    placeholder="Select or Create new PO"/> 
                  : 
                    <FormSelectCreatableClearableSingle
                      register={register}
                      errors={errors}
                      control={control}
                      value={clientPoSelectedOption||''}
                      defaultValue={clientPoSelectedOption||''}
                      optionsArray={
                        clientPOs?.map((options) => ({ 
                          label: options.po, value: options.id
                        }))
                      }
                      onChange={(e, a) => {handleDjtPoChanged(e, a)}}
                      fieldname="clientPO"
                      fieldlabel="PO #"
                      placeholder="Select or Create new PO"
                      isDisabled={true}/>
                  : 
                    <TextInput
                      register={register}
                      errors={errors}
                      fieldname="clientPO"
                      fieldlabel="PO #"
                      prettyname="PO #"
                      placeholder="Enter Client PO #"
                      onChange={handleDjtPoChanged}
                      isDisabled={isPODisabled}
                    />
                  } */}
              {/* </Center>
              <Spacer />
              <Box w='130px' > */}
              {/* THIS SHOULD ONLY SHOW UP IF WE ARE A PARENT DJT 
                  IF THIS IS A CHILD AND THE PARENT HAS A BID ATTACHED
              */}
                {/* <ButtonDenaryPlain
                  width={'130px'}
                  mt='28px'
                  type="button"
                  name='attach-bid'
                  value='Attach Estimate'
                />
              </Box>
            </Flex> */}
          </Box>
        </Flex>

        {/* Job description, Job location */}
        <Flex mb={'25px'}>
          <Box w={componentWidth}>
            <TextareaInput
              register={register}
              errors={errors}
              rules={{ required: "Required" }}
              fieldname="djtNotes"
              fieldlabel="Job Description"
              prettyname="Job description"
              placeholder="Enter Job Description"
              // onChange={handleJobDescriptionChange}
              onBlur={handleJobDescriptionChange}
              isRequired
            />
          </Box>
          <Spacer />
          <Box w={componentWidth}>
            <TextInput
              register={register}
              errors={errors}
              fieldname="contactLocation"
              fieldlabel="Job Location"
              prettyname="Job Location"
              // onChange={handleCompanyDetailsChanged}
              placeholder="Enter Job Location"
            />
          </Box>
        </Flex>
        
        <Divider mb='25px'/>
        <TextInput
          register={register}
          errors={errors}
          control={control}
          fieldname="originalContactPhone"
          fieldtype="hidden"
        />
        <TextInput
          register={register}
          errors={errors}
          control={control}
          fieldname="originalContactEmail"
          fieldtype="hidden"
        />
        {/* Clients dropdown, Contact email */}
        {/* Clients dropdown should only by mutable if this is a new DJT, not a duplicate */}
        <div id='target' ref={clientFocusTargetAfter} />
        <Flex mb={'25px'}>
          <Box w={componentWidth}>
            {(isDuplicate || isEdit) ? 
              <FormSelect
                register={register}
                errors={errors}
                control={control}
                // defaultValue={companySelectedOption}
                // value={companySelectedOption||''}
                selectedoption={companySelectedOption}
                // defaultValue={companySelectedOption||''}
                // rules={{ required: "Client required" }}
                optionsArray={
                  clientCompanies.map((options) => ({ 
                    label: options.label, value: options.value
                  }))
                }
                isDisabled={true}
                onChange={handleClientChanged}
                onMenuOpen={() => { (isUserMobile) && clientFocusTarget.current.scrollIntoView({ behavior: "instant" }) }}
                fieldname="selectCompany"
                fieldlabel="Client"
                // placeholder="Select or Create new client"
              /> 
            : 
              <>
              {/* <FormSelectCreatableSingle
                register={register}
                errors={errors}
                control={control}
                reference={clientSelectRef}
                rules={{ required: "Client required" }}
                value={companySelectedOption||''}
                defaultValue={companySelectedOption||''}
                optionsArray={
                  clientCompanies.data?.map((options) => ({ 
                    label: options.label, value: options.value
                  }))
                }
                onChange={(e, a) => {
                  (isUserMobile) && clientFocusTargetAfter.current.scrollIntoView({ behavior: "instant" })
                  handleClientChanged(e, a)
                }}
                // selectedoption={(clientCompanies?.data?.length===1) ? clientCompanies?.data[0] : null}
                // isDisabled={isClientSelectDisabled}
                onMenuOpen={() => { (isUserMobile) && clientFocusTarget.current.scrollIntoView({ behavior: "instant" }) }}
                fieldname="selectCompany"
                fieldlabel="Client"
                // placeholder="Select or Create new client"
                placeholder="Select or start typing to create new"
                isClearable
              /> */}

              {/* This is a searchable/creatable select that defaults to showing only 
              fresh data. As soon as they start typing, it loads stale data as well to make it searchable. */}
              {(isEstimate) ? <FormSelectAsyncCreatableSingle
                register={register}
                errors={errors}
                control={control}
                reference={clientSelectRef}
                rules={{ required: "Client required" }}
                // value={companySelectedOption||''}
                // defaultValue={companySelectedOption||''}
                defaultOptionsArray={
                  clientCompanies.filter(client => client.staleAfterDate>=todaysDateYMD()).map((options) => ({ 
                    label: options.label, value: options.value
                  }))
                }
                optionsArray={
                  clientCompanies.map((options) => ({ 
                    label: options.label, value: options.value
                  }))
                }
                onChange={(e, a) => {
                  (isUserMobile) && clientFocusTargetAfter.current.scrollIntoView({ behavior: "instant" })
                  handleClientChanged(e, a)
                }}
                // selectedoption={(clientCompanies?.data?.length===1) ? clientCompanies?.data[0] : null}
                // isDisabled={isClientSelectDisabled}
                onMenuOpen={() => { (isUserMobile) && clientFocusTarget.current.scrollIntoView({ behavior: "instant" }) }}
                fieldname="selectCompany"
                fieldlabel="Client"
                // placeholder="Select or Create new client"
                placeholder="Select or start typing to search or create new"
                isClearable
              /> : <FormSelectSimple
                register={register}
                errors={errors}
                control={control}
                reference={clientSelectRef}
                rules={{ required: "Client required" }}
                // value={companySelectedOption||''}
                // defaultValue={companySelectedOption||''}
                defaultOptionsArray={
                  clientCompanies.filter(client => client.staleAfterDate>=todaysDateYMD()).map((options) => ({ 
                    label: options.label, value: options.value
                  }))
                }
                optionsArray={
                  clientCompanies.map((options) => ({ 
                    label: options.label, value: options.value
                  }))
                }
                onChange={(e, a) => {
                  (isUserMobile) && clientFocusTargetAfter.current.scrollIntoView({ behavior: "instant" })
                  handleClientChanged(e, a)
                }}
                // selectedoption={(clientCompanies?.data?.length===1) ? clientCompanies?.data[0] : null}
                // isDisabled={isClientSelectDisabled}
                onMenuOpen={() => { (isUserMobile) && clientFocusTarget.current.scrollIntoView({ behavior: "instant" }) }}
                fieldname="selectCompany"
                fieldlabel="Client"
                // placeholder="Select or Create new client"
                placeholder="Select or start typing to search"
              />}
              </>
              
              }
              <div id='target' ref={clientFocusTarget} />
          </Box>
          <Spacer />
          <Box w={componentWidth}>
            <Box><TextInputWithConfirmationModal
              register={register}
              errors={errors}
              fieldname="contactEmail"
              fieldlabel="Email"
              fieldtype="email"
              prettyname="Email"
              placeholder="Enter Email"
              onBlur={ () => {
                handleClientContactEmailChanged()
              }}
              isReadOnly={false}
              isDisabled={clientContactDisabled}
              onMouseUp={(e) => {
                window.scrollTo({
                  top: window.scrollY + 60,
                  behavior: 'smooth'
                });
                handlePhoneEmailChanged(e)
              }}
              onChange={(e) => {
                window.scrollTo({
                  top: window.scrollY + 60,
                  behavior: 'smooth'
                });
                handlePhoneEmailChanged(e)
              }}
            /></Box>
          </Box>
        </Flex>

        {/* Client contacts dropdown, Contact phone */}
        {/* https://codesandbox.io/s/q4npoqp90w?file=/src/index.js */}
        <div id='target' ref={contactFocusTargetAfter} />
        <Flex mb={'25px'}>
          <Box w={componentWidth}>
            <FormSelectCreatableSingle
              register={register}
              errors={errors}
              control={control}
              reference={contactClientSelectRef}
              rules={{ required: "Client contact required" }}
              optionsArray={
                clientContacts.map((options) => ({ 
                  label: options.name, value: options.id
                }))
              }
              value={clientContactSelected||''}
              onChange={(e, a) => {
                handleClientContactChanged(e, a)
              }}
              fieldname="selectContact"
              fieldlabel="Contact"
              placeholder="Select or start typing to search or create new"
              isClearable
              isDisabled={clientContactDisabled}
            />
            <div id='target' ref={contactFocusTarget} />
            <TextInput
              fieldname="contactId"
              fieldtype='hidden'
              register={register}
              errors={errors}
            />
          </Box>
          <Spacer />
          <Box w={componentWidth}>
            <TextInputWithConfirmationModal
              register={register}
              errors={errors}
              fieldname="contactPhone"
              fieldlabel="Phone"
              fieldtype="tel"
              prettyname="Phone"
              placeholder="Enter Phone"
              isRequired={true} 
              isReadOnly={false}
              maximLength="16"
              minimLength="16"
              isDisabled={clientContactDisabled}
              onMouseUp={(e) => {
                window.scrollTo({
                  top: window.scrollY + 60,
                  behavior: 'smooth'
                });
                handlePhoneEmailChanged(e)
              }}
              // onBlur={(e) => handlePhoneEmailChanged(e)}
              onBlur={(e) => {
                if (e.target.value.length<16) {
                  window.scrollTo({
                    top: window.scrollY + 60,
                    behavior: 'smooth'
                  });
                  handlePhoneEmailChanged(e)
                }
              }}
              onFocus={(e) => {
                window.scrollTo({
                  top: window.scrollY + 60,
                  behavior: 'smooth'
                });
                if (e.target.value.length<16) handlePhoneEmailChanged(e)
                // console.log('onFocus: ', e.target.value)
              }}
              // onKeyUp={(e) => {
              //   if (e.target.value.length<16) {
              //     handlePhoneEmailChanged(e)
              //   }
              //   console.log('onKeyUp: ', e.target.value.length)
              // }}
            />
          </Box>
        </Flex>
        
        {(!isChild && companySelectedOption) && 
        <><Divider mb='25px'/>
          <Flex mb={'25px'}>
          <Box w={componentWidth}>
            <Input 
              // register={register}
              // errors={errors}
              ref={fileInputRef} 
              type="file" 
              id="input-file-upload" 
              multiple={false} 
              // accept="image/jpeg, image/jpg, image/png, application/pdf"
              accept=".doc, .docx, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={handleFileInputChange} 
            />
            <ButtonPrimaryPlain 
              width={'265px'}
              onClick={onContractUploadButtonClick}
              type="button"
              // name='attach-bid'
              name='attach-contract'
              value='Attach New or Revised Contract'
            />
          </Box>
          <Spacer/>
          <Box w={componentWidth}>
            {(showFileUploadSpinner) ? <Center><Spinner color='var(--progress-bar-primary)' /></Center> : 
            <FormSelectSimple
              register={register}
              control={control}
              errors={errors}
              optionsArray={
                availableClientContracts?.map((options) => ({ 
                  label: `${options.name} - ${options.utcDateTime}`, value: options.id
                }))
              }
              // onChange={(e, a) => {handleClientContractChanged(e, a)}}
              onChange={handleClientContractChanged}
              defaultValue={clientContractSelectedOption}
              selectedoption={clientContractSelectedOption}
              // value={jobClassSelected[index]}
              fieldname="selectClientContract"
              id="selectClientContract"
              fieldlabel="Attach Exsiting Contract"
              placeholder={(availableClientContracts?.length>0) ? 'Select contract' : 'No available contracts'}
              issearchable={(!isUserMobile)}
              isclearable={true}
              isDisabled = {(availableClientContracts?.length===0) ? true : false}
              // isDisabled={(availableClientContracts?.length>0) ? false : true}
            />}
          </Box>
        </Flex></>}
      </ModuleBoxDrawer>
  )

}
  
  