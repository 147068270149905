import { generateClient } from 'aws-amplify/api';
import PropTypes from 'prop-types';
import { createDJTUnitOperator } from '../../../graphql/custom_mutations';

const client = generateClient();

export const addDJTUnitOperator = async (props) => {
  if (props) {
    try {
      console.log('addDJTUnitOperator: ', props);

      const response = await client.graphql({
        query: createDJTUnitOperator,
        variables: { input: props },
      });

      // console.log('addDJTUnitOperator response: ', response)
      const results = response.data.createSpkDJTOperatorTbl.id;
      // console.log('addDJTUnitOperator results: ', results)
      return results;
    } catch (err) {
      return 'error addDJTUnitOperator: ' + err;
    }
  } else {
    return null;
  }
};

addDJTUnitOperator.propTypes = {
  props: PropTypes.object.isRequired,
};
