import PropTypes from 'prop-types';
import { Button } from '@chakra-ui/react';
import { AvailableIcons } from '../../Icons/AvailableIcons';
import { useClipboard } from '@chakra-ui/react';

export const ButtonCopyClipboardWithIcon = (props) => {
  const {
    name,
    ref,
    text,
    iconsize = '24px',
    isDisabled = false,
    mx = '0px',
    my = '0px',
  } = props;

  const { hasCopied, onCopy } = useClipboard(text);

  return (
    <Button 
      {...props}
      name={name}
      ref={ref}
      variant='copyClipboard' 
      leftIcon={<AvailableIcons boxSize={iconsize} iconRef={'clipboard-copy'} />}
      onClick={onCopy}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
    >
      {hasCopied ? "Copied!" : "Copy to clipboard"}
    </Button>
  );
};

// Prop validation
ButtonCopyClipboardWithIcon.propTypes = {
  name: PropTypes.string, // Name is expected to be a string
  text: PropTypes.string.isRequired, // Text is expected to be a string and is required
  ref: PropTypes.oneOfType([ // ref can be a function or an object created by React.createRef()
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  iconsize: PropTypes.string, // Icon size should be a string like "20px"
  onClick: PropTypes.func, // onClick is a function
  isDisabled: PropTypes.bool, // isDisabled is a boolean
  mx: PropTypes.oneOfType([ // mx can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([ // my can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
};
