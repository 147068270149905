import { createDJTPrimaryUnitBillableRate } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const addDJTBillableRate = async (props) => {
  if (!props) return;

  try {
    console.log('addDJTBillableRate props: ', props);

    const gqlParams = {
      input: props
    };

    const response = await client.graphql({
      query: createDJTPrimaryUnitBillableRate,
      variables: gqlParams
    });

    console.log('addDJTBillableRate response: ', response);
    const results = response.data.createSpkDJTPrimaryUnitBillableRateTbl.id;
    console.log('addDJTBillableRate results: ', results);

    return results;
  } catch (err) {
    return ('error addDJTBillableRate: ', [err]);
  }
};

addDJTBillableRate.propTypes = {
  props: PropTypes.object.isRequired,
};
