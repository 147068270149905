import { fetchAuthSession } from 'aws-amplify/auth';
const endpointUrl = 'https://ugczaq3r89.execute-api.us-east-1.amazonaws.com/dev/employeesextended';

export const fetchTimeCoExtendedEmployees = async () => {
  try {
    // Refresh the session to ensure it's current and to get the latest authentication tokens
    // const session = await Auth.currentSession();
    const session = await fetchAuthSession();
    const authToken = `Bearer ${session.tokens.idToken}`;

    const response = await fetch(endpointUrl, {
      method: 'POST',
      headers: { 
        'Content-Type':'application/json',
        'Authorization':authToken,
      }
    })

    console.info('<<<<< RESPONSE >>>>>', response);

    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }

    const data = await response.json();
    console.info('***** data: ', data); // Log the parsed data

    // // Assuming data?.body contains the suggested addresses
    return JSON.parse(data?.body);
    
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
    throw error; // Rethrow to let the caller handle it
  }
};
