import {
  Box
} from "@chakra-ui/react";

import PropTypes from "prop-types";

export const  ModuleBox = (props) => {
  const {
    border,
    children,
  } = props;

  return (
    <Box 
      border={border} //used only for debugging placement
      borderColor={{ 
        base: 'red', 
        sm: 'blue', 
        ipadv: 'yellow',
        md: 'green',  // iPad 7th gen
        lg: 'purple', 
        lg2: 'cyan',
        xl: 'orange', 
        '2xl': 'pink'
      }}
      w={{ 
        base: '100px', //< 400 screen width
        sm: '360px', // total width of phone screen
        ipadv: '810px', // ipad vertical
        md: '810px', // ipad 7th gen target - 810 when with nav
        lg: '1086px', // macbook air and ipad - nav is 322, plus 25 for pad, then what is left is 347, 1366-347=1019
        lg2: '1350px', // bigger
        xl: '1400px',
        '2xl': '1920px'
      }}
      borderRadius='6px'
      p='25px'
      mt='25px'
      ml='25px'
      bg='var(--dark-module-bg)'>
        {children}
    </Box>
  )
}

ModuleBox.propTypes = {
  border: PropTypes.string,
  children: PropTypes.node.isRequired,
};

// breakpoints: {
  // sm: '600px',
  // md: '1080px', // iPad 7th gen
  // lg: '1440px', // macbook pro 15
  // xl: '1920px', // HD
  // '2xl': '3840px',  //4K
// },