import PropTypes from 'prop-types';
import { IconButton } from '@chakra-ui/react';
import { AvailableIcons } from '../../Icons/AvailableIcons';

export const ButtonSenaryWithIconOnly = (props) => {
  const {
    name = '',
    ref,
    icon,
    iconsize = '20px',
    onClick,
    isDisabled=false,
    mx='',
    my='',
    w = '40px',
  } = props;

  return (
    <IconButton 
      {...props}
      w={w}
      name={name}
      ref={ref}
      variant='withIconOnlySenary' 
      icon={<AvailableIcons boxSize={iconsize} iconRef={icon} />}
      onClick={onClick}
      isDisabled={isDisabled}
      mx={mx}
      my={my}
    />
  );
};

// Prop validation
ButtonSenaryWithIconOnly.propTypes = {
  name: PropTypes.string, // Name is expected to be a string
  ref: PropTypes.oneOfType([ // ref can be a function or an object created by React.createRef()
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  icon: PropTypes.string.isRequired, // Icon is required and should be a string
  iconsize: PropTypes.string, // Icon size should be a string like "20px"
  value: PropTypes.oneOfType([ // Value can be a string or a number, though it isn't used in this component
    PropTypes.string,
    PropTypes.number,
  ]),
  onClick: PropTypes.func, // onClick is a function
  isDisabled: PropTypes.bool, // isDisabled is a boolean
  mx: PropTypes.oneOfType([ // mx can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  my: PropTypes.oneOfType([ // my can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
  w: PropTypes.oneOfType([ // w (width) can be a string or a number
    PropTypes.string,
    PropTypes.number,
  ]),
};

