import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Container,
  Box,
  Text,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import { UploadUnitDocuments } from './UploadUnitDocuments.jsx';

import { AvailableIcons } from '../Icons/AvailableIcons.jsx';

// amplify storage
// import { Storage } from 'aws-amplify';


// modals
import { CenteredSubmitWithProgressModal } from '../Modal/CenteredSubmitWithProgressModal.jsx';
import { delay } from '../functions/generic.jsx';

// graphQl
import { fetchPrimaryUnitById } from '../graphqlCompnents/Unit/fetchPrimaryUnitById.jsx';
import { fetchUnitDocumentsByUnitId } from '../graphqlCompnents/Unit/fetchUnitDocumentsByUnitId.jsx';

// structural components
import { ModuleBoxDrawer } from '../Structural/ModuleBoxDrawer.jsx';

// form components
import { TextInput } from '../Form/Input/TextInput.jsx';
import { FormSelectSimple } from '../Form/Select/FormSelectSimple.jsx';

export const UnitDocuments = ({ unitId, onClose }) => {

  // --- REACT-HOOK-FORM ---
  const { 
    control, 
    register, 
    handleSubmit, 
    setValue, 
    formState: { 
      errors, 
    }} = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onBlur',
      defaultValues: {},
  });

  const onError = (errors, e) => console.log(errors, e);

  const { isOpen: isSubmitModalOpen , onOpen: onSubmitModalOpen, onClose: onSubmitModalClose } = useDisclosure()
  const [ submitModalContent, setSubmitModalContent ] = useState(null)
  const [ submitModalCloseDisabled, setSubmitModalCloseDisabled ] = useState(true)
  const [ submissionProgress, setSubmissionProgress ] = useState(0)
  async function handleSubmitModalClosed() {
    onSubmitModalClose()
    await delay(500)
    onClose()
  }

  const [ refreshIndex, setRefreshIndex ] = useState(0);
  const handleRefresh = () => {
    setRefreshIndex(prev => prev + 1); // Increment to trigger useEffect
  };

  const [ unitDocuments, setUnitDocuments ] = useState(null)
  useEffect(() => {
    const fetchUnitDocuments = async () => {
      const documents = await fetchUnitDocumentsByUnitId(unitId)
      setUnitDocuments(documents)
      // console.info('documents: ', documents)
    }
    if (unitId) { fetchUnitDocuments() }
  },[unitId, refreshIndex])

  const getDocumentSignedUrl = async (uploadedDocument) => {
    const signedUrl = await Storage.get(uploadedDocument.key, {
      level: "protected",
      identityId: uploadedDocument.identityId,
    });
    // console.log('signedUrl: ', signedUrl)
    return signedUrl;
  }

  let toolbarDisabled = false;

  const [groupedDocuments, setGroupedDocuments] = useState({});
  const [ groupedOptions, setGroupedOptions ] = useState([]) 
  const [ selectedGroupedOption, setSelectedGroupedOption ] = useState({
    key: 'all',
    value: '',
    label: 'All',
  })

  const [selectedName, setSelectedName] = useState('');

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchSignedUrlsAndGroupDocuments = async () => {
      const essentialDocuments = unitDocuments.filter((unit) => unit?.unitDocumentType?.group === 'essential');
      const grouped = await essentialDocuments.reduce(async (accPromise, doc) => {
        const acc = await accPromise;
        const { name } = doc.unitDocumentType;

        if (!acc[name]) {
          acc[name] = [];
        }

        const signedUrl = await getDocumentSignedUrl(doc.uploadedDocument);
        acc[name].push({ ...doc, signedUrl });

        return acc;
      }, Promise.resolve({}));

      setGroupedDocuments(grouped);

      const allOption = {
        key: 'all',
        value: '',
        label: 'All',
      };
      
      const options = [allOption, ...Object.keys(grouped).map(name => ({
        key: name, 
        value: name, 
        label: name
      }))
    ];
      setGroupedOptions(options)
      setSelectedGroupedOption(allOption)
    };

    if (unitDocuments) fetchSignedUrlsAndGroupDocuments();
  }, [unitDocuments]);

  const handleNameChange = (event) => {
    setSelectedName(event.value);
    setSelectedGroupedOption(event);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredDocuments = Object.entries(groupedDocuments).reduce((acc, [name, docs]) => {
    if (!selectedName || name === selectedName) {
      const filteredDocs = docs.filter(doc => doc.notes.toLowerCase().includes(searchTerm));
      if (filteredDocs.length > 0) {
        acc[name] = filteredDocs;
      }
    }
    return acc;
  }, {});

  const downloadFile = async (url, filename) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  const [ unitCode, setUnitCode ] = useState(null)

  useEffect(() => {
    if (unitId) {
      const fetchUnit = async () => {
        const unit = await fetchPrimaryUnitById(unitId)
        if (unit) {
          setValue('code', unit.code)
          setUnitCode(unit.code)
        }
      }

      fetchUnit()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[unitId, refreshIndex])

  const handleKeyDown = (event) => {
    // Check if the key pressed is 'Enter'
    if (event.key === 'Enter' && event.target.type !== 'textarea') {
      event.preventDefault();  // Prevent form submission
    }
  };

  const formSubmit = async (values, event) => {
    // reference AddTeamMember.js for Cognito Add User functionality
    event.preventDefault();
    
    onSubmitModalOpen()
    setSubmitModalContent('Updating unit...')
    setSubmitModalCloseDisabled(true)
    setSubmissionProgress(10)

    // const unitParams = {
    //   id: unitId,
    //   unitSmallImageId: defaultImageId,
    //   unitMediumImageId: defaultImageId,
    // }

    // console.warn(' ----- sending unitParams: ', unitParams)
    // const response = await updateUnit(unitParams)
    // console.warn(' ----- updateUnit response: ', response)

    setSubmitModalContent('Unit has been updated.')
    setSubmissionProgress(100)
    setSubmitModalCloseDisabled(false)

  }

  return (
    <>

    <Container as="form" maxW="1600" mb={12} onKeyDown={handleKeyDown} onSubmit={handleSubmit(formSubmit, onError)}>

      <ModuleBoxDrawer mt='25px'>
        <UploadUnitDocuments 
          unitid={unitId}
          unitname={unitCode}
          refresh={handleRefresh}
          onClose={onClose}
        />
      </ModuleBoxDrawer>

      <ModuleBoxDrawer mt='25px'>

        <Box w={'40%'} my={'25px'}>
          <TextInput
            register={register}
            errors={errors}
            fieldname="searchInput"
            fieldlabel="Search"
            prettyname="Search"
            placeholder="Enter search term"
            onChange={handleSearchChange}
          />
        </Box>

        <Box w={'40%'} my={'25px'}>
          <FormSelectSimple
            register={register}
            control={control}
            errors={errors}
            fieldname="documentTypeSelect"
            fieldlabel="Select Document Type"
            placeholder="Select document type"
            optionsArray={groupedOptions||''}
            onChange={(e) => handleNameChange(e)}
            selectedoption={selectedGroupedOption||''}
          />
        </Box>

        <TableContainer>
          <Table variant={'compact'} size={'compact'}>
            <Thead>
              <Tr>
                <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>DATE ADDED</Text></Th>
                <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>NOTES</Text></Th>
                <Th><Text as="span" pl='10px' className='dark-sfpro-heading-4'>ACTIONS</Text></Th>
              </Tr>
            </Thead>
            <Tbody>

            {Object.entries(filteredDocuments).map(([name, docs], typeIndex) => ( 
              <React.Fragment key={`fragment_doc_type_${typeIndex}`}>
              <Tr key={`tr_doc_type_${typeIndex}`}>
                <Td py='10px' colSpan={3} w='100%'>
                  <Text pl='10px' as="span" textStyle='heading-3'>Document Type: {name}</Text>
                </Td>
              </Tr>
              {docs.map((doc, docIndex) => (
                <Tr key={`tr_doc_row_${typeIndex}_${docIndex}`}>
                  <Td w='30%'>
                    <Text pl='10px' as="span" className='dark-sfpro-text-1'>{doc.createdAt.split('T')[0]}</Text>
                  </Td>
                  <Td w='55%'>
                    <Text as="span" textStyle='text-7'>{doc.notes}</Text>
                  </Td>
                  <Td w='15%'>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label='Options'
                        icon={<AvailableIcons boxSize={'22px'} iconRef={'more'} />} 
                        variant='withIconOnlySenary' 
                        backgroundColor={'var(--dark-main-background)'}
                        w={'60px'}
                        iconsize='18px'
                        height='28px'
                        mb='3px'
                        isDisabled={toolbarDisabled}
                      />
                      <MenuList>
                        <MenuItem 
                          onClick={() => window.open(doc.signedUrl, '_blank', 'noopener,noreferrer')}
                          icon={<AvailableIcons boxSize={'22px'} iconRef={'view'} />} >
                          View
                        </MenuItem>

                        <MenuItem 
                          onClick={() => downloadFile(doc.signedUrl, doc.uploadedDocument.key)}
                          icon={<AvailableIcons boxSize={'22px'} iconRef={'download'} />} >
                          Download
                        </MenuItem>
            
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </React.Fragment>
          ))}

          </Tbody>
        </Table>
      </TableContainer>
    </ModuleBoxDrawer>


    </Container>
    <CenteredSubmitWithProgressModal
      isModalOpen={isSubmitModalOpen}
      onModalClose={onSubmitModalClose}
      content={submitModalContent}
      closeButtonDisabled={submitModalCloseDisabled}
      handleSubmitModalClosed={handleSubmitModalClosed}
      progress={submissionProgress}
      successMessage={'Unit has been updated.'}
      closeButtonVisible={true}
    />
    </>
  )
}

UnitDocuments.propTypes = {
  unitId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
