import { updateCapitalOneCreditCardTransactionReceipt } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const updateCreditCardTransactionReceipt = async (props) => {
  if (!props) return;

  try {
    const gqlParams = {
      input: props,
    };

    console.log('gqlParams: ', gqlParams);

    const response = await client.graphql({
      query: updateCapitalOneCreditCardTransactionReceipt,
      variables: gqlParams,
    });

    console.log('updateCapitalOneCreditCardTransactionReceipt response: ', response);

    const results = response.data.updateSpkCapitalOneCreditCardTransactionReceiptTbl.id;

    console.log('updateCapitalOneCreditCardTransactionReceipt results: ', results);
    return results;
  } catch (err) {
    return 'error updateCreditCardTransactionReceipt: ' + err;
  }
};

updateCreditCardTransactionReceipt.propTypes = {
  props: PropTypes.shape({
    allSort: PropTypes.string,
    id: PropTypes.string.isRequired,
    matchStatus: PropTypes.string,
    qbTransactionEntityId: PropTypes.string,
    isReceiptUploaded: PropTypes.bool,
    quickbooksExpenseId: PropTypes.string,
    clientId: PropTypes.string,
    expenseCategoryId: PropTypes.string,
    unitId: PropTypes.string,
    addedNotes: PropTypes.string,
    hasIssue: PropTypes.bool,
    issue: PropTypes.string,
  }).isRequired,
};
