import { defineStyleConfig } from '@chakra-ui/react'

const Container = defineStyleConfig({
  baseStyle: {
    // border: '1px solid blue',
    // borderRadius: '20px',
    // borderRadius: '6px',
    // textAlign: 'left',
    // padding: '10px 20px 10px 20px',
    // marginTop: '25px',
    // marginLeft: '25px',
    // bg: '#1e1e1f'
  },
  // Two sizes: sm and md
  sizes: {

  },
  // Two variants: outline and solid
  variants: {
    module: {
      // border: '1px solid blue',
      // borderRadius: '20px',
      borderRadius: '6px',
      // textAlign: 'left',
      padding: '10px 20px 10px 20px',
      marginTop: '25px',
      marginLeft: '25px',
      bg: '#1e1e1f'
    },
    primary: {
      // bg: '#1e1e1f'
      margin: '0px',
      padding: '0px',
    },
    stretchtest: {
      // minWidth: '700px',
      // maxWidth: '1400px',
      // width: '100%',
      border: '1px solid green',
      borderRadius: '6px',
      // textAlign: 'left',
      padding: '10px 20px 10px 20px',
      marginTop: '25px',
      marginLeft: '25px',
      bg: '#1e1e1f'
    },
    searchBarContainer: {
      border: '2px solid pink',
      // marginTop: '25px',
      // marginLeft: '25px',

      // flexDirection: 'row',
      // alignItems: 'start',
      // alignItems: 'flex-start',
      


      // justifyContent: 'flex-start',

      minWidth: '700px',
      maxWidth: '1400px',

      // textAlign: 'left',
      // padding: '10px 20px 10px 20px',
      // marginTop: '25px',
      // marginLeft: '25px',
      // bg: '#1e1e1f'
    },
    searchbar: {
      // minWidth: '700px',
      // maxWidth: '1400px',
      // width: '720px',
      height: '90px'

    }
  },
  // The default size and variant values
  defaultProps: {
    variant: 'primary',
  },
})

export default Container