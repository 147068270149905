// TimeCardModule.jsx
import { Box, VStack, Text, Divider, Flex, Spinner } from "@chakra-ui/react";
import { spellOutDate } from '../../functions/dateTime.jsx';
// import { PunchDetails } from "./PunchDetails.jsx";
import PropTypes from 'prop-types';

export const TimeCardModule = ({ 
  totalHoursWorked, 
  currentWeekHours, 
  mostRecentPunchIn, 
  // punchEntries 
}) => {
  const punchDaySpelledOut = spellOutDate(mostRecentPunchIn?.date, 'apple-ny')
  return (
    <Box
      // py={'25px'}
      paddingBottom={'10px'}
      paddingTop={'25px'}
      // px={'10px'}
      bg="black"
      color="white"
      width="100%"
      maxWidth={["100%", null, "400px"]}
    >
      <Flex 
        justifyContent="space-between" 
        alignItems="flex-start" 
        // paddingBottom={'25px'}
      >
        {/* Last Clock-In */}
        <VStack spacing={0} align="center">
          <Text paddingBottom={'5px'}  textStyle="sfprotext-semi-bold-white-15px">Clock-In</Text>
          {mostRecentPunchIn?.time && punchDaySpelledOut ? (
            <>
              <Text paddingBottom="5px" textStyle="sfprotext-semi-bold-gold-24px" textAlign="center">
                {mostRecentPunchIn.time}
              </Text>
              <Text color="var(--dark-text-grey-1)" textStyle="text-2">
                {punchDaySpelledOut}
              </Text>
            </>
          ) : (
            <Box paddingTop={'10px'}><Spinner color="var(--progress-bar-primary)" /></Box>
          )}
            
        </VStack>

        {/* Vertical Divider with space */}
        <Box height="90px" px={'10px'}>
          <Divider 
            orientation="vertical" 
            borderColor="var(--dark-component-border)" 
          />
        </Box>

        {/* Time Card Hours */}
        <VStack spacing={0} align="center">
          <Text paddingBottom={'5px'} textStyle="sfprotext-semi-bold-white-15px">Time Card Hours</Text>
          <Flex>
            <VStack align="center" spacing={0} pr={6}>
              {currentWeekHours!=='00' ? (
                <Text paddingBottom={'5px'} textStyle="sfprotext-semi-bold-gold-24px" textAlign="center">{currentWeekHours}</Text>
              ) : (
                <Box paddingTop={'10px'}><Spinner color="var(--progress-bar-primary)" /></Box>
              )} 
              <Text color="var(--dark-text-grey-1)" textStyle="text-2" textAlign="center">This Week</Text>
            </VStack>
            <VStack align="center" spacing={0}>
              {totalHoursWorked!=='00' ? (
                <Text paddingBottom={'5px'} textStyle="sfprotext-semi-bold-gold-24px" textAlign="center">{totalHoursWorked}</Text>
              ) : (
                <Box paddingTop={'10px'}><Spinner color="var(--progress-bar-primary)" /></Box>
              )} 
              <Text color="var(--dark-text-grey-1)" textStyle="text-2" textAlign="center">Pay Period</Text>
            </VStack>
          </Flex>
        </VStack>
      </Flex>

      {/* <PunchDetails punchEntries={punchEntries} spellOutDate={spellOutDate} /> */}
    </Box>

  );
};

TimeCardModule.propTypes = {
  totalHoursWorked: PropTypes.string.isRequired,
  currentWeekHours: PropTypes.string.isRequired,
  mostRecentPunchIn: PropTypes.object.isRequired,
  punchEntries: PropTypes.array,
};

// <Spinner color='var(--progress-bar-primary)' />