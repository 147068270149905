import { updateCapitalOneInstantNotification } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export const updateInstantNotification = async (props) => {
  if (!props) return;

  console.log('updateInstantNotification props: ', props);
  
  try {
    const gqlParams = { input: props };
    
    const response = await client.graphql({
      query: updateCapitalOneInstantNotification,
      variables: gqlParams
    });
    
    console.log('updateInstantNotification response: ', response);
    
    const results = response.data.updateSpkCapitalOneInstantNotificationTbl.id;
    console.log('updateInstantNotification results: ', results);
    
    return results;
  } catch (err) {
    return ('error updateInstantNotification: ', [err]);
  }
};

updateInstantNotification.propTypes = {
  props: PropTypes.object.isRequired,
};