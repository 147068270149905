import { UpdateDJTLaborUsed } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const updateDJTLaborUsed = async (props) => {
  if (!props || !props.id) return;

  try {
    console.log('running update labor with props: ', props);

    const variables = {
      input: props
    };

    const response = await client.graphql({
      query: UpdateDJTLaborUsed,
      variables
    });

    console.log('response: ', response);

    const results = response.data.updateSpkDJTLaborTbl.id;

    console.log('results: ', results);
    return results;
  } catch (err) {
    return ('error updateDJTLaborUsed: ', [err]);
  }
}

updateDJTLaborUsed.propTypes = {
  props: PropTypes.object.isRequired,
};
