import PropTypes from 'prop-types';
import { Box, VStack, Text, Badge, Flex } from '@chakra-ui/react';

// import { spellOutDate } from '../functions/dateTime.jsx';
// import { truncateString } from '../functions/strings.jsx';

export const UnitInspectionsScrollableBox = (props) => {

  const { 
    items, 
    width, 
    // height, 
    // handleSelectedObject, 
    handleLoadMore=()=>{}, 
    maxHeight,
   } = props

  //  console.log('height: ', height);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight) {
      // console.log('reached the bottom of the scrollbox');
      handleLoadMore()
    }
  };

  return (
    <Box 
      minWidth={'auto'}
      width={width}>
      <Box
        maxHeight={maxHeight||'650px'}
        // height={height||'285px'}
        // height={height||'auto'}
        height='auto'
        // width={width||'100%'}
        width={'100%'}
        overflowY="scroll"
        border="2px solid"
        borderColor="#42424A"
        borderRadius="6px"
        p={2}
        onScroll={handleScroll}
        // bg="var(--dark-button-grey-1)"
        sx={{
          '::-webkit-scrollbar': {
            width: '12px',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'var(--dark-button-grey-4)',
            borderRadius: '10px',
          },
          '::-webkit-scrollbar-track': {
            background: 'var(--dark-button-grey-1)',
          },
        }}
      >
        <VStack spacing={2}>
          {items?.map((item, index) => {
            // console.log('item: ', item);
            const createdAt = item.createdAt;
            const date = new Date(createdAt);
            
            const options = {
              hour: "numeric",         // gives hour in 1-12 format without leading zero
              minute: "2-digit",       // keeps minute with leading zero if needed
              hour12: true,            // ensures AM/PM notation
            };
            
            // Format to local time with specified options
            const formattedTime = date.toLocaleString("en-US", options);

            const status = item.status.isAvailable
            const badgeColor = (status === true) ? 'green' : 'red';
            const badgeText = (status === true) ? 'PASS' : 'FAIL';
            const PFBadge = <Badge 
            variant="solid"
            borderRadius={'6px'}
            // marginRight={'10px'}
            // paddingTop={'5px'}
            // paddingBottom={'5px'} 
            colorScheme={badgeColor} 
            textAlign="center" 
            display="inline-block" 
            minWidth="60px"
            ><Text textStyle='text-1'>{badgeText}</Text></Badge>

            const unitName = `${item?.unit?.code} ${item?.unit?.type?.name}/${item?.unit?.subType?.name}`
            // const inspectedDate = item?.createdAt.split('T')[0]
            // const dateString = spellOutDate(inspectedDate, 'apple-ny');
            const inspectedBy = item?.inspectedBy
            const firstName = (inspectedBy?.goesBy) ? `${inspectedBy?.goesBy}` : `${inspectedBy?.firstName}`;
            const lastInitial = (inspectedBy?.lastName) ? `${inspectedBy?.lastName.charAt(0)}.` : '';
            const inspectorName = `${firstName} ${lastInitial}`

            return (
            <Box
              key={'box_'+index+'_'+item?.id}
              w="100%"
              p={2}
              // bg={selectedObject?.id === item.id ? 'var(--dark-button-grey-4)' : 'var(--dark-button-grey-1)'}
              bg={'var(--dark-button-grey-1)'}
              borderRadius="6px"
              cursor="pointer"
              // onClick={() => handleSelectedObject(item)}
              _hover={{ bg: 'var(--dark-button-grey-4)' }}
            >
              <Text textStyle='text-1'>{unitName}</Text>
              <Flex justify="space-between" align="center" paddingTop={'4px'}>
                <Text fontSize="sm">{inspectorName} {formattedTime}</Text>
                {PFBadge}
              </Flex>
            </Box>
          )})}
        </VStack>
      </Box>
    </Box>
  );
};

UnitInspectionsScrollableBox.propTypes = {
  items: PropTypes.array.isRequired,
  width: PropTypes.string,
  // height: PropTypes.string,
  // handleSelectedObject: PropTypes.func,
  handleLoadMore: PropTypes.func,
  maxHeight: PropTypes.string,
};