import { updateCreditCardChartOfAccounts } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export const updateChartOfAccounts = async (props) => {
  if (!props.qbChartOfAccountsId || !props.qbChartOfAccountsName) return;

  const environment = props?.allSort;
  const allSort = (environment === 'sandbox') ? 'sandbox' : 'all';
  // Set default value for props if needed
  props.allSort = allSort;
  
  try {
    // console.log('updateChartOfAccounts props: ', props);

    // if (!props.allSort) {
    //   props.allSort = 'all';
    // }

    const gqlParams = {
      input: props,
    };

    const response = await client.graphql({
      query: updateCreditCardChartOfAccounts,
      variables: gqlParams,
    });

    // console.log('updateChartOfAccounts response: ', response);
    const results = response.data.updateSpkCreditCardChartOfAccountsTbl.id;
    return results;
  } catch (err) {
    return('error updateChartOfAccounts: ', [err]);
  }
};

updateChartOfAccounts.propTypes = {
  props: PropTypes.shape({
    allSort: PropTypes.string,
    // Add any other fields in `props` that are required here.
  }).isRequired,
};