import { updateClientContactPhoneEmail } from '../../../graphql/custom_mutations';
import PropTypes from 'prop-types';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export const updateClientContact = async (props) => {
  if (!props) return;

  try {
    console.log('PROPS: ', props);

    const response = await client.graphql({
      query: updateClientContactPhoneEmail,
      variables: { input: props },
    });

    console.log('RESPONSE: ', response);

    const results = response.data.updateSpkDJTClientContactTbl.id;
    return results;
  } catch (err) {
    return ('error updateClientContact: ', [err]);
  }
};

updateClientContact.propTypes = {
  props: PropTypes.object.isRequired,
};
